import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import {
    DocumentSharingView, ExchangeEvent, ExchangeEventSortableProperties, SortObject
} from '../document-sharing.types';

@Injectable()
export class ExchangeEventsStateService {

    private connectorIds: string[] = [];
    private readonly defaultSorts: SortObject[] = [
        { by: 'timestamp', direction: 'DESC' },
        { by: 'connectionName', direction: 'ASC' },
        { by: 'documentName', direction: 'ASC' }
    ];

    private sorts = new BehaviorSubject<SortObject[]>(this.defaultSorts);
    sorts$ = this.sorts.asObservable();

    private filterValue = '';
    public filterType = ExchangeEventSortableProperties.DOCUMENT_NAME;

    private startDate = new BehaviorSubject<Date>(this.getDefaultStartDate());
    startDate$ = this.startDate.asObservable();
    private endDate = new BehaviorSubject<Date>(this.getDefaultEndDate());
    endDate$ = this.endDate.asObservable();
    public minSelectableDate: Date = this.getMinSelectableDate();
    public maxSelectableDate: Date = this.getMaxSelectableDate();

    private currentPage = new BehaviorSubject<number>(1);
    currentPage$ = this.currentPage.asObservable();

    private exchangeEvents = new BehaviorSubject<ExchangeEvent[]>([]);
    exchangeEvents$ = this.exchangeEvents.asObservable();

    private hasNext = new BehaviorSubject<boolean>(false);
    hasNext$ = this.hasNext.asObservable();

    private pageSize = 10;
    private noResultsMessages = {
        defaultReceived: [
            'There are no documents received in the past 7 days.',
            'Please choose a different date range or team(s) to display more documents.'
        ],
        defaultSent: [
            'There are no documents sent in the past 7 days.',
            'Please choose a different date range or team(s) to display more documents.'
        ],
        filter: [
            'There are no documents with that keyword.',
            'If you want to filter shared documents based on the document name, please enter a different keyword.'
        ],
        dateRange: [
            'There are no documents in the selected date range.',
            'Please choose a different date range or team(s) to display more documents.'
        ]
    };

    private documentSharingView = new BehaviorSubject<DocumentSharingView>('documentsReceived');
    documentSharingView$ = this.documentSharingView.asObservable();

    private noResultsMessage = new BehaviorSubject<string[]>(this.noResultsMessages.defaultReceived);
    noResultsMessage$ = this.noResultsMessage.asObservable();


    // TODO: isLoading state should probably be a part of component that needs to be rendered, not part of a service
    private isLoading = new BehaviorSubject<boolean>(false);
    isLoading$ = this.isLoading.asObservable();

    public setDocumentSharingView(newView: DocumentSharingView): void {
        if (newView === this.documentSharingView.getValue()) {
            return;
        }
        this.documentSharingView.next(newView);
    }

    public resetPagination(): void {
        this.resetPageNumber();
    }

    public setSorts(sorts: SortObject[]): void {
        this.sorts.next([...sorts]);
    }

    public setPrimarySort(prioritySort: SortObject): void {
        const currentSorts = this.sorts.getValue();

        const nextSorts = [
            prioritySort,
            ...currentSorts.filter((sortObject) => sortObject.by !== prioritySort.by)
        ];

        this.setSorts(nextSorts);
    }

    public resetSorts(): void {
        this.setSorts(this.defaultSorts);
    }

    private getDefaultStartDate(): Date {
        // Offset 6 days to show last 7 days
        const daysOffset = -6;
        const date = this.getSelectableDate(daysOffset);
        return this.setDateHoursToStartOfDay(date);
    }

    private getDefaultEndDate(): Date {
        const date = this.getSelectableDate();
        return this.setDateHoursToEndOfDay(date);
    }

    private getMinSelectableDate(): Date {
        const daysOffset = -120;
        const date = this.getSelectableDate(daysOffset);
        return this.setDateHoursToStartOfDay(date);
    }

    private getMaxSelectableDate(): Date {
        const date = this.getSelectableDate();
        return this.setDateHoursToEndOfDay(date);
    }

    private getSelectableDate(daysOffset = 0): Date {
        const momentDate = moment().add(daysOffset, 'days');
        return momentDate.toDate();
    }

    private setDateHoursToStartOfDay(dateObj: Date): Date {
        const momentDate = moment(dateObj).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        });
        return momentDate.toDate();
    }

    private setDateHoursToEndOfDay(dateObj: Date): Date {
        const momentDate = moment(dateObj).set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999
        });
        return momentDate.toDate();
    }

    public setStartDate(date: Date): void {
        this.startDate.next(date);
        this.setNoResultsMessage('dateRange');
    }

    public setEndDate(date: Date): void {
        const endOfDay = new Date(date);
        endOfDay.setHours(23, 59, 59, 999);
        this.endDate.next(endOfDay);
        this.setNoResultsMessage('dateRange');
    }

    public getFilterValue(): string {
        return this.filterValue;
    }

    public setFilterValue(filterValue: string): void {
        this.filterValue = filterValue;
        // if the document name filter was cleared, use dateRange
        const messageName = !this.filterValue.length ? 'dateRange' : 'filter';
        this.setNoResultsMessage(messageName);
    }

    public setConnectorIds(connectorIds: string[]): void {
        this.connectorIds = connectorIds;
    }

    private setNoResultsMessage(messageName: string) {
        this.noResultsMessage.next(this.noResultsMessages[messageName]);
    }

    public getSorts(): SortObject[] {
        return this.sorts.getValue();
    }

    public getPageSize(): number {
        return this.pageSize;
    }

    public getStartDate(): Date {
        return this.startDate.getValue();
    }

    public getEndDate(): Date {
        return this.endDate.getValue();
    }

    public getConnectorIds(): string[] {
        return this.connectorIds;
    }

    public getDocumentSharingView(): DocumentSharingView {
        return this.documentSharingView.getValue();
    }

    public getExchangeEvents(): ExchangeEvent[] {
        return this.exchangeEvents.getValue();
    }

    public setExchangeEvents(value: ExchangeEvent[]): void {
        this.exchangeEvents.next(value);
    }

    public getHasNext(): boolean {
        return this.hasNext.getValue();
    }

    public setHasNext(value: boolean): void {
        this.hasNext.next(value);
    }

    public getIsLoading(): boolean {
        return this.isLoading.getValue();
    }

    public setIsLoading(value: boolean): void {
        this.isLoading.next(value);
    }

    public getPageNumber(): number {
        return this.currentPage.getValue();
    }

    public setPageNumber(page: number): void {
        this.currentPage.next(page);
    }

    public resetPageNumber(): void {
        this.setPageNumber(0);
    }
}
