import { Component, Input } from '@angular/core';
import template from './projectizer-item.component.html';
import style from './projectizer-item.component.scss';
import { Item } from './projectizer-item.component.type';

@Component({
    selector: 'projectizer-item',
    template,
    styles: [String(style)]
})
export class ProjectizerItemComponent {
    @Input() item: Item;
}
