import {
    Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges
} from '@angular/core';
import { TeamService } from '@app/shared/teams/team.service';
import { RoleTemplate } from '@app/shared/teams/teams.service.types';
import template from './roles-templates-by-template.component.html';


@Component({
    selector: 'roles-templates-by-template',
    template
})
export class RolesTemplatesByTemplateComponent implements OnInit, OnChanges {
  @Input() result: any;
  @Output() onCreate = new EventEmitter<object>();
  @Output() onPreview = new EventEmitter<object>();
  @Output() onClear = new EventEmitter<void>();

  rolesTemplates: RoleTemplate[] = [];
  selectedRoleTemplate: RoleTemplate | string = ''
  submittedSelectedRoleTemplate: RoleTemplate | string = '';
  previewCompleted = false;

  constructor(private teamService: TeamService) {}

  ngOnInit(): void {
      this.teamService.rolesTemplates().subscribe((rolesTemplates: RoleTemplate[]) => {
          this.rolesTemplates = rolesTemplates;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes.result) {
          this.result = changes.result.currentValue;
      }
  }

  create() {
      const event = this.getParams();
      this.onCreate.emit(event);
  }

  preview() {
      const event = this.getParams();
      this.previewCompleted = true;
      this.submittedSelectedRoleTemplate = this.selectedRoleTemplate;
      this.onPreview.emit(event);
  }

  clear() {
      this.selectedRoleTemplate = null;
      this.submittedSelectedRoleTemplate = '';
      this.onClear.emit();
  }

  previewUpToDate() {
      return this.selectedRoleTemplate === this.submittedSelectedRoleTemplate;
  }

  previewTooltip() {
      if (!this.selectedRoleTemplate) {
          return 'Role template needs to be selected';
      }
      if (this.isCreateAuditInProgress()) {
          return 'Create in progress, a new preview cannot be created.';
      }
  }

  clearTooltip() {
      if (this.clearDisabled()) {
          return 'Nothing to clear. Form and results are empty';
      }
      return 'Clear form';
  }

  isCreateAuditInProgress() {
      return this.result && this.result.status === 'inProgress' && this.result.action === 'create';
  }

  clearDisabled() {
      return !this.result && !this.selectedRoleTemplate;
  }

  selectRoleTemplate(roleTemplate: RoleTemplate) {
      this.selectedRoleTemplate = roleTemplate;
  }

  getParams() {
      if (typeof this.selectedRoleTemplate === 'object') {
          return {
              filters: {
                  rolesTemplateName: this.selectedRoleTemplate.roleName
              }
          };
      }
      return {
          filters: {
              rolesTemplateName: ''
          }
      };
  }
}
