import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import studyStartupRoute from './study-startup.route';
import { StudyStartupComponent } from './containers/study-startup/study-startup.component';
import { StudyStartupService } from './study-startup.service';

const dependencies = [uiRouter];

export const studyStartupModule = angular
    .module('app.studyStartup', dependencies)
    .config(studyStartupRoute)
    .factory('StudyStartupService', downgradeInjectable(StudyStartupService))
    .directive('studyStartup', downgradeComponent({ component: StudyStartupComponent }) as ng.IDirectiveFactory);
