export const messages = {
    unknownErr: 'There was an unexpected error. Please contact your administrator.',
    sitesCreated: 'Sites successfully created.',
    sitesCreatedErr: 'Some sites were not able to be successfully created. Please review and try again.',
    sitesUpdated: 'Sites successfully updated.',
    sitesUpdatedErr: 'Some sites were not able to be successfully updated. Please review and try again.',
    studyUpdated: 'Study successfully updated.',
    studyUpdateErr: 'There was an unexpected error during study update. Please contact your administrator.',
    studyCreated: 'Study successfully created.',
    entitiesLink: 'Items successfully connected.',
    entitiesLinkErr: 'Some items were not able to be successfully connected. Please review and try again.',
    monitorGroupsLinked: 'Monitor groups successfully connected.',
    monitorGroupsLinkedErr: 'Monitor groups were not connected. Please review and try again.'
};
