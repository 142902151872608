import * as _ from 'lodash';
import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './modify-completion-modal.component.html';
import style from './modify-completion-modal.component.scss';
import { ItemAttributes, ItemToModify, ModifyCompletionEvent } from './modify-completion-modal.component.types';

@Component({
    selector: 'modify-completion-modal',
    template,
    styles: [String(style)]
})
export class ModifyCompletionModalComponent implements OnInit {
    @Input() item: ItemToModify;
    @Input() attributes: ItemAttributes;
    dropdownPositionConfig = {
        left: -185,
        top: 5
    };

    isProcessing: boolean;
    updateAllTimelines: boolean;
    newCompletionDate: Date;
    error: string;
    showPicker = false;

    @Output() onSave = new EventEmitter<ModifyCompletionEvent>();
    constructor(
        private modalInstance: BsModalRef
    ) {}

    ngOnInit(): void {
        this.isProcessing = false;
        this.updateAllTimelines = false;
        this.newCompletionDate = this.item.completionDate || this.attributes.uploadedDate
            ? new Date(this.item.completionDate || this.attributes.uploadedDate)
            : null;

    }

    onTogglePicker(): void {
        if (this.showPicker === false) {
            this.showPicker = true;
        }
    }

    toggleUpdateAllTimelines() {
        this.updateAllTimelines = !this.updateAllTimelines;
    }

    setCompletion(value: Date): void {
        this.newCompletionDate = value;
    }

    cancel(): void {
        this.modalInstance.hide();
    }

    save() {
        if (!this.newCompletionDate) {
            this.error = 'Please set a valid date before saving.';
            return;
        }

        this.isProcessing = true;
        const updatePayload = _.pick(this.item, ['objectId', 'objectType']);

        const onSaveEvent = {
            updatePayload: {
                ...updatePayload,
                completionDate: this.newCompletionDate?.toISOString(),
                allItemOccurrences: this.updateAllTimelines
            },
            itemName: this.attributes.name,
            onSuccess: () => {
                this.modalInstance.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        };

        this.onSave.emit(onSaveEvent);
    }
}
