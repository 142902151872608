import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { SigningPasscodeService } from '@app/shared/signing-passcode/signing-passcode.service';

import template from './signing-pin-expiry-notification.component.html';

@Component({
    selector: 'signing-pin-expiry-notification',
    template
})
export class SigningPinExpiryNotificationComponent {
    @Input() signingPinExpired: boolean;
    @Output() dismiss = new EventEmitter<void>();

    constructor(
        private SigningPasscode: SigningPasscodeService,
        private Notifications: NotificationsService
    ) {}

    cancel(): void {
        this.dismiss.emit();
    }

    sendSigningPasscodeResetInstructions(): void {
        this.SigningPasscode.resetSigningPasscode().subscribe(() => {
            this.Notifications.success('Instructions for setting your Signing PIN has been sent to your email');
        });
        this.dismiss.emit();
    }
}
