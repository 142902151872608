import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { CHECKBOX_STATES } from '@app/core/constants';
import { SigningReasons, User } from '@app/shared/models';
import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';

import template from './log-entry-signers-tab.component.html';
import styles from './log-entry-signers-tab.component.scss';

export interface LogEntrySignersTabDataProps {
    checked?: CHECKBOX_STATES;
    notifyMe?: CHECKBOX_STATES;
    emailSigner?: CHECKBOX_STATES;
    signByDate?: Date;
    column?: string;
    columnOptions?: string[];
    reason?: SigningReasons;
    showSignByDateInvalid?: boolean;
    activeDropdown: boolean;
}
export interface LogEntrySignersTabDataRow extends User, LogEntrySignersTabDataProps { }

export enum LogEntrySignersTabStatus {
    ALL_COLUMNS_HAVE_PENDING = 'All columns have pending signatures. See Pending tab.',
    ALL_COLUMNS_HAVE_SIGNATURE = 'All columns have signatures.',
    NO_AVAILABLE_COLUMNS = 'All columns have pending or existing signatures. See Pending tab.'
}

@Component({
    selector: 'log-entry-signers-tab',
    template,
    styles: [String(styles)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogEntrySignersTabComponent {
    @Input() status: LogEntrySignersTabStatus = null;
    @Input() data: LogEntrySignersTabDataRow[] = [];
    @Input() loadingPotentialSigners = false;
    @Input() potentialSigners: User[] = [];
    @Input() selectedSigners: User[] = [];

    @Output() signersFilterChange = new EventEmitter<string>();
    @Output() removeSigners = new EventEmitter<string[]>();
    @Output() selectSigners = new EventEmitter<FilteredSelectEvent<User>>();
    @Output() changeColumn = new EventEmitter<{ value: string; id: string }>();
    @Output() changeReason = new EventEmitter<{ value: SigningReasons; id: string }>();
    @Output() changeSignByDate = new EventEmitter<{ value: Date; id: string }>();
    @Output() changeNotifyMe = new EventEmitter<{ value: CHECKBOX_STATES; id: string }>();
    @Output() changeEmailSigner = new EventEmitter<{ value: CHECKBOX_STATES; id: string }>();

    signingReasons = Object.values(SigningReasons);
    headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
    checkedIds: Set<string> = new Set();
    infoText = '';
    dropdownPositionConfig = {
        left: -135
    };

    readonly currentDate = new Date(new Date().setHours(1, 0, 0, 0) - 1);

    onSignersFilterChange($event: string): void {
        this.signersFilterChange.emit($event);
    }

    onTogglePicker(row:LogEntrySignersTabDataRow): void {
        if (row) {
            row.activeDropdown = !row.activeDropdown;
        }
    }

    onSignersSelect($event: FilteredSelectEvent<User>): void {
        this.selectSigners.emit($event);
    }

    toggleCheckedAllRows(): void {
        if (
            this.headerCheckboxState === CHECKBOX_STATES.NOT_SELECTED
            || this.headerCheckboxState === CHECKBOX_STATES.PARTIALLY_SELECTED
        ) {
            this.headerCheckboxState = CHECKBOX_STATES.SELECTED;
            this.data.forEach((row: LogEntrySignersTabDataRow) => {
                row.checked = this.headerCheckboxState;
                this.checkedIds.add(row.id);
            });
        }
        else {
            this.headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
            this.data.forEach((row: LogEntrySignersTabDataRow) => {
                row.checked = this.headerCheckboxState;
                this.checkedIds.delete(row.id);
            });
        }
    }

    private handleHeaderCheckboxStateOnRowChange(): void {
        if (!this.checkedIds.size) {
            this.headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
        }
        else if (this.checkedIds.size === this.data.length) {
            this.headerCheckboxState = CHECKBOX_STATES.SELECTED;
        }
        else {
            this.headerCheckboxState = CHECKBOX_STATES.PARTIALLY_SELECTED;
        }
    }

    toggleCheckedRow($event: LogEntrySignersTabDataRow): void {
        if ($event.checked && $event.checked === CHECKBOX_STATES.SELECTED) {
            $event.checked = CHECKBOX_STATES.NOT_SELECTED;
            this.checkedIds.delete($event.id as string);
        }
        else {
            $event.checked = CHECKBOX_STATES.SELECTED;
            this.checkedIds.add($event.id as string);
        }
        this.handleHeaderCheckboxStateOnRowChange();
    }

    onColumnChange(value: string, row: LogEntrySignersTabDataRow): void {
        if (value !== row.column) {
            this.changeColumn.emit({ value, id: row.id });
        }
    }

    onReasonChange(value: SigningReasons, row: LogEntrySignersTabDataRow): void {
        if (value !== row.reason) {
            this.changeReason.emit({ value, id: row.id });
        }
    }

    onSignByDateChange(value: Date, row: LogEntrySignersTabDataRow): void {
        if (!value && !row.signByDate) {
            return;
        }
        if (value && row.signByDate && new Date(value).toISOString() === new Date(row.signByDate).toISOString()) {
            return;
        }
        this.changeSignByDate.emit({ value, id: row.id });
    }

    private getNextCheckboxState(currentState: CHECKBOX_STATES): CHECKBOX_STATES {
        if (currentState === CHECKBOX_STATES.SELECTED) {
            return CHECKBOX_STATES.NOT_SELECTED;
        }
        return CHECKBOX_STATES.SELECTED;
    }

    onNotifyMeChange(row: LogEntrySignersTabDataRow): void {
        const focusedElement = document.activeElement as HTMLElement;
        const value = this.getNextCheckboxState(row.notifyMe);
        this.changeNotifyMe.emit({ value, id: row.id });

        this.focusHtmlElement(focusedElement);
    }

    onEmailSignerChange(row: LogEntrySignersTabDataRow): void {
        const focusedElement = document.activeElement as HTMLElement;
        const value = this.getNextCheckboxState(row.emailSigner);
        this.changeEmailSigner.emit({ value, id: row.id });

        this.focusHtmlElement(focusedElement);
    }

    private focusHtmlElement(elementToFocus: HTMLElement): void {
        setTimeout(() => {
            if (elementToFocus) {
                elementToFocus.focus();
            }
        }, 0);
    }

    onSignersRemove(): void {
        const ids = Array.from(this.checkedIds);
        if (!(ids && ids.length)) {
            return;
        }
        this.removeSigners.emit(ids);
        this.checkedIds.clear();
        this.headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
    }
}
