import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EntityModule } from '@app/widgets/entity/entity.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SharedModule } from '@app/shared/shared.module';
import { FolderDownloadComponent } from './components/folder-download/folder-download.component';
import { ShortcutsImportComponent } from './components/shortcuts-import/shortcuts-import.component';
import { FolderFormComponent } from './components/folder-form/folder-form.component';
import { MoveComponent } from './components/move/move.component';
import { TimelineBulkAssignComponent } from './containers/timeline-bulk-assign/timeline-bulk-assign.component';
import { ShortcutsExportComponent } from './components/shortcuts-export/shortcuts-export.component';
import { DestroyFolderComponent } from './components/destroy-folder/destroy-folder.component';
import { FolderShowComponent } from './containers/folder-show/folder-show.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        ScrollingModule,
        ReactiveFormsModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        WidgetsModule,
        EntityModule,
        SharedModule
    ],
    providers: [],
    declarations: [
        FolderDownloadComponent,
        TimelineBulkAssignComponent,
        FolderFormComponent,
        MoveComponent,
        ShortcutsImportComponent,
        ShortcutsExportComponent,
        DestroyFolderComponent,
        FolderShowComponent
    ],
    entryComponents: [
        FolderDownloadComponent,
        TimelineBulkAssignComponent,
        FolderFormComponent,
        MoveComponent,
        ShortcutsImportComponent,
        ShortcutsExportComponent,
        DestroyFolderComponent,
        FolderShowComponent
    ],
    exports: [
    ]
})
export class FoldersModule {}
