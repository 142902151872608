import {
    Component, OnInit,
    Output,
    EventEmitter,
    Input
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MESSAGES, REGEX, ROLE_BLINDED_STATUSES } from '@app/core/constants';
import { Role, User } from '@app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RenameRoleEvent } from './edit-role.component.types';
import template from './edit-role.component.html';
import styles from './edit-role.component.scss';

@Component({
    selector: 'edit-role',
    template,
    styles: [String(styles)]
})
export class EditRoleComponent implements OnInit {
    @Input() role: Role;
    @Input() usersAssignedToRole: User[];
    @Output() onEdit = new EventEmitter<RenameRoleEvent>();
    originalName: string;
    originalBlindedStatus: string;
    isProcessing = false;
    showTooltip = {
        roleName: false
    };

    roleNamePattern = REGEX.names;
    nameMessage = MESSAGES.validNameMessage;
    editRoleForm: FormGroup;

    constructor(
        private fb: FormBuilder,
        private modal: BsModalRef
    ) { }

    ngOnInit(): void {
        this.originalName = this.role.name;
        this.originalBlindedStatus = this.role.blindedStatus || ROLE_BLINDED_STATUSES.NO_MARK;
        this.initForm();
        this.editRoleForm.controls.roleName.setValue(this.role.name);
    }

    cancel(): void {
        this.modal.hide();
    }

    save(): void {
        if (this.editRoleForm.invalid) {
            return;
        }

        this.isProcessing = true;
        this.role.name = this.editRoleForm.value.roleName;
        this.role.blindedStatus = this.editRoleForm.value.blindedStatus;
        const renameEvent = {
            role: this.role,
            onError: () => {
                this.isProcessing = false;
            },
            onSuccess: () => {
                this.modal.hide();
            }
        };

        this.onEdit.emit(renameEvent);
    }

    handleKeyPress(event: KeyboardEvent): void {
        if (event.which === 13 && this.editRoleForm.valid
            && this.editRoleForm.value.roleName.trim() !== this.originalName.trim()) {
            this.save();
        }
    }

    initForm(): void {
        this.editRoleForm = this.fb.group({
            roleName: ['', [Validators.required, Validators.pattern(this.roleNamePattern)]],
            blindedStatus: [this.originalBlindedStatus, []]
        });
    }

    get roleBlindedStatuses() {
        return ROLE_BLINDED_STATUSES;
    }

    get isSubmitDisabled() {
        return (this.editRoleForm.invalid
            || this.isProcessing)
            || (
                this.editRoleForm.value.roleName.trim() === this.originalName.trim()
                && this.editRoleForm.value.blindedStatus.trim() === this.originalBlindedStatus.trim()
            );
    }

    get isWarningEnabled() {
        return this.usersAssignedToRole && this.usersAssignedToRole.length
            && (this.editRoleForm.value.blindedStatus.trim() !== this.originalBlindedStatus.trim());
    }

}
