import {
    StateProvider,
    Transition,
    StateService,
    TransitionPromise
} from '@uirouter/angularjs';
import { take } from 'rxjs/operators';
import { AppConfigService } from '@app/shared/app-config/app-config.service';
import { UsersService } from '@app/shared/users/users.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { TeamService } from '@app/shared/teams/team.service';
import { User } from '@app/shared/models';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { HttpHeaders } from '@angular/common/http';
import { Auth0Service } from '../shared/sessions/auth0.service';
import { Pendo } from '../core/analytics/pendo-script-initializer.service.types';

const layoutRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('home', {
        url: '/',
        views: {
            'layout@': {
                controller: [
                    '$state',
                    'Auth0Service',
                    function welcomeController($state: StateService, Auth0: Auth0Service): TransitionPromise {
                        return Auth0.isAuthenticated$
                            .pipe(take(1))
                            .toPromise()
                            .then((isLoggedIn) => {
                                if (isLoggedIn) {
                                    return $state.go('app.select-team');
                                }
                                return $state.go('sign-in');
                            }) as TransitionPromise;
                    }]
            }
        }
    });

    $stateProvider.state('app', {
        url: '/app',
        views: {
            'layout@': {
                component: 'appLayout'
            }
        },
        resolve: {
            currentUser: [
                'Users',
                'CurrentSession',
                'Notifications',
                '$window',
                'FeatureFlagService',
                'AppConfigService',
                function loadUser(
                    users: UsersService,
                    currentSession: CurrentSessionService,
                    notifications: NotificationsService,
                    $window,
                    featureFlagService: FeatureFlagService,
                    appConfigService: AppConfigService
                ): Promise<void> {
                    return users.getCurrent().toPromise()
                        .then((user: User) => {
                            if (!currentSession.isRegistrationFinished(user)) {
                                $window.location.replace(`${appConfigService.config.authHost}`);
                            }
                            const headersToAdd = new HttpHeaders({ Range: 'bytes=0-1023' });
                            users.getHeaders(headersToAdd).toPromise()
                                .then((headers: string[]) => {
                                    const canUsePartialRequests = headers.includes('range');
                                    currentSession.setCanUsePartialRequests(canUsePartialRequests);
                                });
                            currentSession.setCurrentUser(user);
                            featureFlagService.initialize(user);
                        }).catch((error) => notifications.error(error.error.message));
                }]
        },
        data: {
            requiresAuth: true
        }
    });

    $stateProvider.state('app.team', {
        url: '/teams/:teamId',
        abstract: true,
        template: '<div class="app_layout__container" ui-view></div>',
        resolve: {
            currentTeam: [
                '$transition$',
                'Team',
                'CurrentSession',
                '$window',
                'FeatureFlagService',
                'AppConfigService',
                function loadCurrentTeam(
                    $transition$: Transition,
                    teams: TeamService,
                    currentSession: CurrentSessionService,
                    window: Window & { pendo: Pendo },
                    featureFlagService: FeatureFlagService,
                    appConfigService: AppConfigService
                ): ng.IPromise<void> {
                    return teams.loadById($transition$.params().teamId).toPromise()
                        .then((team) => {
                            currentSession.setCurrentTeam(team);
                            featureFlagService.patchContext(team);
                            if (appConfigService.config.pendoEnabled) {
                                const user = currentSession.getCurrentUser();
                                window.pendo.initialize({
                                    visitor: {
                                        id: user.id
                                    },
                                    account: {
                                        id: team.id,
                                        teamName: team.name,
                                        environment: appConfigService.config.environment
                                    }
                                });
                            }
                        });
                }]
        }
    });

    $stateProvider.state('app.select-platform', {
        url: '/select-platform',
        onEnter: ['$window', 'AppConfigService', function onEnter($window, appConfigService: AppConfigService): void {
            $window.location.replace(`${appConfigService.config.authHost}/#/app/select-platform`);
        }]
    });

    $stateProvider.state('sign-in', {
        url: '/sign-in?&returnTo&userId&sessionExpired&email',
        params: {
            returnTo: { squash: true, value: null },
            userId: { squash: true, value: null },
            sessionExpired: { squash: true, value: null },
            email: { squash: true, value: null }
        },
        onEnter: ['$transition$', '$window', 'AppConfigService', function onEnter($transition$, $window, appConfigService: AppConfigService): void {
            const searchParams = new URLSearchParams();
            const {
                returnTo, userId, sessionExpired, email
            } = $transition$.params();

            if (returnTo) {
                searchParams.set('returnTo', returnTo);
            }
            if (userId) {
                searchParams.set('userId', userId);
            }
            if (sessionExpired) {
                searchParams.set('sessionExpired', sessionExpired);
            }
            if (email) {
                searchParams.set('email', email);
            }

            const paramsString = searchParams.toString();
            $window.location.replace(`${appConfigService.config.authHost}/#/sign-in${paramsString ? `?${paramsString}` : ''}`);
        }]
    });
};

layoutRoute.$inject = ['$stateProvider'];

export default layoutRoute;
