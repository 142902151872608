import { Binder, Folder, Document } from '@app/shared/models';

export type VirtualTreeNode = (Binder | Folder | Omit<Document, 'id'>) & {
    id: string;
    items?: VirtualTreeNode[];
    title?: string;
    binderId? : string;
};

export type VirtualTreeFlatNode = VirtualTreeNode & {
    level?: number;
    hasChildren?: boolean;
    exchangeEventId?: string;
}

export interface VirtualTreeItemSelectedEvent<T extends Binder | Folder | Document> {
    item: T;
    selectedItems?: T[];
}

export enum VirtualTreeSelectionMode {
    NONE = 'NONE',
    // Items are only selectable directly and not via an ancestor
    MULTI_SELECT = 'MULTI_SELECT',
    // Items can be selected directly or via their ancestor items
    HIERARCHICAL_MULTI_SELECT = 'HIERARCHICAL_MULTI_SELECT',
    // Only one item can be selected at a time
    SINGLE = 'SINGLE'
}
