import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { SIGNING_REASONS } from '@app/core/constants';
import template from './signature-reason-picker.html';
import style from './signature-reason-picker.scss';

@Component({
    selector: 'signature-reason-picker',
    template,
    styles: [String(style)]
})
export class SignatureReasonPickerComponent implements OnInit {
    signingReasons = SIGNING_REASONS.reasons;
    selectedReason = '';
    @Input() reasonLabel = 'Reason';
    @Input() defaultSigningReason: string;
    @Output() reasonChange = new EventEmitter<string>();

    ngOnInit(): void {
        if (this.defaultSigningReason) {
            this.selectedReason = this.defaultSigningReason;
        }
    }

    onReasonChanged(event): void {
        this.reasonChange.emit(event);
    }
}
