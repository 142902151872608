export type DocumentSharingView = 'documentsReceived' | 'documentsSent';

export type DocumentDirection = 'receiving' | 'sending';

export const CONFIG_TYPES = {
    EBINDER: 'ebinder'
} as const;

export const REGIONS = {
    US: 'us',
    DE: 'de',
    KP: 'kp'
} as const;

export type Region = typeof REGIONS[keyof typeof REGIONS];

export type ConfigType = typeof CONFIG_TYPES[keyof typeof CONFIG_TYPES];

export interface Receiver {
    teamId: string;
    receivingLocation?: {
        binderId: string;
        folderId?: string;
    };
    region: Region;
    configType: ConfigType;
}

export interface Sender {
    teamId: string;
    region: Region;
}

export type Config = Receiver & { name: string };

export interface Connection {
    _id: string;
    configs: [Config, Config];
}

export const EXCHANGE_EVENT_STATUS = {
    FAILED: 'FAILED',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS'
} as const;

export const EXCHANGE_JOB_STATUS = {
    IN_PROGRESS: 'IN PROGRESS',
    COMPLETE: 'COMPLETE'
} as const;

export type ExchangeEventStatus = typeof EXCHANGE_EVENT_STATUS[keyof typeof EXCHANGE_EVENT_STATUS];
export type ExchangeJobStatus = typeof EXCHANGE_JOB_STATUS[keyof typeof EXCHANGE_JOB_STATUS];

export interface ExchangeDocumentDetail {
    documentId: string;
    version: number;
}

export interface ExchangeDocument {
    name: string;
    ext: string;
    receiving: ExchangeDocumentDetail | null;
    sending: ExchangeDocumentDetail;
}

export interface ExchangeEvent {
    _id: string;
    connectorId: string;
    exchangeJobId: string;
    timestamp: Date;
    status: ExchangeEventStatus;
    document: ExchangeDocument;
    sender: Sender;
    receiver: Receiver;
    requestMetadata?: unknown | null;
    responseMetadata?: unknown | null;
    createdBy: string;
    updatedBy: string;
    connection: Connection;
}

export const ExchangeEventSortableProperties = {
    TIMESTAMP: 'timestamp',
    CONNECTION_NAME: 'connectionName',
    DOCUMENT_NAME: 'documentName'
} as const;

export type SortBy = typeof ExchangeEventSortableProperties[keyof typeof ExchangeEventSortableProperties];

// services/integration-connector-service/lib/constants/sorting-directions.ts
export const SortingDirections = {
    ASC: 'ASC',
    DESC: 'DESC'
} as const;

export type SortingDirectionValues = typeof SortingDirections[keyof typeof SortingDirections];

export interface SortObject {
    by: typeof ExchangeEventSortableProperties[keyof typeof ExchangeEventSortableProperties];
    direction: typeof SortingDirections[keyof typeof SortingDirections];
}

export interface GetExchangeEventParams {
    teamId: string;
    sort: SortObject[];
    pageSize: number;
    startDate: Date;
    endDate: Date;
    connectorIds: string[];
    isReceiver: boolean;
    filterType?: typeof ExchangeEventSortableProperties.DOCUMENT_NAME;
    filterValue?: string;
}

export interface GetActiveConnectionsParams {
    teamId: string;
    isReceiver?: boolean;
}

export interface GetExchangeEventsResponse {
    exchangeEvents: {
        items: ExchangeEvent[];
        hasNext: boolean;
    }
}

export interface CreateExchangeParams {
    senderTeamId: string;
    documents: any[],
    connections: Connection[];
}

export type PageCommand = 'previousPage' | 'nextPage';
