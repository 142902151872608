import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { TeamInboxResponse } from '@app/components/team-inbox/team-inbox.types';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Team } from '@app/shared/models';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TeamInboxCheckerService implements OnDestroy {
    readonly url = {
        inbox: (teamId: string): string => `/api/teams/${teamId}/inbox`
    }

    teamInboxCount = new BehaviorSubject<number>(0);
    teamInboxCount$ = this.teamInboxCount.asObservable();
    private teamId: string;

    $destroy = new Subject<void>();

    constructor(
        private CurrentSession: CurrentSessionService,
        private http: HttpClient
    ) {}

    ngOnDestroy(): void {
        this.$destroy.next();
        this.$destroy.complete();
    }

    init(): void {
        this.CurrentSession.currentTeam$
            .pipe(takeUntil(this.$destroy), distinctUntilChanged((prev, curr) => prev && curr && prev.id === curr.id))
            .subscribe(this.onTeamChanged.bind(this));
    }

    private onTeamChanged(team: Team): void {
        if (!team) {
            return;
        }

        if (this.teamId === team.id) {
            return;
        }

        this.teamId = team.id;
        this.http.get<TeamInboxResponse>(this.url.inbox(this.teamId))
            .subscribe((data) => {
                this.teamInboxCount.next(data.recordCount);
            });
    }

    public decreaseCounter(): void {
        const currentValue = this.teamInboxCount.getValue();
        if (currentValue > 0) {
            this.teamInboxCount.next(currentValue - 1);
        }
    }
}
