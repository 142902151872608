import {
    Component,
    Input,
    OnChanges,
    OnInit
} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

@Component({
    selector: 'deadline-icon',
    template: `
    <span *ngIf="state">
        <i [tooltip]="deadlineMessageTemplate"
            [isDisabled]="!state.date"
            [placement]="tooltipPlacement"
            container="body"
            class="far fa-fw {{iconClass}} {{getStoplightClass()}}"></i>
        <ng-template #deadlineMessageTemplate>
            <span>{{tooltipMessage}} {{state.date | date:'dd-MMM-yyyy':teamTimezone}}</span>
        </ng-template>
    </span>
    `
})

export class DeadlineIconComponent implements OnInit, OnChanges {
    @Input() type?: 'expiration' | 'due';
    @Input() teamTimezone: string;
    @Input() tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
    @Input() state: {
        status: 'due' | 'almost due' | 'on track';
        date: string;
    }

    public iconClass: string;
    public tooltipMessage: string;

    private DeadlineType = {
        expiration: 'expiration',
        due: 'due'
    }

    private DeadlineStatus = {
        due: 'due',
        almostDue: 'almost due',
        onTrack: 'on track'
    }

    ngOnInit(): void {
        if (this.teamTimezone) {
            this.teamTimezone = moment().tz(this.teamTimezone).format('Z');
        }
        this.iconClass = this.getIconClass();
        this.tooltipMessage = this.getTooltipMessage();
        this.tooltipPlacement = _.isUndefined(this.tooltipPlacement) ? 'right' : this.tooltipPlacement;
    }

    ngOnChanges(): void {
        this.tooltipMessage = this.getTooltipMessage();
    }


    getIconClass(): string {
        switch (this.type) {
            case this.DeadlineType.expiration:
                return 'fa-calendar-check';
            case this.DeadlineType.due:
                return 'fa-calendar-plus';
            default:
                return 'fa-calendar-alt';
        }
    }

    getStoplightClass(): string {
        const status = this.state ? this.state.status : null;
        switch (status) {
            case this.DeadlineStatus.due:
                return 'text-danger';
            case this.DeadlineStatus.almostDue:
                return 'text-warning';
            default:
                return 'text-muted';
        }
    }

    getTooltipMessage(): string {
        const status = _.get(this, 'state.status', '');
        let message = '';
        switch (this.type) {
            case this.DeadlineType.expiration:
                message = this._getExpirationTooltipMessage(status);
                break;
            case this.DeadlineType.due:
            default:
                message = this._getDueTooltipMessage(status);
                break;
        }

        if (_.get(this, 'state.date', false)) {
            message += ': ';
        }
        return message;
    }

    _getDueTooltipMessage(status: string): string {
        switch (status) {
            case this.DeadlineStatus.due:
                return 'Due';
            case this.DeadlineStatus.almostDue:
                return 'Almost Due';
            default:
                return 'Due Date Set';
        }
    }

    _getExpirationTooltipMessage(status: string): string {
        switch (status) {
            case this.DeadlineStatus.due:
                return 'Expired';
            case this.DeadlineStatus.almostDue:
                return 'Almost Expired';
            default:
                return 'Expiration Date Set';
        }
    }
}
