import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { UserTeamTraining } from '../models/user-team-training.model';
import { UserTrainingApiService } from './user-training-api/user-training-api.service';
import { CurrentSessionService } from '../../core/current-session.service';

@Injectable()
export class UserTrainingService {
    constructor(
        private currentSessionService: CurrentSessionService,
        private userTrainingApiService: UserTrainingApiService
    ) {}

    readonly florenceTrainingBaseUrl = 'https://training.florencehc.com';

    trainingQueue$: Observable<UserTeamTraining[]> = this.currentSessionService.currentTeam$.pipe(
        switchMap((team) => {
            const trainingCompleted = false;
            return this.userTrainingApiService.getTrainings$(team.id, trainingCompleted);
        }),
        shareReplay(1)
    );

    incompleteTrainingsCount$: Observable<number> = this.trainingQueue$.pipe(
        map((trainingQueue) => trainingQueue?.length)
    );
}
