import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit
} from '@angular/core';
import {
    FormControl, Validators, AbstractControl
} from '@angular/forms';
import {
    REGEX,
    MESSAGES
} from '@app/core/constants';

import template from './pwd-and-confirmation-inputs.component.html';

@Component({
    selector: 'pwd-and-confirmation-inputs',
    template
})
export class PwdAndConfirmationInputsComponent implements OnInit {
    @Input() passwordLabel = 'Password';
    @Input() confirmationLabel = 'Password Confirmation';
    @Input() autofocusPassword = false;
    @Output() fieldsRegister = new EventEmitter<{ password: AbstractControl; confirmation: AbstractControl }>();
    private passwordMessage = MESSAGES.passwordMessage;
    password: AbstractControl;
    confirmation: AbstractControl;

    private showTooltip = {
        confirmation: false,
        password: false
    };

    ngOnInit(): void {
        this.password = new FormControl('', [
            Validators.required, Validators.pattern(REGEX.password10)
        ]);

        this.confirmation = new FormControl('', [
            Validators.required, Validators.pattern(REGEX.password10), this.validateConfirmation.bind(this)
        ]);

        this.password.valueChanges.subscribe(() => {
            this.confirmation.updateValueAndValidity();
        });

        this.fieldsRegister.emit({ password: this.password, confirmation: this.confirmation });
    }

    validateConfirmation(control: AbstractControl): { pattern: boolean } {
        return control.value === this.password.value ? null : { pattern: true };
    }
}
