'use strict';

exports.watcherTypes = {
    SEARCH_INDEX: 'search_index',
    ENCRYPT_BACKUP: 'encrypt_backup',
    EVENT_NOTIFICATION: 'event_notification',
    APPROVAL_EVENT_NOTIFICATION: 'approval_event_notification',
    AUTH_USER_CHANGE: 'auth_user_change',
    DOCUMENTS_METADATA_CHANGE: 'documents_metadata_change',
    EXTRACT_REQUEST_NOTIFICATIONS: 'extract_request_notifications'
};
