import {
    Output,
    Input,
    EventEmitter,
    Component
} from '@angular/core';

import { CHECKBOX_STATES } from '@app/core/constants';
import { SignatureRequest, SigningReasons } from '@app/shared/models';

import template from './log-entry-pending-signatures-tab.component.html';
import styles from './log-entry-pending-signatures-tab.component.scss';

export type LogEntryPendingTabDataProps = {
    checked?: CHECKBOX_STATES;
    activeDropdown?: boolean;
    markedAsCanceled?: boolean;
    updated?: boolean;
    notifyMe?: CHECKBOX_STATES;
    emailSigner?: CHECKBOX_STATES;
    showSignByDateInvalid?: boolean;
}
export type LogEntryPendingTabDataRow = SignatureRequest & LogEntryPendingTabDataProps;

@Component({
    selector: 'log-entry-pending-signatures-tab',
    template,
    styles: [String(styles)]
})
export class LogEntryPendingSignaturesTabComponent {
    @Input() data: LogEntryPendingTabDataRow[] = [];

    @Output() changePendingReason = new EventEmitter<{ value: SigningReasons; id: string }>();
    @Output() changePendingSignByDate = new EventEmitter<{ value: Date; id: string }>();
    @Output() changePendingNotifyMe = new EventEmitter<{ value: CHECKBOX_STATES; id: string }>();
    @Output() changePendingEmailSigner = new EventEmitter<{ value: CHECKBOX_STATES; id: string }>();
    @Output() markCanceled = new EventEmitter<string[]>();
    @Output() undoChanges = new EventEmitter<string[]>();

    signingReasons = Object.values(SigningReasons);
    headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
    checkedIds: Set<string> = new Set();
    readonly currentDate = new Date(new Date().setHours(1, 0, 0, 0) - 1);
    dropdownPositionConfig = {
        left: -135
    };

    toggleCheckedAllRows(): void {
        if (
            this.headerCheckboxState === CHECKBOX_STATES.NOT_SELECTED
            || this.headerCheckboxState === CHECKBOX_STATES.PARTIALLY_SELECTED
        ) {
            this.headerCheckboxState = CHECKBOX_STATES.SELECTED;
            this.data.forEach((row: LogEntryPendingTabDataRow) => {
                row.checked = this.headerCheckboxState;
                this.checkedIds.add(row.id);
            });
        }
        else {
            this.headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
            this.data.forEach((row: LogEntryPendingTabDataRow) => {
                row.checked = this.headerCheckboxState;
                this.checkedIds.delete(row.id);
            });
        }
    }

    onTogglePicker(row:LogEntryPendingTabDataRow): void {
        if (row) {
            row.activeDropdown = !row.activeDropdown;
        }
    }

    private handleHeaderCheckboxStateOnRowChange(): void {
        if (!this.checkedIds.size) {
            this.headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
        }
        else if (this.checkedIds.size === this.data.length) {
            this.headerCheckboxState = CHECKBOX_STATES.SELECTED;
        }
        else {
            this.headerCheckboxState = CHECKBOX_STATES.PARTIALLY_SELECTED;
        }
    }

    toggleCheckedRow($event: LogEntryPendingTabDataRow): void {
        if ($event.checked && $event.checked === CHECKBOX_STATES.SELECTED) {
            $event.checked = CHECKBOX_STATES.NOT_SELECTED;
            this.checkedIds.delete($event.id as string);
        }
        else {
            $event.checked = CHECKBOX_STATES.SELECTED;
            this.checkedIds.add($event.id as string);
        }
        this.handleHeaderCheckboxStateOnRowChange();
    }

    onReasonChange(value: SigningReasons, row: LogEntryPendingTabDataRow): void {
        if (value === row.reason) {
            return;
        }
        this.changePendingReason.emit({ value, id: row.id });
    }

    onSignByDateChange(value: Date, row: LogEntryPendingTabDataRow): void {
        if (!value && !row.signByDate) {
            return;
        }
        if (value && row.signByDate && new Date(value).toISOString() === new Date(row.signByDate).toISOString()) {
            return;
        }
        this.changePendingSignByDate.emit({ value, id: row.id });
    }

    private getNextCheckboxState(currentState: CHECKBOX_STATES): CHECKBOX_STATES {
        if (currentState === CHECKBOX_STATES.SELECTED) {
            return CHECKBOX_STATES.NOT_SELECTED;
        }
        return CHECKBOX_STATES.SELECTED;
    }

    onNotifyMeChange(row: LogEntryPendingTabDataRow): void {
        const value = this.getNextCheckboxState(row.notifyMe);
        this.changePendingNotifyMe.emit({ value, id: row.id });
    }

    onEmailSignerChange(row: LogEntryPendingTabDataRow): void {
        const value = this.getNextCheckboxState(row.emailSigner);
        this.changePendingEmailSigner.emit({ value, id: row.id });
    }

    onMarkCanceled(): void {
        if (!this.checkedIds.size) {
            return;
        }
        const ids = Array.from(this.checkedIds);
        this.markCanceled.emit(ids);
    }

    onUndoChanges(): void {
        if (!this.checkedIds.size) {
            return;
        }
        const ids = Array.from(this.checkedIds);
        this.undoChanges.emit(ids);
    }
}
