import { StateProvider } from '@uirouter/angularjs';

const notificationsRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.manage-notifications', {
        url: '/manage-notifications',
        views: {
            '': 'manageNotifications'
        },
        params: {
            isTeamNotifications: false
        }
    });
    $stateProvider.state('app.team.manage-team-notifications', {
        url: '/manage-team-notifications',
        views: {
            '': 'manageNotifications'
        },
        params: {
            isTeamNotifications: true
        }
    });
};


notificationsRoute.$inject = ['$stateProvider'];

export default notificationsRoute;
