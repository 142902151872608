import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
    Team, Binder, Folder, Document, ApiError
} from '@app/shared/models';
import { calculateEntityPath, EntityPathItem } from '@app/shared/documents/calculate-entity-path.util';
import { GetTimelinesResponseItem } from '@app/shared/projects/projects.service.types';
import { CurrentSessionService } from '@app/core/current-session.service';
import { ProjectsService } from '@app/shared/projects/projects.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';

import template from './timeline-bulk-assign.component.html';
import styles from './timeline-bulk-assign.component.scss';

@Component({
    selector: 'timeline-bulk-assign',
    template,
    styles: [String(styles)]
})
export class TimelineBulkAssignComponent implements OnInit {
    @Input() documents: Document[];
    @Input() parentEntity: Binder | Folder;
    @Output() timelinesAssigned = new EventEmitter<GetTimelinesResponseItem[]>();

    entityPath: EntityPathItem[];
    isProcessing = false;
    teamTimelinesLoaded = false;
    initialTimelines: GetTimelinesResponseItem[] = [];
    currentTimelines$ = new BehaviorSubject<GetTimelinesResponseItem[]>([]);
    otherTimelines$ = new BehaviorSubject<GetTimelinesResponseItem[]>([]);
    timelinesChanged$ = of(false);
    private currentTeam: Team;

    constructor(
        private modalRef: BsModalRef,
        private Projects: ProjectsService,
        private CurrentSession: CurrentSessionService,
        private Notifications: NotificationsService
    ) { }

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.entityPath = calculateEntityPath(this.parentEntity, this.currentTeam);
        this.Projects.getTimelines(this.currentTeam.id, { prependProjectName: true })
            .pipe(take(1))
            .subscribe(
                (teamTimelines) => {
                    this.otherTimelines$.next(sortByLexicographically([...teamTimelines], 'name'));
                    this.teamTimelinesLoaded = true;
                },
                (error) => {
                    if (error.error && error.error.message) {
                        this.Notifications.error(error.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                    this.cancel();
                }
            );
        this.timelinesChanged$ = this.currentTimelines$.asObservable()
            .pipe(map((timelines) => timelines.length > 0));
    }

    cancel(): void {
        if (this.isProcessing) {
            return;
        }
        this.modalRef.hide();
    }

    onTimelineRemove(removedTimeline: GetTimelinesResponseItem): void {
        const newCurrentTimelines = this.currentTimelines$.getValue().filter((t) => t.id !== removedTimeline.id);
        const newOtherTimelines = sortByLexicographically([...this.otherTimelines$.getValue(), removedTimeline], 'name');
        this.currentTimelines$.next(newCurrentTimelines);
        this.otherTimelines$.next(newOtherTimelines);
    }

    onTimelineAssign(asignedTimeline: GetTimelinesResponseItem): void {
        const newCurrentTimelines = sortByLexicographically([...this.currentTimelines$.getValue(), asignedTimeline], 'name');
        const newOtherTimelines = this.otherTimelines$.getValue().filter((t) => t.id !== asignedTimeline.id);
        this.currentTimelines$.next(newCurrentTimelines);
        this.otherTimelines$.next(newOtherTimelines);
    }

    save(): void {
        const timelines = this.currentTimelines$.getValue();
        if (this.isProcessing) {
            return;
        }
        this.isProcessing = true;

        const timelineIds = timelines.map((t) => t.id);
        const documentIds = this.documents.map((d) => d.id) as string[];
        this.Projects.saveBulkTimelineAssignments(this.currentTeam.id, documentIds, timelineIds)
            .pipe(take(1))
            .subscribe(
                () => {
                    this.timelinesAssigned.emit(timelines);
                    this.isProcessing = false;
                    this.Notifications.success(`Assigned Timelines updated for ${documentIds.length} items!`);
                    this.modalRef.hide();
                },
                ({ error }: ApiError) => {
                    this.isProcessing = false;
                    this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                }
            );
    }
}
