import { Component, Input } from '@angular/core';
import template from './role-chip-training-required.component.html';
import { IChipIcon } from '../../../../widgets/chip/types/i-chip-icon';

@Component({
    selector: 'role-chip-training-required',
    template
})
export class RoleChipTrainingRequiredComponent {
    @Input() roleName: string;

    icon: IChipIcon = {
        name: 'fa-graduation-cap',
        color: '#F18067'
    }
}
