import { StateProvider } from '@uirouter/angularjs';

const connectedTeamsRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.connected-teams', {
        url: '/document-sharing/connected-teams',
        views: {
            '': 'connectedTeams'
        }
    });
};

connectedTeamsRoute.$inject = ['$stateProvider'];

export default connectedTeamsRoute;
