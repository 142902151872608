import * as _ from 'lodash';
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnDestroy
} from '@angular/core';
import { Document } from '@app/shared/models';
import { DocumentService } from '@app/shared/documents/document.service';
import { DocumentUpdateError, SetPiiResponse } from '@app/shared/documents/documents.service.types';
import { Subscription } from 'rxjs';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './toggle-pii.html';
import style from './toggle-pii.scss';

@Component({
    template,
    styles: [String(style)]
})
export class TogglePiiComponent implements OnInit, OnDestroy {
    @Input() setPii: boolean;
    @Input() documents: Document[];
    @Output() onPiiUpdate = new EventEmitter<SetPiiResponse>();

    modalTitle: string;
    modalMessage: string;
    isProcessing = false;

    private documentsServiceSubscription: Subscription;

    constructor(
        private DocumentService: DocumentService,
        private Notifications: NotificationsService,
        private modalRef: BsModalRef
    ) { }

    ngOnInit(): void {
        this.documents = this.documents.filter((doc) => {
            return doc.hasPii !== this.setPii;
        });
        this.setModalContent();
    }

    private setModalContent(): void {
        const action = this.setPii ? 'mark' : 'unmark';
        const result = this.setPii ? 'lose' : 'gain';
        const object = this.documents.length > 1
            ? `these (${this.documents.length}) documents` : 'this document';

        this.modalTitle = `Are you sure you want to ${action} ${object} as PHI?`;
        this.modalMessage = `${_.capitalize(action)}ing ${object} as PHI may cause some users to ${result} access to ${object}`;
    }

    cancel(): void {
        this.modalRef.hide();
    }

    confirm(): void {
        this.isProcessing = true;
        this.documentsServiceSubscription = this.DocumentService.setHasPii(this.documents, this.setPii)
            .subscribe((response: SetPiiResponse) => {

                if (response.errors.length > 0) {
                    response.errors.forEach((errorResponse: DocumentUpdateError) => {
                        const rawMsg = errorResponse.error.message;
                        const fullMessage = `An error occurred for ${errorResponse.document.title}. ${rawMsg}`;
                        this.Notifications.error(fullMessage);
                    });
                }
                if (response.documents.length) {
                    this.Notifications.success(`Document(s) ${this.setPii ? 'marked' : 'unmarked'} as containing PHI.`);
                    this.isProcessing = false;
                    this.onPiiUpdate.emit(response);
                }
            },
            (error: HttpErrorResponse) => {
                if (error.error && error.error.message) {
                    this.Notifications.error(error.error.message);
                }
                else {
                    this.Notifications.unexpectedError();
                    this.cancel();
                }
            }, () => {
                this.isProcessing = false;
                this.cancel();
            });
    }

    ngOnDestroy(): void {
        if (this.documentsServiceSubscription) {
            this.documentsServiceSubscription.unsubscribe();
        }
    }
}
