import {
    Component,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter
} from '@angular/core';

import * as _ from 'lodash';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { qcReviewStatuses } from '@florencehealthcare/florence-constants/lib/qc-reviews';

import { SORT, TABLE_CELL_TYPES } from '@app/core/constants';
import { MultiSelectService } from '@app/shared/multi-select/multi-select.service';
import { PaginationConfig } from '@app/components/reports/reports.service.types';
import {
    ColumnConfig, DisplayProperty, Sorter
} from './generic-table.component.types';

import template from './generic-table.component.html';
import styles from './generic-table.component.scss';

@Component({
    selector: 'generic-table',
    template,
    styles: [String(styles)]
})

export class GenericTableComponent implements OnInit, OnChanges {
    @Input() data: object[];
    @Input() displayLocked = false;
    @Input() rowHeight: number;
    @Input() sorter: Sorter;
    @Input() scrollable: boolean | string;
    @Input() columnConfig: ColumnConfig[];
    @Input() pagination: PaginationConfig;
    @Input() minWidth?: string = '100%'; // 1500px or 150%
    @Input() timezone?: string;

    @Output() tablePageChanged: EventEmitter<PaginationConfig> = new EventEmitter();
    @Output() nameCellClick?: EventEmitter<void> = new EventEmitter<void>();
    @Output() filterConfirmed?: EventEmitter<ColumnConfig> = new EventEmitter<ColumnConfig>();

    readonly restrictedValue = 'Details Not Available';
    readonly defaultTooltipPlacement = 'bottom';
    readonly qcReviewStatuses = qcReviewStatuses;

    multiSelect: (event, index: number, items: object[]) => object[];
    onSortChange: Sorter['onSortChange'];
    TABLE_CELL_TYPES = TABLE_CELL_TYPES;
    SORT = SORT;
    maxHeight: string;
    scrollClass: string;

    selectedOptions = {};

    constructor(private multiSelectService: MultiSelectService) {
        this.getSortValue = this.getSortValue.bind(this);
    }

    ngOnInit(): void {

        this.validate();
        const isScrollable = !(this.scrollable === 'false' || this.scrollable === false);
        this.scrollClass = isScrollable ? 'flex-table-scroller' : '';
        this.rowHeight = _.isUndefined(this.rowHeight) ? 10 : this.rowHeight;
        this.maxHeight = isScrollable ? `${this.rowHeight * 50}px` : null;

        if (this.sorter) {
            this.SORT = this.sorter.SORT;
            if (this.sorter.onSortChange) {
                this.onSortChange = this.sorter.onSortChange;
            }
        }

        const sortables = _.reduce(this.columnConfig, (arr, config) => {
            if (!config.isSortable) {
                return arr;
            }
            arr.push(config.sortProperty || config.displayProperty);
            return arr;
        }, []);

        if (!_.includes(sortables, this.SORT.by)) {
            const sortProperty = this.sorter && this.sorter.defaultSortProperty ? this.sorter.defaultSortProperty : sortables[0];
            this.setSort(sortProperty, this.SORT?.isReversed);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.columnConfig) {
            this.columnConfig = _.cloneDeep(changes.columnConfig.currentValue);

            _.forEach(this.columnConfig, (config) => {
                if (config.renderType === TABLE_CELL_TYPES.checkbox) {

                    this.multiSelect = this.multiSelectService.makeMultiSelect(config.checkboxCellStateProperty).select;
                }
                config.widthClass = this.getWidthClass(config.widthValue);

                if (config.isSearchable && config?.searchFieldType === 'singleSelect' && config.searchValue) {
                    this.selectedOptions = {
                        [config.headerName]: config.searchValue
                    };
                }

                const cellClasses = [];
                if (config.truncate === true) {
                    cellClasses.push('truncate-cell-text');
                }
                config.cellClasses = config.cellClasses || '';
                config.cellClasses += ` ${cellClasses.join(' ')}`;

                const cellContainerClasses = [];
                if (config.renderType === TABLE_CELL_TYPES.type) {
                    cellContainerClasses.push('flex-table-row-item-icon');
                }
                config.cellContainerClasses = config.cellContainerClasses || '';
                config.cellContainerClasses += ` ${cellContainerClasses.join(' ')}`;
            });
        }
    }

    getLocationValue(row: object, displayProperty: DisplayProperty): string {
        // Added because of the dot notation error
        if (typeof displayProperty === 'string') {
            return;
        }

        const binder = this.getValue(row, displayProperty.binderName);
        if (!binder) {
            return this.restrictedValue;
        }
        const foldersPath = this.getValue(row, displayProperty.foldersPath);
        const document = this.getValue(row, displayProperty.documentName);
        const logEntryRow = this.getValue(row, displayProperty.logEntryRow);
        const logEntryRowAppendix = logEntryRow && `Row${logEntryRow}`;
        return [binder, foldersPath, document, logEntryRowAppendix].filter(Boolean).join('/');
    }

    getNameVersionValue(row: object, displayProperty): string {
        const name = this.getValue(row, displayProperty.name);
        const logEntryRow = this.getValue(row, displayProperty.logEntryRow);
        if (logEntryRow) {
            const logEntryVersion = this.getValue(row, displayProperty.logEntryVersion);
            return `${name} - Row${logEntryRow} Version: ${logEntryVersion}`;
        }
        return `${name} Version: ${this.getValue(row, displayProperty.version)}`;
    }

    getNameValue(row: object, displayProperty): string {
        const name = this.getValue(row, displayProperty.name || displayProperty);
        const logEntryRow = this.getValue(row, displayProperty.logEntryRow);
        const logEntryRowAppendix = logEntryRow && `Row${logEntryRow}`;
        return [name, logEntryRowAppendix].filter(Boolean).join(' - ');
    }

    getStatusValue(row: object, displayProperty): string {
        const status = this.getValue(row, displayProperty.name || displayProperty);
        return status;
    }

    headerClick(config: ColumnConfig): void {
        if (!config) {
            return;
        }
        if (config.isSortable) {
            const prop = this.getSortProp(config);
            this.setSort(prop as string, undefined);
        }
    }

    isCurrentlySorted(config: ColumnConfig): boolean {
        if (!config) {
            return;
        }
        return this.SORT.by === this.getSortProp(config);
    }

    onCheckboxCellClickHandler(event, index, config): void {
        this.multiSelect(event, index, this.data);

        let allChecked = true;
        let allUnchecked = true;
        this.data.forEach((row) => {

            if (_.get(row, config.checkboxCellStateProperty)) {
                allUnchecked = false;
            }
            else {
                allChecked = false;
            }
        });

        if (allChecked) {
            config.headerCheckboxState = 1;
        }
        else if (allUnchecked) {
            config.headerCheckboxState = 0;
        }
        else {
            config.headerCheckboxState = 2;
        }
    }

    onCheckboxHeaderClickHandler(config: ColumnConfig): void {
        if (config.headerCheckboxState) {
            config.headerCheckboxState = 0;
        }
        else {
            config.headerCheckboxState = 1;
        }
        this.data.forEach((row) => {

            _.set(row, String(config.checkboxCellStateProperty), config.headerCheckboxState);
        });
    }

    showPagination(): boolean {
        return this.pagination && this.pagination.totalItemCount > this.pagination.pageSize;
    }

    onTablePageChange($event: PageChangedEvent): void {
        if (!this.pagination) {
            return;
        }
        this.turnOffAllCheckboxHeaders();
        this.tablePageChanged.emit({ ...this.pagination, pageNum: $event.page });
    }

    getTooltipValue(row: object, config: ColumnConfig): string {
        if (config.renderType === TABLE_CELL_TYPES.location) {
            return Object.values(config.displayProperty)
                .map((property) => this.getValue(row, property))
                .filter(Boolean)
                .join('/');
        }
        if (config.renderType === TABLE_CELL_TYPES.username) {
            // eslint-disable-next-line dot-notation
            const name = this.getValue(row, config.displayProperty['name']);
            // eslint-disable-next-line dot-notation
            const email = this.getValue(row, config.displayProperty['email']);
            return name && email ? `${name} - ${email}` : '';
        }
        if (config.renderType === TABLE_CELL_TYPES.status) {
            return this.getStatusValue(row, config.displayProperty);
        }
        const value = this.getValue(row, config.displayProperty as string);
        if (!value) {
            return '';
        }
        return value;
    }

    getValue(row: object, displayProperty: string): string {
        return _.get(row, displayProperty);
    }


    onNameCellClick(): void {
        if (this.nameCellClick) {
            this.nameCellClick.emit();
        }
    }

    isOptionSelected(config: ColumnConfig, option: string): boolean {
        return config.searchFieldPreselected?.includes(option);
    }

    filterKeyPressed(event: string, config: ColumnConfig): void {
        if (!this.filterConfirmed) {
            return;
        }

        if (config?.searchFieldType === 'singleSelect') {

            config.searchValue = event.trim().toUpperCase() === 'ALL' ? '' : event;
            this.selectedOptions[config.headerName] = config.searchValue;
        }
        else if (config?.searchFieldType === 'multiSelect') {

            if (event === 'Select All') {
                config.searchFieldPreselected = config.searchFieldOptions;
            }
            else if (event === 'Unselect All') {
                config.searchFieldPreselected = [];
            }
            else if (config.searchFieldPreselected?.includes(event)) {
                config.searchFieldPreselected = config.searchFieldPreselected.filter((option) => option !== event);
            }
            else {
                config.searchFieldPreselected.push(event);
            }

            if (config.searchFieldPreselected.length === config.searchFieldOptions.length) {
                config.searchValue = undefined;
                delete this.selectedOptions[config.headerName];
            }
            else {
                config.searchValue = config.searchFieldPreselected;
                this.selectedOptions[config.headerName] = config.searchFieldPreselected;
            }
        }
        else {
            config.searchValue = event;
        }
        this.filterConfirmed.emit(config);
    }

    private getSortValue(row: object): string {
        return _.get(row, this.SORT.by, '');
    }

    private validate(): void {
        this.validateSorterConfig();
        _.forEach(this.columnConfig, this.validateColumnConfig.bind(this));
    }

    private validateColumnConfig(config): void {
        if (config.renderType === TABLE_CELL_TYPES.checkbox) {
            return this.validateCheckboxConfig(config);
        }
        if (config.renderType === TABLE_CELL_TYPES.username) {
            return this.validateUsernameConfig(config);
        }
    }

    private validateCheckboxConfig(config): void {
        if (config.isSortable || config.sortProperty) {
            throw new Error('Cannot set checkbox column to sortable in generic-table component');
        }
        if (!config.checkboxCellStateProperty) {
            throw new Error('Missing checkboxCellStateProperty for checkbox column config in generic-table component');
        }
    }

    private validateUsernameConfig(config): void {
        if (!config.displayProperty) {
            throw new Error('Missing displayProperty username column config in generic-table component');
        }
    }

    private validateSorterConfig(): void {
        if (!this.sorter) {
            return;
        }
        if (!this.sorter.SORT) {
            throw new Error('Specified customer sorter without SORT directive');
        }
    }

    private getWidthClass(num): string {
        if (num !== 0 && !num) {
            return 'flex-table-row-item-width-1';
        }
        let int = parseInt(num, 10);
        int = _.clamp(int, 1, 10);
        return `flex-table-row-item-width-${int}`;
    }

    private getSortProp(config: ColumnConfig): string | { [key: string]: string } {
        return config.sortProperty || config.displayProperty;
    }

    private setSort(prop: string, isReversed: boolean): void {
        this.SORT.set(prop, isReversed);

        if (this.onSortChange) {
            this.onSortChange(this.SORT);
            return;
        }

        this.data = _.orderBy(
            this.data,
            [(item): string => this.getSortValue(item)],
            [this.SORT.isReversed ? 'desc' : 'asc']
        );
    }

    private turnOffAllCheckboxHeaders(): void {
        this.columnConfig.forEach((config) => {
            if (config.headerCheckboxState) {
                config.headerCheckboxState = 0;
            }
        });
    }
}
