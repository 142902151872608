import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { Timeline } from '@app/shared/models';
import template from './timeline-selection-dropdown.component.html';

@Component({
    selector: 'timeline-selection-dropdown',
    template
})

export class TimelineSelectionDropdownComponent {
    @Input() options: Timeline[];
    @Input() label: string;
    @Input() optionDisplayKey: string;
    @Output() navigate = new EventEmitter<Timeline>();


    handleNavigate(option: Timeline): void {
        this.navigate.emit(option);
    }
}
