import {
    Component, Input, OnInit
} from '@angular/core';
import { LogEntrySigStatuses } from '@app/components/documents/components/log-entry-row/log-entry-row.component.types';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Column, Signature, User } from '@app/shared/models';
import { LogEntryBulkRequestSignatureTableDataRow } from '../../containers/log-entry-bulk-signature-requests/log-entry-bulk-signature-requests.types';

import template from './log-entry-bulk-request-signatures-row-preview.component.html';
import styles from './log-entry-bulk-request-signatures-row-preview.component.scss';

@Component({
    selector: 'log-entry-bulk-request-signatures-row-preview',
    template,
    styles: [String(styles)]
})

export class LogEntryBulkRequestSignaturesRowPreviewComponent implements OnInit {
    @Input() rowData: LogEntryBulkRequestSignatureTableDataRow;
    currentUser: User;
    logEntrySignatureStatuses = LogEntrySigStatuses;

    constructor(
        private CurrentSession: CurrentSessionService
    ) {}

    ngOnInit(): void {
        this.currentUser = this.CurrentSession.getCurrentUser();
    }

    getLogEntrySignatureStatus(column: Column): number|null {
        const isSigned = this.isSignatureStatus(column.value as Signature, 'Signed');
        if (isSigned) {
            return isSigned ? this.logEntrySignatureStatuses.signed : null;
        }

        const { signatureRequest } = column;
        if (!signatureRequest) {
            return this.logEntrySignatureStatuses.signable;
        }
        if (signatureRequest.userId !== this.currentUser.id) {
            return this.logEntrySignatureStatuses.pendingAnotherUser;
        }
        return signatureRequest.isDue
            ? this.logEntrySignatureStatuses.pastDueCurrentUser
            : this.logEntrySignatureStatuses.pendingCurrentUser;
    }

    isSignatureStatus(signature: Signature, status: string): boolean {
        return signature?.status === status;
    }

}
