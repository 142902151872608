'use strict';

module.exports = {
    api: 'api',
    announcements: 'announcements',
    auditTrail: 'auditTrail',
    characterValidation: 'characterValidation',
    notifications: 'notifications',
    docConversion: 'documentConversion',
    newDocumentViewer: 'newDocumentViewer',
    emailConnector: 'emailConnector',
    emailContentDocument: 'emailContentDocument',
    eventDistro: 'eventDistro',
    downloader: 'downloader',
    documents: 'documents',
    contents: 'contents',
    shortcuts: 'shortcuts',
    placeholders: 'placeholders',
    elogs: 'elogs',
    doaLogs: 'doaLogs',
    downloads: 'downloads',
    labels: 'labels',
    logTemplates: 'logTemplates',
    log: 'log',
    logEntries: 'logEntries',
    formFields: 'formFields',
    folders: 'folders',
    binders: 'binders',
    teams: 'teams',
    features: 'features',
    users: 'users',
    supportUsers: 'support-users',
    teamDeactivation: 'team-deactivation',
    roles: 'roles',
    blindedRoles: 'blinded/unblindedRoles',
    forms: 'forms',
    facade: 'facade',
    business: 'business',
    dal: 'dal',
    hapi: 'hapi',
    awsStorage: 'awsStorage',
    tags: 'tags',
    tasks: 'tasks',
    digests: 'digests',
    reports: 'reports',
    timelines: 'timelines',
    projects: 'projects',
    actionGroups: 'actionGroups',
    items: 'items',
    saml: 'saml',
    monitorReview: 'monitorReview',
    studies: 'studies',
    sites: 'sites',
    studyStructureTemplates: 'studyStructureTemplates',
    sipTeamIntegrationLink: 'sipTeamIntegrationLink',
    documentHistory: 'documentHistory',
    studyStartup: 'studyStartup',

    create: 'create',
    read: 'read',
    show: 'show',
    update: 'update',
    destroy: 'destroy',
    orphan: 'orphan',
    clone: 'clone',
    move: 'move',
    replace: 'replace',
    receive: 'receive',
    accept: 'accept',
    rename: 'rename',
    download: 'download',
    annotations: 'annotations',
    redaction: 'redaction',
    passwordReset: 'passwordReset',
    samlPasswordReset: 'samlPasswordReset',
    requestPasswordReset: 'requestPasswordReset',
    confirmRegistration: 'confirmRegistration',
    permissions: 'permissions',
    lock: 'lock',
    invite: 'invite',
    run: 'run',
    preview: 'preview',
    cancel: 'cancel',
    status: 'status',
    approval: 'approval',
    runActionGroupRequests: 'runActionGroupRequests',
    accessDates: 'accessDates',
    open: 'open',
    closed: 'closed',
    approved: 'approved',
    changeDocumentVersion: 'changeDocumentVersion',
    placeholder: 'placeholder',
    pullIntoPlaceholder: 'pullIntoPlaceholder',
    document: 'document',
    shortcut: 'shortcut',
    attachments: 'attachments',
    signatureRequest: 'signatureRequest',
    signed: 'signed',
    strikethrough: 'strikethrough',

    bulk: 'bulk',

    security: 'security',

    sessions: 'sessions',
    admin: 'admin',

    reminder: 'reminder',
    request: 'request',
    assignment: 'assignment',

    stream: 'stream',
    buffer: 'buffer',
    secureStorageAws: 'secureStorageAws',
    resetSigningPasscode: 'resetSigningPasscode',
    requestHeader: 'requestHeader',

    rollback: 'rollback',
    unhandledError: 'unhandledError',

    uncategorized: 'uncategorized',

    unlink: 'unlink',
    externalDependency: 'external dependency',

    reportsService: 'reports-service',
    authService: 'auth-service',
    archiveService: 'archive-service',

    archivalScheduledEmail: 'archival-scheduled-email',

    fhcEventBus: 'fhc-event-bus',
    fhcEventBusPublish: 'fhc-event-bus-publish',
    fhcEventBusConsume: 'fhc-event-bus-consume',
    fhcEventBusMessageBackupFailure: 'fhc-event-bus-message-backup-failure',

    jwtTokenAuth0: 'jwt-token-auth0',
    jwtHeader: 'jwt-header-token',
    jwtCookie: 'jwt-cookie-token',
    jwtCookieTeamMember: 'jwt-cookie-team-member-token',
    bearerToken: 'bearer-access-token',
    hmacAuth: 'hmac-auth-token',
    publicApi: 'public-api',
    jwtTokenPublicApi: 'jwt-token-public-api',
    jwtM2MTokenApi: 'jwt-m2m-token-api',
    postLoginUpsert: 'post-login-upsert',
    jwtTokenWorkFloManager: 'jwt-token-workflo-manager',
    jwtTokenCsApp: 'jwt-token-cs-app',
    fileDownload: 'file-download',
    externalEvents: 'external-events',
    mongoTransaction: 'mongo-transaction',

    qcReview: 'qc-review',

    mfa: 'mfa',
    regions: 'regions',
    csApp: 'cs-app',
    sitelink2: 'sitelink2',
    trainingGate: 'training-gate',

    trainingAssignmentService: 'training-assignment-service',

    intercomEvent: 'intercom-event'
};
