import {
    Component,
    Input,
    OnChanges
} from '@angular/core';

import { NamesPreview } from '@app/shared/models';
import { NAMES_PREVIEW_LIMIT } from '@app/core/constants';

import template from './names-preview.component.html';
import styles from './names-preview.component.scss';

@Component({
    selector: 'names-preview',
    template,
    styles: [String(styles)]
})
export class NamesPreviewComponent implements OnChanges {
    @Input() data: NamesPreview = [];

    readonly namesCountLimit = NAMES_PREVIEW_LIMIT;
    previewErrorsCount = 0;
    previewStatusMessage: string;

    ngOnChanges(): void {
        if (!this.data || !this.data.length) {
            return;
        }
        const previewsWithErros = this.data.filter((previewItem) => !!previewItem.error);
        this.previewErrorsCount = previewsWithErros.length;
        if (this.data.length > this.namesCountLimit) {
            this.previewErrorsCount += 1;
        }
        if (this.previewErrorsCount > 0) {
            const issuesCountInfo = `${this.previewErrorsCount} issue${this.previewErrorsCount === 1 ? '' : 's'}`;
            this.previewStatusMessage = `${issuesCountInfo}: Select icons for details, and edit list on left.`;
        }
        else {
            this.previewStatusMessage = 'List is ready! Click Save to continue.';
        }
    }
}
