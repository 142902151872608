import { StateProvider } from '@uirouter/angularjs';
import { ROUTES } from '@app/core/constants';
import { LogTemplateType } from './components/log-template-type-selector/log-template-type-selector.component.types';

export const logTemplatesRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state(ROUTES.logTemplatesSelector, {
        url: '/log-templates',
        views: {
            '': 'logTemplateTypeSelector'
        }
    });

    $stateProvider.state(ROUTES.elogs, {
        url: '/log-templates/elog',
        views: {
            '': 'teamLogTemplates'
        },
        data: {
            logType: LogTemplateType.ELOG
        }
    });

    $stateProvider.state(ROUTES.doaLogs, {
        url: '/log-templates/doa',
        views: {
            '': 'teamLogTemplates'
        },
        data: {
            logType: LogTemplateType.DOA
        }
    });

    $stateProvider.state(ROUTES.logTemplateCreate, {
        url: '/log-templates/elog/new',
        views: {
            '': 'logTemplateView'
        }
    });

    $stateProvider.state(ROUTES.doaLogTemplateCreate, {
        url: '/log-templates/doa/new',
        views: {
            '': 'doaLogTemplateCreate'
        }
    });

    $stateProvider.state(ROUTES.logTemplateView, {
        url: '/log-templates/elog/:logTemplateId',
        views: {
            '': 'logTemplateView'
        }
    });

    $stateProvider.state(ROUTES.doaLogTemplateView, {
        url: '/log-templates/doa/:logTemplateId',
        views: {
            '': 'doaLogTemplateView'
        }
    });

    $stateProvider.state(ROUTES.logTemplateViewVersion, {
        url: '/log-templates/elog/:logTemplateId/versions/:version',
        views: {
            '': 'logTemplateView'
        }
    });

    $stateProvider.state(ROUTES.doaLogTemplateViewVersion, {
        url: '/log-templates/doa/:logTemplateId/versions/:version',
        views: {
            '': 'doaLogTemplateView'
        }
    });
};

logTemplatesRoute.$inject = ['$stateProvider', '$urlServiceProvider'];
