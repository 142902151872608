import { Label } from './label.model';
import { Folder } from './folder.model';
import { Document } from './document.model';

export type BrowseNode = BrowseFolder | BrowseDocument;

export const browseNodeIsDocument = (object: BrowseNode): object is BrowseDocument => {
    return object.type === 'document';
};

export const browseNodeIsFolder = (object: BrowseNode): object is BrowseFolder => {
    return object.type === 'folder';
};

export type BrowseFolder = Folder & {
    items?: BrowseNode[];
}
export type BrowseDocument = Omit<Document, 'id'> & {
    id: string;
    name?: string;
    title?: string;
    documentId?: {documentId?: string};
};

export interface BrowseTree {
    binderId: string;
    binderName: string;
    createdAt: string;
    createdBy: string;
    id: string;
    isDownloadable: boolean;
    items: BrowseNode[];
    labels: Label[];
    lineage: string[];
    name: string;
    nameCanonical: string;
    path: string;
    pathCanonical: string;
    permissions: { [key: string]: boolean };
    subType?: 'folder' | 'placeholder';
    teamId: string;
    title?: string;
    type: 'binder' | 'folder';
    updatedAt: string;
    updatedBy: string;
}
