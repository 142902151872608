import * as _ from 'lodash';
import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Binder } from '@app/shared/models';
import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import template from './choose-folder-options.component.html';
import styles from './choose-folder-options.component.scss';

@Component({
    selector: 'choose-folder-options',
    template,
    styles: [String(styles)]
})

export class ChooseFolderOptionsComponent {
    @Input() binders: Binder[] = [];
    @Input() assignStructureForm: FormGroup;
    @Input() selectedBinder: Binder;
    @Input() hasFoldersInBinder = false;
    @Input() binderSelectionInProcess = false;
    @Output() binderSelected: EventEmitter<Binder> = new EventEmitter<Binder>();

    filteredBinders: Binder[] = [];
    filterBinderControl = new FormControl(null);

    sortedBinders: Binder[] = [];

    ngOnInit(): void {
        this.sortedBinders = sortByLexicographically(_.cloneDeep(this.binders), 'name');
        this.filteredBinders = this.sortedBinders;
    }

    filterBinders(filter: string): void {
        if (!filter) {
            this.filteredBinders = this.sortedBinders;
            return;
        }

        const filterCanonical = filter.trim().toLowerCase();
        this.filteredBinders = this.sortedBinders.filter((b) => b.name.toLowerCase().includes(filterCanonical));
    }

    onBinderSelect($event: FilteredSelectEvent<Binder>): void {
        this.selectedBinder = $event.added && $event.added.length ? $event.added[0] : null;
        this.assignStructureForm.controls.assignStructureOption.setValue('newFolder');
        this.binderSelected.emit(this.selectedBinder);
    }

    onSelectionCleared(): void {
        this.selectedBinder = null;
        this.assignStructureForm.controls.assignStructureOption.setValue('newFolder');
        this.binderSelected.emit(this.selectedBinder);
    }
}
