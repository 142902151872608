import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import { downgradeComponent } from '@angular/upgrade/static';

import foldersRoute from './folders.route';
import { FolderShowComponent } from './containers/folder-show/folder-show.component';
import { ShortcutsImportComponent } from './components/shortcuts-import/shortcuts-import.component';
import { FolderDownloadComponent } from './components/folder-download/folder-download.component';
import { FolderFormComponent } from './components/folder-form/folder-form.component';
import { MoveComponent } from './components/move/move.component';
import { DestroyFolderComponent } from './components/destroy-folder/destroy-folder.component';

const dependencies = [
    uiRouter
];

const foldersModule = angular
    .module('app.folders', dependencies)
    .directive('folderShow', downgradeComponent({ component: FolderShowComponent }))
    .directive('destroyFolder', downgradeComponent({ component: DestroyFolderComponent }))
    .directive('moveComponent', downgradeComponent({ component: MoveComponent }))
    .directive('folderDownload', downgradeComponent({ component: FolderDownloadComponent }) as ng.IDirectiveFactory)
    .directive('folderForm', downgradeComponent({ component: FolderFormComponent }) as ng.IDirectiveFactory)
    .directive('shortcutsImport', downgradeComponent({ component: ShortcutsImportComponent }) as ng.IDirectiveFactory)
    .config(foldersRoute);

export default foldersModule;
