import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { ReportsTableComponent } from './components/reports-table/reports-table.component';
import { ReportsTableService } from './components/reports-table/reports-table.service';
import { ReportsIndexStateService } from './reports-index-state.service';
import reportsRoute from './reports.route';
import { ObjectPickerComponent } from './components/object-picker/object-picker.component';
import { ChooseBindersFoldersComponent } from './components/choose-binders-folders/choose-binders-folders.component';
import { ReportService } from './report.service';
import { ReportsIndexComponent } from './containers/reports-index/reports-index.component';

const dependencies = [uiRouter];

const reports = angular
    .module('reports', dependencies)
    .config(reportsRoute)
    .factory('ReportsTable', downgradeInjectable(ReportsTableService))
    .factory('ReportsIndexState', downgradeInjectable(ReportsIndexStateService))
    .directive('reportsIndex', downgradeComponent({ component: ReportsIndexComponent }) as ng.IDirectiveFactory)
    .directive('reportsTable', downgradeComponent({ component: ReportsTableComponent }) as ng.IDirectiveFactory)
    .factory('ReportService', downgradeInjectable(ReportService))
    .directive('objectPicker', downgradeComponent({ component: ObjectPickerComponent }) as ng.IDirectiveFactory)
    .directive('chooseBindersFolders', downgradeComponent({ component: ChooseBindersFoldersComponent }) as ng.IDirectiveFactory);

export default reports;
