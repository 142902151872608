import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import template from './missing-permissions.html';
import style from './missing-permissions.scss';
import { MissingPermission, MissingPermissionSaveEvent } from './missing-permissions.types';

@Component({
    template,
    styles: [String(style)]
})
export class MissingPermissionsComponent implements OnInit {
    @Output() dismiss = new EventEmitter<void>();
    @Output() onSave = new EventEmitter<MissingPermissionSaveEvent>();
    @Input() missingPermissions: MissingPermission[];
    @Input() activeSubjectName: string;

    modalTitle: string;
    modalMessageInfo: string;
    modalMessageQuestion: string;
    isProcessing = false;

    ngOnInit(): void {
        this.modalTitle = 'Extra Permission(s) Needed';
        this.modalMessageInfo = `To save these permissions, the following permission(s) must also be granted to ${this.activeSubjectName}:`;
        this.modalMessageQuestion = 'Would you like to include the above permission(s)?';
    }

    cancel(): void {
        this.dismiss.emit();
    }

    save(): void {
        this.isProcessing = true;
        this.onSave.emit({
            onSave: () => this.cancel(),
            onError: () => {
                this.isProcessing = false;
            }
        });
    }
}
