import * as angular from 'angular';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { SessionsActivityShowComponent } from './containers/session-activity-show/sessions-activity-show.component';
import { SessionActivityDetailsComponent } from './components/session-activity-details/session-activity-details.component';
import sessionActivityRoute from './sessions.route';
import { SessionActivityService } from './session-activity.service';

const sessionsActivityShow = angular
    .module('app.sessions', [])
    .directive('sessionsActivityShow', downgradeComponent({ component: SessionsActivityShowComponent }) as ng.IDirectiveFactory)
    .directive('sessionActivityDetails', downgradeComponent({ component: SessionActivityDetailsComponent }) as ng.IDirectiveFactory)
    .factory('SessionActivity', downgradeInjectable(SessionActivityService))
    .config(sessionActivityRoute);

export default sessionsActivityShow;
