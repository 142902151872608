import { Component, Input } from '@angular/core';
import {
    Document,
    SendToSipSiteInput,
    SendToSipDocVersionInput
} from '@app/shared/models';
import { take } from 'rxjs/operators';
import { DocumentService } from '@app/shared/documents/document.service';
import { ModalsService } from '../../../../shared/modal-helper/modals.service';
import { DocumentExportModalComponent } from '../document-export/document-export.component';
import template from './document-send.component.html';

@Component({
    selector: 'document-send',
    template
})
export class DocumentSendComponent {
    @Input() doc: Document;
    @Input() sites: SendToSipSiteInput[];

    docVersions: SendToSipDocVersionInput[];
    hasMultipleSites: boolean;
    isDocumentLocked = false;

    constructor(
        private Modals: ModalsService,
        private DocumentService: DocumentService
    ) {}

    ngOnInit(): void {
        this.isDocumentLocked = this.doc.isLocked;
        this.getDocVersions();
    }

    openSipExportModal(): void {
        const initialState = {
            sites: this.sites,
            hasMultipleSites: this.sites.length > 1,
            versions: this.docVersions,
            document: this.doc
        };
        const exportModalRef = this.Modals.show(DocumentExportModalComponent, {
            initialState
        });

        exportModalRef.content.export.pipe(take(1)).subscribe(async (value) => {
            await this.DocumentService.sendToSip({
                ...value,
                docId: this.getDocumentId()
            }).subscribe();
            exportModalRef.hide();
        });
    }

    private getDocumentId(): string {
        return typeof this.doc.id !== 'string'
            ? this.doc.id.documentId
            : this.doc.id;
    }

    private getDocVersions(): void {
        this.docVersions = this.doc.versions.reduce(
            (nonPiiVersions, currVersion) => {
                if (!currVersion.hasPii) {
                    nonPiiVersions.push({
                        number: currVersion.number,
                        ...(currVersion.number === this.doc.version
                            ? {
                                name: `Version ${currVersion.number} (current)`,
                                isLatest: true
                            }
                            : {
                                name: `Version ${currVersion.number}`,
                                isLatest: false
                            })
                    });
                }
                return nonPiiVersions;
            },
            []
        );
    }
}
