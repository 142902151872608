import {
    Component,
    EventEmitter,
    OnInit,
    Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './document-decline-qc-review.component.html';
import styles from './document-decline-qc-review.component.scss';

@Component({
    selector: 'document-decline-qc-review',
    template,
    styles: [String(styles)]

})
export class DocumentDeclineQcReviewComponent implements OnInit {
    reason = '';
    @Output() decline = new EventEmitter<{reason: string}>();
    @Output() close = new EventEmitter<void>();

    form: FormGroup;
    isProcessing: boolean;

    constructor(
        private fb: FormBuilder,
        public modal: BsModalRef
    ) {}

    ngOnInit(): void {
        this.initForm();
    }

    cancel(): void {
        this.modal.hide();
        this.close.emit();
    }

    declineQcReview(): void {
        if (this.isProcessing) {
            return;
        }

        this.isProcessing = true;
        const reason = this.form.controls.reason && this.form.controls.reason.value;
        this.decline.emit({ reason });
    }

    private initForm(): void {
        this.form = this.fb.group({
            reason: ['', [Validators.required, notBlank]]
        });
    }
}
