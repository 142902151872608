import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import { downgradeComponent } from '@angular/upgrade/static';

import bindersRoute from './binders.route';
import { BinderFormComponent } from './components/binder-form/binder-form.component';
import { BinderDownloadComponent } from './containers/binder-download/binder-download.component';
import { DestroyBinderComponent } from './components/destroy-binder/destroy-binder.component';
import { BindersIndexComponent } from './containers/binders-index/binders-index.component';

const dependencies = [
    uiRouter
];

const bindersModule = angular
    .module('app.binders', dependencies)
    .directive('binderIndex', downgradeComponent({ component: BindersIndexComponent }) as ng.IDirectiveFactory)
    .directive('binderDownload', downgradeComponent({ component: BinderDownloadComponent }) as ng.IDirectiveFactory)
    .directive('binderForm', downgradeComponent({ component: BinderFormComponent }) as ng.IDirectiveFactory)
    .directive('destroyBinder', downgradeComponent({ component: DestroyBinderComponent }) as ng.IDirectiveFactory)
    .config(bindersRoute);

export default bindersModule;
