import { AuditTrailSubject } from '@app/shared/models';

export interface GetAuditsParams {
    teamId: string;
    objectId: string;
    limitToOverwritten: boolean;
    overwrittenObjectId?: string;
    pageSize: number;
    next?: string;
    sortBy: AuditTrailSortField;
    sortDir: 'ASC' | 'DESC';
    subject: AuditTrailSubject;
    filter?: {
        type: string;
        value: string;
    };
}

export enum AuditTrailFilterField {
    EMAIL = 'email',
    EVENT_TYPE = 'eventType',
    FRIENDLY_NAME = 'friendlyName'
}

export enum AuditTrailSortField {
    TIMESTAMP = 'timestamp',
    EMAIL = 'email',
    EVENT_TYPE = 'eventType'
}
export type RequestData = {
    url: string;
    params: Partial<GetAuditsParams>;
}
