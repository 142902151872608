'use strict';

const DEFAULT_PER_PAGE_SIZE = 10;
const MAX_PER_PAGE_SIZE = 50;
const DEFAULT_PAGE_NUM = 1;

module.exports = {
    DEFAULT_PER_PAGE_SIZE,
    MAX_PER_PAGE_SIZE,
    DEFAULT_PAGE_NUM
};
