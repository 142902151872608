import { Tag } from '@app/shared/models';
import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import {
    FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { notBlank } from '@app/core/form-validators';
import * as _ from 'lodash';
import { REGEX } from '@app/core/constants';
import template from './update-tag.component.html';
import { TagUpdateEvent } from './update-tag.component.types';
@Component({
    selector: 'update-tag',
    template
})
export class UpdateTagComponent implements OnInit {
    @Input() tag: Tag;
    @Output() onUpdate= new EventEmitter<TagUpdateEvent>();
    tagClone: Tag;
    updateTagForm: FormGroup;
    isProcessing: boolean;
    constructor(
        private fb: FormBuilder,
        private modal: BsModalRef
    ) {
        this.isProcessing = false;
    }

    ngOnInit(): void {
        this.tagClone = _.cloneDeep(this.tag);
        this.initForm();
    }

    cancel(): void {
        this.modal.hide();
    }

    save(): void {
        if (this.updateTagForm.invalid) {
            return;
        }
        this.isProcessing = true;
        this.tag.name = this.updateTagForm.value.name.trim();
        this.tag.description = this.updateTagForm.value.description;

        this.onUpdate.emit({
            tag: this.tag,
            onSuccess: () => {
                this.modal.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        });
    }

    private initForm(): void {
        this.updateTagForm = this.fb.group({
            name: [this.tagClone.name, [Validators.required, Validators.pattern(REGEX.tagName), notBlank]],
            description: [this.tagClone.description || '', [Validators.maxLength(250)]]
        });
    }
}
