import * as _ from 'lodash';
import { REGEX } from '@app/core/constants';
import {
    Component, EventEmitter, OnInit, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
    FormBuilder, FormControl, FormGroup, Validators
} from '@angular/forms';
import { TeamService } from '@app/shared/teams/team.service';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material';
import { AddUsersEvent } from './add-users.component.types';

import template from './add-users.component.html';
import styles from './add-users.component.scss';

@Component({
    template,
    styles: [String(styles)]
})

export class AddUsersComponent implements OnInit {

    @Output() onAddUser = new EventEmitter<AddUsersEvent>();
    isProcessing: boolean;
    hasErrors: boolean;
    sendNotifications: boolean;
    pendingInvites: {text: string}[];
    emailRegex: string;
    addUsersForm: FormGroup;
    readonly maxEmails = 100;
    readonly baseSeparator = ',';
    readonly separatorsPattern = REGEX.listSeparators;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
    selectable = true;
    removable = true;
    addOnBlur = true;
    emailInputControl = new FormControl('');

    constructor(
        private Team: TeamService,
        private modal: BsModalRef,
        private fb: FormBuilder
    ) {
        let emailRegex = REGEX.email.toString();
        emailRegex = emailRegex.slice(emailRegex.indexOf('/') + 1, emailRegex.lastIndexOf('/'));
        this.emailRegex = emailRegex;

        this.isProcessing = false;
        this.pendingInvites = [];
        this.hasErrors = false;
        this.sendNotifications = true;
    }

    _emailAlreadyAdded(email: string) {
        return !!_.find(this.pendingInvites, { text: email });
    }

    toggleSendNotifications() {
        this.sendNotifications = !this.sendNotifications;
    }

    displayError() {
        this.hasErrors = true;
    }

    removeError() {
        this.hasErrors = false;
    }

    hasInvalidTags() {
        return this.hasErrors && this.addUsersForm.get('emailInput').value.length > 0;
    }

    hasDuplicatedTags() {
        return this.hasInvalidTags() && this._emailAlreadyAdded(this.addUsersForm.get('emailInput').value);
    }

    isSendDisabled() {
        const emails = this.addUsersForm.get('emails').value.split(',');
        return !this.pendingInvites.length || this.isProcessing || this.hasInvalidTags() || emails.length > 100;
    }

    divideEmailTags(event: ClipboardEvent) {
        event.preventDefault();
        const data = event.clipboardData ? event.clipboardData.getData('text/plain') : '';
        const pastingString = data.replace(/\s+/g, ' ').replace(this.separatorsPattern, this.baseSeparator);

        const emails = pastingString.split(this.baseSeparator);
        _.each(emails, (email) => {
            email = email.trim();
            if (email.length > 0 && !this._emailAlreadyAdded(email) && REGEX.email.test(email)) {
                this.pendingInvites.push({ text: email });
                this.updateForm();
            }
        });
    }

    cancel(): void {
        this.modal.hide();
    }

    send() {
        const emailsToInvite = _.map(this.pendingInvites, (invite) => invite.text);
        this.isProcessing = true;

        this.Team.inviteUsersToTeam(emailsToInvite, this.sendNotifications)
            .subscribe(
                () => {
                    this.emitEvent(true);
                    this.modal.hide();
                },
                () => {
                    this.emitEvent(false);
                    this.isProcessing = false;
                }
            );
    }

    private emitEvent(success: boolean) {
        this.onAddUser.emit({
            success,
            onSuccess: () => {
                this.modal.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        });
    }


    add(event: MatChipInputEvent): void {
        const { input } = event;
        const { value } = event;


        if ((value || '').trim() && REGEX.email.test(value) && !this._emailAlreadyAdded(this.addUsersForm.get('emailInput').value)) {

            this.pendingInvites.push({ text: value.trim() });
            this.updateForm();
            this.removeError();
        }
        else {
            this.displayError();
        }

        if (input && REGEX.email.test(value) && !this._emailAlreadyAdded(this.addUsersForm.get('emailInput').value)) {
            input.value = '';
            this.emailInputControl.setValue('');
        }
    }

    remove(email: { text: string }): void {
        const index = this.pendingInvites.indexOf(email);

        if (index >= 0) {
            this.pendingInvites.splice(index, 1);
            this.addUsersForm.get('emails').setValue(this.pendingInvites.map((invite) => invite.text).join(this.baseSeparator));
            this.removeError();
        }
    }

    ngOnInit() {
        this.addUsersForm = this.fb.group({
            emails: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
            emailInput: ['', Validators.pattern(this.emailRegex)]
        });
        this.addUsersForm.valueChanges.subscribe(() => {
            this.validateForm();
        });
    }

    validateForm() {
        const emails = this.addUsersForm.get('emails').value.split(this.baseSeparator).map((email) => email.trim());
        this.pendingInvites = emails.map((email) => ({ text: email })).filter((invite) => invite.text);
        if (!this.addUsersForm.get('emailInput').value) {
            this.removeError();
        }
    }

    updateForm() {
        this.addUsersForm.get('emails').setValue(this.pendingInvites.map((invite) => invite.text).join(this.baseSeparator));
        this.addUsersForm.get('emailInput').setValue('');
    }


}
