import * as _ from 'lodash';
import { StateService } from '@uirouter/angular';
import {
    Crumb,
    Team,
    Timeline,
    Project,
    ApiError
} from '@app/shared/models';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { Component, OnInit } from '@angular/core';
import { TeamService } from '@app/shared/teams/team.service';
import { ProjectsService } from '@app/shared/projects/projects.service';
import { SORT } from '@app/core/constants';
import { CurrentSessionService } from '@app/core/current-session.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { forkJoin } from 'rxjs';
import template from './projects-detail-page.component.html';
import { ProjectsDetailPageTimeline } from './projects-detail-page.types.component';
import { CreateTimelineModalComponent } from '../../compontents/create-timeline-modal/create-timeline-modal.component';
import { TimelineCreateEvent } from '../../compontents/create-timeline-modal/create-timeline-modal.component.types';
import { DestroyProjectOrTimelineComponent } from '../../compontents/destroy-project/destroy-project-or-timeline.component';
import { DestroyProjectOrTimelineType, ProjectOrTimelineDeleteEvent } from '../../compontents/destroy-project/destroy-project-or-timeline.component.types';
import { DuplicateTimelineComponent } from '../../compontents/duplicate-timeline-modal/duplicate-timeline-modal.component';
import { DuplicateTimelineEvent } from '../../compontents/duplicate-timeline-modal/duplicate-timeline-modal.types';

@Component({
    selector: 'projects-detail-page',
    template
})
export class ProjectsDetailPageComponent implements OnInit {
    crumbs: Crumb[] = [];
    project: Project;
    timelines: ProjectsDetailPageTimeline[];
    selectedTimeline: ProjectsDetailPageTimeline;
    currentTeam: Team;
    teamId: string;
    filter: string;
    loadingData = false;
    openDropDown: string;

    SORT = SORT;

    constructor(
        private $state: StateService,
        private Teams: TeamService,
        private Projects: ProjectsService,
        private CurrentSession: CurrentSessionService,
        private Notifications: NotificationsService,
        private Modals: ModalsService
    ) {
    }

    ngOnInit(): void {
        const stateParams = this.$state.params;
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.teamId = this.currentTeam.id;
        if (!this.canView()) {
            this.$state.go('app.select-team');
        }

        this.loadingData = true;
        forkJoin({
            project: this.Projects.getProject(this.teamId, stateParams.projectId),
            timelines: this.Projects.getProjectTimelines(this.teamId, stateParams.projectId)
        }).subscribe(
            (data) => {
                this.loadingData = false;
                this.project = data.project;
                this.timelines = data.timelines.map((timeline: Timeline) => {
                    return { ...timeline, href: this.getTimelineHref(timeline) };
                });

                this.crumbs = this._getCrumbs();
                this.updateSort('name', false);
            },
            ({ error }: ApiError) => {
                this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
            }
        );
    }

    select(timeline: ProjectsDetailPageTimeline): void {
        if (timeline.selected) {
            timeline.selected = false;
            this.selectedTimeline = undefined;
        }
        else {
            this.timelines.forEach((t) => {
                t.selected = false;
            });
            timeline.selected = true;
            this.selectedTimeline = timeline;
        }
    }

    canCreate(): boolean {
        return this.currentTeam.permissions.createTimeline;
    }

    create(): void {
        if (!this.canCreate()) {
            return;
        }

        const timelineCreateModal = this.Modals.show(CreateTimelineModalComponent, {
            animated: true,
            class: 'modal-md',
            initialState: {
                project: this.project
            }
        });

        timelineCreateModal.content.onCreate.subscribe((event: TimelineCreateEvent) => {
            this.Projects.createTimeline(this.teamId, event.timeline)
                .subscribe(
                    (timeline) => {
                        event.onSuccess();
                        this.Notifications.success(`Timeline "${event.timeline.name}" created!`);
                        this.navigateToTimeline(timeline);
                    },
                    ({ error }: ApiError) => {
                        event.onError();
                        this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                    }
                );
        });
    }


    edit(): void {
        if (this.canEdit()) {
            this.navigateToTimeline(this.selectedTimeline);
        }
    }

    canEdit(): boolean {
        return this.selectedTimeline && this.currentTeam.permissions.updateTimeline;
    }

    navigateToTimeline(timeline: Timeline): void {
        this.$state.go('app.team.timeline-update', {
            teamId: timeline.teamId,
            projectId: timeline.projectId,
            timelineId: timeline.id
        });
    }

    canView(): boolean {
        return this.Teams.canViewProjectsAndTimelines();
    }

    remove(): void {
        if (this.canRemove()) {

            const destroyTimelineModal = this.Modals.show(DestroyProjectOrTimelineComponent, {
                class: 'modal-md',
                initialState: {
                    name: this.selectedTimeline.name,
                    type: DestroyProjectOrTimelineType.TIMELINE
                }
            });

            destroyTimelineModal.content.onDestroy.subscribe((event: ProjectOrTimelineDeleteEvent) => {
                this.Projects.deleteTimeline(this.teamId, this.selectedTimeline)
                    .subscribe(
                        () => {
                            event.onSuccess();
                            this.Notifications.success(`Timeline "${this.selectedTimeline.name}" Deleted!`);
                            this.timelines = _.pull(this.timelines, this.selectedTimeline);
                            this.selectedTimeline = undefined;
                        },
                        ({ error }: ApiError) => {
                            event.onError();
                            this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                        }
                    );
            });
        }
    }

    canRemove(): boolean {
        return this.selectedTimeline && this.currentTeam.permissions.deleteTimelines;
    }

    duplicate(): void {
        if (!this.canDuplicate()) {
            return;
        }

        this.Projects.getAllProjects(this.teamId)
            .subscribe(
                (projects) => {

                    const duplicateModal = this.Modals.show(DuplicateTimelineComponent, {
                        animated: true,
                        class: 'modal-md',
                        initialState: {
                            timeline: _.cloneDeep(this.selectedTimeline),
                            allProjects: projects
                        }
                    });

                    duplicateModal.content.onDuplicate.subscribe(
                        (duplacetEvent: DuplicateTimelineEvent) => {
                            const { timeline, onSuccess, onError } = duplacetEvent;
                            this.Projects.duplicateTimeline(this.teamId, timeline)
                                .subscribe(
                                    (duplicatedTimeline) => {
                                        const msg = 'Successfully created new timeline <%= timeline.name %>.<br>'
                                            + '<strong><a href="<%= urlRoute %>">GO TO TIMELINE</a></strong>';
                                        const msgTemplate = _.template(msg);
                                        const urlRoute = this.$state.href('app.team.manage-project-timeline', {
                                            teamId: duplicatedTimeline.teamId,
                                            projectId: duplicatedTimeline.projectId,
                                            timelineId: duplicatedTimeline.id
                                        });
                                        const params = {
                                            timeline: duplicatedTimeline,
                                            urlRoute
                                        };
                                        onSuccess();

                                        this.Notifications.success(msgTemplate(params));
                                        this.$state.reload();
                                    },
                                    ({ error }: ApiError) => {
                                        this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                                        onError();
                                    }
                                );
                        }
                    );
                },
                ({ error }: ApiError) => {
                    this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                }
            );
    }

    canDuplicate(): boolean {
        return this.selectedTimeline && this.currentTeam.permissions.duplicateTimelines;
    }


    _getCrumbs(): Crumb[] {
        return [
            {
                name: 'Manage Projects',
                stateName: 'app.team.manage-projects',
                stateParams: { teamId: this.teamId }
            },
            {
                name: this.project.name
            }
        ];
    }

    updateSort(sortName: string, isReversed: boolean): void {
        this.SORT.set(sortName, isReversed);
        const sortedtimelines = this.timelines.sort((a, b) => {
            return this.SORT.naturalSort({ value: a[sortName] }, { value: b[sortName] });
        });
        if (this.SORT.isReversed) {
            sortedtimelines.reverse();
        }
        this.timelines = [...sortedtimelines];
    }

    canActOnSelection(): boolean {
        return this.selectedTimeline
            && (this.canRemove()
                || this.canDuplicate()
                || this.canEdit()
                || this.canView());
    }

    toggleActions($event: PointerEvent, timeline: ProjectsDetailPageTimeline): void {
        $event.preventDefault();
        if (this.selectedTimeline !== timeline) {
            this.select(timeline);
            this.openDropDown = timeline.id;
        }
        this.openDropDown = undefined;
    }

    updateFilter(text: string): void {
        this.filter = text;
    }

    private getTimelineHref(timeline: Timeline): string {
        if (!timeline || !this.canView()) {
            return;
        }

        return this.$state.href('app.team.manage-project-timeline', {
            teamId: this.teamId,
            projectId: timeline.projectId,
            timelineId: timeline.id
        });
    }

    goToTimelineDetails(timeline: Timeline): string {
        if (!timeline || !this.canView()) {
            return;
        }

        this.$state.go('app.team.manage-project-timeline', {
            teamId: this.teamId,
            projectId: timeline.projectId,
            timelineId: timeline.id
        });
    }
}
