import {
    Component,
    Output,
    EventEmitter,
    Input
} from '@angular/core';

import { CurrentSessionService } from '@app/core/current-session.service';
import { Team } from '@app/shared/models';

import template from './need-help.component.html';
@Component({
    selector: 'need-help',
    template
})
export class NeedHelpComponent {
    @Output() dismiss = new EventEmitter<void>();

    public supportEmail: string;
    public supportPhone: string;
    public displayWithPhone: boolean;
    private team: Team;
    private readonly zendeskUrl = 'https://florencehealthcare.zendesk.com';

    constructor(
        private CurrentSession: CurrentSessionService
    ) {}

    ngOnInit(): void {
        this.team = this.CurrentSession.getCurrentTeam();
        this.supportEmail = this.team.settings.support && this.team.settings.support.email;
        this.supportPhone = this.team.settings.support && this.team.settings.support.phone;
    }

    dismissModal(): void {
        this.dismiss.emit();
    }
}
