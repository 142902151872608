import {
    Component, Input, OnDestroy, OnInit, Output, EventEmitter
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';

import { Document } from '@app/shared/models';
import { QCReviewService } from '@app/components/documents/qc-review.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { Subject } from 'rxjs';
import { AddQcReviewEvent, ChangeReviewerQcReviewEvent, ReviewCycle } from '@app/components/documents/qc-review.services.types';
import { qcReviewStatuses } from '@florencehealthcare/florence-constants/lib/qc-reviews';
import template from './add-change-qc-review.component.html';
import styles from './add-change-qc-review.component.scss';
import { DecoratedUser } from '../document-request-qc-review/document-request-qc-review.component.types';

@Component({
    selector: 'add-qc-review',
    template,
    styles: [String(styles)]

})
export class AddChangeQcReviewComponent implements OnInit, OnDestroy {
    @Input() entity: Document;
    @Input() documentVersion: number;
    @Input() userId: string;
    @Input() reviewCycle: ReviewCycle;
    @Input() index: number;

    private readonly destroy$ = new Subject<void>();
    loadingUsers = false;
    filteredUsers: DecoratedUser[];
    currentFilter = '';
    usersHash: DecoratedUser[];
    reviewNumber: number;
    isChange = false;
    initiallySelectedUser = [];

    isProcessing = false;
    @Output() addQcReview = new EventEmitter<AddQcReviewEvent>();
    @Output() changeReviewerQcReview = new EventEmitter<ChangeReviewerQcReviewEvent>();
    reviewGroup: FormGroup;
    constructor(
        public modal: BsModalRef,
        private QcReviewService: QCReviewService,
        private Notifications: NotificationsService
    ) {}


    ngOnInit(): void {
        this.loadUsers('');
        this.initForm();
        this.reviewNumber = this.entity.qcReview.reviews.length + 1;
        this.isChange = !!this.reviewCycle;
        this.initiallySelectedUser = this.reviewCycle
            ? this.setInitiaSelectedUser() : [];
    }

    setInitiaSelectedUser() {
        if (this.reviewCycle.reviewerIds.id === this.userId) {
            return [{ ...this.reviewCycle.reviewerIds, displayName: `${this.checkDisplayName()} (Assign to yourself)` }];
        }
        return [{ ...this.reviewCycle.reviewerIds, displayName: this.checkDisplayName() }];
    }

    checkDisplayName() {
        return this.reviewCycle.reviewerIds.fullName ? this.reviewCycle.reviewerIds.fullName : this.reviewCycle.reviewerIds.email;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }


    initForm(): void {
        this.reviewGroup = new FormGroup({
            reviewer: new FormControl('', Validators.required),
            comment: new FormControl('', [Validators.required, notBlank])
        });
    }

    loadUsers(filter: string, index?: number): void {
        if (filter !== '' && index < 0) {
            return;
        }
        this.loadingUsers = true;
        const params = {
            documentId: typeof this.entity.id === 'string' ? this.entity.id : this.entity.id.documentId,
            documentVersion: this.documentVersion
        };
        this.QcReviewService.getPotentialQCReviewers(params)
            .subscribe(
                (data) => {
                    const decoratedUsers = data.map((u) => ({ ...u, displayName: u.name }));
                    const usersWithCurrentOnTop = this.setCurrentUserOnTop(decoratedUsers);
                    this.currentFilter = filter;
                    this.loadingUsers = false;
                    this.usersHash = [...usersWithCurrentOnTop];
                    if (filter === '') {
                        this.filteredUsers = this.usersHash;
                    }
                    if (this.initiallySelectedUser
                        && this.reviewCycle
                        && this.reviewCycle.status === qcReviewStatuses.DECLINED) {
                        this.reviewGroup.get('reviewer').setValue(this.usersHash.find(((user) => user.email === this.initiallySelectedUser[0].email)));
                    }
                },
                (error) => {
                    this.loadingUsers = false;
                    if (error.error && error.error.message) {
                        this.Notifications.error(error.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                }
            );
    }

    filterUsers(filter: string): void {
        if (!filter) {
            this.usersHash = this.filteredUsers;
            return;
        }
        const filterCanonical = filter.trim().toLowerCase();
        this.usersHash = this.filteredUsers.filter((t) => t.name.toLowerCase().includes(filterCanonical));
    }

    private setCurrentUserOnTop(users: DecoratedUser[]): DecoratedUser[] {
        let usersClone = [...users];
        const currentUserIndex = usersClone.findIndex((element) => element.id === this.userId);
        if (currentUserIndex > -1) {
            const [currentUser] = usersClone.splice(currentUserIndex, 1);
            usersClone = [
                { ...currentUser, displayName: `${currentUser.fullName} (Assign to yourself)` },
                ...usersClone
            ];
        }
        return usersClone;
    }


    canSubmit(): boolean {
        return this.reviewGroup.valid;
    }

    onSelectionCleared(): void {
        this.reviewGroup.controls.reviewer.setValue('');
    }

    onUserSelect(event: FilteredSelectEvent<DecoratedUser>): void {
        this.reviewGroup.controls.reviewer.setValue(event.added[0]);
        this.usersHash = [{ ...event.added[0] }];
    }

    onSave(): void {
        const review = {
            reviewerIds: [this.reviewGroup.value.reviewer._id],
            comment: this.reviewGroup.value.comment
        };
        this.isProcessing = true;
        if (this.isChange) {
            const cycleId = this.reviewCycle.id;
            this.changeReviewerQcReview.emit({
                review,
                cycleId,
                onError: () => {
                    this.isProcessing = false;
                },
                onSuccess: () => {
                    this.isProcessing = false;
                    this.modal.hide();
                }
            });
            return;
        }

        this.addQcReview.emit({
            review,
            onError: () => {
                this.isProcessing = false;
            },
            onSuccess: () => {
                this.isProcessing = false;
                this.modal.hide();
            }
        });
    }

    dismissModal(): void {
        this.modal.hide();
    }
}
