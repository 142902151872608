import { DocumentProject } from '../models';
import { GetTimelinesResponseItem } from '../projects/projects.service.types';

/**
 * Converts timelines array to projects array with grouping timelines by project
 * Assumes that timeline.name starts with project name followed by "/" and actual timeline name
 */
export const mapTimelinesToDocumentProjects = (timelines: GetTimelinesResponseItem[]): DocumentProject[] => {
    const projectsHash: { [key: string]: DocumentProject } = {};

    timelines.forEach((timeline) => {
        const { projectId } = timeline;
        const [projectName, timelineName] = timeline.name.split('/');

        const project = projectsHash[projectId];
        if (!project) {
            projectsHash[projectId] = {
                projectId,
                projectName,
                timelines: [{
                    timelineId: timeline.id,
                    timelineName
                }]
            };
        }
        else {
            projectsHash[projectId].timelines = [
                ...projectsHash[projectId].timelines,
                {
                    timelineId: timeline.id,
                    timelineName
                }
            ];
        }
    });
    return Object.values(projectsHash);
};
