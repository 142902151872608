import { Pipe } from '@angular/core';
import { LogTemplateUtil } from '../utils/log-template.util';

@Pipe({
    name: 'numberOrLetterIdentifier'
})
export class NumberOrLetterIdentifierPipe {
    transform(
        index: number,
        isNumberIdentifier: boolean
    ): string {
        return LogTemplateUtil.getNumberOrLetterIdentifier(index, isNumberIdentifier);
    }
}
