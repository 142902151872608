import {
    BrowseNode,
    browseNodeIsDocument,
    BrowseTree,
    BrowseFolder,
    BrowseDocument
} from '@app/shared/models';
import { sortByLexicographically } from './sort-by-lexicographically.util';

const lexSortBrowseNodeItems = (nodeItems: BrowseNode[], docNameProp: keyof BrowseDocument): BrowseNode[] => {
    let documents: BrowseDocument[] = [];
    let folders: BrowseFolder[] = [];
    nodeItems.forEach((item) => {
        if (browseNodeIsDocument(item)) {
            documents.push(item);
        }
        else {
            folders.push(item);
        }
    });
    documents = sortByLexicographically(documents, docNameProp);
    folders = sortByLexicographically(folders, 'name');
    return [...folders, ...documents];
};

const lexSortBrowseNode = (node: BrowseNode, docNameProp: 'name' | 'title'): BrowseNode => {
    if (browseNodeIsDocument(node)) {
        return node;
    }
    if (!(node.items && node.items.length)) {
        return node;
    }
    node.items = lexSortBrowseNodeItems(node.items, docNameProp)
        .map((nodeItem) => lexSortBrowseNode(nodeItem, docNameProp));
    return node;
};

export const sortBrowseTreeLexicographically = (tree: BrowseTree, docNameProp: 'name' | 'title'): BrowseTree => {
    if (tree && tree.items && tree.items.length) {
        tree.items = lexSortBrowseNodeItems(tree.items, docNameProp)
            .map((node) => lexSortBrowseNode(node, docNameProp));
    }
    return tree;
};
