import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@app/shared/shared.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { FoldersService } from '@app/shared/folders/folders.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EntityModule } from '@app/widgets/entity/entity.module';
import { FolderizerComponent } from './folderizer.component';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        WidgetsModule,
        ReactiveFormsModule,
        FormsModule,
        EntityModule
    ],
    providers: [
        FoldersService,
        BsModalRef
    ],
    declarations: [
        FolderizerComponent
    ],
    entryComponents: [
        FolderizerComponent
    ],
    exports: [
        FolderizerComponent
    ]
})
export class FolderizerModule {}
