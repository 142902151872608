'use strict';

exports.downloads = {
    statuses: {
        PENDING: 'Pending',
        COMPLETE: 'Complete',
        DOWNLOADED: 'Downloaded',
        FAILED: 'Failed'
    },
    types: {
        AUDIT_TRAIL: 'Audit Trail',
        ACCESS_DATES_REPORT: 'Access Dates Report',
        REPORT: 'Report',
        DOCUMENT: 'Document',
        FOLDER: 'Folder',
        BINDER: 'Binder',
        SHORTCUT: 'Shortcut',
        PLACEHOLDER: 'Placeholder',
        LOG: 'Log',
        MULTIPLE_DOCUMENTS: 'Multiple Documents',
        MULTIPLE_FOLDERS: 'Multiple Folders',
        MULTIPLE_PLACEHOLDERS: 'Multiple Placeholders',
        MULTIPLE_SHORTCUTS: 'Multiple Shortcuts',
        MULTIPLE_LOGS: 'Multiple Logs',
        FILE: 'File',
        STUDY_STRUCTURE_TEMPLATE: 'Study Structure Template',
        ARCHIVE: 'Archive',
        LOG_IMPORT_ROWS_TEMPLATE: 'Log Import Rows Template'
    }
};

exports.downloadItems = {
    types: {
        DOCUMENT: 'document',
        SHORTCUT: 'shortcut',
        PLACEHOLDER: 'placeholder',
        LOG: 'log'
    }
};
