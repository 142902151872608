import { Component, Input, OnInit } from '@angular/core';
import { Document } from '@app/shared/models';
import { TABLE_CELL_TYPES } from '@app/core/constants';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { styles } from './shortcut-details.scss';
import template from './shortcut-details.component.html';

@Component({
    template,
    styles: [String(styles)]
})
export class ShortcutDetailsComponent implements OnInit {
    @Input() document: Document;
    @Input() shortcuts: Document[];
    tableConfig = [];

    constructor(public Modal: BsModalRef) {}
    ngOnInit(): void {
        this.tableConfig = [{
            renderType: TABLE_CELL_TYPES.type,
            headerName: '',
            displayProperty: null,
            widthValue: 1
        },
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: 'name',
            isSortable: true,
            widthValue: 9,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Location',
            displayProperty: 'location',
            isSortable: true,
            widthValue: 9,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Last Modified',
            displayProperty: 'updatedAt',
            isSortable: true,
            widthValue: 3
        }];
    }

    cancel(): void {
        this.Modal.hide();
    }

    onNameCellClick(): void {
        this.cancel();
    }
}
