import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    SendToSipDocVersionInput,
    SendToSipSiteInput
} from '@app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './document-export.html';

@Component({
    selector: 'document-export',
    template
})
export class DocumentExportModalComponent {
    @Input() sites: SendToSipSiteInput[];
    @Input() versions: SendToSipDocVersionInput[];
    @Input() hasMultipleSites: boolean;
    @Output() export = new EventEmitter<{
        studyId: string;
        siteId: string;
        version: number;
    }>();

    docExportForm: FormGroup;

    constructor(public modal: BsModalRef, private fb: FormBuilder) {}

    ngOnInit(): void {
        this.docExportForm = this.fb.group({
            site: [{ value: '', disabled: this.sites.length === 1 }, [Validators.required]],
            docVersion: ['', [Validators.required]]
        });
        this.initializeForm(this.sites, this.versions);
    }

    sendToSip(): void {
        if (this.docExportForm.valid) {
            this.export.emit({
                studyId: this.docExportForm.controls.site.value.studyId,
                siteId: this.docExportForm.controls.site.value.siteId,
                version: this.docExportForm.controls.docVersion.value
            });
        }
    }

    private initializeForm(sites: SendToSipSiteInput[], versions: SendToSipDocVersionInput[]): void {
        if (sites && sites.length) {
            this.docExportForm.patchValue({ site: sites[0] });
        }
        if (versions && versions.length) {
            this.docExportForm.patchValue({
                docVersion: versions.find((v) => v.isLatest).number
            });
        }
    }
}
