import {
    Component, EventEmitter, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import {
    FormBuilder, FormControl, FormGroup, Validators
} from '@angular/forms';
import {
    Observable, Observer, Subscription, of
} from 'rxjs';
import {
    switchMap
} from 'rxjs/operators';
import { PaywallIntegration } from '@app/shared/models';
import template from './monitor-review-integration-form.component.html';
import styles from './monitor-review-integration-form.component.scss';
import { FormResponse, FormStructureField, UpdatedFormResponse } from './monitor-review-integration-form.component.types';

@Component({
    selector: 'monitor-review-integration-form',
    template,
    styles: [String(styles)]
})

export class MonitorReviewIntegrationFormComponent implements OnInit, OnDestroy {
    @Input() integration: PaywallIntegration;
    @Input() formResponses: FormResponse;
    @Output() formResponsesChange = new EventEmitter<UpdatedFormResponse>();

    form: FormGroup;
    suggestionsMap: { [key: string]: Observable<string[]> } = {};
    selected: string;
    isLoading: boolean;
    subscriptions: Subscription[];


    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        this.subscriptions = [];

        this.buildForm();

        this.emitFormValuesToParent();

        this.initSearch();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }


    buildForm() {
        const group = this.integration.formStructure.reduce((acc, field) => {
            const initialValue = this.formResponses && this.formResponses[field.fieldLabelName] ? this.formResponses[field.fieldLabelName] : '';
            acc[field.fieldLabelName] = new FormControl(initialValue, [Validators.required]);
            return acc;
        }, {});

        this.form = this.fb.group(group);
    }

    initSearch() {
        this.integration.formStructure.forEach((field) => {
            this.suggestionsMap[field.fieldLabelName] = new Observable((observer: Observer<string>) => {
                const inputControl = this.form.get(field.fieldLabelName);
                const subscription = inputControl.valueChanges.subscribe((term) => {
                    observer.next(term);
                });
                this.subscriptions.push(subscription);
            }).pipe(
                switchMap((term) => {
                    return this.transformResult(term, field);
                })
            );
        });
    }

    transformResult(term: string, field: FormStructureField) {
        return term ? this.search(term, field.availableValues) : of([]);
    }

    emitFormValuesToParent() {
        this.form.valueChanges.subscribe((values) => {
            const result = {
                updatedFormResponse: values,
                isFormValid: !this.form.invalid
            };

            this.formResponsesChange.emit(result);
        });
    }

    search(term: string, availableValues: string[]): Observable<string[]> {
        if (availableValues && availableValues.length) {
            const searchResult = availableValues
                .filter((value) => value.toLowerCase()
                    .includes(term.toLowerCase())).slice(0, 10);

            return of(searchResult);
        }
        return of([]);
    }

    handleSelect({ item }: { item: string }, fieldName: string): void {
        this.form.get(fieldName).setValue(item);
    }

    getSelectorName(n: string): string {
        return n.replace(/\W/g, '');
    }

    ctrlHasError(fieldLabelName: string): boolean {

        const ctrl = this.form.get(fieldLabelName);
        return ctrl.invalid && (ctrl.dirty || ctrl.touched);
    }

    typeaheadIsLoading(e: boolean): void {
        this.isLoading = e;
    }

}
