import { DocumentCountsResponse } from '@app/shared/folders/folders.service.types';
import { BrowseTree, Document, Folder } from '@app/shared/models';

export enum DatesUpdateTypes {
    EXPIRATION = 'expiration',
    DUE = 'due',
    EXPIRATION_AND_DUE = 'expiration/due'
}

export interface EmailDestination {
    teamId: string;
    binderId: string;
    folderId?: string;
    crumbsEntity: BrowseTree ;
}

export interface ItemCounts {
    [key: string]: DocumentCountsResponse;
}

export interface StateParams {
    binderId: string;
    folderId: string;
    teamId: string;
}

export interface ResolvePermissionsOptions {
    docs: Document[];
    folders: Folder[];
    resolve: () => void;
}

export interface SelectedItems {
    docs: Document[];
    folders: Folder[];
}
