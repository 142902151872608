import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
    transform(items: any[], filter: string, key?: string): any[] {
        if (!(items && items.length)) {
            return [];
        }
        if (!filter) {
            return items;
        }

        const filterLowered = filter.toLowerCase();

        if (key) {
            return this.filterItemsByKey(items, key, filterLowered);
        }

        return this.filterItemsByAllKeys(items, filterLowered);
    }

    private filterItemsByKey(items: any[], key: string, filter: string) {
        return items.filter((item) => {
            const keyArray = key.split('.');
            const value = keyArray.reduce((currentItem, itemKey) => currentItem[itemKey], item);
            if (value && typeof value === 'string') {
                return value.toLowerCase().includes(filter);
            }
            return false;
        });
    }

    private filterItemsByAllKeys(items, filter) {
        return items.filter((item) => {
            return Object.keys(item).some((key) => {
                const value = item[key];
                if (value && typeof value === 'object') {
                    return this.traverseObject(value, filter);
                } if (value && typeof value === 'string') {
                    return value.toLowerCase().includes(filter);
                }
                return false;
            });
        });
    }

    private traverseObject(obj, filter) {
        const keys = Object.keys(obj);
        for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i];
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const value = obj[key];
                if (value && typeof value === 'object') {
                    if (this.traverseObject(value, filter)) {
                        return true;
                    }
                }
                else if (value && typeof value === 'string') {
                    if (value.toLowerCase().includes(filter)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
}
