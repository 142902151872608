import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    Input,
    HostListener,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ElementRef
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    takeUntil,
    throttleTime
} from 'rxjs/operators';

import { CHECKBOX_STATES } from '@app/core/constants';

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import styles from './filtered-select.component.scss';
import template from './filtered-select.component.html';

type OutputItem<T> = T & {
    id: string;
}

type DataItem<T> = OutputItem<T> & {
    fsChecked: CHECKBOX_STATES;
}

export type FilteredSelectEvent<T> = {
    added: T[];
    removedIds: string[];
}

@Component({
    selector: 'filtered-select',
    template,
    styles: [String(styles)]
})
export class FilteredSelectComponent<T = any> implements OnInit, OnChanges, OnDestroy {
    @Input() loadingData = false;
    @Input() isSingleSelect = false;
    @Input() hasNextBatch = false;
    @Input() disabled = false;
    @Input() data: DataItem<T>[] = [];
    @Input() displayProperty: keyof T;
    @Input() displayPropertyOptional: keyof T;
    @Input() tooltipProperty: keyof T;
    @Input() tooltipPropertyPlacement = 'auto';
    @Input() placeholder: string;
    @Input() initiallySelected: DataItem<T>[] = [];
    @Input() emitIfInitalSelected = false;
    @Input() filterControl = new FormControl(null);
    @Input() tooltipText = '';
    @Input() tooltipPosition = 'right';
    @Input() clearAfterSingleSelect = false;
    @Input() disableToggleShowList = false;
    @Input() itemHeight = 29;
    @Input() visibleItemsCount = 8;
    @Input() showInitiallySelectedItems = false;
    @Output() filter = new EventEmitter<string>();
    @Output() itemSelect = new EventEmitter<FilteredSelectEvent<T>>();
    @Output() scrollEnd = new EventEmitter<string>();
    @Output() selectionCleared = new EventEmitter<void>();

    @ViewChild('filter', { static: false }) filterInput: ElementRef;
    @ViewChild(CdkVirtualScrollViewport, { static: false }) scrollViewport: CdkVirtualScrollViewport;

    private clickedInside = false;
    private isInitialValueSet = false;

    private addedHash: { [key: string]: DataItem<T> } = {};
    private removedHash: { [key: string]: DataItem<T> } = {};
    readonly lineRequired = 'Assign to yourself';

    private readonly scrollEndSource$ = new Subject<string>();
    private readonly destroy$ = new Subject<void>();
    showList = false;
    readonly filterInputDebounceTime = 400;
    readonly scrollEndThrottleTime = 500;

    private dataList = new BehaviorSubject<DataItem<T>[]>([]);
    dataList$ = this.dataList.asObservable();

    isDataListEmpty$ = this.dataList$.pipe(
        map((dataList) => dataList?.length === 0)
    );

    private isAddButtonDisabled = new BehaviorSubject<boolean>(true);
    isAddButtonDisabled$ = this.isAddButtonDisabled.asObservable();

    private areAllItemsSelected = new BehaviorSubject<boolean>(false);
    areAllItemsSelected$ = this.areAllItemsSelected.asObservable();

    get showAddButton(): boolean {
        return !this.isSingleSelect && !this.showInitiallySelectedItems && !!this.dataList.getValue().length;
    }

    private get selectedItems(): DataItem<T>[] {
        return this.data.filter((item) => item.fsChecked === CHECKBOX_STATES.SELECTED);
    }

    private get itemsNotInitiallySelected(): DataItem<T>[] {
        return this.data.filter((item) => (
            this.initiallySelected.findIndex(({ id }) => id === item.id) === -1
        ));
    }

    private get hasNewlySelectedItems(): boolean {
        return this.selectedItems.some((selectedItem) => {
            const isInitiallySelected = this.initiallySelected
                .some(({ id }) => id === selectedItem.id);

            if (!isInitiallySelected) {
                return true;
            }
            return false;
        });
    }

    @HostListener('click')
    clickInside(): void {
        this.clickedInside = true;
    }

    @HostListener('document:click')
    clickOutside(): void {
        if (!this.clickedInside && this.showList) {
            this.closeOpenList();
        }
        this.clickedInside = false;
    }

    ngOnInit(): void {
        this.filterControl.valueChanges.pipe(
            filter((v) => v !== null),
            takeUntil(this.destroy$),
            debounceTime(this.filterInputDebounceTime),
            distinctUntilChanged((p, n) => {
                if (!!p && !n) {
                    this.selectionCleared.emit();
                    this.showList = true;
                }
                return p === n;
            })
        ).subscribe((value) => {
            if (!this.isSingleSelect) {
                this.outputSelected();
            }
            this.filter.emit(value);
        });

        this.scrollEndSource$.pipe(
            takeUntil(this.destroy$),
            throttleTime(this.scrollEndThrottleTime)
        ).subscribe(() => {
            this.scrollEnd.emit();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!(changes.data || changes.initiallySelected)) {
            return;
        }
        const selectedTemp = this.initiallySelected.slice();
        this.data = this.data.map((dataItem) => {
            const index = selectedTemp.findIndex((s) => s.id === dataItem.id);
            if (index >= 0 || this.addedHash[dataItem.id]) {
                selectedTemp.splice(index, 1);
                return { ...dataItem, fsChecked: CHECKBOX_STATES.SELECTED };
            }
            return { ...dataItem, fsChecked: CHECKBOX_STATES.NOT_SELECTED };
        });

        if (this.isSingleSelect || this.showInitiallySelectedItems) {
            this.dataList.next(this.data);
        }
        else {
            this.dataList.next(this.itemsNotInitiallySelected);
            this.setAreAllItemsSelected();
        }

        this.setIsAddButtonDisabled();

        if (!this.isInitialValueSet && this.isSingleSelect && this.initiallySelected && this.initiallySelected.length) {
            this.filterControl.setValue(this.initiallySelected[0][this.displayProperty]);
            this.isInitialValueSet = true;
        }
    }

    toggleShowList(): void {
        if (this.disabled) {
            return;
        }
        this.showList = !this.showList;
    }

    onInputFocused(): void {
        this.showList = true;
    }

    clearSelection(): void {
        if (this.disabled) {
            return;
        }
        this.filterControl.setValue('');
        if (this.isInitialValueSet) {
            this.showList = true;
        }
        this.selectionCleared.emit();
    }

    onScrolledIndexChange(): void {
        if (!this.hasNextBatch) {
            return;
        }

        const { end } = this.scrollViewport.getRenderedRange();
        const total = this.scrollViewport.getDataLength();

        if (end === total) {
            this.scrollEndSource$.next();
        }
    }

    trackByIndex(index: number): number {
        return index;
    }

    onItemSelected = (item: DataItem<T>): void => {
        item.fsChecked = CHECKBOX_STATES.SELECTED;
        const outputItem = { ...item };
        delete outputItem.fsChecked;

        this.addedHash[item.id] = outputItem;
        if (this.removedHash[item.id]) {
            delete this.removedHash[item.id];
        }
    }

    onItemUnselected = (item: DataItem<T>): void => {
        item.fsChecked = CHECKBOX_STATES.NOT_SELECTED;

        this.removedHash[item.id] = item;
        if (this.addedHash[item.id]) {
            delete this.addedHash[item.id];
        }
    }

    onItemClick(item: DataItem<T>): void {
        if (this.initiallySelected && this.isSingleSelect) {
            item.fsChecked = CHECKBOX_STATES.NOT_SELECTED;
        }
        if (item.fsChecked === CHECKBOX_STATES.SELECTED) {
            this.onItemUnselected(item);
        }
        else {
            this.onItemSelected(item);
        }
        if (this.isSingleSelect) {
            this.outputSelected();
            if (!this.clearAfterSingleSelect) {
                this.filterControl.setValue(item[this.displayProperty]);
                this.filterControl.markAsTouched();
            }
            this.filterInput.nativeElement.blur();
            this.showList = false;
        }
        else {
            this.setIsAddButtonDisabled();
        }
    }

    closeOpenList(): void {
        if (!this.isSingleSelect) {
            this.outputSelected();
            this.filterControl.setValue(null);
            this.filterInput.nativeElement.blur();
            this.filter.emit('');
        }
        this.showList = false;
    }

    outputSelected(): void {
        const removedIds = [];
        if (!this.emitIfInitalSelected) {
            this.initiallySelected.forEach((dataItem) => {
                if (this.addedHash[dataItem.id]) {
                    delete this.addedHash[dataItem.id];
                }
                if (this.removedHash[dataItem.id]) {
                    removedIds.push(dataItem.id);
                }
            });
        }
        const added = Object.values(this.addedHash);
        if (!removedIds.length && !added.length) {
            return;
        }
        this.itemSelect.emit({ added, removedIds });
        this.addedHash = {};
        this.removedHash = {};
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private setIsAddButtonDisabled(): void {
        this.isAddButtonDisabled.next(!this.hasNewlySelectedItems);
    }

    private setAreAllItemsSelected(): void {
        let areAllItemsSelected: boolean;
        if (this.data?.length === 0) {
            areAllItemsSelected = false;
        }
        else {
            areAllItemsSelected = this.data?.every((item) => item.fsChecked === CHECKBOX_STATES.SELECTED);
        }

        this.areAllItemsSelected.next(areAllItemsSelected);
    }
}
