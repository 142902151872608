import {
    Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output
} from '@angular/core';
import * as _ from 'lodash';
import {
    Document,
    Task,
    Team,
    User,
    Signature,
    Study,
    DocumentSubTypes,
    DocumentSIPStatus,
    DocumentSIPStatusProperties,
    MonitorReview,
    AuditTrailEventType,
    ApiError
} from '@app/shared/models';
import { forkJoin, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DocumentService } from '@app/shared/documents/document.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { DocumentBulkSetDatesComponent } from '@app/components/documents/components/document-bulk-set-dates/document-bulk-set-dates.component';
import { HttpErrorResponse } from '@angular/common/http';
import { BulkUpdateDueAndExpirationDatesResponseItem, UpdateNameResponse, GetSignatureStatusResponse } from '@app/shared/documents/documents.service.types';
import { DOCUMENT_TYPES } from '@florencehealthcare/florence-constants/lib/documents';
import { qcReviewStatuses } from '@florencehealthcare/florence-constants/lib/qc-reviews';
import { CurrentSessionService } from '@app/core/current-session.service';
import { TeamService } from '@app/shared/teams/team.service';
import { EntityTypeService } from '@app/widgets/entity/entity-type.service';
import { StateService } from '@uirouter/core';
import { DocumentTimelineListComponent }
    from '@app/components/documents/components/document-timeline-list/document-timeline-list.component';
import * as moment from 'moment';
import { DocumentMonitorReviewService } from '../../document-monitor-review.service';
import { BulkSetDatesUpdate } from '../document-bulk-set-dates/document-bulk-set-dates.component.types';
import { DocumentFormComponent } from '../document-form/document-form.component';
import { DocumentSignatureListComponent } from '../document-signature-list/document-signature-list.component';
import { ShortcutDetailsComponent } from '../shortcut-details/shortcut-details.component';
import { DocumentTaskListComponent } from '../document-task-list/document-task-list.component';
import { MonitorReviewListComponent } from '../monitor-review-list/monitor-review-list.component';
import { AssignCategoryComponent } from '../assign-category/assign-category.component';
import { DocumentSignaturesComponent } from '../document-signatures/document-signatures.component';
import { AssignCategorySubmitEvent, DocumentCategory } from '../assign-category/assign-category.component.types';
import { MonitorReviewEditComponent } from '../monitor-review-edit/monitor-review-edit.component';
import { MonitorReviewEditSubmitEvent, MonitorReviewSubmitComment } from '../monitor-review-edit/monitor-review-edit.component.types';
import styles from './document-details.component.scss';
import template from './document-details.component.html';
import { QcReview } from '../../qc-review.services.types';
import { OnDocRenameEvent } from './document-details.component.types';
import { ViewRelatedDocumentsDetailsComponent } from '../view-related-documents-details/view-related-documents-details.component';
import { BulkDocumentDatesUpdate } from '../document-bulk-set-document-dates/document-bulk-set-document-dates.component.types';
import { DocumentBulkSetDocumentDatesComponent } from '../document-bulk-set-document-dates/document-bulk-set-document-dates.component';

@Component({
    selector: 'document-details',
    template,
    styles: [String(styles)]
})
export class DocumentDetailsComponent implements OnInit, OnDestroy, OnChanges {

    @Input() doc: Document;
    @Input() monitorReviews: MonitorReview[];
    @Input() viewableStudy: Study;
    @Input() hasUnviewableStudies: boolean;
    @Input() monitorReview: MonitorReview;

    @Output() openTask = new EventEmitter<Task>();
    @Output() onDocRename = new EventEmitter<OnDocRenameEvent>();
    @Output() viewAudit = new EventEmitter<void>();
    @Output() viewDocSharingHistory = new EventEmitter<void>();
    @Output() onMonitorReviewsUpdate = new EventEmitter<{ monitorReviews: MonitorReview[]}>();


    public currentUser: User;
    public currentTeam: Team;
    documentType: string;
    viewMoreLimit: number;
    fileSize: string|number;
    rawFilename: string;
    approvalStatus: string;
    currentCompletedSignatures: Signature[];
    previousCompletedSignatures: Signature[];
    showDocumentCategory: boolean;
    canUpdateDocumentCategory: boolean;
    disableRename: boolean;
    isDocumentLocked = false;
    canViewTimeline: boolean;
    hasPlaceholderData: boolean;
    showPlaceholderCreated: boolean;
    showEditExpirationDate: boolean;
    showEditDocumentDate: boolean;
    disableEditExpirationOnDate: boolean;
    hasCompletedSignatures: boolean;
    hasPendingSignatures: boolean;
    showSIPHistory: boolean;
    sipHistoryExpanded: boolean;
    documentSIPStatuses: Array<DocumentSIPStatusProperties>;
    friendlyImportedVia: string;
    hasQcReview : boolean;
    qcReviewStatus : string;
    numOfReviews : number;
    currentQcReview : number;
    teamTimezone: string;
    private readonly destroy$ = new Subject<void>();


    constructor(
        @Inject('Window') private $window: Window,
        private readonly $state: StateService,
        private CurrentSessionService: CurrentSessionService,
        private TeamService: TeamService,
        private DocumentService: DocumentService,
        private DocumentMonitorReviews: DocumentMonitorReviewService,
        private Notifications: NotificationsService,
        private Modals: ModalsService,
        private EntityType: EntityTypeService
    ) {
        this.viewMoreLimit = 3;
    }

    ngOnInit(): void {
        this.getQcReviewStats();
        this.currentUser = this.CurrentSessionService.getCurrentUser();
        this.currentTeam = this.CurrentSessionService.getCurrentTeam();
        this.documentType = this.EntityType.get(this.doc);
        this.friendlyImportedVia = this._getFriendlyImportedVia(this.doc.friendlyImportedVia);
        this.teamTimezone = moment()
            .tz(this.currentTeam.settings.timezone)
            .format('Z')
            .split(':')
            .join('');
        this.monitorReviews = _.cloneDeep(this.monitorReviews);
        this.approvalStatus = _.get(this.doc, 'documentProperties.approval.status');
        this.isDocumentLocked = this.doc.isLocked;
        this.documentSIPStatuses = this.getDocumentSIPStatuses(this.doc.documentSIPStatus || []);
        this.setViewableAndEnabled();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    trackByIndex(index: number): number {
        return index;
    }

    contactSupport(): void {
        this.$window.open('https://florencehealthcare.zendesk.com/hc/en-us/articles/20843241243667/', '_blank');
    }

    getQcReviewStatus() : string {
        return 'Pending QC Review';
    }

    getCurrentQcReviewProgress(qcReview: QcReview) : number {
        const firstPendingIndex = qcReview.reviews.findIndex((review) => review.status === qcReviewStatuses.PENDING_REVIEW);

        if (firstPendingIndex >= 0) {
            return firstPendingIndex + 1;
        }

        return qcReview.reviews.length;
    }

    getQcReviewStats(): void {

        if (this.doc.qcReview) {
            this.qcReviewStatus = this.getQcReviewStatus();
            this.currentQcReview = this.getCurrentQcReviewProgress(this.doc.qcReview);
        }
    }

    getDocumentSIPStatuses(rawDocumentSIPStatuses: Array<DocumentSIPStatus>): Array<DocumentSIPStatusProperties> {
        const hash = {};
        rawDocumentSIPStatuses.forEach((documentSIPStatus: DocumentSIPStatus) => {
            if (hash[documentSIPStatus.version]) {
                hash[documentSIPStatus.version].push(documentSIPStatus);
            }
            else {
                hash[documentSIPStatus.version] = [documentSIPStatus];
            }
        });
        return Object.keys(hash).map((version: string) => ({
            version: parseInt(version, 10),
            SIPUpdates: hash[version]
        }));
    }

    ngOnChanges(changes: ng.IOnChangesObject): void {
        if (changes.doc && changes.doc.currentValue) {
            this.getQcReviewStats();
            this.documentType = this.EntityType.get(this.doc);
            this.organizeSignatures();
        }
        if (changes.monitorReviews) {
            this.monitorReviews = _.cloneDeep(this.monitorReviews);
        }
        if (changes.monitorReview && changes.monitorReview.currentValue) {
            this.monitorReview = _.cloneDeep(this.monitorReview);
            this.openMonitorReview(this.monitorReview);
        }
    }


    setViewableAndEnabled(): void {

        this.showDocumentCategory = this.currentTeam
            && this.currentTeam.settings.features.documentCategories;
        this.canUpdateDocumentCategory = !this.isShortcut(this.doc)
            && (this.doc.permissions.updateDocumentCategory
                || this.doc.permissions.updateDocumentStatus);
        this.disableRename = !this.doc.permissions.renameDocument
            || !this.doc.isLatestVersion;
        this.canViewTimeline = this.TeamService.canManageIndividualTimeline();
        this.hasPlaceholderData = this.doc.subType === 'placeholder'
            || !!this.doc.originalPlaceholderTitle;
        this.showPlaceholderCreated = this.doc.subType === 'placeholder'
            || this.filledViaCloneOrShortcut();
        this.fileSize = this.getFileSize();
        this.rawFilename = this.doc.originalDocument ? this.doc.originalDocument.rawFilename : this.doc.rawFilename;
        this.showEditExpirationDate = this.doc.permissions.updateDocExpDate
            && (this.doc.subType === 'content' || this.doc.subType === 'log' || this.isShortcutOfContent());
        this.showEditDocumentDate = this.doc.permissions.manageDocumentDate
        && (this.doc.subType === 'content' || this.doc.subType === 'log');
        this.disableEditExpirationOnDate = !this.doc.isLatestVersion;

        this.sipHistoryExpanded = false;
        this.showSIPHistory = !!this.documentSIPStatuses.length;
    }

    showAuditTrail(): void {
        this.viewAudit.emit();
    }

    showViewDocSharingHistory(): void {
        this.viewDocSharingHistory.emit();
    }

    _getFriendlyImportedVia(friendlyImportedVia) {
        if (friendlyImportedVia === 'SiteLink\u2122 Bulk Distribution' || friendlyImportedVia === 'SiteLink\u2122 eLog Distribution') {
            return friendlyImportedVia;
        }
        if (this.doc.subType === DOCUMENT_TYPES.LOG && this.doc.overwrittenPlaceholderId !== null) {
            return 'New Log Created';
        }
        return this.capitalize(friendlyImportedVia);
    }

    rename(): void {
        if (this.isDocumentLocked) {
            return;
        }
        const formModal = this.Modals.show(DocumentFormComponent, {
            class: 'modal-md',
            initialState: {
                document: this.doc,
                formType: 'rename-document'
            }
        });

        formModal.content.submit.subscribe((event) => {
            this.DocumentService.updateName(this.doc.id.toString(), event.data.name, event.data.renameShortcuts)
                .subscribe((results) => {
                    const { document } = results as UpdateNameResponse;
                    this.onDocRename.emit({ document });

                }, () => {
                    return undefined;
                });
            formModal.hide();
        });
    }

    viewShortcutDetails(): void {
        this.Modals.show(ShortcutDetailsComponent, {
            class: 'modal-lg',
            initialState: {
                document: this.doc,
                shortcuts: this.doc.shortcuts.map((sc) => {
                    return {
                        ...sc,
                        contentVersion: this.doc.version || this.doc.id.version
                    };
                })
            }
        });
    }

    viewRelatedDocumentDetails(): void {
        const relatedDocsModal = this.Modals.show(ViewRelatedDocumentsDetailsComponent, {
            class: 'modal-lg',
            initialState: {
                documentKind: 'Related Documents',
                baseDocument: this.doc
            }
        });

        relatedDocsModal.content.dismiss.subscribe(() => {
            relatedDocsModal.hide();
        });
    }

    _handleErrorNotification({ error }: ApiError): void {
        const { message } = error;
        if (message) {
            return this.Notifications.error(message);
        }

        return this.Notifications.unexpectedError();
    }

    setDate(): void {
        if (this.isDocumentLocked) {
            return;
        }

        const modalRef = this.Modals.show(DocumentBulkSetDatesComponent, {
            class: 'modal-xl',
            initialState: {
                documents: [this.doc],
                parentEntity: this.doc
            }
        });

        modalRef.content.update.subscribe((updates: BulkSetDatesUpdate[]) => {
            modalRef.content.isProcessing = true;

            this.DocumentService.bulkUpdateDueAndExpirationDates(updates)
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                    (docsUpdated) => {
                        this.handleBulkUpdateDatesNotifications(docsUpdated);
                        modalRef.content.isProcessing = false;
                        modalRef.hide();
                        this.$state.reload();
                    },
                    (error) => {
                        modalRef.content.isProcessing = false;
                        this._handleErrorNotification(error);
                        modalRef.hide();
                        this.$state.reload();
                    }
                );
        });
    }

    setDocumentDate(): void {
        if (this.isDocumentLocked) {
            return;
        }

        const modalRef = this.Modals.show(DocumentBulkSetDocumentDatesComponent, {
            class: 'modal-lg',
            initialState: {
                documents: [this.doc],
                parentEntity: this.doc
            }
        });

        modalRef.content.update.subscribe((updates: BulkDocumentDatesUpdate[]) => {
            modalRef.content.isProcessing = true;

            this.DocumentService.bulkUpdateDocumentDates(updates)
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                    (docsUpdated) => {
                        this.handleBulkUpdateDocumentDatesNotifications(docsUpdated);
                        modalRef.content.isProcessing = false;
                        modalRef.hide();
                        this.$state.reload();
                    },
                    (error) => {
                        modalRef.content.isProcessing = false;
                        this._handleErrorNotification(error);
                        modalRef.hide();
                        this.$state.reload();
                    }
                );
        });
    }

    private handleBulkUpdateDatesNotifications(updates: BulkUpdateDueAndExpirationDatesResponseItem[]): void {
        const item = updates[0];

        if (item.statusCode === 200) {
            if (item.document.docEventName === AuditTrailEventType.DOCUMENT_DUE_DATE_SET) {
                if (item.document.originalDoc.dueDate && !item.document.finalDoc.dueDate) {
                    this.Notifications.success('Due Date removed from placeholder.');
                }
                else {
                    this.Notifications.success('Due Date added to placeholder.');
                }
            }

            if (item.document.docEventName === AuditTrailEventType.DOCUMENT_EXP_DATE_SET) {
                if (item.document.originalDoc.expirationDate && !item.document.finalDoc.expirationDate) {
                    this.Notifications.success('Expiration Date removed from document.');
                }
                else {
                    this.Notifications.success('Expiration Date added to document.');
                }
            }
        }
        else {
            this.Notifications.error(String(item.error.message));
        }
    }

    private handleBulkUpdateDocumentDatesNotifications(updates: BulkUpdateDueAndExpirationDatesResponseItem[]): void {
        const item = updates[0];

        if (item.statusCode === 200) {
            if (item.document.originalDoc.documentDate && !item.document.finalDoc.documentDate) {
                this.Notifications.success('Document Date removed from document.');
            }
            else {
                this.Notifications.success('Document Date added to document.');
            }
        }
        else {
            this.Notifications.error(String(item.error.message));
        }
    }

    viewSignatures(): void {
        const contentVersion = this.DocumentService.getCurrentDocDisplayVersion(this.doc);

        this.DocumentService.getDocumentSignatureStatus({
            documentId: this.doc.id,
            version: this.doc.version,
            contentVersion,
            includePreviousSignatures: true
        }).subscribe((data: GetSignatureStatusResponse) => {
            this.Modals.show(DocumentSignaturesComponent, {
                class: 'modal-md',
                initialState: {
                    doc: this.doc,
                    signatures: data.signatures
                }
            });
        });
    }

    viewSignatureRequests(): void {

        this.DocumentService.getDocumentSignatureStatus({
            documentId: this.doc.id,
            version: this.doc.version,
            includeLogEntrySignatureRequests: this.doc.subType === 'log'
        }).subscribe((data: GetSignatureStatusResponse) => {
            this.Modals.show(DocumentSignatureListComponent, {
                class: 'modal-lg',
                initialState: {
                    doc: this.doc,
                    pending: true,
                    documentSignatureStatus: data
                }
            });
        });

    }

    openSingleTask(task: Task): void {
        if (this.doc.permissions.manageDocTasks) {
            this.openTask.emit(task);
        }
    }

    viewTasks(): void {
        const viewTaskModal = this.Modals.show(DocumentTaskListComponent, {
            class: 'modal-md',
            initialState: {
                doc: this.doc
            }
        });

        viewTaskModal.content.openTaskModal.subscribe((task: Task) => {
            this.openTask.emit(task);
        });
    }

    _getUnwoundTimelines(): any[] {

        if (!this.currentTeam || !this.doc.projects) {
            return [];
        }
        return this.doc.projects.reduce((unwoundTimelines, project) => {

            const timelines = project.timelines.map((timeline) => ({
                teamId: this.currentTeam.teamId,
                projectId: project.projectId,
                projectName: project.projectName,
                timelineId: timeline.timelineId,
                timelineName: timeline.timelineName
            }));
            return unwoundTimelines.concat(timelines);
        }, []);
    }

    viewTimelines(): void {
        const unwoundTimelines = this._getUnwoundTimelines();

        this.Modals.show(DocumentTimelineListComponent, {
            class: 'modal-lg',
            initialState: {
                document: this.doc,
                timelines: unwoundTimelines
            }
        });
    }

    openTimeline({ timelineId, projectId }):void {
        if (this.canViewTimeline) {
            this.$state.go('app.team.manage-project-timeline', {
                teamId: this.currentTeam.teamId,
                projectId,
                timelineId
            });
        }
    }

    viewMonitorReviews(): void {

        const formMonitorReviews = this.Modals.show(MonitorReviewListComponent, {
            class: 'modal-lg',
            initialState: {
                document: this.doc,
                monitorReviews: this.monitorReviews
            }
        });

        formMonitorReviews.content.monitorReviewClick.subscribe((monitorReview) => {
            this.openMonitorReview(monitorReview);
            formMonitorReviews.hide();
        });
    }

    openMonitorReview(monitorReview): void {

        const { teamId } = this.doc;
        const documentId: string = this.doc.id as string;

        const observables: Observable<any>[] = [
            this.DocumentMonitorReviews.getPaywalls(teamId, documentId),
            this.DocumentMonitorReviews.getInheritedStudyLabel(documentId),
            this.DocumentMonitorReviews.getEventsForReview(documentId, monitorReview.id, null)
        ];

        forkJoin(observables)
            .pipe(take(1))
            .subscribe(
                ([paywalls, studyLabel, reviewEvents]) => {
                    const monitorModal = this.Modals.show(MonitorReviewEditComponent, {
                        class: 'modal-md',
                        initialState: {
                            document: this.doc,
                            monitorReviews: this.monitorReviews,
                            paywallOrgs: paywalls,
                            studyLabel,
                            currentReview: monitorReview,
                            reviewEvents: reviewEvents.items,
                            reviewEventsNext: reviewEvents.next
                        }
                    });

                    monitorModal.content.onMonitorReviewCommentSubmit
                        .subscribe((reviewEventWithComment: MonitorReviewSubmitComment) => {
                            const { currentReview, comment, notifyRecipients } = reviewEventWithComment;
                            this._submitMonitorReviewComment(currentReview, comment, notifyRecipients);
                            if (notifyRecipients) {
                                this._submitMonitorReview(currentReview);
                            }
                            monitorModal.hide();
                        });

                    monitorModal.content.onMonitorReviewSubmit
                        .subscribe((reviewEventWithOutComment: MonitorReviewEditSubmitEvent) => {
                            this._submitMonitorReview(reviewEventWithOutComment);
                            monitorModal.hide();
                        });
                },
                (error: HttpErrorResponse) => {
                    this._handleHttpError(error);
                }
            );
    }

    _submitMonitorReviewComment(monitorReview, comment, notifyRecipients) {
        if (monitorReview.id) {
            return this.DocumentMonitorReviews
                .comment(monitorReview.documentId, monitorReview.id, monitorReview.recipientIds, comment, notifyRecipients)
                .subscribe((newComment) => {
                    this.Notifications.success('Monitor Review comment added!');
                    ({ monitorReview, comment: newComment });
                }, (error: HttpErrorResponse) => {
                    this._handleHttpError(error);
                });
        }

        const documentId: string = this.doc.id as string;
        return this.DocumentMonitorReviews
            .createMonitorReview(documentId, { ...monitorReview, comment })
            .subscribe((review: MonitorReview) => {
                if (!monitorReview.hideNotification) {
                    this.Notifications.success('Monitor review created!');
                }
                this.monitorReviews.push(review);
                this._onMonitorReivewsUpdate();
                return this.DocumentMonitorReviews
                    .comment(documentId, review.id, review.recipientIds, comment)
                    .subscribe((newComment) => {
                        this.Notifications.success('Monitor Review comment added!');
                        ({ monitorReview: review, comment: newComment });
                    });
            }, (error: HttpErrorResponse) => {
                this._handleHttpError(error);
            });
    }

    _submitMonitorReview(monitorReview) {

        const documentId: string = this.doc.id as string;
        if (monitorReview.id) {
            return this.DocumentMonitorReviews
                .updateStatus(documentId, monitorReview.id, monitorReview.status, monitorReview.recipientIds, '')
                .subscribe((review) => {
                    this.Notifications.success('Monitor review status updated!');
                    Object.assign(this.monitorReviews.find((r) => r.id === review.id), review);
                    this._onMonitorReivewsUpdate();
                    return review;
                }, (error: HttpErrorResponse) => {
                    this._handleHttpError(error);
                });
        }

        return this.DocumentMonitorReviews
            .createMonitorReview(documentId, monitorReview)
            .subscribe((review) => {
                if (!monitorReview.hideNotification) {
                    this.Notifications.success('Monitor review created!');
                }
                this.monitorReviews.push(review);
                this._onMonitorReivewsUpdate();
                return review;
            }, (error: HttpErrorResponse) => {
                this._handleHttpError(error);
            });
    }

    _onMonitorReivewsUpdate() {
        this.onMonitorReviewsUpdate.emit({ monitorReviews: this.monitorReviews });
    }

    get hasLogDetails(): boolean {
        return this.doc.subType === DocumentSubTypes.log
            && this.doc.logDetails.some(this.detailHasValue);
    }

    detailHasValue(detail: { name?: string; value?: string } = { value: '' }): boolean {
        return Boolean(detail.value);
    }

    isShortcut(doc): boolean {
        return this.DocumentService.isShortcut(doc);
    }

    goToOriginalDoc(): void {

        this.isShortcut(this.doc) && this.DocumentService.goToDocument({ doc: this.doc.originalDocument });
    }

    getFileSize() {
        const file = this.doc.originalDocument ? this.doc.originalDocument.file : this.doc.file;
        if (file && file.size && !Number.isNaN(file.size / 1024.0)) {
            return (file.size / 1024.0).toFixed(2);
        }
    }

    filledViaCloneOrShortcut() {
        return this.doc.originalPlaceholderTitle && !this.doc.overwrittenPlaceholderId;
    }

    isShortcutOfContent() {
        return this.doc.originalDoc ? this.doc.originalDocument.subType === 'content' : false;
    }

    _handleHttpError(error: HttpErrorResponse) {
        const message = (error && error.error && error.error.message);
        message ? this.Notifications.error(message) : this.Notifications.unexpectedError();
    }

    updateDocumentCategory() {
        if (this.isDocumentLocked) {
            return;
        }

        this.TeamService.getCategories(this.currentTeam.id)
            .subscribe((documentCategories: DocumentCategory[]) => {
                const assignCategoryForm = this.Modals.show(AssignCategoryComponent, {
                    class: 'modal-md',
                    initialState: {
                        categories: documentCategories,
                        document: this.doc,
                        canUpdateCategory: this.doc.permissions.updateDocumentCategory,
                        canUpdateStatus: this.doc.permissions.updateDocumentStatus
                    }
                });

                assignCategoryForm.content.onStatusUpdate.subscribe((event: AssignCategorySubmitEvent) => {

                    const documentCategory = {
                        categoryId: event.categoryId,
                        statusId: event.statusId
                    };

                    this.DocumentService.updateDocumentCategory(this.doc.id, documentCategory)
                        .subscribe((doc: Document) => {
                            this.doc.updatedAt = doc.updatedAt;
                            this.doc.documentCategory = doc.documentCategory;
                            event.onSuccess();
                        }, () => {
                            event.onError();
                            return undefined;
                        });
                });
            });


    }

    private capitalize(word) {
        return word[0].toUpperCase() + word.substring(1).toLowerCase();
    }

    private organizeSignatures(): void {

        this.currentCompletedSignatures = [];
        this.previousCompletedSignatures = [];

        const contentVersion = this.DocumentService.getCurrentDocDisplayVersion(this.doc);

        this.doc.signatures.forEach((sig) => {

            if (sig.status !== 'Signed') {
                return;
            }

            if (this.doc.isBrokenShortcut) {
                this.previousCompletedSignatures.push(sig);
            }
            else if (sig.version === contentVersion) {
                this.currentCompletedSignatures.push(sig);
            }
            else if (sig.version < contentVersion) {
                this.previousCompletedSignatures.push(sig);
            }
        });

        this.hasCompletedSignatures = this.previousCompletedSignatures.length > 0 || this.currentCompletedSignatures.length > 0;
        this.hasPendingSignatures = this.doc.pendingSignatures.length > 0;
    }
}
