import { StateService } from '@uirouter/angular';
import { BehaviorSubject } from 'rxjs';

import {
    Crumb,
    Team,
    TeamPermissions,
    AuditTrailSubject,
    Study,
    TeamSipIntegrationLink
} from '@app/shared/models';
import { TeamService } from '@app/shared/teams/team.service';
import { AuditTrailService } from '@app/shared/audit-trail/audit-trail.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { StudiesService } from '@app/shared/studies/studies.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { ConfirmDestroySubmitEvent } from '@app/widgets/confirm-destroy/confirm-destroy.component.types';
import { AuditTrailModalItem, AuditTrailTypes } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component.types';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { AuditTrailModalComponent } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { debounceTime, filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ConfirmDestroyComponent } from '@app/widgets/confirm-destroy/confirm-destroy.component';
import { GetStudiesParams } from '@app/shared/studies/studies.service.types';
import { SaveParams as SaveTeamSipLinkParams, TeamSipLinkCreateComponent } from '../../components/team-sip-link-create/team-sip-link-create.component';
import { TeamSipLinksService } from '../../team-sip-links.service';
import { TeamSettingsTab, TeamSettingsTabs } from './manage-team-settings.component.types';
import { UpdateEvent } from '../../components/manage-team-security/manage-team-security.component';
import { UpdateProfileData } from '../../components/manage-team-profile/manage-team-profile.component.types';
import template from './manage-team-settings.component.html';
import styles from './manage-team-settings.component.scss';

@Component({
    selector: 'manage-team-settings',
    template,
    styles: [String(styles)]
})
export class ManageTeamSettingsComponent implements OnInit {
    team: Team;
    crumbs: Crumb[] = [{ name: 'Manage Team Settings' }];
    activeTabIndex: number;
    private studiesRes = new BehaviorSubject<{ items: Study[]; recordCount: number }>(undefined);
    studiesRes$ = this.studiesRes.asObservable();
    private teamSipLinksRes = new BehaviorSubject<TeamSipIntegrationLink[]>(undefined);
    teamSipLinksRes$ = this.teamSipLinksRes.asObservable();
    createdTeamSipLinkId: string | undefined;
    teamSecurityUpdateLoading = false;
    teamTimeoutPeriodUpdateLoading = false;
    teamMfaUpdateLoading = false;
    studyProfileEntryTeamSettingLocation = true;
    isStudyRolesEnabled = true;

    public tabs: TeamSettingsTabs = {
        settings: {
            state: 'app.team.manage-team-settings',
            index: 1,
            permCheck: 'canAccessManageTeamProfile'
        },
        manageStudyRoles: {
            state: 'app.team.manage-study-roles',
            index: 2,
            permCheck: 'canAccessStudyRoles'
        },
        labels: {
            state: 'app.team.manage-team-labels',
            index: 3,
            permCheck: 'canAccessLabels'
        },
        sso: {
            state: 'app.team.manage-team-sso',
            index: 4,
            permCheck: 'canAcessSingleSignOn'
        },
        studies: {
            state: 'app.team.manage-team-studies',
            index: 5,
            onLoad: 'loadTeamStudies',
            permCheck: 'canAcessTeamStudies'
        },
        teamSipLinks: {
            state: 'app.team.manage-team-sip-links',
            index: 6,
            onLoad: 'loadTeamSipLinks',
            permCheck: 'canAccessManageTeamSipLinks'
        },
        security: {
            state: 'app.team.manage-security',
            index: 7,
            permCheck: 'canAccessSecurityTab'
        }
    };

    constructor(
        private Teams: TeamService,
        private TeamSipLinks: TeamSipLinksService,
        private AuditTrail: AuditTrailService,
        private CurrentSession: CurrentSessionService,
        private $state: StateService,
        private Studies: StudiesService,
        private Notifications: NotificationsService,
        private Modals: ModalsService,
        private FeatureFlags: FeatureFlagService
    ) { }

    ngOnInit(): void {
        this.getStudyProfileEntryFlag();
        this.getTeamStudyRolesFlag();
        this.team = this.CurrentSession.getCurrentTeam();
        this.crumbs = [{ name: `Manage Team Settings: ${this.team.name}` }];
        const perm = this.team ? this.team.permissions : {} as TeamPermissions;
        if (!(perm.manageTeam || perm.viewTeamProfile)) {
            this.$state.go('app.select-team');
            return;
        }
        this.initActiveTab(this.$state.current.name);
        this.generateTabsHref();
    }

    getStudyProfileEntryFlag() {
        this.FeatureFlags.getFlag(FEATURE_FLAGS.STUDY_PROFILE_SIDEMENU_ENTRY, false).pipe(
            filter((flag) => {
                return flag !== undefined;
            })
        ).subscribe((value) => {
            this.studyProfileEntryTeamSettingLocation = !value;
        });
    }

    getTeamStudyRolesFlag(): void {
        this.FeatureFlags.getFlag(FEATURE_FLAGS.STUDY_ROLES, false).pipe(
            filter((flag) => flag !== undefined),
            debounceTime(300)
        ).subscribe((isFlagEnabled) => {
            this.isStudyRolesEnabled = isFlagEnabled;
        });
    }

    initActiveTab(stateName: string): void {
        const tab = this.getViewableTab(stateName) || this.getViewableTab();
        if (!tab) {
            this.$state.go('app.select-team');
            return;
        }
        this.setActiveTab(tab);
    }

    getViewableTab(stateName?: string): TeamSettingsTab {
        return Object.values(this.tabs).find(({ state, permCheck }: TeamSettingsTab) => {
            if (stateName) {
                return state === stateName && this[permCheck]();
            }
            return this[permCheck]();
        });
    }

    setActiveTab(tab: TeamSettingsTab): void {
        if (this.activeTabIndex === tab.index) {
            return;
        }
        this.activeTabIndex = tab.index;
        tab.onLoad && this[tab.onLoad]();
        this.$state.transitionTo(tab.state, { teamId: this.team.id }, { location: 'replace' });
    }

    openAuditTrailModal(): void {
        if (!this.team.permissions.viewTeamAuditTrail) {
            return;
        }

        const auditItem: AuditTrailModalItem = {
            id: this.team.id,
            teamId: this.team.teamId,
            permissions: this.team.permissions,
            type: this.team.type as AuditTrailTypes,
            name: this.team.name

        };
        const params = {
            subject: AuditTrailSubject.TEAM_PROFILE,
            teamId: auditItem.teamId,
            objectId: auditItem.id,
            overwrittenObjectId: null,
            limitToOverwritten: false,
            ...this.AuditTrail.auditPagination
        };

        this.AuditTrail.getAudits(params).subscribe((audits) => {
            this.Modals.show(AuditTrailModalComponent, {
                class: 'modal-xl',
                initialState: {
                    data: audits,
                    item: auditItem,
                    subject: params.subject,
                    pagination: this.AuditTrail.auditPagination,
                    onPageChange: this.AuditTrail.getAudits.bind(this.AuditTrail)
                }
            });
        },
        (errorResponse) => {
            const message = errorResponse && errorResponse.error && errorResponse.error.message;
            if (message) {
                this.Notifications.error(message);
            }
            else {
                this.Notifications.unexpectedError();
            }
        });
    }

    canAccessLabels(): boolean {
        if (this.team) {
            const {
                labelManage, labelList, labelAssign, labelCreate, labelEdit, labelDelete,
                labelValueManage, labelValueAdd, labelValueUpdate, labelValueDelete
            } = this.team.permissions;

            return this.team
                && this.team.settings.features.labels
                && (labelManage
                    || labelList
                    || labelAssign
                    || labelCreate
                    || labelEdit
                    || labelDelete
                    || labelValueManage
                    || labelValueAdd
                    || labelValueUpdate
                    || labelValueDelete);
        }
        return false;
    }

    canAccessSecurityTab(): boolean {
        return this.team && (
            this.team.permissions.updateTeamMFA
            || this.team.permissions.manageTeamTimeoutPeriod);
    }

    canAccessManageTeamProfile(): boolean {
        return this.team
            && (this.team.permissions.renameTeam || this.team.permissions.viewTeamProfile);
    }

    canAccessStudyRoles(): boolean {
        return this.isStudyRolesEnabled && this.team?.permissions?.manageStudyRoles;
    }

    canAcessSingleSignOn(): boolean {
        return this.team && this.team.permissions.manageSSO && this.team.settings.features.sso;
    }

    canAcessTeamStudies(): boolean {
        return this.team && this.team.permissions.viewTeamStudyProfiles && this.studyProfileEntryTeamSettingLocation;
    }

    canEditTeamStudies(): boolean {
        return this.team && this.team.permissions.createEditTeamStudyProfiles;
    }

    canManageMonitorGroups(): boolean {
        return this.team && this.team.permissions.managePaywalls;
    }

    canAccessManageTeamSipLinks(): boolean {
        return this.team && this.team.permissions.teamManageSipLinks;
    }

    updateTeamProfile(event: UpdateProfileData) {
        this.Teams.update(this.team.id, event.updatedFields)
            .subscribe((updatedTeam: Team) => {
                this.CurrentSession.setCurrentTeam({ ...this.team, ...updatedTeam });
                this.ngOnInit();
                event.onSuccess();
            },
            (error) => {
                const statusCode = error?.response?.status || error?.status;
                event.onError();
                if (statusCode === 403) {
                    // Override default API service's redirect
                    this.$state.go(this.tabs.settings.state, {}, { reload: true });
                }
            });
    }

    loadTeamStudies(params: GetStudiesParams = {}): void {
        params.sortDir = params.sortDir || 'ASC';
        params.sortBy = params.sortBy || 'uniqueProtocolId';
        params.pageNum = params.pageNum || 1;
        params.pageSize = params.pageSize || 20;
        this.Studies.getStudies(this.team.id, params).subscribe((value) => {
            this.studiesRes.next(value);
        });
    }

    loadTeamSipLinks(): void {
        this.TeamSipLinks.getTeamSipLinks(this.team.id).subscribe((value) => {
            this.teamSipLinksRes.next(value);
        });
    }

    desposeTeamTeamSipLinks(): void {
        this.teamSipLinksRes.next(undefined);
    }

    loadSponsorNames(): void {
        this.TeamSipLinks.getTeamSipLinks(this.team.id).subscribe((value) => {
            this.teamSipLinksRes.next(value);
        });
    }

    openCreateSipLink(): void {
        this.TeamSipLinks.getSponsorNames().subscribe((sponsors) => {
            const modal = this.Modals.show(TeamSipLinkCreateComponent, {
                animated: false,
                class: 'modal-md',
                initialState: {
                    sponsors,
                    team: this.team
                }
            });

            modal.content.save.subscribe(({ params, close, cb }: SaveTeamSipLinkParams) => {
                this.TeamSipLinks.createTeamSipLink(params).subscribe(
                    (val) => {
                        this.Notifications.success('Successfully created SIP Configuration.');
                        this.teamSipLinksRes.next([val].concat(this.teamSipLinksRes.value));
                        close();
                    }, ({ error }) => {
                        this.Notifications.error(error.message || 'Unable to create SIP Configuration.');
                        cb();
                    }
                );
            });

            modal.content.cancel.subscribe(() => modal.hide());
        });
    }

    openDestroySipLink(teamSipLink: TeamSipIntegrationLink): void {
        const bodyText = 'This action <span class="strong text-uppercase">cannot</span> be undone. This action CANNOT be undone. '
            + 'This will permanently delete the SIP Configuration.';

        const modal = this.Modals.show(ConfirmDestroyComponent, {
            animated: false,
            class: 'modal-md',
            initialState: {
                requireReason: true,
                bodyText
            }
        });

        modal.content.save.subscribe((event: ConfirmDestroySubmitEvent) => {
            this.TeamSipLinks.deleteTeamSipLink(teamSipLink, event.data.reason).subscribe(
                () => {
                    this.Notifications.success('SIP Configuration Data successfully deleted!');
                    const val = this.teamSipLinksRes.value.filter(({ id }) => id !== teamSipLink.id);
                    this.teamSipLinksRes.next(val);
                    event.onSuccess();
                }, ({ error }) => {
                    this.Notifications.error(error.message || 'SIP Configuration Data failed to delete. Please try again or contact Florence support.');
                    event.onError();
                }
            );
        });

        modal.content.dismiss.subscribe(() => modal.hide());
    }

    updateTeamSecurity(event: UpdateEvent): void {

        const updateParams = {
            ...(event.mfaPolicyEnabled !== undefined && {
                mfaPolicy: {
                    isEnabled: event.mfaPolicyEnabled
                }
            }),
            ...(event.inactivityTimeout !== undefined && {
                inactivityTimeout: event.inactivityTimeout
            })
        };

        if (event.mfaPolicyEnabled) {
            this.teamMfaUpdateLoading = true;
        }
        if (event.inactivityTimeout) {
            this.teamTimeoutPeriodUpdateLoading = true;
        }
        this.Teams.update(this.team.id, updateParams).subscribe((updatedTeam) => {
            this.CurrentSession.setCurrentTeam({ ...this.team, ...updatedTeam });
            this.ngOnInit();
            this.teamTimeoutPeriodUpdateLoading = false;
            this.teamMfaUpdateLoading = false;
        },
        () => {
            this.teamTimeoutPeriodUpdateLoading = false;
            this.teamMfaUpdateLoading = false;
        });
    }

    private generateTabsHref(): void {
        Object.values(this.tabs).forEach((tab) => {
            tab.href = this.$state.href(tab.state, { teamId: this.team.id });
        });
    }
}
