
import {
    Component, EventEmitter, Output
} from '@angular/core';

import template from './log-template-inactive-warning.component.html';
import styles from './log-template-inactive-warning.component.scss';

@Component({
    selector: 'log-template-inactive-warning',
    template,
    styles: [String(styles)]
})
export class LogTemplateInactiveWarningComponent {
    @Output() dismiss = new EventEmitter<void>();

    dismissModal(): void {
        this.dismiss.emit();
    }
}
