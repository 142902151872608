/* eslint-disable no-useless-escape */

'use strict';

const Assert = require('assert');
const _ = require('lodash');
const AnnotationConstants = require('./documents/annotations');
const AuditTrailsConstants = require('./audittrails');
const ActionGroups = require('./actionGroups');
const Downloads = require('./downloads');
const DocumentPropertiesConstants = require('./documentProperties');
const NamesConstants = require('./names');
const Projects = require('./projects');
const RolesConstants = require('./roles');
const PublicApiRouteConstants = require('./publicApiRoutes');
const SamlConstants = require('./saml');
const SubscriptionsConstants = require('./subscriptions');
const Support = require('./support');
const SystemNotifications = require('./system-notifications');
const Documents = require('./documents');
const Filters = require('./filters');
const Folders = require('./folders');
const ChangeStreamProducerConstants = require('./changeStreamProducer');
const Validation = require('./validation');
const ImportTypes = require('./importTypes');
const Signing = require('./signing');
const MonitorReviews = require('./monitor-reviews');
const MonitorReviewEvents = require('./monitor-review-events');
const Newrelic = require('./newrelic');
const { entities, collections } = require('./entities');
const { isValueDefined } = require('./value-defined');
const { AUTH_STRATEGIES } = require('./auth');
const Timestamps = require('./timestamps');
const ListQueryFields = require('./list-query-fields');
const QCReviewsConstants = require('./qc-reviews');
const StandardRolesPermissions = require('./standard-roles-permissions');
const WorkflowApiConstants = require('./workflow-api');
const LanguagesRegex = require('./languages-regex');
const InternalEvents = require('./internal-audit-trails/index');
const Extensions = require('./extensions');
const Tags = require('./tags');
const FriendlyAuditTrailEvents = require('./friendly-audit-trail-events');
const Events = require('./events');
const EventsToCategory = require('./events-to-category');
const PrivilegeIds = require('./privilege-ids');
const {
    privs,
    userPrivs,
    teamPrivs,
    binderPrivs,
    folderPrivs,
    documentPrivs,
    downloadPrivs
} = require('./privileges');
const AuditTrailCategories = require('./audit-trail-categories');
const UserRoles = require('./user-roles');

exports.AUTH_STRATEGIES = AUTH_STRATEGIES;

exports.documents = Documents;
exports.filters = Filters;
exports.folders = Folders;
exports.actionGroups = ActionGroups;
exports.monitorReviews = MonitorReviews;
exports.monitorReviewEvents = MonitorReviewEvents;
exports.systemNotifications = SystemNotifications;
exports.newrelic = Newrelic;
exports.publicApiRouteConstants = PublicApiRouteConstants;
exports.timestamps = Timestamps;
exports.listQueryFields = ListQueryFields;
exports.standardRolesPermissions = StandardRolesPermissions;
exports.workflowApiConstants = WorkflowApiConstants;
exports.createValidatorWith = Validation.createValidatorWith;
exports.userRoles = UserRoles;

const internals = {};
Object.assign(exports, AuditTrailsConstants);
Object.assign(exports, AnnotationConstants);
Object.assign(exports, AuditTrailsConstants);
Object.assign(exports, Downloads);
Object.assign(exports, DocumentPropertiesConstants);
Object.assign(exports, NamesConstants);
Object.assign(exports, Projects);
Object.assign(exports, RolesConstants);
Object.assign(exports, SamlConstants);
Object.assign(exports, SubscriptionsConstants);
Object.assign(exports, Support);
Object.assign(exports, ChangeStreamProducerConstants);
Object.assign(exports, Validation);
Object.assign(exports, ImportTypes);
Object.assign(exports, Signing);
Object.assign(exports, WorkflowApiConstants);
Object.assign(exports, LanguagesRegex);
Object.assign(exports, InternalEvents);

exports.NODE_ENVS = {
    DEVELOPMENT: 'development',
    BUILD: 'build',
    UAT: 'uat',
    QA: 'qa',
    FATE: 'fate',
    PRODUCTION: 'production',
    TEST: 'test'
};

const oneGb = 1073741824;
const oneMb = 1048576;

exports.MAX_DATE = new Date('9999-09-09T00:00:00.000Z');

exports.REGEXES = Validation.REGEXES;
exports.VALIDATION = Validation.VALIDATION;
exports.DEFAULT_RETENTION_PERIOD_YEARS = 25;

exports.SIGNING_REASONS = Signing.SIGNING_REASONS;
exports.SIGNING_METHODS = Signing.SIGNING_METHODS;
exports.SIGNING_DECLINE_REASON = Signing.SIGNING_DECLINE_REASON;
exports.SIGNING_STATUS = Signing.SIGNING_STATUS;

exports.SOCKET_TIMEOUT = 1000 * 60 * 16; // 16 min. To override default node socket timeout for uploads
exports.SERVER_TIMEOUT = 1000 * 60 * 15; // 15 min. To set server processing timeout for uploads. Must be less than socket timeout
exports.PAYLOAD_TIMEOUT = 1000 * 60 * 15; // 15 min. To set timeout for client to complete upload
// 60 min. To override default node socket timeout for audit template execution
exports.ROLE_TEMPLATES_AUDIT_SOCKET_TIMEOUT = 1000 * 60 * 60;
// 59 min. To set server processing timeout for audit template execution. Must be less than socket timeout
exports.ROLE_TEMPLATES_AUDIT_SERVER_TIMEOUT = 1000 * 60 * 59;
exports.ALLOWED_FILE_TYPES = [
    'png',
    'jpg',
    'jpeg',
    'pdf',
    'gif',
    'tif',
    'tiff',
    'doc',
    'docx',
    'ppt',
    'pptx',
    'xls',
    'xlsx',
    'txt',
    'xps',
    'rtf'
];
exports.EXCEL_FILE_TYPES = {
    xla: '.xla',
    xlam: '.xlam',
    xls: '.xls',
    xlsb: '.xlsb',
    xlsm: '.xlsm',
    xlsx: '.xlsx',
    xlt: '.xlt',
    xltm: '.xltm',
    xltx: '.xltx',
    xlw: '.xlw'
};
exports.ASYNC_LIMIT = 10;
exports.BATCH_OF_TEN = 10;
exports.MAX_FILE_SIZE = oneGb * 20; // 20 gb
exports.MAX_CONVERSION_SIZE = oneMb * 75; // 75 mb
exports.INVALID_CREDENTIALS_MESSAGE = 'Your email or password was incorrect. Please try again.';
exports.PASSWORD_EXPIRED_MESSAGE = 'Your password has expired!';
exports.TOO_MANY_ANNOTATIONS = 'Your document has too many annotations. Please reduce the number of annotations to save your document successfully.';
exports.WILDCARD = '*';
exports.STATUS_CODES = {
    FAILED_DEPENDENCY: 424
};
exports.ARCHIVE_STATUSES = {
    scheduled: 'SCHEDULED',
    inProgress: 'IN_PROGRESS',
    complete: 'COMPLETE',
    failed: 'FAILED'
};
exports.FILES = {
    // To be used on the website. (300x110)
    LOGO_SRC: 'https://s3.amazonaws.com/com.researchbinders.assets/logos/Florence-eBinder-logo.png',
    // To be used with email templates (225x56)
    LOGO_HORIZONTAL_SRC: 'https://s3.amazonaws.com/com.researchbinders.assets/logos/Florence-eBinder-logo-v2.png',
    ICON_CHECKBOX: 'https://s3.amazonaws.com/com.researchbinders.assets/email-content/checkbox.png',
    ICON_WARNING: 'https://s3.amazonaws.com/com.researchbinders.assets/email-content/warning.png'
};
exports.SITE = {
    NAME: 'Florence'
};
exports.NOTIFIERS = {
    EMAIL: 'Email',
    SLACK: 'Slack',
    SQS: 'sqs',
    SQS_BATCH: 'sqs-batch'
};

exports.EMAILS_VIA_SQS = {
    BATCH_SIZE: 10,
    ZENDESK_URL_SITE_CLOSEOUT_PROCEDURES: 'https://florencehealthcare.zendesk.com/hc/en-us/articles/14056282439443-Site-Closeout-Procedures'
};

exports.AUDIT_TRAIL = 'auditTrail';
exports.ARCHIVE = 'archive';
exports.ACCESS_DATES_REPORT = 'accessDatesReport';
exports.REPORT = 'report';

exports.AUDIT_TRAIL_SUBJECTS = {
    BINDER: 'binder',
    DOCUMENT: 'document',
    FOLDER: 'folder',
    USERS: 'users',
    ROLE: 'role',
    TAG: 'tags',
    LOG_TEMPLATE: 'log_template',
    LOG_ENTRY: 'log_entry',
    TEAM_PROFILE: 'team_profile',
    TEAM_MEMBERS: 'team_members',
    ANNOUNCEMENTS: 'announcements',
    USER_PROFILE: 'user_profile',
    STUDY_STRUCTURE_TEMPLATES: 'study_structure_templates'
};
exports.AUDIT_TRAIL_CATEGORIES = AuditTrailCategories;
exports.AUDIT_TRAIL_FILTERS = {
    EMAIL: 'email',
    EVENT_TYPE: 'eventType',
    FRIENDLY_NAME: 'friendlyName'
};
exports.AUDIT_TRAIL_FILTERS_NAMES = _.map(exports.AUDIT_TRAIL_FILTERS, (name, key) => name);
exports.ACCESS_DATES_REPORT_FILTERS = {
    NAME: 'name',
    ROLE: 'role',
    WHO: 'who'
};
exports.NEW_ACCESS_DATES_FILTERS = {
    name: 'userName',
    role: 'roles.name'
};
exports.NEW_ACCESS_DATES_SORT_BY = {
    end: 'roles.end',
    start: 'roles.start'
};

exports.REPORT_NAMES = {
    pastDueDates: 'Placeholders Past Due',
    dueDates: 'Placeholders Due',
    dueDates2Days: 'Placeholders Due - In 2 Days',
    dueDates14Days: 'Placeholders Due - In 14 Days',
    dueDates30Days: 'Placeholders Due - In 30 Days',
    dueDates60Days: 'Placeholders Due - In 60 Days',
    documentsRecentlyUploaded: 'Documents - Recently Uploaded',
    pastExpirationDates: 'Documents Expired',
    expirationDates: 'Documents Expiring',
    expirationDates2Days: 'Documents Expiring - In 2 Days',
    expirationDates14Days: 'Documents Expiring - In 14 Days',
    expirationDates30Days: 'Documents Expiring - In 30 Days',
    expirationDates60Days: 'Documents Expiring - In 60 Days',
    signaturesCompleted: 'Signatures - Completed',
    signaturesMyQueue: 'Signatures - My Queue',
    signaturesPending: 'Signatures - Pending',
    signaturesDeclined: 'Signatures - Declined',
    signaturesSignByDate: 'Signatures - Sign By Date',
    studyAttributes: 'Study Profile',
    tasksClosed: 'Tasks - Closed',
    tasksMyQueue: 'Tasks - My Queue',
    tasksPending: 'Tasks - Pending',
    approvalsApproved: 'Approvals - Approved',
    approvalsRejected: 'Approvals - Rejected',
    approvalsCancelled: 'Approvals - Cancelled',
    approvalsPendingSignatures: 'Approvals - Pending Signatures',
    approvalsReadyForApproval: 'Approvals - Ready for Approval',
    approvalsPendingForm: 'Approvals - Pending Form Completion',
    labels: 'Labels - Assigned',
    tags: 'Tags',
    monitorReviewsOpen: 'Monitor Reviews - Open Query',
    monitorReviewsApproved: 'Monitor Reviews - Approved and Reviewed',
    documentStatus: 'SIP - Document Statuses',
    documentSent: 'SIP - Documents Sent',
    qcReviewGeneral: 'QC Review - General',
    qcReviewMyQueue: 'QC Review - My Queue',
    documentsOverview: 'Documents - Overview',
};

exports.COLLECTIONS = collections;
exports.ENTITIES = entities;

const privObjTypes = [
    collections.TEAM,
    collections.USER,
    collections.BINDER,
    collections.FOLDER,
    collections.DOCUMENT,
    collections.ROLE,
    collections.TAG,
    collections.DOWNLOAD,
    collections.ANNOUNCEMENT
];

exports.PRIVILEGE_OBJECT_TYPES = privObjTypes;
exports.extensions = Extensions;

const entitiesToClxns = {};
exports.ENTITIES_TO_CLXNS = entitiesToClxns;
_.forEach(exports.ENTITIES, (val, key) => {

    entitiesToClxns[val] = collections[key];
});
const clxnsToEntities = {};
exports.CLXNS_TO_ENTITIES = clxnsToEntities;
_.forEach(exports.COLLECTIONS, (val, key) => {

    clxnsToEntities[val] = entities[key];
});
internals.downloadHierarchy = [collections.TEAM, collections.USER, collections.DOWNLOAD];
internals.objHierarchy = [collections.TEAM, collections.BINDER, collections.FOLDER, collections.DOCUMENT];
internals.announcementHierarchy = [collections.TEAM, collections.ANNOUNCEMENT];

exports.DOCUMENT_SOURCE_TYPES = {
    EMAIL_CONNECTOR: 'EMAIL_CONNECTOR',
    MANUAL: 'MANUAL'
};
exports.FRIENDLY_SOURCE_TYPES = {};
exports.FRIENDLY_SOURCE_TYPES[exports.DOCUMENT_SOURCE_TYPES.EMAIL_CONNECTOR] = 'Email Connector';
exports.FRIENDLY_SOURCE_TYPES[exports.DOCUMENT_SOURCE_TYPES.MANUAL] = 'Manual';
exports.DOCUMENT_RELATIONSHIPS = {
    EMAIL_ATTACHMENT: 'EMAIL_ATTACHMENT',
    EMAIL_COMMUNICATION: 'EMAIL_COMMUNICATION'
};
exports.FRIENDLY_DOCUMENT_RELATIONSHIPS = {};
exports.FRIENDLY_DOCUMENT_RELATIONSHIPS[exports.DOCUMENT_RELATIONSHIPS.EMAIL_ATTACHMENT] = 'Email Attachment';
exports.FRIENDLY_DOCUMENT_RELATIONSHIPS[exports.DOCUMENT_RELATIONSHIPS.EMAIL_COMMUNICATION] = 'Email Communication';

exports.MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

exports.SHORTCUT_SUFFIX = ' - Shortcut';
// for logs
exports.TAGS = Tags;
// for system generated tag entitied
exports.SYSTEM_TAG_NAMES = {
    EMAIL_TAG: 'Imported Via Email'
};
exports.LOG_LEVEL = {
    error: 'error',
    warn: 'warn',
    debug: 'debug',
    trace: 'trace',
    verbose: 'verbose' // All
};
exports.VIRTUAL_ENTITIES = {
    PLACEHOLDER: 'placeholder'
};
exports.IMPORT_TYPES = ImportTypes.IMPORT_TYPES;
exports.QC_REVIEWS_STATUSES = QCReviewsConstants.qcReviewStatuses;

exports.FRIENDLY_IMPORT_TYPES = {};
exports.FRIENDLY_IMPORT_TYPES[exports.IMPORT_TYPES.PLACEHOLDER] = 'Placeholder';
exports.FRIENDLY_IMPORT_TYPES[exports.IMPORT_TYPES.FAX] = 'Fax Connector';
exports.FRIENDLY_IMPORT_TYPES[exports.IMPORT_TYPES.EMAIL] = 'Email Attachment';
exports.FRIENDLY_IMPORT_TYPES[exports.IMPORT_TYPES.EMAIL_CONTENT] = 'Email Communication';
exports.FRIENDLY_IMPORT_TYPES[exports.IMPORT_TYPES.PRINTER] = 'Printer Connector';
exports.FRIENDLY_IMPORT_TYPES[exports.IMPORT_TYPES.DIRECT] = 'Direct';
exports.FRIENDLY_IMPORT_TYPES[exports.IMPORT_TYPES.MIGRATION] = 'Migrated';
exports.FRIENDLY_IMPORT_TYPES[exports.IMPORT_TYPES.SHORTCUT] = 'Shortcut';
exports.FRIENDLY_IMPORT_TYPES[exports.IMPORT_TYPES.DUPLICATE] = 'Duplicate';
exports.FRIENDLY_IMPORT_TYPES[exports.IMPORT_TYPES.MOVE] = 'Move';
exports.FRIENDLY_IMPORT_TYPES[exports.IMPORT_TYPES.API] = 'API';
exports.FRIENDLY_IMPORT_TYPES[exports.IMPORT_TYPES.SIP_INTEGRATION] = 'SIP Integration';
exports.FRIENDLY_IMPORT_TYPES[exports.IMPORT_TYPES.EHUB] = 'SiteLink\u2122 Bulk Distribution';
exports.FRIENDLY_IMPORT_TYPES[exports.IMPORT_TYPES.EHUB_LOG_DISTRIBUTION] = 'SiteLink\u2122 eLog Distribution';
exports.FRIENDLY_IMPORT_TYPES[exports.IMPORT_TYPES.DOC_SHARING] = 'Document Sharing';
exports.FRIENDLY_IMPORT_TYPES[exports.IMPORT_TYPES.ECONSENT] = 'eConsent';
exports.FRIENDLY_IMPORT_TYPES[exports.IMPORT_TYPES.PLACEHOLDER_FILL_NEW_LOG] = 'New Log';

exports.ALLOWED_MRM_DOCUMENT_TYPES = ['content', 'log', 'placeholder', 'shortcut'];
exports.DOCUMENT_TYPES = Documents.DOCUMENT_TYPES;
exports.DOCUMENT_ACTIONS = Documents.DOCUMENT_ACTIONS;
exports.TARGET_TYPES = {
    BINDER: 'binder',
    FOLDER: 'folder',
    PLACEHOLDER: 'placeholder'
};

exports.DOCUMENT_SIP_STATUSES = {
    APPROVED: 'Approved',
    REJECTED: 'Rejected',
    REQUEST_FOR_REVISION: 'Request for Revision'
};

exports.DOCUMENT_SIP_SENT_STATUSES = {
    SUCCESS: 'success',
    FAIL: 'fail'
};

exports.INTEGRATIONS = {
    SIP: 'SIP'
};

exports.REPORT_FILTER_TYPES = {
    TEXT: 'text',
    SELECT: 'select'
};

exports.EVENTS = Events;
exports.FRIENDLY_EVENT_NAMES = FriendlyAuditTrailEvents;

exports.eventToCategory = (event) => (EventsToCategory[event] || exports.AUDIT_TRAIL_CATEGORIES.UNCATEGORIZED);
const categoriesToEvents = _.invertBy(EventsToCategory);
exports.categoryToEvents = (category) => categoriesToEvents[category];

exports.DOCUMENT_HISTORY_EVENTS = Object.values(EventsToCategory).reduce((hash, event) => {

    switch (event) {
        case FriendlyAuditTrailEvents[Events.DOCUMENT_HIGHLIGHTED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_REDACTED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_PLACED_SIGNATURE]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_REPLACED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_REPLACED_VIA_EMAIL]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_SIGNED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_FORM_FINALIZED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_FORM_UPDATED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_CLONED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_IMPORTED_VIA_EMAIL]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_IMPORTED_VIA_EHUB]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_CREATED]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_CREATED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_NOTATED]:
        case FriendlyAuditTrailEvents[Events.TEXT_ANNOTATION_ADDED_TO_DOCUMENT]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_MARKED_AS_PHI]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_UNMARKED_AS_PHI]:
            // eslint-disable-next-line no-param-reassign
            hash[event] = event;
            break;
        default:
            break;
    }
    return hash;
}, {});

exports.ANNOUNCEMENT_RECIPIENT_REASONS = {
    DIRECT: 'direct',
    INHERITED: 'inherited'
};

exports.TASK_TYPES = {
    requestRevision: 'Request Revision'
};
exports.TASK_STATUS = {
    todo: 'Todo',
    inProgress: 'In Progress',
    complete: 'Complete',
    closed: 'Closed'
};
exports.CONVERSION_STATUSES = {
    na: 'N/A',
    skip: 'Skip',
    pending: 'Pending',
    complete: 'Complete',
    failed: 'Failed',
    inProgress: 'In Progress',
    tooLarge: 'Too Large',
    unsupported: 'Unsupported File Type'
};
exports.FORM_STATUSES = {
    CHECKING_FORM: 'checking-form',
    NO_FORM: 'no-form',
    FORM_IN_PROGRESS: 'form-in-progress',
    FORM_FINALIZED: 'form-finalized'
};
exports.QUERY_TYPES = {
    dueExpiring: 'dueExpiring',
    due: 'due',
    expiring: 'expiring',
    signatureQueue: 'signatureQueue',
    signatureReport: 'signatureReport',
    signatureSignByDate: 'signatureSignByDate',
    studyAttributes: 'studyAttributes',
    taskQueue: 'taskQueue',
    taskReport: 'taskReport',
    tagsReport: 'tagsReport',
    labelsReport: 'labelsReport',
    approvalsApproved: 'approvalsApproved',
    approvalsRejected: 'approvalsRejected',
    approvalsCancelled: 'approvalsCancelled',
    approvalsPendingSignatures: 'approvalsPendingSignatures',
    approvalsReadyForApproval: 'approvalsReadyForApproval',
    approvalsPendingForm: 'approvalsPendingForm',
    monitorReviewsOpenReport: 'monitorReviewsOpenReport',
    monitorReviewsApprovedReport: 'monitorReviewsApprovedReport'
};
exports.DIGESTS_EMAIL_TEMPLATE_TYPES = {
    due: 'due',
    expiring: 'expiring',
    signatureQueue: 'signatureQueue',
    taskQueue: 'taskQueue'
};
exports.SYSTEM_REPORT_NAMES = {
    due: 'Placeholders Due',
    due2Days: 'Placeholders Due - In 2 Days',
    due14Days: 'Placeholders Due - In 14 Days',
    due30Days: 'Placeholders Due - In 30 Days',
    due60Days: 'Placeholders Due - In 60 Days',
    expiring: 'Documents Expiring',
    expiring2Days: 'Documents Expiring - In 2 Days',
    expiring14Days: 'Documents Expiring - In 14 Days',
    expiring30Days: 'Documents Expiring - In 30 Days',
    expiring60Days: 'Documents Expiring - In 60 Days',
    signatureQueue: 'Signatures - My Queue',
    signatureSignByDate: 'Signatures - Sign By Date',
    signatureReportPending: 'Signatures - Pending',
    signatureReportCompleted: 'Signatures - Completed',
    studyAttributes: 'Study Profile',
    taskQueue: 'Tasks - My Queue',
    taskReportPending: 'Tasks - Pending',
    taskReportClosed: 'Tasks - Closed',
    tags: 'Tags',
    labels: 'Labels - Assigned',
    monitorReviewsOpen: 'Monitor Reviews - Open Query',
    monitorReviewsApproved: 'Monitor Reviews - Approved and Reviewed',
    documentStatus: 'SIP - Document Statuses',
    documentSent: 'SIP - Documents Sent',
    documentRecentlyUploaded: 'Documents - Recently Uploaded'
};
exports.SYSTEM_DIGEST_NAMES = {
    due2Days: 'Documents due in 2 days',
    due14Days: 'Documents due in 14 days',
    due30Days: 'Documents due in 30 days',
    due60Days: 'Documents due in 60 days',
    expiring2Days: 'Documents expiring in 2 days',
    expiring14Days: 'Documents expiring in 14 days',
    expiring30Days: 'Documents expiring in 30 days',
    expiring60Days: 'Documents expiring in 60 days',
    signatureQueue: 'Signatures in My Queue',
    taskQueue: 'Tasks in My Queue'
};
exports.SYSTEM_DIGEST_FRIENDLY_DIGEST_NAMES = {
    'Documents due in 2 days': 'Documents due in 2 days',
    'Documents due in 14 days': 'Documents due in 14 days',
    'Documents due in 30 days': 'Documents due in 30 days',
    'Documents due in 60 days': 'Documents due in 60 days',
    'Documents expiring in 2 days': 'Documents expiring in 2 days',
    'Documents expiring in 14 days': 'Documents expiring in 14 days',
    'Documents expiring in 30 days': 'Documents expiring in 30 days',
    'Documents expiring in 60 days': 'Documents expiring in 60 days',
    'Signatures in My Queue': 'Signatures in My Queue',
    'Tasks in My Queue': 'Tasks in My Queue'
};
exports.FREQUENCY_TYPES = {
    monthly: 'monthly',
    weekly: 'weekly',
    daily: 'daily',
    never: 'never'
};
exports.FREQUENCY_NAMES = {
    monthly: 'Monthly',
    weekly: 'Weekly',
    daily: 'Daily',
    never: 'Never'
};
exports.EXPORT_FILE_TEMPLATES = {
    auditTrail: 'auditTrail',
    accessDatesReport: 'accessDatesReport',
    permissions: 'permissions',
    reports: 'reports'
};
exports.EXPORT_FILE_FORMATS = {
    pdf: 'pdf',
    xlsx: 'xlsx',
    csv: 'csv',
    zip: 'zip',
    placeholder: 'txt',
    brokenShortcut: 'txt'
};

exports.PRIVILEGES = privs;

const internalRoleNames = {
    fhcAdmin: {
        id: 'css_admin',
        abbr: 'CSS',
        name: 'Customer Success Specialist'
    },
    binderOwner: {
        id: 'binder_owner',
        abbr: 'BO',
        name: 'Binder Owner'
    }
};

exports.INTERNAL_ROLE_NAMES = internalRoleNames;

const roleNames = {
    teamAdmin: {
        id: 'team_admin',
        abbr: 'TA',
        name: 'Florence API User Team Setup'
    }
};

exports.ROLE_NAMES = roleNames;

exports.TEST_ROLE_NAMES = {
    '12345 - Sponsor CRO Monitor': {
        id: '12345_sponsor_cro_monitor',
        abbr: '1scm',
        name: '12345 - Sponsor CRO Monitor'
    },
    'Practice 1 - Sponsor CRO Monitor': {
        id: 'practice_1_sponsor_cro_monitor',
        abbr: 'p1scm',
        name: 'Practice 1 - Sponsor CRO Monitor'
    },
    '12345 - Practice 1 CRO Monitor': {
        id: '12345_practice_1_sponsor_cro_monitor',
        abbr: '1p1scm',
        name: '12345 - Practice 1 CRO Monitor'
    },
    'Site Credentials - Credential A_A - Sponsor CRO Monitor': {
        id: 'credential_aa_sponsor_cro_monitor',
        abbr: 'caascm',
        name: 'Site Credentials - Credential A_A - Sponsor CRO Monitor'
    },
    'Credential B_B - Sponsor CRO Monitor': {
        id: 'credential_bb_sponsor_cro_monitor',
        abbr: 'cbbscm',
        name: 'Credential B_B - Sponsor CRO Monitor'
    }
};

exports.ROLE_TEMPLATES_AUDIT_ACTIONS = {
    PREVIEW: 'preview',
    CREATE: 'create'
};

exports.ROLE_TEMPLATES_AUDIT_STATUSES = {
    PENDING: 'pending',
    IN_PROGRESS: 'inProgress',
    COMPLETED: 'completed',
    FAILED: 'failed'
};

const roleNicknames = {
    'Team Admin': 'Team Owner'
};

exports.useRoleNickname = (roleName) => roleNicknames[roleName] || roleName;
exports.ROLE_NICKNAMES = roleNicknames;

const roleFns = {};
exports.ROLE_FUNCTIONS = roleFns;
const roleTpls = {};
exports.ROLE_TEMPLATES = roleTpls;

exports.PRIVILEGE_IDS = PrivilegeIds;
exports.FLORENCE_SUPPORT_USE_PRIVILEGES = [
    PrivilegeIds.team_manage,
    PrivilegeIds.team_manage_features,
    PrivilegeIds.paywalls_manage,
    PrivilegeIds.document_manage_monitor_reviews
];

exports.FLATTEN_PRIVILEGES = {
    ...userPrivs,
    ...teamPrivs,
    ...binderPrivs,
    ...folderPrivs,
    ...documentPrivs,
    ...downloadPrivs
};

const flatClientIdPrivs = {};
exports.PRIV_CLIENT_MAP = flatClientIdPrivs;
_.forEach(privs, (group, clxn) => {

    _.forEach(group, (obj) => {

        flatClientIdPrivs[obj.clientId] = obj;
    });
});

const clientIdToPrivId = {};
exports.CLIENT_PRIV_MAP = clientIdToPrivId;
_.forEach(privs, (group, groupName) => {

    clientIdToPrivId[groupName] = {};
    _.forEach(group, (priv, privId) => {

        clientIdToPrivId[priv.clientId] = privId;
    });
});

const privIdMap = {};
exports.PRIVILEGE_ID_MAP = privIdMap;
_.forEach(privs, (group, groupName) => {

    privIdMap[groupName] = {};
    _.forEach(group, (priv, privId) => {

        privIdMap[priv.id] = priv;
    });
});

const privIdToPrivMap = {};
exports.PRIVID_TO_PRIV = privIdToPrivMap;
_.forEach(privs, (group, groupName) => {

    _.forEach(group, (priv, privId) => {

        privIdToPrivMap[priv.id] = priv;
    });
});


roleTpls[internalRoleNames.fhcAdmin.id] = [
    {
        subjectId: null, // fhcAdmin roleId
        subjectType: collections.ROLE,
        objectId: '*',
        objectType: collections.TEAM,
        privilege: teamPrivs[PrivilegeIds.team_manage_features].id,
        teamId: '*',
        createdBy: 'SYSTEM'
    },
    {
        subjectId: null, // fhcAdmin roleId
        subjectType: collections.ROLE,
        objectId: '*',
        objectType: collections.TEAM,
        privilege: teamPrivs[PrivilegeIds.paywalls_manage].id,
        teamId: '*',
        createdBy: 'SYSTEM'
    },
    {
        subjectId: null, // fhcAdmin roleId
        subjectType: collections.ROLE,
        objectId: '*',
        objectType: collections.TEAM,
        privilege: teamPrivs[PrivilegeIds.team_member].id,
        teamId: '*',
        createdBy: 'SYSTEM'
    },
    {
        subjectId: null, // fhcAdmin roleId
        subjectType: collections.ROLE,
        objectId: '*',
        objectType: collections.TEAM,
        privilege: teamPrivs[PrivilegeIds.team_update_profile].id,
        teamId: '*',
        createdBy: 'SYSTEM'
    },
    {
        subjectId: null, // fhcAdmin roleId
        subjectType: collections.ROLE,
        objectId: '*',
        objectType: collections.TEAM,
        privilege: teamPrivs[PrivilegeIds.team_invite].id,
        teamId: '*',
        createdBy: 'SYSTEM'
    },
    {
        subjectId: null, // fhcAdmin roleId
        subjectType: collections.ROLE,
        objectId: '*',
        objectType: collections.TEAM,
        privilege: teamPrivs[PrivilegeIds.team_uninvite].id,
        teamId: '*',
        createdBy: 'SYSTEM'
    },
    {
        subjectId: null, // fhcAdmin roleId
        subjectType: collections.ROLE,
        objectId: '*',
        objectType: collections.TEAM,
        privilege: teamPrivs[PrivilegeIds.team_view_profile].id,
        teamId: '*',
        createdBy: 'SYSTEM'
    },
    {
        subjectId: null, // fhcAdmin roleId
        subjectType: collections.ROLE,
        objectId: '*',
        objectType: collections.USER,
        privilege: userPrivs[PrivilegeIds.user_view_profile].id,
        teamId: '*',
        createdBy: 'SYSTEM'
    },
    {
        subjectId: null, // fhcAdmin roleId
        subjectType: collections.ROLE,
        objectId: '*',
        objectType: collections.USER,
        privilege: userPrivs[PrivilegeIds.user_view_teams].id,
        teamId: '*',
        createdBy: 'SYSTEM'
    },
    {
        subjectId: null, // fhcAdmin roleId
        subjectType: collections.ROLE,
        objectId: '*',
        objectType: collections.USER,
        privilege: userPrivs[PrivilegeIds.user_update_users_subscriptions].id,
        teamId: '*',
        createdBy: 'SYSTEM'
    }
];
roleTpls[roleNames.teamAdmin.id] = [
    {
        subjectId: null, // teamAdmin roleId
        subjectType: collections.ROLE,
        objectId: null, // teamId
        objectType: collections.TEAM,
        privilege: teamPrivs[PrivilegeIds.team_manage].id,
        teamId: null, // teamId
        createdBy: null // creator id
    },
    {
        subjectId: null, // teamAdmin roleId
        subjectType: collections.ROLE,
        objectId: null, // teamId
        objectType: collections.TEAM,
        privilege: teamPrivs[PrivilegeIds.password_policy_manage].id,
        teamId: null, // teamId
        createdBy: null // creator id
    },
    {
        subjectId: null, // teamAdmin roleId
        subjectType: collections.ROLE,
        objectId: null, // teamId
        objectType: collections.TEAM,
        privilege: teamPrivs[PrivilegeIds.signing_pin_policy_manage].id,
        teamId: null, // teamId
        createdBy: null // creator id
    },
    {
        subjectId: null, // teamAdmin roleId
        subjectType: collections.ROLE,
        objectId: null, // teamId
        objectType: collections.TEAM,
        privilege: teamPrivs[PrivilegeIds.signature_options_manage].id,
        teamId: null, // teamId
        createdBy: null // creator id
    }
];
roleTpls[internalRoleNames.binderOwner.id] = [
    {
        subjectId: null, // binder owner role id
        subjectType: collections.ROLE,
        objectId: null, // binderId
        objectType: collections.BINDER,
        privilege: binderPrivs[PrivilegeIds.binder_manage].id,
        teamId: null, // teamId
        createdBy: null // creator id
    }
];

/**
 * Clones and populates the fhcAdmin role
 *
 * @param {Object} params
 * @param {string} subjectId - The subject id
 * @returns {Array} - The populated template
 */
roleFns[internalRoleNames.fhcAdmin.id] = (params) => {

    const fhcAd = _.cloneDeep(roleTpls[internalRoleNames.fhcAdmin.id]);
    _.forEach(fhcAd, (perm) => {

        perm.subjectId = params.subjectId;
    });
    return fhcAd;
};

/**
 * Clones and populates the teamAdmin role
 *
 * @param {Object} params
 * @param {string} params.creatorId - The id of the user creating this role
 * @param {string} params.subjectId - The subject id (roleId)
 * @param {string} params.teamId - The team id
 *
 * @returns {Array} - The populated template
 */
roleFns[roleNames.teamAdmin.id] = (params) => {

    const teamAd = _.cloneDeep(roleTpls[roleNames.teamAdmin.id]);
    _.forEach(teamAd, (perm) => {

        perm.objectId = params.teamId;
        perm.subjectId = params.subjectId;
        perm.teamId = params.teamId;
        perm.createdBy = params.creatorId;
    });
    return teamAd;
};

/**
 * Clones and populates the binder owner role
 *
 * @param {Object} params
 * @param {string} params.creatorId - The id of the user creating this role
 * @param {string} params.subjectId - The subject id (roleId)
 * @param {string} params.objectId - The object id (binderId)
 * @param {string} params.teamId - The team id
 *
 * @returns {Array} - The populated template
 */
roleFns[internalRoleNames.binderOwner.id] = (params) => {

    const binderOwner = _.cloneDeep(roleTpls[internalRoleNames.binderOwner.id]);
    _.forEach(binderOwner, (perm) => {

        perm.objectId = params.objectId;
        perm.subjectId = params.subjectId;
        perm.teamId = params.teamId;
        perm.createdBy = params.creatorId;
    });
    return binderOwner;
};

/**
 * Object that maps team membership privilege to what team object fields user can get
 */
exports.TEAM_MEMBERSHIP_MAPPING = {
    [PrivilegeIds.team_manage]: [],
    [PrivilegeIds.team_member]: ['name', 'description', 'createdAt', 'createdBy', 'modifiedBy', 'modifiedAt'],
    [PrivilegeIds.team_member_limited]: ['name', 'description', 'createdAt', 'createdBy', 'modifiedBy', 'modifiedAt']
};

/**
 * Gets a string array of all privileges in a hierarchy from
 * the given privilegeId upwards
 *
 * @param {string} A privilege id as listed in CONSTANTS.PRIVILEGE_IDS
 * @return {Array} A string array of all privileges in a hierarchy from
 * the given privilegeId upwards
 */
const getPrivilegesInHierarchy = (privilegeId) => {

    const privilege = internals._getPrivSpec(privilegeId).priv;
    const parentIds = privilege.parents;
    let allIds = [privilegeId];
    _.forEach(parentIds, (parentId) => {

        const res = getPrivilegesInHierarchy(parentId);
        allIds = _.flatten([allIds, res]);
    });
    return _.uniq(allIds);
};

exports.getPrivilegesForVisibility = () => getPrivilegesInHierarchy(PrivilegeIds.document_view_pii).concat(getPrivilegesInHierarchy(PrivilegeIds.document_view_non_pii));
exports.getPrivilegesInHierarchy = getPrivilegesInHierarchy;

/**
 * Gets the privilege spec of a given privilege id
 *
 * @param {string} privId - The id of the privilege spec to get
 * @returns {Object} - The privilege spec
 * @private
 */
internals._getPrivSpec = (privId) => {

    let groupName;
    _.some(privs, (group, clxnName) => {

        if (group[privId]) {
            groupName = clxnName;
            return true;
        }
    });
    return { priv: privs[groupName][privId], clxn: groupName };
};


/**
 * Gets the child privileges of a given type of a given privilege.
 *
 * @param {string} privilegeId - The privilege id to get the children of
 * @param {string} entityType - A valid privilege entity type, i.e. users,
 * teams, binders, folders, or documents.
 * @param {string} prop - The property to return, if omitted, the whole
 * privilege object is returned
 * @returns {Array} An array of the child privileges
 */
exports.getChildPrivileges = (privilegeId, clxn, prop) => {

    if (prop) {
        Assert(prop !== 'id' || prop !== 'clientId', 'Invalid property to retrieve from a privilege object.');
    }
    const children = [];
    const clxns = internals._getSubTypesInHierarchy(clxn);
    _.forEach(clxns, (c) => {

        _.forEach(privs[c], (privilege) => {

            const parents = getPrivilegesInHierarchy(privilege.id);
            if (parents.indexOf(privilegeId) > -1) {
                children.push(prop ? privilege[prop] : privilege);
            }
        });
    });
    return children;
};

/**
 * Gets a nested structure of privileges
 *
 * @param {string} clxn - The collection name get a stubbed privilege hierarchy for
 * @returns {Object} A hierarchical structure of privileges from the given clxn down
 */
exports.getPrivilegeHierarchyOfType = (clxn) => {

    const hash = {};
    const colls = internals._getSubTypesInHierarchy(clxn);
    let allPaths = [];
    _.forEach(colls, (c) => {

        _.forEach(privs[c], (privilege) => {

            const paths = internals._getPrivilegePaths(privilege, clxn);
            allPaths.push(paths);
        });
    });
    allPaths = _.flatten(allPaths);
    allPaths = _.sortBy(allPaths, (path) => path);
    const reversedPaths = [];
    _.forEach(allPaths, (path) => {

        reversedPaths.unshift(path);
    });
    const pathHash = {};
    _.forEach(allPaths, (path) => {

        const arr = path.split('.');
        const last = arr[arr.length - 1];
        pathHash[path] = last;
    });
    _.forEach(pathHash, (clientPrivId, path) => {

        const priv = flatClientIdPrivs[clientPrivId];
        _.set(hash, path, {
            name: priv.name,
            description: priv.description,
            has: false,
            isHidden: priv.isHidden,
            clientId: clientPrivId,
            parents: priv.parents
        });
    });
    internals._decorateHashWithAllChildrenHiddenProps(hash, reversedPaths);
    return hash;
};

/**
 * Adds an 'allChildrenHidden' property to parent permissions which is true if
 * all of their children are hidden, else false.
 *
 * @param {Object} hash - The hash representing the path array in object format
 * @param {Array} paths - The string array of paths in reverse alphabetical order
 * @private
 */
internals._decorateHashWithAllChildrenHiddenProps = (hash, paths) => {

    let curPathPrefix = '';
    let lastPathPrefix;
    let pathIsHidden = true;
    _.forEach(paths, (path) => {

        const clientPrivId = internals._getClientIdFromPath(path);
        const priv = flatClientIdPrivs[clientPrivId];

        curPathPrefix = internals._derivePathPrefix(path, priv.clientId);

        if (curPathPrefix === lastPathPrefix) {
            pathIsHidden = priv.isHidden && pathIsHidden;
        }
        const parentPath = exports.deriveParentPath(path, priv.clientId);
        if (parentPath) {
            const val = _.get(hash, `${parentPath}.allChildrenHidden`);
            const setPath = `${parentPath}.allChildrenHidden`;
            const setVal = (val) ? priv.isHidden && val : priv.isHidden;
            _.set(hash, setPath, setVal);
        }
        lastPathPrefix = curPathPrefix;
    });
};

/**
 * Gets the path prefix of a given path. i.e. the path except the final clientId
 *
 * @param {string} path - The path
 * @param {string} clientId - The final clientId in the path
 * @returns {string} - The path without the final clientId
 * @private
 */
internals._derivePathPrefix = (path, clientId) => {

    let pathPrefix = '';
    if (path.indexOf('.') > -1) {
        const len = path.length - clientId.length;
        pathPrefix = path.substring(0, len);
    }
    return pathPrefix;
};

/**
 * Gets the parent privilege path of a given privilege path
 *
 * @param {string} path - The path
 * @param {string} clientId - The final clientId in the path
 * @returns {string} - The parent path
 * @private
 */
exports.deriveParentPath = (path, clientId) => {

    let pathPrefix = '';
    const str = '.permissions.';
    if (path.indexOf('.') > -1) {
        const len = path.length - clientId.length - str.length;
        pathPrefix = path.substring(0, len);
    }
    return pathPrefix;
};

/**
 * Gets the final clientId of a path
 *
 * @param {string} path - The path
 * @returns {string} - The last clientId in the path
 * @private
 */
internals._getClientIdFromPath = (path) => {

    const lastDotIdx = path.lastIndexOf('.');
    return path.substring(lastDotIdx + 1);
};


/**
 * Gets all paths in the hierarchy for the given privilege object
 *
 * @param {Object} privilege - A privilege object as declared above at exports.PRIVILEGES
 * @param {Object} clxn - The limiting collection of the privilege hierarchy
 * @returns {Object} - An object of string (dot delimited) paths as keys and the clientId (last item in the path)
 * as the value
 * @private
 */
exports.getPrivilegePaths = (privilege, clxn) => internals._getPrivilegePaths(privilege, clxn);

/**
 * Gets all paths in the hierarchy for the given privilege object
 *
 * @param {Object} privilege - A privilege object as declared above at exports.PRIVILEGES
 * @param {Object} clxn - The limiting collection of the privilege hierarchy
 * @returns {Object} - An object of string (dot delimited) paths as keys and the clientId (last item in the path)
 * as the value
 * @private
 */
internals._getPrivilegePaths = (privilege, clxn) => {

    const paths = [];
    const currentPath = `${privilege.clientId}.`;
    internals._getPrivPathsRecursive(privilege, currentPath, paths, clxn);
    const finalPaths = [];
    _.forEach(paths, (path) => {

        const arr = path.split('.');
        const reverse = _.reverse(arr);
        const injectedArr = [];
        for (let i = 0; i < reverse.length; ++i) {

            const el = reverse[i];
            injectedArr.push(el);
            if (i < reverse.length - 1) {
                injectedArr.push('permissions');
            }
        }
        finalPaths.push(injectedArr.join('.'));
    });
    return finalPaths;
};

/**
 * Recursive helper to get the paths
 *
 * @param {Object} privilege - A privilege object as declared above at exports.PRIVILEGES
 * @param {string} currentPath - The current path being built
 * @param {Array} paths - The array of paths being populated
 * @private
 */
internals._getPrivPathsRecursive = (privilege, currentPath, paths, clxn) => {

    const parentIds = privilege.parents || [];
    if (parentIds.length > 1) {
        const newPath = currentPath;
        for (let i = 0; i < parentIds.length; ++i) {

            const parentId = parentIds[i];
            const spec = internals._getPrivSpec(parentId);
            const parentPrivilege = spec.priv;
            const collection = spec.clxn;
            // if collection <= clxn in the hierarchy continue
            const isLte = internals._isLteInHierarchy(collection, clxn);
            if (isLte) {
                const parentPath = `${newPath}${parentPrivilege.clientId}.`;
                internals._getPrivPathsRecursive(parentPrivilege, parentPath, paths, clxn);
            }
            else {
                // paths.push(newPath.slice(0, newPath.length - 1));
                currentPath = '';
            }
        }
    }
    else if (parentIds.length === 1) {

        const parentId = parentIds[0];
        const spec = internals._getPrivSpec(parentId);
        const parentPrivilege = spec.priv;
        const collection = spec.clxn;
        const isLte = internals._isLteInHierarchy(collection, clxn);
        if (isLte) {
            currentPath += `${parentPrivilege.clientId}.`;
            internals._getPrivPathsRecursive(parentPrivilege, currentPath, paths, clxn);
        }
        else {
            paths.push(currentPath.slice(0, currentPath.length - 1));
            currentPath = '';
        }
    }
    else {
        paths.push(currentPath.slice(0, currentPath.length - 1));
        currentPath = '';
    }
};

/**
 * Determines if the first collection name is the same as or occurs lower in the
 * hierarchy than the second collection name
 *
 * @param {string} clxn1 - The first collection name
 * @param {string} clxn2 - The second collection name
 * @returns {boolean} - True if the first collection is the same as or occurs lower in the
 * hierarchy than the second collection name, else false
 * @private
 */
internals._isLteInHierarchy = (clxn1, clxn2) => {

    const clxnHierarchy = exports.getTypesInHierarchy(clxn1);
    if (clxnHierarchy.indexOf(clxn2) > -1) {
        return true;
    }
    return false;
};


/**
 * Gets a hash for a given privilege type with the privilege ids
 * as keys and false as the values
 *
 * @param {string} clxn - The privilege type. i.e. users,
 * teams, binders, folders, or documents.
 * @returns {Object} The hash of privilege ids
 */
exports.getPrivilegeHashOfType = (clxn) => {

    const hash = {};
    const clxns = internals._getSubTypesInHierarchy(clxn);
    _.forEach(clxns, (c) => {

        _.forEach(privs[c], (privilege) => {

            hash[privilege.clientId] = false;
        });
    });

    return hash;
};

/**
 * Gets the type hierarchy from the given starting point. There are two type hierarchies
 * in the system. One for users which consists of just teams, users and downloads. This is said in
 * the sense that privileges on a user are had in the context of a team. The second
 * hierarchy is for the system objects (teams, binders, folders, and documents).
 *
 * @param {string} type - One of the valid privilege object types (teams, users, binders, folders, documents)
 * @returns {Array} - A string array of all the objectTypes in the hierarchy from (and
 * including) the given type and upwards.
 */
exports.getTypesInHierarchy = (type) => {

    Assert.ok(privObjTypes.indexOf(type) >= 0, `${type} is not a valid privilege objectType`);

    const downloadHierarchyIndex = internals.downloadHierarchy.indexOf(type);
    const announcementHierarchyIndex = internals.announcementHierarchy.indexOf(type);
    if (downloadHierarchyIndex !== -1) {
        return internals.downloadHierarchy.slice(0, downloadHierarchyIndex + 1);
    }
    if (announcementHierarchyIndex !== -1) {
        return internals.announcementHierarchy.slice(0, announcementHierarchyIndex + 1);
    }
    const idx = internals.objHierarchy.indexOf(type);
    return internals.objHierarchy.slice(0, idx + 1);
};

/**
 * Extracts permission name from a permission DB object
 * @param {Array} perm -
 * @param {Object} opts -
 * @param {Object} opts.includeHidden - If true, return names of hidden privs, or return null. Default false.
 */
exports.extractPermissionName = (perm, opts = {}) => {

    const priv = exports.PRIVID_TO_PRIV[perm.privilege];
    const includeHidden = opts.includeHidden || false;

    let name = null;

    if (includeHidden && priv.isHidden) {
        name = null;
    }
    else {
        name = priv.name;
    }

    return name;
};

/**
 * Extracts a list of permission names from a list of permission DB objects
 * @param {Array} perms - list of permission Db objects
 * @param {Object} opts -
 * @param {Object} opts.includeHidden - Default false - Whether or not to also show hidden permissions
 */
exports.extractPermissionNames = (perms, opts = {}) => {


    if (!perms) {
        return null;
    }

    const res = [];

    perms.forEach((it) => {

        const privName = exports.extractPermissionName(it, opts);

        if (privName) {
            res.push(privName);
        }
    });

    return res;
};


/**
 * Gets all types from a given point down in a hierarchy
 *
 * @param {string} type - The point in the collection hierarchy to start from
 * @returns {Array} - The string array of collections below a certain point in
 * the hierarchy
 */
internals._getSubTypesInHierarchy = (type) => {

    Assert.ok(privObjTypes.indexOf(type) >= 0, `${type} is not a valid privilege objectType`);
    const idx = internals.objHierarchy.indexOf(type);
    const res = internals.objHierarchy.slice(idx);
    return res;
};

exports.permissionFilters = {
    ALL: 'ALL',
    PERM: 'PERM',
    DIRECTPERM: 'DIRECTPERM',
    NOPERM: 'NOPERM'
};


// BOOTSTRAPPING STUFF

exports.PRIVILEGE_HIERARCHY_PATH_ARRAYS = {
    teams: [],
    binders: [],
    folders: [],
    documents: []
};

/**
 * Sets an array of privilege hierarchy paths for teams, binders, folders and documents
 * @private
 */
internals._cachePrivilegeHierarchyPathArrays = () => {

    const entityHierarchy = [
        collections.TEAM,
        collections.BINDER,
        collections.FOLDER,
        collections.DOCUMENT
    ];
    entityHierarchy.forEach((clxn) => {

        let pathArray = [];
        const hierarchy = exports.getPrivilegeHierarchyOfType(clxn);
        internals._derivePrivilegeHierarchyPathArray(hierarchy, pathArray, clxn);
        pathArray = _.sortBy(pathArray, (el) => el);
        exports.PRIVILEGE_HIERARCHY_PATH_ARRAYS[clxn] = pathArray;
    });
};

/**
 * Recursively extracts the paths of the given privilege hierarchy and
 * pushes them onto the given array
 *
 * @param {Object} hierarchy - A privilege hierarchy
 * @param {Array} pathArray - And array to push paths onto
 * @param {String} clxn - The collection for which the hierarchy was derived
 * @private
 */
internals._derivePrivilegeHierarchyPathArray = (hierarchy, pathArray, clxn) => {

    _.forEach(hierarchy, (privObject) => {

        const paths = exports.getPrivilegePaths(privObject, clxn);
        paths.forEach((path) => {

            pathArray.push(path);
        });
        if (privObject.permissions) {
            internals._derivePrivilegeHierarchyPathArray(privObject.permissions, pathArray, clxn);
        }
    });
};

exports.TEMPLATING_REGEXES = {
    TEMPLATE_FLAG_REGEX: new RegExp(/\[template\]/, 'i'),
    GLOBAL_FLAG_REGEX: new RegExp(/\[global\]/, 'i'),
    DO_NOT_MAP_FLAG_REGEX: new RegExp(/\[do not map\]/, 'i')
};

exports.DOCUMENT_FILTERS = {
    documents: 'documents',
    placeholders: 'placeholders',
    revisionsRequested: 'revisionsRequested',
    due: 'due',
    expired: 'expired',
    almostDue: 'almostDue',
    almostExpired: 'almostExpired',
    pendingSignatures: 'pendingSignatures',
    pendingSignaturesAlmostDue: 'pendingSignaturesAlmostDue',
    pendingSignaturesDue: 'pendingSignaturesDue',
    pendingTasks: 'pendingTasks'
};

/**
 * Function to run any setup tasks
 * @private
 */
(function bootstrapConstants() {

    internals._cachePrivilegeHierarchyPathArrays();
}());

exports.isValueDefined = isValueDefined;

exports.TEAM_DEACTIVATION_STATUSES = {
    SCHEDULED: 'scheduled',
    DEACTIVATED: 'deactivated',
    CANCELED: 'canceled',
    REACTIVATED: 'reactivated'
};

exports._methods = internals;
