import {
    SignatureTypes,
    SigningReasons,
    Document
} from '@app/shared/models';
import { CHECKBOX_STATES } from '@app/core/constants';

export interface PendingSignatureRequest {
    id: string;
    method: SignatureTypes;
    reason: SigningReasons;
    signByDate: Date|string|null;
    user: {
        id: string;
        email: string;
        name: string;
    };
    isMarkedForCancellation: boolean;
    notifyRequestorWhenSigned: boolean;
    remindSigner: boolean;
    isChanged: boolean;
    checked: CHECKBOX_STATES;
    showSignByDateInvalid: boolean;
}

export interface DocumentsPendingSignatureRequestsDataRow {
    documentId?: string;
    activeDropdown?: boolean;
    pendingSignatureRequest: PendingSignatureRequest;
    signatureTypeOptions?: Array<SignatureTypes>;
}

export const isPendingTabDataRow = (object): object is DocumentsPendingSignatureRequestsDataRow => {
    return object.rowDataType === undefined;
};

export interface DataHeaderRow {
    documentId?: string;
    activeDropdown?: boolean;
    rowDataType: 'header-row';
    document: Document;
    entityPath: {
        type: 'binder' | 'folder' | 'document';
        name: string;
    }[];
}

export type DocumentsPendingSignatureTabDataRow = DocumentsPendingSignatureRequestsDataRow | DataHeaderRow;
