
import {
    Component, Input, Output, OnInit, EventEmitter
} from '@angular/core';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MESSAGES, REGEX } from '@app/core/constants';
import {
    Binder, Document, DocumentSubTypes, Folder, Team
} from '@app/shared/models';
import {
    VirtualTreeFlatNode,
    VirtualTreeItemSelectedEvent,
    VirtualTreeNode,
    VirtualTreeSelectionMode
} from '@app/widgets/virtual-tree/virtual-tree.component.types';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as CustomValidators from '@app/core/form-validators';
import style from './document-placeholder-fill.component.scss';
import template from './document-placeholder-fill.component.html';
import {
    FillMethodsFriendlyNames, PlaceholderFillModes, ReplacePlaceholderOnSaveData, TITLE_MODES
} from './document-placeholder-fill.component.types';

@Component({
    selector: 'document-placeholder-fill',
    template,
    styles: [String(style)]
})
export class DocumentPlaceholderFillComponent implements OnInit {

    @Input() document: Document;
    @Input() loadRoot;
    @Input() loadItem;
    @Output() onSubmit = new EventEmitter<ReplacePlaceholderOnSaveData>();

    isLoadingRoot: boolean;
    isRootLoaded: boolean;
    isProcessing: boolean;

    characterError = MESSAGES.invalidCharactersMessage;
    lengthError = MESSAGES.invalidLengthMessage;
    titleModes = TITLE_MODES;

    fillMethods = [
        { friendlyName: FillMethodsFriendlyNames.DOCUMENT_MOVE, value: PlaceholderFillModes.MOVE },
        { friendlyName: FillMethodsFriendlyNames.DOCUMENT_DUPLICATE, value: PlaceholderFillModes.CLONE }
    ];

    fillPlaceholderForm: FormGroup;
    originalDoc;
    items: VirtualTreeNode[];
    selectionMode = VirtualTreeSelectionMode;
    currentPath: string[];
    currentTeam: Team;

    constructor(
        private modalRef: BsModalRef
    ) {
        this.isProcessing = false;
        this.isRootLoaded = false;
        this.isLoadingRoot = false;
        this.originalDoc = { title: '' };
    }

    ngOnInit(): void {
        this.initForm();

        this.currentPath = [this.document.binderId].concat(this.document.lineage);

        if (!this.document.shortcuts.length) {
            this.fillMethods.push({ friendlyName: FillMethodsFriendlyNames.SHORTCUT, value: PlaceholderFillModes.SHORTCUT });
        }

        this.isLoadingRoot = true;
        this.loadRoot().then((items) => {
            this.items = sortByLexicographically(items, 'name');
            this.isLoadingRoot = false;
            this.isRootLoaded = true;
        });
    }

    initForm() {
        this.fillPlaceholderForm = new FormGroup({
            fillMethod: new FormControl('', [
                Validators.required
            ]),
            newTitleFrom: new FormControl(this.titleModes.ORIGINAL_PLACEHOLDER, [
                Validators.required
            ]),
            customName: new FormControl(null, [
                Validators.required,
                Validators.maxLength(250),
                Validators.pattern(REGEX.names),
                CustomValidators.notBlank
            ])
        });
    }

    get isUserInput(): boolean {
        return this.fillPlaceholderForm.controls.newTitleFrom.value === TITLE_MODES.CUSTOM;
    }

    getModalData() {
        let name: string;
        const newTitleFrom = this.fillPlaceholderForm.controls.newTitleFrom.value;
        const fillMethod = this.fillPlaceholderForm.controls.fillMethod.value;
        const customName = this.fillPlaceholderForm.controls.customName.value;

        switch (newTitleFrom) {
            case TITLE_MODES.ORIGINAL_DOC:
                name = fillMethod === PlaceholderFillModes.SHORTCUT
                    ? `${this.originalDoc.title} - Shortcut`
                    : this.originalDoc.title;
                break;
            case TITLE_MODES.ORIGINAL_PLACEHOLDER:
                name = fillMethod === PlaceholderFillModes.SHORTCUT
                    ? `${this.document.title} - Shortcut`
                    : this.document.title;
                break;
            case TITLE_MODES.CUSTOM:
            default:
                name = customName;
        }

        return {
            id: this.document.id,
            originalDocumentId: this.originalDoc.id,
            fillMode: fillMethod,
            name
        };
    }

    handleSubmit() {
        if (this.submitDisabled() || this.isProcessing) {
            return;
        }
        this.isProcessing = true;
        const event = {
            ...this.getModalData(),
            onSuccess: () => this.modalRef.hide(),
            onError: () => {
                this.isProcessing = false;
            }
        };
        this.onSubmit.emit(event);
    }

    cancel(): void {
        this.modalRef.hide();
    }

    hasName() {
        const newTitleFrom = this.fillPlaceholderForm.controls.newTitleFrom.value;
        const hasExistingTitle = newTitleFrom === this.titleModes.ORIGINAL_DOC
            || newTitleFrom === this.titleModes.ORIGINAL_PLACEHOLDER;
        const hasCustomTitle = !hasExistingTitle && this.fillPlaceholderForm.controls.customName.valid;
        return hasExistingTitle || hasCustomTitle;
    }

    submitDisabled(): boolean {
        const formNotFilled = !this.originalDoc.id || !this.hasName() || !this.fillPlaceholderForm.controls.fillMethod.value;
        return (formNotFilled && !this.movingMovedPlaceholder()) || this.isProcessing;
    }

    selectCustomOption(customOption: string) {
        this.fillPlaceholderForm.controls.newTitleFrom.setValue(customOption);
    }

    movingMovedPlaceholder(): boolean {
        return this.originalDoc.overwrittenPlaceholderId
            && this.fillPlaceholderForm.controls.fillMethod.value === PlaceholderFillModes.MOVE;
    }

    isItemSelectable(item: Binder | Folder | Document): boolean {
        return item.type === 'document' && item.subType === DocumentSubTypes.content;
    }

    isItemDisplayable(item: Document): boolean {
        return item.subType !== DocumentSubTypes.shortcut && item.subType !== DocumentSubTypes.placeholder;
    }

    onItemSelected($event: VirtualTreeItemSelectedEvent<VirtualTreeFlatNode>):void {
        this.originalDoc = $event.item;
    }

    onItemUnselected():void {
        this.originalDoc = { title: '' };
    }
}
