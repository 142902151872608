import {
    Component,
    Input,
    Output,
    OnInit,
    EventEmitter,
    Inject
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Team, Folder } from '@app/shared/models';
import { DownloadCheckerService } from '@app/shared/downloads/download-checker.service';
import { NeedHelpComponent } from '@app/components/need-help/containers/need-help/need-help.component';
import { ImportViaEmailComponent } from '@app/widgets/import-via-email/import-via-email.component';
import { filter, take, tap } from 'rxjs/operators';
import { TeamInboxCheckerService } from '@app/shared/teams-inbox/team-inbox-checker.service';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import styles from './nav-menu.component.scss';
import template from './nav-menu.component.html';
import { WarningModalComponent } from '../warning-modal/warning-modal.component';

@Component({
    selector: 'nav-menu',
    template,
    styles: [String(styles)]
})
export class NavMenuComponent implements OnInit {
    @Input() teamId: string;
    @Input() showProjects: boolean;
    @Input() canUpload?: boolean;
    @Input() emailDestination?: { teamId: string; binderId: string; folderId?: string; docId?: string; crumbsEntity: Folder };
    @Output() onUpload?= new EventEmitter<FileList>();

    public currentTeam: Team;
    private readonly zendeskUrl = 'https://florencehealthcare.zendesk.com';
    studyProfileEntryNavMenuLocation = false

    constructor(
        private modalsService: ModalsService,
        public DownloadChecker: DownloadCheckerService,
        public TeamInboxChecker: TeamInboxCheckerService,
        private CurrentSession: CurrentSessionService,
        private $state: StateService,
        @Inject('Window') private window: Window,
        private FeatureFlags: FeatureFlagService
    ) { }

    ngOnInit(): void {
        this.getStudyProfileEntryFlag();
        this.currentTeam = this.CurrentSession.getCurrentTeam();
    }

    getStudyProfileEntryFlag() {
        this.FeatureFlags.getFlag(FEATURE_FLAGS.STUDY_PROFILE_SIDEMENU_ENTRY, false).pipe(
            filter((flag) => {
                return flag !== undefined;
            })
        ).subscribe((value) => {
            this.studyProfileEntryNavMenuLocation = value;
        });
    }

    filesAdded($event: FileList): void {
        if (!this.onUpload) {
            return;
        }
        this.onUpload.emit($event);
    }

    openEmailModal(): void {
        const initialState = {
            resolve: this.emailDestination
        };
        const emailModal = this.modalsService.show(ImportViaEmailComponent, {
            initialState,
            class: 'modal-md'
        });

        emailModal.content.dismiss
            .pipe(take(1))
            .subscribe(() => {
                emailModal.hide();
            });
    }

    openNeedHelp(): void {
        const { support } = this.currentTeam.settings;
        const teamHasSupport = support && support.email && support.phone;
        if (!teamHasSupport) {
            const url = this.$state.href('app.help-desk', { return_to: this.zendeskUrl });
            this.window.open(url, '_blank');
            return;
        }

        const needHelpModal = this.modalsService.show(NeedHelpComponent, {
            initialState: {},
            class: 'modal-md'
        });

        needHelpModal.content.dismiss.subscribe(() => {
            needHelpModal.hide();
        });
    }

    openTeamDeactivationLearnMore(): void {
        if (!this.currentTeam.teamDeactivationDate) {
            return;
        }

        const formattedDate = new Date(this.currentTeam.teamDeactivationDate).toLocaleDateString('en-GB', {
            day: 'numeric', month: 'short', year: 'numeric'
        }).replace(/ /g, '-');

        const learnMoreModal = this.modalsService.show(WarningModalComponent, {
            initialState: {
                header: 'Team Deactivation',
                content: `
                    <p> This team is scheduled for deactivation on <b>${formattedDate}</b>.</p>
                    <p> After deactivation, you will no longer be able to access this team or its contents.
                        Documents should be downloaded prior to deactivation if you need to maintain access.
                    </p>
                    <p>
                        <a href="https://florencehealthcare.zendesk.com/hc/en-us/articles/360002352173-How-to-download-a-binder-folder-or-document" target="_blank"> Click here </a>
                        to learn how to download your documents.
                    </p>
                `,
                secondaryButton: 'Close'
            },
            class: 'modal-md'
        });
        learnMoreModal.content.dismiss.subscribe(() => {
            learnMoreModal.hide();
        });
    }

    get canViewStudyManage() {
        return this.studyProfileEntryNavMenuLocation
            && this.currentTeam
            && this.currentTeam.permissions
            && (this.currentTeam.permissions.manageTeamStudyProfiles
                || this.currentTeam.permissions.viewTeamStudyProfiles
            );
    }

    get canViewDocumentSharing() {
        return this.currentTeam && this.currentTeam.permissions && this.currentTeam.permissions.documentSharingView;
    }

    get canViewTeamInbox() {
        return this.currentTeam?.permissions?.viewTeamInbox || this.currentTeam?.permissions?.manageTeamInbox;
    }

    get canManageLongTermArchive() {
        return this.currentTeam.permissions?.viewArchives && this.currentTeam.settings.features.longTermArchive;
    }

    openLongTermArchive() {
        this.$state.go('app.team.long-term-archives', { teamId: this.teamId });
    }
}
