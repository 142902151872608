import {
    Announcement,
    AnnouncementRecipient,
    RelatedObject
} from '@app/shared/models';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    GetAnnouncementsParams,
    GetAnnouncementsResponse,
    SendAnnouncementParams,
    SendAnnouncementResponse
} from './annoucements.service.types';

@Injectable()
export class AnnouncementsService {
    private readonly url = {
        team: (teamId: string): string => `/api/teams/${teamId}/announcements`,
        user: (teamId: string, userId: string): string => `/api/teams/${teamId}/users/${userId}/announcements`,
        single: (teamId: string, announcementId: string): string => `/api/teams/${teamId}/announcements/${announcementId}`,
        recipients: (teamId: string, announcementId: string): string => `/api/teams/${teamId}/announcements/${announcementId}/recipients`,
        relatedObjects: (teamId: string, announcementId: string): string => `/api/teams/${teamId}/announcements/${announcementId}/related-objects`
    };

    constructor(
        private $http: HttpClient
    ) {}

    getAnnouncements(teamId: string, userId: string, params: GetAnnouncementsParams): Observable<GetAnnouncementsResponse> {
        const query = {
            pageNum: 1,
            ...params,
            type: undefined
        };

        let httpParams = new HttpParams();
        Object.entries(query).forEach(
            ([key, value]) => {
                if (value) {
                    httpParams = httpParams.set(key, value);
                }
            }
        );

        const uri = params.type === 'user' ? this.url.user(teamId, userId) : this.url.team(teamId);
        return this.$http.get<GetAnnouncementsResponse>(uri, { params: httpParams });
    }

    sendAnnouncement(teamId: string, params: SendAnnouncementParams): Observable<SendAnnouncementResponse> {
        const {
            title,
            message,
            userIds = [],
            roleIds = [],
            relatedObjects = []
        } = params;
        const payload = {
            title,
            message,
            userIds,
            roleIds,
            relatedObjects
        };
        if (!payload.relatedObjects.length) {
            delete payload.relatedObjects;
        }
        const uri = this.url.team(teamId);
        return this.$http.post<SendAnnouncementResponse>(uri, payload);
    }

    getAnnouncement(teamId: string, announcementId: string): Observable<Announcement> {
        const uri = this.url.single(teamId, announcementId);
        return this.$http.get<Announcement>(uri);
    }

    getAnnouncementRecipients(teamId: string, announcementId: string): Observable<AnnouncementRecipient[]> {
        const uri = this.url.recipients(teamId, announcementId);
        return this.$http.get<AnnouncementRecipient[]>(uri);
    }

    getAnnouncementRelatedObjects(teamId: string, announcementId: string): Observable<RelatedObject[]> {
        const uri = this.url.relatedObjects(teamId, announcementId);
        return this.$http.get<RelatedObject[]>(uri);
    }
}
