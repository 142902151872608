import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import tagsRoute from './tags.route';
import { CreateTagComponent } from './components/create-tag/create-tag.component';
import { DeleteTagComponent } from './components/delete-tag/delete-tag.component';
import { TagsService } from './tags.service';
import { UpdateTagComponent } from './components/update-tag/update-tag.component';
import { ManageTagsComponent } from './containers/manage-tags/manage-tags.component';

const dependencies = [
    uiRouter
];

const tagsModule = angular
    .module('app.tags', dependencies)
    .directive('manageTags', downgradeComponent({ component: ManageTagsComponent }) as ng.IDirectiveFactory)
    .directive('createTag', downgradeComponent({ component: CreateTagComponent }) as ng.IDirectiveFactory)
    .directive('updateTag', downgradeComponent({ component: UpdateTagComponent }) as ng.IDirectiveFactory)
    .directive('deleteTag', downgradeComponent({ component: DeleteTagComponent }) as ng.IDirectiveFactory)
    .factory('TagsService', downgradeInjectable(TagsService))
    .config(tagsRoute);

export default tagsModule;
