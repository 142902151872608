import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Role } from '@app/shared/models';
import { map } from 'rxjs/operators';
import {
    GetRoleResponse,
    GetRolePermissionsParams,
    GetRolePermissionsResponse,
    GetRolesResponse,
    RemoveRolePermissionsParams,
    GetRolesParams,
    UsersAssignedToRole
} from './roles.service.types';

@Injectable()
export class RolesService {
    readonly url = {
        roles: (teamId: string): string => `/api/teams/${teamId}/roles`,
        role: (teamId: string, roleId: string): string => `/api/teams/${teamId}/roles/${roleId}`,
        roleUsers: (teamId: string, roleId: string): string => `/api/teams/${teamId}/roles/${roleId}/users`,
        rolePermissions: (teamId: string, roleId: string): string => `/api/teams/${teamId}/roles/${roleId}/permissions`
    };

    constructor(
        private $http: HttpClient
    ) {}

    getRoles(teamId: string, params: GetRolesParams): Observable<GetRolesResponse> {
        let httpParams = new HttpParams();
        Object.entries(params).forEach(
            ([key, value]) => {
                if (key !== 'filter' && value) {
                    httpParams = httpParams.set(key, value.toString());
                }
            }
        );
        if (params.filter) {
            httpParams = httpParams.set('filter', JSON.stringify(params.filter));
        }

        return this.$http.get<GetRolesResponse>(this.url.roles(teamId), { params: httpParams });
    }

    getRole(teamId: string, roleId: string): Observable<Role> {
        return this.$http.get<GetRoleResponse>(this.url.role(teamId, roleId)).pipe(map((data) => data.role));
    }

    createRole(teamId: string, role: Role): Observable<Role> {
        return this.$http.post<GetRoleResponse>(this.url.roles(teamId), role).pipe(map((data) => data.role));
    }

    updateRole(role: Role): Observable<Role> {
        return this.$http.patch<GetRoleResponse>(this.url.role(role.teamId, role.id), role).pipe(map((data) => data.role));
    }

    deleteRole(role: Role, reason: string): Observable<void> {
        const httpParams = new HttpParams().set('reason', reason);
        return this.$http.delete<void>(this.url.role(role.teamId, role.id), { params: httpParams });
    }

    duplicateRole(role: Role): Observable<Role> {
        return this.$http.patch<GetRoleResponse>(this.url.role(role.teamId, role.id), { name: role.name, clone: true })
            .pipe(map((data) => data.role));
    }

    getRolePermissions(teamId: string, roleId: string,
        payload: GetRolePermissionsParams): Observable<GetRolePermissionsResponse> {
        return this.$http.post<GetRolePermissionsResponse>(this.url.rolePermissions(teamId, roleId), payload);
    }

    removeRolePermissions(teamId: string, roleId: string, params: RemoveRolePermissionsParams): Observable<void> {
        let httpParams = new HttpParams();
        if (params) {
            Object.entries(params).forEach(
                ([key, value]) => {
                    if (value) {
                        httpParams = httpParams.set(key, value);
                    }
                }
            );
        }
        return this.$http.delete<void>(this.url.rolePermissions(teamId, roleId), { params: httpParams });
    }

    getUsersAssignedToRole(teamId: string, roleId: string): Observable<UsersAssignedToRole> {
        return this.$http.get<UsersAssignedToRole>(this.url.roleUsers(teamId, roleId));
    }

}
