import { TimeZoneData } from './timezone-picker.component.types';

const TIME_ZONES: TimeZoneData[] = [
    {
        label: 'Berlin, Madrid, Vienna, Oslo, Brussels, Paris, Belgrade',
        value: 'Europe/Berlin',
        rawTZs: [
            'Europe/Amsterdam',
            'Europe/Andorra',
            'Europe/Belgrade',
            'Europe/Berlin',
            'Europe/Bratislava',
            'Europe/Brussels',
            'Europe/Budapest',
            'Europe/Busingen',
            'Europe/Copenhagen',
            'Europe/Gibraltar',
            'Europe/Ljubljana',
            'Europe/Luxembourg',
            'Europe/Madrid',
            'Europe/Malta',
            'Europe/Monaco',
            'Europe/Oslo',
            'Europe/Paris',
            'Europe/Podgorica',
            'Europe/Prague',
            'Europe/Rome',
            'Europe/San_Marino',
            'Europe/Sarajevo',
            'Europe/Skopje',
            'Europe/Stockholm',
            'Europe/Tirane',
            'Europe/Vaduz',
            'Europe/Vatican',
            'Europe/Vienna',
            'Europe/Warsaw',
            'Europe/Zagreb',
            'Europe/Zurich'
        ]
    },
    {
        label: 'Antigua, Anguilla, Aruba, Barbados, St Barthelemy, La Paz, Kralendijk',
        value: 'America/Antigua',
        rawTZs: [
            'America/Anguilla',
            'America/Antigua',
            'America/Aruba',
            'America/Barbados',
            'America/Blanc-Sablon',
            'America/Boa_Vista',
            'America/Campo_Grande',
            'America/Caracas',
            'America/Cuiaba',
            'America/Curacao',
            'America/Dominica',
            'America/Grenada',
            'America/Guadeloupe',
            'America/Guyana',
            'America/Kralendijk',
            'America/La_Paz',
            'America/Lower_Princes',
            'America/Manaus',
            'America/Marigot',
            'America/Martinique',
            'America/Montserrat',
            'America/Port_of_Spain',
            'America/Porto_Velho',
            'America/Puerto_Rico',
            'America/Santo_Domingo',
            'America/St_Barthelemy',
            'America/St_Kitts',
            'America/St_Lucia',
            'America/St_Thomas',
            'America/St_Vincent',
            'America/Tortola'
        ]
    },
    {
        label: 'New York, Toronto, Detroit, Atlanta, Nassau, Havana',
        value: 'America/New_York',
        rawTZs: [
            'America/Detroit',
            'America/Grand_Turk',
            'America/Havana',
            'America/Indiana/Indianapolis',
            'America/Indiana/Marengo',
            'America/Indiana/Petersburg',
            'America/Indiana/Vevay',
            'America/Indiana/Vincennes',
            'America/Indiana/Winamac',
            'America/Iqaluit',
            'America/Kentucky/Louisville',
            'America/Kentucky/Monticello',
            'America/Nassau',
            'America/New_York',
            'America/Nipigon',
            'America/Pangnirtung',
            'America/Port-au-Prince',
            'America/Thunder_Bay',
            'America/Toronto'
        ]
    },
    {
        label: 'Bujumbura, Gaborone, Lubumbashi, Cairo, Maseru, Tripoli, Blantyre',
        value: 'Africa/Bujumbura',
        rawTZs: [
            'Africa/Blantyre',
            'Africa/Bujumbura',
            'Africa/Cairo',
            'Africa/Gaborone',
            'Africa/Harare',
            'Africa/Johannesburg',
            'Africa/Khartoum',
            'Africa/Kigali',
            'Africa/Lubumbashi',
            'Africa/Lusaka',
            'Africa/Maputo',
            'Africa/Maseru',
            'Africa/Mbabane',
            'Africa/Tripoli',
            'Africa/Windhoek'
        ]
    },
    {
        label: 'Luanda, Porto Novo, Kinshasa, Bangui, Brazzaville, Douala, Algiers',
        value: 'Africa/Luanda',
        rawTZs: [
            'Africa/Algiers',
            'Africa/Bangui',
            'Africa/Brazzaville',
            'Africa/Douala',
            'Africa/Kinshasa',
            'Africa/Lagos',
            'Africa/Libreville',
            'Africa/Luanda',
            'Africa/Malabo',
            'Africa/Ndjamena',
            'Africa/Niamey',
            'Africa/Porto-Novo',
            'Africa/Tunis'
        ]
    },
    {
        label: 'Ouagadougou, Abidjan, Accra, Banjul, Conakry, Bissau, Monrovia',
        value: 'Africa/Ouagadougou',
        rawTZs: [
            'Africa/Abidjan',
            'Africa/Accra',
            'Africa/Bamako',
            'Africa/Banjul',
            'Africa/Bissau',
            'Africa/Conakry',
            'Africa/Dakar',
            'Africa/Freetown',
            'Africa/Lome',
            'Africa/Monrovia',
            'Africa/Nouakchott',
            'Africa/Ouagadougou',
            'Africa/Sao_Tome'
        ]
    },
    {
        label: 'Singapore, Shanghai, Hong Kong, Makassar, Ulaanbaatar, Choibalsan, Macau',
        value: 'Asia/Singapore',
        rawTZs: [
            'Asia/Brunei',
            'Asia/Choibalsan',
            'Asia/Hong_Kong',
            'Asia/Irkutsk',
            'Asia/Kuala_Lumpur',
            'Asia/Kuching',
            'Asia/Macau',
            'Asia/Makassar',
            'Asia/Manila',
            'Asia/Shanghai',
            'Asia/Singapore',
            'Asia/Taipei',
            'Asia/Ulaanbaatar'
        ]
    },
    {
        label: 'Buenos Aires, Cordoba, Salta, Jujuy, Tucuman, Catamarca, La Rioja',
        value: 'America/Argentina/Buenos_Aires',
        rawTZs: [
            'America/Argentina/Buenos_Aires',
            'America/Argentina/Catamarca',
            'America/Argentina/Cordoba',
            'America/Argentina/Jujuy',
            'America/Argentina/La_Rioja',
            'America/Argentina/Mendoza',
            'America/Argentina/Rio_Gallegos',
            'America/Argentina/Salta',
            'America/Argentina/San_Juan',
            'America/Argentina/San_Luis',
            'America/Argentina/Tucuman',
            'America/Argentina/Ushuaia'
        ]
    },
    {
        label: 'Athens, Sofia, Tallinn, Helsinki, Kiev, Vilnius, Riga',
        value: 'Europe/Athens',
        rawTZs: [
            'Europe/Athens',
            'Europe/Bucharest',
            'Europe/Chisinau',
            'Europe/Helsinki',
            'Europe/Kiev',
            'Europe/Mariehamn',
            'Europe/Riga',
            'Europe/Sofia',
            'Europe/Tallinn',
            'Europe/Uzhgorod',
            'Europe/Vilnius',
            'Europe/Zaporozhye'
        ]
    },
    {
        label: 'Belem, Fortaleza, Recife, Araguaina, Maceio, Bahia, Sao Paulo',
        value: 'America/Belem',
        rawTZs: [
            'America/Araguaina',
            'America/Bahia',
            'America/Belem',
            'America/Cayenne',
            'America/Fortaleza',
            'America/Maceio',
            'America/Montevideo',
            'America/Paramaribo',
            'America/Punta_Arenas',
            'America/Recife',
            'America/Santarem',
            'America/Sao_Paulo'
        ]
    },
    {
        label: 'Chicago, Rainy River, Rankin Inlet, Matamoros, Winnipeg, Minneapolis, Memphis, Houston',
        value: 'America/Chicago',
        rawTZs: [
            'America/Chicago',
            'America/Indiana/Knox',
            'America/Indiana/Tell_City',
            'America/Matamoros',
            'America/Menominee',
            'America/North_Dakota/Beulah',
            'America/North_Dakota/Center',
            'America/North_Dakota/New_Salem',
            'America/Rainy_River',
            'America/Rankin_Inlet',
            'America/Resolute',
            'America/Winnipeg'
        ]
    },
    {
        label: 'Jakarta, Pontianak, Phnom Penh, Vientiane, Hovd, Novosibirsk, Barnaul',
        value: 'Asia/Jakarta',
        rawTZs: [
            'Asia/Bangkok',
            'Asia/Barnaul',
            'Asia/Ho_Chi_Minh',
            'Asia/Hovd',
            'Asia/Jakarta',
            'Asia/Krasnoyarsk',
            'Asia/Novokuznetsk',
            'Asia/Novosibirsk',
            'Asia/Phnom_Penh',
            'Asia/Pontianak',
            'Asia/Tomsk',
            'Asia/Vientiane'
        ]
    },
    {
        label: 'Tashkent, Aqtobe, Aqtau, Atyrau, Oral, Karachi, Yekaterinburg',
        value: 'Asia/Tashkent',
        rawTZs: [
            'Asia/Aqtau',
            'Asia/Aqtobe',
            'Asia/Ashgabat',
            'Asia/Atyrau',
            'Asia/Dushanbe',
            'Asia/Karachi',
            'Asia/Oral',
            'Asia/Qyzylorda',
            'Asia/Samarkand',
            'Asia/Tashkent',
            'Asia/Yekaterinburg'
        ]
    },
    {
        label: 'Bogota, Rio Branco, Atikokan, Panama, Guayaquil, Jamaica, Cayman',
        value: 'America/Bogota',
        rawTZs: [
            'America/Atikokan',
            'America/Bogota',
            'America/Cancun',
            'America/Cayman',
            'America/Eirunepe',
            'America/Guayaquil',
            'America/Jamaica',
            'America/Lima',
            'America/Panama',
            'America/Rio_Branco'
        ]
    },
    {
        label: 'Belize, Regina, Swift Current, Costa Rica, Guatemala, Tegucigalpa, Managua',
        value: 'America/Belize',
        rawTZs: [
            'America/Belize',
            'America/Costa_Rica',
            'America/El_Salvador',
            'America/Guatemala',
            'America/Managua',
            'America/Regina',
            'America/Swift_Current',
            'America/Tegucigalpa'
        ]
    },
    {
        label: 'Jayapura, Tokyo, Pyongyang, Seoul, Chita, Yakutsk, Khandyga',
        value: 'Asia/Jayapura',
        rawTZs: [
            'Asia/Chita',
            'Asia/Dili',
            'Asia/Jayapura',
            'Asia/Khandyga',
            'Asia/Pyongyang',
            'Asia/Seoul',
            'Asia/Tokyo',
            'Asia/Yakutsk'
        ]
    },
    {
        label: 'Dhaka, Thimphu, Urumqi, Bishkek, Almaty, Qostanay, Omsk',
        value: 'Asia/Dhaka',
        rawTZs: [
            'Asia/Almaty',
            'Asia/Bishkek',
            'Asia/Dhaka',
            'Asia/Omsk',
            'Asia/Qostanay',
            'Asia/Thimphu',
            'Asia/Urumqi'
        ]
    },
    {
        label: 'Edmonton, Denver, Boise, El Paso, Cambridge Bay, Yellowknife, Inuvik, Ojinaga',
        value: 'America/Edmonton',
        rawTZs: [
            'America/Boise',
            'America/Cambridge_Bay',
            'America/Denver',
            'America/Edmonton',
            'America/Inuvik',
            'America/Ojinaga',
            'America/Yellowknife'
        ]
    },
    {
        label: 'Djibouti, Asmara, Addis Ababa, Nairobi, Mogadishu, Dar es Salaam, Kampala',
        value: 'Africa/Djibouti',
        rawTZs: [
            'Africa/Addis_Ababa',
            'Africa/Asmara',
            'Africa/Dar_es_Salaam',
            'Africa/Djibouti',
            'Africa/Kampala',
            'Africa/Mogadishu',
            'Africa/Nairobi'
        ]
    },
    {
        label: 'Tarawa, Majuro, Kwajalein, Nauru, Funafuti, Wake, Wallis',
        value: 'Pacific/Tarawa',
        rawTZs: [
            'Pacific/Funafuti',
            'Pacific/Kwajalein',
            'Pacific/Majuro',
            'Pacific/Nauru',
            'Pacific/Tarawa',
            'Pacific/Wake',
            'Pacific/Wallis'
        ]
    },
    {
        label: 'Bahrain, Baghdad, Kuwait, Qatar, Riyadh, Aden',
        value: 'Asia/Bahrain',
        rawTZs: [
            'Asia/Aden',
            'Asia/Baghdad',
            'Asia/Bahrain',
            'Asia/Kuwait',
            'Asia/Qatar',
            'Asia/Riyadh'
        ]
    },
    {
        label: 'Pohnpei, Kosrae, Noumea, Bougainville, Guadalcanal, Efate',
        value: 'Pacific/Pohnpei',
        rawTZs: [
            'Pacific/Bougainville',
            'Pacific/Efate',
            'Pacific/Guadalcanal',
            'Pacific/Kosrae',
            'Pacific/Noumea',
            'Pacific/Pohnpei'
        ]
    },
    {
        label: 'London, Guernsey, Dublin, Isle of Man, Jersey, Lisbon',
        value: 'Europe/London',
        rawTZs: [
            'Europe/Dublin',
            'Europe/Guernsey',
            'Europe/Isle_of_Man',
            'Europe/Jersey',
            'Europe/Lisbon',
            'Europe/London'
        ]
    },
    {
        label: 'Anchorage, Juneau, Sitka, Metlakatla, Yakutat, Nome',
        value: 'America/Anchorage',
        rawTZs: [
            'America/Anchorage',
            'America/Juneau',
            'America/Metlakatla',
            'America/Nome',
            'America/Sitka',
            'America/Yakutat'
        ]
    },
    {
        label: 'Dubai, Yerevan, Baku, Tbilisi, Muscat',
        value: 'Asia/Dubai',
        rawTZs: [
            'Asia/Baku',
            'Asia/Dubai',
            'Asia/Muscat',
            'Asia/Tbilisi',
            'Asia/Yerevan'
        ]
    },
    {
        label: 'Minsk, Moscow, Simferopol, Kirov, Istanbul',
        value: 'Europe/Minsk',
        rawTZs: [
            'Europe/Istanbul',
            'Europe/Kirov',
            'Europe/Minsk',
            'Europe/Moscow',
            'Europe/Simferopol'
        ]
    },
    {
        label: 'Halifax, Glace Bay, Moncton, Goose Bay, Thule',
        value: 'America/Halifax',
        rawTZs: [
            'America/Glace_Bay',
            'America/Goose_Bay',
            'America/Halifax',
            'America/Moncton',
            'America/Thule'
        ]
    },
    {
        label: 'Creston, Dawson Creek, Fort Nelson, Hermosillo, Phoenix',
        value: 'America/Creston',
        rawTZs: [
            'America/Creston',
            'America/Dawson_Creek',
            'America/Fort_Nelson',
            'America/Hermosillo',
            'America/Phoenix'
        ]
    },
    {
        label: 'Chuuk, Guam, Saipan, Port Moresby',
        value: 'Pacific/Chuuk',
        rawTZs: [
            'Pacific/Chuuk',
            'Pacific/Guam',
            'Pacific/Port_Moresby',
            'Pacific/Saipan'
        ]
    },
    {
        label: 'Mexico City, Merida, Monterrey, Bahia Banderas',
        value: 'America/Mexico_City',
        rawTZs: [
            'America/Bahia_Banderas',
            'America/Merida',
            'America/Mexico_City',
            'America/Monterrey'
        ]
    },
    {
        label: 'Astrakhan, Saratov, Ulyanovsk, Samara',
        value: 'Europe/Astrakhan',
        rawTZs: [
            'Europe/Astrakhan',
            'Europe/Samara',
            'Europe/Saratov',
            'Europe/Ulyanovsk'
        ]
    },
    {
        label: 'Pago Pago, Niue, Midway',
        value: 'Pacific/Pago_Pago',
        rawTZs: [
            'Pacific/Midway',
            'Pacific/Niue',
            'Pacific/Pago_Pago'
        ]
    },
    {
        label: 'Hobart, Melbourne, Sydney',
        value: 'Australia/Hobart',
        rawTZs: [
            'Australia/Hobart',
            'Australia/Melbourne',
            'Australia/Sydney'
        ]
    },
    {
        label: 'Los Angeles, Las Vegas, Vancouver, Tijuana',
        value: 'America/Vancouver',
        rawTZs: [
            'America/Los_Angeles',
            'America/Tijuana',
            'America/Vancouver'
        ]
    },
    {
        label: 'Rarotonga, Tahiti, Honolulu',
        value: 'Pacific/Rarotonga',
        rawTZs: [
            'Pacific/Honolulu',
            'Pacific/Rarotonga',
            'Pacific/Tahiti'
        ]
    },
    {
        label: 'Canary, Faroe, Madeira',
        value: 'Atlantic/Canary',
        rawTZs: [
            'Atlantic/Canary',
            'Atlantic/Faroe',
            'Atlantic/Madeira'
        ]
    },
    {
        label: 'Enderbury, Fakaofo, Tongatapu',
        value: 'Pacific/Enderbury',
        rawTZs: [
            'Pacific/Enderbury',
            'Pacific/Fakaofo',
            'Pacific/Tongatapu'
        ]
    },
    {
        label: 'Comoro, Antananarivo, Mayotte',
        value: 'Indian/Comoro',
        rawTZs: [
            'Indian/Antananarivo',
            'Indian/Comoro',
            'Indian/Mayotte'
        ]
    },
    {
        label: 'Mauritius, Reunion, Mahe',
        value: 'Indian/Mauritius',
        rawTZs: [
            'Indian/Mahe',
            'Indian/Mauritius',
            'Indian/Reunion'
        ]
    },
    {
        label: 'Magadan, Sakhalin, Srednekolymsk',
        value: 'Asia/Magadan',
        rawTZs: [
            'Asia/Magadan',
            'Asia/Sakhalin',
            'Asia/Srednekolymsk'
        ]
    },
    {
        label: 'Palmer, Rothera',
        value: 'Antarctica/Palmer',
        rawTZs: [
            'Antarctica/Palmer',
            'Antarctica/Rothera'
        ]
    },
    {
        label: 'Broken Hill, Adelaide',
        value: 'Australia/Broken_Hill',
        rawTZs: [
            'Australia/Adelaide',
            'Australia/Broken_Hill'
        ]
    },
    {
        label: 'Brisbane, Lindeman',
        value: 'Australia/Brisbane',
        rawTZs: [
            'Australia/Brisbane',
            'Australia/Lindeman'
        ]
    },
    {
        label: 'Whitehorse, Dawson',
        value: 'America/Whitehorse',
        rawTZs: [
            'America/Dawson',
            'America/Whitehorse'
        ]
    },
    {
        label: 'Nicosia, Famagusta',
        value: 'Asia/Nicosia',
        rawTZs: [
            'Asia/Famagusta',
            'Asia/Nicosia'
        ]
    },
    {
        label: 'El Aaiun, Casablanca',
        value: 'Africa/El_Aaiun',
        rawTZs: [
            'Africa/Casablanca',
            'Africa/El_Aaiun'
        ]
    },
    {
        label: 'Kolkata, Colombo',
        value: 'Asia/Kolkata',
        rawTZs: [
            'Asia/Colombo',
            'Asia/Kolkata'
        ]
    },
    {
        label: 'Reykjavik, St Helena',
        value: 'Atlantic/Reykjavik',
        rawTZs: [
            'Atlantic/Reykjavik',
            'Atlantic/St_Helena'
        ]
    },
    {
        label: 'Maldives, Kerguelen',
        value: 'Indian/Maldives',
        rawTZs: [
            'Indian/Kerguelen',
            'Indian/Maldives'
        ]
    },
    {
        label: 'Mazatlan, Chihuahua',
        value: 'America/Mazatlan',
        rawTZs: [
            'America/Chihuahua',
            'America/Mazatlan'
        ]
    },
    {
        label: 'Gaza, Hebron',
        value: 'Asia/Gaza',
        rawTZs: [
            'Asia/Gaza',
            'Asia/Hebron'
        ]
    },
    {
        label: 'Vladivostok, Ust Nera',
        value: 'Asia/Vladivostok',
        rawTZs: [
            'Asia/Ust-Nera',
            'Asia/Vladivostok'
        ]
    },
    {
        label: 'Kamchatka, Anadyr',
        value: 'Asia/Kamchatka',
        rawTZs: [
            'Asia/Anadyr',
            'Asia/Kamchatka'
        ]
    },
    {
        label: 'Kabul',
        value: 'Asia/Kabul',
        rawTZs: [
            'Asia/Kabul'
        ]
    },
    {
        label: 'McMurdo',
        value: 'Antarctica/McMurdo',
        rawTZs: [
            'Antarctica/McMurdo'
        ]
    },
    {
        label: 'Casey',
        value: 'Antarctica/Casey',
        rawTZs: [
            'Antarctica/Casey'
        ]
    },
    {
        label: 'Davis',
        value: 'Antarctica/Davis',
        rawTZs: [
            'Antarctica/Davis'
        ]
    },
    {
        label: 'Dumont d\'Urville',
        value: 'Antarctica/DumontDUrville',
        rawTZs: [
            'Antarctica/DumontDUrville'
        ]
    },
    {
        label: 'Mawson',
        value: 'Antarctica/Mawson',
        rawTZs: [
            'Antarctica/Mawson'
        ]
    },
    {
        label: 'Syowa',
        value: 'Antarctica/Syowa',
        rawTZs: [
            'Antarctica/Syowa'
        ]
    },
    {
        label: 'Troll',
        value: 'Antarctica/Troll',
        rawTZs: [
            'Antarctica/Troll'
        ]
    },
    {
        label: 'Vostok',
        value: 'Antarctica/Vostok',
        rawTZs: [
            'Antarctica/Vostok'
        ]
    },
    {
        label: 'Lord Howe',
        value: 'Australia/Lord_Howe',
        rawTZs: [
            'Australia/Lord_Howe'
        ]
    },
    {
        label: 'Macquarie',
        value: 'Antarctica/Macquarie',
        rawTZs: [
            'Antarctica/Macquarie'
        ]
    },
    {
        label: 'Darwin',
        value: 'Australia/Darwin',
        rawTZs: [
            'Australia/Darwin'
        ]
    },
    {
        label: 'Perth',
        value: 'Australia/Perth',
        rawTZs: [
            'Australia/Perth'
        ]
    },
    {
        label: 'Eucla',
        value: 'Australia/Eucla',
        rawTZs: [
            'Australia/Eucla'
        ]
    },
    {
        label: 'Bermuda',
        value: 'Atlantic/Bermuda',
        rawTZs: [
            'Atlantic/Bermuda'
        ]
    },
    {
        label: 'Noronha',
        value: 'America/Noronha',
        rawTZs: [
            'America/Noronha'
        ]
    },
    {
        label: 'St Johns',
        value: 'America/St_Johns',
        rawTZs: [
            'America/St_Johns'
        ]
    },
    {
        label: 'Cocos',
        value: 'Indian/Cocos',
        rawTZs: [
            'Indian/Cocos'
        ]
    },
    {
        label: 'Santiago',
        value: 'America/Santiago',
        rawTZs: [
            'America/Santiago'
        ]
    },
    {
        label: 'Easter',
        value: 'Pacific/Easter',
        rawTZs: [
            'Pacific/Easter'
        ]
    },
    {
        label: 'Cape Verde',
        value: 'Atlantic/Cape_Verde',
        rawTZs: [
            'Atlantic/Cape_Verde'
        ]
    },
    {
        label: 'Christmas',
        value: 'Indian/Christmas',
        rawTZs: [
            'Indian/Christmas'
        ]
    },
    {
        label: 'Galapagos',
        value: 'Pacific/Galapagos',
        rawTZs: [
            'Pacific/Galapagos'
        ]
    },
    {
        label: 'Ceuta',
        value: 'Africa/Ceuta',
        rawTZs: [
            'Africa/Ceuta'
        ]
    },
    {
        label: 'Fiji',
        value: 'Pacific/Fiji',
        rawTZs: [
            'Pacific/Fiji'
        ]
    },
    {
        label: 'Stanley',
        value: 'Atlantic/Stanley',
        rawTZs: [
            'Atlantic/Stanley'
        ]
    },
    {
        label: 'Nuuk',
        value: 'America/Nuuk',
        rawTZs: [
            'America/Nuuk'
        ]
    },
    {
        label: 'Danmarkshavn',
        value: 'America/Danmarkshavn',
        rawTZs: [
            'America/Danmarkshavn'
        ]
    },
    {
        label: 'Scoresbysund',
        value: 'America/Scoresbysund',
        rawTZs: [
            'America/Scoresbysund'
        ]
    },
    {
        label: 'South Georgia',
        value: 'Atlantic/South_Georgia',
        rawTZs: [
            'Atlantic/South_Georgia'
        ]
    },
    {
        label: 'Jerusalem',
        value: 'Asia/Jerusalem',
        rawTZs: [
            'Asia/Jerusalem'
        ]
    },
    {
        label: 'Chagos',
        value: 'Indian/Chagos',
        rawTZs: [
            'Indian/Chagos'
        ]
    },
    {
        label: 'Tehran',
        value: 'Asia/Tehran',
        rawTZs: [
            'Asia/Tehran'
        ]
    },
    {
        label: 'Amman',
        value: 'Asia/Amman',
        rawTZs: [
            'Asia/Amman'
        ]
    },
    {
        label: 'Kiritimati',
        value: 'Pacific/Kiritimati',
        rawTZs: [
            'Pacific/Kiritimati'
        ]
    },
    {
        label: 'Beirut',
        value: 'Asia/Beirut',
        rawTZs: [
            'Asia/Beirut'
        ]
    },
    {
        label: 'Yangon',
        value: 'Asia/Yangon',
        rawTZs: [
            'Asia/Yangon'
        ]
    },
    {
        label: 'Norfolk',
        value: 'Pacific/Norfolk',
        rawTZs: [
            'Pacific/Norfolk'
        ]
    },
    {
        label: 'Kathmandu',
        value: 'Asia/Kathmandu',
        rawTZs: [
            'Asia/Kathmandu'
        ]
    },
    {
        label: 'Auckland',
        value: 'Pacific/Auckland',
        rawTZs: [
            'Pacific/Auckland'
        ]
    },
    {
        label: 'Chatham',
        value: 'Pacific/Chatham',
        rawTZs: [
            'Pacific/Chatham'
        ]
    },
    {
        label: 'Marquesas',
        value: 'Pacific/Marquesas',
        rawTZs: [
            'Pacific/Marquesas'
        ]
    },
    {
        label: 'Gambier',
        value: 'Pacific/Gambier',
        rawTZs: [
            'Pacific/Gambier'
        ]
    },
    {
        label: 'Miquelon',
        value: 'America/Miquelon',
        rawTZs: [
            'America/Miquelon'
        ]
    },
    {
        label: 'Pitcairn',
        value: 'Pacific/Pitcairn',
        rawTZs: [
            'Pacific/Pitcairn'
        ]
    },
    {
        label: 'Azores',
        value: 'Atlantic/Azores',
        rawTZs: [
            'Atlantic/Azores'
        ]
    },
    {
        label: 'Palau',
        value: 'Pacific/Palau',
        rawTZs: [
            'Pacific/Palau'
        ]
    },
    {
        label: 'Asuncion',
        value: 'America/Asuncion',
        rawTZs: [
            'America/Asuncion'
        ]
    },
    {
        label: 'Kaliningrad',
        value: 'Europe/Kaliningrad',
        rawTZs: [
            'Europe/Kaliningrad'
        ]
    },
    {
        label: 'Volgograd',
        value: 'Europe/Volgograd',
        rawTZs: [
            'Europe/Volgograd'
        ]
    },
    {
        label: 'Longyearbyen',
        value: 'Arctic/Longyearbyen',
        rawTZs: [
            'Arctic/Longyearbyen'
        ]
    },
    {
        label: 'Juba',
        value: 'Africa/Juba',
        rawTZs: [
            'Africa/Juba'
        ]
    },
    {
        label: 'Damascus',
        value: 'Asia/Damascus',
        rawTZs: [
            'Asia/Damascus'
        ]
    },
    {
        label: 'Adak',
        value: 'America/Adak',
        rawTZs: [
            'America/Adak'
        ]
    },
    {
        label: 'Apia',
        value: 'Pacific/Apia',
        rawTZs: [
            'Pacific/Apia'
        ]
    }
];

export { TIME_ZONES };
