import {
    Component,
    OnChanges,
    OnInit,
    Input,
    SimpleChanges
} from '@angular/core';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { qcReviewStatuses } from '@florencehealthcare/florence-constants/lib/qc-reviews';
import { Document } from '@app/shared/models';
import { QcReviewDetailsComponent } from '../qc-review-details/qc-review-details.component';

import styles from './qc-review-global-status.component.scss';
import template from './qc-review-global-status.component.html';

@Component({
    selector: 'qc-review-global-status',
    template,
    styles: [String(styles)]
})

export class QcReviewGlobalStatusComponent implements OnInit, OnChanges {
    @Input() doc: Document;

    rejectedStatuses = [qcReviewStatuses.REJECTED, qcReviewStatuses.REJECTION_ACCEPTED];
    qcReviewStatus : string;
    currentQcReview : number;

    constructor(
        private Modals: ModalsService
    ) {}

    ngOnInit(): void {
        if (this.doc.qcReview) {
            this.getQcReviewStats();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.doc && changes.doc.currentValue) {
            this.getQcReviewStats();
        }
    }

    getQcReviewStatus(qcReview) : string {
        if (qcReview.reviews[0].status === qcReviewStatuses.PENDING_REVIEW) {
            return 'Pending QC Review';
        }
        if (qcReview.reviews[qcReview.reviews.length - 1].status === qcReviewStatuses.APPROVED) {
            return 'QC Review Approved';
        }

        let status = 'In QC Review';
        qcReview.reviews.forEach((review) => {
            if (this.rejectedStatuses.includes(review.status)) {
                status = 'QC Review Rejected';
            }
            if (qcReviewStatuses.IN_REJECTION_REVIEW === review.status) {
                status = 'In Rejection Review';
            }
        });

        return status;
    }

    getCurrentQcReviewProgress(qcReview) : number {
        const notFinished = [
            qcReviewStatuses.REJECTED,
            qcReviewStatuses.DECLINED,
            qcReviewStatuses.IN_REVIEW,
            qcReviewStatuses.IN_REJECTION_REVIEW,
            qcReviewStatuses.REJECTION_ACCEPTED
        ];

        if (qcReview.reviews[qcReview.reviews.length - 1].status === qcReviewStatuses.APPROVED) {
            return qcReview.reviews.length;
        }

        let index = qcReview.reviews.findIndex((review) => notFinished.includes(review.status));
        if (index >= 0) {
            return index + 1;
        }

        index = qcReview.reviews.findIndex((review) => review.status === qcReviewStatuses.PENDING_REVIEW);
        if (notFinished.includes(qcReview.reviews[index])) {
            return index;
        }

        return index + 1;
    }

    getQcReviewStats() {
        if (this.doc.qcReview) {
            this.qcReviewStatus = this.getQcReviewStatus(this.doc.qcReview);
            this.currentQcReview = this.getCurrentQcReviewProgress(this.doc.qcReview);
        }
    }

    openViewQcDetails(): void {
        const { doc } = this;
        this.Modals.show(QcReviewDetailsComponent, {
            initialState: {
                doc
            }
        });

    }
}
