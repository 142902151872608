import { StateProvider } from '@uirouter/angularjs';

const permissionsRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.permission-report', {
        url: '/permission-report/:subjectId/:subjectType',
        params: {
            filter: null, // PermissionReportFilter.ALL.id,
            subjectId: null,
            subjectType: 'user'
        },
        views: {
            '': 'permissionReport'
        }
    });
};

permissionsRoute.$inject = ['$stateProvider'];

export default permissionsRoute;
