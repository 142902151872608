import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import template from './day-picker.component.html';
import style from './day-picker.component.scss';

@Component({
    selector: 'day-picker',
    template,
    styles: [String(style)]
})
export class DayPickerComponent {

    @Input() form: FormGroup
    @Input() controlName: string;
    @Input() validationError: string;
}
