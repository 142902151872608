/* eslint-disable array-callback-return */
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CookiesService {
    private readonly cookeName = 'auth0';
    constructor(
        @Inject('Window') private window: Window
    ) { }

    checkIfAuth0CookieExists(): string {
        return this.window.document.cookie.split(';').find((el) => {
            if (el.includes(this.cookeName) && !el.includes('_legacy')) {
                return el;
            }
        })?.split('=')[1];
    }

    getIdleExpiryCookieValue(): string {
        return this.window.document.cookie.split(';').find((el) => el.includes('ng2idle.main.expiry'))?.split('=')[1];
    }

    getIdlingCookieValue(): string {
        return this.window.document.cookie.split(';').find((el) => el.includes('ng2idle.main.idling'))?.split('=')[1];
    }
}
