import { AfterViewInit, Component, Input } from '@angular/core';
import { Crumb } from '@app/shared/models';

import styles from './breadcrumbs.component.scss';
import template from './breadcrumbs.component.html';

@Component({
    selector: 'breadcrumbs',
    template,
    styles: [String(styles)]
})
export class BreadcrumbsComponent implements AfterViewInit {
    @Input() crumbs: Crumb[];

    ngAfterViewInit(): void {
        const btn = document.querySelector('.crumb-link');
        if (btn) {
            btn.addEventListener('click', this.removePopup);
        }
    }

    replaceSpecialChars(str: string): string {
        return str.replace(/\W/g, '-');
    }

    // click on crumb a tag link was not closing datepicker on logs add rows
    removePopup() {
        if (window.document.getElementsByClassName('datetime-picker-dropdown').length) {
            const element = window.document.getElementsByClassName('datetime-picker-dropdown')[0];
            element.remove();
        }
    }

}
