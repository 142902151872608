import {
    AfterContentInit,
    Directive,
    ElementRef,
    Input
} from '@angular/core';

@Directive({
    selector: '[appAutoFocus]'
})
export class AppAutoFocusDirective implements AfterContentInit {
    @Input() appAutoFocus: boolean;

    constructor(private el: ElementRef) {}

    public ngAfterContentInit(): void {
        setTimeout(() => {
            this.el.nativeElement.focus();
        }, 0);
    }
}
