// This file exists because date-time-picker doesn't have the option to work with
// UTC dates exclusively.

import { isString } from 'lodash';

/**
 * Create dateB so that it is moved towards or away from dateA
 * by the timezone offset of dateA.
 * @param {Date|string} value - dateA
 * @param {number} direction - towards (+1) or away (-1)
 * @returns {Date|string} - dateB
 */
export const moveRelativeToUTC = (value: Date | string, direction: 1 | -1): Date | string => {
    if (!value) {
        return value;
    }

    const date = isString(value) ? new Date(value) : value;
    return new Date(date.getTime() + direction * date.getTimezoneOffset() * 60000);
};
