export interface DocumentHistory {
    id: string,
    eventType: string,
    user: {
        id: string,
        email: string,
        fullName: string
    },
    timestamp: Date,
    document: {
        id: string
        version: number,
        name: string
    }
    connection?: {
        id: string,
        name: string
    }
}

export interface GetDocumentHistoriesPathParams {
    document: {
        id: string,
        version: number
    },
    exchangeEventId: string;
}

export const DocumentHistoryFilterField = {
    ACTION: 'ACTION',
    WHO: 'WHO'
} as const;

export interface GetDocumentHistoriesQueryParams {
    pageNum: number;
    pageSize: number;
    sortBy: 'timestamp' | 'user';
    sortDir: 'ASC' | 'DESC';
    filterBy: keyof typeof DocumentHistoryFilterField;
    searchTerm: string;
}

export interface GetDocumentHistoryResponse {
    documentHistories: DocumentHistory[];
    totalItems: number;
}
