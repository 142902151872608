/**
 * Checks if value is Date in the past
 * @param {Date | string} value - input value
 * @returns {Boolean}
 */
export const isDateInThePast = (value: Date | string, ignoreTime = false): boolean => {
    const now = new Date();
    const date = new Date(value);
    if (ignoreTime) {
        now.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);
    }
    return date < now;
};
