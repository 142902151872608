const months = [
    { name: 'January', number: 0, abbrev: 'Jan' },
    { name: 'February', number: 1, abbrev: 'Feb' },
    { name: 'March', number: 2, abbrev: 'Mar' },
    { name: 'April', number: 3, abbrev: 'Apr' },
    { name: 'May', number: 4, abbrev: 'May' },
    { name: 'June', number: 5, abbrev: 'Jun' },
    { name: 'July', number: 6, abbrev: 'Jul' },
    { name: 'August', number: 7, abbrev: 'Aug' },
    { name: 'September', number: 8, abbrev: 'Sep' },
    { name: 'October', number: 9, abbrev: 'Oct' },
    { name: 'November', number: 10, abbrev: 'Nov' },
    { name: 'December', number: 11, abbrev: 'Dec' }
];

export default months;
