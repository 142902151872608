import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component, Inject, Input, OnDestroy, OnInit
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
    AbstractControl,
    ControlContainer,
    FormBuilder,
    FormControl,
    Validators
} from '@angular/forms';
import { DOA_LOG_TEMPLATE_FORM_CONTROLS, DOA_LOG_TEMPLATE_STEP_FORM_CONTROL_NAMES, DOA_LOG_TEMPLATE_STEP_NAMES } from '../doa-log-template.types';
import template from './doa-log-template-create-template-step.component.html';
import stepStyles from '../doa-log-template-steps.style.scss';
import { AbstractWizardStep } from '../../../../../widgets/wizard/utils/abstract-wizard-step/abstract-wizard-step.component';
import { StudyResponsibilities, StudyRole } from '../../../../../shared/models';
import { MESSAGES, REGEX } from '../../../../../core/constants';
import { notBlank } from '../../../../../core/form-validators';
import { StudyRolesMap } from '../../../../../shared/study-roles/study-roles.types';

@Component({
    selector: 'doa-log-template-create-template-step',
    template,
    styles: [String(stepStyles)],
    // eslint-disable-next-line no-use-before-define
    providers: [{ provide: AbstractWizardStep, useExisting: DoaLogTemplateCreateTemplateStepComponent }],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoaLogTemplateCreateTemplateStepComponent extends AbstractWizardStep implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        @Inject(ControlContainer) controlContainer: ControlContainer,
        private formBuilder: FormBuilder
    ) {
        super(controlContainer);
    }

    @Input() studyResponsibilities: StudyResponsibilities;
    @Input() studyRoles: StudyRole[];
    @Input() studyRolesMap: StudyRolesMap;

    readonly stepFriendlyName = DOA_LOG_TEMPLATE_STEP_NAMES.CREATE_TEMPLATE;
    readonly stepFormControlName = DOA_LOG_TEMPLATE_STEP_FORM_CONTROL_NAMES.CREATE_TEMPLATE;
    readonly logLegendFormControlName = DOA_LOG_TEMPLATE_FORM_CONTROLS.LEGEND;
    readonly generalInformationFormControlName = DOA_LOG_TEMPLATE_FORM_CONTROLS.INFORMATION;

    logLegendFormControl: FormControl;
    generalInformationFormControl: FormControl;

    private readonly destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.initFormControls();

    }

    ngAfterViewInit(): void {
        this.syncStepValidityWithFormGroupStatusChanges$().pipe(
            takeUntil(this.destroy$)
        ).subscribe();

        this.stepFormGroup.updateValueAndValidity();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getFormControlErrorMessage(
        formControl: AbstractControl
    ): { message: string } {
        let message = '';

        switch (true) {
            case formControl.errors?.required:
            case formControl.errors?.blank:
                message = MESSAGES.invalidBlankMessage;
                break;
            default:
                message = MESSAGES.validExtremelyLongTextMessage;
        }

        return { message };
    }

    private initFormControls(): void {
        this.logLegendFormControl = this.formBuilder.control('', [
            Validators.pattern(REGEX.extremelyLongText),
            Validators.maxLength(10000),
            notBlank
        ]);

        this.generalInformationFormControl = this.formBuilder.control('', [
            Validators.pattern(REGEX.extremelyLongText),
            Validators.maxLength(10000),
            notBlank
        ]);

        this.parentForm.addControl(
            this.stepFormControlName,
            this.formBuilder.group({
                [this.logLegendFormControlName]: this.logLegendFormControl,
                [this.generalInformationFormControlName]: this.generalInformationFormControl
            })
        );
    }
}
