import {
    Component, Input, EventEmitter, Output, HostListener, ElementRef, OnInit, AfterViewInit, ViewChild
} from '@angular/core';
import {
    Column, Document, LogEntry, AuditTrailSubject, LogEntryTypes, DocumentPropertyColumn
} from '@app/shared/models';
import { Signature, SigningReasons } from '@app/shared/models/signature.model';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UIRouterGlobals } from '@uirouter/core';

import { NotificationsService } from '@app/core/notifications/notifications.service';
import { AuditTrailModalItem } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component.types';
import { DocumentLogEntriesService } from '@app/shared/documents-log-entries/document-log-entries.service';
import { SelectionModel } from '@angular/cdk/collections';
import { SORT } from '@app/core/constants';
import { messages } from './document-log-entries-notifications';

import template from './document-log-entries.component.html';
import styles from './document-log-entries.component.scss';
import { Can, SignLogEntriesEvent, DeclineLogEntryEvent } from './document-log-entries.component.types';
import { LogEntrySaveData } from '../../components/log-entry-form/log-entry-form.component.types';

@Component({
    selector: 'document-log-entries',
    template,
    styles: [String(styles)]
})
export class DocumentLogEntriesComponent implements OnInit, AfterViewInit {
    @Input() doc: Document;
    @Input() currentUserId: string;
    @Input()
    set logEntryToUpdate(value: string | string[]) {

        if (value) {
            this.getFreshLogEntries();
        }
    }

    @Output() audit = new EventEmitter<AuditTrailModalItem>();
    @Output() sign = new EventEmitter<SignLogEntriesEvent>();
    @Output() decline = new EventEmitter<DeclineLogEntryEvent>();
    @Output() setVersion = new EventEmitter<number | undefined>();
    @Output() requestSignatures = new EventEmitter<{ logEntries: LogEntry[] }>();
    @Output() strikethroughLogEntry = new EventEmitter<{ logEntries: LogEntry[] }>();
    @Output() logEntriesLoaded = new EventEmitter();

    @ViewChild('logEntriesContainer', { static: false }) logEntriesContainer: ElementRef
    @ViewChild('target', { static: false }) private scrollContainer: ElementRef;
    @ViewChild('row', { static: false }) private row: ElementRef;

    parentStruckThrough = false;
    struckThroughRowInfoLoading = false;
    userThatStruckThrough = {};
    reasonForStrikeThrough = '';
    dateWhenStruckThrough = undefined;
    showStrikeThroughInfoRow = false;
    logEntries: LogEntry[] = [];
    isDocumentLocked = false;
    columns: DocumentPropertyColumn[];
    showMyPendingSignaturesFilter: boolean;
    columnWidth: string;
    nextLogEntry: string;
    can: Can = {
        inGeneral: {},
        currently: {}
    } as Can;

    selectedEntries = new SelectionModel<LogEntry>(true);
    expandedLogEntry: LogEntry;
    private expandedLogEntryHistory = new BehaviorSubject<LogEntry[]>([]);
    public expandedLogEntryHistory$ = this.expandedLogEntryHistory.asObservable();
    isEditingSelected: boolean;
    isCreating: boolean;
    onlyMyPendingSignatures = false;
    gridStyles: { '-ms-grid-columns'?: string; 'grid-template-columns'?: string } = {};
    isLoading = {
        entries: true,
        additional: false,
        form: false,
        history: false
    };

    isDisabledLeft = true;
    isDisabledRight = false;
    showScrollButtons = false;

    private readonly minColumnWidthInPx = 200;
    private readonly logEntryRowNumberWidthInPx = 55;
    private readonly logEntryRowSelectWidthInPx = 40;
    private readonly logEntryRowVersionWidthInPx = 70;
    private readonly logEntryRowActionWidthInPx = 85;
    scrollStep: number;
    oldScrolly = 0;

    SORT = SORT;
    sortName = 'rowNumber';
    sortIsReversed = false;

    constructor(
        private Notifications: NotificationsService,
        private LogEntries: DocumentLogEntriesService,
        private globals: UIRouterGlobals
    ) { }

    ngOnInit(): void {
        this.getLogEntries();

        this.columns = this.doc.documentProperties.columns;
        this.showMyPendingSignaturesFilter = this.columns.some((column) => column.type === 'signature');
        this.isDocumentLocked = this.doc.isLocked;
        this.resolveGeneralPermissions();
        this.resolveCurrentPermissions();
    }

    handleQueryParams(): void {
        const urlParams = this.globals.params;
        const { openRequestSignatureModal } = urlParams;


        if (openRequestSignatureModal === 'true') {
            this.logEntries.forEach((logEntry: LogEntry) => {
                this.selectedEntries.toggle(logEntry);
            });

            this.handleRequestSignature();
        }
    }

    ngAfterViewInit(): void {
        this.columnWidth = this.calcColumnWidth(this.columns.length, this.logEntriesContainer.nativeElement.offsetWidth);
        this.gridStyles = {
            '-ms-grid-columns': `${this.logEntryRowNumberWidthInPx}px ${this.logEntryRowSelectWidthInPx}px ${this.logEntryRowVersionWidthInPx}px (${this.columnWidth})[${this.columns.length}] ${this.logEntryRowActionWidthInPx}px`,
            'grid-template-columns': `${this.logEntryRowNumberWidthInPx}px ${this.logEntryRowSelectWidthInPx}px ${this.logEntryRowVersionWidthInPx}px repeat(${this.columns.length}, ${this.columnWidth}) ${this.logEntryRowActionWidthInPx}px`
        };
    }

    updateSort(sortName: string): void {
        if (this.isLoading.entries || this.isLoading.additional) {
            return;
        }

        const selectedColumn = this.columns.find((column) => column.name === sortName);
        if ((sortName !== 'rowNumber' && !selectedColumn) || (selectedColumn && selectedColumn.type !== 'date' && selectedColumn.type !== 'team member')) {
            return;
        }

        this.selectedEntries.clear();
        this.isEditingSelected = false;
        this.isCreating = false;
        this.resolveCurrentPermissions();
        this.sortIsReversed = this.sortName !== sortName ? false : !this.sortIsReversed;
        this.sortName = sortName;
        this.getFreshLogEntries();
    }

    @HostListener('window:scroll', ['$event'])
    getMoreLogEntries(event): void {
        const isNearBottom = event.currentTarget.innerHeight + event.currentTarget.pageYOffset
            >= event.target.body.offsetHeight - 50;
        if (!this.nextLogEntry || this.isLoading.entries || this.isLoading.additional || !isNearBottom) {
            return;
        }
        this.isLoading.additional = true;

        this.getLogEntries();
    }

    toggleMyPendingSignatures(): void {
        if (this.isLoading.entries) {
            return;
        }
        this.onlyMyPendingSignatures = !this.onlyMyPendingSignatures;
        this.selectedEntries.clear();
        this.resolveCurrentPermissions();

        this.getFreshLogEntries();
    }

    private getFreshLogEntries(onSuccess?: () => void): void {
        this.isLoading.entries = true;
        this.logEntries = [];
        this.selectedEntries.clear();
        this.nextLogEntry = undefined;

        return this.getLogEntries(onSuccess);
    }

    private getLogEntries(onSuccess?: () => void): void {
        // if latest version, we do not want to use the version-specific route
        const version = this.doc.isLatestVersion ? undefined : this.doc.version;

        this.LogEntries.getLogEntries(this.doc.id as string, this.doc.teamId, {
            version,
            next: this.nextLogEntry,
            myPendingSignatures: this.onlyMyPendingSignatures
        }, this.sortName, this.sortIsReversed ? 'DESC' : 'ASC').subscribe({
            next: ({ items, next }) => {
                this.logEntries.push(...items);
                this.nextLogEntry = next;
                onSuccess && onSuccess();
            },
            error: () => {
                this.nextLogEntry = undefined;
            },
            complete: () => {
                this.isLoading.entries = false;
                this.isLoading.additional = false;
                this.logEntriesLoaded.emit();
                this.SORT.set(this.sortName, this.sortIsReversed);

                setTimeout(() => {
                    this.setDisabledArrowState();
                    this.handleQueryParams();
                });
            }
        });
    }

    private resolveGeneralPermissions(): void {
        const { permissions, isLatestVersion } = this.doc;

        this.can.inGeneral.create = isLatestVersion && permissions.createLogEntry;
        this.can.inGeneral.sign = isLatestVersion && permissions.signLogEntry;
        this.can.inGeneral.decline = isLatestVersion && permissions.signLogEntry;
        this.can.inGeneral.requestSignatures = isLatestVersion && permissions.requestSignature;
        this.can.inGeneral.edit = isLatestVersion && permissions.editLogEntry;
        this.can.inGeneral.viewAudit = isLatestVersion && permissions.viewDocumentAuditTrail;
        this.can.inGeneral.strikethroughLogEntry = isLatestVersion && permissions.strikethroughLogEntry;
        this.can.inGeneral.act = isLatestVersion && (
            permissions.signLogEntry
            || permissions.requestSignature
            || permissions.editLogEntry
            || permissions.viewDocumentAuditTrail
        );

    }

    private resolveCurrentPermissions(): void {
        this.can.currently.sign = this.can.inGeneral.sign
                                && this.resolveCanSignCurrentEntries()
                                && !this.isDocumentLocked
                                && !this.rowStruckThrough();
        this.can.currently.decline = this.can.inGeneral.sign
                                && this.resolveCanDeclineCurrentEntries()
                                && !this.isDocumentLocked
                                && !this.rowStruckThrough();
        this.can.currently.requestSignatures = this.can.inGeneral.requestSignatures
                                            && this.resolveCanRequestSignatures()
                                            && !this.isDocumentLocked
                                            && !this.rowStruckThrough();
        this.can.currently.edit = this.can.inGeneral.edit
                                && this.selectedEntries.selected.length === 1
                                && !this.isDocumentLocked
                                && !this.rowStruckThrough();
        this.can.currently.viewAudit = this.can.inGeneral.viewAudit
                                    && this.selectedEntries.selected.length === 1;
        this.can.currently.strikethroughLogEntry = this.can.inGeneral.strikethroughLogEntry
                                                && !this.isDocumentLocked
                                                && !this.rowStruckThrough();
        this.can.currently.act = Boolean(this.selectedEntries.selected.length)
            && (this.can.currently.sign
            || this.can.currently.decline
            || this.can.currently.requestSignatures
            || this.can.currently.edit
            || this.can.currently.viewAudit
            || this.can.currently.strikethroughLogEntry);
    }

    rowStruckThrough(): boolean {
        if (this.selectedEntries.selected.find((entry) => entry.struckThrough)) {
            return true;
        }
        return false;
    }

    private resolveCanRequestSignatures(): boolean {
        return Boolean(this.selectedEntries.selected.length) && this.selectedEntries.selected.every((logEntry) => {
            return logEntry.columns.some((column) => column.type === LogEntryTypes.signature);
        });
    }

    private resolveCanSignCurrentEntries(): boolean {
        return this.selectedEntries.selected.length > 0 && this.selectedEntries.selected.every((logEntry) => {
            return logEntry.columns.some((c) => {
                if (this.selectedEntries.selected.length === 1) {
                    // A single row can be signed even without pending signature
                    return this.isSignable(c) && this.isSignableByCurrentUser(c)
                    && this.hasPendingSignatureRequestForCurrentUser(c);
                }
                // Bulk sign is allowed only on rows with pending signatures
                return this.isSignable(c) && this.hasPendingSignatureRequestForCurrentUser(c);
            });
        });
    }

    private resolveCanDeclineCurrentEntries(): boolean {
        return this.selectedEntries.selected.length === 1 && this.selectedEntries.selected.every((logEntry) => {
            return logEntry.columns.some((c) => this.isSignable(c) && this.isDeclinableByCurrentUser(c));
        });
    }

    private isSignable(c: Column): boolean {
        return c.type === LogEntryTypes.signature && (!c.value || ((c.value as Signature).status === 'Declined'));
    }

    private isSignableByCurrentUser(c: Column): boolean {
        // either there is no signature request or there is a non-overdue signature request for current user
        return !c.signatureRequest || this.hasPendingSignatureRequestForCurrentUser(c);
    }

    private hasPendingSignatureRequestForCurrentUser(c: Column): boolean {
        return c.signatureRequest && c.signatureRequest.userId === this.currentUserId && !c.signatureRequest.isDue;
    }

    private isDeclinableByCurrentUser(c: Column): boolean {
        return c.signatureRequest && c.signatureRequest.userId === this.currentUserId;
    }

    private calcColumnWidth(colCount: number, totalAvailableWidthInPx: number): string {
        const remainingColumnAvailableWidth = totalAvailableWidthInPx
            - this.logEntryRowNumberWidthInPx
            - this.logEntryRowSelectWidthInPx
            - this.logEntryRowVersionWidthInPx
            - this.logEntryRowActionWidthInPx;
        const evenlyDividedColumnWidthInPx = Math.floor(remainingColumnAvailableWidth / colCount);
        this.scrollStep = evenlyDividedColumnWidthInPx < this.minColumnWidthInPx
            ? this.minColumnWidthInPx : evenlyDividedColumnWidthInPx;

        if (evenlyDividedColumnWidthInPx < this.minColumnWidthInPx) {
            this.showScrollButtons = true;
        }
        else {
            this.showScrollButtons = false;
        }
        return evenlyDividedColumnWidthInPx < this.minColumnWidthInPx ? `${this.minColumnWidthInPx}px` : `${evenlyDividedColumnWidthInPx}px`;
    }

    isEntrySelected(logEntry: LogEntry): boolean {
        return this.selectedEntries.isSelected(logEntry);
    }

    isEditingEntry(logEntry: LogEntry): boolean {
        return this.isEditingSelected && this.isEntrySelected(logEntry);
    }

    toggleEntry(logEntry: LogEntry): void {
        this.isEditingSelected = false;
        this.selectedEntries.toggle(logEntry);
        this.resolveCurrentPermissions();
    }

    selectSingleEntry(logEntry: LogEntry): void {
        this.isEditingSelected = false;
        this.selectedEntries.clear();
        this.selectedEntries.select(logEntry);
        this.resolveCurrentPermissions();
    }

    isEntryExpanded(logEntry: LogEntry = { id: {} } as LogEntry): boolean {
        return this.expandedLogEntry && this.expandedLogEntry.id.logEntryId === logEntry.id.logEntryId;
    }

    expandLogEntry(logEntry: LogEntry): void {
        this.expandedLogEntryHistory.next([]);
        this.expandedLogEntry = this.isEntryExpanded(logEntry) ? undefined : logEntry;
        if (this.expandedLogEntry) {
            this.struckThroughRowInfoLoading = true;
            this.isLoading.history = true;
            const docVersion = this.doc.isLatestVersion ? undefined : this.doc.version;
            this.LogEntries.getLogEntryVersionHistory(this.doc.id as string, logEntry.teamId, logEntry.id, docVersion)
                .pipe(map(this.decorateChangedLogEntryProps))
                .subscribe(
                    (data) => {

                        this.isLoading.history = false;
                        if (logEntry.struckThrough) {
                            this.userThatStruckThrough = {
                                fullName: logEntry.struckThroughBy.fullName,
                                email: logEntry.struckThroughBy.email
                            };
                            this.reasonForStrikeThrough = logEntry.comment;
                            this.dateWhenStruckThrough = logEntry.updatedAt;

                            this.showStrikeThroughInfoRow = true;
                            this.parentStruckThrough = true;
                            this.struckThroughRowInfoLoading = false;
                        }
                        else {
                            this.struckThroughRowInfoLoading = true;
                            this.parentStruckThrough = false;
                            this.showStrikeThroughInfoRow = false;
                        }
                        this.expandedLogEntryHistory.next(data);
                    },
                    () => {
                        this.struckThroughRowInfoLoading = false;
                        this.isLoading.history = false;
                    }
                );
        }
    }

    private decorateChangedLogEntryProps(logEntries: LogEntry[]): LogEntry[] {
        const logEntriesCols = logEntries.reduce((hash, { columns }) => {
            columns.forEach((col) => {
                hash[col.name] = hash[col.name] || [];
                hash[col.name].push(col);
            });
            return hash;
        }, {});
        Object.values(logEntriesCols).forEach((valuesArray: Column[]) => {
            valuesArray.forEach((val: Column, i) => {
                if (i > 0) {
                    val.isUpdated = valuesArray[i - 1].value !== val.value;
                }
            });
        });
        return logEntries;
    }

    handleCreateNewClick(): void {
        this.selectedEntries.clear();
        this.isEditingSelected = false;
        this.isCreating = true;
        this.resolveCurrentPermissions();
        setTimeout(() => {
            this.scrollContainer.nativeElement.scroll({
                left: 0,
                top: this.scrollContainer.nativeElement.scrollHeight + 300,
                behavior: 'smooth'
            });
        });
    }

    handleEditClick(): void {
        this.isCreating = false;
        this.isEditingSelected = true;
    }

    handleRequestSignature(): void {
        this.requestSignatures.emit({ logEntries: this.selectedEntries.selected });
    }

    handleStrikethroughLogEntry(): void {
        this.strikethroughLogEntry.emit({ logEntries: this.selectedEntries.selected });
    }

    cancelCreate(): void {
        this.isCreating = false;
    }

    cancelEdit(): void {
        this.isEditingSelected = false;
    }

    insertLogEntryInOrderByDate(logEntry: LogEntry): void {
        const sortColumnIndex = this.columns.findIndex((column) => column.name === this.sortName);
        const createdDate = logEntry.columns[sortColumnIndex].value
            ? new Date(logEntry.columns[sortColumnIndex].value.toString()).getTime()
            : 0;
        let start = 0;

        if (this.sortIsReversed) {
            start = this.logEntries.length;
            for (let i = this.logEntries.length - 1; i >= 0; i -= 1) {
                const currentDate = this.logEntries[i].columns[sortColumnIndex].value
                    ? new Date(this.logEntries[i].columns[sortColumnIndex].value.toString()).getTime()
                    : 0;
                if (currentDate <= createdDate) {
                    start -= 1;
                }
                else {
                    break;
                }
            }
        }
        else {
            for (let i = 0; i < this.logEntries.length; i += 1) {
                const currentDate = this.logEntries[i].columns[sortColumnIndex].value
                    ? new Date(this.logEntries[i].columns[sortColumnIndex].value.toString()).getTime()
                    : 0;
                if (currentDate <= createdDate) {
                    start += 1;
                }
                else {
                    break;
                }
            }
        }
        this.logEntries.splice(start, 0, logEntry);
    }

    insertLogEntryInOrderByTeamMember(logEntry: LogEntry): void {
        const sortColumnIndex = this.columns.findIndex((column) => column.name === this.sortName);
        const createdTeamMember = logEntry.columns[sortColumnIndex].userInfo;

        let createdTeamMemberName = '';
        if (createdTeamMember.fullNameCanonical && createdTeamMember.fullNameCanonical !== createdTeamMember.emailCanonical) {
            createdTeamMemberName = `${createdTeamMember.fullNameCanonical} ${createdTeamMember.emailCanonical}`;
        }
        else if (!createdTeamMember.fullNameCanonical
            || createdTeamMember.fullNameCanonical === createdTeamMember.emailCanonical) {
            createdTeamMemberName = `${createdTeamMember.emailCanonical}`;
        }

        let start = 0;

        if (this.sortIsReversed) {
            start = this.logEntries.length;
            for (let i = this.logEntries.length - 1; i >= 0; i -= 1) {
                const currentTeamMember = this.logEntries[i].columns[sortColumnIndex].userInfo;
                let currentTeamMemberName = '';
                if (currentTeamMember.fullNameCanonical
                    && currentTeamMember.fullNameCanonical !== currentTeamMember.emailCanonical) {
                    currentTeamMemberName = `${currentTeamMember.fullNameCanonical} ${currentTeamMember.emailCanonical}`;
                }
                else if (!currentTeamMember.fullNameCanonical
                    || currentTeamMember.fullNameCanonical === currentTeamMember.emailCanonical) {
                    currentTeamMemberName = `${currentTeamMember.emailCanonical}`;
                }

                if (currentTeamMemberName <= createdTeamMemberName) {
                    start -= 1;
                }
                else {
                    break;
                }
            }
        }
        else {
            for (let i = 0; i < this.logEntries.length; i += 1) {
                const currentTeamMember = this.logEntries[i].columns[sortColumnIndex].userInfo;
                let currentTeamMemberName = '';
                if (currentTeamMember.fullNameCanonical
                    && currentTeamMember.fullNameCanonical !== currentTeamMember.emailCanonical) {
                    currentTeamMemberName = `${currentTeamMember.fullNameCanonical} ${currentTeamMember.emailCanonical}`;
                }
                else if (!currentTeamMember.fullNameCanonical
                    || currentTeamMember.fullNameCanonical === currentTeamMember.emailCanonical) {
                    currentTeamMemberName = `${currentTeamMember.emailCanonical}`;
                }

                if (currentTeamMemberName <= createdTeamMemberName) {
                    start += 1;
                }
                else {
                    break;
                }
            }
        }
        this.logEntries.splice(start, 0, logEntry);
    }

    insertLogEntryInOrder(logEntry: LogEntry, sortColumnType: string): void {
        if (sortColumnType === 'date') {
            this.insertLogEntryInOrderByDate(logEntry);
        }
        else if (sortColumnType === 'team member') {
            this.insertLogEntryInOrderByTeamMember(logEntry);
        }
    }

    create(logEntryData: LogEntrySaveData, keepEditing = false): void {
        this.isLoading.form = true;
        this.LogEntries.createLogEntry(this.doc.id as string, { ...logEntryData, teamId: this.doc.teamId }).subscribe(
            (created) => {
                this.Notifications.success(messages.logEntryCreated);
                this.isLoading.form = false;
                this.isCreating = keepEditing;
                if (this.isCreating) {
                    this.scrollContainer.nativeElement.scroll({
                        left: 0,
                        top: this.scrollContainer.nativeElement.scrollHeight + 300,
                        behavior: 'smooth'
                    });
                }

                if (this.sortName === 'rowNumber') {
                    if (this.sortIsReversed) {
                        this.scrollContainer.nativeElement.scroll({
                            left: 0,
                            top: 0,
                            behavior: 'smooth'
                        });
                        this.logEntries.unshift(created);
                    }
                    else {
                        this.logEntries.push(created);
                    }
                }
                else {
                    const sortColumnType = this.columns.find((column) => column.name === this.sortName).type;
                    this.insertLogEntryInOrder(created, sortColumnType);
                }

                this.updateStateOnChanges();
            },
            ({ error }) => {
                const errorMessage = error.statusCode === 409 ? error.message : messages.logEntryCreatedErr;
                this.Notifications.error(errorMessage);
                this.isLoading.form = false;
                this.isCreating = true;
            }
        );
    }

    createAndKeepEditing(logEntryData: LogEntrySaveData): void {
        this.create(logEntryData, true);
    }

    saveLogEntry(logEntry: LogEntry, logEntryData: LogEntrySaveData): void {
        const { documentId: { documentId }, id: { logEntryId }, teamId } = logEntry;

        this.isLoading.form = true;
        this.LogEntries.updateLogEntry(documentId, logEntryId, Object.assign(logEntryData, { teamId })).subscribe({
            next: (updatedEntry) => {
                this.Notifications.success(messages.logEntryUpdated);
                this.isLoading.form = false;
                this.isEditingSelected = false;
                const indexToUpdate = this.logEntries.findIndex((entry) => entry.id.logEntryId === updatedEntry.id.logEntryId);

                if (this.sortName === 'rowNumber') {
                    this.logEntries[indexToUpdate] = updatedEntry;
                }
                else {
                    this.logEntries.splice(indexToUpdate, 1);
                    const sortColumnType = this.columns.find((column) => column.name === this.sortName).type;
                    this.insertLogEntryInOrder(updatedEntry, sortColumnType);
                }

                this.selectedEntries.clear();
                if (this.sortName === 'rowNumber') {
                    this.selectedEntries.select(updatedEntry);
                }
                this.resolveCurrentPermissions();
                // clear expanded state
                this.expandLogEntry(this.expandedLogEntry);
                this.updateStateOnChanges();
            },
            error: ({ error }) => {
                if (error.message === messages.logEntryNoChangesToUpdateErr) {
                    this.Notifications.error(error.message);
                }
                else {
                    const errorMessage = error.statusCode === 409 ? error.message : messages.logEntryCreatedErr;
                    this.Notifications.error(errorMessage);
                }
                this.isLoading.form = false;
            }
        });
    }

    private getSignatureFromSelectedEntry(): SignLogEntriesEvent {
        // When signing a single entry we don't check if there is a signature request
        const logEntry = this.selectedEntries.selected[0];

        // Here we could attempt to infer the column and reason but we leave that for the sign-document component.
        return [{ logEntry }];
    }

    private getSignaturesFromSelectedEntries(): SignLogEntriesEvent {
        const signatures: SignLogEntriesEvent = [];

        this.selectedEntries.sort((a, b) => {
            return a.rowNumber > b.rowNumber ? 1 : -1;
        });

        this.selectedEntries.selected.forEach((logEntry) => {
            logEntry.columns.forEach((column) => {
                // When signing a multiple entries we do check if there is a signature request
                if (column.signatureRequest && column.signatureRequest.userId === this.currentUserId) {
                    signatures.push({
                        logEntry,
                        column: column.name,
                        reason: column.signatureRequest.reason
                    });
                }
            });
        });

        return signatures;
    }

    private getReason(logEntry: LogEntry, columnName: string): SigningReasons | undefined {
        const column = logEntry.columns.find(({ name }) => name === columnName);

        return column && column.signatureRequest && column.signatureRequest.reason;
    }

    /**
     * Clicking on "Sign" in Actions dropdown
     */
    handleSign(): void {
        const signatures = this.selectedEntries.selected.length === 1
            ? this.getSignatureFromSelectedEntry()
            : this.getSignaturesFromSelectedEntries();

        this.sign.emit(signatures);
    }

    /**
     * Clicking on the signature column
     */
    handleDirectSign(logEntry: LogEntry, column: string): void {
        const reason = this.getReason(logEntry, column);

        this.sign.emit([{ logEntry, column, reason }]);
    }

    /**
     * Clicking on "Decline" in Actions dropdown
     */
    handleDecline(): void {
        const logEntry = this.getFirstSelectedEntry();

        const column = logEntry.columns.find((c) => {
            return this.isSignable(c) && this.isDeclinableByCurrentUser(c);
        });

        if (!column) {
            return;
        }

        this.decline.emit({ logEntry, column: column.name });
    }

    /**
     * Clicking on "Past Due"
     */
    handleDirectDecline(logEntry: LogEntry, column: string): void {
        this.decline.emit({ logEntry, column });
    }

    updateStateOnChanges(): void {
        if (this.doc.logStatus === 'finalized') {
            this.setVersion.emit(this.doc.version + 1);
        }
    }

    openAuditTrail(): void {
        const logEntry = this.getFirstSelectedEntry();

        this.audit.emit({
            // take permissions, name, binder name and path from doc
            ...this.doc,
            ...logEntry,
            id: logEntry.id.logEntryId,
            subject: AuditTrailSubject.LOG_ENTRY
        } as AuditTrailModalItem);
    }

    private getFirstSelectedEntry(): LogEntry {
        return this.selectedEntries.selected[0];
    }

    scrollLeft() {
        const tracks = document.querySelector('.tracks');
        const scrollValue = tracks.scrollLeft - this.scrollStep;
        this.isDisabledRight = false;
        if (tracks.scrollLeft <= this.scrollStep) {
            this.isDisabledLeft = true;
        }

        tracks.scrollTo({
            left: scrollValue,
            behavior: 'smooth'
        });
    }

    scrollRight() {
        const tracks = document.querySelector('.tracks');
        this.isDisabledLeft = false;

        const scrollValue = tracks.scrollLeft + this.scrollStep;

        if ((tracks.scrollWidth - tracks.clientWidth) <= scrollValue) {
            this.isDisabledRight = true;
        }

        tracks.scrollTo({
            left: scrollValue,
            behavior: 'smooth'
        });
    }

    onScroll(event: { target: { offsetHeight: number; scrollTop: number; scrollHeight: number; }; }) {
        const tracks = document.querySelector('.tracks');
        if (this.oldScrolly > tracks.scrollTop) {
            this.oldScrolly = tracks.scrollTop;
            return;
        }
        if (this.oldScrolly < tracks.scrollTop
            && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.oldScrolly = tracks.scrollTop;
            if (!this.nextLogEntry || this.isLoading.entries || this.isLoading.additional) {
                return;
            }
            this.isLoading.additional = true;

            this.getLogEntries();
        }
        this.setDisabledArrowState();

    }

    setDisabledArrowState() {
        const tracks = document.querySelector('.tracks');
        if (tracks.scrollLeft === 0) {
            this.isDisabledLeft = true;
        }
        else {
            this.isDisabledLeft = false;
        }

        if (tracks.scrollLeft + tracks.clientWidth === tracks.scrollWidth) {
            this.isDisabledRight = true;
        }
        else {
            this.isDisabledRight = false;

        }
    }
}
