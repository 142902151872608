import {
    Component,
    Output,
    EventEmitter
} from '@angular/core';
import template from './job-title-required.html';
import style from './job-title-required.scss';
import { JobTitleRequiredEvent } from './job-title-required.types';

@Component({
    template,
    styles: [String(style)]
})
export class JobTitleRequiredComponent {
    @Output() dismiss = new EventEmitter<void>();
    @Output() onUpdateProfile = new EventEmitter<JobTitleRequiredEvent>();

    cancel(): void {
        this.dismiss.emit();
    }

    updateProfile(): void {
        this.onUpdateProfile.emit({
            onUpdateProfile: () => this.cancel()
        });
    }
}
