import { Component, ContentChild, Input } from '@angular/core';
import { AbstractWizardStep } from '../utils/abstract-wizard-step/abstract-wizard-step.component';
import template from './wizard-step.component.html';

@Component({
    selector: 'wizard-step',
    template
})
export class WizardStepComponent {
    @Input() stepName: string;

    @ContentChild(AbstractWizardStep, { static: true })
    contentChild: AbstractWizardStep;

    stepNumber: number;

    isHidden: boolean;
}
