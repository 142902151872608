import {
    Component, Input, OnChanges, SimpleChanges, Output, EventEmitter
} from '@angular/core';
import {
    Subject
} from 'rxjs';
import {
    Study
} from '@app/shared/models';
import { PaginationPropertiesService } from '@app/shared/pagination-properties/pagination-properties.service';
import { Pagination } from './update-study-select-study.types';
import template from './update-study-select-study.component.html';
import styles from './update-study-select-study.component.scss';

@Component({
    selector: 'update-study-select-study',
    template,
    styles: [String(styles)]
})

export class UpdateStudySelectStudyComponent implements OnChanges {

    @Input() studyData: {
        items: Study[];
        recordCount: number;
    };

    @Input() loadingStudyData: boolean;

    @Output() pageUpdated = new EventEmitter<Pagination>();
    @Output() selectedStudy = new EventEmitter<string>();

    studies: Study[];
    selectedStudyId = undefined;
    loading = true;
    boundaryLink: boolean;
    directionLink: boolean;
    maxSize: number;
    totalPageSize: number;
    pagination: Pagination = {
        pageNum: 1,
        pageSize: 20,
        sortBy: 'uniqueProtocolId', // default value
        sortDir: 'ASC'
    };

    $destroy = new Subject<void>();

    constructor(
        paginationProperties: PaginationPropertiesService
    ) {
        this.boundaryLink = paginationProperties.showBoundaryLinkNumbers();
        this.directionLink = true;
        this.maxSize = paginationProperties.getMaxSize();
    }

    ngOnInit(): void {
        this.pageUpdated.emit(this.pagination);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.studyData && changes.studyData.currentValue) {
            this.loading = false;
            const { items, recordCount } = changes.studyData.currentValue;
            this.studies = items;
            this.pagination.totalItems = recordCount;
        }
        else {
            this.pageUpdated.emit(this.pagination);
        }
    }

    select(study: Study): void {
        if (!this.loadingStudyData) {
            this.selectedStudyId = this.selectedStudyId === study.id ? undefined : study.id;
            this.selectedStudy.emit(this.selectedStudyId);
        }
    }

    changePage({ page }): void {
        this.pagination.pageNum = page;
        this.pageUpdated.emit(this.pagination);
    }

    changeSort(sortBy: string): void {
        this.pagination.sortBy = sortBy;
        const currentDir = this.pagination.sortDir;
        this.pagination.sortDir = currentDir === 'DESC' ? 'ASC' : 'DESC';
        this.pageUpdated.emit(this.pagination);
    }

}
