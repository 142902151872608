'use strict';

const { entities } = require('../entities');

module.exports = {
    id: {
        $toString: '$_id'
    },
    teamId: 1,
    title: '$name',
    name: 1,
    binderId: 1,
    subType: entities.FOLDER,
    lineage: 1,
    path: 1,
    type: 1,
    _id: 0
};
