import * as _ from 'lodash';
import { StateService } from '@uirouter/angular';
import { moveAwayFromUTC, moveTowardsUTC } from '@app/shared/date-time';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { sortBrowseTreeLexicographically } from '@app/widgets/sort/sort-browse-tree-lexicographically.util';
import {
    Binder,
    BrowseTree,
    Crumb,
    Project,
    Team,
    Timeline,
    TimelineItem
} from '@app/shared/models';
import { Component, OnInit } from '@angular/core';
import { MESSAGES, REGEX } from '@app/core/constants';
import { TeamService } from '@app/shared/teams/team.service';
import { BindersService } from '@app/shared/binders/binders.service';
import { ProjectsService } from '@app/shared/projects/projects.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { EditTimelineFileds } from '@app/shared/projects/projects.service.types';
import { forkJoin } from 'rxjs';

import template from './timeline-update.component.html';
import styles from './timeline-update.component.scss';
import { FieldErrors } from '../../compontents/timeline-form/timeline-form.component.types';

@Component({
    selector: 'timeline-update',
    template,
    styles: [String(styles)]
})
export class TimelineUpdateComponent implements OnInit {
    currentTeam: Team;
    project: Project;
    timeline: Timeline;
    timelineId: string;
    items: any[];
    selectedItems: TimelineItem[] = [];
    fieldErrors: FieldErrors = {} as FieldErrors;
    globalErrors: string[] = [];
    binders: Binder[] = [];
    crumbs: Crumb[] = [];
    isProcessing = false;
    loadingData = false;
    loadBinder: () => BrowseTree | Promise<void>;

    constructor(
        private Teams: TeamService,
        private Binders: BindersService,
        private Projects: ProjectsService,
        private $state: StateService,
        private CurrentSession: CurrentSessionService,
        private Notifications: NotificationsService
    ) {
        this.loadBinder = this._loadBinder.bind(this);
    }

    ngOnInit(): void {
        const stateParams = this.$state.params;
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        const perm = this.currentTeam.permissions;
        if (!(perm.viewDashboard && (perm.manageTimelines || perm.updateTimeline))) {
            this.$state.go('app.select-team');
            return;
        }

        this.loadingData = true;
        forkJoin({
            binders: this.Binders.getBinders(this.currentTeam.id, { includeArchived: false }),
            project: this.Projects.getProject(this.currentTeam.id, stateParams.projectId),
            timeline: this.Projects.getFullTimeline(this.currentTeam.id, stateParams.timelineId)
        }).subscribe(
            (data) => {
                this.binders = sortByLexicographically(data.binders, 'name');
                const timeline = _.pick(data.timeline, [
                    'id',
                    'name',
                    'teamId',
                    'projectId',
                    'items',
                    'projectedStart',
                    'projectedEnd',
                    'isComplete'
                ]);
                timeline.projectedStart = moveTowardsUTC(timeline.projectedStart)?.toString();
                timeline.projectedEnd = moveTowardsUTC(timeline.projectedEnd)?.toString();
                this.timeline = timeline as Timeline;

                this.project = data.project;

                this.timelineId = this.timeline.id;
                this.items = this.timeline.items || [];
                delete this.timeline.items;
                delete this.timeline.id;

                this.selectedItems = this.items.map((item) => ({
                    id: item.id,
                    type: item.type,
                    binderId: item.binderId
                })) as unknown as TimelineItem[];
                this.crumbs = this.getCrumbs();
                this.loadingData = false;
            },
            ({ error }) => {
                this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
            }
        );
    }

    _loadBinder(params): BrowseTree | Promise<void> {

        if (params.objectType !== 'binder') {
            return Promise.resolve();
        }

        return this.Teams.loadTreeWithDocs(this.currentTeam.id, params.objectId, false)
            .toPromise()
            .then((data) => {
                return sortBrowseTreeLexicographically(data as any, 'name');
            }) as any;
    }

    onNameChange(event: { name: string }): void {
        this.timeline.name = _.trim(event.name);
    }

    onIsCompleteChange(event: { isComplete: boolean}): void {
        this.timeline.isComplete = event.isComplete;
    }

    onSelectedItemsChange(event: { selectedItems }): void {
        this.selectedItems = event.selectedItems;
    }

    /**
     * Validate the timeline
     * @private
     * @return {field: Object, global: []} Return an array of errors
     */
    private validateForm() {
        const errors = {
            hasErrors: false,
            field: {
                name: '',
                projectedStart: '',
                projectedEnd: ''
            },
            global: []
        };

        if (!this.timeline.projectedStart) {
            errors.hasErrors = true;
            errors.field.projectedStart = 'Please set a valid projected start date.';
        }

        if (!this.timeline.projectedEnd) {
            errors.hasErrors = true;
            errors.field.projectedEnd = 'Please set a valid projected end date.';
        }

        if (!this.timeline.name) {
            errors.hasErrors = true;
            errors.field.name = 'Please choose a name.';
        }
        else if (!REGEX.names.test(this.timeline.name)) {
            errors.hasErrors = true;
            errors.field.name = `Please choose a valid name. ${MESSAGES.validNameMessage}`;
        }

        if (
            this.timeline.projectedStart
            && this.timeline.projectedEnd
            && new Date(this.timeline.projectedStart) > new Date(this.timeline.projectedEnd)
        ) {
            errors.hasErrors = true;
            errors.global.push('Projected start date must be before projected end date.');
        }

        return errors;
    }

    onSubmit() {
        const errors = this.validateForm();
        if (errors.hasErrors) {
            this.fieldErrors = errors.field as FieldErrors;
            this.globalErrors = errors.global;
            if (errors.global && errors.global.length) {
                this.Notifications.error(errors.global[0]);
            }
            return;
        }

        this.isProcessing = true;
        const updatePayload = _.merge({}, this.timeline, this.timelineChanges());
        this.moveDates(updatePayload);
        this.Projects.editTimeline(this.currentTeam.id, this.timelineId, updatePayload as unknown as EditTimelineFileds)
            .subscribe((timeline) => {
                this.Notifications.success(`Timeline "${timeline.name}" Updated!`);
                this.isProcessing = false;
                this.$state.reload();
            }, ({ error }) => {
                this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                this.isProcessing = false;
            });
    }

    onCancel() {
        this.$state.go('app.team.manage-project-timeline', {
            teamId: this.currentTeam.id,
            projectId: this.timeline.projectId,
            timelineId: this.timelineId
        });
    }

    private moveDates(payload): void {
        payload.projectedStart = moveAwayFromUTC(payload.projectedStart);
        payload.projectedEnd = moveAwayFromUTC(payload.projectedEnd);
    }

    private timelineChanges() {
        const existing = this.normalizeSelectedItems(this.items);
        const selected = this.normalizeSelectedItems(this.selectedItems);

        const removeItems = _.differenceBy(existing, selected, 'objectId');
        const addItems = _.differenceBy(selected, existing, 'objectId');

        return {
            addItems,
            removeItems
        };
    }

    private normalizeSelectedItems(items) {
        return items.map((item) => ({ objectType: item.type, objectId: item.id }));
    }

    private getCrumbs(): Crumb[] {
        const teamId = this.currentTeam.id;
        return [
            {
                name: 'Manage Projects',
                stateName: 'app.team.manage-projects',
                stateParams: { teamId }
            },
            {
                name: this.project.name,
                stateName: 'app.team.manage-project',
                stateParams: {
                    projectId: this.project.id,
                    teamId
                }
            },
            {
                name: this.timeline.name
            }
        ];
    }
}
