import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MESSAGES, REGEX } from '@app/core/constants';
import { Project } from '@app/shared/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import template from './create-timeline-modal.component.html';
import { TimelineCreateEvent } from './create-timeline-modal.component.types';

@Component({
    selector: 'create-timeline-modal',
    template
})
export class CreateTimelineModalComponent implements OnInit {
    @Input() project: Project;

    @Output() onCreate = new EventEmitter<TimelineCreateEvent>();

    namePattern = REGEX.names;
    readonly nameMessage = MESSAGES.validNameMessage;

    isProcessing: boolean;

    createTimelineForm: FormGroup;

    constructor(
        private modalInstance: BsModalRef,
        private fb: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.isProcessing = false;
        this.initForm();
    }

    initForm() {
        this.createTimelineForm = this.fb.group({
            timelineName: ['', [Validators.required, notBlank, Validators.pattern(this.namePattern)]]
        });
    }

    handleKeyPress(event: KeyboardEvent): void {
        if (event.key === 'Enter' && this.createTimelineForm.valid) {
            this.create();
        }
    }

    cancel(): void {
        this.modalInstance.hide();
    }

    create() {
        this.isProcessing = true;

        const timelineCreateEvent = {
            timeline: {
                name: this.createTimelineForm.controls.timelineName.value,
                projectId: this.project.id
            },
            onSuccess: () => {
                this.modalInstance.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        };

        this.onCreate.emit(timelineCreateEvent);
    }
}
