import { StateProvider } from '@uirouter/angularjs';

const sessionActivityRoute = ($stateProvider: StateProvider): void => {

    $stateProvider.state('app.team.session-activity-show', {
        url: '/session-activity',
        views: {
            '': 'sessionsActivityShow'
        }
    });
};

sessionActivityRoute.$inject = ['$stateProvider'];

export default sessionActivityRoute;
