'use strict';

module.exports = {
    STAMP_SIGNATURE: 'StampSignature',
    TIMESTAMP: 'Timestamp',
    FREE_TEXT: 'Free Text',
    REDACT: 'Redact',
    HIGHLIGHT: 'Highlight',
    RECTANGLE: 'Rectangle'
};
