import * as _ from 'lodash';
import { REGEX, MESSAGES } from '@app/core/constants';

import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Role } from '@app/shared/models';
import {
    FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import style from './duplicate-role.component.scss';
import template from './duplicate-role.component.html';
import { DuplicateRoleEventParams } from './duplicate-role.component.types';

@Component({
    selector: 'duplicate-role',
    template,
    styles: [String(style)]
})
export class DuplicateRoleComponent implements OnInit {
    @Input() role: Role;
    @Output() onDuplicate = new EventEmitter<DuplicateRoleEventParams>();

    roleNamePattern: RegExp;
    readonly nameMessage = MESSAGES.validNameMessage;
    readonly blankNameTooltip = 'Role name cannot be empty.';
    isProcessing: boolean;
    showTooltip: boolean;

    originalName: string;
    newRole: Role;

    renameRoleForm: FormGroup;

    constructor(
        private modalInstance: BsModalRef,
        private fb: FormBuilder
    ) {
        this.roleNamePattern = REGEX.names;
        this.isProcessing = false;
        this.showTooltip = false;
    }

    ngOnInit(): void {
        this.newRole = _.cloneDeep(this.role);
        this.originalName = this.role.name;
        this.initForm();
    }

    initForm() {
        this.renameRoleForm = this.fb.group({
            roleName: [this.role.name, [Validators.required, notBlank, Validators.pattern(this.roleNamePattern)]]
        });
    }

    handleKeyPress(event: KeyboardEvent): void {
        if (event.key === 'Enter'
            && this.renameRoleForm.valid
            && this.renameRoleForm.controls.roleName.value !== this.role.name
        ) {
            this.save();
        }
    }

    cancel(): void {
        this.modalInstance.hide();
    }

    save() {
        this.isProcessing = true;
        this.newRole.name = this.renameRoleForm.controls.roleName.value;

        const duplicateRoleEvent = {
            role: this.newRole,
            onSuccess: () => {
                this.modalInstance.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        };

        this.onDuplicate.emit(duplicateRoleEvent);
    }
}
