import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './reset-password-info.component.html';
import styles from './reset-password-info.component.scss';

@Component({
    selector: 'reset-password-info',
    template,
    styles: [String(styles)]
})
export class ResetPasswordInfoComponent {
    constructor(private modalRef: BsModalRef) {}

    close(): void {
        this.modalRef.hide();
    }
}
