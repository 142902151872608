import { TeamSigningPINPolicy, TeamPasswordPolicy } from '../../../../shared/models';

// TODO: remove next line after tweaking es-lint rule 'import/prefer-default-export'
// eslint-disable-next-line import/prefer-default-export
export enum FormSignatureOptions {
    BOTH = 'both',
    ADDENDUM = 'addendum',
    ANNOTATION = 'annotation',
}

export enum MonitorReviewStatusOptions {
    BOTH = 'both',
    REVIEW = 'review',
    APPROVE = 'approve',
}

export type SigningPINPolicyFormFields = {
    isEnabled: boolean;
    expiryDays: string;
    loginAlertsDays: string;
}

export type TeamSupport = {
    email: string;
    phone: string;
}

export type FormFieldErrors = {
    name?: string;
    passwordPolicy?: {
        expiryDays?: string;
        loginAlertsDays?: string;
    };
    signingPINPolicy?: {
        expiryDays?: string;
        loginAlertsDays?: string;
    };
    supportEmail?: string;
    supportPhone?: string;
    timezone?: string;
}

export type TeamProfileFormErrors = {
    hasErrors: boolean;
    field: FormFieldErrors;
}

export type UpdatedTeamSettings = {
    name?: string;
    timezone?: string;
    enablePasswordPolicy?: boolean;
    passwordPolicy?: Partial<TeamPasswordPolicy>;
    signingPINPolicy?: Partial<TeamSigningPINPolicy>;
    teamSignatureOptions?: FormSignatureOptions;
    disableAddendum?: boolean;
    disableAnnotation?: boolean;
    disableMonitorReviewedStatus?: boolean;
    disableMonitorApprovedStatus?: boolean;
    automaticBinderOwnerPermissions?: boolean;
    automaticDocumentOwnerPermissions?: boolean;
    support?: TeamSupport;
}

export interface MonitorReviewConfig {
    disableMonitorReviewedStatus?: boolean;
    disableMonitorApprovedStatus?: boolean;
}

export interface SignatureConfig {
    disableAddendum?: boolean;
    disableAnnotation?: boolean;
}

export interface UpdateProfileData {
    updatedFields: UpdatedTeamSettings;
    onSuccess: () => void;
    onError: () => void;
}
