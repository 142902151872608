
'use strict';

// The list of audit trails (all of which are internal audits) that
// should be dropped because none of the rabbitMQ consumers use them.
const AUDITS_TO_DROP = {
    childFolderPathUpdated: 'Child Folder Path Updated',
    childDocumentStudyAttributesAssigned: 'Child Document Study Attributes Assigned',
    childDocumentPathUpdated: 'Child Document Path Updated',
    childPlaceholderPathUpdated: 'Child Placeholder Path Updated',
    childShortcutPathUpdated: 'Child Shortcut Path Updated',
    childLogPathUpdated: 'Child Log Path Updated',
    childDocumentMoved: 'Child Document Moved',
    childPlaceholderMoved: 'Child Placeholder Moved',
    childShortcutMoved: 'Child Shortcut Moved',
    childLogMoved: 'Child Log Moved',
    childBinderLabelsUpdated: 'Child Binder Labels Updated',
    childFolderLabelsUpdated: 'Child Folder Labels Updated',
    documentExtracted: 'Document Extracted',
    documentApproveRejectStarted: 'Document Approve/Reject Started',
    documentFormCheckCompleted: 'Document Form Check Completed',
    shortcutOriginalDocDueDateSet: 'Shortcut Original Document Due Date Set',
    shortcutOriginalDocExpDateSet: 'Shortcut Original Document Exp Date Set',
    shortcutOriginalPlaceholderFilled: 'Shortcut Original Placeholder Filled',
    UserEntersTeam: 'User Enters Team',
    teamFeaturesUpdated: 'Team Features Updated',
    documentExportedToIntegration: 'Document Exported to External System',
    archivalStarted: 'Long-term Archive Started',
    folderLocationChanged: 'Folder Location Changed',
    // msg queue events
    MQ_DOWNLOAD_CREATED: 'DownloadCreated',
    MQ_DOCUMENT_CREATED: 'DocumentCreated',
    MQ_CONVERTER: 'DocumentInConverterQueue',
    MQ_MIGRATION: 'DocumentInMigrationQueue',
    MQ_DIGEST_SUBSCRIPTION_PENDING: 'DigestSubscriptionPending',
    MQ_ANNOUNCEMENT_SENT: 'AnnouncementSent',
    MQ_ROLE_TEMPLATE_AUDIT_CREATED: 'RoleTemplateAuditCreated',
    MQ_DOCUMENT_EXCHANGE_START: 'DocumentExchangeStart',
    MQ_ARCHIVAL_SCHEDULED: 'ArchivalScheduled',
    MQ_ARCHIVAL_COMPLETED: 'ArchivalCompleted'
};

module.exports = {
    AUDITS_TO_DROP
};
