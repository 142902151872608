import { Component, Input, OnInit } from '@angular/core';
import { LabelsService } from '@app/shared/labels/labels.service';
import { ApiError, Team } from '@app/shared/models';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { Label } from '@app/shared/labels/labels.service.types';
import { ConfirmDestroyComponent } from '@app/widgets/confirm-destroy/confirm-destroy.component';
import { Observable, of } from 'rxjs';
import {
    catchError, switchMap, tap
} from 'rxjs/operators';
import { ConfirmDestroySubmitEvent } from '@app/widgets/confirm-destroy/confirm-destroy.component.types';
import { SORT } from '@app/core/constants';
import { Label as LabelUpdateEvent } from '@app/components/teams/components/label-update/label-update.component.types';
import * as _ from 'lodash';
import { LabelCreateComponent } from '../label-create/label-create.component';
import { LabelCreateEvent } from '../label-create/label-create.component.types';
import { LabelUpdateComponent } from '../label-update/label-update.component';

import styles from './manage-labels.component.scss';
import template from './manage-labels.component.html';
@Component({
    selector: 'manage-labels',
    template,
    styles: [String(styles)]
})

export class ManageLabelsComponent implements OnInit {
   @Input() team: Team;
   labels: Label[] = [];
   loadingLabels = true;
   numValuesShow = 3;
   SORT = SORT;
   selectedLabel: Label | null = null;

   constructor(
        private labelsService: LabelsService,
        private modalsService: ModalsService,
        private notificationsService: NotificationsService
   ) {}

   ngOnInit(): void {
       this.loadLabels();
   }

   loadLabels(): void {
       this.labelsService.getLabels(this.team.id).subscribe((labels) => {
           this.labels = labels;
           this.updateSort('name');
           this.loadingLabels = false;
       });
   }

   select(label: Label): void {
       if (!label) {
           return;
       }
       if (label.selected) {
           label.selected = false;
           this.selectedLabel = null;
       }
       else {
           this.labels.forEach((l) => {
               l.selected = false;
           });
           label.selected = true;
           this.selectedLabel = label;
       }
   }

   updateSort(sortName: string, isReversed?: boolean): void {
       this.SORT.set(sortName, isReversed);
       const sortedProjects = this.labels.sort((a, b) => {
           return this.SORT.naturalSort({ value: a[sortName] }, { value: b[sortName] });
       });
       if (this.SORT.isReversed) {
           sortedProjects.reverse();
       }
       this.labels = [...sortedProjects];
   }

   onLabelAdded(label: Label): Label {
       this.notificationsService.success(`Created label ${label.name}.`);
       this.labels.push(label);
       this.updateSort('name');
       label.success = true;
       setTimeout(() => {
           label.success = false;
       }, 5000);
       return label;
   }

   openCreateLabelModal(): void {
       if (!this.canCreateLabel()) {
           return;
       }
       const createLabelModal = this.modalsService.show(LabelCreateComponent, {
           animated: false,
           class: 'modal-md',
           initialState: {}
       });

       createLabelModal.content.onCreateLabel.subscribe((event: LabelCreateEvent) => {
           const { label } = event;
           this.labelsService.createLabel(this.team.id, label)
               .subscribe(
                   (createdLabel: Label) => {
                       this.onLabelAdded(createdLabel);
                       createLabelModal.hide();
                   },
                   (error) => {
                       this.handleErrorNotification(error);
                       createLabelModal.hide();
                   }
               );
       });
   }

   openValueAssignedWarnModal(params): void {
       const bodyText = 'This action <span class="strong text-uppercase">cannot</span> be undone. This will permanently update '
        + `values for the label <span class="strong">${this.selectedLabel.name}</span> which will impact current assignments.`;
       const warningText = 'Some removed values have already been assigned to one or more Binders or Folders. '
        + 'The Binders and Folders will remain but the label assignment will be removed.';

       const modalInstance = this.modalsService.show(ConfirmDestroyComponent, {
           class: 'modal-md',
           initialState: {
               warningText,
               bodyText
           }
       });

       modalInstance.content.save.subscribe((event: ConfirmDestroySubmitEvent) => {
           this.persistUpdate(params).subscribe(
               () => {
                   event.onSuccess();
               },
               () => {
                   event.onError();
               }
           );
           modalInstance.hide();
       });

       modalInstance.content.dismiss.subscribe(() => {
           modalInstance.hide();
       });
   }


   private persistUpdate(params: any): Observable<Label | Record<string, unknown>> {
       const teamId = this.team.id;
       const labelId = this.selectedLabel.id;
       const {
           addedValues = [],
           updatedValues = [],
           removedValues = []
       } = params;
       const results = [];
       const errors = [];

       const { name, description } = params;

       const updateLabelProps$ = (name || description || description === '')
           ? this.labelsService.updateLabelProps({ teamId, labelId, updates: params }).pipe(
               catchError((error) => {
                   errors.push(error);
                   return of(null);
               })
           )
           : of(null);

       const addLabelValues$ = addedValues.length
           ? this.labelsService.addLabelValues({ teamId, labelId, addedValues }).pipe(
               catchError((error) => {
                   errors.push(error);
                   return of(null);
               })
           )
           : of(null);

       const deleteLabelValues$ = removedValues.length
           ? this.labelsService.deleteLabelValues({ teamId, labelId, removedValues }).pipe(
               catchError((error) => {
                   errors.push(error);
                   return of(null);
               })
           )
           : of(null);

       const updateLabelValues$ = updatedValues.length
           ? this.labelsService.updateLabelValues({ teamId, labelId, updatedValues }).pipe(
               catchError((error) => {
                   errors.push(error);
                   return of(null);
               })
           )
           : of(null);

       return updateLabelProps$.pipe(
           tap((result) => results.push(result)),
           switchMap(() => addLabelValues$),
           tap((result) => results.push(result)),
           switchMap(() => deleteLabelValues$),
           tap((result) => results.push(result)),
           switchMap(() => updateLabelValues$),
           tap((result) => results.push(result)),
           switchMap(() => {
               if (errors.length > 1) {
                   const message = 'There was a problem making the label updates! Try again or contact customer support.';
                   this.handleErrorNotification(null, message);
                   return this.labelsService.getSingleLabel({ teamId, labelId }).pipe(
                       catchError((err) => {
                           this.handleErrorNotification(err);
                           return of(null);
                       })
                   );
               }

               if (errors.length === 1) {
                   this.handleErrorNotification(errors[0]);
                   return this.labelsService.getSingleLabel({ teamId, labelId }).pipe(
                       catchError((err) => {
                           this.handleErrorNotification(err);
                           return of(null);
                       })
                   );
               }

               this.notificationsService.success('Updated label.');
               const filteredResults = results.filter(Boolean);
               if (!filteredResults.length) {
                   return of(null);
               }

               return of(_.maxBy(filteredResults, 'updatedAt'));
           }),
           tap((updatedLabel) => this.onLabelUpdated(updatedLabel)),
           catchError((error) => {
               this.handleErrorNotification(error);
               return of(null);
           })
       );
   }

   openDeleteLabelModal() {
       if (!this.canDeleteSelectedLabel()) {
           return;
       }
       this.labelsService.checkIsAssigned(
           { teamId: this.team.id, labelId: this.selectedLabel.id, values: [] }
       ).subscribe(({ isAssigned }) => {
           const bodyText = 'This action <span class="strong text-uppercase">cannot</span> be undone. This will permanently delete '
                    + `the label <span class="strong">${this.selectedLabel.name}</span> and all of its assignments.`;
           const warningText = isAssigned ? 'The Label selected for deletion has already been assigned to one or more Binders or Folders. '
                    + 'The Binders and Folders will remain but will no longer have this Label assigned.' : '';

           const destroyModal = this.modalsService.show(ConfirmDestroyComponent, {
               class: 'modal-md',
               initialState: {
                   warningText,
                   bodyText,
                   requireReason: true,
                   confirmLabel: 'Type in the name of the label to continue',
                   confirmValue: this.selectedLabel.name
               }
           });

           destroyModal.content.dismiss.subscribe(() => {
               destroyModal.hide();
           });

           destroyModal.content.save.subscribe((event: ConfirmDestroySubmitEvent) => {
               this.labelsService.deleteLabel({
                   teamId: this.team.id,
                   labelId: this.selectedLabel.id,
                   reason: event.data.reason
               }).subscribe(
                   () => {
                       const startIndex = this.labels.findIndex((l) => l.id === this.selectedLabel.id);
                       this.labels.splice(startIndex, 1);
                       this.notificationsService.success('Label successfully deleted.');
                       this.selectedLabel = null;
                       event.onSuccess();
                   },
                   (err) => {
                       this.handleErrorNotification(err);
                       event.onError();
                   }
               );
               destroyModal.hide();
           });
       });
   }


   onLabelUpdated(updatedLabel = {} as Label): Label {
       this.selectedLabel.selected = false;
       this.selectedLabel = null;
       const label = this.labels.find((l) => l.id === updatedLabel.id);
       if (label) {
           Object.assign(label, updatedLabel);
       }
       return updatedLabel;
   }

   canCreateLabel(): boolean {
       return this.team.permissions.labelCreate;
   }

   openEditLabelModal() {
       if (!this.canEditSelectedLabel()) {
           return;
       }

       const editModal = this.modalsService.show(LabelUpdateComponent, {
           animated: true,
           class: 'modal-md',
           initialState: {
               label: this.selectedLabel as LabelUpdateEvent
           }
       });

       editModal.content.onUpdateLabel
           .subscribe((updates) => {
               this.checkValueAssignedAndUpdate(updates);
               updates.onSuccess();
           });
   }

   checkValueAssignedAndUpdate(params): void {
       const checkValueIds = params.removedValues.map((v) => v.id);

       if (checkValueIds.length) {
           this.labelsService.checkIsAssigned({
               teamId: this.team.id,
               labelId: this.selectedLabel.id,
               values: checkValueIds
           }).subscribe(({ isAssigned }) => {
               if (isAssigned) {
                   this.openValueAssignedWarnModal(params);
               }
               else {
                   this.persistUpdate(params).subscribe();
               }
           });
       }
       else {
           this.persistUpdate(params).subscribe();
       }
   }

   private handleErrorNotification(error: ApiError, customMessage?: string): void {

       const message = (error && error.error && error.error.message) || customMessage;
       if (message) {
           return this.notificationsService.error(message);
       }

       return this.notificationsService.unexpectedError();
   }

   trackById(index: number, item: any): number {
       return item.id;
   }


   canEditSelectedLabel() {
       const {
           labelEdit, labelValueManage, labelValueAdd, labelValueUpdate, labelValueDelete
       } = this.team.permissions;

       return this.selectedLabel
        && labelEdit && (labelValueManage || (labelValueAdd && labelValueUpdate && labelValueDelete));
   }

   canDeleteSelectedLabel() {
       return this.selectedLabel
        && !this.selectedLabel.isPredefined
        && this.team.permissions.labelDelete;
   }

   canActOnSelection() {
       return this.canEditSelectedLabel()
        || this.canDeleteSelectedLabel();
   }

   toggleActions($event, label): void {
       $event.preventDefault();
       if (!label) {
           return;
       }
       if (this.selectedLabel !== label) {
           this.select(label);
       }
   }

}
