import { Inject, Injectable } from '@angular/core';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { StateService } from '@uirouter/core';
import { forEach } from 'angular';
import { isEmpty, template } from 'lodash';

import {
    SessionActivityStatuses,
    SessionActivityEvents
} from './session-activity.constants';
import {
    DocumentUpload,
    FailedShortcutCreation,
    FailedSignatureReminder,
    FailedTaskReminder,
    SuccessfulShortcutCreation,
    SuccessfulSignatureReminder,
    SuccessfulTaskReminder
} from './session-activity.service.types';
@Injectable({ providedIn: 'root' })
export class SessionActivityService {

    constructor(
        private $state: StateService,
        private Notifications: NotificationsService,
        @Inject('Window') private $window: Window

    ) { }

    private sessionStorageKey = 'sessionActivity';
    activityHash = {};

    private clearArray(arr: []): void {
        if (!arr) {
            return;
        }
        while (arr.length > 0) {
            arr.pop();
        }
    }

    private clearEach(hash): void {
        forEach(hash, (val, teamId) => {
            if (this.activityHash[teamId]) {
                this.clearArray(this.activityHash[teamId]);
            }
        });
    }

    private loadSessionActivity(): void {
        const sessionActivityStr = this.$window.sessionStorage.getItem(this.sessionStorageKey);
        const storedVal = sessionActivityStr ? JSON.parse(sessionActivityStr) : {};

        if (!isEmpty(this.activityHash)) {
            if (isEmpty(storedVal)) {
                this.clearEach(this.activityHash);
            }
            return;
        }
        this.activityHash = storedVal;
    }

    private saveValues(): void {
        try {
            window.sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(this.activityHash));
        }
        catch (err) {
            /*
            setItem() will throw an exception if the storage quota is exceeded
            NOTE: some browsers in private mode have quota of 0, which means they do not allow storage at all
            */
            this.Notifications.info('Could not save action to Session Activity Log.');
        }
    }

    private getTeamSessionActivity(teamId: string) {
        this.loadSessionActivity();
        if (!this.activityHash[teamId]) {
            this.activityHash[teamId] = [];
        }
        return this.activityHash[teamId];
    }

    private pushSessionActivityEvent = (teamId: string, data) => {
        this.getTeamSessionActivity(teamId).push(data);
        this.saveValues();
    };

    pushFailedShortcutCreation(teamId: string, data: FailedShortcutCreation): void {
        const event = SessionActivityEvents.SHORTCUT_CREATE;
        const status = SessionActivityStatuses.FAILED;
        const params = {
            data: {
                when: data.when,
                object: data.shortcut,
                origin: data.origin,
                target: data.target,
                error: data.error
            },
            event,
            status
        };
        this.pushSessionActivityEvent(teamId, params);
    }

    pushSuccessfulShortcutCreation(teamId: string, data: SuccessfulShortcutCreation): void {

        const event = SessionActivityEvents.SHORTCUT_CREATE;
        const status = SessionActivityStatuses.SUCCESSFUL;
        const params = {
            data: {
                when: data.when,
                object: data.shortcut,
                origin: data.origin,
                target: data.target
            },
            event,
            status
        };
        this.pushSessionActivityEvent(teamId, params);
    }

    pushFailedSignatureReminder(teamId: string, data: FailedSignatureReminder): void {
        const event = SessionActivityEvents.SIGNATURE_REMINDER;
        const status = SessionActivityStatuses.FAILED;
        const params = {
            data: {
                when: data.when,
                object: data.document,
                error: data.error
            },
            event,
            status
        };
        this.pushSessionActivityEvent(teamId, params);
    }

    pushSuccessfulSignatureReminder(teamId: string, data: SuccessfulSignatureReminder): void {
        const event = SessionActivityEvents.SIGNATURE_REMINDER;
        const status = SessionActivityStatuses.SUCCESSFUL;
        const params = {
            data: {
                when: data.when,
                object: data.document
            },
            event,
            status
        };
        this.pushSessionActivityEvent(teamId, params);
    }

    pushFailedTaskReminder(teamId: string, data: FailedTaskReminder): void {
        const event = SessionActivityEvents.TASK_REMINDER;
        const status = SessionActivityStatuses.FAILED;
        const params = {
            data: {
                when: data.when,
                object: data.task,
                document: data.document,
                error: data.error
            },
            event,
            status
        };
        this.pushSessionActivityEvent(teamId, params);
    }

    pushSuccessfulTaskReminder(teamId: string, data: SuccessfulTaskReminder): void {
        const event = SessionActivityEvents.TASK_REMINDER;
        const status = SessionActivityStatuses.SUCCESSFUL;
        const params = {
            data: {
                when: data.when,
                object: data.task,
                document: data.document
            },
            event,
            status
        };
        this.pushSessionActivityEvent(teamId, params);
    }

    pushFailedDocumentUpload(teamId: string, data: DocumentUpload): void {
        const event = SessionActivityEvents.DOCUMENT_UPLOAD;
        const status = SessionActivityStatuses.FAILED;
        const params = {
            data: {
                object: data.document,
                when: data.when
            },
            event,
            status
        };
        this.pushSessionActivityEvent(teamId, params);
    }

    pushSuccessfulDocumentUpload(teamId: string, data: DocumentUpload): void {
        const event = SessionActivityEvents.DOCUMENT_UPLOAD;
        const status = SessionActivityStatuses.SUCCESSFUL;
        const params = {
            data: {
                object: data.document,
                when: data.when
            },
            event,
            status
        };
        this.pushSessionActivityEvent(teamId, params);
    }

    getUserActivityForTeam(teamId: string) {
        return this.getTeamSessionActivity(teamId);
    }

    clearUserActivityForTeam(teamId: string) {
        this.loadSessionActivity();
        if (this.activityHash[teamId]) {
            this.clearArray(this.activityHash[teamId]);
        }
        this.saveValues();
    }

    getNotificationMessage(teamId: string, baseMsg: string) {
        const msg = `${baseMsg}<br><strong><a href="<%= url %>">CLICK HERE FOR DETAILS</a></strong>`;
        const msgTemplate = template(msg);

        const url = this.$state.href('app.team.session-activity-show', { teamId });

        return msgTemplate({ url });
    }
}
