import {
    Component, Input, EventEmitter, Output, OnChanges
} from '@angular/core';
import {
    Column, DocumentPropertyColumn, LogEntryTypes, Signature
} from '@app/shared/models';
import { LogEntryRowLogEntry, LogEntrySigStatuses } from './log-entry-row.component.types';

import template from './log-entry-row.component.html';
import styles from './log-entry-row.component.scss';

@Component({
    selector: 'log-entry-row',
    template,
    styles: [String(styles)]
})
export class LogEntryRowComponent implements OnChanges {

    @Input() logEntry: LogEntryRowLogEntry;
    @Input() columnsSpec: DocumentPropertyColumn[];
    @Input() isEntrySelected: boolean;
    @Input() isLatestForRow: boolean;
    @Input() isHistoryExpanded: boolean;
    @Input() currentUserId: string;
    @Input() canAct: boolean;
    @Input() isDocumentLocked: boolean;
    @Input() disableActions: boolean;
    @Input() canSign: boolean;
    @Input() parentStruckThrough: boolean;
    @Output() select = new EventEmitter<void>();
    @Output() actionsClick = new EventEmitter<void>();
    @Output() expandHistory = new EventEmitter<void>();
    @Output() signEntry = new EventEmitter<string>();
    @Output() declineEntry = new EventEmitter<string>();
    logEntrySignatureStatuses = LogEntrySigStatuses;

    ngOnChanges(): void {
        if (this.logEntry) {
            this.logEntry.columns = this.logEntry.columns.map((column) => {
                if (column.type === LogEntryTypes.multiSelect) {
                    return {
                        ...column,
                        displayText: this.getMultiselectColumnText(column)
                    };
                }
                if (column.type === LogEntryTypes.singleSelect) {
                    return {
                        ...column,
                        displayText: this.getSingleselectColumnText(column)
                    };
                }
                return column;
            });
        }
    }

    emitEntrySelection(): void {
        this.select.emit();
    }

    emitActionsClick(): void {
        if (this.disableActions) {
            return;
        }
        this.actionsClick.emit();
    }

    emitEntryExpansion(): void {
        this.expandHistory.emit();
    }

    handleSign(columnName): void {
        if (this.isDocumentLocked) {
            return;
        }

        this.signEntry.emit(columnName);
    }

    handleDecline(columnName): void {
        if (this.isDocumentLocked) {
            return;
        }

        this.declineEntry.emit(columnName);
    }

    getLogEntrySignatureStatus(column: Column): number | null {
        const isSigned = this.isSignatureStatus(column.value as Signature, 'Signed');
        if (!this.isLatestForRow || !this.logEntry.isLatestVersion || !this.canSign || isSigned) {
            return isSigned ? this.logEntrySignatureStatuses.signed : null;
        }

        const { signatureRequest } = column;
        if (!signatureRequest) {
            return this.logEntrySignatureStatuses.signable;
        }
        if (signatureRequest.userId !== this.currentUserId) {
            return this.logEntrySignatureStatuses.pendingAnotherUser;
        }
        return signatureRequest.isDue
            ? this.logEntrySignatureStatuses.pastDueCurrentUser
            : this.logEntrySignatureStatuses.pendingCurrentUser;
    }

    getLetterForIndex(index: number): string {
        const letterCode = 65 + (index % 26);
        const repeatCount = Math.floor(index / 26);
        let letter = String.fromCharCode(letterCode);
        if (repeatCount > 0) {
            letter = this.getLetterForIndex(repeatCount - 1) + letter;
        }
        return letter;
    }

    private getMultiselectColumnText(column: Column) {

        if (!(column.value && (column.value as string[]).length)) {
            return '';
        }

        const selectedOptionIndices = [];
        const columnSpec = this.columnsSpec.find(({ name, type }) => column.name === name && column.type === type);
        columnSpec.selectOptions.options.forEach((optSpec, index) => {
            if ((column.value as string[]).includes(optSpec.id)) {
                selectedOptionIndices.push(index);
            }
        });

        const isLetterIdentifier = columnSpec.selectOptions.isNumberIdentifier === false;

        const identifiers = isLetterIdentifier
            ? selectedOptionIndices.map((index) => this.getLetterForIndex(index))
            : selectedOptionIndices.map((index) => index + 1);

        return identifiers.join(', ');
    }

    private getSingleselectColumnText(column: Column) {

        if (!column.value) {
            return '';
        }

        const columnSpec = this.columnsSpec.find(({ name, type }) => column.name === name && column.type === type);
        const selectedOptionSpec = columnSpec.selectOptions.options.find((option) => option.id === column.value);
        return selectedOptionSpec ? selectedOptionSpec.name : '';
    }

    isSignatureStatus(signature: Signature, status: string): boolean {
        return signature?.status === status;
    }
}
