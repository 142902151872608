import {
    Component,
    EventEmitter, HostListener,
    Input,
    OnChanges,
    Output,
    ViewChild
} from '@angular/core';
import { MAX_DATE_ISO } from '@app/core/constants';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { DropDownPosition, IDateTimePopupButtonOptions } from './date-time-popup.component.types';
import template from './date-time-popup.component.html';
import style from './date-time-popup.component.scss';

  @Component({
      template,
      selector: 'date-time-popup',
      styles: [String(style)]
  })

export class DateTimePopupComponent implements OnChanges {

    @Input() modalPositionConfig: DropDownPosition;

    @ViewChild('dropdown', { static: true })
    public dropdown: BsDropdownDirective;

    @Input()
    public appendTo: string = undefined;

    @Input()
    public value: Date;

    @Output()
    public valueChange = new EventEmitter();

    @Input()
    public allowMaxDateInput = false;

    @Input()
    public showPopup = false;

    @Output()
    public showPopupChange = new EventEmitter();

    @Input()
    public showDate = true;

    @Input()
    public showTime = true;

    @Input()
    public showWeeks = false;

    @Input()
    public showMeridian = false;

    @Input()
    public showSeconds = false;

    @Input()
    public datepickerMode = 'day';

    @Input()
    public initDate: Date = null;

    @Input()
    public minDate: Date = null;

    @Input()
    public maxDate: Date = null;

    @Input()
    public dateDisabled: any[] = [];

    @Input()
    public nowButton: IDateTimePopupButtonOptions;

    @Input()
    public clearButton: IDateTimePopupButtonOptions;

    @Input()
    public closeButton: IDateTimePopupButtonOptions;

    @Input()
    public minuteStep = 5;

    @Input()
    public hourStep = 1;

    @Input()
    public secondsStep = 1;

    @Input()
    public timezone: string;

    public localValue: Date = null;
    public isOpening = false;
    dropdownPlacement: string;
    allowArrowKeys = true;
    MAX_DATE = new Date(MAX_DATE_ISO);
    public isDropUp = false;


    getDropdownPlacement(): string {
        return 'bottom';
    }

    public ngOnChanges(changes: any) {
        if (!this.nowButton) {
            this.nowButton = { show: true, label: 'Now', cssClass: 'btn btn-secondary btn-sm' };
        }

        if (!this.clearButton) {
            this.clearButton = { show: true, label: 'Clear', cssClass: 'btn btn-secondary btn-sm' };
        }

        if (!this.closeButton) {
            this.closeButton = { show: true, label: 'Close', cssClass: 'btn btn-secondary btn-sm' };
        }

        this.dropdownPlacement = this.getDropdownPlacement();


        // user maybe typing a value into an input box, so would come in as string
        if (typeof this.value === 'string') {
        // check if the string is a valid date

            if (!Number.isNaN(new Date(this.value).getTime())) {
                this.localValue = new Date(this.value);
            }
        }
        else if (this.value) {
            this.localValue = this.value;
        }

        // toggle if open
        if (changes.showPopup && this.dropdown) {
            if (changes.showPopup.currentValue === true && this.dropdown.isOpen === false) {
                this.dropdown.show();
            }
            else if (changes.showPopup.currentValue === false && this.dropdown.isOpen === true) {
                this.dropdown.hide();
            }
        }
    }

    @HostListener('window:scroll', [])
    @HostListener('window:resize', [])

    public dropdownStyle() {
        if (!this.modalPositionConfig) {
            return {};
        }

        const {
            top, right, bottom, left
        } = this.modalPositionConfig;
        const customStyles: any = {};

        if (top !== undefined) {
            customStyles.top = `${top}px`;
        }
        if (right !== undefined) {
            customStyles.right = `${right}px`;
        }
        if (bottom !== undefined) {
            customStyles.bottom = `${bottom}px`;
        }
        if (left !== undefined) {
            customStyles.left = `${left}px`;
        }

        return customStyles;
    }


    public onOpenChange() {
        if (this.dropdown.isOpen) {
            this.isOpening = true;

            if (this.allowMaxDateInput) {
                const valueDate = this.value ? new Date(this.value) : new Date();
                if (valueDate.getTime() === this.MAX_DATE.getTime() || this.value === null) {
                    // If the value matches MAX_DATE, set localValue to the current date
                    this.localValue = new Date();
                    this.localValue.setHours(0, 0, 0, 0);
                }
            }

            setTimeout(() => {
                this.isOpening = false;
            }, 250);
        }
    }

    public onHidden() {
        this.showPopup = false;
        this.showPopupChange.emit(false);
    }

    public onNow() {
        this.localValue = new Date();
        this.onPickerChange('timepicker');
    }

    public onClear() {
        this.valueChange.emit(null);
    }

    public onClose() {
        this.showPopup = false;
        this.showPopupChange.emit(false);
    }

    public onPickerChange(picker: string) {
        if (this.isOpening === true) {
            return;
        }

        if (picker === 'datepicker' && this.value !== null) {
            if (typeof this.value === 'string') {
                this.value = new Date(this.value);
            }
            const hours = this.value.getHours();
            const minutes = this.value.getMinutes();
            const seconds = this.value.getSeconds();
            const milliseconds = this.value.getMilliseconds();

            this.localValue.setHours(hours, minutes, seconds, milliseconds);
        }

        this.valueChange.emit(this.localValue);

        if (this.showDate === true && this.showTime === false) {
            this.onHidden();
        }
    }
}
