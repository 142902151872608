import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortByDate'
})
export class SortByDatePipe implements PipeTransform {

    transform(value: any[], dateField: string, order = 'asc'): any[] {
        if (!value || !dateField) {
            return value;
        }

        return value.sort((a, b) => {
            const dateA = new Date(a[dateField]);
            const dateB = new Date(b[dateField]);

            const comparison = dateA.getTime() - dateB.getTime();

            return order === 'asc' ? comparison : -comparison;
        });
    }

}
