import { Component, Input } from '@angular/core';
import template from './role-chip-inactive.component.html';
import { IChipIcon } from '../../../../widgets/chip/types/i-chip-icon';

@Component({
    selector: 'role-chip-inactive',
    template
})
export class RoleChipInactiveComponent {
    @Input() roleName: string;

    icon: IChipIcon = {
        name: 'fa-minus-circle',
        color: '#ADB7C2'
    }
}
