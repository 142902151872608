import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import {
    Document,
    DocumentSubTypes,
    SignatureRequest,
    SignatureTypes,
    User
} from '@app/shared/models';
import { ALLOWED_PREVIEW_FILE_TYPES } from '@florencehealthcare/florence-constants/lib/documents';
import { sortBy } from '@app/widgets/sort/sort-by.util';
import { isDateInThePast } from '@app/shared/date-time/is-date-in-the-past.util';
import { areDatesEqual } from '@app/shared/date-time/are-dates-equal.util';
import { CurrentSessionService } from '@app/core/current-session.service';
import { CHECKBOX_STATES } from '@app/core/constants';
import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';
import {
    CreateSignatureRequestParams,
    GetDocumentsPendingSignatureRequestsResponse,
    GetNoSignPermissionsResponse,
    SignatureRequestReminderParams,
    SignatureRequestsBulkUpdatePayload
} from '@app/shared/documents/documents.service.types';

import {
    DocumentsSignatureDataRow,
    DocumentsSignatureDataRowProps,
    DocumentsTabDataRow,
    isDocumentsTabDataRow
} from '../components/documents-selected-tab/documents-selected-tab.types';
import { DocumentsSignersTabDataRow, DocumentsSignersDataRowProps } from '../components/documents-signers-tab/documents-signers-tab.types';
import {
    DocumentsPendingSignatureRequestsDataRow,
    DocumentsPendingSignatureTabDataRow,
    isPendingTabDataRow,
    PendingSignatureRequest
} from '../components/documents-pending-signatures-tab/documents-pending-signatures-tab.types';
import { NoSigningPermissionsModalData, NoSignUserEntity } from '../components/no-signing-permissions/no-signing-permissions.types';

type DocumentsGroupedByLocation = { path: string; documents: (DocumentsSignatureDataRow)[] }[]
type DocumentEntityPath = {
    type: 'binder' | 'folder';
    name: string;
}[];

type DocumentsTabUpdates = Partial<Pick<
    DocumentsSignatureDataRowProps,
    | 'method'
    | 'reason'
>>

type SignersTabUpdates = Partial<Pick<
    DocumentsSignersDataRowProps,
    'signByDate'
    | 'notifyMe'
    | 'emailSigner'
>>

type PendingTabUpdates = Partial<Pick<
    PendingSignatureRequest,
    'isMarkedForCancellation'
    | 'method'
    | 'remindSigner'
    | 'notifyRequestorWhenSigned'
    | 'signByDate'
    | 'reason'
>>

@Injectable({
    providedIn: 'root'
})
export class DocumentsSignatureRequestsStore {
    private readonly selectedDocuments = new BehaviorSubject<Document[]>([]);
    private readonly documentsTabData = new BehaviorSubject<DocumentsTabDataRow[]>([]);
    selectedDocuments$ = this.selectedDocuments.asObservable();
    documentsTabData$ = this.documentsTabData.asObservable();

    private readonly selectedSigners = new BehaviorSubject<User[]>([]);
    private readonly signersTabData = new BehaviorSubject<DocumentsSignersTabDataRow[]>([]);
    selectedSigners$ = this.selectedSigners.asObservable();
    signersTabData$ = this.signersTabData.asObservable();

    private readonly pendingRequests = new BehaviorSubject<GetDocumentsPendingSignatureRequestsResponse>([]);
    private readonly pendingTabData = new BehaviorSubject<DocumentsPendingSignatureTabDataRow[]>([]);
    pendingRequests$ = this.pendingRequests.asObservable();
    pendingTabData$ = this.pendingTabData.asObservable();

    private readonly noSigningPermissionsData = new BehaviorSubject<GetNoSignPermissionsResponse>([]);
    noSigningPermissionsData$ = this.noSigningPermissionsData.asObservable();

    private teamPreferedSignatureMethod: SignatureTypes;
    private currentUserId: string;

    constructor(
        private CurrentSession: CurrentSessionService
    ) {
        this.CurrentSession.currentTeam$.subscribe((team) => {
            if (!team?.settings?.signatures) {
                return;
            }
            const { disableAddendum, disableAnnotation } = team?.settings?.signatures;
            if (disableAddendum) {
                this.teamPreferedSignatureMethod = SignatureTypes.stamp;
            }
            if (disableAnnotation) {
                this.teamPreferedSignatureMethod = SignatureTypes.addendum;
            }
        });
        this.CurrentSession.currentUser$.subscribe((u) => {
            this.currentUserId = u?.id;
        });
    }

    private mapDocumentsForDocumentsTab(data: (Document | DocumentsTabDataRow)[]): DocumentsTabDataRow[] {
        let groupedByLocation: DocumentsGroupedByLocation = data.reduce<DocumentsGroupedByLocation>((acc, d) => {
            if (!isDocumentsTabDataRow(d)) {
                return acc;
            }

            const document = d as DocumentsSignatureDataRow;
            const docPath = Array.isArray(document.path) && document.path.length
                ? document.path[document.path.length - 1].path
                : document.path;

            let groupPath = document.binderName;
            if (docPath && docPath.length) {
                groupPath = `${groupPath}/${docPath}`;
            }
            const group = acc.find((g) => {
                return g.path === groupPath;
            });
            if (group) {
                group.documents.push(document);
            }
            else {
                acc.push({
                    path: groupPath,
                    documents: [document]
                });
            }
            return acc;
        }, []);
        groupedByLocation = sortBy(groupedByLocation, 'path');

        const flat = groupedByLocation.reduce<DocumentsTabDataRow[]>((acc, group) => {
            const entityPath: DocumentEntityPath = group.path.split('/')
                .map((pathItem, index) => {
                    return { name: pathItem, type: index === 0 ? 'binder' : 'folder' };
                });

            return acc.concat([
                {
                    entityPath,
                    rowDataType: 'header-row'
                },
                ...sortBy(group.documents, 'title')
            ]);
        }, []);
        return flat;
    }

    private getDocumentSignatureTypeOptions(document: Document): Array<SignatureTypes> {
        if (document.subType === 'shortcut') {
            return [SignatureTypes.addendum];
        }
        if (document.subType === 'log') {
            return [SignatureTypes.log];
        }
        if (document.formStatus === 'form-in-progress') {
            return [SignatureTypes.stamp];
        }
        if (!ALLOWED_PREVIEW_FILE_TYPES.includes(document.ext?.toLowerCase())) {
            return [SignatureTypes.addendum];
        }
        if (this.teamPreferedSignatureMethod) {
            return [this.teamPreferedSignatureMethod];
        }
        return [
            SignatureTypes.any,
            SignatureTypes.addendum,
            SignatureTypes.stamp
        ];
    }

    private mapAdditionalDocumentProps(
        document: DocumentsSignatureDataRow,
        additionalProps: DocumentsSignatureDataRowProps = {}
    ): DocumentsSignatureDataRow {
        let { signatureTypeOptions } = document;
        if (!signatureTypeOptions) {
            signatureTypeOptions = this.getDocumentSignatureTypeOptions(document);
        }
        let { method } = additionalProps;
        if (method && !signatureTypeOptions.includes(method)) {
            [method] = signatureTypeOptions;
        }

        return {
            ...document,
            ...additionalProps,
            ...method && { method },
            signatureTypeOptions
        };
    }

    private docHasAllSignersWithPendingRequest(
        docPendingRequests: SignatureRequest[],
        selectedSigners: DocumentsSignersTabDataRow[]
    ): boolean {
        if (!selectedSigners.length) {
            return !!docPendingRequests.length;
        }
        const userIds = docPendingRequests.map((sr) => sr.userId);
        const foundSelectedSigners = selectedSigners.filter((s) => userIds.includes(s.id));
        return foundSelectedSigners.length === selectedSigners.length;
    }

    private getPendingRequestsUpdates(
        data: GetDocumentsPendingSignatureRequestsResponse,
        selectedDocumentsCount: number,
        signersTabData: User[]
    ): {
        docIdsWithAllPending: Set<string>;
        docsIdsWithSomePending: Set<string>;
        signerIdsWithAllPending: Set<string>;
        signerIdsWithSomePending: Set<string>;
        signersToPendingDocsHash: { [key: string]: string[] };
    } {

        const docIdsWithAllPending = new Set<string>();
        const docsIdsWithSomePending = new Set<string>();
        const signerIdsWithAllPending = new Set<string>();
        const signerIdsWithSomePending = new Set<string>();
        const signersToPendingDocsHash: { [key: string]: string[] } = {};

        data.forEach((dataItem) => {

            if (this.docHasAllSignersWithPendingRequest(dataItem.pendingSignatureRequests, signersTabData)) {
                docIdsWithAllPending.add(dataItem.documentId);
            }
            else {
                docsIdsWithSomePending.add(dataItem.documentId);
            }
            dataItem.pendingSignatureRequests.forEach(({ userId: signerId }) => {
                if (signersToPendingDocsHash[signerId]) {
                    signersToPendingDocsHash[signerId].push(dataItem.documentId);
                }
                else {
                    signersToPendingDocsHash[signerId] = [dataItem.documentId];
                }
            });
        });
        Object.keys(signersToPendingDocsHash).forEach((signerId) => {
            if (signersToPendingDocsHash[signerId].length === selectedDocumentsCount) {
                signerIdsWithAllPending.add(signerId);
            }
            else {
                signerIdsWithSomePending.add(signerId);
            }
        });
        return {
            docIdsWithAllPending,
            docsIdsWithSomePending,
            signerIdsWithAllPending,
            signerIdsWithSomePending,
            signersToPendingDocsHash
        };
    }

    private docHasAllSignersThatLackSignPermission(
        signerIdsWithNoPermission: string[],
        selectedSigners: DocumentsSignersTabDataRow[]
    ): boolean {
        const foundSelectedSigners = selectedSigners.filter((s) => signerIdsWithNoPermission.includes(s.id));
        return selectedSigners.length && foundSelectedSigners.length === selectedSigners.length;
    }

    private getNoSigningPermissionUpdates(
        data: GetNoSignPermissionsResponse,
        selectedSigners: DocumentsSignersTabDataRow[],
        selectedDocumentsCount: number
    ): {
        docIdsAllLackPermission: Set<string>;
        docIdsSomeLackPermission: Set<string>;
        signerIdsLackPermissionForAll: Set<string>;
        signerIdsLackPermissionForSome: Set<string>;
        signersToDocsPermsHash: { [key: string]: string[] };
    } {

        const docIdsAllLackPermission = new Set<string>();
        const docIdsSomeLackPermission = new Set<string>();
        const signerIdsLackPermissionForAll = new Set<string>();
        const signerIdsLackPermissionForSome = new Set<string>();
        const signersToDocsPermsHash: { [key: string]: string[] } = {};

        data.forEach((dataItem) => {
            if (this.docHasAllSignersThatLackSignPermission(dataItem.usersWithoutSignPermissions, selectedSigners)) {
                docIdsAllLackPermission.add(dataItem.documentId);
            }
            else {
                docIdsSomeLackPermission.add(dataItem.documentId);
            }
            dataItem.usersWithoutSignPermissions.forEach((signerId) => {
                if (signersToDocsPermsHash[signerId]) {
                    signersToDocsPermsHash[signerId].push(dataItem.documentId);
                }
                else {
                    signersToDocsPermsHash[signerId] = [dataItem.documentId];
                }
            });
        });
        Object.keys(signersToDocsPermsHash).forEach((signerId) => {
            if (signersToDocsPermsHash[signerId].length === selectedDocumentsCount) {
                signerIdsLackPermissionForAll.add(signerId);
            }
            else {
                signerIdsLackPermissionForSome.add(signerId);
            }
        });

        return {
            docIdsAllLackPermission,
            docIdsSomeLackPermission,
            signerIdsLackPermissionForAll,
            signerIdsLackPermissionForSome,
            signersToDocsPermsHash
        };
    }

    private calculateDocumentEntityPath(document: Document): DocumentEntityPath {
        const entityPath: DocumentEntityPath = [{ type: 'binder', name: document.binderName }];
        if (Array.isArray(document.path) && document.path.length) {
            document.path.forEach((pathElement) => {
                entityPath.push({
                    type: 'folder',
                    name: pathElement.name
                });
            });
        }
        else if (typeof document.path === 'string' && document.path.length) {
            document.path.split('/').forEach((pathElement) => {
                entityPath.push({
                    type: 'folder',
                    name: pathElement
                });
            });
        }
        return entityPath;
    }

    private mapPendingRequestsAndPermissionsDataToTabs(
        pendingRequests: GetDocumentsPendingSignatureRequestsResponse,
        permissionsData: GetNoSignPermissionsResponse,
        selectedDocumentsCount: number,
        signers: User[],
        documentsTabData: DocumentsTabDataRow[],
        signersTabData: DocumentsSignersTabDataRow[]
    ): {
        updatedDocumentsTabRows: DocumentsTabDataRow[];
        updatedSignersTabDataRows: DocumentsSignersTabDataRow[];
    } {

        const {
            docIdsWithAllPending,
            docsIdsWithSomePending,
            signerIdsWithAllPending,
            signerIdsWithSomePending,
            signersToPendingDocsHash
        } = this.getPendingRequestsUpdates(
            pendingRequests,
            selectedDocumentsCount,
            signers
        );

        const {
            docIdsAllLackPermission,
            docIdsSomeLackPermission,
            signerIdsLackPermissionForAll,
            signerIdsLackPermissionForSome,
            signersToDocsPermsHash
        } = this.getNoSigningPermissionUpdates(
            permissionsData,
            signers,
            selectedDocumentsCount
        );

        const docIdsPreviewNotAvailable = new Set<string>();
        const shortcutIdsNotSignable = new Set<string>();
        documentsTabData.forEach((d) => {
            if (isDocumentsTabDataRow(d)
                && (d.subType === DocumentSubTypes.content
                    || (d.subType === DocumentSubTypes.shortcut && d.originalDocumentSubType === DocumentSubTypes.content))
                && (!ALLOWED_PREVIEW_FILE_TYPES.includes(d.ext?.toLowerCase()))
            ) {
                docIdsPreviewNotAvailable.add(d.id as string);
            }
            if (isDocumentsTabDataRow(d)
                && (d.subType === DocumentSubTypes.shortcut && this.teamPreferedSignatureMethod === SignatureTypes.stamp)
            ) {
                shortcutIdsNotSignable.add(d.id as string);
            }
        });

        const updatedDocumentsTabRows = documentsTabData.map((row) => {
            if (row.rowDataType === 'header-row') {
                return row;
            }
            const documentId = row.id;
            return this.mapAdditionalDocumentProps(
                row,
                {
                    hasPendingSignatureRequests: false,
                    hasSignersWithoutPermissions: false,
                    rowDataType: undefined,
                    ...docIdsWithAllPending.has(documentId) && { rowDataType: 'all-signers-have-pending-request' },
                    ...docsIdsWithSomePending.has(documentId) && { hasPendingSignatureRequests: true },
                    ...docIdsAllLackPermission.has(documentId) && { rowDataType: 'all-signers-lack-sign-permission' },
                    ...docIdsSomeLackPermission.has(documentId) && { hasSignersWithoutPermissions: true },
                    ...docIdsPreviewNotAvailable.has(documentId) && { rowDataType: 'file-unsupported' },
                    ...shortcutIdsNotSignable.has(documentId) && { rowDataType: 'shortcut-not-signable' }
                }
            );
        });

        const updatedSignersTabDataRows: DocumentsSignersTabDataRow[] = signersTabData.map((row) => {
            const signerId = row.id;
            const hasPendingAndLacksPermissionsForAll = signersToDocsPermsHash[signerId]
                && signersToDocsPermsHash[signerId].length !== selectedDocumentsCount
                && signersToPendingDocsHash[signerId]
                && signersToPendingDocsHash[signerId].length !== selectedDocumentsCount
                && signersToDocsPermsHash[signerId].length + signersToPendingDocsHash[signerId].length === selectedDocumentsCount;
            return {
                ...row,
                lacksPermissionsForSomeDocuments: false,
                hasPendingSignatureRequestsOnSomeDocuments: false,
                rowDataType: undefined,
                ...signerIdsLackPermissionForSome.has(signerId) && {
                    lacksPermissionsForSomeDocuments: true,
                    lacksPermissionsForDocuments: signersToDocsPermsHash[signerId]
                },
                ...signerIdsLackPermissionForAll.has(signerId) && { rowDataType: 'lacks-sign-permission-for-all-documents' },
                ...signerIdsWithSomePending.has(signerId) && {
                    hasPendingSignatureRequestsOnSomeDocuments: true,
                    hasPendingSignatureRequestsOnDocuments: signersToPendingDocsHash[signerId]
                },
                ...signerIdsWithAllPending.has(signerId) && { rowDataType: 'has-pending-request-for-all-documents' },
                ...hasPendingAndLacksPermissionsForAll && { rowDataType: 'lacks-sign-permission-for-all-documents' }
            };
        });
        return {
            updatedDocumentsTabRows,
            updatedSignersTabDataRows
        };
    }

    private mapPendingRequestsToPendingTabData(
        loadedPendingRequests: GetDocumentsPendingSignatureRequestsResponse,
        documents: Document[]
    ): DocumentsPendingSignatureTabDataRow[] {
        let pendingTabData = [];
        loadedPendingRequests.forEach((dataItem, index) => {
            if (dataItem.pendingSignatureRequests.length) {
                const document = { ...documents[index] };
                pendingTabData = pendingTabData.concat([
                    {
                        documentId: dataItem.documentId,
                        rowDataType: 'header-row',
                        document,
                        entityPath: this.calculateDocumentEntityPath(document)
                    },
                    ...dataItem.pendingSignatureRequests.map((psr): DocumentsPendingSignatureRequestsDataRow => {
                        return {
                            documentId: dataItem.documentId,
                            signatureTypeOptions: this.getDocumentSignatureTypeOptions(document),
                            pendingSignatureRequest: {
                                id: psr.id,
                                method: psr.method,
                                reason: psr.reason,
                                signByDate: psr.signByDate,
                                isMarkedForCancellation: false,
                                user: psr.user,
                                notifyRequestorWhenSigned: psr.notifyUsersOnSigned.includes(this.currentUserId),
                                remindSigner: false,
                                isChanged: false,
                                checked: CHECKBOX_STATES.NOT_SELECTED,
                                showSignByDateInvalid: false
                            }
                        };
                    })
                ]);
            }
        });
        return pendingTabData;
    }

    addDocumentsData(
        documents: Document[],
        loadedPendingRequests: GetDocumentsPendingSignatureRequestsResponse,
        newPermissionsData: GetNoSignPermissionsResponse
    ): void {
        let pendingTabData = this.mapPendingRequestsToPendingTabData(loadedPendingRequests, documents);
        const filteredPendingRequests = loadedPendingRequests.filter((d) => d.pendingSignatureRequests.length);
        const pendingRequests = this.pendingRequests.getValue().concat(filteredPendingRequests);
        const selectedDocuments = this.selectedDocuments.getValue().concat(documents);
        const permissionsData = this.noSigningPermissionsData.getValue().concat(newPermissionsData);
        const {
            updatedDocumentsTabRows,
            updatedSignersTabDataRows
        } = this.mapPendingRequestsAndPermissionsDataToTabs(
            pendingRequests,
            permissionsData,
            selectedDocuments.length,
            this.selectedSigners.getValue(),
            this.documentsTabData.getValue().concat(documents as unknown as DocumentsSignatureDataRow),
            this.signersTabData.getValue()
        );
        this.selectedDocuments.next(selectedDocuments);
        this.pendingRequests.next(pendingRequests);
        this.noSigningPermissionsData.next(permissionsData);

        this.documentsTabData.next(this.mapDocumentsForDocumentsTab(updatedDocumentsTabRows));
        this.signersTabData.next(updatedSignersTabDataRows);
        pendingTabData = this.pendingTabData.getValue().concat(pendingTabData);
        this.pendingTabData.next(pendingTabData);
    }

    updateDocumentsData(data: DocumentsTabUpdates, documentIds: string[]): void {
        const updatedData = this.documentsTabData.getValue()
            .slice()
            .map((d) => {
                if (!isDocumentsTabDataRow(d)) {
                    return d;
                }
                let updated = d;
                if (documentIds.includes(updated.id)) {
                    updated = this.mapAdditionalDocumentProps(updated, data);
                    updated = { ...updated, changed: true };
                }
                return updated;
            });
        this.documentsTabData.next(updatedData);
    }

    removeSelectedDocuments(documentsIds: string[]): void {
        const selectedDocuments = this.selectedDocuments.getValue()
            .filter((d) => !documentsIds.includes(d.id as string));
        this.selectedDocuments.next(selectedDocuments);

        const permissionsData = this.noSigningPermissionsData.getValue()
            .filter((dataItem) => !documentsIds.includes(dataItem.documentId));
        this.noSigningPermissionsData.next(permissionsData);

        const pendingRequests = this.pendingRequests.getValue()
            .filter((dataItem) => !documentsIds.includes(dataItem.documentId));
        this.pendingRequests.next(pendingRequests);

        const pendingTabData = this.pendingTabData.getValue()
            .filter((dataItem) => !documentsIds.includes(dataItem.documentId));
        this.pendingTabData.next(pendingTabData);

        const documentsTabData = this.documentsTabData.getValue()
            .filter((d: DocumentsSignatureDataRow) => !documentsIds.includes(d.id as string));
        const {
            updatedDocumentsTabRows,
            updatedSignersTabDataRows
        } = this.mapPendingRequestsAndPermissionsDataToTabs(
            pendingRequests,
            permissionsData,
            selectedDocuments.length,
            this.selectedSigners.getValue(),
            documentsTabData,
            this.signersTabData.getValue()
        );
        this.documentsTabData.next(this.mapDocumentsForDocumentsTab(updatedDocumentsTabRows));
        this.signersTabData.next(updatedSignersTabDataRows);
    }

    getCurrentlySelectedDocuments(): Document[] {
        return this.selectedDocuments.getValue().slice();
    }

    private isPendingTabRowChanged(row: DocumentsPendingSignatureRequestsDataRow, pendingRequest: SignatureRequest): boolean {
        const rowData = row.pendingSignatureRequest;
        return rowData.remindSigner
            || rowData.reason !== pendingRequest.reason
            || rowData.method !== pendingRequest.method
            || !areDatesEqual(rowData.signByDate, pendingRequest.signByDate)
            || !!rowData.notifyRequestorWhenSigned !== pendingRequest.notifyUsersOnSigned.includes(this.currentUserId);
    }

    updatePendingTabData(changes: PendingTabUpdates, ids: string[]): void {
        const initialData = this.pendingRequests.getValue();
        const pendingTabData = this.pendingTabData.getValue().map(
            (row: DocumentsPendingSignatureRequestsDataRow) => {
                if (isPendingTabDataRow(row) && ids.includes(row.pendingSignatureRequest.id)) {
                    if (changes.method && row.signatureTypeOptions.length === 1) {
                        const [method] = row.signatureTypeOptions;
                        changes.method = method;
                    }
                    const initialRequest = initialData.find((d) => d.documentId === row.documentId)
                        .pendingSignatureRequests.find((psr) => psr.id === row.pendingSignatureRequest.id);
                    const updatedRow = {
                        ...row,
                        pendingSignatureRequest: {
                            ...row.pendingSignatureRequest,
                            ...changes
                        }
                    };
                    updatedRow.pendingSignatureRequest.isChanged = this.isPendingTabRowChanged(
                        updatedRow,
                        initialRequest
                    );
                    const { signByDate, isMarkedForCancellation } = updatedRow.pendingSignatureRequest;
                    updatedRow.pendingSignatureRequest.showSignByDateInvalid = !!signByDate && !isMarkedForCancellation
                        && isDateInThePast(signByDate);
                    return updatedRow;
                }
                return row;
            }
        );
        this.pendingTabData.next(pendingTabData);
    }

    undoPendingTabDataChanges(ids: string[]): void {
        const initialData = this.pendingRequests.getValue();
        const pendingTabData = this.pendingTabData.getValue().map(
            (row: DocumentsPendingSignatureRequestsDataRow) => {
                if (isPendingTabDataRow(row) && ids.includes(row.pendingSignatureRequest.id)) {
                    const initialRequest = initialData.find((d) => d.documentId === row.documentId)
                        .pendingSignatureRequests.find((psr) => psr.id === row.pendingSignatureRequest.id);
                    return {
                        ...row,
                        pendingSignatureRequest: {
                            ...row.pendingSignatureRequest,
                            method: initialRequest.method,
                            reason: initialRequest.reason,
                            signByDate: initialRequest.signByDate,
                            remindSigner: false,
                            notifyRequestorWhenSigned: initialRequest.notifyUsersOnSigned.includes(this.currentUserId),
                            isChanged: false,
                            isMarkedForCancellation: false,
                            showSignByDateInvalid: false
                        }
                    };
                }
                return row;
            }
        );
        this.pendingTabData.next(pendingTabData);
    }

    selectSigners(
        updatedSelectedSigners: User[],
        selectionData: FilteredSelectEvent<User>,
        permissionsData: GetNoSignPermissionsResponse
    ): void {
        const { added, removedIds } = selectionData;
        if (!(added.length || removedIds.length)) {
            return;
        }

        let signersTabDataToSave = this.signersTabData.getValue();
        if (removedIds.length) {
            signersTabDataToSave = signersTabDataToSave.filter((s) => !removedIds.includes(s.id));
        }
        if (added.length) {
            signersTabDataToSave = signersTabDataToSave.concat(added);
        }

        const {
            updatedDocumentsTabRows,
            updatedSignersTabDataRows
        } = this.mapPendingRequestsAndPermissionsDataToTabs(
            this.pendingRequests.getValue(),
            permissionsData,
            this.selectedDocuments.getValue().length,
            updatedSelectedSigners,
            this.documentsTabData.getValue(),
            signersTabDataToSave
        );

        this.signersTabData.next(sortBy(updatedSignersTabDataRows, 'name'));
        this.documentsTabData.next(updatedDocumentsTabRows);
        this.selectedSigners.next(updatedSelectedSigners);
        this.noSigningPermissionsData.next(permissionsData);
    }

    getCurrentlySelectedSigners(): User[] {
        return this.selectedSigners.getValue().slice();
    }

    updateSignersData(updates: SignersTabUpdates, signerIds: string[]): void {
        const updatedData = this.signersTabData.getValue()
            .map((s): DocumentsSignersTabDataRow => {
                let updated = s;
                if (signerIds.includes(s.id)) {
                    updated = { ...s, ...updates };
                }
                if ('signByDate' in updates) {
                    updated.showSignByDateInvalid = !!updated.signByDate && isDateInThePast(updated.signByDate);
                }
                return updated;
            });
        this.signersTabData.next(updatedData);
    }

    getNoSigningPermissionData(): GetNoSignPermissionsResponse {
        return this.noSigningPermissionsData.getValue();
    }

    getNoSigningPermissionModalData(): NoSigningPermissionsModalData {
        const documents = this.selectedDocuments.getValue().slice();
        const signersHash: { [key: string]: NoSignUserEntity } = {};
        this.selectedSigners.getValue().forEach((signer) => {
            signersHash[signer.id] = {
                id: signer.id,
                name: signer.name,
                email: signer.email
            };
        });
        return this.noSigningPermissionsData.getValue().map((dataItem) => {
            const documentIndex = documents.findIndex((d) => d.id === dataItem.documentId);
            const document = documents[documentIndex];
            documents.splice(documentIndex, 1);
            return {
                document: {
                    ...document,
                    entityPath: this.calculateDocumentEntityPath(document)
                },
                usersWithoutSignPermissions: dataItem.usersWithoutSignPermissions.map((id) => signersHash[id])
            };
        });
    }

    getBulkSignatureRequestPayload(): SignatureRequestsBulkUpdatePayload {
        const documentsTabData = this.documentsTabData.getValue();
        const signersTabData = this.signersTabData.getValue();
        const pendingTabData = this.pendingTabData.getValue();
        const payload: SignatureRequestsBulkUpdatePayload = {
            comment: '',
            updates: []
        };
        documentsTabData.forEach((d) => {
            if (isDocumentsTabDataRow(d)) {
                const updateItem = {
                    documentId: d.id,
                    version: d.version,
                    add: [],
                    resend: [],
                    cancel: []
                };
                signersTabData.forEach((s) => {
                    if (
                        d.reason
                        && !d.rowDataType
                        && !s.rowDataType
                        && !(s.lacksPermissionsForDocuments && s.lacksPermissionsForDocuments.includes(d.id))
                        && !(s.hasPendingSignatureRequestsOnDocuments && s.hasPendingSignatureRequestsOnDocuments.includes(d.id))
                    ) {
                        const addItem: CreateSignatureRequestParams = {
                            userId: s.id,
                            reason: d.reason,
                            method: d.method || d.signatureTypeOptions[0],
                            skipEmailNotification: s.emailSigner !== CHECKBOX_STATES.SELECTED,
                            notifyRequestorWhenSigned: s.notifyMe === CHECKBOX_STATES.SELECTED,
                            signByDate: s.signByDate || null
                        };
                        updateItem.add.push(addItem);
                    }
                });
                pendingTabData.forEach((row) => {
                    if (isPendingTabDataRow(row) && row.documentId === d.id) {
                        if (row.pendingSignatureRequest.isChanged) {
                            const resendItem: SignatureRequestReminderParams = {
                                id: row.pendingSignatureRequest.id,
                                reason: row.pendingSignatureRequest.reason,
                                method: row.pendingSignatureRequest.method,
                                signByDate: row.pendingSignatureRequest.signByDate,
                                notifyRequestorWhenSigned: row.pendingSignatureRequest.notifyRequestorWhenSigned
                            };
                            updateItem.resend.push(resendItem);
                        }
                        if (row.pendingSignatureRequest.isMarkedForCancellation) {
                            updateItem.cancel.push(row.pendingSignatureRequest.id);
                        }
                    }
                });
                payload.updates.push(updateItem);
            }
        });
        payload.updates = payload.updates.filter((updateItem) => {
            return updateItem.add.length
                || updateItem.resend.length
                || updateItem.cancel.length;
        });
        if (payload.updates.length) {
            return payload;
        }
        return null;
    }

    flushData(): void {
        this.selectedDocuments.next([]);
        this.documentsTabData.next([]);

        this.selectedSigners.next([]);
        this.signersTabData.next([]);

        this.pendingRequests.next([]);
        this.pendingTabData.next([]);
        this.noSigningPermissionsData.next([]);
    }
}
