import { Injectable } from '@angular/core';
import { ModalsService } from './modals.service';

@Injectable({
    providedIn: 'root'
})
export class ModalStackHelperService {
    constructor(private modalService: ModalsService) { }

    dismissAll(): void {
        this.modalService.closeAllModals;
    }
}
