import { Component, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Subject } from 'rxjs';
import {
    tap, takeUntil, filter,
    debounceTime
} from 'rxjs/operators';
import { Crumb, Team } from '../../../../shared/models';
import { CurrentSessionService } from '../../../../core/current-session.service';
import template from './doa-log-template-create.component.html';
import styles from './doa-log-template-create.component.scss';
import { FeatureFlagService } from '../../../../core/feature-flag.service';
import { FEATURE_FLAGS } from '../../../../core/constants/feature-flags';

@Component({
    selector: 'doa-log-template-create',
    template,
    styles: [String(styles)]
})
export class DoaLogTemplateCreateComponent implements OnInit, OnDestroy {
    constructor(
        private currentSessionService: CurrentSessionService,
        private $state: StateService,
        private featureFlagService: FeatureFlagService
    ) { }

    private readonly destroy$ = new Subject<void>();

    crumbs: Crumb[] = [];
    currentTeam: Team;

    ngOnInit(): void {
        this.currentTeam = this.currentSessionService.getCurrentTeam();
        if (!this.currentTeam.settings.features.logTemplates || !this.currentTeam.permissions.viewLogTemplates) {
            this.$state.go('app.select-team');
        }
        this.redirectIfFeatureFlagDisabled();
        this.crumbs = this.getCrumbs();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private redirectIfFeatureFlagDisabled(): void {
        this.featureFlagService.getFlag(FEATURE_FLAGS.DOA_LOG_TEMPLATES, false)
            .pipe(
                filter((isFlagEnabled) => isFlagEnabled !== undefined),
                debounceTime(300),
                takeUntil(this.destroy$)
            ).subscribe((isFlagEnabled) => {
                if (isFlagEnabled === false) {
                    this.$state.go('app.team.log-template-create', { teamId: this.currentTeam.id });
                }
            });
    }

    private getCrumbs(): Crumb[] {
        const crumbs: Crumb[] = [
            {
                name: 'Log Templates',
                stateName: 'app.team.log-templates'
            },
            {
                name: 'DOA Log Templates',
                stateName: 'app.team.log-templates-doa'
            },
            {
                name: 'Create DOA Log Template'
            }
        ];

        return crumbs;
    }
}
