import { Injectable, Inject } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged, tap } from 'rxjs/operators';

import { CurrentSessionService } from '@app/core/current-session.service';
import { SystemNotification, SystemNotificationType } from '@app/shared/models';
import { SystemNotificationsService } from './system-notifications.service';

interface LocalStorageData {
    downtimeBannerDismissed?: boolean;
    dismissedModalNotifications?: string[];
}

@Injectable()
export class SystemNotificationsStoreService {
    private readonly localStorageKey = 'system-notifications';
    private readonly downtimeBanner = new BehaviorSubject<SystemNotification>(null);

    constructor(
        private SystemNotifications: SystemNotificationsService,
        private CurrentSession: CurrentSessionService,
        @Inject('Window') private window: Window
    ) {
        this.init();
    }

    init(): void {
        this.CurrentSession.currentUser$.pipe(
            distinctUntilChanged((previousUser, currentUser) => {
                const previousUserId = previousUser && previousUser.id;
                const currentUserId = currentUser && currentUser.id;
                const userLogout = previousUserId && !currentUserId;

                if (userLogout) {
                    this.clearLocalStorageData();
                }
                return userLogout;
            })
        ).subscribe();
    }

    getDowntimeBannerContent(): Observable<string | null> {
        return this.downtimeBanner.asObservable().pipe(
            map((downtimeBanner: SystemNotification) => {
                if (this.downtimeBannerDismissed()) {
                    return null;
                }
                if (downtimeBanner) {
                    return downtimeBanner.content;
                }
                return null;
            })
        );
    }

    getDisplayableNotifications(userId: string): Observable<SystemNotification[]> {
        return this.SystemNotifications.getSystemNotifications(userId).pipe(
            tap((notifications) => {
                const downtimeBanner = notifications.find((n) => n.type === SystemNotificationType.DOWNTIME_BANNER);
                this.downtimeBanner.next(downtimeBanner);
            }),
            map((notifications) => {
                return notifications
                    .filter((n) => n.type !== SystemNotificationType.DOWNTIME_BANNER && !this.modalNotificationDismissed(n.id))
                    .sort((current, next) => {
                        const currentStartDate = new Date(current.startDate);
                        const nextStartDate = new Date(next.startDate);
                        return currentStartDate > nextStartDate ? 1 : -1;
                    });
            })
        );
    }

    public dismissDowntimeBanner(): void {
        const data = this.getLocalStorageData();
        data.downtimeBannerDismissed = true;
        this.window.localStorage.setItem(this.localStorageKey, JSON.stringify(data));
        this.downtimeBanner.next(null);
    }

    public dismissModalNotification(notificationId: string): void {
        const data = this.getLocalStorageData();
        if (data.dismissedModalNotifications && data.dismissedModalNotifications.length) {
            data.dismissedModalNotifications.push(notificationId);
        }
        else {
            data.dismissedModalNotifications = [notificationId];
        }
        this.window.localStorage.setItem(this.localStorageKey, JSON.stringify(data));
    }

    private modalNotificationDismissed(notificationId: string): boolean {
        const { dismissedModalNotifications } = this.getLocalStorageData();
        return !!dismissedModalNotifications && dismissedModalNotifications.includes(notificationId);
    }

    private downtimeBannerDismissed(): boolean {
        const { downtimeBannerDismissed } = this.getLocalStorageData();
        return !!downtimeBannerDismissed;
    }

    private getLocalStorageData(): LocalStorageData {
        const data = this.window.localStorage.getItem(this.localStorageKey);
        if (!data) {
            return {};
        }
        return JSON.parse(data);
    }

    private clearLocalStorageData(): void {
        this.window.localStorage.removeItem(this.localStorageKey);
    }
}
