import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { Team } from '@app/shared/models';
import template from './deactivation-marker.component.html';
import style from './deactivation-marker.component.scss';

@Component({
    selector: 'team-deactivation-marker',
    template,
    styles: [String(style)]
})
export class TeamDeactivationMarkerComponent {
    @Input() team: Team;
    @Output() learnMoreClicked = new EventEmitter<boolean>();

    clickLearnMore() {
        this.learnMoreClicked.emit(true);
    }
}
