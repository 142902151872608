import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output
} from '@angular/core';

import { Announcement } from '@app/shared/models';
import { PaginationPropertiesService } from '@app/shared/pagination-properties/pagination-properties.service';
import { Pagination, ReturnTo } from './announcements-table.component.types';

import template from './announcements-table.component.html';

@Component({
    selector: 'announcements-table',
    template
})
export class AnnouncementsTableComponent implements OnInit, OnChanges {
    @Input() announcements: Announcement[];
    @Input() loading: boolean;
    @Input() recordCount: number;
    @Input() sortDir: string;
    @Input() sortBy: string;
    @Input() pageNumber: number;
    @Input() returnTo: ReturnTo;
    @Output() onSortOrPageChange = new EventEmitter<Pagination>();

    showBoundaryLinkNumbers: boolean;
    maxSize: number;
    pagination: Pagination;

    constructor(
        private PaginationProperties: PaginationPropertiesService
    ) {
        this.showBoundaryLinkNumbers = PaginationProperties.showBoundaryLinkNumbers();
        this.maxSize = PaginationProperties.getMaxSize();
        this.pagination = {
            pageNum: 1,
            pageSize: 20,
            sortBy: 'createdAt',
            sortDir: 'DESC'
        };
    }

    ngOnInit(): void {
        if (this.sortDir) {
            this.pagination.sortDir = this.sortDir;
        }

        if (this.sortBy) {
            this.pagination.sortBy = this.sortBy;
        }

        if (this.pageNumber) {
            this.pagination.pageNum = this.pageNumber;
        }
    }

    ngOnChanges(): void {
        this.pagination.totalItems = this.recordCount;
    }

    setSort(sortBy: string): void {
        if (this.pagination.sortBy === sortBy) {
            this.pagination.sortDir = this.pagination.sortDir === 'ASC' ? 'DESC' : 'ASC';
        }
        this.pagination.sortBy = sortBy;
        this.handleSortOrPageChange({ page: this.pagination.pageNum });
    }

    handleSortOrPageChange({ page }: { page: number | string }): void {
        this.onSortOrPageChange.emit({ ...this.pagination, pageNum: Number(page) });
    }
}
