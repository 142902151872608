import { Tag } from '@app/shared/models';
import {
    Component,
    EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
    AbstractControl, FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import { REGEX } from '@app/core/constants';
import { TagDeleteEvent } from './delete-tag.component.types';
import template from './delete-tag.component.html';

@Component({
    template
})

export class DeleteTagComponent implements OnInit {
    @Input() tag: Tag;
    @Output() destroyTag = new EventEmitter<TagDeleteEvent>();
    isProcessing: boolean;
    deleteTagForm: FormGroup;

    constructor(
        private fb: FormBuilder,
        private modal: BsModalRef
    ) {
        this.isProcessing = false;
    }

    ngOnInit(): void {
        this.initForm();
    }

    cancel(): void {
        this.modal.hide();
    }

    destroy(): void {
        this.isProcessing = true;
        const tag = this.deleteTagForm.value;
        this.destroyTag.emit({
            tag,
            onSuccess: () => {
                this.modal.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        });
    }

    ctrlHasError(ctrl: AbstractControl): boolean {
        return ctrl.dirty && ctrl.value !== this.tag.name;
    }

    get deleteTagFormCtrls(): { [key: string]: AbstractControl } {
        return this.deleteTagForm.controls;
    }

    private initForm(): void {
        this.deleteTagForm = this.fb.group({
            name: ['', [Validators.required, Validators.pattern(REGEX.tagName), notBlank]],
            reason: ['', [Validators.required, notBlank]]
        });
    }
}
