import { StateProvider } from '@uirouter/angularjs';

const documentsRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.document-show', {
        url: '/documents/:documentId/versions/:version?taskId&contentVersion&openRequestSignatureModal',
        views: {
            '': {
                component: 'documentShow'
            }
        }
    });

    $stateProvider.state('app.team.document-show-most-recent', {
        url: '/documents/:documentId?taskId&contentVersion&signatureRequestId?monitorReviewId',
        views: {
            '': {
                component: 'documentShow'
            }
        }
    });

    $stateProvider.state('app.team.view-manage-qc-review', {
        url: '/documents/:documentId/versions/:version/manage-qc-review',
        views: {
            '': {
                component: 'qcReviewViewManage'
            }
        }
    });
};

documentsRoute.$inject = ['$stateProvider'];

export default documentsRoute;
