import {
    Component, EventEmitter, Output, OnInit, Input
} from '@angular/core';
import {
    FormBuilder, FormGroup, Validators
} from '@angular/forms';

import { notBlank } from '@app/core/form-validators';
import { EntityPathItem } from '@app/shared/documents/calculate-entity-path.util';

import { ReasonEvent } from './reason-modal.component.types';
import template from './reason-modal.component.html';
import styles from './reason-modal.component.scss';

@Component({
    selector: 'reason-modal',
    styles: [String(styles)],
    template
})
export class ReasonModalComponent implements OnInit {
    @Input() header = 'Please provide a reason';
    @Input() reasonLabel = 'Please provide a reason for these changes:';
    @Input() body = '';
    @Input() path?: EntityPathItem[];

    @Output() save = new EventEmitter<ReasonEvent>();
    @Output() dismiss = new EventEmitter<void>();

    form: FormGroup;
    isProcessing = false;

    constructor(
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.initForm();
    }

    dismissModal(): void {
        this.dismiss.emit();
    }

    onSave(): void {
        if (this.isProcessing) {
            return;
        }

        const reason = this.form.controls.reason && this.form.controls.reason.value;

        this.isProcessing = true;
        this.save.emit({
            data: { reason },
            onSuccess: () => {
                this.dismissModal();
            },
            onError: () => {
                this.isProcessing = false;
            }
        });
    }

    private initForm(): void {
        this.form = this.fb.group({
            reason: ['', [
                Validators.required,
                notBlank
            ]]
        });
    }
}
