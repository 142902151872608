import {
    AfterViewInit,
    Component, ElementRef, EventEmitter, Input, OnInit, Output,
    ViewChild
} from '@angular/core';
import {
    FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Tag } from '@app/shared/models';
import { REGEX } from '@app/core/constants';
import { TagCreateEvent } from './create-tag.component.types';
import template from './create-tag.component.html';
@Component({
    selector: 'create-tag',
    template
})
export class CreateTagComponent implements OnInit, AfterViewInit {
    @Input() tag: Tag;
    @Output() onCreate= new EventEmitter<TagCreateEvent>();

    isProcessing: boolean;
    createTagForm: FormGroup;
    @ViewChild('nameInput', { static: false }) nameInput!: ElementRef;
    constructor(
        private fb: FormBuilder,
        private modal: BsModalRef
    ) {
        this.isProcessing = false;
    }

    ngOnInit(): void {
        this.initForm();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.nameInput.nativeElement.focus();
        }, 0);
    }

    cancel(): void {
        this.modal.hide();
    }

    save(): void {
        this.isProcessing = true;
        const tag = {
            name: this.createTagForm.controls.name.value.trim(),
            description: this.createTagForm.controls.description.value,
            includeInReports: true
        };
        this.onCreate.emit({
            tag,
            onSuccess: () => {
                this.modal.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        });
    }

    private initForm(): void {
        this.createTagForm = this.fb.group({
            name: ['', [Validators.required, Validators.pattern(REGEX.tagName), notBlank]],
            description: ['']
        });
    }

}
