import {
    AfterViewInit, ChangeDetectorRef,
    Component, ElementRef, Input, ViewChild
} from '@angular/core';
import template from './ellipsized-text.component.html';

@Component({
    selector: 'ellipsized-text',
    template,
    host: { class: 'u-flex-grow-1 u-d-inline-block u-text-overflow-ellipsis' }
})
export class EllipsizedTextComponent implements AfterViewInit {
    @ViewChild('contentWrapper', { static: false }) contentRef: ElementRef<HTMLSpanElement>;
    @Input() adaptivePosition = false;
    public fullTextTooltip?: string;

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

    ngAfterViewInit() {
        const { offsetWidth, scrollWidth } = this.contentRef.nativeElement;

        if (offsetWidth < scrollWidth) {
            this.fullTextTooltip = this.contentRef.nativeElement.innerText;
            this.changeDetectorRef.detectChanges();
        }
    }
}
