import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalRef, ModalOptions, ComponentConstructor } from './modals.service.types';

export * from './modals.service.types';
@Injectable({
    providedIn: 'root'
})
export class ModalsService {
    private readonly defaultOptions = {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: true,
        class: '',
        animated: false,
        initialState: {}
    };

    constructor(
        private modalService: BsModalService
    ) { }

    show<T>(content: ComponentConstructor<T>, config?: ModalOptions<T>): ModalRef<T> {
        const modalConfig = config || {};
        return this.modalService.show(content, {
            ...this.defaultOptions,
            ...modalConfig
        }) as ModalRef<T>;
    }

    hide(level: number): void {
        document.body?.classList.remove('modal-open');
        return this.modalService.hide(level);
    }

    closeAllModals() {
        const modalCounts = this.modalService.getModalsCount();
        for (let i = 1; i <= modalCounts; i += 1) {
            this.modalService.hide(i);
        }
    }
}
