import { Pipe, PipeTransform } from '@angular/core';
import { sortBy } from './sort-by.util';

@Pipe({
    name: 'sort'
})
export class SortPipe implements PipeTransform {
    transform(array: any, field: string): any[] {

        if (!Array.isArray(array)) {
            return;
        }
        return sortBy(array, field);
    }
}
