const routes = {
    binders: 'app.team.binders',
    selectTeam: 'app.select-team',
    elogs: 'app.team.log-templates-elog',
    doaLogs: 'app.team.log-templates-doa',
    logTemplatesSelector: 'app.team.log-templates',
    logTemplateCreate: 'app.team.log-template-create',
    doaLogTemplateCreate: 'app.team.doa-log-template-create',
    logTemplateView: 'app.team.log-template-view',
    doaLogTemplateView: 'app.team.doa-log-template-view',
    logTemplateViewVersion: 'app.team.log-template-view-version',
    doaLogTemplateViewVersion: 'app.team.doa-log-template-view-version'
};

export default routes;
