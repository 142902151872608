import {
    Component, Input, Output, EventEmitter, OnInit
} from '@angular/core';
import {
    FormBuilder, Validators, AbstractControl, FormControl, FormGroup
} from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import { LogDetailData } from '@app/shared/documents/documents.service.types';
import { Document, LogDetail } from '@app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';

import template from './document-edit-details.component.html';
import { EditDetailsEvent } from './document-edit-details.component.types';
import { LogTemplateType } from '../../../log-templates/components/log-template-type-selector/log-template-type-selector.component.types';

@Component({
    selector: 'document-edit-details',
    template
})
export class DocumentEditDetailsComponent implements OnInit {
    @Input() doc: Document;
    @Output() save = new EventEmitter<EditDetailsEvent>();

    isProcessing = false;
    private currentDetailsHash = {};

    progress = 1;
    steps = { 1: 'Details', 2: 'Reason' };
    orderedDetails: LogDetail[];
    form: FormGroup;
    detailControls: FormControl[] = [];
    logDetailsFormGroup: FormGroup;
    shouldRequireReason = false;
    isDoaLog: boolean;

    constructor(
        private fb: FormBuilder,
        private modalRef: BsModalRef
    ) { }

    ngOnInit(): void {
        this.shouldRequireReason = this.getShouldRequireReason();
        this.orderedDetails = this.orderDetails();
        this.initForm();
        this.isDoaLog = this.doc.documentProperties?.templateType === LogTemplateType.DOA;
    }

    initForm(): void {
        this.logDetailsFormGroup = this.fb.group({});

        const reasonValidators = [notBlank];
        if (this.shouldRequireReason) {
            reasonValidators.push(Validators.required);
        }
        this.form = this.fb.group({
            reason: ['', reasonValidators],
            logDetails: this.logDetailsFormGroup
        });

        this.orderedDetails.forEach(({ name, value, immutable }) => {
            const control = this.fb.control(value, [Validators.maxLength(250)]);
            this.detailControls.push(control);
            this.logDetailsFormGroup.addControl(name, control);
            this.currentDetailsHash[name] = { value, immutable };
        });
    }

    ctrlInvalid(ctrl: AbstractControl): boolean {
        return ctrl.invalid && (ctrl.dirty || ctrl.touched);
    }

    setProgress(progress: string | number): void {
        this.progress = Number(progress);
    }

    dismissModal(): void {
        this.modalRef.hide();
    }

    saveFormOrIncrementProgress(): void {
        if (this.progress === 1 && this.shouldRequireReason) {
            return this.incrementProgress(1);
        }

        this.saveForm();
    }

    incrementProgress(direction: 1 | -1): void {
        this.setProgress(this.progress + direction);
    }

    private saveForm(): void {
        if (this.isProcessing) {
            return;
        }

        this.isProcessing = true;

        const logDetails = this.getChangedDetails();

        if (!this.detailsChanged(logDetails)) {
            this.dismissModal();
            return;
        }

        const reason = this.form.controls.reason.value || undefined;

        this.save.emit({
            data: { logDetails, reason },
            onSuccess: () => this.dismissModal(),
            onError: () => {
                this.isProcessing = false;
                this.dismissModal();
            }
        });
    }

    submitDisabled(): boolean {
        return this.isProcessing
            || (this.progress === 1 && (this.form.pristine || this.logDetailsFormGroup.invalid))
            || (this.progress === 2 && this.form.invalid);
    }

    private orderDetails(): LogDetail[] {
        return this.doc.logDetails.slice().sort((a, b) => {
            if (a.immutable && !b.immutable) {
                return -1;
            }

            if (!a.immutable && b.immutable) {
                return 1;
            }

            return 0;
        });
    }

    private getChangedDetails(): LogDetailData[] {
        const formValues = this.logDetailsFormGroup.value;

        return Object.keys(formValues).reduce((acc, name) => {
            const value = formValues[name];
            if (!this.currentDetailsHash[name].immutable) {
                acc.push({ name, value });
            }
            return acc;
        }, []);
    }

    private detailsChanged(changedDetails: LogDetail[]): boolean {
        return changedDetails.some(({ name, value }) => {
            const orderedDetail = this.orderedDetails.find((detail) => detail.name === name);
            return orderedDetail && orderedDetail.value !== value;
        });
    }

    private getShouldRequireReason(): boolean {
        const atLeastOneRegularDetailHasValue = this.doc.logDetails
            .filter(({ immutable }) => !immutable)
            .some(({ value }) => !!value);
        return this.doc.version > 1 || atLeastOneRegularDetailHasValue;
    }
}
