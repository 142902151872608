import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';

@Component({
    selector: 'upload-link',
    template: `
        <a class="test-uploadLink"
            (keydown.enter)="openUpload()"
            tabindex="0">
            <label for="upload-link-input">
                <ng-content></ng-content>
            </label>
        </a>
        <input id="upload-link-input"
               class="u-d-none"
               type="file"
               #fileInput
               [attr.multiple]="multiple ? true : null"
               (change)="onSelect($event)"/>
    `
})
export class UploadLinkComponent {
    @Input() multiple = false;
    @Output() upload = new EventEmitter<FileList>();
    @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

    openUpload(): void {
        this.fileInput.nativeElement.click();
    }

    onSelect(event: Event): void {
        const target = event.target as HTMLInputElement;
        this.upload.emit(target.files);
        target.value = null;
    }
}
