import {
    Component, Input
} from '@angular/core';
import { EntityPathItem } from '@app/shared/documents/calculate-entity-path.util';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './review-findings-concluded.component.html';
import styles from './review-findings-concluded.component.scss';

@Component({
    selector: 'review-findings-qc-review',
    template,
    styles: [String(styles)]

})
export class ReviewFindingsConcludedComponent {
    @Input() path: EntityPathItem[];
    @Input() operation: string

    constructor(
        public modal: BsModalRef
    ) {}

    onOk(): void {
        this.modal.hide();
    }

}
