import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { Connection } from '@app/components/document-sharing/services/document-sharing.types';
import { Binder, Document } from '@app/shared/models';

import { BinderNameResolverService } from '../../../../../binders/services/binder-name-resolver-service';
import template from './share-documents-summary.component.html';
import styles from './share-documents-summary.component.scss';

@Component({
    selector: 'share-documents-summary',
    template,
    styles: [String(styles)],
    providers: [BinderNameResolverService]
})
export class ShareDocumentsSummaryComponent implements OnInit, OnChanges {
    activeTabIndex: number;
    isInputField: boolean;
    connectionsWithDifferentDataCenters: string[] = [];
    differentDataCenterTooltip = '<span><b>Attention: </b>This team is in a different<br> data center from your team</span>';

    @Input() exchangeName: string;
    @Input() documents: Document[];
    @Input() connections: Connection[];
    @Input() binders: Binder[];

    @Output() removeDocument = new EventEmitter<Document>();
    @Output() removeConnections = new EventEmitter<Connection[]>();
    @Output() updateExchangeNameEvent = new EventEmitter<string>();

    constructor(
        private binderNameResolverService: BinderNameResolverService
    ) { }

    ngOnInit() {
        this.isInputField = false;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.binders) {
            this.binderNameResolverService.cacheBinders(this.binders);
        }
        if (changes.exchangeName) {
            this.exchangeName = changes.exchangeName.currentValue;
        }
        if (changes.connections) {
            this.connectionsWithDifferentDataCenters = changes.connections.currentValue
                .filter((connection) => connection.configs[0]?.region !== connection.configs[1]?.region)
                .map((connection) => connection._id);
        }
    }

    onRemoveDocument(document: Document): void {
        this.removeDocument.emit(document);
    }

    onRemoveConnections(connection: Connection): void {
        this.removeConnections.emit([connection]);
    }

    getInfoTooltipText(document: Document) {
        const binderName = this.binderNameResolverService.getBinderName(document.binderId);
        return document.path ? `${binderName}/${document.path}` : binderName;
    }

    checkIfDataCenterIsDifferent(id: string) {
        return this.connectionsWithDifferentDataCenters.includes(id);
    }

    /*  WARNING: Uncomment this part of the code when set/edit exchangeName is available again

    sendExchangeNameEvent(event) {
        this.updateExchangeNameEvent.emit(event.target.value);
    }

    toggleInputField(): void {
        this.isInputField = !this.isInputField;
    }} */
}
