import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { NeedHelpComponent } from './containers/need-help/need-help.component';
import { ModalsModule } from '../../widgets/modals/modals.module';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
    imports: [
        ModalsModule,
        CommonModule,
        SharedModule,
        UIRouterModule
    ],
    providers: [
    ],
    declarations: [
        NeedHelpComponent
    ],
    entryComponents: [
        NeedHelpComponent
    ],
    exports: [
    ]
})
export class NeedHelpModule {}
