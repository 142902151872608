import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter,
    Input, OnDestroy, OnInit, Output, QueryList, ViewChildren
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import {
    catchError, debounceTime, switchMap, takeUntil, tap
} from 'rxjs/operators';
import template from './doa-log-template-details-form.component.html';
import { LogTemplateDetail, LogTemplateDetailType } from '../../../../../shared/models';
import { MESSAGES, REGEX } from '../../../../../core/constants';
import { DOA_LOG_TEMPLATE_FORM_CONTROLS } from '../doa-log-template.types';
import { notBlank } from '../../../../../core/form-validators';
import { LogTemplatesService } from '../../../../../shared/log-templates/log-templates.service';
import { CurrentSessionService } from '../../../../../core/current-session.service';
import { NotificationsService } from '../../../../../core/notifications/notifications.service';


@Component({
    selector: 'doa-log-template-details-form',
    template,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoaLogTemplateDetailsFormComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    constructor(
        private controlContainer: ControlContainer,
        private formBuilder: FormBuilder,
        private logTemplatesService: LogTemplatesService,
        private notificationsService: NotificationsService,
        private currentSessionService: CurrentSessionService,
        private changeDetectorRef: ChangeDetectorRef
    ) { }

    @Input() name?: string;
    @Input() details?: LogTemplateDetail[];
    @Input() canEdit = true;
    @Input() templateId?: string;
    @Output() emitIsFetchingTemplateNameUnique = new EventEmitter<boolean>();

    teamId: string

    @ViewChildren('detailControlRef') detailControlRef: QueryList<ElementRef>;

    readonly requiredDetails: LogTemplateDetail[] = [
        { name: 'Unique Protocol Number', type: LogTemplateDetailType.TEXT },
        { name: 'Study Name', type: LogTemplateDetailType.TEXT },
        { name: 'Principal Investigator', type: LogTemplateDetailType.TEXT }
    ];

    readonly optionalDetails: LogTemplateDetail[] = [
        { name: 'Sponsor', type: LogTemplateDetailType.TEXT },
        { name: 'IRB Number', type: LogTemplateDetailType.TEXT },
        { name: 'Site Number', type: LogTemplateDetailType.TEXT }
    ];

    readonly nameFormControlName = DOA_LOG_TEMPLATE_FORM_CONTROLS.NAME;
    readonly detailsFormControlName = DOA_LOG_TEMPLATE_FORM_CONTROLS.DETAILS;
    readonly validationErrBlankMessage = MESSAGES.invalidBlankMessage;
    readonly validationErrShortMessage = MESSAGES.validShortNameMessage;
    readonly validationErrTemplateNameUnique = MESSAGES.nonUniqueValue('template', 'name');

    nameFormControl: FormControl;
    detailsFormArray: FormArray;

    get parentForm() {
        return <FormGroup> this.controlContainer.control;
    }

    get defaultDetails(): LogTemplateDetail[] {
        return this.requiredDetails.concat(this.optionalDetails);
    }

    ngOnInit(): void {
        this.initFormControls();
        this.teamId = this.currentSessionService.getCurrentTeam().id;

        this.nameFormControl.valueChanges.pipe(
            tap(() => this.emitIsFetchingTemplateNameUnique.emit(true)),
            debounceTime(300),
            switchMap(() => this.templateNameUniqueValidator$()),
            tap((isUnique) => {
                if (isUnique === false) {
                    this.nameFormControl.setErrors({ notUnique: true });
                    this.changeDetectorRef.detectChanges();
                }
                this.emitIsFetchingTemplateNameUnique.emit(false);
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    isRequiredDetail(detailName: string): boolean {
        return this.requiredDetails.some((detail) => detail.name.toLowerCase() === detailName.toLowerCase());
    }

    getFormControlErrorMessage(
        formControl: AbstractControl
    ): { message: string } {
        let message = '';

        switch (true) {
            case formControl.errors?.required:
            case formControl.errors?.blank:
                message = this.validationErrBlankMessage;
                break;
            case !!formControl.errors?.pattern:
                message = this.validationErrShortMessage;
                break;
            case formControl.errors?.notUnique:
                message = this.validationErrTemplateNameUnique;
                break;
            default:
                break;
        }

        return { message };
    }

    removeFormArrayItem(formArray: FormArray, index: number): void {
        formArray.removeAt(index);
        this.parentForm.markAsDirty();
    }

    onAddDetail(): void {
        const emptyDetailFormGroup = this.generateDetailFormGroup();

        this.detailsFormArray.push(emptyDetailFormGroup);

        const lastDetailElement = <HTMLElement> this.detailControlRef.last.nativeElement;
        lastDetailElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
    }

    private initFormControls(): void {
        const initialDetailsFormArray = this.getInitialDetailsFormArrayValue();

        this.nameFormControl = this.formBuilder.control({
            value: this.name,
            disabled: !this.canEdit
        }, [
            Validators.required,
            Validators.pattern(REGEX.names),
            notBlank,
            Validators.maxLength(50)
        ]);

        this.detailsFormArray = this.formBuilder.array(
            initialDetailsFormArray,
            [Validators.required]
        );

        this.parentForm.addControl(
            this.nameFormControlName,
            this.nameFormControl
        );

        this.parentForm.addControl(
            this.detailsFormControlName,
            this.detailsFormArray
        );
    }

    private getInitialDetailsFormArrayValue(): FormGroup[] {
        let initialDetailsFormArrayValue: FormGroup[];

        if (this.details?.length) {
            // if details @Input() was provided, use it as the starting value
            initialDetailsFormArrayValue = this.details.map(
                (detail) => this.generateDetailFormGroup(detail)
            );
        }
        else {
            // else use the default details
            initialDetailsFormArrayValue = this.generateDefaultDetailsFormGroups();
        }

        return initialDetailsFormArrayValue;
    }

    private generateDefaultDetailsFormGroups(): FormGroup[] {
        return this.defaultDetails.map((detail) => {
            return this.generateDetailFormGroup(detail);
        });
    }

    private generateDetailFormGroup(
        detail: LogTemplateDetail = this.emptyLogDetail
    ): FormGroup {
        const detailFormGroup = this.formBuilder.group({
            name: this.formBuilder.control({
                value: detail.name,
                disabled: !this.canEdit
            }, [
                Validators.required,
                Validators.pattern(REGEX.names),
                notBlank,
                Validators.maxLength(50)
            ]),
            type: this.formBuilder.control(detail.type, [
                Validators.required
            ])
        });

        if (this.isRequiredDetail(detail.name)) {
            detailFormGroup.controls.name.disable();
        }

        return detailFormGroup;
    }

    private get emptyLogDetail() {
        return {
            name: '',
            type: LogTemplateDetailType.TEXT
        };
    }

    templateNameUniqueValidator$(): Observable<boolean> | null {
        const { value } = this.nameFormControl;
        if (!value) {
            return of(null);
        }

        return this.logTemplatesService.isLogTemplateNameUnique$(this.teamId, value, this.templateId).pipe(
            catchError(() => {
                this.emitIsFetchingTemplateNameUnique.emit(false);
                this.notificationsService.error('Error checking if template name is unique.');
                return of(false);
            })
        );
    }
}
