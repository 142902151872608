import {
    Component,
    Input,
    OnChanges
} from '@angular/core';
import * as moment from 'moment';
import {
    Team
} from '@app/shared/models';

@Component({
    selector: 'date-time',
    template: `
    <span *ngIf="!linebreak; else twoLines">
        {{ formattedDate }}
    </span>
    <ng-template #twoLines>
        {{ formattedDate }}<br>{{ formattedTime }}
    </ng-template>
    `
})
export class DateTimeComponent implements OnChanges {
    @Input() date?: Date | string | moment.Moment;
    @Input() timezone?: string;
    @Input() dateOnly?= false;
    @Input() linebreak?= false;
    @Input() displayProperty?: string;
    formattedDate: string;
    formattedTime?: string;

    ngOnChanges(): void {
        const timezone = moment().tz(this.timezone || moment.tz.guess()).format('z');
        if (this.displayProperty && this.displayProperty === 'expirationDate') {
            const dateFormat = this.dateOnly ? 'DD-MMM-YYYY' : 'DD-MMM-YYYY @';
            const timezoneDifference = `${Number(moment().tz(this.timezone).format('Z').slice(0, 3)) - Number(moment(this.date).format('Z').slice(0, 3))}:00`;
            this.formattedDate = moment(this.date).add(timezoneDifference).format(dateFormat);
            this.formattedTime = `${moment(this.date).add(timezoneDifference).format('LT')} ${moment().tz(this.timezone).format('z')}`;
            return;
        }
        if (this.displayProperty && this.displayProperty === 'dueDate') {
            const dateFormat = this.dateOnly ? 'DD-MMM-YYYY' : 'DD-MMM-YYYY @';
            const timezoneDifference = `${Number(moment().tz(this.timezone).format('Z').slice(0, 3)) - Number(moment(this.date).format('Z').slice(0, 3))}:00`;
            this.formattedDate = moment(this.date).add(timezoneDifference).format(dateFormat);
            this.formattedTime = `${moment(this.date).add(timezoneDifference).format('LT')} ${moment().tz(this.timezone).format('z')}`;
            return;
        }
        if (this.linebreak) {
            this.formattedDate = moment(this.date).format('DD-MMM-YYYY @');
            this.formattedTime = `${moment(this.date).format('LT')} ${timezone}`;
            return;
        }
        if (this.dateOnly) {
            this.formattedDate = this.timezone
                ? moment.tz(this.date, this.timezone).format('DD-MMM-YYYY')
                : moment(this.date).format('DD-MMM-YYYY');
        }
        else {
            this.formattedDate = `${moment(this.date).format('DD-MMM-YYYY @ LT')} ${timezone}`;
        }
    }
}
