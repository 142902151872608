import { Inject, Injectable } from '@angular/core';

@Injectable()
export class PaginationPropertiesService {

    constructor(@Inject('Window') private window: Window) {}

    getMaxSize(): number {
        const widthRatio = this._getWidthRatio();
        return Math.min(widthRatio, 5);
    }

    showBoundaryLinkNumbers(): boolean {
        const widthRatio = this._getWidthRatio();
        return widthRatio > 5;
    }

    private _getWidthRatio(): number {
        return Math.floor(this.window.innerWidth / 100);
    }
}
