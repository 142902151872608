import * as _ from 'lodash';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Injectable } from '@angular/core';
import { throwError, of, Observable } from 'rxjs';
import { Team } from '@app/shared/models';

const dashboardAccessError = 'Dashboards are a premium feature. Please contact customer support to upgrade.';
const roleTemplateManagerAccessError = 'Role template manager is a premium feature. Please contact customer support to upgrade.';

@Injectable()
export class AppFeaturesService {

    constructor(private currentSession: CurrentSessionService) {}

    assertDashboardEnabledForCurrentTeam(): Observable<boolean> {
        const currentTeam: Team = this.currentSession.getCurrentTeam();
        if (!_.get(currentTeam, 'settings.features.dashboard', false)) {
            return throwError(dashboardAccessError);
        }
        return of(true);
    }

    assertRoleTemplateManagerEnabledForCurrentTeam(): Observable<boolean> {
        const currentTeam: Team = this.currentSession.getCurrentTeam();
        if (!_.get(currentTeam, 'settings.features.roleTemplates', false)) {
            return throwError(roleTemplateManagerAccessError);
        }
        return of(true);
    }
}
