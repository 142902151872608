
import { Ng2StateDeclaration } from '@uirouter/angular';
import { DownloadsIndexComponent } from './containers/downloads-index/downloads-index.component';

export const downloadsRoutes: Ng2StateDeclaration[] = [
    {
        name: 'app.team.downloads',
        url: '/downloads',
        component: DownloadsIndexComponent,
        data: { requiresAuth: true }
    }
];
