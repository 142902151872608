import { StateService } from '@uirouter/core';

import { Component, Input, OnInit } from '@angular/core';
import {
    AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { CHECKBOX_STATES } from '@app/core/constants';
import { DocumentLogEntriesService } from '@app/shared/documents-log-entries/document-log-entries.service';
import {
    Column, Document, LogEntry, LogEntryTypes, Signature, SigningReasons, User
} from '@app/shared/models';
import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';
import { UpdateLogEntrySignatureRequestsPayload } from '@app/shared/documents-log-entries/document-log-entries.service.types';
import { isDateInThePast } from '@app/shared/date-time/is-date-in-the-past.util';
import { LogEntryBulkRequestSignatureTableDataRow, SignerOptions, SignersFormControls } from './log-entry-bulk-signature-requests.types';

import template from './log-entry-bulk-signature-requests.component.html';
import styles from './log-entry-bulk-signature-requests.component.scss';

@Component({
    selector: 'log-entry-bulk-signature-requests',
    template,
    styles: [String(styles)]
})

export class LogEntryBulkSignatureRequestsComponent implements OnInit {
    @Input() logDocument: Document;
    @Input() logEntries: LogEntry[];

    readonly progressSteps = { 1: 'Choose Signer(s)', 2: 'Request Signatures' };
    readonly commentMaxLength = 2000;
    progress = 1;
    loadingPotentialSigners = false;
    signersFormControls = SignersFormControls;
    potentialSigners: User[] = [];
    initiallySelectedSigners: User[] = [];
    signatureColumns: Column[];
    hasTeamMemberColumn: boolean;
    signersForm: FormGroup = new FormGroup({
        signatureColumn: new FormControl(null, [Validators.required]),
        signerOption: new FormControl('', [Validators.required]),
        signer: new FormControl(null, [
            this.conditionalValidator(
                () => {
                    return this.signersForm.get(SignersFormControls.SIGNER_OPTION).value === SignerOptions.SINGLE;
                },
                Validators.required
            )
        ])
    });

    isSubmitting = false;
    tableData: LogEntryBulkRequestSignatureTableDataRow[];
    isSingleSigner: boolean;
    isTableDataValid: boolean;
    comment: string;

    constructor(
        private $state: StateService,
        private modalRef: BsModalRef,
        private DocumentLogEntries: DocumentLogEntriesService
    ) { }

    ngOnInit(): void {
        this.loadPotentialSigners().pipe(take(1)).subscribe();
        this.signatureColumns = this.getSignatureColumns();
        this.hasTeamMemberColumn = this.getHasTeamMemberColumn();

        if (!this.hasTeamMemberColumn) {
            this.signersForm.patchValue({ signerOption: SignerOptions.SINGLE }, { emitEvent: false });
        }
    }

    hideModal(): void {
        this.modalRef.hide();
    }

    setProgress(n: number): void {
        this.progress = Number(n);
    }

    incrementProgress(n: number): void {
        this.setProgress(Number(this.progress) + n);

        if (this.progress === 2) {
            this.generateTableData();
            this.updateTableDataValidity();
        }
    }

    onSignersFilterChange($event: string): void {
        this.loadPotentialSigners($event).pipe(take(1)).subscribe();
    }

    onSelectSigners($event: FilteredSelectEvent<User>): void {
        this.initiallySelectedSigners = $event.added;
        this.signersForm.patchValue({ signer: $event.added[0] });
    }

    onSelectionCleared(): void {
        this.initiallySelectedSigners = [];
        this.signersForm.patchValue({ signer: null });
    }

    onSignByDateSelected({ date, rowData }: {
        date: Date;
        rowData: LogEntryBulkRequestSignatureTableDataRow;
    }): void {
        rowData.signByDate = date;
        this.updateTableDataValidity();
    }

    onSignatureReasonSelected({ reason, rowData }: {
        reason: SigningReasons;
        rowData: LogEntryBulkRequestSignatureTableDataRow;
    }): void {
        rowData.reason = reason;
        this.updateTableDataValidity();
    }

    onNotifyMeUpdated(rowData: LogEntryBulkRequestSignatureTableDataRow): void {
        rowData.notifyMe = !rowData.notifyMe;
    }

    onEmailSignerUpdated(rowData: LogEntryBulkRequestSignatureTableDataRow): void {
        rowData.emailSigner = !rowData.emailSigner;
    }

    onRowRemoved(rowData: LogEntryBulkRequestSignatureTableDataRow): void {
        this.tableData = this.tableData.filter(
            (row) => row.id.logEntryId !== rowData.id.logEntryId
        );
        this.updateTableDataValidity();
    }

    requestSignatures(): void {
        const payload = this.getUpdateLogEntrySignatureRequestsPayload();
        if (!payload) {
            this.hideModal();
            return;
        }
        this.isSubmitting = true;
        payload.comment = this.comment;

        this.DocumentLogEntries.updateSignatureRequests(payload).pipe(
            take(1),
            tap(() => {
                this.isSubmitting = false;
                this.$state.go(this.$state.current, { openRequestSignatureModal: false }, { reload: true });
                this.hideModal();
            }),
            catchError(() => {
                this.hideModal();
                return of([]);
            })
        ).subscribe();
    }

    private getUpdateLogEntrySignatureRequestsPayload(): UpdateLogEntrySignatureRequestsPayload | null {
        const payload: UpdateLogEntrySignatureRequestsPayload = {
            comment: '',
            updates: [],
            isLogEntriesBulkSignatureRequest: true
        };
        const updateItem = {
            documentId: this.logDocument.id as string,
            version: this.logDocument.version,
            add: [],
            resend: [],
            cancel: []
        };
        this.tableData
            .forEach((row) => {
                if (row.columns[0] && row.columns[0].name && row.reason) {
                    const addItem = {
                        userId: row.signer.id,
                        reason: row.reason,
                        method: 'Log',
                        skipEmailNotification: !row.emailSigner,
                        notifyRequestorWhenSigned: row.notifyMe,
                        signByDate: row.signByDate || null,
                        columnName: this.getSignatureColumn(row.columns).name,
                        entryId: row.id.logEntryId
                    };
                    updateItem.add.push(addItem);
                }
            });

        if (
            updateItem.add.length
            || updateItem.cancel.length
            || updateItem.resend.length
        ) {
            payload.updates.push(updateItem);
            return payload;
        }

        return null;
    }

    private generateTableData(): void {
        const signatureColumn = this.signersForm.get(this.signersFormControls.SIGNATURE_COLUMN).value;
        this.tableData = this.logEntries
            .map((logEntry) => {
                const signer = this.getSigner(logEntry.columns);
                return {
                    ...logEntry,
                    signatureColumn,
                    signer,
                    signByDate: null,
                    reason: null,
                    notifyMe: false,
                    emailSigner: false,
                    checked: CHECKBOX_STATES.NOT_SELECTED,
                    signatureColumnHasPendingSignatures: this.getSignatureColumnHasPendingSignatures(logEntry),
                    otherColumHasPendingSignatureForUser: this.getOtherColumHasPendingSignatureForUser(logEntry, signer),
                    signatureColumnHasSignatures: this.getSignatureColumnHasSignatures(logEntry)
                };
            })
            .sort((a, b) => a.rowNumber - b.rowNumber);
    }

    private getSignatureColumnHasPendingSignatures(logEntry: LogEntry): boolean {
        const signatureColumn = this.getSignatureColumn(logEntry.columns);
        return signatureColumn && !!signatureColumn.signatureRequest;
    }

    private getOtherColumHasPendingSignatureForUser(logEntry: LogEntry, user: User): boolean {
        const col = logEntry.columns.find((column) => {
            return column.pendingSignatureUserId === user.id;
        });
        return !!col;
    }

    private getSignatureColumnHasSignatures(logEntry: LogEntry): boolean {
        const signatureColumn = this.getSignatureColumn(logEntry.columns);
        return signatureColumn.value && (signatureColumn.value as Signature).status === 'Signed';
    }

    private getSignatureColumn(logEntryColumns: Column[]): Column {
        const selectedSignatureColumn: Column = this.signersForm.get(this.signersFormControls.SIGNATURE_COLUMN).value;
        return logEntryColumns.find((column) => column.name === selectedSignatureColumn.name);
    }

    private getSigner(logEntryColumns: Column[]): User {
        const signer = this.signersForm.get(this.signersFormControls.SIGNER).value;
        if (signer) {
            this.isSingleSigner = true;
            return signer;
        }

        const teamMemberColumn = logEntryColumns.find((column) => column.type === LogEntryTypes.teamMember);
        this.isSingleSigner = false;
        return teamMemberColumn.userInfo;
    }

    private updateTableDataValidity(): void {
        const validTableData = this.tableData
            .filter((row) => !row.signatureColumnHasPendingSignatures
                && !row.signatureColumnHasSignatures
                && !row.otherColumHasPendingSignatureForUser);
        this.isTableDataValid = validTableData.length
                                && validTableData.every(
                                    (row) => row.reason && (row.signByDate ? !isDateInThePast(row.signByDate) : true)
                                );
    }

    private getSignatureColumns(): Column[] {
        return this.logEntries[0].columns.filter((column) => column.type === LogEntryTypes.signature);
    }

    private getHasTeamMemberColumn(): boolean {
        return this.logEntries[0].columns.some((column) => column.type === LogEntryTypes.teamMember);
    }

    private loadPotentialSigners(filterValue?: string): Observable<User[]> {
        this.loadingPotentialSigners = true;

        return this.DocumentLogEntries.getPotentialSigners({
            documentId: this.logDocument.id as string,
            filter: filterValue
        }).pipe(
            tap((data) => {
                this.potentialSigners = data;
                this.loadingPotentialSigners = false;
            }),
            catchError(() => {
                return of([]);
            })
        );
    }

    private conditionalValidator(predicate: () => boolean, validator: ValidatorFn): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return control.parent && predicate() ? validator(control) : null;
        };
    }
}
