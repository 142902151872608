import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { Project, Timeline } from '@app/shared/models';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { notBlank } from '@app/core/form-validators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import template from './duplicate-timeline-modal.component.html';
import { DuplicateTimelineEvent } from './duplicate-timeline-modal.types';

@Component({
    template
})
export class DuplicateTimelineComponent implements OnInit {
    @Input() timeline: Timeline;
    @Input() allProjects: Project[];
    @Output() onDuplicate = new EventEmitter<DuplicateTimelineEvent>();
    isProcessing: boolean;
    selectedProject: Project;
    duplicateForm: FormGroup;

    constructor(
        private modal: BsModalRef,
        private fb: FormBuilder
    ) {
        this.isProcessing = false;
    }

    ngOnInit(): void {
        this.isProcessing = false;
        this.timeline.name += ' copy';
        this.selectedProject = _.find(this.allProjects, { id: this.timeline.projectId });
        this.initForm();
    }

    handleKeyPress(event: KeyboardEvent): void {
        if (event.key === 'Enter' && this.duplicateForm.valid) {
            this.save();
        }
    }

    initForm(): void {
        this.duplicateForm = this.fb.group({
            name: [this.timeline.name, [Validators.required, notBlank]],
            project: [this.selectedProject, Validators.required]
        });
    }

    cancel(): void {
        this.modal.hide();
    }

    save(): void {
        this.isProcessing = true;
        this.timeline.name = this.duplicateForm.value.name;
        this.timeline.projectId = this.duplicateForm.value.project.id;
        const onSaveEvent = {
            timeline: this.timeline,
            onSuccess: () => {
                this.modal.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        };
        this.onDuplicate.emit(onSaveEvent);
    }
}
