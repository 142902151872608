import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit
} from '@angular/core';
// @todo - look into including this from a cdn
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { TIME_ZONES } from './timezone-picker.list';

import template from './timezone-picker.component.html';

@Component({
    selector: 'timezone-picker',
    template
})
export class TimezonePickerComponent implements OnInit {
    @Input() value: string;
    @Output() onChange = new EventEmitter<string>();
    options = [];
    continentsOrder = ['America', 'Europe', 'Asia', 'Australia', 'Africa', 'Indian', 'Pacific', 'Atlantic', 'Antarctica', 'Arctic'];

    ngOnInit(): void {
        const timeZonesSorted = _.sortBy(
            TIME_ZONES.map((timeZone) => {
                const dateTime = moment().tz(timeZone.value);
                const utcOffset = dateTime.format('Z');
                const numericOffset = parseInt(utcOffset.replace(':', ''), 10);
                return {
                    value: timeZone.value,
                    label: `${dateTime.format('hh:mm a')} (UTC ${utcOffset}) ${dateTime.format('MMM Do')} - ${timeZone.label}`,
                    numericOffset
                };
            }), ['numericOffset', 'value']
        );

        const hasSelectedValue = timeZonesSorted.filter((timeZone) => timeZone.value === this.value).length > 0;
        if (!hasSelectedValue) {
            this.handleChange(null);
        }

        const timeZonesGroupedContinents = timeZonesSorted.reduce((previous, current) => {
            const currentContinent = current.value.split('/')[0];
            const currentGroup = previous.filter((option) => option.name === currentContinent);

            if (currentGroup.length > 0) {
                currentGroup[0].items.push(current);
            }
            else {
                previous.push({
                    name: currentContinent,
                    items: [current]
                });
            }

            return previous;
        }, []);

        this.options = [];
        this.continentsOrder.forEach((continent) => {
            const currentContinent = timeZonesGroupedContinents.filter((group) => group.name === continent);
            if (currentContinent.length === 1) {
                this.options.push(currentContinent[0]);
            }
        });
    }

    handleChange(change): void {
        this.onChange.emit(change);
    }
}
