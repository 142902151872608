import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalsModule } from '@app/widgets/modals/modals.module';
import { SigningPinExpiryNotificationComponent } from './containers/signing-pin-expiry-notification/signing-pin-expiry-notification.component';

@NgModule({
    imports: [
        CommonModule,
        ModalsModule
    ],
    providers: [
    ],
    declarations: [
        SigningPinExpiryNotificationComponent
    ],
    entryComponents: [
        SigningPinExpiryNotificationComponent
    ],
    exports: [
    ]
})
export class SigningPinPolicyModule {}
