'use strict';

module.exports = [
    'DD-MMM-YYYY',
    'DD-MM-YYYY',
    'DD-M-YYYY',
    'D-MMM-YYYY',
    'D-MM-YYYY',
    'D-M-YYYY',
    'DD/MMM/YYYY',
    'DD/MM/YYYY',
    'DD/M/YYYY',
    'D/MMM/YYYY',
    'D/MM/YYYY',
    'D/M/YYYY'
];
