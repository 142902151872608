import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Binder } from '@app/shared/models';
import { FoldersService } from '@app/shared/folders/folders.service';
import { BindersService } from '@app/shared/binders/binders.service';
import {
    TeamConfiguration,
    StudyStartupPayload,
    GetJobStatuses,
    CreateStudy,
    ListJobs
} from './study-manager.service.types';
import { Pagination } from './containers/job-status-table/job-status-table.types';

@Injectable()
export class StudyManagerService {
    private readonly url = {
        teamConfig: (teamId: string): string => `/api/teams/${teamId}/studies-startup/team-configuration`,
        createStudy: (teamId: string): string => `/api/teams/${teamId}/studies-startup`,
        getJobStatuses: (teamId: string): string => `/api/teams/${teamId}/studies-startup`,
        listJobs: (teamId: string): string => `/api/teams/${teamId}/jobs`
    };

    constructor(
        private $http: HttpClient,
        private Folders: FoldersService,
        private Binders: BindersService
    ) { }

    listJobs(teamId: string, uniqueProtocolId: string): Observable<ListJobs> {
        const url = this.url.listJobs(teamId);
        const query = {
            studyId: uniqueProtocolId,
            status: 'COMPLETED',
            workflowType: 'StudyStartupPOST'
        };

        let httpParams = new HttpParams();
        Object.entries(query).forEach(
            ([key, value]) => {
                if (value) {
                    httpParams = httpParams.set(key, value);
                }
            }
        );

        return this.$http.get<ListJobs>(url, { params: httpParams });
    }

    getTeamConfig(teamId: string): Observable<TeamConfiguration> {
        const url = this.url.teamConfig(teamId);

        return this.$http.get<TeamConfiguration>(url);
    }

    upsertStudyStartup(teamId: string, payload: Partial<StudyStartupPayload>): Observable<CreateStudy> {
        const url = this.url.createStudy(teamId);

        return this.$http.post<CreateStudy>(url, payload);
    }

    getJobStatuses(teamId: string, paginationParams: Pagination): Observable<GetJobStatuses> {
        const url = this.url.getJobStatuses(teamId);
        const params = paginationParams && Object.keys(paginationParams)
            .reduce((httpParam, param) => {
                return httpParam.set(param, paginationParams[param]);
            }, new HttpParams());
        return this.$http.get<GetJobStatuses>(url, { params });
    }

    async getFolderByPath(teamId: string, binderId: string, path: string[]): Promise<any> {
        const objectType: 'binder' | 'folder' = 'binder';
        if (path.length === 0) {
            throw new Error('Path cannot be empty');
        }

        let folderToBeFound;
        const browseTree = await this.Folders.browse({
            teamId,
            binderId,
            folderId: undefined,
            includeDocs: false,
            includeDecorations: false,
            includeArchived: false,
            objectId: binderId,
            objectType
        }).toPromise();

        if (path.length === 1) {
            return browseTree;
        }
        if (path.length === 2) {
            folderToBeFound = browseTree.items.find((child) => child.name === path[1]);
            if (!folderToBeFound) {
                throw new Error(`Folder ${path[1]} not found`);
            }
            return folderToBeFound;
        }
        const folderName = path.pop();

        const pathToFolderDir = path.slice(1);

        const finalFolderDir = pathToFolderDir.reduce(async (previousPromise, element) => {
            const prevResult = await previousPromise;
            if (!prevResult) {
                throw new Error(`Folder ${element} not found`);
            }
            const folderDir = prevResult.items.find((child) => child.name === element);
            if (!folderDir) {
                throw new Error(`Folder ${element} not found`);
            }
            return this.Folders.browse({
                teamId,
                binderId,
                folderId: folderDir.id,
                includeDocs: false,
                includeDecorations: false,
                includeArchived: false,
                objectId: folderDir.id,
                objectType
            }).toPromise();
        }, Promise.resolve(browseTree));


        folderToBeFound = (await finalFolderDir).items.find((child) => child.name === folderName);
        if (!folderToBeFound) {
            throw new Error(`Folder ${folderName} not found`);
        }
        return folderToBeFound;
    }

    getBinderByName(teamId: string, binderName: string): Promise<Binder> {
        return this.Binders.getBinders(teamId).toPromise().then((binders) => {
            return binders.find((binder) => binder.name === binderName);
        });
    }
}
