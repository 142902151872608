import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { LabeledEntity } from '@app/shared/models';
import * as _ from 'lodash';
import { SORT } from '@app/core/constants';
import template from './labels-assign.html';
import styles from './labels-assign.scss';
import { StudyLabel, StudyLabelValue } from './labels-assign.types';

@Component({
    selector: 'labels-assign',
    template,
    styles: [String(styles)]
})
export class LabelsAssignComponent implements OnInit, OnChanges {
    availableLabels: StudyLabel[] = [];
    uniqueAssignedLabels: LabeledEntity[] = [];
    valuesToAssign: StudyLabelValue[] = [];
    selectedLabel: StudyLabel = null;
    hiddenValueText = '[empty]';
    searchForValue: string;
    studyValueIds: string[] = [];
    @Input() entityId: string;
    @Input() assignedLabels: LabeledEntity[];
    @Input() availableTeamLabels: StudyLabel[];
    @Output() onAssignLabels = new EventEmitter<Partial<LabeledEntity>[]>();
    @Output() onRemoveLabel = new EventEmitter<LabeledEntity>();

    SORT = SORT;

    ngOnInit(): void {
        const UPIDlabel = this.availableLabels.find((label) => label.name === 'Unique Protocol ID');
        if (UPIDlabel) {
            this.studyValueIds = UPIDlabel.values.filter((val) => val.studyId).map((value) => value.id);
        }
        this.availableLabels = this.availableLabels.sort(this.SORT.naturalSort);

        this.assignedLabels.forEach((assignedLabel) => {
            const { labelId } = assignedLabel;
            this.availableLabels.filter((label) => label.id === labelId);
            if (this.studyValueIds.includes(assignedLabel.valueId)) {
                assignedLabel.isStudyValue = true;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.assignedLabels) {
            this.uniqueAssignedLabels = _.uniqBy(
                this.assignedLabels,
                (labeled) => labeled.labelId + labeled.valueId + (labeled.objectId !== this.entityId)
            );
        }

        if (changes.availableTeamLabels) {
            this.availableLabels = this.availableTeamLabels.filter(this.filterHiddenPredefined.bind(this));
        }
    }

    filterHiddenPredefined(label: StudyLabel): boolean {
        return !(label.isPredefined && label.values.length === 1 && label.values[0].value === this.hiddenValueText);
    }

    onLabelSelection(label: StudyLabel): void {
        this.valuesToAssign = [];
        this.searchForValue = '';
        const takenValuesHash = this.uniqueAssignedLabels.reduce((hash, assignedLabel) => {

            if (assignedLabel.labelId === label.id && assignedLabel.objectId === this.entityId) {
                hash[assignedLabel.valueId] = true;
            }
            return hash;
        }, {});
        label.selectableValues = label.values.filter(({ id }) => !takenValuesHash[id]);
        this.selectedLabel = label;
        this.selectedLabel.selectableValues = this.selectedLabel.selectableValues.sort(this.SORT.naturalSort);
    }

    onValueSelection(value: StudyLabelValue): void {
        if (value.isSelected) {
            // value.name is required for the humanized-entity-list to work
            value.name = value.value;
            this.valuesToAssign.push(value);
            return;
        }
        _.pullAllBy(this.valuesToAssign, [value], 'id');
    }

    assignSelectedValues(): void {
        const data = this.selectedLabel.selectableValues.reduce((accum: Partial<LabeledEntity>[], value) => {
            if (value.isSelected) {
                accum.push({
                    labelId: this.selectedLabel.id,
                    labelName: this.selectedLabel.name,
                    valueId: value.id,
                    value: value.value
                });
                delete value.isSelected;
            }
            return accum;
        }, []);
        this.onAssignLabels.emit(data);
        this.selectedLabel = null;
        this.valuesToAssign = [];
    }

    removeAssignedValue(labeled: LabeledEntity): void {
        this.onRemoveLabel.emit(labeled);
    }
}
