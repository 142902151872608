import {
    CloneInstruction,
    CloneDestination,
    User,
    Signature,
    Document,
    SigningReasons,
    SignatureTypes,
    SignatureRequest,
    DocumentId,
    AuditTrailEventType,
    ApiErrorResponse,
    DocumentProperties
} from '../models';

export interface DocumentApprovalRejectResponse {
    updatedDocumentProperty: DocumentProperties;
}

export interface DocumentUpdateError {
    error: {
        statusCode: number;
        message: string;
    };
    document: Document;
}

export interface DocumentMoveResponse {
    documents: Document[];
    errors: DocumentUpdateError[];
    renamedShortcuts: Array<Document[]>;
}

export interface LogDocumentCreateResponse {
    document: Document;
    message?: string;
}

export type ShowDocumentSavedNotificationParams = {
    signature?: {
        status: string;
    };
    annotations?: Array<{
        type: string;
    }>;
    formFields?: Array<{
        type: string;
    }>;
    shouldFinalize?: boolean;
};

export interface DocumentUploadResponse {
    document: Document;
    href: string;
    success: boolean;
}

export interface LogDetailSelection {
    name: string;
    selectedOption: string;
}

export interface LogColumnSelection {
    name: string;
    selectedHeader: string;
}

export type AnnotationSavedParams = {
    documentId: string | DocumentId;
    version: number;
    annotations: any[];
    email: string;
    reason: string;
    password: string;
    signingPasscode: string;
    jobTitle: string
};

export type DocumentFormSavedParams = AnnotationSavedParams & {
    formFields: any[];
    shouldFinalize: boolean;

};

export type FillPlaceholderLogParams = {
    id: string | DocumentId;
    filename: string,
    logTemplateId: string,
    detailSelections: LogDetailSelection[];
    columnSelections: LogColumnSelection[];
}

export type DocumentActionResponse = {
    document: Document;
}

export interface GetSignatureStatusResponse {
    signatures: Signature[];
    signatureRequests: object[];
    potentialSigners: User;
}

export interface NavParamsInput {
    doc: Document;
    version?: number;
    reload?: boolean;
}

export interface NavParams {
    teamId: string;
    documentId: string;
    version: number;
    contentVersion?: number | null;
}

export type CategoryErrors = {
    document: Document;
    error: {
        error: string;
        message: string;
        statusCode: number;
    }
}


export type DocumentUpdateResponse = {
    documents: Document[];
    errors: CategoryErrors[];
    removedDocuments?: Document[];
    renamedShortcuts?: Document[][];
}

export interface UpdateNameResponse {
    document: Document,
    renamedShortcuts: Document[]
}

export type UpdateDocumentCategoriesParams = {
    categoryId: string;
    statusId: string;
}

export interface DestroyDocuments {
    documents: Document[];
    errors: DocumentUpdateError[];
}

export interface SetPiiResponse {
    documents: Document[];
    errors: DocumentUpdateError[];
    renamedShortcuts: Array<Document[]>; // couldn't reproduce the type for thr rename shortcuts.
}

export interface DocumentsCloneBatchParams {
    destination: CloneDestination;
    documentIds: string[];
}

export interface DocumentCustomCloneParams {
    destination: CloneDestination;
    cloneInstructions: CloneInstruction[];
}

export interface DocumentClone extends Document {
    oldId: {
        documentId: string;
        version: number;
    };
}

export interface SendToSipParams {
    studyId: string;
    siteId: string;
    docId: string;
    version: number;
}

export interface GetPotentialSignersParams {
    teamId: string;
    documentIds: string[];
    filter?: string;
}

export interface GetNoSignPermissionsParams {
    teamId: string;
    documentIds: string[];
    userIds: string[];
}

export type GetNoSignPermissionsResponse = Array<{
    documentId: string;
    usersWithoutSignPermissions: string[];
}>

export type GetDocumentsPendingSignatureRequestsResponse = Array<{
    documentId: string;
    pendingSignatureRequests: SignatureRequest[];
}>

export interface CreateSignatureRequestParams {
    userId: string;
    reason: SigningReasons;
    method: SignatureTypes;
    skipEmailNotification: boolean;
    notifyRequestorWhenSigned: boolean;
    signByDate: Date;
    entryId?: string;
    columnName?: string;
}

export interface SignatureRequestReminderParams {
    id: string;
    reason: SigningReasons;
    method: SignatureTypes;
    signByDate: Date | string;
    notifyRequestorWhenSigned: boolean;
}

export interface SignatureRequestsBulkUpdatePayload {
    comment: string;
    updates: {
        documentId: string;
        version: number;
        contentVersion?: number;
        add?: CreateSignatureRequestParams[];
        resend?: SignatureRequestReminderParams[];
        cancel?: string[];
    }[];
}

export type SignatureRequestsBulkUpdateResponse = Array<{
    statusCode: number;
    payload: {
        createdRequests: SignatureRequest[];
        updatedRequests: SignatureRequest[];
        cancelledRequestCount: number;
    };
}>

export type LogMetadataType = 'legend' | 'information';

export interface LogDetailData {
    name: string;
    value: string;
}

export interface LogMetadata {
    logDetails?: LogDetailData[];
    legend?: string;
    information?: string;
    comment?: string;
    reason?: string;
}

export interface CreateLogParams {
    teamId: string;
    binderId: string;
    folderId: string;
    filename: string;
    logTemplateId: string;
    detailSelections: LogDetailSelection[];
    columnSelections: LogColumnSelection[];
}

export interface UpdatedDocumentDates {
    dueDate?: Date | null;
    expirationDate?: Date | null;
}

export interface GetJobTitleRequiredResponse {
    jobTitleRequired: boolean;
}

export interface UpdateDocumentIsLockedItem {
    id: string | DocumentId;
    isLocked: boolean;
}

type DocEventName = AuditTrailEventType.DOCUMENT_DUE_DATE_SET | AuditTrailEventType.DOCUMENT_EXP_DATE_SET;


export interface BulkUpdateDueAndExpirationDatesResponseItem {
    statusCode: number;
    document: {
        doc: Document;
        originalDoc: Document | null;
        finalDoc: Document | null;
        docEventName: DocEventName
    };
    error: ApiErrorResponse | undefined;
}

export enum DocumentPlaceholderFillModes {
    MOVE = 'move',
    CLONE = 'clone',
    SHORTCUT = 'shortcut',
    NEW_LOG = 'newlog'
}

export interface PlaceholderFillParams {
    id: string,
    originalDocumentId: string,
    name: string,
    fillMode: DocumentPlaceholderFillModes
}
