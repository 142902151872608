import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

import template from './page-progress.component.html';
import styles from './page-progress.component.scss';

@Component({
    selector: 'page-progress',
    template,
    styles: [String(styles)]
})
export class PageProgressComponent {
    inlineStyles = { maxWidth: '60%' };
    @Input() steps: { [key: number]: string };
    @Input() progress: number;
    @Output() setProgress = new EventEmitter<number | string>();


    updateProgress(n, event): void {
        event.preventDefault();
        // only allow navigation to previous states
        if (this.progress <= n) {
            return;
        }
        this.setProgress.emit(n);
    }
}
