import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'daysRemaining'
})
export class DaysRemainingPipe implements PipeTransform {
    transform(dateInTheFuture: Date): number {
        return Math.ceil((
            new Date(dateInTheFuture).getTime()
            - new Date().getTime()
        ) / (1000 * 3600 * 24));
    }
}
