import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { LoginAlertsService } from './login-alerts.service';

const dependencies = [];

const loginAlertsModule = angular
    .module('app.shared.loginAlerts', dependencies)
    .factory('LoginAlerts', downgradeInjectable(LoginAlertsService))
    .run(['LoginAlerts', (): void => {
        return undefined;
    }]);

export default loginAlertsModule;
