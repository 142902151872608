import { StateProvider } from '@uirouter/angularjs';

const manageTagsRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.manage-tags', {
        url: '/manage-tags',
        views: {
            '': 'manageTags'
        }
    });
};

manageTagsRoute.$inject = ['$stateProvider'];

export default manageTagsRoute;
