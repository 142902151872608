import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Entity } from '@app/shared/models';

@Injectable()
export class EntityTypeService {
    get(entity: Entity): string {
        let typeName;
        if (entity.type === 'document') {
            if (entity.formStatus === 'form-in-progress') {
                typeName = 'Fillable Form';
            }
            else if (entity.subType === 'content') {
                typeName = 'document';
            }
            else {
                typeName = entity.subType;
            }
        }
        else {
            typeName = entity.type || '';
        }

        return _.startCase(_.camelCase(typeName));
    }
}
