import { PermissionTree } from './permission-tree.model';

export interface AuditTrail {
    addedValues?: any[];
    category: AuditTrailCategory;
    createdAt: string;
    createdBy: string;
    email: string;
    eventType: AuditTrailEventType;
    finalState: any;
    isDeclinedSignature?: boolean;
    isSignature?: boolean;
    label: string;
    added: any;
    updatedValues?: any[];
    originalState: any;
    permissionDiff?: PermissionDiff;
    roleAccessDiff?: RoleAccessDiff;
    reason: string;
    removedValues: any[];
    sourceSystem: string;
    timestamp: string;
    updatedAt: string;
    documentFailedToSendUserInformation?: AuditTrailUserInfo;
    user: AuditTrailUserInfo;
    uniqueObjectId: {
        binderId: string;
        teamId: string;
        folderId: string;
        version: number;
        objectId: string;
        objectType: string;
        friendlyName: string;
        objectIdPath: string[];
        longFriendlyName?: string;
    };
    who: Omit<AuditTrailUserInfo, 'id'>;
    itemDiff?: any;
    itemDiffFrom?: any;
    invited?: {
        email: string;
        name?: string;
    };
    removed?: any;
}

export enum AuditTrailEventType {
    BINDER_LABELS_UPDATED = 'Binder Labels Updated',
    BINDER_LABELS_UPDATED_VIA_STUDY_PROFILE = 'Binder Labels Updated via Study Profiles',
    FOLDER_LABELS_UPDATED_VIA_STUDY_PROFILE = 'Folder Labels Updated via Study Profiles',
    BINDER_RENAMED = 'Binder Renamed',
    DOCUMENT_CLONE_FROM_FOLDER = 'Document Clone from Folder',
    DOCUMENT_CLONED_FROM_FOLDER = 'Document Cloned from Folder',
    DOCUMENT_CREATED = 'Document Created',
    DOCUMENT_CREATED_IN_FOLDER = 'Document Created in Folder',
    DOCUMENT_DELETED_FROM_FOLDER = 'Document Deleted from Folder',
    DOCUMENT_DUPLICATED = 'Document Duplicated',
    DOCUMENT_FAILED_TO_SEND_TO_INTEGRATED_SYSTEM = 'Document Failed to Send to Integrated System',
    DOCUMENT_MOVED_FROM_FOLDER = 'Document Moved from Folder',
    DOCUMENT_SIGNATURE_REQUEST_REMINDER = 'Document Signature Request Reminder',
    DOCUMENT_SIGNATURE_REQUEST_REMOVED = 'Document Signature Request Removed',
    DOCUMENT_SIGNATURE_REQUESTED = 'Document Signature Requested',
    DOCUMENT_SUCCESSFULLY_SENT_TO_INTEGRATED_SYSTEM = 'Document Successfully Sent to Integrated System',
    DOCUMENT_SIGNATURE_REQUEST_REMIND = 'Document Signature Request Reminder',
    DOCUMENT_SIGNATURE_REQUEST_DELETED = 'Document Signature Request Removed',
    DOCUMENT_SIGNED = 'Document Signed',
    DOCUMENT_DECLINED = 'Signature Request Declined',
    DOCUMENT_PLACED_SIGNATURE = 'Signature Placed on Document',
    DOCUMENT_REDACTED = 'Document Redacted',
    DOCUMENT_HIGHLIGHTED = 'Document Highlighted',
    DOCUMENT_NOTATED = 'Text Added to Document',
    DOCUMENT_STAMPED = 'Document Stamped',
    DOCUMENT_FORM_UPDATED = 'Form Updated',
    DOCUMENT_FORM_FINALIZED = 'Form Finalized',
    REVISION_REQUEST = 'Revision Requested',
    DOCUMENT_REPLACED = 'Document Replaced',
    DOCUMENT_MOVED = 'Document Moved',
    DOCUMENT_CLONED = 'Document Duplicated',
    DOCUMENT_BULK_CLONED = 'Document Bulk Duplicated',
    DOCUMENT_RENAMED = 'Document Renamed',
    DOCUMENT_VIEWED = 'Document Viewed',
    DOCUMENT_RECEIVED = 'Document Received',
    DOCUMENT_ACCEPTED = 'Document Accepted',
    DOCUMENT_DOWNLOADED = 'Document Downloaded',
    DOCUMENT_DUE_DATE_SET = 'Document Due Date Set',
    DOCUMENT_EXP_DATE_SET = 'Document Expiration Date Set',
    DOCUMENT_DATE_SET = 'Document Date Set',
    EMAIL_CONNECTOR_PROCESSED_INBOUND_MESSAGE = 'Processed Incoming Message Via Email Connector',
    DOCUMENT_APPROVE_REJECT_STARTED = 'Document Approve/Reject Started',
    DOCUMENT_REJECTED = 'Document Rejected',
    DOCUMENT_CANCELLED = 'Document Cancelled',
    DOCUMENT_APPROVED = 'Document Approved',
    DOCUMENT_FORM_CHECK_COMPLETED = 'Document Form Check Completed',
    DOCUMENT_TAGS_UPDATED = 'Document Tags Updated',
    DOCUMENT_CATEGORY_UPDATED = 'Document Type Updated',
    CHILD_DOCUMENT_PATH_UPDATED = 'Child Document Path Updated',
    CHILD_DOCUMENT_MOVED = 'Child Document Moved',
    DOCUMENT_MARKED_AS_PHI = 'Document Marked as PHI',
    DOCUMENT_UNMARKED_AS_PHI = 'Document Unmarked as PHI',
    FOLDER_DELETED_FROM_FOLDER = 'Folder Deleted from Folder',
    FOLDER_DUPLICATED = 'Folder Duplicated',
    FOLDER_LABELS_UPDATED = 'Folder Labels Updated',
    FOLDER_MOVED = 'Folder Moved',
    FOLDER_RENAMED = 'Folder Renamed',
    LABEL_CREATED = 'Label Created',
    LABEL_CREATED_VIA_STUDY_PROFILE = 'Label Created via Study Profile',
    LABEL_DELETED = 'Label Deleted',
    LABEL_UPDATED = 'Label Updated',
    PLACEHOLDER_FILLED = 'Placeholder Filled',
    PLACEHOLDER_FILLED_VIA_EMAIL = 'Placeholder Filled Via Email',
    PLACEHOLDER_FILLED_VIA_PRINTER = 'Placeholder Filled Via Printer',
    PLACEHOLDER_DELETED = 'Placeholder Deleted',
    PLACEHOLDER_CREATED = 'Placeholder Created',
    PLACEHOLDER_MOVED = 'Placeholder Moved',
    PLACEHOLDER_CLONED = 'Placeholder Duplicated',
    PLACEHOLDER_BULK_CLONED = 'Placeholder Bulk Duplicated',
    PLACEHOLDER_RENAMED = 'Placeholder Renamed',
    PLACEHOLDER_VIEWED = 'Placeholder Viewed',
    PLACEHOLDER_DOWNLOADED = 'Placeholder Downloaded',
    PLACEHOLDER_DUE_DATE_SET = 'Placeholder Due Date Set',
    PLACEHOLDER_TAGS_UPDATED = 'Placeholder Tags Updated',
    CHILD_PLACEHOLDER_PATH_UPDATED = 'Child Placeholder Path Updated',
    CHILD_PLACEHOLDER_MOVED = 'Child Placeholder Moved',
    SHORTCUT_DELETED = 'Shortcut Deleted',
    SHORTCUT_CREATED = 'Shortcut Created',
    SHORTCUT_CREATED_FROM = 'Shortcut Created',
    SHORTCUT_SIGNED = 'Shortcut Signed',
    SHORTCUT_DECLINED = 'Signature Request Declined',
    SHORTCUT_MOVED = 'Shortcut Moved',
    SHORTCUT_RENAMED = 'Shortcut Renamed',
    SHORTCUT_VIEWED = 'Shortcut Viewed',
    SHORTCUT_DOWNLOADED = 'Shortcut Downloaded',
    SHORTCUT_DUE_DATE_SET = 'Shortcut Due Date Set',
    SHORTCUT_EXP_DATE_SET = 'Shortcut Expiration Date Set',
    SHORTCUT_ORIGINAL_DOC_DELETED = 'Shortcut Original Document Deleted',
    SHORTCUT_BROKEN_PLACEHOLDER_FILLED = 'Shortcut Broken, Placeholder Filled by Creating New Log',
    SHORTCUT_ORIGINAL_DOC_DUE_DATE_SET = 'Shortcut Original Document Due Date Set',
    SHORTCUT_ORIGINAL_DOC_EXP_DATE_SET = 'Shortcut Original Document Exp Date Set',
    SHORTCUT_ORIGINAL_PLACEHOLDER_FILLED = 'Shortcut Original Placeholder Filled',
    DOCUMENT_IMPORTED_VIA_DOC_SHARING = 'Document Imported via Document Sharing',
    DOCUMENT_SUCCESSFULLY_SHARED_VIA_DOC_SHARING = 'Document Successfully Shared via Document Sharing',
    DOCUMENT_FAILED_FOR_SHARING_VIA_DOC_SHARING = 'Document Failed for Sharing via Document Sharing',
    SHORTCUT_TAGS_UPDATED = 'Shortcut Tags Updated',
    CHILD_SHORTCUT_PATH_UPDATED = 'Child Shortcut Path Updated',
    CHILD_SHORTCUT_MOVED = 'Child Shortcut Moved',
    LOG_DELETED = 'Log Deleted',
    LOG_CREATED = 'Log Created',
    LOG_MOVED = 'Log Moved',
    LOG_RENAMED = 'Log Renamed',
    LOG_VIEWED = 'Log Viewed',
    LOG_DOWNLOADED = 'Log Downloaded',
    LOG_EXP_DATE_SET = 'Log Expiration Date Set',
    LOG_TAGS_UPDATED = 'Log Tags Updated',
    LOG_SIGNATURE_REQUESTED = 'Log Signature Requested',
    LOG_SIGNATURE_REQUEST_REMIND = 'Log Signature Request Reminder',
    LOG_SIGNATURE_REQUEST_DELETED = 'Log Signature Request Removed',
    LOG_SIGNED = 'Log Signed',
    LOG_DECLINED = 'Signature Request Declined',
    CHILD_LOG_PATH_UPDATED = 'Child Log Path Updated',
    CHILD_LOG_MOVED = 'Child Log Moved',
    LOG_DETAILS_UPDATED = 'Log Details Updated',
    LOG_NEW_VERSION_CREATED = 'New Log Version Created',
    LOG_ENTRY_CREATED = 'Log Entry Created',
    LOG_ENTRY_UPDATED = 'Log Entry Updated',
    LOG_ENTRY_SIGNATURE_REQUESTED = 'Log Entry Signature Requested',
    LOG_ENTRY_REQUEST_REMIND = 'Log Entry Signature Request Reminder',
    LOG_ENTRY_REQUEST_DELETED = 'Log Entry Signature Request Removed',
    LOG_ENTRY_SIGNED = 'Log Entry Signed',
    LOG_ENTRY_DECLINED = 'Log Entry Signature Request Declined',
    ROLE_CREATED = 'Role Created',
    ROLE_DELETED = 'Role Removed',
    ROLE_PERMISSIONS_UPDATED = 'Role Permissions Updated',
    USER_ALIAS_UPDATED = 'User Email Connector Alias Updated',
    USER_PROFILE_INFORMATION_CHANGED = 'Profile Information Changed',
    USER_NAME_CHANGED ='User Name Changed',
    USER_PERMISSIONS_UPDATED = 'User Permissions Updated',
    ALL_USER_PERMISSIONS_REMOVED = 'All User Permissions Removed',
    ROLE_CLONED = 'Role Duplicated',
    ROLE_RENAMED = 'Role Renamed',
    ROLE_ACCESS_UPDATED = 'Role Access Updated',
    BLINDED_ROLE_ACCESS_UPDATED = 'Blinded Role Access Updated',
    UNBLINDED_ROLE_ACCESS_UPDATED = 'Unblinded Role Access Updated',
    LOG_TEMPLATE_CREATED = 'Template Created',
    LOG_TEMPLATE_CREATED_VIA_SITELINK = 'Template Created via SiteLink\u2122',
    LOG_TEMPLATE_UPDATED = 'Template Updated',
    LOG_TEMPLATE_MARKED_AS_ACTIVE = 'Template Moved To Active',
    LOG_TEMPLATE_MARKED_AS_INACTIVE = 'Template Moved To Inactive',
    STUDY_CREATED = 'Study Created',
    STUDY_CREATED_VIA_WORKFLOW_API = 'Study Created via Workflow API',
    STUDY_UPDATED = 'Study Updated',
    STUDY_UPDATED_VIA_WORKFLOW_API = 'Study Updated via Workflow API',
    STUDY_BINDERS_AND_FOLDERS_UPDATED = 'Study Binders & Folders Updated',
    STUDY_PAYWALLS_UPDATED = 'Study Monitor Groups Updated',
    SITE_CREATED = 'Site Created',
    SITE_CREATED_VIA_WORKFLOW_API = 'Site Created via Workflow API',
    SITE_UPDATED = 'Site Updated',
    SITE_UPDATED_VIA_WORKFLOW_API = 'Site Updated via Workflow API',
    SITE_BINDERS_AND_FOLDERS_UPDATED = 'Site Binders & Folders Updated',
    SITE_BINDERS_AND_FOLDERS_UPDATED_VIA_WORKFLOW_API = 'Site Binders & Folders Updated via Workflow API',
    SUB_FOLDER_CLONED_FROM_FOLDER = 'Sub folder Cloned from Folder',
    SUB_FOLDER_CLONED_TO_FOLDER = 'Sub folder Cloned to Folder',
    SUB_FOLDER_CREATED_IN_FOLDER = 'Sub folder Created in Folder',
    SUB_FOLDER_DELETED_FROM_FOLDER = 'Sub folder Deleted from Folder',
    SUB_FOLDER_MOVED_FROM_FOLDER = 'Sub folder Moved from Folder',
    SUB_FOLDER_MOVED_TO_FOLDER = 'Sub folder Moved to Folder',
    TEAM_DEFAULT_PRINTER_BINDER_CHANGED = 'Team Default Printer Binder Changed',
    TEAM_FORM_FIELD_TEXT_CHANGED = 'Team Form Field Text Changed',
    TEAM_PASSWORD_POLICY_CHANGED = 'Team Password Policy Changed',
    TEAM_RENAMED = 'Team Renamed',
    TEAM_SIGNATURE_POLICY_CHANGED = 'Team Signature Policy Changed',
    TEAM_SIGNING_PIN_POLICY_CHANGED = 'Team Signing PIN Policy Changed',
    TEAM_TIMEZONE_CHANGED = 'Team Timezone Changed',
    TEAM_AUTOMATIC_BINDER_OWNER_SETTING_CHANGED = 'Team Automatic Binder Owner Setting Changed',
    TEAM_AUTOMATIC_DOCUMENT_OWNER_SETTING_CHANGED = 'Team Automatic Document Owner Setting Changed',
    TEAM_DEACTIVATION_SCHEDULED = 'Team Deactivation Scheduled',
    TEAM_DEACTIVATED = 'Team Deactivated',
    TEAM_REACTIVATED = 'Team Reactivated',
    TEAM_DEACTIVATION_CANCELED = 'Team Deactivation Canceled',
    TEAM_UPDATED = 'Team Updated',
    TAG_CREATED = 'Tag Created',
    TEAMMATE_ADDED = 'Teammate Added',
    TEAMMATE_REMOVED = 'Teammate Removed',
    TEAMMATES_ADDED_TO_ROLE = 'Teammate(s) Added to Role',
    TEAMMATES_REMOVED_FROM_ROLE = 'Teammate(s) Removed from Role',
    QC_REVIEW_CREATED = 'QC Review Created',
    QC_REVIEW_ACCEPTED = 'QC Review Started',
    QC_REVIEW_DECLINED = 'QC Review Declined',
    QC_REVIEW_APPROVED = 'QC Review Approved',
    QC_REVIEW_REJECTED = 'QC Review Rejected',
    QC_REVIEW_ADDED = 'QC Review Added',
    QC_REVIEW_DELETED = 'QC Review Deleted',
    QC_REVIEW_CYCLE_REMOVED = 'QC Review Cycle Removed',
    QC_REVIEWER_CHANGED = 'QC Reviewer Changed',
    TEXT_ANNOTATION_ADDED_TO_DOCUMENT = 'Text Annotation Added to a Document',
    TIMESTAMP_ANNOTATION_ADDED_TO_DOCUMENT = 'Timestamp Annotation Added to a Document'
}

export enum AuditTrailCategory {
    LOG_TEMPLATES = 'log_templates',
    TEAM_PROFILE = 'team_profile',
    TEAM_MEMBERS = 'team_members',
    FOLDER_STRUCTURE = 'folder_structure',
    USERS = 'users',
    TAGS = 'tags',
    ANNOUNCEMENTS = 'announcements',
    INTERNALS = 'internals',
    UNCATEGORIZED = 'uncategorized',
    USER_PROFILE = 'user_profile',
}

export enum AuditTrailSubject {
    BINDER = 'binder',
    DOCUMENT = 'document',
    FOLDER = 'folder',
    USERS = 'users',
    ROLE = 'role',
    TAG = 'tags',
    LOG_TEMPLATES = 'log_templates',
    LOG_ENTRIES = 'log_entries',
    TEAM_PROFILE = 'team_profile',
    TEAM_MEMBERS = 'team_members',
    USER_PROFILE = 'user_profile',
    ANNOUNCEMENTS = 'announcements',
    LOG_TEMPLATE = 'log_template',
    LOG_ENTRY = 'log_entry',
    STUDY_STRUCTURE_TEMPLATES = 'study_structure_templates',
    STUDY_PROFILES = 'study_profiles'
}

export interface PermissionDiff {
    permissions: {
        tree: PermissionTree;
    };
    type: 'role' | 'user';
}


export interface RoleAccessDiff {
    after: RoleAccessState;
    before: RoleAccessState;
    beforeNull?: string;
}

export interface RoleAccessState {
    active: 'Active' | 'Inactive';
    start: string;
    end: string;
}

interface AuditTrailUserInfo {
    id: string;
    name: string;
    email: string;
}
