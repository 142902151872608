import { MonitorReview, Paywall, User } from '@app/shared/models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
    ApproveParams,
    ApproveResponse,
    GetEventsForReviewParams,
    GetEventsForReviewResponse,
    StudyLabel
} from './document-monitor-review.service.types';

@Injectable()
export class DocumentMonitorReviewService {
    readonly url = {
        monitorReviews: (documentId: string): string => `/api/documents/${documentId}/monitor-reviews`,
        enabled: (documentId: string): string => `/api/documents/${documentId}/monitor-reviews/enabled`,
        update: (documentId: string, monitorReviewId: string): string => `/api/documents/${documentId}/monitor-reviews/${monitorReviewId}`,
        events: (documentId: string, monitorReviewId: string): string => `/api/documents/${documentId}/monitor-reviews/${monitorReviewId}/events`,
        comments: (documentId: string, monitorReviewId: string): string => `/api/documents/${documentId}/monitor-reviews/${monitorReviewId}/comments`,
        approve: (documentId: string): string => `/api/documents/${documentId}/monitor-reviews/approvals`,
        reviewed: (documentId: string): string => `/api/documents/${documentId}/monitor-reviews/review`,
        documentLabels: (teamId: string, documentId: string): string => `/api/teams/${teamId}/documents/${documentId}/labels`,
        studyLabel: (documentId: string): string => `/api/documents/${documentId}/monitor-reviews/study-label`,
        paywalls: (teamId: string): string => `/api/teams/${teamId}/user-paywalls`,
        potentialRecipients: (documentId: string): string => `/api/documents/${documentId}/monitor-reviews/potential-recipients`
    };

    constructor(
        private $http: HttpClient
    ) {}

    createMonitorReview(documentId: string, params: MonitorReview): Observable<MonitorReview> {
        return this.$http.post<MonitorReview>(this.url.monitorReviews(documentId), params);
    }

    updateStatus(documentId: string, monitorReviewId: string,
        status: string, recipientIds: string[], comment: string): Observable<MonitorReview> {
        return this.$http.patch<MonitorReview>(this.url.update(documentId, monitorReviewId), { status, recipientIds, comment });
    }

    approve(documentId: string, params: ApproveParams): Observable<ApproveResponse> {
        return this.$http.post<ApproveResponse>(this.url.approve(documentId), params);
    }

    reviewed(documentId: string, params: ApproveParams): Observable<ApproveResponse> {
        return this.$http.post<ApproveResponse>(this.url.reviewed(documentId), params);
    }

    comment(documentId: string, monitorReviewId: string,
        recipientIds: [], text: string, notifyRecipients = false): Observable<string> {
        return this.$http.post<string>(this.url.comments(documentId, monitorReviewId), { text, recipientIds, notifyRecipients });
    }

    getReviewsForDoc(documentId: string): Observable<MonitorReview[]> {
        return this.$http.get<MonitorReview[]>(this.url.monitorReviews(documentId));
    }

    getEventsForReview(documentId: string, monitorReviewId: string,
        next?: string): Observable<GetEventsForReviewResponse> {
        let params = new HttpParams();
        if (next) {
            params = params.set('next', next);
        }

        return this.$http.get<GetEventsForReviewResponse>(this.url.events(documentId, monitorReviewId), { params });
    }

    getPaywalls(teamId: string, documentId: string): Observable<Paywall[]> {
        return this.$http.get<Paywall[]>(this.url.paywalls(teamId), { params: { documentId } });
    }

    getInheritedStudyLabel(documentId: string): Observable<StudyLabel> {
        return this.$http.get<StudyLabel>(this.url.studyLabel(documentId));
    }

    getInheritedLabels(teamId: string, documentId: string): Observable<string[]> {
        return this.$http.get<string[]>(this.url.documentLabels(teamId, documentId));
    }

    checkMonitorReviewEnabled(documentId: string): Observable<boolean> {
        return this.$http.get<boolean>(this.url.enabled(documentId));
    }

    getPotentialRecipients(documentId: string, paywallId: string, filter: string): Observable<User[]> {
        let params = new HttpParams().set('paywallId', paywallId);
        if (filter) {
            params = params.set('filter', filter);
        }

        return this.$http.get<User[]>(this.url.potentialRecipients(documentId), { params });
    }
}
