import { LogTemplate, StudyRole } from '../../../../shared/models';

export const DOA_LOG_TEMPLATE_STEP_NAMES = {
    LOG_DETAILS: 'Log Details',
    STUDY_RESPONSIBILITIES: 'Study Responsibilities',
    STUDY_ROLES: 'Study Roles',
    CREATE_TEMPLATE: 'Create Template'
} as const;

export const DOA_LOG_TEMPLATE_STEP_FORM_CONTROL_NAMES = {
    LOG_DETAILS: 'logDetails',
    STUDY_RESPONSIBILITIES: 'studyResponsibilities',
    STUDY_ROLES: 'studyRoles',
    CREATE_TEMPLATE: 'createTemplate'
} as const;

export const DOA_LOG_TEMPLATE_FORM_CONTROLS = {
    NAME: 'name',
    DETAILS: 'details',
    COLUMNS: 'columns',
    INFORMATION: 'information',
    RESPONSIBILITIES: 'responsibilities',
    ROLES: 'roles',
    LEGEND: 'legend',
    COMMENT: 'comment',
    SINGLE_SELECT_COLUMN_OPTIONS: 'singleselectColumnOptions',
    MULTI_SELECT_COLUMN_OPTIONS: 'multiselectColumnOptions',
    REASON: 'reason'
} as const;

export const ADD_ROLE_RESPONSIBILITY_BUTTON_PLACEMENT = {
    ABOVE: 'above',
    BELOW: 'below',
    TOP_RIGHT: 'top-right',
    TOP_LEFT: 'top-left'
} as const;

export type AddRoleResponsibilityButtonPlacement = typeof ADD_ROLE_RESPONSIBILITY_BUTTON_PLACEMENT[
    keyof typeof ADD_ROLE_RESPONSIBILITY_BUTTON_PLACEMENT
];

export type DoaLogTemplateFormDetails = Pick<
    LogTemplate,
    'name' | 'details'
>

export type DoaLogTemplateFormResponsibilities = Pick<
    LogTemplate,
    'responsibilities'
>

export type DoaLogTemplateFormRoles = {
    roles: StudyRole[];
    reason?: string;
};

export type DoaLogTemplateFormCreateTemplate = Pick<
    LogTemplate,
    'legend' | 'information' | 'columns'
>

export interface DoaLogTemplateCreateForm {
    logDetails: DoaLogTemplateFormDetails;
    studyResponsibilities: DoaLogTemplateFormResponsibilities;
    studyRoles: DoaLogTemplateFormRoles;
    createTemplate: DoaLogTemplateFormCreateTemplate;
}

export type DoaLogTemplateEditRolesResponsibilitiesForm = Pick<
    DoaLogTemplateCreateForm,
    'studyResponsibilities' | 'studyRoles'
>
