import {
    Component, Input, Output, EventEmitter, ContentChildren, QueryList, AfterContentInit, OnDestroy, OnInit
} from '@angular/core';
import {
    BehaviorSubject, Observable, Subject
} from 'rxjs';
import {
    tap, pairwise, takeUntil, switchMap
} from 'rxjs/operators';
import { WizardStepComponent } from './wizard-step/wizard-step.component';
import { ProgressSteps } from './wizard.types';

import template from './wizard.component.html';
import styles from './wizard.component.scss';

@Component({
    selector: 'wizard',
    template,
    styles: [String(styles)]
})
export class WizardComponent implements OnInit, AfterContentInit, OnDestroy {
    @Input() saveButtonText: string;
    @Input() secondarySaveButtonText: string;
    @Input() isSaving: boolean;
    @Input() isLoading: boolean;
    @Input() allowSaveDraft = false;
    @Input() isSaveDisabled = false;
    @Input() displayCancelButtonOnAllSteps = true
    @Input() secondaryFormSaveButton = false

    @Output() canceled = new EventEmitter<null>();
    @Output() formSaved = new EventEmitter<null>();
    @Output() secondaryFormSaved = new EventEmitter<null>();
    @Output() draftSaved = new EventEmitter<null>();
    @Output() stepChange = new EventEmitter<number>();


    @ContentChildren(WizardStepComponent)
    steps: QueryList<WizardStepComponent>;

    readonly startingStepNumber = 1;
    finalStepNumber: number;

    private currentStepNumber = new BehaviorSubject<number>(this.startingStepNumber);
    currentStepNumber$ = this.currentStepNumber.asObservable();

    private currentStep = new BehaviorSubject<WizardStepComponent>(<WizardStepComponent>{});
    currentStep$ = this.currentStep.asObservable();

    isCurrentStepValid$: Observable<boolean> = this.currentStep$.pipe(
        switchMap((step) => step.contentChild?.isStepValid$)
    );

    isCurrentStepLoading$: Observable<boolean> = this.currentStep$.pipe(
        switchMap((step) => step.contentChild?.isLoading$)
    );

    progressSteps: ProgressSteps = {};

    private readonly destroy$ = new Subject();

    ngOnInit(): void {
        this.currentStepNumber.pipe(
            pairwise(),
            tap(([previousStepNumber, newStepNumber]) => {
                this.changeStep(previousStepNumber, newStepNumber);
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    ngAfterContentInit(): void {
        this.initSteps();

        this.finalStepNumber = Object.keys(this.progressSteps).length;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private initSteps(): void {
        this.steps.forEach((step, index) => {
            step.stepNumber = index + 1;
            this.progressSteps[step.stepNumber] = step.stepName;

            if (step.stepNumber === this.startingStepNumber) {
                this.showStep(step);
                this.setCurrentStep(step);
                return;
            }
            this.hideStep(step);
        });
    }

    onCancel(): void {
        this.canceled.emit();
    }

    onSaveForm(): void {
        this.formSaved.emit();
    }

    onSecondarySaveForm(): void {
        this.secondaryFormSaved.emit();
    }

    onSaveDraft(): void {
        this.draftSaved.emit();
    }

    incrementProgress(): void {
        this.setCurrentStepNumber(
            this.currentStepNumber.getValue() + 1
        );
        this.stepChange.emit(this.currentStepNumber.getValue());
    }

    decrementProgress(): void {
        this.setCurrentStepNumber(
            this.currentStepNumber.getValue() - 1
        );
        this.stepChange.emit(this.currentStepNumber.getValue());
    }

    private getStep(stepNumber: number): WizardStepComponent {
        return this.steps.find((step) => step.stepNumber === stepNumber);
    }

    private showStep(step: WizardStepComponent): void {
        step.isHidden = false;
    }

    private hideStep(step: WizardStepComponent): void {
        step.isHidden = true;
    }

    private changeStep(previousStepNumber: number, newStepNumber: number): void {
        const previousStep = this.getStep(previousStepNumber);
        this.hideStep(previousStep);

        const newStep = this.getStep(newStepNumber);
        this.showStep(newStep);

        this.setCurrentStep(newStep);
    }

    private setCurrentStepNumber(value: number): void {
        this.currentStepNumber.next(value);
    }

    private setCurrentStep(step: WizardStepComponent): void {
        this.currentStep.next(step);
    }

    onStepClicked(stepNumber: string) {
        const newStepNumber = Number(stepNumber);

        if (newStepNumber < this.currentStepNumber.getValue()) {
            this.setCurrentStepNumber(newStepNumber);
        }

        // TODO: only allow a forward step click if current step is valid
    }
}
