export enum Support {
    PHONE_UNFORMATTED = '18888290896',
    PHONE = '+1 (888) 829-0896',
    EMAIL = 'support@florencehc.com'
}

export enum Frequency {
    daily = 'Daily',
    weekly = 'Weekly',
    monthly = 'Monthly',
    never = 'Never'
}

export const DaysRanges = {
    everyDay: [1, 2, 3, 4, 5, 6, 7],
    weekdays: [1, 2, 3, 4, 5]
};

export enum Periods {
    am = 'AM',
    pm = 'PM'
}

export enum Days {
    monday = 'Monday',
    tuesday = 'Tuesday',
    wednesday = 'Wednesday',
    thursday = 'Thursday',
    friday = 'Friday',
    saturday = 'Saturday',
    sunday = 'Sunday'
}

export enum FriendlyDaysSelection {
    monday = 'Mo',
    tuesday = 'Tu',
    wednesday = 'We',
    thursday = 'Th',
    friday = 'Fr',
    saturday = 'Sa',
    sunday = 'Su',
    everyDay = 'Every Day',
    weekdays = 'Weekdays'
}

export const DigestNames = {
    due2Days: 'Documents due in 2 days',
    due14Days: 'Documents due in 14 days',
    due30Days: 'Documents due in 30 days',
    due60Days: 'Documents due in 60 days',
    expiring2Days: 'Documents expiring in 2 days',
    expiring14Days: 'Documents expiring in 14 days',
    expiring30Days: 'Documents expiring in 30 days',
    expiring60Days: 'Documents expiring in 60 days',
    signatureQueue: 'Signatures in My Queue',
    taskQueue: 'Tasks in My Queue'
};

export const ZendeskLinks = {
    myNotifications: 'https://florencehealthcare.zendesk.com/hc/en-us/articles/115001500027-Using-the-My-Email-Notifications-feature',
    teamNotifications: 'https://florencehealthcare.zendesk.com/hc/en-us/articles/4402024163603-Placeholder-Article-for-New-Team-Notifications'
};
