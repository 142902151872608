import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import { downgradeComponent } from '@angular/upgrade/static';

import connectedTeamsRoute from './connected-teams.route';
import { ConnectedTeamsComponent } from './containers/connected-teams/connected-teams.component';

const dependencies = [uiRouter];

const connectedTeamModule = angular
    .module('app.connected-teams', dependencies)
    .directive('connectedTeams', downgradeComponent({ component: ConnectedTeamsComponent }) as ng.IDirectiveFactory)
    .config(connectedTeamsRoute);

export default connectedTeamModule;
