import { Directive, HostListener, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: 'input[trimOnBlur], textarea[trimOnBlur]'
})
export class TrimOnBlurDirective {
    constructor(
        @Self() private ngControl: NgControl
    ) {}

    @HostListener('blur')
    onBlur(): void {
        const { value, control } = this.ngControl;
        if (!value || value === value?.trim()) {
            return;
        }

        const trimmed = value.trim();
        control.patchValue(trimmed);
    }
}
