import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Timeline } from '@app/shared/models';
import style from './destroy-item.component.scss';
import template from './destroy-item.component.html';

import { Item, ItemDestroyEvent, RemoveItems } from './destroy-item.component.types';

@Component({
    template,
    styles: [String(style)]
})
export class DestroyItemComponent {

    @Input() items: Item[];
    @Input() currentTimeline: Timeline;
    @Output() onDestroy = new EventEmitter<ItemDestroyEvent>();

    isProcessing: boolean;
    reason: string;
    removeItems: RemoveItems[];

    constructor(
        private modal: BsModalRef
    ) {

        this.isProcessing = false;
        this.reason = '';
        this.removeItems = new Array<RemoveItems>();
    }

    cancel(): void {
        this.modal.hide();
    }

    destroy() {
        this.isProcessing = true;

        this.items.forEach((item) => {
            this.removeItems.push({
                objectType: item.type,
                objectId: item.id
            });
        });
        const update = {
            projectId: this.currentTimeline.projectId,
            removeItems: this.removeItems
        };
        let successMessage;
        if (this.items.length === 1) {
            successMessage = `Item "${this.items[0].name}" removed from timeline!`;
        }
        else {
            successMessage = 'Items removed from timeline!';
        }
        this.onDestroy.emit({
            updatePayload: update,
            message: successMessage,
            onSuccess: () => {
                this.modal.hide();
            },
            onError: () => {
                this.isProcessing = false;
                this.modal.hide();
            }
        });
    }
}
