import { StateProvider } from '@uirouter/angularjs';

const documentSharingRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.document-sharing', {
        url: '/document-sharing',
        views: {
            '': 'documentSharing'
        }
    });
};

documentSharingRoute.$inject = ['$stateProvider'];
export default documentSharingRoute;
