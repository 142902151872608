export const messages = {
    unknownErr: 'There was an unexpected error. Please contact your administrator.',
    monitorGroupCreated: 'Monitor group successfully created.',
    monitorGroupCreatedErr: 'Monitor group was not created. Please review and try again.',
    monitorGroupUpdated: 'Monitor group successfully updated.',
    monitorGroupUpdatedErr: 'Monitor group was not updated. Please review and try again.',
    monitorGroupNameTakenErr: 'Monitor group with this name already exists. Please specify different name.',
    domainValidationErrMessage: 'Invalid domain.',
    domainTakenErrMessage: 'Domain already exists.'
};
