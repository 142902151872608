'use strict';

const LanguagesRegex = require('../languages-regex');

// For testing purposes
const ALLOWED_CHARS = {
    lowercaseLatin: 'a-z',
    uppercaseLatin: 'A-Z',
    unicodeChars: '\u00C0-\u1EF3',
    digits: '0-9',
    specialChars: '-_!.,;@#$%^&+=~`é{}()\';[\\]',
    whitespaceChars: '\\s'
};

const MAX_NAME_LENGTH = 250;

exports.ALLOWED_CHARS = ALLOWED_CHARS;
exports.MAX_NAME_LENGTH = MAX_NAME_LENGTH;

const allowedChars = 'a-zA-Z\\u00C0-\\u1EF30-9-_!.,;@#$%^&+=~’`é{}()\';[\\]\\s';
const notAllowedChars = '\\\\/:<>|?"*´§¤°¸¨';
const allowedCharsWithJapanese = `a-zA-Z\\u00C0-\\u1EF3${LanguagesRegex.JAPANESE_REGEX}0-9-_!.,;@#$%^&+=~\`é{}()';[\\]\\s`;

const regexs = {
    // eslint-disable-next-line no-misleading-character-class
    notAllowedChars: new RegExp(`[^${allowedChars}]`),
    allowedChars,
    allowedHost: RegExp(/^([\w.-]{1,30}\.|)(researchbinders\.com|local)$/i),
    // eslint-disable-next-line no-misleading-character-class
    allowedCharsWithJapanese: new RegExp(`[^${allowedCharsWithJapanese}]`),
    name: new RegExp(`^[${allowedChars}]{1,250}$`), // eslint-disable-line no-misleading-character-class
    nameAllowedAllCharacter: new RegExp(`^[^${notAllowedChars}]{1,250}$`),
    siteName: new RegExp(`^[${allowedChars}]{1,1000}$`), // eslint-disable-line no-misleading-character-class
    fileName: new RegExp(`^[${allowedChars}]{1,250}$`), // eslint-disable-line no-misleading-character-class
    // Some users created passwords before we increased the requirement to 10+ characters,
    // So, allow current passwords to be validated at 8 characters
    password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\S]{8,}$/,
    // Regex enforcing our stricter requirements for newly created passwords
    newPassword: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\S]{10,}$/,
    signingPasscode: /.{4,}/,
    email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    reservedUri: RegExp(/[;,/?:@&=+$]/),
    textContent: RegExp(/\w+/),
    textContentAllCharsAllowed: RegExp(/.+/),
    tags: RegExp(/^(?!(?=.*[>^:!#])|(?=\s*$)).*$/),
    labels: RegExp(/^((?![>^:!#]).)*$/),
    documentType: RegExp(/^((?![>^:!#]).)*$/),
    numbersOnly: RegExp(/\d+/g),
    phone: /^[-+\d()/ ]+$/,
    objectId: /^[0-9a-fA-F]{24}$/,
    noLeadingOrTrailingWithespace: /^\S$|^\S[ \S]*\S$/
};

const REGIONS = {
    US: 'us',
    DE: 'de',
    KP: 'kp'
};

const CONFIG_TYPES = {
    EBINDER: 'ebinder'
};

const allCharactersValidation = (value) => {

    if (!value) {
        return;
    }

    const baseErrorMessage = `param with value "${value}" fails to match the required pattern: `;
    if (value && !regexs.nameAllowedAllCharacter.test(value)) {
        const errorMessage = baseErrorMessage + regexs.nameAllowedAllCharacter;
        throw new Error(errorMessage);
    }
};

exports.REGIONS = REGIONS;
exports.CONFIG_TYPES = CONFIG_TYPES;
exports.REGEXES = regexs;

/**
 * @description Function that will return validator that uses injected Joi instance.
 * Allows usage of validators with different versions of the joi library, e.g.
 *
 * @param Joi - Joi instance
 */
exports.createValidatorWith = (joi) => {

    // eslint-disable-next-line newline-per-chained-call
    const VALIDATION_NAME = joi.string().min(1).max(250).trim().regex(regexs.name);
    const VALIDATION = {
        USER_NAME: VALIDATION_NAME,
        TEAM_NAME: VALIDATION_NAME.required(),
        OPTIONAL_OBJECT_NAME: VALIDATION_NAME,
        // eslint-disable-next-line newline-per-chained-call
        FILE_NAME: joi.string().min(1).max(250).trim().regex(regexs.fileName), // forbidden characters \ / :
        PASSWORD: joi.string().regex(regexs.password),
        SIGNING_PASSCODE: joi.string().regex(regexs.signingPasscode),
        NEW_PASSWORD: joi.string().regex(regexs.newPassword),
        EMAIL: joi.string().regex(regexs.email),
        TIMEZONE: joi.string().max(50),
        TEXT_CONTENT: joi.string().regex(regexs.textContent).required(),
        TEXT_CONTENT_ALL_CHARS_ALLOWED: joi.string().regex(regexs.textContentAllCharsAllowed).required(),
        TAG_NAME: joi.string().regex(regexs.tags).required(),
        LABEL_NAME_VALUE: joi.string().min(1).max(250).regex(regexs.labels),
        DOCUMENT_TYPE: joi.string().regex(regexs.documentType).required(),
        STUDY_ATTRIBUTE: VALIDATION_NAME,
        SITE_NAME: joi.string().regex(regexs.siteName),
        OBJECT_ID: joi.string().regex(regexs.objectId).example('620f7e790f8a42004fb0a84f'),
        OBJECT_NAME: joi.string().min(1).max(250).trim()
            .external(async (value) => {

                allCharactersValidation(value);
            })
            .required(),
        OBJECT_FILE_NAME: joi.string().min(1).max(250).trim()
            .external(async (value) => {

                allCharactersValidation(value);
            })
    };
    return VALIDATION;
};
