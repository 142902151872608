export const sortBy = <T>(array: T[], field: keyof T): T[] => {
    return array.sort((a, b) => {
        const value1 = a[field] && a[field].toString ? a[field].toString().toLowerCase() : a[field];
        const value2 = b[field] && b[field].toString ? b[field].toString().toLowerCase() : b[field];
        if (value1 < value2) {
            return -1;
        }
        if (value1 > value2) {
            return 1;
        }
        return 0;
    });
};
