import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

import uiRouter from '@uirouter/angularjs';
import { BreadcrumbsComponent } from './breadcrumbs.component';

const dependencies = [
    uiRouter
];

const breadcrumbsModule = angular
    .module('app.widgets.breadcrumbs', dependencies)
    .directive('breadcrumbs', downgradeComponent({ component: BreadcrumbsComponent }) as ng.IDirectiveFactory);

export default breadcrumbsModule;
