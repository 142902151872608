import {
    Component, Input, Output, EventEmitter,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Site, Study } from '@app/shared/models';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import template from './set-up-automation.component.html';
import { styles } from './set-up-automation.component.scss';
import { AbstractWizardStep } from '../../../../widgets/wizard/utils/abstract-wizard-step/abstract-wizard-step.component';


@Component({
    selector: 'set-up-automation-step',
    template,
    styles: [String(styles)],
    // eslint-disable-next-line no-use-before-define
    providers: [{ provide: AbstractWizardStep, useExisting: SetUpAutomationStepComponent }],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetUpAutomationStepComponent extends AbstractWizardStep implements OnInit, OnDestroy {

    @ViewChild('studiesViewport', { static: false }) studiesViewport: CdkVirtualScrollViewport;
    @ViewChild('fetchDetect', { static: false }) fetchDetect: ElementRef;

    @Input() studies: Study[];
    @Input() selectedStudy: Study;
    @Input() totalStudiesCount: number;
    @Input() studySites: Site[];
    @Input() selectedSite: Site;
    @Input() isLoadingStudies: boolean;
    @Input() isLoadingStudySites: boolean;

    @Output() fetchMoreStudiesEvent = new EventEmitter<boolean>();
    @Output() fetchMoreStudySitesEvent = new EventEmitter<boolean>();
    @Output() selectStudyEvent = new EventEmitter<Study>();
    @Output() selectSiteEvent = new EventEmitter<Site>();
    @Output() addStudySiteTeamEvent = new EventEmitter<void>();

    readonly stepFormControlName = 'setupAutomation';
    private readonly destroy$ = new Subject<void>();

    private intersectionObserver: IntersectionObserver;

    ngOnInit(): void {
        this.syncStepValidityWithFormGroupStatusChanges$().pipe(
            takeUntil(this.destroy$)
        ).subscribe();

        this.stepFormGroup.updateValueAndValidity();
    }

    ngAfterViewInit(): void {
        this.intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (this.studies?.length < this.totalStudiesCount) {
                        this.fetchMoreStudiesEvent.emit();
                    }
                }
            });
        }, { root: this.studiesViewport?.elementRef?.nativeElement });

        this.intersectionObserver.observe(this.fetchDetect?.nativeElement);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getMoreStudies() {
        this.fetchMoreStudiesEvent.emit(true);
    }

    getSites() {
        this.fetchMoreStudySitesEvent.emit(true);
    }

    selectStudy(selectedStudy: Study) {
        this.selectStudyEvent.emit(selectedStudy);
    }

    selectSite(selectedSite: Site) {
        this.selectSiteEvent.emit(selectedSite);
    }

    addStudySiteTeam() {
        this.addStudySiteTeamEvent.emit();
    }

    studyNameInputText$(): Observable<string> {
        return of(this.selectedStudy).pipe(
            map((study: Study) => (study && study.nickname) ?? 'Study name')
        );
    }

    validStudySites$(): Observable<Site[]> {
        return of(this.studySites).pipe(
            map((sites: Site[]) => sites.filter((site) => {
                return !!site.customSiteId && site.teamMembers?.length > 0;
            }))
        );
    }

    private hasValidStudySites$(): Observable<boolean> {
        return this.validStudySites$().pipe(
            map((validStudySites: Site[]) => validStudySites.length > 0)
        );
    }

    isSiteIdInputDisabled$(): Observable<boolean> {
        return this.hasValidStudySites$().pipe(
            map((hasValidStudySites: boolean) => {
                if (this.isLoadingStudySites) {
                    return true;
                }
                return !this.selectedStudy?.uniqueProtocolId || !hasValidStudySites;
            })
        );
    }

    showAddStudySiteTeamElement$(): Observable<boolean> {
        return this.hasValidStudySites$().pipe(
            map((hasValidStudySites: boolean) => {
                if (this.isLoadingStudySites) {
                    return false;
                }
                return this.selectedStudy?.uniqueProtocolId && !hasValidStudySites;
            })
        );
    }
}
