import * as _ from 'lodash';

import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit
} from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';

import { StateService } from '@uirouter/angularjs';

import { CurrentSessionService } from '@app/core/current-session.service';
import {
    Folder,
    Document,
    Team,
    Binder,
    CloneDestination,
    CloneInstruction,
    BrowseTree,
    ApiErrorResponse,
    ApiError
} from '@app/shared/models';
import { BrowseParams } from '@app/shared/teams/teams.service.types';
import { BindersService } from '@app/shared/binders/binders.service';
import { FoldersService } from '@app/shared/folders/folders.service';
import { DocumentService } from '@app/shared/documents/document.service';
import { VirtualTreeItemSelectedEvent, VirtualTreeSelectionMode } from '@app/widgets/virtual-tree/virtual-tree.component.types';
import { REGEX, MESSAGES } from '@app/core/constants';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { sortBrowseTreeLexicographically } from '@app/widgets/sort/sort-browse-tree-lexicographically.util';

import { TeamService } from '@app/shared/teams/team.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import template from './duplicate.component.html';
import styles from './duplicate.component.scss';
import { CreateNamesListComponent } from '../create-custom-names-list/create-names-list.component';

enum NamingMethod {
    sameForAll = 'Same for all',
    customNames = 'Custom names'
}

@Component({
    selector: 'duplicate',
    template,
    styles: [String(styles)]
})
export class DuplicateComponent implements OnInit {
    @Input() items: (Folder | Document)[] = [];
    @Input() container: Folder;
    @Output() dismiss = new EventEmitter<void>();

    readonly forbiddenChars = MESSAGES.forbiddenChars;
    readonly namingMethodOptions = NamingMethod;
    countTooltip: string;
    namingMethodTooltip = 'Select naming method: the same name for all (ending in numbers) or custom names for each item.';
    namesOfDuplicatesTooltip = 'Name of duplicated items. If same name already exists in location, a number is appended, e.g. "Itemname (1)"';
    namesListButtonTooltip = 'Click to create custom list of names for each placeholder';
    customDocumentNames: string[] = [];
    selectedNamingMethod: NamingMethod;
    itemsType: 'folder' | 'document';
    currentTeam: Team;
    entity: Folder | Document;
    currentPath: string[] = [];
    hasShortcuts = false;
    isProcessing = false;
    isBinderTreeLoading = false;
    isDuplicatingLockedDocuments = false;
    binderTreeRootItems: Binder[];
    binderTreeSelectionMode = VirtualTreeSelectionMode.SINGLE;
    destination: Binder | Folder;
    customNameForm = this.fb.group({
        count: [1, [
            Validators.max(100),
            Validators.min(1)
        ]],
        name: ['', [
            Validators.pattern(REGEX.names)
        ]]
    })

    constructor(
        private $state: StateService,
        private CurrentSession: CurrentSessionService,
        private Teams: TeamService,
        private Binders: BindersService,
        private Folders: FoldersService,
        private Modals: ModalsService,
        private fb: FormBuilder,
        private Notifications: NotificationsService,
        private Documents: DocumentService
    ) { }

    get countFormControl(): AbstractControl {
        return this.customNameForm.get('count');
    }

    get nameFormControl(): AbstractControl {
        return this.customNameForm.get('name');
    }

    get countHasErrors(): boolean {
        return this.countFormControl.invalid && (this.countFormControl.dirty || this.countFormControl.touched);
    }

    get nameHasErrors(): boolean {
        return this.nameFormControl.invalid && (this.nameFormControl.dirty || this.nameFormControl.touched);
    }

    get isSameForAllSelected(): boolean {
        return this.selectedNamingMethod === this.namingMethodOptions.sameForAll;
    }

    get isCustomNamesSelected(): boolean {
        return this.selectedNamingMethod === this.namingMethodOptions.customNames;
    }

    get shouldDisableDuplicate(): boolean {
        return !this.destination
            || this.isProcessing
            || (this.isCustomNamesSelected && !this.customDocumentNames.length)
            || (this.isSameForAllSelected && this.customNameForm.invalid);
    }

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.selectNamingMethod(this.namingMethodOptions.sameForAll);
        if (this.items && this.items.length) {
            this.entity = this.items.length > 1 ? this.container : this.items[0];
            this.itemsType = this.items[0].type;
            this.currentPath = [this.items[0].binderId].concat(...this.items.map((i) => i.lineage));
            if (this.items.length === 1) {
                const prefilledName = this.items[0].name;
                this.customNameForm.patchValue({ name: prefilledName });
            }
        }

        this.isBinderTreeLoading = true;
        this.Binders
            .getBinders(this.currentTeam.id, { includeArchived: false })
            .toPromise()
            .then((rootItems) => {
                this.binderTreeRootItems = sortByLexicographically(rootItems, 'name');
                this.isBinderTreeLoading = false;
            })
            .catch(this.handleErrorNotification);

        if (this.itemsType === 'folder') {
            this.countFormControl.disable();
            this.countTooltip = 'Folders limited to 1 duplicate.';
            this.namingMethodTooltip = 'Not available for folders.';

            this.Teams.hasShortcuts(this.currentTeam.id, {
                objectType: 'folders',
                objectIds: this.items.map((item: Folder) => item.id)
            }).pipe(take(1))
                .subscribe((hasShortcuts) => {
                    this.hasShortcuts = hasShortcuts;
                });
        }
        if (this.itemsType === 'document') {
            this.isDuplicatingLockedDocuments = this.items.some((item: Document) => item.isLocked);
        }
    }

    loadBinderTreeItem = (browseParams: BrowseParams): ng.IPromise<BrowseTree> => {
        return this.Teams.browse(this.currentTeam.id, { ...browseParams, includeArchived: false })
            .toPromise().then((data) => sortBrowseTreeLexicographically(data, 'name'));
    }

    isItemSelectable(): boolean {
        return true;
    }

    onItemSelected($event: VirtualTreeItemSelectedEvent<Binder | Folder>): void {
        this.destination = $event.item;
    }

    onItemUnselected(): void {
        this.destination = undefined;
    }

    selectNamingMethod(method: NamingMethod): void {
        this.selectedNamingMethod = method;
        if (method === this.namingMethodOptions.sameForAll) {
            this.countFormControl.enable();
            this.countTooltip = 'Number of duplicates (max 100)';
        }
        else if (method === this.namingMethodOptions.customNames) {
            if (this.customDocumentNames.length) {
                this.countFormControl.setValue(this.customDocumentNames.length);
            }
            this.countFormControl.disable();
            this.countTooltip = 'When Naming method is Custom names, count is based on the number of names in your list and cannot be edited.';
        }
    }

    dismissModal(): void {
        if (!this.isProcessing) {
            this.dismiss.emit();
        }
    }

    onCloneSuccess = (): void => {
        this.isProcessing = false;
        this.dismissModal();
        this.$state.go(this.$state.current, {}, { reload: true });
    }

    onCloneFailure = (): void => {
        this.isProcessing = false;
        this.dismissModal();
    }

    openCustomNamesModal(): void {
        const modal = this.Modals.show(CreateNamesListComponent, {
            animated: false,
            class: 'modal-lg',
            initialState: {
                namesLocation: this.destination,
                customNames: this.customDocumentNames
            }
        });

        modal.content.dismiss.subscribe((customNames: string[]) => {
            if (customNames === null) {
                modal.hide();
                return;
            }
            this.customDocumentNames = customNames;
            this.customNameForm.patchValue({ count: customNames.length || 1 });
            this.namesListButtonTooltip = customNames.length > 0
                ? 'View or change names list.'
                : 'Click to create custom list of names for each placeholder';
            modal.hide();
        });
    }

    duplicate(): void {
        if (this.shouldDisableDuplicate) {
            return;
        }
        this.isProcessing = true;

        const duplicateDestination: CloneDestination = { binderId: null };
        if (this.destination.type === 'folder') {
            duplicateDestination.binderId = this.destination.binderId;
            duplicateDestination.folderId = this.destination.id;
        }
        else {
            duplicateDestination.binderId = this.destination.id;
        }

        if (this.items.length === 1) {
            if (this.itemsType === 'folder') {
                const teamId = this.currentTeam.id;
                const { binderId } = this.items[0];
                this.Folders.customClone({
                    teamId,
                    binderId,
                    folder: this.items[0] as Folder,
                    destination: duplicateDestination,
                    folderCloneCustomName: this.nameFormControl.value
                }).toPromise()
                    .then(
                        (data) => {
                            const response = data[0];

                            if (response.statusCode === 200) {
                                const destinationUrl = this.$state.href('app.team.folder', {
                                    teamId,
                                    binderId: duplicateDestination.binderId,
                                    folderId: duplicateDestination.folderId || null
                                });
                                const msg = 'Successfully duplicated to <%= destinationName %>.<br> <strong><a href="<%= destinationUrl %>">GO TO FOLDER</a></strong>';
                                const msgTemplate = _.template(msg);
                                const destinationName = this.destination.name;
                                const templateParams = { destinationName, destinationUrl };
                                const html = msgTemplate(templateParams);
                                this.Notifications.success(html);
                            }
                            else {
                                const rawMsg = (response.payload as ApiErrorResponse).message;
                                const fullMessage = `An error occurred for Folder, ${this.items[0].name}. ${rawMsg}`;
                                this.Notifications.error(fullMessage);
                            }

                            this.onCloneSuccess();
                        },
                        (error) => {
                            this.onCloneFailure();
                            this.handleErrorNotification(error);
                        }
                    );
            }
            if (this.itemsType === 'document') {
                let cloneInstructions: CloneInstruction[];
                if (this.selectedNamingMethod === this.namingMethodOptions.customNames) {
                    cloneInstructions = this.customDocumentNames.map((name) => ({ numberOfClones: 1, name }));
                }
                else {
                    cloneInstructions = [{ numberOfClones: this.countFormControl.value, name: this.nameFormControl.value }];
                }
                this.Documents.customClone(
                    this.items[0] as Document,
                    duplicateDestination,
                    cloneInstructions
                ).toPromise().then(this.onCloneSuccess, this.onCloneFailure);
            }
        }
        else {
            if (this.itemsType === 'folder') {
                const teamId = this.currentTeam.id;
                const { binderId } = this.items[0];
                const params = {
                    teamId,
                    binderId,
                    folders: this.items as Folder[],
                    destination: duplicateDestination
                };
                this.Folders.cloneBatch(params).toPromise()
                    .then(
                        (data) => {
                            const successfullResponseItems = data.filter((responseItem) => responseItem.statusCode === 200);
                            data.forEach((responseItem, index) => {
                                const folder = this.items[index];
                                if (responseItem.statusCode !== 200) {
                                    const rawMsg = (responseItem.payload as ApiErrorResponse).message;
                                    const fullMessage = `An error occurred for Folder, ${folder.name}. ${rawMsg}`;
                                    this.Notifications.error(fullMessage);
                                }
                            });
                            if (successfullResponseItems.length) {
                                const destinationUrl = this.$state.href('app.team.folder', {
                                    teamId,
                                    binderId: duplicateDestination.binderId,
                                    folderId: duplicateDestination.folderId || null
                                });
                                const msg = 'Successfully duplicated to <%= destinationName %>.<br> <strong><a href="<%= destinationUrl %>">GO TO FOLDER</a></strong>';
                                const msgTemplate = _.template(msg);
                                const destinationName = this.destination.name;
                                const templateParams = { destinationName, destinationUrl };
                                const html = msgTemplate(templateParams);
                                this.Notifications.success(html);
                            }

                            this.onCloneSuccess();
                        },
                        (error) => {
                            this.onCloneFailure();
                            this.handleErrorNotification(error);
                        }
                    );
            }
            if (this.itemsType === 'document') {
                this.Documents.cloneBatch(
                    this.items as Document[],
                    duplicateDestination
                ).toPromise().then(this.onCloneSuccess, this.onCloneFailure);
            }
        }
    }

    private handleErrorNotification({ error }: ApiError): void {
        const { message } = error;
        if (message) {
            return this.Notifications.error(message);
        }

        return this.Notifications.unexpectedError();
    }
}
