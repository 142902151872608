import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import {
    Document,
    LogEntry,
    SignatureRequest,
    User
} from '@app/shared/models';
import { areDatesEqual } from '@app/shared/date-time/are-dates-equal.util';
import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';
import { CHECKBOX_STATES } from '@app/core/constants';
import { isDateInThePast } from '@app/shared/date-time/is-date-in-the-past.util';
import { UpdateLogEntrySignatureRequestsPayload } from '@app/shared/documents-log-entries/document-log-entries.service.types';

import { LogEntrySignersTabDataProps, LogEntrySignersTabDataRow } from '../components/log-entry-signers-tab/log-entry-signers-tab.component';
import { LogEntryPendingTabDataRow } from '../components/log-entry-pending-signatures-tab/log-entry-pending-signatures-tab.component';

type UpdateSignersTabDataProps = Partial<Pick<
    LogEntrySignersTabDataProps,
    'emailSigner' | 'notifyMe' | 'signByDate' | 'reason'
>>;

type UpdatePendingTabDataProps = Partial<Pick<
    LogEntryPendingTabDataRow,
    'emailSigner' | 'notifyMe' | 'signByDate' | 'reason'
>>;

@Injectable({
    providedIn: 'root'
})
export class LogEntrySignatureRequestsStore {
    private logEntry: LogEntry = null;
    private availableColumns: string[] = [];
    private currentUserId: string = null;
    private readonly subjects = {
        selectedSigners: new BehaviorSubject<User[]>([]),
        signersTabData: new BehaviorSubject<LogEntrySignersTabDataRow[]>([]),
        pendingRequests: new BehaviorSubject<SignatureRequest[]>([]),
        pendingTabData: new BehaviorSubject<LogEntryPendingTabDataRow[]>([])
    };

    selectedSigners$ = this.subjects.selectedSigners.asObservable();
    signersTabData$ = this.subjects.signersTabData.asObservable();

    pendingRequests$ = this.subjects.pendingRequests.asObservable();
    pendingTabData$ = this.subjects.pendingTabData.asObservable();

    init(entry: LogEntry, currentUser: User, availableColumns: string[]): void {
        this.currentUserId = currentUser.id;
        this.logEntry = entry;
        this.availableColumns = availableColumns;
    }

    private mapSignerToSignerTabDataItem = (
        signer: User,
        additionalProps: UpdateSignersTabDataProps = {}
    ): LogEntrySignersTabDataRow => {
        const row: LogEntrySignersTabDataRow = {
            ...signer,
            ...additionalProps,
            columnOptions: this.availableColumns
        };
        row.showSignByDateInvalid = !!row.signByDate && isDateInThePast(row.signByDate);
        return row;
    }

    private mapSignatureRequestToPendingTabRow = (r: SignatureRequest): LogEntryPendingTabDataRow => {
        return {
            ...r,
            updated: false,
            markedAsCanceled: false,
            reason: r.reason,
            signByDate: r.signByDate,
            notifyMe: r.notifyUsersOnSigned.includes(this.currentUserId)
                ? CHECKBOX_STATES.SELECTED
                : CHECKBOX_STATES.NOT_SELECTED,
            emailSigner: CHECKBOX_STATES.NOT_SELECTED
        };
    }

    selectSigners(change: FilteredSelectEvent<User>): void {
        const { removedIds, added } = change;
        let signersTabData = this.subjects.signersTabData.getValue();
        let selectedSigners = this.subjects.selectedSigners.getValue();
        if (removedIds && removedIds.length) {
            signersTabData = signersTabData.filter((s) => !removedIds.includes(s.id));
            selectedSigners = selectedSigners.filter((s) => !removedIds.includes(s.id));
        }
        if (added && added.length) {
            const mapped = added.map((s) => this.mapSignerToSignerTabDataItem(s));
            signersTabData = signersTabData.concat(mapped);
            selectedSigners = selectedSigners.concat(added);
        }
        this.subjects.signersTabData.next(signersTabData);
        this.subjects.selectedSigners.next(selectedSigners);
    }

    updateSignersTabData(updates: UpdateSignersTabDataProps, id: string): void {
        const updatedRows = this.subjects.signersTabData.getValue()
            .map((row) => {
                if (row.id === id) {
                    return this.mapSignerToSignerTabDataItem(row, updates);
                }
                return row;
            });
        this.subjects.signersTabData.next(updatedRows);
    }

    updateSignersTabDataColumn(column: string, id: string): void {
        let updatedRows = this.subjects.signersTabData.getValue();
        const existingRowWithColumnIndex = updatedRows.findIndex((row) => row.column === column);
        updatedRows = updatedRows.map((row, index) => {
            if (index === existingRowWithColumnIndex) {
                return { ...row, column: undefined };
            }
            if (row.id === id) {
                return { ...row, column };
            }
            return row;
        });
        this.subjects.signersTabData.next(updatedRows);
    }

    setPendingRequests(pendingRequests: SignatureRequest[]): void {
        this.subjects.pendingRequests.next(pendingRequests);
        this.subjects.pendingTabData.next(
            pendingRequests.map(this.mapSignatureRequestToPendingTabRow)
        );
    }

    private isPendingTabRowUpdated(row: LogEntryPendingTabDataRow, pendingRequest: SignatureRequest): boolean {
        return row.emailSigner !== CHECKBOX_STATES.NOT_SELECTED
            || row.reason !== pendingRequest.reason
            || !areDatesEqual(row.signByDate, pendingRequest.signByDate)
            || !!row.notifyMe !== pendingRequest.notifyUsersOnSigned.includes(this.currentUserId);
    }

    updatePendingTabData(updates: UpdatePendingTabDataProps, id: string): void {
        const pendingRequests = this.subjects.pendingRequests.getValue();
        const updatedRows = this.subjects.pendingTabData.getValue()
            .map((row, index): LogEntryPendingTabDataRow => {
                if (row.id === id) {
                    const updatedRow = {
                        ...row,
                        ...updates
                    };
                    updatedRow.updated = this.isPendingTabRowUpdated(updatedRow, pendingRequests[index]);
                    updatedRow.showSignByDateInvalid = !!updatedRow.signByDate && isDateInThePast(updatedRow.signByDate);
                    return updatedRow;
                }
                return row;
            });
        this.subjects.pendingTabData.next(updatedRows);
    }

    markPendingTabDataAsCanceled(ids: string[]): void {
        const updatedRows = this.subjects.pendingTabData.getValue()
            .map((row): LogEntryPendingTabDataRow => {
                if (ids.includes(row.id)) {
                    return {
                        ...row,
                        markedAsCanceled: true,
                        showSignByDateInvalid: false
                    };
                }
                return row;
            });
        this.subjects.pendingTabData.next(updatedRows);
    }

    undoPendingTabDataChanges(ids: string[]): void {
        const pendingRequests = this.subjects.pendingRequests.getValue();
        const updatedRows = this.subjects.pendingTabData.getValue()
            .map((row, index): LogEntryPendingTabDataRow => {
                if (ids.includes(row.id)) {
                    return {
                        ...this.mapSignatureRequestToPendingTabRow(pendingRequests[index]),
                        checked: row.checked,
                        updated: false,
                        showSignByDateInvalid: false
                    };
                }
                return row;
            });
        this.subjects.pendingTabData.next(updatedRows);
    }

    getUpdateLogEntrySignatureRequestsPayload(logDocument: Document): UpdateLogEntrySignatureRequestsPayload | null {
        const signersTabData = this.subjects.signersTabData.getValue();
        const pendingTabData = this.subjects.pendingTabData.getValue();
        const payload: UpdateLogEntrySignatureRequestsPayload = {
            comment: '',
            updates: []
        };
        const updateItem = {
            documentId: logDocument.id as string,
            version: logDocument.version,
            add: [],
            resend: [],
            cancel: []
        };
        signersTabData.forEach((row) => {
            if (row.column && row.reason) {
                const addItem = {
                    userId: row.id,
                    reason: row.reason,
                    method: 'Log',
                    skipEmailNotification: row.emailSigner !== CHECKBOX_STATES.SELECTED,
                    notifyRequestorWhenSigned: row.notifyMe === CHECKBOX_STATES.SELECTED,
                    signByDate: row.signByDate || null,
                    columnName: row.column,
                    entryId: this.logEntry.id.logEntryId
                };
                updateItem.add.push(addItem);
            }
        });
        pendingTabData.forEach((row) => {
            if (row.markedAsCanceled) {
                updateItem.cancel.push(row.id);
            }
            else if (row.updated) {
                const resendItem = {
                    id: row.id,
                    reason: row.reason,
                    method: row.method,
                    signByDate: row.signByDate,
                    notifyRequestorWhenSigned: !!row.notifyMe
                };
                updateItem.resend.push(resendItem);
            }
        });
        if (
            updateItem.add.length
            || updateItem.cancel.length
            || updateItem.resend.length
        ) {
            payload.updates.push(updateItem);
            return payload;
        }
        return null;
    }

    flushData(): void {
        Object.values(this.subjects).forEach((s) => s.next([]));
        this.currentUserId = null;
        this.logEntry = null;
        this.availableColumns = [];
    }
}
