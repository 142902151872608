import { Directive, Self, HostBinding } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormUtil } from '../../utils/form.util';

@Directive({
    selector: 'input[applyErrorStylesIfControlInvalid], textarea[applyErrorStylesIfControlInvalid]'
})
export class ApplyErrorStylesIfControlInvalidDirective {
    constructor(
        @Self() private ngControl: NgControl
    ) {}

    @HostBinding('class.has-error')
    get isInvalid(): boolean {
        return FormUtil.controlHasError(this.ngControl);
    }
}
