'use strict';

module.exports = {
    monitorReviewStatuses: {
        OPEN: 'open',
        CLOSED: 'closed'
    },
    monitorReviewPredefinedLabels: {
        UNIQUE_PROTOCOL_ID: 'Unique Protocol ID'
    },
    monitorReviewReportStatuses: {
        OPEN: 'Open Query',
        CLOSED: 'Closed Query',
        APPROVED: 'Approved',
        REVIEWED: 'Reviewed'
    },
    monitorReviewFinalStatuses: {
        APPROVED: 'Approved',
        REVIEWED: 'Reviewed'
    },
    monitorReviewFilters: {
        OPEN_QUERY: 'openQuery',
        APPROVED: 'approved',
        REVIEWED: 'reviewed'
    }
};
