import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Team } from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';
import template from './choose-teams.component.html';
import { Connection } from '../../services/document-sharing.types';
import { SelectionState, SelectionStates } from '../../models/checkbox-selection-state.model';

@Component({
    selector: 'choose-teams',
    template
})
export class ChooseTeamsComponent implements OnInit {
    @Input() connections: Connection[];
    @Input() isReceiver: boolean;
    @Input() selectedConnectionIds: string[];
    @Output() closeModal = new EventEmitter<boolean>();
    @Output() selectConnections = new EventEmitter<string[]>();

    private newSelectionIds: Set<string>;
    public filteredConnections: Connection[];
    public isDoneButtonDisabled = true;
    public currentTeam: Team;

    public selectionStates: { [key:string]: SelectionStates } = {
        selectAll: SelectionState.NONE
    };

    constructor(
        private modalRef: BsModalRef,
        private CurrentSession: CurrentSessionService
    ) {}

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();

        // set the initial values for selectionStates
        this.connections.forEach((connection) => {
            this.selectionStates[connection._id] = this.selectedConnectionIds.includes(connection._id)
                ? SelectionState.FULL : SelectionState.NONE;
        });
        this.resetFilteredConnections();
        // copy the selectedConnectionIds into a new Set to keep the original state intact in event that the user clicks Cancel
        this.newSelectionIds = new Set<string>(this.selectedConnectionIds);
        if (this.newSelectionIds.size) {
            this.isDoneButtonDisabled = false;
        }
    }

    hideModal(): void {
        this.closeModal.emit(true);
        this.modalRef.hide();
    }

    onFilterInput(filterInputValue): void {
        if (!filterInputValue) {
            this.resetFilteredConnections();
        }
        else {
            this.filteredConnections = this.connections.filter((connection) => {
                return this.getConnectedTeamName(connection).toLowerCase().includes(filterInputValue.toLowerCase());
            });
        }
    }

    onChoseTeams(): void {
        this.selectConnections.emit(Array.from(this.newSelectionIds));
        this.hideModal();
    }

    resetFilteredConnections(): void {
        this.filteredConnections = [...this.connections];
    }

    onSelectConnections(connections: Connection[]): void {
        connections.forEach((connection) => {
            this.newSelectionIds.add(connection._id);
            this.selectionStates[connection._id] = SelectionState.FULL;
        });
        if (this.isDoneButtonDisabled) {
            this.isDoneButtonDisabled = false;
        }
    }

    onDeselectConnections(connections: Connection[]): void {
        connections.forEach((connection) => {
            this.newSelectionIds.delete(connection._id);
            this.selectionStates[connection._id] = SelectionState.NONE;
        });
        // if no connections are checked, disable the Done button
        if (Object.values(this.selectionStates).every((selectionState) => selectionState === SelectionState.NONE)) {
            this.isDoneButtonDisabled = true;
        }
    }

    getConnectedTeamName(connection: Connection): string {
        return connection.configs.find((config) => config.teamId !== this.currentTeam.id)?.name;
    }

    toggleSelectAll(): void {
        if (this.selectionStates.selectAll === SelectionState.NONE) {
            this.selectionStates.selectAll = SelectionState.FULL;
            this.onSelectConnections(this.filteredConnections);
        }
        else {
            this.selectionStates.selectAll = SelectionState.NONE;
            this.onDeselectConnections(this.filteredConnections);
        }
    }

    toggleSelectConnection(connection: Connection): void {
        if (this.newSelectionIds.has(connection._id)) {
            this.onDeselectConnections([connection]);
        }
        else {
            this.onSelectConnections([connection]);
        }
    }
}
