'use strict';

const SigningReasons = require('./signing-reasons');
const SigningDeclineReasion = require('./signing-decline-reason');
const SigningStatuses = require('./signing-status');

const signingReasonsArr = Object.values(SigningReasons);
const signingStatusesArr = Object.values(SigningStatuses);

const signatureSchema = {
    user: {
        userId: { type: String, required: true },
        name: { type: String, required: true },
        email: { type: String, default: null },
        notificationEmail: { type: String, default: null },
        jobTitle: { type: String, default: null }
    },
    notifyRequestorWhenSigned: { type: Boolean, default: false },
    version: { type: Number },
    reason: { type: String, enum: [...signingReasonsArr, SigningDeclineReasion], required: true },
    status: { type: String, enum: signingStatusesArr, default: SigningStatuses.signed },
    signatureComment: String,
    timestamp: Date,
    createdBy: { type: String, required: true },
    updatedBy: { type: String, required: true },
    createdAt: Date,
    updatedAt: Date,
    requestedAt: Date
};

module.exports = signatureSchema;
