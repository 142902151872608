import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import {
    FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { RejectionDisagreeEvent, RejectionAgreeEvent, ReviewCycle } from '@app/components/documents/qc-review.services.types';
import { notBlank } from '@app/core/form-validators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { calculateEntityPath, EntityPathItem } from '@app/shared/documents/calculate-entity-path.util';
import { Document, Team } from '@app/shared/models';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import template from './review-findings-qc-review.component.html';
import styles from './review-findings-qc-review.component.scss';
import { ReviewFindingsConcludedComponent } from '../review-finding-concluded/review-findings-concluded.component';

@Component({
    selector: 'review-findings-qc-review',
    template,
    styles: [String(styles)]

})
export class ReviewFindingsQcReviewComponent implements OnInit {
    @Input() index: number;
    @Input() review;
    @Input() comment = '';
    @Input() totalReviews: number;
    @Input() entity: Document;
    @Input() currentTeam: Team;
    @Output() rejectionDisagreeReviewFindings = new EventEmitter<RejectionDisagreeEvent>();
    @Output() rejectionAgreeReviewFindings = new EventEmitter<RejectionAgreeEvent>();

    form: FormGroup;
    path: EntityPathItem[];
    progress = '';
    isProcessing = false;

    constructor(
        private fb: FormBuilder,
        public modal: BsModalRef,
        private Modals: ModalsService
    ) {}

    ngOnInit(): void {
        this.path = calculateEntityPath(this.entity, this.currentTeam);
        this.path[this.path.length - 1].name = `${this.entity.name} - Version ${this.entity.version}`;
        this.initForm();
    }

    private initForm(): void {
        this.form = this.fb.group({
            decision: ['', [Validators.required]],
            comment: [this.comment]
        });

        this.form.get('decision').valueChanges
            .subscribe((value) => {
                if (value === 'disagree') {
                    this.form.get('comment').setValidators([Validators.required, notBlank]);
                }
                else {
                    this.form.get('comment').clearValidators();
                }
                this.form.get('comment').updateValueAndValidity();
            });
    }

    canSubmit(): boolean {
        return this.form.valid;
    }


    reviewFindingsConcluded(operation): void {
        this.Modals.show(ReviewFindingsConcludedComponent, {
            initialState: {
                path: this.path,
                operation
            }
        });
    }

    onSubmit() {
        if (this.isProcessing) {
            return;
        }

        this.isProcessing = true;
        const { comment } = this.form.value;
        if (this.form.value.decision === 'agree') {
            this.rejectionAgreeReviewFindings.emit({
                comment,
                onSuccess: () => {
                    this.dismissModal();
                    this.reviewFindingsConcluded('agreed');
                    this.isProcessing = false;
                },
                onError: () => {
                    this.isProcessing = false;
                }
            });
        }
        else {
            this.rejectionDisagreeReviewFindings.emit({
                comment,
                onSuccess: () => {
                    this.dismissModal();
                    this.reviewFindingsConcluded('disagreed');
                    this.isProcessing = false;
                },
                onError: () => {
                    this.isProcessing = false;
                }
            });
        }
    }

    dismissModal(): void {
        this.modal.hide();
    }

}
