import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { PasswordExpiredComponent } from './components/password-expired.modal/password-expired.component';
import { PasswordExpiryReminderComponent } from './components/password-expiry-reminder.modal/password-expiry-reminder.component';


const dependencies = [
];

export default angular.module('app.passwordPolicy', dependencies)
    .directive('passwordExpired', downgradeComponent({ component: PasswordExpiredComponent }) as ng.IDirectiveFactory)
    .directive('passwordExpiryReminder', downgradeComponent({ component: PasswordExpiryReminderComponent }) as ng.IDirectiveFactory);
