import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { Document } from '@app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import template from './document-approval.component.html';
import styles from './document-approval.component.scss';
import { DocumentSubmitEvent } from './document-approval.component.types';

@Component({
    template,
    styles: [String(styles)]
})

export class DocumentApprovalComponent implements OnInit {
    @Input() entity: Document;
    @Input() action: string;
    @Output() submit = new EventEmitter<DocumentSubmitEvent>();
    docApprovalForm: FormGroup;
    isProcessing = false;
    reasonMaxLength = 500;
    actionTitleName: string;
    actionTextName: string;

    constructor(
        private fb: FormBuilder,
        private Modal: BsModalRef
    ) {}

    ngOnInit(): void {
        this.actionTitleName = this.action === 'approve' ? 'Approve' : 'Reject';
        this.actionTextName = this.action === 'approve' ? 'APPROVED' : 'REJECTED';
        this.initForm();
    }

    cancel(): void {
        this.Modal.hide();
    }

    handleSubmit() {
        this.isProcessing = true;
        const reason = this.docApprovalForm.controls.reason && this.docApprovalForm.controls.reason.value;
        this.submit.emit({
            data: { entity: this.entity, reason }
        });
        this.Modal.hide();
    }

    private initForm(): void {
        this.docApprovalForm = this.fb.group({});

        if (this.action === 'reject') {
            this.docApprovalForm.addControl('reason', this.fb.control('', [Validators.required, Validators.maxLength(this.reasonMaxLength), notBlank]));
        }
    }
}
