import { UIRouter } from '@uirouter/angular';

// eslint-disable-next-line @typescript-eslint/no-unused-vars, func-style
export function uiRouterConfigFn(router: UIRouter) {
    const { urlService } = router;

    urlService.rules.otherwise(() => {
        const path = urlService.path();
        // If no valid path exists, return the state name
        if (!path || path === '/') {
            return 'home';
        }

        // Return the default path if not handled
        return '/';
    });

}
