import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { StateService } from '@uirouter/angularjs';
import { MONTHS, SORT } from '@app/core/constants';

import {
    ApiError, DashboardProject, Project, Team, Timeline
} from '@app/shared/models';
import { Component, Inject, OnInit } from '@angular/core';
import { ProjectsService } from '@app/shared/projects/projects.service';
import { TeamService } from '@app/shared/teams/team.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import style from './dashboard-timelines.component.scss';
import template from './dashboard-timelines.component.html';
import { DashboardReport, ProjectMonths, TimelineMap } from './dashboard-timelines.component.types';

@Component({
    selector: 'dashboard-timelines',
    template,
    styles: [String(style)]
})
export class DashboardTimelinesComponent implements OnInit {

    crumbs = [{ name: 'Project Dashboard' }];
    showControl = false;
    showLegend = false;
    loadingData = false;
    showTodayLabel = false;

    MONTHS = MONTHS;

    viewBy: string;
    currentTeam: Team;
    isSingleBinder: boolean;
    allProjects: Project[];
    dashboardProject: DashboardProject;
    timelines: Timeline[];
    dashboardReport: DashboardReport;

    today: moment.Moment;
    projectDuration: number;
    projectProgress: number;

    startMonth: number;
    startYear: number;
    beginDate: moment.Moment;

    endMonth: number;
    endYear: number;
    endDate: moment.Moment;

    showToday: boolean;
    projectMonths: ProjectMonths[];

    graphContainer: HTMLElement;

    visibleTimelines: Timeline[];
    hiddenTimelines: Timeline[];

    timelineMap: TimelineMap;

    clalculatingMonths = false;
    SORT = SORT;
    clampLength = 50;

    constructor(
        @Inject('Window') private window: Window,
        private $state: StateService,
        private Projects: ProjectsService,
        private Teams: TeamService,
        private CurrentSession: CurrentSessionService,
        private Notifications: NotificationsService
    ) {
    }

    ngOnInit(): void {
        const stateParams = this.$state.params;
        this.viewBy = stateParams.viewBy || 'Tag';
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.isSingleBinder = !!stateParams.binderId;
        this.loadingData = true;
        this.Projects.getAllProjects(this.currentTeam.id)
            .subscribe(
                (projects) => {
                    this.allProjects = projects;
                    this.updateSort('name', false);
                    if (projects.length === 0) {
                        this.loadingData = false;
                        return;
                    }
                    this.Projects
                        .getDashboardProject(this.currentTeam.id, stateParams.projectId || projects[0].id)
                        .subscribe(
                            (project) => {
                                this.dashboardProject = project;
                                this.timelines = _.sortBy(_.get(this.dashboardProject, 'dashboard.timelines', []), 'name');
                                this.dashboardReport = this.dashboardProject?.dashboard || {
                                    endDate: '',
                                    startDate: '',
                                    timelines: [],
                                    documentCount: 0,
                                    placeholderCount: 0
                                };
                                this.initData();
                                this.loadingData = false;
                                this.initializeGraph();
                            },
                            (error) => {
                                this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                                this.loadingData = false;
                            }
                        );
                },
                (error) => {
                    this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                }
            );
    }

    updateSort(sortName: string, isReversed?: boolean): void {
        this.SORT.set(sortName, isReversed);
        const sortedProjects = this.allProjects.sort((a, b) => {
            return this.SORT.naturalSort({ value: a[sortName] }, { value: b[sortName] });
        });
        if (this.SORT.isReversed) {
            sortedProjects.reverse();
        }
        this.allProjects = [...sortedProjects];
    }

    initData() {

        this.today = moment();
        this.projectDuration = 0.0;
        this.projectProgress = this._calcProjectProgress();

        const { startDate } = this.dashboardReport;
        const startDateMoment = startDate ? moment(startDate) : moment();
        this.startMonth = startDateMoment.month();
        this.startYear = startDateMoment.year();
        this.beginDate = moment([this.startYear, this.startMonth, 1]);

        const { endDate } = this.dashboardReport;
        const endDateMoment = endDate ? moment(endDate) : moment().add(2, 'months');
        this.endMonth = endDateMoment.month();
        this.endYear = endDateMoment.year();
        this.endDate = endDateMoment;

        this.showToday = this.beginDate.isBefore(this.today) && this.today.isBefore(this.endDate);
        this.projectMonths = []; // a collection of objects with month strings and dates

        // get the width of the graph area need to timeout to wait until dom is loaded
        this.calcTotalTime();
    }

    initializeGraph() {
        setTimeout(() => {
            this.graphContainer = this.window.document.getElementById('graphContainer');

            if (this.timelines.length > 0) {
                this._calcMonths();
                this.showTodayLabel = true;

                this.timelineMap = {};
                this.timelines.forEach((timeline) => {
                    this.timelineMap[timeline.id] = timeline;
                });

                let timelineOrder = null;

                this.dashboardProject.dashboardTimelineOrder.forEach((id) => {

                    if (!timelineOrder) {
                        timelineOrder = [];
                    }

                    timelineOrder.push(this.timelineMap[id]);
                });

                // should be::: this.visibleTimelines || this.timelines
                this.visibleTimelines = [].concat(timelineOrder || this.timelines);

                // difference between this.visibleTimelines and this.timelines
                this.hiddenTimelines = _.differenceBy(this.timelines, this.visibleTimelines, 'id');

            }
        });
    }

    _calcProjectProgress() {
        const total = this.dashboardReport?.documentCount
            / (this.dashboardReport?.documentCount + this.dashboardReport?.placeholderCount);
        const result = Math.round(total * 100);

        return result;
    }

    calcTotalTime() {
        // start at the begin month on the first and end on last month on last day
        // this.endDate = this.endMonth === 1 ? Moment([this.endYear, this.endMonth, 28])
        // : Moment([this.endYear, this.endMonth, 30])
        const beginDate = moment([this.startYear, this.startMonth, 1]);
        const endDate = moment([this.endYear, this.endMonth, 1]).endOf('month');

        this.projectDuration = Number(endDate.diff(beginDate, 'years', true).toFixed(6));

    }

    calcProjectedWidth(item) {

        let beginDate = moment(item.projectedStartDate);
        let endDate = moment(item.projectedEndDate);

        if (this.beginDate.diff(item.projectedStartDate) > 0) {
            beginDate = moment(this.beginDate);
        }

        if (this.endDate.diff(endDate) < 0) {
            endDate = moment(this.endDate);
        }

        const duration = Number(endDate.diff(beginDate, 'years', true).toFixed(6));

        // calculate the width of the line in proportion to the whole duration
        const proportion = ((duration / this.projectDuration) * 100.0).toFixed(6);
        return `${proportion.toString()}%`;
    }

    numCalcActualWidth(item) {

        // calc the actual dates if they exist, if not, use the projected as the begin and end
        let beginDate = item.actualStartDate ? moment(item.actualStartDate) : moment(item.projectedStartDate);
        if (this.beginDate.diff(beginDate) > 0) {
            beginDate = moment(this.beginDate);
        }

        let endDate; // item.actualEndDate? Moment(item.actualEndDate) : Moment(item.projectedEndDate);

        if (item.isFinished && item.actualEndDate) {
            endDate = moment(item.actualEndDate);
        }
        else {

            if (!item.actualEndDate) {
                endDate = moment(item.projectedEndDate);
            }
            else if (moment(item.actualEndDate).isBefore(moment(item.projectedEndDate))) {
                endDate = moment(item.projectedEndDate);
            }
            else {
                endDate = moment(item.actualEndDate);
            }

            /**
             * Make sure an 'active' timeline's endDate never goes past the today line
             */
            if ((item.isFinished || item.inProgress) && endDate > moment()) {
                endDate = moment();
            }

            /**
             * New request: If in progress, no matter what, make sure end date is today
             */
            if (item.inProgress) {
                endDate = moment();
            }
        }

        if (this.beginDate.diff(endDate) > 0) {
            return 0;
        }

        if (this.endDate.diff(endDate) < 0) {
            endDate = moment(this.endDate);
        }

        const duration = endDate.diff(beginDate, 'years', true).toFixed(6);

        // calculate the width of the line in proportion to the whole duration
        return ((duration / this.projectDuration) * 100.0).toFixed(6);
    }

    calcActualWidth(item) {

        // calculate the width of the line in proportion to the whole duration
        const proportion = this.numCalcActualWidth(item);
        return `${proportion.toString()}%`;
    }

    numCalcLeftOffset(itemDate) {

        const endDate = moment(itemDate);
        const duration = Number(endDate.diff(this.beginDate, 'years', true).toFixed(6));
        // calculate the width of the line in proportion to the whole
        return ((duration / this.projectDuration) * 100.0).toFixed(6);
    }

    calcLeftOffset(itemDate) {

        if (this.beginDate.diff(itemDate) > 0) {
            itemDate = moment(this.beginDate);
        }

        // calculate the width of the line in proportion to the whole
        const proportion = this.numCalcLeftOffset(itemDate);
        return `${proportion.toString()}%`;
    }

    outOfVisibleScope(item) {
        const itemStartDate = item.actualStartDate || item.projectedStartDate;
        const itemEndDate = item.actualEndDate || item.projectedEndDate;

        if (this.beginDate.diff(itemEndDate) > 0
            || this.endDate.diff(itemStartDate) < 0) {

            return true;
        }


        return false;

    }

    projectedLine(item) {
        return item.noProjections
            || (this.beginDate.diff(item.projectedStartDate) > 0 && this.endDate.diff(item.projectedEndDate) < 0);
    }

    leftProjectedLine(item) {
        if (item.projectedStartDate) {
            return item.noProjections
                || this.beginDate.diff(item.projectedStartDate) > 0
                || this.endDate.diff(item.projectedStartDate) < 0;
        }

        return true;

    }

    rightProjectedLine(item) {
        if (item.projectedEndDate) {
            return item.noProjections
                || this.endDate.diff(item.projectedEndDate) < 0
                || this.beginDate.diff(item.projectedEndDate) > 0;
        }

        return true;

    }

    calcTodayOffset(isFinalText) {
        if (isFinalText) {
            // have to calculate proper offset for final text bc of spacing issues in position absoluteness
            // if(this.graphContainer) {
            //     return this.graphContainer.clientWidth - 100 + 'px';
            // } else {
            //     return '100%';
            // }
        }
        else {
            const { beginDate } = this;
            const endDate = this.today;
            const duration = Number(endDate.diff(beginDate, 'years', true).toFixed(6));
            // calculate the width of the line in proportion to the whole
            const proportion = ((duration / this.projectDuration) * 100.0).toFixed(6);
            return `${proportion.toString()}%`;
        }
    }

    calcTodayWidth(isFinalText) {
        if (isFinalText) {
            return this.graphContainer ? `${(this.graphContainer.getBoundingClientRect().height + 30).toString()}px` : '0px';
        }

        return this.graphContainer ? `${this.graphContainer.getBoundingClientRect().height.toString()}px` : '0px';
    }

    _calcMonths() {

        // clear out the months
        this.projectMonths = [];
        this.clalculatingMonths = true;

        const { beginDate, endDate } = this;
        const beginMonth = beginDate.month();
        const beginYear = beginDate.year();
        const endMonth = endDate.month();
        const endYear = endDate.year();

        let duration = (endDate.diff(beginDate, 'years', true));

        if (duration < 1) {
            if ((endYear - beginYear) > 0) {
                duration = 1; // needs to be at least 1 if we cross years
            }
        }

        for (let i = 0; i <= duration; i += 1) {
            const year = (beginYear + i);
            for (let j = 0; j <= 12 * duration; j += 1) {

                const month = (beginMonth + j) % 12;
                const monthLabel = {
                    id: `${month}${year}`,
                    month: _.find(this.MONTHS, { number: (beginMonth + j) % 12 }).abbrev,
                    year
                };
                if (year === endYear && year === beginYear) {

                    if (month >= beginMonth && month <= endMonth) {
                        this.projectMonths.push({
                            monthLabel,
                            date: moment([year, month, 1])
                        });
                    }
                }

                else if (year === endYear) {

                    if (month <= endMonth) {
                        this.projectMonths.push({
                            monthLabel,
                            date: moment([year, month, 1])
                        });
                    }
                }

                else if (year === beginYear) {

                    if (month >= beginMonth) {
                        this.projectMonths.push({
                            monthLabel,
                            date: moment([year, month, 1])
                        });
                    }
                }

                else {

                    this.projectMonths.push({
                        monthLabel,
                        date: moment([year, month, 1])
                    });
                }
            }
        }

        // remove duplicates from the months
        this.projectMonths = _.uniqBy(this.projectMonths, 'monthLabel.id');
        this.projectMonths = _.sortBy(this.projectMonths, 'date');
        this._calcMonthCluster();
    }

    _calcMonthCluster() {

        // the space that will be alotted for each month label
        const spacing = 90;
        // determine how many labels can be placed for the months
        const clusterRatio = Math.floor(this.graphContainer.getBoundingClientRect().width / spacing);
        const totalMonths = this.projectMonths.length;
        let factor = Math.ceil(totalMonths / clusterRatio);
        // if (factor < 1) {
        //     factor = 1; //show all months since there is enough space
        // }
        const tempArray = [];
        if (factor === 4) {
            factor = 5;
        }

        this.projectMonths.forEach((month, index) => {

            if (index % factor === 0 || (index === totalMonths - 1)) {
                // if we wanted to always include the endpoint
                tempArray.push(month);
            }
        });
        this.projectMonths = tempArray;

        this.projectMonths.forEach((month) => {
            const left = this.calcMonthOffset(month.date);
            const top = this.calcTodayWidth(false);
            month.left = left;
            month.top = top;
        });
        this.clalculatingMonths = false;
    }


    calcMonthOffset(month) {

        // need to always start at the beginning of the month  date: Moment([this.beginYear, this.beginMonth, 1])
        const beginDate = moment([this.startYear, this.startMonth, 1]);
        const endDate = month;
        const duration = endDate.diff(beginDate, 'years', true).toFixed(6);
        // calculate the width of the line in proportion to the whole - a little off with icons added so remove a few percent
        const proportion = ((duration / this.projectDuration) * 100 - 2).toFixed(6);
        return `${proportion.toString()}%`;
    }

    setDates() {

        // calc the new dates
        this.beginDate = moment([this.startYear, this.startMonth, 1]);
        this.endDate = this.endMonth === 1
            ? moment([this.endYear, this.endMonth, 28]) : moment([this.endYear, this.endMonth, 30]);
        // re-calculate all widths and offsets
        this.calcTotalTime();
        this._calcMonths();

        // show today line?
        this.showToday = this.beginDate.isBefore(this.today) && this.today.isBefore(this.endDate);
    }

    setControlToggle() {
        this.showControl = !this.showControl;
    }

    validateDates() {

        const beginDate = moment([this.startYear, this.startMonth, 1]);
        const endDate = moment([this.endYear, this.endMonth, 1]).endOf('month');

        return beginDate.isBefore(endDate);
    }

    selectProject(project) {
        this.dashboardProject = project;
        this.$state.go('app.team.dashboard-timelines', {
            teamId: this.currentTeam.id,
            projectId: project.id
        });
    }

    toggleLegend() {
        this.showLegend = !this.showLegend;
    }

    goToDetailView(timeline) {
        this.$state.go('app.team.dashboard-timeline-detail', {
            teamId: this.currentTeam.id,
            projectId: timeline.projectId,
            timelineId: timeline.id
        });
    }

    get isDropdownDisabled(): boolean {
        return !this.dashboardProject;
    }

    canViewProjectsAndTimelines() {
        return this.Teams.canViewProjectsAndTimelines();
    }

    manageProject() {
        if (!this.canViewProjectsAndTimelines()) {
            return;
        }

        this.$state.go('app.team.manage-project', {
            teamId: this.currentTeam.id,
            projectId: this.dashboardProject.id
        });
    }

    canUpdateProject() {
        return (this.currentTeam.permissions && this.currentTeam.permissions.updateProjects);
    }

    updateVisibility($event) {
        this.visibleTimelines = $event.visibleTimelines;
        this.hiddenTimelines = $event.hiddenTimelines;
    }

    /**
     * Updates the project with the Ids of this.visibleTimelines
     */
    saveTimelineSettings() {

        if (this.canUpdateProject()) {

            // ids of visibleTimelines
            this.dashboardProject.dashboardTimelineOrder = this.visibleTimelines.map((it) => it.id);

            // save to server
            this.Projects.editProject(this.dashboardProject.teamId, this.dashboardProject)
                .subscribe(
                    () => this.Notifications.success(`Project '${this.dashboardProject.name}' updated!`),
                    ({ error }: ApiError) => {
                        this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                    }
                );
        }
    }
}
