import { TRAINING_STATUSES } from '@florencehealthcare/florence-constants/lib/user-roles';
import { RoleChipDetails } from '../role-chip-details/role-chip-details';

export class TeamOwnerRoleChipDetails extends RoleChipDetails {
    constructor() {
        super(
            'Team Owner',
            true,
            TRAINING_STATUSES.NOT_NEEDED
        );
    }
}
