import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { TeamStudyRole } from '@app/components/teams/components/manage-study-roles/manage-study-roles.types';
import { StudyRoleIdName, StudyTeamPreview } from '../study-roles.types';

@Injectable()
export class StudyRolesApiService {
    constructor(private http: HttpClient) { }

    private readonly url = {
        base: (teamId: string): string => `/api/teams/${teamId}/study-roles`,
        studyRole: (teamId: string, teamStudyRoleId: string): string => `/api/teams/${teamId}/study-roles/${teamStudyRoleId}`,
        studyRoles: '/api/constants/study-roles',
        filePreview: (teamId: string) => `/api/teams/${teamId}/study-roles/file-preview`
    }

    private isLoading = new BehaviorSubject<boolean>(null);
    isLoading$ = this.isLoading.asObservable();

    private isLoadingStudyRoles = new BehaviorSubject<boolean>(null);
    isLoadingStudyRoles$ = this.isLoadingStudyRoles.asObservable();

    getStudyRoles$(): Observable<StudyRoleIdName[]> {
        this.isLoading.next(true);

        return this.http.get<StudyRoleIdName[]>(this.url.studyRoles)
            .pipe(
                tap(() => this.isLoading.next(false)),
                catchError((error) => {
                    this.isLoading.next(false);
                    return throwError(() => error);
                }),
                retry(3)
            );
    }

    getTeamStudyRoles$(teamId: string): Observable<TeamStudyRole[]> {
        this.isLoadingStudyRoles.next(true);
        return this.http.get<TeamStudyRole[]>(this.url.base(teamId))
            .pipe(
                tap(() => this.isLoadingStudyRoles.next(false)),
                catchError((error) => {
                    this.isLoadingStudyRoles.next(false);
                    return throwError(() => error);
                }),
                retry(3)
            );
    }

    getFilePreview$(teamId: string, file: File) {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('filename', file.name);
        formData.append('size', `${file.size}`);

        const headers = new HttpHeaders();
        headers.set('Content-Type', undefined);

        return this.http.post<StudyTeamPreview[]>(this.url.filePreview(teamId), formData, { headers });
    }

    createTeamStudyRoles$(teamId: string, names: string[]): Observable<TeamStudyRole[]> {
        return this.http.post<TeamStudyRole[]>(this.url.base(teamId), { names });
    }

    updateTeamStudyRole$(teamId: string, teamStudyRoleId: string, name: string): Observable<TeamStudyRole> {
        return this.http.patch<TeamStudyRole>(this.url.studyRole(teamId, teamStudyRoleId), { name });
    }

    deleteTeamStudyRole$(teamId: string, teamStudyRoleId: string): Observable<{ acknowledged: boolean, deletedCount: number }> {
        return this.http.delete<{ acknowledged: boolean, deletedCount: number }>(this.url.studyRole(teamId, teamStudyRoleId));
    }
}
