import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { interval, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
// Info: This service was previously idle directive that keeps lastEventTime variable synced local storage and listens
// for global user activity, but I could not find the location where lastEventTime was used besides in the service itself.
export class IdleMonitorService implements OnDestroy {
  private timeout: any;
  private intervalSub: Subscription;
  private timestamp = this.$window.localStorage.getItem('lastEventTime');

  private readonly debounceTime = 500;
  private readonly checkInterval = 5000;

  constructor(private idle: Idle,
    @Inject('Window') private $window: Window) {}

  init(): void {
      this.initEventListeners();
      this.startPeriodicCheck();
  }

  private initEventListeners(): void {
      // Listen for global user activity (mousemove, keydown)
      ['mousemove', 'keydown'].forEach((event) => {
          this.$window.addEventListener(event, () => this.resetLastEventTime());
      });
  }

  private startPeriodicCheck(): void {
      // Periodically check for activity from other tabs
      this.intervalSub = interval(this.checkInterval).subscribe(() => {
          const lastEventTime = this.$window.localStorage.getItem('lastEventTime');
          if (lastEventTime && lastEventTime > this.timestamp) {
              this.idle.watch(); // Reactivate idle watch on activity
              this.timestamp = lastEventTime;
          }
      });
  }

  private resetLastEventTime(): void {
      if (this.timeout) {
          clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
          const currentTime = new Date().getTime().toString();
          this.$window.localStorage.setItem('lastEventTime', currentTime);
      }, this.debounceTime);
  }

  ngOnDestroy(): void {
      // Clean up subscriptions and timeouts
      if (this.intervalSub) {
          this.intervalSub.unsubscribe();
      }
      if (this.timeout) {
          clearTimeout(this.timeout);
      }

      ['mousemove', 'keydown'].forEach((event) => {
          this.$window.removeEventListener(event, () => this.resetLastEventTime());
      });
  }
}
