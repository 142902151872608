'use strict';

module.exports = {
    monitorReviewEventTypes: {
        COMMENT: 'comment',
        APPROVAL: 'approval',
        REVIEWED: 'reviewed',
        STATUS_CHANGE: 'statusChange',
        NEW_DOCUMENT_VERSION: 'newDocumentVersion'
    }
};
