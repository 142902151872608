import { Subject } from 'rxjs';
import {
    Component, Input, Output, EventEmitter, SimpleChanges, OnChanges
} from '@angular/core';
import {
    AbstractControl, FormBuilder, Validators
} from '@angular/forms';
import * as _ from 'lodash';
import {
    Study, Team, ApiError
} from '@app/shared/models';
import { REGEX, MESSAGES } from '@app/core/constants';
import { notBlank } from '@app/core/form-validators';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { TeamService } from '@app/shared/teams/team.service';
import { BindersService } from '@app/shared/binders/binders.service';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { sortBrowseTreeLexicographically } from '@app/widgets/sort/sort-browse-tree-lexicographically.util';
import { EntityPathService } from '@app/widgets/entity/entity-path.service';
import { StateService } from '@uirouter/core';

import { VirtualTreeFlatNode } from '@app/widgets/virtual-tree/virtual-tree.component.types';
import template from './study-form.component.html';
import styles from './study-form.component.scss';
import { SelectLocationComponent } from '../select-location/select-location.component';
import { BrowseParams } from '../../../../shared/teams/teams.service.types';
import {
    TeamConfiguration, CreateForm, RawForm, UpdateForm
} from '../../study-manager.service.types';

@Component({
    selector: 'study-form',
    template,
    styles: [String(styles)]
})
export class StudyFormComponent implements OnChanges {
    @Input() study: Study;
    @Input() teamId: string;
    @Input() teamConfig: TeamConfiguration;
    @Input() createMode: boolean;
    @Input() uniqueProtocolIds: string[];
    @Output() submit = new EventEmitter<{study: Partial<Study>; next: boolean; uniqueProtocolIdChanged: boolean}>();
    @Output() next = new EventEmitter<{study: Partial<CreateForm>;}>();
    @Output() progress = new EventEmitter<number>();
    $destroy = new Subject<void>();
    _currentTeam: Team;
    loading = false;
    studyFields: string[];
    studyTypes: string[];
    locationOptions: string[] = ['Create New Binder', 'Select Existing Binder or Folder'];
    rootContainerPath = null;
    validationErrMessage = MESSAGES.validNameMessage;
    validationOptionalErrMessage = MESSAGES.validOptionalNameMessage;
    validationDuplicateUPIDErrMessage = 'Unique Protocol ID field must be unique';
    studyForm = this.fb.group({
        uniqueProtocolId: ['', [
            Validators.required,
            Validators.pattern(REGEX.names),
            Validators.maxLength(250),
            notBlank
        ]],
        nickname: ['', [
            Validators.required,
            Validators.pattern(REGEX.names),
            Validators.maxLength(250),
            notBlank
        ]],
        studyType: ['Select Study Type', [
            Validators.required,
            Validators.pattern(REGEX.names),
            Validators.maxLength(250),
            notBlank
        ]],
        sponsor: ['', [
            Validators.required,
            Validators.pattern(REGEX.names),
            Validators.maxLength(250),
            notBlank
        ]],
        location: [this.locationOptions[0]],
        selectedLocation: [''],
        cro: ['', [
            Validators.pattern(REGEX.names),
            Validators.maxLength(250)
        ]],
        device: ['', [
            Validators.pattern(REGEX.names),
            Validators.maxLength(250)
        ]],
        condition: ['', [
            Validators.pattern(REGEX.names),
            Validators.maxLength(250)
        ]],
        siteId: [{ value: '', disabled: true }],
        siteName: ['', [
            Validators.pattern(REGEX.names),
            Validators.maxLength(250)
        ]],
        pi: ['', [
            Validators.pattern(REGEX.names),
            Validators.maxLength(250)
        ]],
        coordinator: ['', [
            Validators.pattern(REGEX.names),
            Validators.maxLength(250)
        ]],
        customSiteId: ['', [
            Validators.pattern(REGEX.names),
            Validators.maxLength(250)
        ]],
        siteCountry: ['', [
            Validators.pattern(REGEX.names),
            Validators.maxLength(250)
        ]]
    });

    constructor(
        private fb: FormBuilder,
        private Modals: ModalsService,
        private Notifications: NotificationsService,
        private CurrentSession: CurrentSessionService,
        private Binders: BindersService,
        private Teams: TeamService,
        private EntityPath: EntityPathService,
        private $state: StateService
    ) {}

    ngOnInit(): void {
        if (!this.teamConfig) {
            this.$state.go('app.team.study-manager', { teamId: this.teamId });
        }
        this.studyTypes = this.teamConfig.teamConfiguration.studies.map((study) => study.type);
        this.getStudyFields();
        this._currentTeam = this.CurrentSession.getCurrentTeam();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.study) {
            this.loading = changes.study.currentValue === undefined;
            this.initializeForm(changes.study.currentValue);
        }
    }

    onSave(): void {
        if (!this.loading && this.studyForm.valid) {
            const formValues: Partial<RawForm> = this.getTrimmedStudyValues();
            const study: UpdateForm = {
                uniqueProtocolId: formValues.uniqueProtocolId,
                nickname: formValues.nickname,
                studyType: formValues.studyType,
                sponsor: formValues.sponsor,
                device: formValues.device,
                condition: formValues.condition,
                cro: formValues.cro
            };
            this.next.emit({
                study
            });
            this.studyForm.markAsPristine();
        }
    }

    onNext(): void {
        if (!this.loading && this.studyForm.valid) {
            const formValues: Partial<RawForm> = this.getTrimmedStudyValues();
            const study: CreateForm = {
                uniqueProtocolId: formValues.uniqueProtocolId,
                nickname: formValues.nickname,
                studyType: formValues.studyType,
                sponsor: formValues.sponsor,
                device: formValues.device,
                condition: formValues.condition,
                cro: formValues.cro,
                rootContainerPath: this.rootContainerPath,
                customSiteId: formValues.customSiteId,
                siteName: formValues.siteName,
                pi: formValues.pi,
                coordinator: formValues.coordinator,
                siteCountry: formValues.siteCountry,
                location: this.ctrls.location.value
            };
            this.next.emit({
                study
            });
            this.studyForm.markAsPristine();
        }
    }

    selectLocation(): void {
        const modalRef = this.Modals.show(SelectLocationComponent, {
            class: 'modal-lg',
            initialState: {
                teamId: this.teamId,
                loadRoot: () => this.Binders
                    .getBinders(this._currentTeam.id, { includeArchived: false })
                    .toPromise()
                    .then((binders) => sortByLexicographically(binders, 'name'))
                    .catch(this._handleErrorNotification),
                loadItem: (params = {} as BrowseParams) => this.Teams
                    .browse(this._currentTeam.id, { ...params, includeArchived: false })
                    .toPromise()
                    .then((data) => sortBrowseTreeLexicographically(data, 'name'))
            }
        });

        modalRef.content.rootContainer.subscribe((params) => {
            this._setRootContainerPath(params.rootContainer, modalRef);
        });
    }

    _setRootContainerPath(rootContainer: VirtualTreeFlatNode, modalRef): void {
        if (rootContainer.type === 'binder') {
            this.rootContainerPath = rootContainer.name;
            modalRef.hide();
        }
        else {
            this.EntityPath.getPathForEntity(rootContainer)
                .toPromise()
                .then((data) => {
                    const binder = data[1];
                    this.rootContainerPath = `${binder.name}/${rootContainer.path}`;
                })
                .then(modalRef.hide());
        }
    }

    onReset(): void {
        this.studyForm.reset();
        this.initializeForm(this.study);
    }

    get ctrls(): { [key: string]: AbstractControl } {
        return this.studyForm.controls;
    }

    get useRootContainerPath(): boolean {
        return this.ctrls.location.value === this.locationOptions[1];
    }

    get studyTypePlaceholderDisabled(): boolean {
        return this.ctrls.studyType.value !== 'Select Study Type';
    }

    ctrlHasError(ctrl: AbstractControl): boolean {
        return ctrl.invalid && (ctrl.dirty || ctrl.touched);
    }

    uniqueProtocolIdIsDuplicate(ctrl: AbstractControl): boolean {
        const takenUPIDs = this.uniqueProtocolIds && this.study
            ? this.uniqueProtocolIds.filter((v) => v !== this.study.uniqueProtocolId)
            : this.uniqueProtocolIds;

        return this.uniqueProtocolIds && takenUPIDs.includes(ctrl.value?.trim());
    }

    private initializeForm(study: Partial<CreateForm>): void {
        if (study && Object.keys(study).length) {
            if (study.rootContainerPath) {
                this.rootContainerPath = study.rootContainerPath;
            }
            this.studyForm.patchValue(study);
            this.studyForm.markAsPristine();
        }
    }

    private getTrimmedStudyValues(): Study | Record<string, unknown> {
        const study = _.pick(this.studyForm.getRawValue(), this.studyFields);
        const trimmedStudy = Object.keys(study).reduce((acc, current) => {
            acc[current] = study[current]?.trim();
            return acc;
        }, {});
        return trimmedStudy;
    }

    private getStudyFields(): void {
        this.studyFields = Object.keys(this.ctrls)
            .filter((k) => this.ctrls[k] !== this.ctrls.teamSipIntegrationLink);
    }

    nextDisabled(): boolean {
        const res = this.uniqueProtocolIdIsDuplicate(this.ctrls.uniqueProtocolId)
            || this.ctrls.studyType.value === 'Select Study Type'
            || (this.ctrls.location.value === this.locationOptions[1] && this.rootContainerPath === null)
            || this.studyForm.invalid;
        return res;
    }

    nextDisabledUpdateStudy(): boolean {
        return this.ctrls.nickname.value === ''
            || this.ctrls.studyType.value === 'Select Study Type'
            || this.ctrls.sponsor.value === ''
            || this.studyForm.invalid;
    }

    cancelCreateStudyFlow() {
        this.$state.go('app.team.study-manager', { teamId: this.teamId });
    }

    _handleErrorNotification({ error }: ApiError): void {
        const { message } = error;
        if (message) {
            return this.Notifications.error(message);
        }

        return this.Notifications.unexpectedError();
    }

    changeStep(n: number) {
        this.progress.emit(n);
    }

    cancelUpdateStudyFlow() {
        this.$state.go('app.team.study-manager', { teamId: this.teamId });
    }
}
