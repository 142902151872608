import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SamlConfig } from '@app/shared/models';

@Injectable()
export class SingleSignOnService {
    readonly url = {
        metadata: (teamId: string): string => `/api/saml/teams/${teamId}/metadata`,
        samlConfig: (teamId: string): string => `/api/saml/teams/${teamId}/config`
    }

    constructor(private http: HttpClient, @Inject('Window') private window: Window) {}

    getConfig(teamId: string): Observable<SamlConfig> {
        return this.http.get<SamlConfig>(this.url.samlConfig(teamId));
    }

    updateConfig(teamId: string, samlConfig: SamlConfig): Observable<SamlConfig> {
        return this.http.patch<SamlConfig>(`${this.url.samlConfig(teamId)}/${samlConfig.id}`, samlConfig);
    }

    downloadMetadata(teamId: string): void {
        this.window.open(this.url.metadata(teamId), '_blank');
    }
}
