export enum DownloadStatuses {
    pending = 'Pending',
    complete = 'Complete',
    downloaded = 'Downloaded',
    failed = 'Failed'
}

export enum DownloadTypes {
    auditTrail = 'Audit Trail',
    accessDatesReport = 'Access Dates Report',
    report = 'Report',
    document = 'Document',
    folder = 'Folder',
    binder = 'Binder',
    shortcut = 'Shortcut',
    placeholder = 'Placeholder',
    multipleDocuments = 'Multiple Documents',
    multipleFolders = 'Multiple Folders',
    multiplePlaceholders = 'Multiple Placeholders',
    multipleShortcuts = 'Multiple Shortcuts',
    file = 'File'
}

export interface Download {
    id: string;
    userId: string;
    teamId: string;
    filename: string;
    filenameCanonical: string;
    ext: string;
    extCanonical: string;
    status: DownloadStatuses;
    failureReason: string;
    type: DownloadTypes;
    file: {
        fileId: string;
        md5: string;
    };
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
}
