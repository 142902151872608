import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { qcReviewStatuses } from '@florencehealthcare/florence-constants/lib/qc-reviews';
import template from './qc-review-details.component.html';
import styles from './qc-review-details.component.scss';

@Component({
    selector: 'qc-review-details',
    template,
    styles: [String(styles)]
})

export class QcReviewDetailsComponent {
    @Input() doc;
    qcStatusMap = new Map([
        [qcReviewStatuses.PENDING_REVIEW, 'Pending QC Review'],
        [qcReviewStatuses.IN_REVIEW, 'In QC Review'],
        [qcReviewStatuses.APPROVED, 'QC Review Approved'],
        [qcReviewStatuses.DECLINED, 'QC Review Declined'],
        [qcReviewStatuses.REJECTED, 'QC Review Rejected'],
        [qcReviewStatuses.IN_REJECTION_REVIEW, 'In Rejection Review'],
        [qcReviewStatuses.REJECTION_ACCEPTED, 'QC Review Rejected']
    ]);

    constructor(
        public modal: BsModalRef
    ) {}

    dismissModal(): void {
        this.modal.hide();
    }

}
