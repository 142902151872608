import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

import template from './checkbox-tristate.component.html';
import styles from './checkbox-tristate.component.scss';

@Component({
    selector: 'checkbox-tristate',
    template,
    styles: [String(styles)]
})
export class CheckboxTristateComponent {
    @Input() state: 0 | 1 | 2;
    @Input() muted = false;
    @Input() disabled = false;
    @Input() label: string;
    @Output() check = new EventEmitter<void>();

    handleClick(event?: KeyboardEvent|MouseEvent): void {
        if (this.disabled) {
            return;
        }
        event && event.preventDefault();
        this.check.emit();
    }
}
