import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { CurrentSessionService } from '@app/core/current-session.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { BindersService } from '@app/shared/binders/binders.service';
import { TeamService } from '@app/shared/teams/team.service';
import { BrowseParams } from '@app/shared/teams/teams.service.types';
import {
    ApiError, Binder, BinderTreeSelectionItem, BrowseTree, RelatedObjectRef, Team
} from '@app/shared/models';
import { sortBrowseTreeLexicographically } from '@app/widgets/sort/sort-browse-tree-lexicographically.util';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { VirtualTreeSelectionMode } from '@app/widgets/virtual-tree/virtual-tree.component.types';

import template from './announcement-form.component.html';

@Component({
    selector: 'announcement-form',
    template
})
export class AnnouncementFormComponent implements OnInit, OnChanges {
    @Input() maxAnnouncementTitleLength: number;
    @Input() maxAnnouncementMessageLength: number;
    @Input() announcementForm: FormGroup;
    @Input() announcementRelatedObjects: RelatedObjectRef[];
    @Output() relatedObjectsChanged = new EventEmitter<RelatedObjectRef[]>();

    $destroy = new Subject<void>();

    loadingRoot = false;
    selectedItems: { id: string; type: string }[] = [];
    selectionMode = VirtualTreeSelectionMode.MULTI_SELECT;
    binders: Binder[];
    currentTeam: Team;

    constructor(
        private CurrentSession: CurrentSessionService,
        private Binders: BindersService,
        private Teams: TeamService,
        private Notifications: NotificationsService
    ) { }

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.loadingRoot = true;
        this.Binders.getBinders(this.currentTeam.id, { includeArchived: false })
            .pipe(takeUntil(this.$destroy))
            .subscribe(
                (binders) => {
                    this.binders = sortByLexicographically(binders, 'name');
                    this.loadingRoot = false;
                },
                this.handleErrorNotification
            );
        this.assignSelectedItems();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.announcementRelatedObjects && changes.announcementRelatedObjects.currentValue) {
            this.assignSelectedItems();
        }
    }

    ngOnDestroy(): void {
        this.$destroy.next();
        this.$destroy.complete();
    }

    get ctrls(): { [key: string]: AbstractControl } {
        return this.announcementForm.controls;
    }

    private assignSelectedItems(): void {
        if (!this.announcementRelatedObjects) {
            return;
        }

        this.selectedItems = this.announcementRelatedObjects.map((item) => ({
            id: item.objectId,
            type: item.type
        }));
    }

    isItemSelectable(): boolean {
        return true;
    }

    loadItem = (params: BrowseParams): ng.IPromise<BrowseTree> => {
        params.includeDocs = true;
        params.includeArchived = false;
        return this.Teams.browse(this.currentTeam.id, params)
            .toPromise()
            .then((data: BrowseTree) => {
                return sortBrowseTreeLexicographically(data, 'name');
            });
    }

    onSelectionChange = ($event: { selectedItems: BinderTreeSelectionItem[] }): void => {
        this.announcementRelatedObjects = $event.selectedItems.map((item) => ({
            objectId: item.id,
            type: item.type
        }));
        this.relatedObjectsChanged.emit(this.announcementRelatedObjects);
    }

    private handleErrorNotification({ error }: ApiError): void {
        const { message } = error;
        if (message) {
            return this.Notifications.error(message);
        }

        return this.Notifications.unexpectedError();
    }
}
