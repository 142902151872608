import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './remove-qc-review-cycle.component.html';
import styles from './remove-qc-review-cycle.component.scss';

@Component({
    selector: 'remove-qc-review-cycle',
    template,
    styles: [String(styles)]
})

export class RemoveQcReviewCycleComponent {
    @Input() index;
    @Input() totalReviews;
    @Output() deleteQcReviewCycle = new EventEmitter<{ onSuccess:() => void; onError: () => void;}>();

    isProcessing = false;

    constructor(
        public modal: BsModalRef
    ) {}

    onDeleteQcReviewCycle(): void {
        this.isProcessing = true;
        this.deleteQcReviewCycle.emit({
            onError: () => {
                this.isProcessing = false;
                this.modal.hide();
            },
            onSuccess: () => {
                this.isProcessing = false;
                this.modal.hide();
            }
        });
    }

    cancel(): void {
        this.modal.hide();
    }

}
