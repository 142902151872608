import { SORT } from '@app/core/constants';
import { StateService } from '@uirouter/core';
import { Component, OnInit } from '@angular/core';
import { AdapterService } from '@app/shared/adapter/adapter.service';
import { TeamService } from '@app/shared/teams/team.service';
import { ALLOWED_PREVIEW_FILE_TYPES } from '@florencehealthcare/florence-constants/lib/documents';
import { ProjectsService } from '@app/shared/projects/projects.service';
import {
    ApiError, BrowseNode, browseNodeIsDocument, Crumb, Project, Team, Timeline
} from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { forkJoin } from 'rxjs';
import template from './dashboard-timeline-detail-page.component.html';
import { Item } from '../timeline-detail-page/timeline-detail-page.component.types';
@Component({
    selector: 'dashboard-timelines-detail',
    template
})
export class DashboardTimelineDetailPageComponent implements OnInit {
    SORT = SORT;
    project: Project;
    items: Item[];
    timeline:Timeline;
    loadingData = false
    currentTeam: Team;
    allTimelines: Timeline[];
    adaptedItems: Item[] = [];
    sortName: string;
    crumbs: Crumb[];

    constructor(
        private $state: StateService,
        private adapterService: AdapterService,
        private teamService: TeamService,
        private projectsService: ProjectsService,
        private currentSessionService: CurrentSessionService,
        private notificationsService: NotificationsService

    ) {}

    ngOnInit():void {
        this.currentTeam = this.currentSessionService.getCurrentTeam();
        const stateParams = this.$state.params;
        this.loadingData = true;

        forkJoin({
            project: this.projectsService.getProject(this.currentTeam.id, stateParams.projectId),
            timelines: this.projectsService.getProjectTimelines(this.currentTeam.id, stateParams.projectId),
            timeline: this.projectsService.getFullTimeline(this.currentTeam.id, stateParams.timelineId)
        }).subscribe({
            next: (data) => {
                this.project = data.project;
                this.allTimelines = data.timelines;
                this.timeline = data.timeline;

                const items = this.timeline.items || [];
                this.adaptedItems = items.map((item) => this.adapterService.adaptBinderItem(item)) as unknown as Item[];
                this.updateSort('name', false);
                this.crumbs = this.getCrumbs();
                this.loadingData = false;
            },
            error: ({ error }: ApiError) => {
                this.notificationsService.error(error.message || 'Server Error: Please contact your administrator.');
            }
        });
    }

    private getValueByPath(obj, path) {
        return path.split('.').reduce((acc, key) => acc && acc[key], obj);
    }

    updateSort(sortName: string, isReversed?: boolean): void {
        this.sortName = sortName;
        this.SORT.set(sortName, isReversed);

        let sortedItems = this.adaptedItems.sort((a, b) => {
            return this.SORT.naturalSort({ value: this.getValueByPath(a, sortName) },
                { value: this.getValueByPath(b, sortName) });
        });

        if (this.SORT.isReversed) {
            sortedItems = sortedItems.reverse();
        }

        this.adaptedItems = [...sortedItems];
    }

    sortByType(sortedItems: BrowseNode[] | Item[]) {
        const itemsWithoutPreview = [];
        const indexesToDelete = [];
        sortedItems.forEach((item, index) => {
            if (
                browseNodeIsDocument(item)
                && item.type === 'document'
                && item.subType === 'content'
                && (ALLOWED_PREVIEW_FILE_TYPES.indexOf(item.ext?.toLowerCase()) === -1)

            ) {
                itemsWithoutPreview.push(item);
                indexesToDelete.push(index);
            }
        });

        for (let i = indexesToDelete.length - 1; i >= 0; i -= 1) {
            sortedItems.splice(indexesToDelete[i], 1);
        }

        const sortedDocsWithoutPreview = itemsWithoutPreview.sort((a, b) => {
            return this.SORT.naturalSort({ value: a.name }, { value: b.name });
        });

        if (this.SORT.isReversed) {
            sortedDocsWithoutPreview.reverse();
        }

        const finalSortOrder: BrowseNode[] = this.SORT.isReversed
            ? [...sortedDocsWithoutPreview, ...sortedItems]
            : [...sortedItems, ...sortedDocsWithoutPreview];

        this.adaptedItems = [...finalSortOrder] as unknown as Item[];
    }

    goToItem(item): void {

        if (item.type !== 'document') {
            return;
        }

        this.$state.go('app.team.document-show', {
            teamId: item.teamId,
            documentId: item.id,
            version: item.version
        });
    }

    selectTimeline(timeline): void {
        const destination = {
            teamId: timeline.teamId,
            projectId: timeline.projectId,
            timelineId: timeline.id
        };
        this.$state.go('app.team.dashboard-timeline-detail', destination);
    }

    canManageTimeline() {
        return this.teamService.canManageIndividualTimeline();
    }

    manageTimeline() {
        if (!this.canManageTimeline()) {
            return;
        }

        this.$state.go('app.team.manage-project-timeline', {
            teamId: this.timeline.teamId,
            projectId: this.timeline.projectId,
            timelineId: this.timeline.id
        });
    }

    private getCrumbs(): Crumb[] {
        return [
            {
                name: `Project Dashboard: ${this.project.name}`,
                stateName: 'app.team.dashboard-timelines',
                stateParams: {
                    teamId: this.timeline.teamId,
                    projectId: this.timeline.projectId
                }
            },
            {
                name: this.timeline.name
            }
        ];
    }

}
