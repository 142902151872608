import { AppConfigService } from '@app/shared/app-config/app-config.service';
import { Component, OnInit } from '@angular/core';
import { User } from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';
import { StateService } from '@uirouter/core';
import { REGEX } from '@app/core/constants';
import { UsersService } from '@app/shared/users/users.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { Auth0Service } from '@app/shared/sessions/auth0.service';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './password-expired.component.html';
import styles from './password-expired.component.scss';


@Component({
    selector: 'password-expired',
    template,
    styles: [String(styles)]
})
export class PasswordExpiredComponent implements OnInit {

    public user: User;
    private stateLocation = 'app.select-team';
    public isProcessing = false;
    public emailPattern = REGEX.email;
    public passwordPattern = REGEX.password;
    public showTooltip = {
        confirmation: false,
        oldPassword: false,
        newPassword: false
    };

    constructor(
        public modal: BsModalRef,
        private $state: StateService,
        private CurrentSession: CurrentSessionService,
        private UsersService: UsersService,
        private AppConfigService: AppConfigService,
        private Notifications: NotificationsService,
        private Auth0Service: Auth0Service,
        private ApiError: ApiErrorsService
    ) {}

    ngOnInit() {
        this.user = this.CurrentSession.getCurrentUser();
    }

    cancel(): void {
        if (!this.isProcessing) {
            this.$state.go(this.stateLocation);
        }
        this.modal.hide();
    }

    sendResetLink(): void {
        this.UsersService.resetPasswordAuth0(this.user.emailCanonical).subscribe(() => {
            this.Notifications.success('Reset password link has been sent to your email, as security measure you will be logout!');
            this.modal.hide();
            timer(1000).pipe(take(1)).subscribe(() => {
                this.Auth0Service.logout({
                    returnTo: `${this.AppConfigService.config.authHost}/#/sign-in?email=${this.user.emailCanonical}`
                });
            });
        },
        (error) => {
            this.ApiError.handleError(error);
        });
    }
}
