import * as _ from 'lodash';
import { Document, User, Task } from '@app/shared/models';
import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { REGEX } from '@app/core/constants';
import { notBlank } from '@app/core/form-validators';
import template from './task-form.component.html';
import style from './task-form.component.scss';
import { TaskDestroyEvent, TaskSaveEvent } from './task-form.component.types';

@Component({
    template,
    styles: [String(style)]
})
export class TaskFormComponent implements OnInit {
    @Input() task: Task;
    @Input() users: User[];
    @Input() doc: Document;
    @Input() isCreate: boolean;
    @Output() onSave = new EventEmitter<TaskSaveEvent>();
    @Output() onDestroy = new EventEmitter<TaskDestroyEvent>();
    originalTask: Task;
    taskForm: FormGroup;
    isProcessing: boolean;
    isDocumentLocked: boolean;
    showDeleteCount: number;
    originalStatus: string;
    filterText: string;
    formErrors: { name?: boolean } = {};
    originalAssignees = new Set<string>();
    filteredUsers: User[];
    maxNameLength = 250;
    maxMessageLength = 2000;
    maxCommentLength = 1000;

    constructor(
        private modal: BsModalRef,
        private fb: FormBuilder
    ) {
        this.isProcessing = false;
        this.isDocumentLocked = false;
        this.showDeleteCount = 0;
        this.filterText = '';
    }

    ngOnInit(): void {
        if (this.task) {
            this.task.assignedTo.forEach((user: User) => this.originalAssignees.add(user.id));
            this.originalTask = _.cloneDeep(this.task);
        }
        else {
            this.task = {
                assignedTo: [],
                notifyCreatorOnClosed: true,
                notifyCreatorOnCompletion: true,
                comment: '',
                message: '',
                type: 'task',
                targetType: 'document'
            } as unknown as Task;
        }
        this.task = _.assign(this.task, { targetId: this.doc.id }, this.task);
        this.users = this.task.availableAssignees || this.users;
        delete this.task.availableAssignees;
        this.originalStatus = this.task.status;
        this.isDocumentLocked = this.doc.isLocked;
        this.initForm();
        this.getFilteredUsers();
    }

    assignUser(user: User): void {
        user.assigned = true;
        this.task.assignedTo.push(user);

        if (!this.isCreate) {
            const availUsersIndex = _.findIndex(this.users, { id: user.id });
            if (availUsersIndex > -1) {
                this.users.splice(availUsersIndex, 1);
                this.getFilteredUsers();
            }
        }
    }

    removeUser(user: User): void {
        user.assigned = false;

        const assignedToIndex = _.findIndex(this.task.assignedTo, { id: user.id });
        if (assignedToIndex > -1) {
            this.task.assignedTo.splice(assignedToIndex, 1);
            if (!this.isCreate) {
                this.users.push(user);
                this.getFilteredUsers();
            }
        }
    }

    setStatus(newStatus: Task['status']): void {
        this.task.status = newStatus;
    }

    toggleRemindUser(user: User): void {
        user.remind = !user.remind;
    }

    updateFilterText(text: string): void {
        this.filterText = text;
        this.getFilteredUsers();
    }

    getFilteredUsers() {
        if (!this.filterText) {
            this.filteredUsers = this.users.slice().sort((a, b) => a.name.localeCompare(b.name));
        }

        this.filteredUsers = this.users.filter((user) => user?.name?.toLowerCase().includes(this.filterText.toLowerCase()));

        this.filteredUsers.sort((a, b) => a.name.localeCompare(b.name));
    }

    onLabelClick(event: Event, type: string) {
        if (!this.isCreate) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        if (type === 'completion') {
            this.toggleNotifyOnCompletion();
        }
        else if (type === 'closed') {
            this.toggleNotifyOnClose();
        }
    }

    toggleNotifyOnCompletion(): void {
        if (!this.isCreate) {
            return;
        }
        this.task.notifyCreatorOnCompletion = !this.task.notifyCreatorOnCompletion;
    }

    toggleNotifyOnClose(): void {
        if (!this.isCreate) {
            return;
        }
        this.task.notifyCreatorOnClosed = !this.task.notifyCreatorOnClosed;
    }

    cancel(): void {
        this.modal.hide();
    }

    getSubmitData() {
        const remind = [];
        const assign = [];
        this.task.assignedTo?.forEach((user) => {
            if (user.assigned && !this.originalAssignees.has(user.id)) {
                assign.push(user.id);
            }
            else if (user.remind) {
                remind.push(user);
            }
        });
        const remove = !this.isCreate ? this.users.reduce((arr, user) => {
            if (this.originalAssignees.has(user.id) && !assign.includes(user.id)) {
                arr.push(user.id);
            }
            return arr;
        }, []) : [];

        return {
            ...this.task,
            remove,
            remind,
            assign
        };
    }

    save(): void {
        const task = this.getSubmitData();
        this.isProcessing = true;
        this.onSave.emit({
            task,
            reason: '',
            onSuccess: () => {
                this.modal.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        });
    }

    deleteTask(): void {
        // only delete after clicking twice, the first time show the delete warning message
        this.showDeleteCount += 1;
        if (this.showDeleteCount > 1) {
            this.isProcessing = true;
            this.onDestroy.emit({
                task: this.task,
                reason: '',
                onSuccess: () => {
                    this.modal.hide();
                },
                onError: () => {
                    this.isProcessing = false;
                    this.modal.hide();
                }
            });
        }
    }

    isTaskEdited(): boolean {
        if (this.isCreate) {
            return false;
        }

        return this.originalTask.name !== this.task.name
        || this.originalTask.status !== this.task.status
        || (this.task.comment && this.originalTask.comment !== this.task.comment)
        || this.task.assignedTo.length !== this.originalAssignees.size
        || !this.task.assignedTo.every((user) => this.originalAssignees.has(user.id))
        || this.task.assignedTo.some((user) => user.remind === true);
    }

    isSaveDisabled(): boolean {
        return this.taskForm.invalid
            || this.isProcessing
            || this.originalStatus === 'Closed'
            || (!this.isCreate && !this.isTaskEdited());
    }

    private initForm(): void {

        this.taskForm = this.fb.group({
            name: [
                { value: this.task?.name || '', disabled: this.task?.status === 'Closed' },
                [Validators.required, Validators.maxLength(250), notBlank]
            ],
            message: [
                { value: this.task?.message || '', disabled: !this.isCreate || this.task?.status === 'Closed' },
                [Validators.pattern(REGEX.longText), Validators.maxLength(2000)]
            ],
            comment: [
                { value: this.task?.comment || '', disabled: this.isCreate || this.task?.status === 'Closed' || this.isDocumentLocked },
                [Validators.pattern(REGEX.longText), Validators.maxLength(1000)]
            ],
            notifyCreatorOnCompletion: [{ value: this.task?.notifyCreatorOnCompletion || false, disabled: !this.isCreate }],
            notifyCreatorOnClosed: [{ value: this.task?.notifyCreatorOnClosed || false, disabled: !this.isCreate }]
        });
        this.taskForm.valueChanges.subscribe((values) => {
            this.task.name = values.name;
            this.task.comment = values.comment;
            if (this.isCreate) {
                this.task.message = values.message;
            }
        });
    }
}
