import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Team } from '@app/shared/models';
import { TeamTimeoutPeriod } from '../../containers/manage-team-settings/manage-team-settings.component.types';

import template from './manage-team-security.component.html';

export interface UpdateEvent {
    mfaPolicyEnabled?: boolean;
    inactivityTimeout?: number;
}

@Component({
    selector: 'manage-team-security',
    template
})
export class ManageTeamSecurityComponent implements OnInit {
    @Input() team: Team;
    @Input() loadingTeamSecuritySave: boolean;
    @Input() loadingTimeperiodSave: boolean;
    @Output() update = new EventEmitter<UpdateEvent>();
    timeoutPeriodOptions: typeof TeamTimeoutPeriod;
    public isMfaEnabledChanged: boolean;
    public isTeamOutPeriodChanged: boolean;

    mfaEnabled = new FormControl(false, Validators.required);
    timeoutPeriod = new FormControl(TeamTimeoutPeriod.default, Validators.required);

    ngOnInit() {
        this.timeoutPeriodOptions = TeamTimeoutPeriod;

        this.mfaEnabled.valueChanges.subscribe((mfaFieldStatus) => {
            this.isMfaEnabledChanged = this.team.settings.mfaPolicy.isEnabled === mfaFieldStatus;
        });

        this.timeoutPeriod.valueChanges.subscribe((timeOutPeriodStatus) => {
            this.isTeamOutPeriodChanged = this.team.settings.inactivityTimeout === timeOutPeriodStatus;
        });

    }

    onChange(): void {
        const updatedFields = this._getUpdatedFields({
            mfaPolicyEnabled: this.mfaEnabled.value,
            inactivityTimeout: this.timeoutPeriod.value
        });
        this.update.emit(updatedFields);
    }

    _getUpdatedFields(fields:UpdateEvent): UpdateEvent {
        const {
            mfaPolicyEnabled,
            inactivityTimeout
        } = fields;
        const updatedFields: UpdateEvent = {};

        if (this.team.settings.mfaPolicy.isEnabled !== mfaPolicyEnabled) {
            updatedFields.mfaPolicyEnabled = mfaPolicyEnabled;
            this.isMfaEnabledChanged = true;
        }
        if (this.team.settings.inactivityTimeout !== inactivityTimeout) {
            updatedFields.inactivityTimeout = inactivityTimeout;
            this.isTeamOutPeriodChanged = true;
        }
        return updatedFields;
    }
}
