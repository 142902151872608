import { StateProvider } from '@uirouter/angularjs';

const teamInboxRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.team-inbox', {
        url: '/team-inbox',
        views: {
            '': 'teamInbox'
        }
    });

    $stateProvider.state('app.team.inbox-document-preview', {
        url: '/inbox/documents/:documentId/versions/:version',
        params: {
            documentId: null,
            version: null
        },
        views: {
            '': 'documentInboxPreview'
        }
    });
};

teamInboxRoute.$inject = ['$stateProvider'];
export default teamInboxRoute;
