import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnDestroy
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CurrentSessionService } from '@app/core/current-session.service';
import {
    Folder,
    Team,
    Binder,
    NamesPreview,
    ApiError
} from '@app/shared/models';
import { BindersService } from '@app/shared/binders/binders.service';
import { FoldersService } from '@app/shared/folders/folders.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { MESSAGES, NAMES_PREVIEW_LIMIT } from '@app/core/constants';

import template from './create-names-list.component.html';
import styles from './create-names-list.component.scss';

@Component({
    selector: 'create-names-list',
    template,
    styles: [String(styles)]
})
export class CreateNamesListComponent implements OnInit, OnDestroy {
    @Input() namesLocation: Binder | Folder;
    @Input() customNames: string[];
    @Output() dismiss = new EventEmitter<string[]>();
    $destroy = new Subject<void>();

    readonly forbiddenChars = MESSAGES.forbiddenChars;
    isProcessing = false;
    previewContainsErrors = false;
    currentTeam: Team;
    namesInput = new FormControl('');
    namesPreview: NamesPreview = [];

    constructor(
        private CurrentSession: CurrentSessionService,
        private Binders: BindersService,
        private Folders: FoldersService,
        private Notifications: NotificationsService
    ) { }

    get shouldDisableSave(): boolean {
        return this.isProcessing
            || this.previewContainsErrors
            || !this.namesPreview.length
            || !this.namesInput.value.trim();
    }

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        if (this.customNames && this.customNames.length) {
            this.namesInput.setValue(this.customNames.join('\n'));
        }
        this.namesInput.valueChanges
            .pipe(takeUntil(this.$destroy))
            .subscribe(() => {
                this.previewContainsErrors = false;
                this.namesPreview = [];
            });
    }

    dismissModal(): void {
        if (this.isProcessing) {
            return;
        }
        this.dismiss.emit(null);
    }

    preview(): void {
        this.isProcessing = true;
        const names = this.getParsedInput();
        if (this.namesLocation.type === 'binder') {
            this.Binders
                .getNamesPreview({
                    teamId: this.currentTeam.id,
                    binderId: this.namesLocation.id,
                    names
                }).toPromise()
                .then(this.onGetPreviewSuccess, this.onGetPreviewFail);
        }
        else if (this.namesLocation.type === 'folder') {
            this.Folders.getNamesPreview({
                teamId: this.currentTeam.id,
                binderId: this.namesLocation.binderId,
                folderId: this.namesLocation.id,
                names
            }).toPromise()
                .then(this.onGetPreviewSuccess, this.onGetPreviewFail);
        }
    }

    save(): void {
        if (this.isProcessing) {
            return;
        }
        const names = this.getParsedInput();
        this.dismiss.emit(names);
    }

    clear(): void {
        this.namesInput.setValue('');
        this.previewContainsErrors = false;
        this.namesPreview = [];
    }

    ngOnDestroy(): void {
        this.$destroy.next();
        this.$destroy.complete();
    }

    private handleErrorNotification({ error }: ApiError): void {
        const { message } = error;
        if (message) {
            return this.Notifications.error(message);
        }

        return this.Notifications.unexpectedError();
    }

    private getParsedInput(): string[] {
        const names: string[] = this.namesInput.value.split('\n');
        return names.map((name) => name.trim()).filter((name) => !!name);
    }

    private onGetPreviewSuccess = (namesPreview: NamesPreview): void => {
        this.namesPreview = namesPreview;
        const previewErrors = namesPreview.filter((np) => !!np.error);
        this.previewContainsErrors = previewErrors.length > 0 || namesPreview.length > NAMES_PREVIEW_LIMIT;
        this.isProcessing = false;
    }

    private onGetPreviewFail = ({ error }): void => {
        this.namesPreview = [];
        this.isProcessing = false;
        this.previewContainsErrors = false;
        this.handleErrorNotification(error);
    }
}
