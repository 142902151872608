import { Component } from '@angular/core';
import template from './wizard-skeleton.component.html';
import styles from './wizard-skeleton.component.scss';

@Component({
    selector: 'wizard-skeleton',
    template,
    styles: [String(styles)]
})
export class WizardSkeletonComponent {}
