import { UrlService } from '@uirouter/angularjs';

const routerConfig = (
    $locationProvider: ng.ILocationProvider,
    $urlServiceProvider: UrlService
): void => {
    // Prevent UI-Router from automatically intercepting URL changes when it starts;
    $urlServiceProvider.deferIntercept();
    $urlServiceProvider.rules.otherwise('/');
    $urlServiceProvider.config.strictMode(false);

    $locationProvider.hashPrefix('');
};

routerConfig.$inject = [
    '$locationProvider',
    '$urlServiceProvider'
];

export default routerConfig;
