import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { ScrollingModule, ScrollDispatcher, ViewportRuler } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '@app/shared/shared.module';

import { ConnectedTeamsComponent } from './containers/connected-teams/connected-teams.component';
import { ConnectedTeamsListComponent } from './components/connected-teams-list/connected-teams-list.component';
import { ConnectedTeamsApiService } from './services/connected-teams/connected-teams-api.service';
import { ConnectedTeamsStateService } from './services/connected-teams/connected-teams-state.service';

@NgModule({
    imports: [
        WidgetsModule,
        UIRouterModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        TooltipModule.forRoot(),
        ScrollingModule
    ],
    declarations: [
        ConnectedTeamsComponent,
        ConnectedTeamsListComponent
    ],
    entryComponents: [
        ConnectedTeamsComponent
    ],
    providers: [
        ConnectedTeamsApiService,
        ConnectedTeamsStateService,
        ScrollDispatcher,
        ViewportRuler
    ]
})
export class ConnectedTeamsModule { }
