import {
    Component, Input, Output, EventEmitter, SimpleChanges, Inject
} from '@angular/core';
import { Team } from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';
import template from './connected-teams-list.component.html';
import styles from './connected-teams-list.component.scss';
import { SortObject, SortBy, ConnectedTeamsList } from '../../services/connected-teams.types';

@Component({
    selector: 'connected-teams-list',
    template,
    styles: [String(styles)]
})
export class ConnectedTeamsListComponent {
    @Input() connectedTeamsList: ConnectedTeamsList[] = [];
    @Input() isLoading = false;
    @Input() hasFilterValue = false;
    @Input() sorts: SortObject[] = [{ by: 'teamName', direction: 'DESC' }];
    @Output() nextSort = new EventEmitter();

    public loadingSizes = ['40%', '45%', '30%'];
    public currentTeam: Team;
    public sortMap = {
        teamName: 'DESC'
    };

    public showConnectedTeams = false;

    constructor(
        private CurrentSession: CurrentSessionService,
        @Inject('Window') private window: Window
    ) {}

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.sorts && changes.sorts.currentValue) {
            this.sortMap = changes.sorts.currentValue.reduce(
                (acc, sortObject) => {
                    acc[sortObject.by] = sortObject.direction;
                    return acc;
                },
                {}
            );
        }
        if (changes.connectedTeamsList.currentValue.length > 0) {
            this.showConnectedTeams = true;
        }
        else {
            this.showConnectedTeams = false;
        }
    }

    handleSort(by: SortBy): void {
        const direction = this.sortMap[by];
        const oppositeDirection = direction === 'ASC' ? 'DESC' : 'ASC';
        this.nextSort.emit({ by, direction: oppositeDirection });
    }

    contactSupport(): void {
        this.window.open('https://florencehealthcare.zendesk.com/hc/en-us', '_blank');
    }
}
