import { StateProvider } from '@uirouter/angularjs';
import { ROUTES } from '@app/core/constants';

const bindersRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state(ROUTES.binders, {
        url: '/binders',
        views: {
            '': 'binderIndex'
        }
    });
};

bindersRoute.$inject = ['$stateProvider'];

export default bindersRoute;
