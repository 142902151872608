import { SORT } from '@app/core/constants';

export type ColumnConfig = {
    renderType: 'type' | 'name' | 'date' | 'text' | 'username' | 'checkbox' | 'location';
    headerName: string;
    displayProperty: DisplayProperty;
    latestVersion?: boolean;
    isSortable?: boolean;
    sortProperty?: string;
    widthValue?: number;
    widthClass: string;
    headerClasses?: string;
    cellContainerClasses?: string;
    cellClasses?: string;
    tooltipPlacement?: string;
    dateOnly?: boolean;
    checkboxCellStateProperty?: string;
    headerCheckboxState?: number;
    truncate?: boolean;
    onCheckboxCellClick?: () => void;
    onCheckboxHeaderClick?: () => void;
};

export type DisplayProperty = string | { [key: string]: string }

export type Pagination = {
    pageSize: number;
    pageNumber: number;
    totalItemCount: number;
    maxSize: number;
    showBoundary: boolean;
    onPageChange: () => boolean;
};

export type Sorter = {
    SORT: typeof SORT;
    defaultSortProperty: string;
    onSortChange?: (SORT) => void;
}

export enum MonitorReviewStatus {
    'Approved' = 'Approved',
    'Reviewed' = 'Reviewed'
}
