import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@app/shared/models';
import { Observable } from 'rxjs';
import {
    GetPotentialQCReviewersParams,
    QcReview,
    QcReviewRequestParam,
    QcReviewAcceptParams,
    QcReviewDeclineParam,
    RejectQcReviewParams,
    RemoveQcReviewCycleParams,
    DeleteQcReviewParams,
    AddQcReviewParams,
    ChangeReviewerQcReviewParams,
    ApproveOrReviewFindingsQcReviewParams,
    RejectionAgreementQcReviewParams
} from './qc-review.services.types';

@Injectable({ providedIn: 'root' })
export class QCReviewService {
    readonly url = {
        qcReviewRequest: (documentId, version): string => `/api/documents/${documentId}/versions/${version}/qc-review`,
        potentialQCReviewers: (documentId: string, documentVersion: number) => `/api/documents/${documentId}/versions/${documentVersion}/qc-potential-reviewers`,
        qcReviewAccept: (documentId : string, documentVersion: number) => `/api/documents/${documentId}/versions/${documentVersion}/qc-review-accept`,
        qcReviewDecline: (documentId : string, version: number) => `api/documents/${documentId}/versions/${version}/qc-review-decline`,
        approveQcReview: (documentId : string, version: number) => `/api/documents/${documentId}/versions/${version}/qc-review-approve`,
        rejectQcReview: (documentId : string, version: number) => `/api/documents/${documentId}/versions/${version}/qc-review-reject`,
        removeQcCycle: (documentId : string, version: number) => `/api/documents/${documentId}/versions/${version}/qc-remove-cycle`,
        deleteQcReview: (documentId : string, documentVersion: number) => `/api/documents/${documentId}/versions/${documentVersion}/qc-review-delete`,
        addQcReview: (documentId : string, documentVersion: number) => `/api/documents/${documentId}/versions/${documentVersion}/qc-review-add`,
        changeReviewerQcReview: (documentId : string, documentVersion: number) => `/api/documents/${documentId}/versions/${documentVersion}/qc-review-change-reviewer`,
        rejectionDisagreeQcReview: (documentId : string, documentVersion: number) => `/api/documents/${documentId}/versions/${documentVersion}/qc-rejection-disagree`,
        rejectionAgreeQcReview: (documentId : string, documentVersion: number) => `/api/documents/${documentId}/versions/${documentVersion}/qc-rejection-agree`
    };

    constructor(
        private http: HttpClient
    ) { }

    requestQCReview(params : QcReviewRequestParam): Observable<QcReview> {
        const body = {
            reviews: params.reviews,
            teamId: params.teamId
        };

        return this.http.post<QcReview>(this.url.qcReviewRequest(params.documentId, params.documentVersion), body);

    }

    getPotentialQCReviewers(params: GetPotentialQCReviewersParams): Observable<User[]> {
        const { documentId, documentVersion } = params;
        const loadUrl = this.url.potentialQCReviewers(documentId, documentVersion);

        return this.http.get<User[]>(loadUrl);
    }

    acceptQcReview(params: QcReviewAcceptParams): Observable<QcReview> {
        const { documentId, documentVersion } = params;

        const body = {
            teamId: params.teamId
        };

        return this.http.post<QcReview>(this.url.qcReviewAccept(documentId, documentVersion), body);
    }

    declineQcReview(params : QcReviewDeclineParam): Observable<QcReview> {
        const body = {
            teamId: params.teamId,
            reason: params.reason
        };

        return this.http.post<QcReview>(this.url.qcReviewDecline(params.documentId, params.documentVersion), body);
    }

    approveQcReview(params: ApproveOrReviewFindingsQcReviewParams): Observable<QcReview> {
        const body = {
            teamId: params.teamId,
            comment: params.comment
        };

        return this.http.post<QcReview>(this.url.approveQcReview(params.documentId, params.documentVersion), body);
    }

    rejectQcReview(params: RejectQcReviewParams): Observable<QcReview> {
        const body = {
            teamId: params.teamId,
            reasons: params.reasons,
            comment: params.comment
        };

        return this.http.post<QcReview>(this.url.rejectQcReview(params.documentId, params.documentVersion), body);
    }

    removeQcReviewCycle(params: RemoveQcReviewCycleParams): Observable<QcReview> {
        const {
            documentId, documentVersion, cycleId, teamId
        } = params;

        return this.http.delete<QcReview>(this.url.removeQcCycle(documentId, documentVersion), { params: { teamId, cycleId } });
    }

    deleteQcReview(params: DeleteQcReviewParams): Observable<QcReview> {
        const { teamId } = params;
        return this.http.delete<QcReview>(this.url.deleteQcReview(params.documentId, params.documentVersion),
            { params: { teamId } });
    }

    addQcReview(params: AddQcReviewParams): Observable<QcReview> {
        const body = {
            review: params.review,
            teamId: params.teamId
        };

        return this.http.patch<QcReview>(this.url.addQcReview(params.documentId, params.documentVersion), body);
    }

    changeReviewerQcReview(params: ChangeReviewerQcReviewParams): Observable<QcReview> {
        const body = {
            review: params.review,
            teamId: params.teamId,
            cycleId: params.cycleId
        };

        return this.http.patch<QcReview>(this.url.changeReviewerQcReview(params.documentId, params.documentVersion), body);
    }

    rejectionDisagreeQcReview(params: ApproveOrReviewFindingsQcReviewParams): Observable<QcReview> {
        const body = {
            teamId: params.teamId,
            comment: params.comment
        };

        return this.http.post<QcReview>(this.url.rejectionDisagreeQcReview(params.documentId, params.documentVersion), body);
    }

    rejectionAgreeQcReview(params: RejectionAgreementQcReviewParams): Observable<QcReview> {
        const body = {
            teamId: params.teamId,
            comment: params.comment
        };

        return this.http.post<QcReview>(this.url.rejectionAgreeQcReview(params.documentId, params.documentVersion), body);
    }

}
