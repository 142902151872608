import controller from './app-layout.controller';
import template from './app-layout.html';
import './app-layout.scss';

const appLayoutComponent: ng.IComponentOptions = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {
        currentUser: '<'
    }
};

export default appLayoutComponent;
