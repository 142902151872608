import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import {
    NoSignDocumentEntity,
    NoSignUserEntity,
    NoSigningPermissionsModalData
} from './no-signing-permissions.types';

import template from './no-signing-permissions.component.html';
import styles from './no-signing-permissions.component.scss';

@Component({
    selector: 'no-signing-permissions',
    styles: [String(styles)],
    template
})
export class NoSigningPermissionsComponent implements OnInit {
    @Input() data: NoSigningPermissionsModalData;

    formattedRows: (NoSignUserEntity | NoSignDocumentEntity)[];

    constructor(public modal: BsModalRef) { }

    ngOnInit(): void {
        this.formattedRows = [];
        this.data.forEach(({ document, usersWithoutSignPermissions }, indx) => {
            const groupName = `doc-itt-${indx}`;
            document.groupName = groupName;
            usersWithoutSignPermissions.forEach((user) => Object.assign(user, { groupName }));
            this.formattedRows.push(document, ...usersWithoutSignPermissions);
        });

        if (!this.formattedRows.length) {
            this.dismissModal();
        }
    }

    dismissModal(): void {
        this.modal.hide();
    }
}
