import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Timeline } from '@app/shared/models';

import template from './timeline-visibility-settings.component.html';
import styles from './timeline-visibility-settings.component.scss';

@Component({
    selector: 'timeline-visibility-settings',
    template,
    styles: [String(styles)]
})
export class TimelineVisibilitySettingsComponent {
    @Input() visibleTimelines: Timeline[];
    @Input() hiddenTimelines: Timeline[];
    @Output() visibilityUpdated = new EventEmitter<{
        visibleTimelines: Timeline[];
        hiddenTimelines: Timeline[];
    }>();

    drop(event: CdkDragDrop<Timeline[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
        else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }

        this.visibilityUpdated.emit({
            visibleTimelines: this.visibleTimelines,
            hiddenTimelines: this.hiddenTimelines
        });
    }
}
