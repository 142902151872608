import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import {
    AbstractControl, FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import { CreateRolesByTemplateParams, AssignRoleParam } from './assign-users-to-roles.types';
import style from './assign-users-to-roles.component.scss';
import template from './assign-users-to-roles.component.html';
@Component({
    selector: 'assign-users-to-roles',
    template,
    styles: [String(style)]
})
export class AssignUsersToRolesComponent implements OnInit {
  @Input() loadingResults = false;
  @Input()results: any = null;
  submittedRolesAndUsers = '';
  previewCompleted = false;
  createCompleted = false;
  auditForm: FormGroup;


  @Output() onCreate = new EventEmitter<CreateRolesByTemplateParams>();
  @Output() onPreview = new EventEmitter<CreateRolesByTemplateParams>();
  @Output() onClear = new EventEmitter<void>();

  constructor(
    private fb: FormBuilder
  ) {}


  ngOnInit(): void {
      this.initForm();
  }

  create() {
      const event = this._getParams();
      this.createCompleted = true;
      this.onCreate.emit(event);
  }

  preview() {
      const event = this._getParams();
      this.previewCompleted = true;
      this.createCompleted = false;
      this.submittedRolesAndUsers = this.auditForm.value.rolesAndUsers;
      this.onPreview.emit(event);
  }

  clear() {
      this.auditForm.patchValue({
          rolesAndUsers: ''
      });
      this.submittedRolesAndUsers = '';
      this.onClear.emit();
  }

  previewUpToDate(): boolean {
      return this.auditForm.value.rolesAndUsers === this.submittedRolesAndUsers;
  }

  previewTooltip(): string {
      if (!this.auditForm.value.rolesAndUsers) {
          return 'Roles and users field cannot be empty';
      }
  }

  clearTooltip(): string {
      if (this.clearDisabled()) {
          return 'Nothing to clear. Form and results are empty';
      }
      return 'Clear form';
  }

  clearDisabled(): boolean {
      return !this.results && !this.auditForm.value.rolesAndUsers;
  }

  createDisabled(): boolean {
      return !this.previewCompleted
      || !this.previewUpToDate()
      || this.loadingResults
      || (this.createCompleted && this.previewUpToDate());
  }

  createTooltip(): string {
      if (!this.auditForm.value.rolesAndUsers) {
          return 'Roles and users field cannot be empty';
      }
      if (!this.submittedRolesAndUsers) {
          return 'You need to run the preview before running the create action';
      }
      if (!this.previewCompleted) {
          return 'You need to run the preview before you can run the create action';
      }
      if (!this.previewUpToDate()) {
          return 'Form values have changed since the last preview. You need to run the preview with new values first and then you can run create action';
      }
      if (this.createCompleted && this.previewUpToDate()) {
          return 'Create has already been executed for these parameters';
      }
  }

  private _getParams(): CreateRolesByTemplateParams {
      return {
          roles: this._extractRoles(this.auditForm.value.rolesAndUsers)
      };
  }

  private _extractRoles(templateString: string): AssignRoleParam[] {
      const LINE_SPLITER = '\n';
      const USER_SPLITER = /[\t|;]/;
      const templateLines: string[] = templateString.split(LINE_SPLITER);

      return templateLines.reduce((parsedRoleParams: AssignRoleParam[], currentLine: string) => {
          const trimmedLine = currentLine.trim();
          if (trimmedLine !== '') {
              const [roleName, ...userEmails] = trimmedLine.split(USER_SPLITER);
              parsedRoleParams.push({
                  roleName: roleName.trim(),
                  userEmails: userEmails.map((email) => email.trim())
              });
          }
          return parsedRoleParams;
      }, []);
  }

  get formCtrls(): { [key: string]: AbstractControl } {
      return this.auditForm.controls;
  }

  private initForm(): void {
      this.auditForm = this.fb.group({
          rolesAndUsers: ['', [Validators.required, notBlank, Validators.maxLength(100000)]]
      });
  }
}
