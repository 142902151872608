import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import template from './job-title-required-change.html';
import style from './job-title-required-change.scss';
import { JobTitleRequiredChangeEvent } from './job-title-required-change.types';

@Component({
    template,
    styles: [String(style)]
})
export class JobTitleRequiredChangeComponent implements OnInit {
    @Output() dismiss = new EventEmitter<void>();
    @Output() onConfirm = new EventEmitter<JobTitleRequiredChangeEvent>();
    @Input() jobTitleRequired: boolean;
    @Input() isFolder: boolean;

    modalTitle: string;
    modalMessage: string;
    modalClickInfo: string;
    isProcessing = false;

    ngOnInit(): void {
        const type = this.isFolder ? 'Folder' : 'Binder';
        const typePlural = this.isFolder ? 'Folder(s)' : 'Binder';

        if (this.jobTitleRequired) {
            this.modalTitle = 'Add Job Title to Signature';
            this.modalMessage = `A signer's Job Title will be included in their signature once the Job Title is added to a ${type}.`;
            this.modalClickInfo = `Please click "Confirm" below to add the Job Title to the selected ${typePlural}.`;
        }
        else {
            this.modalTitle = 'Remove Job Title from Signature';
            this.modalMessage = `The Job Title will no longer be applied to document signatures if it is removed from the ${type}.`;
            this.modalClickInfo = 'Click "Remove" below to remove the Job Title.';
        }
    }

    cancel(): void {
        this.dismiss.emit();
    }

    confirm(): void {
        this.isProcessing = true;
        this.onConfirm.emit({
            onSuccess: () => this.cancel(),
            onError: () => {
                this.isProcessing = false;
            }
        });
    }
}
