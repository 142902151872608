import { Injectable } from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import * as Sentry from '@sentry/browser';
import { map } from 'rxjs/operators';
import * as Integrations from '@sentry/integrations';

import { Team, User } from '../../shared/models';
import { AppConfigService } from '../../shared/app-config/app-config.service';


type SentryUserData = {
    id: string;
    email: string;
    isConfirmed: boolean;
    isRemote: boolean;
}

@Injectable()
class SentryConfigService {
    _Sentry;
    constructor(
        private CurrentSession: CurrentSessionService,
        private appConfigService: AppConfigService
    ) {}

    init() {
        Sentry.init({
            dsn: this.appConfigService.config.sentryDsn,
            environment: this.appConfigService.config.environment,
            release: this.appConfigService.config.release,
            integrations: [new Integrations.Angular()]
        });
        this._Sentry = Sentry;
        this.CurrentSession.currentUser$
            .pipe(map((u: Partial<User>) => {
                const sentryUser: SentryUserData = {
                    ...(u && u.id) && { id: u.id },
                    ...(u && u.email) && { email: u.email },
                    ...(u && u.isConfirmed) && { isConfirmed: u.isConfirmed },
                    ...(u && u.isRemote) && { isRemote: u.isRemote }
                };
                return sentryUser;
            }))
            .subscribe(this._setUser.bind(this));

        this.CurrentSession.currentTeam$
            .subscribe(this._setTeam.bind(this));
    }

    private _setUser(user: User): void {
        this._Sentry.configureScope((scope) => {
            scope.setUser(user);
        });
    }

    private _setTeam(obj: Team): void {
        this._Sentry.configureScope((scope) => {
            scope.setTag('teamId', obj ? obj.id : null);
        });
    }
}

export default SentryConfigService;
