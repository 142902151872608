import {
    Component, EventEmitter, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import template from './cursor-pagination.component.html';
import style from './cursor-pagination.component.scss';
import { Cursor, CursorPageChangedEvent } from './cursor-pagination.component.types';

@Component({
    selector: 'cursor-pagination',
    template,
    styles: [String(style)]
})
export class CursorPaginationComponent implements OnInit, OnDestroy {
    @Input() next?: Cursor;
    @Input() paginationReset$: Observable<undefined>;

    @Output() pageChanged = new EventEmitter<CursorPageChangedEvent>();

    page = 1;
    current?: Cursor = null;
    previousCursors: Cursor[] = [];
    isFirstPage = true;
    pageIsLoading = false;
    subscription: Subscription;

    ngOnInit(): void {
        this.subscription = this.paginationReset$.subscribe(() => {
            this.resetPagination();
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    previousPage(): void {
        if (this.page === 1 || this.pageIsLoading) {
            return;
        }

        this.setPage(this.page - 1);
        const cursor = this.previousCursors.pop();
        this.current = cursor;
        this.onPageChanged(cursor);
    }

    nextPage(): void {
        if (!this.next || this.pageIsLoading) {
            return;
        }

        this.setPage(this.page + 1);
        this.previousCursors.push(this.current);
        this.current = this.next;
        this.onPageChanged(this.next);
    }

    private onPageChanged(cursor: Cursor): void {
        this.pageIsLoading = true;
        this.pageChanged.emit({
            cursor,
            onSuccess: () => {
                this.pageIsLoading = false;
            }
        });
    }

    private resetPagination(): void {
        this.setPage(1);
        this.current = null;
        this.previousCursors = [];
        this.onPageChanged(null);
    }

    private setPage(page: number): void {
        this.page = page;
        this.isFirstPage = this.page === 1;
    }
}
