import { Input, Component, OnInit } from '@angular/core';
import { Document, DocumentSubTypes } from '@app/shared/models';

@Component({
    selector: 'previous-version-warning',
    template: `
    <page-warning *ngIf="isPreviousVersion">
        You are about to take action on an old version (V{{version}}) of this document. The most current version is V{{lastVersion}}.
    </page-warning>
    `
})
export class PreviousVersionWarningComponent implements OnInit {
    @Input() doc: Document;
    isPreviousVersion: boolean;
    lastVersion: number;
    version: number;

    ngOnInit(): void {
        this.version = this.doc.originalDocumentVersion || this.doc.version;
        this.lastVersion = this.doc.versions ? Math.max(...this.doc.versions.map(({ number }) => number)) : this.doc.version;

        const isShortcut = this.doc.subType === DocumentSubTypes.shortcut;
        this.isPreviousVersion = isShortcut
            ? this.doc.originalDocumentVersion < this.lastVersion
            : this.doc.version < this.lastVersion;
    }
}
