import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MESSAGES, REGEX, ROLE_BLINDED_STATUSES } from '@app/core/constants';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './create-role.component.html';
import styles from './create-role.component.scss';
import { CreateRoleEvent } from './create-role.component.types';
@Component({
    selector: 'create-role',
    template,
    styles: [String(styles)]
})
export class CreateRoleComponent implements OnInit {
  isProcessing = false;
  namePattern = REGEX.names;
  nameMessage = MESSAGES.validNameMessage;
  @Input() areBlindedUnblindedRolesEnabled = false;
  @Output() onCreate = new EventEmitter<CreateRoleEvent>();
  createRoleForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private modal: BsModalRef
  ) {
      this.createRoleForm = this.fb.group({
          name: ['', [Validators.required, Validators.pattern(this.namePattern)]],
          blindedStatus: ['', []]
      });
  }

  ngOnInit(): void {
      this.initForm();
  }

  preventClosingIfProcessing(event: Event): void {
      if (this.isProcessing) {
          event.preventDefault();
      }
  }

  cancel(): void {
      this.modal.hide();
  }

  create(): void {
      this.isProcessing = true;
      const roleObjToSave = {
          name: this.createRoleForm.value.name,
          ...this.areBlindedUnblindedRolesEnabled && { blindedStatus: this.createRoleForm.value.blindedStatus }
      };
      const createEvent = {
          ...roleObjToSave,
          onError: () => {
              this.isProcessing = false;
          },
          onSuccess: () => {
              this.modal.hide();
          }
      };
      this.onCreate.emit(createEvent);
  }

  initForm(): void {
      this.createRoleForm = this.fb.group({
          name: ['', [Validators.required, Validators.pattern(this.namePattern)]],
          blindedStatus: [ROLE_BLINDED_STATUSES.NO_MARK, []]
      });
  }

  get roleBlindedStatuses() {
      return ROLE_BLINDED_STATUSES;
  }
}
