'use strict';

/*
    When the user filters binders and folders in the virtual tree component,
    we aim to set a debounce time for entering characters.
    Otherwise, a request would be sent for each character.
    This will also affect component tests, so tick() function should be set to
    debounce time in all components that are connected to the virtual tree component.
*/
exports.FILTERS = {
    DEBOUNCE_TIME: 400
};
