import {
    Component, Input, Output, EventEmitter
} from '@angular/core';

import { User, Role } from '@app/shared/models';
import { TeamService } from '@app/shared/teams/team.service';

import template from './user-role-select.component.html';
import styles from './user-role-select.component.scss';

@Component({
    selector: 'user-role-select',
    template,
    styles: [String(styles)]
})
export class UserRoleSelect {
    @Output() itemSelected = new EventEmitter<User | Role>();
    @Output() itemUnselected = new EventEmitter<User | Role>();
    @Input() currentSelection: (User | Role)[] = [];

    constructor(private Teams: TeamService) { }

    searchRolesAndUsers = (query: string): ng.IPromise<(User | Role)[]> => {
        return this.Teams.searchRolesAndUsers(query)
            .toPromise()
            .then((results) => results.filter((item) => !this.itemIsAlreadySelected(item)));
    }

    selectItem = (item: User | Role): void => {
        this.itemSelected.emit(item);
    }

    removeItem(item: User | Role): void {
        this.itemUnselected.emit(item);
    }

    private itemIsAlreadySelected(item: User | Role): boolean {
        return this.currentSelection.some((existingItem) => (
            existingItem.type === item.type && existingItem.id === item.id
        ));
    }
}
