import { NgModule } from '@angular/core';
import { UserTrainingApiService } from './user-training-api/user-training-api.service';
import { UserTrainingService } from './user-training.service';

@NgModule({
    providers: [
        UserTrainingService,
        UserTrainingApiService
    ]
})
export class UserTrainingModule {}
