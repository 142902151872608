import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from '../models/app-config.model';
@Injectable({
    providedIn: 'root'
})
export class AppConfigService {

    public config: AppConfig;

    constructor(
        private handler: HttpBackend
    ) {}

    init(): Observable<void> {
        const httpClient = new HttpClient(this.handler);
        return httpClient.get<AppConfig>('/api/app-config')
            .pipe(
                tap((config) => {
                    this.config = config;
                }),
                map(() => undefined)
            );
    }
}
