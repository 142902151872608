import {
    AfterViewInit,
    ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit
} from '@angular/core';
import { ControlContainer, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DOA_LOG_TEMPLATE_STEP_FORM_CONTROL_NAMES, DOA_LOG_TEMPLATE_STEP_NAMES } from '../doa-log-template.types';
import { AbstractWizardStep } from '../../../../../widgets/wizard/utils/abstract-wizard-step/abstract-wizard-step.component';
import template from './doa-log-template-details-step.component.html';
import styles from '../doa-log-template-steps.style.scss';

@Component({
    selector: 'doa-log-template-details-step',
    template,
    styles: [String(styles)],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line no-use-before-define
    providers: [{ provide: AbstractWizardStep, useExisting: DoaLogTemplateDetailsStepComponent }]
})
export class DoaLogTemplateDetailsStepComponent extends AbstractWizardStep implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        @Inject(ControlContainer) controlContainer: ControlContainer,
        private formBuilder: FormBuilder
    ) {
        super(controlContainer);
    }

    readonly stepFriendlyName = DOA_LOG_TEMPLATE_STEP_NAMES.LOG_DETAILS;
    readonly stepFormControlName = DOA_LOG_TEMPLATE_STEP_FORM_CONTROL_NAMES.LOG_DETAILS;

    private readonly destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.initForm();
    }

    ngAfterViewInit(): void {
        this.syncStepValidityWithFormGroupStatusChanges$().pipe(
            takeUntil(this.destroy$)
        ).subscribe();

        this.stepFormGroup.updateValueAndValidity();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    initForm(): void {
        this.parentForm.addControl(
            this.stepFormControlName,
            this.formBuilder.group({})
        );
    }
}
