import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import {
    AbstractControl, ControlContainer, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators
} from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
    filter, takeUntil, tap
} from 'rxjs/operators';
import {
    BehaviorSubject, Observable, of, Subject
} from 'rxjs';
import uuid from 'uuid';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import {
    BulkImportResponsibilitiesComponent
} from '@app/components/log-templates/components/doa-log-template/bulk-import/bulk-import-resposibilities/bulk-import-responsibilities.component';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { notBlank } from '@app/core/form-validators';
import {
    ADD_ROLE_RESPONSIBILITY_BUTTON_PLACEMENT,
    AddRoleResponsibilityButtonPlacement,
    DOA_LOG_TEMPLATE_FORM_CONTROLS,
    DOA_LOG_TEMPLATE_STEP_FORM_CONTROL_NAMES,
    DOA_LOG_TEMPLATE_STEP_NAMES
} from '../doa-log-template.types';
import {
    AbstractWizardStep
} from '../../../../../widgets/wizard/utils/abstract-wizard-step/abstract-wizard-step.component';
import template from './doa-log-template-study-responsibilities-step.component.html';
import stepStyles from '../doa-log-template-steps.style.scss';
import componentStyles from './doa-log-template-study-responsibilities-step.component.scss';
import { MESSAGES, REGEX } from '../../../../../core/constants';
import { StudyResponsibilities, StudyResponsibility, StudyRole } from '../../../../../shared/models';
import { FormUtil } from '../../../../../shared/forms/utils/form.util';
import { NotificationsService } from '../../../../../core/notifications/notifications.service';
import { LogTemplateUtil } from '../../../utils/log-template.util';

@Component({
    selector: 'doa-log-template-study-responsibilities-step',
    template,
    styles: [String(stepStyles), String(componentStyles)],
    // eslint-disable-next-line no-use-before-define
    providers: [{ provide: AbstractWizardStep, useExisting: DoaLogTemplateStudyResponsibilitiesStepComponent }],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoaLogTemplateStudyResponsibilitiesStepComponent extends AbstractWizardStep implements OnInit, OnDestroy {
    constructor(
        @Inject(ControlContainer) controlContainer: ControlContainer,
        @Inject('Window') private window: Window,
        private cdr: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private notificationsService: NotificationsService,
        private featureFlagService: FeatureFlagService,
        private Modals: ModalsService
    ) {
        super(controlContainer);
    }

    readonly MIN_RESPONSIBILITIES_LENGTH: number = 2;
    readonly MAX_RESPONSIBILITIES_LENGTH: number = 100;

    readonly buttonPlacementAbove = ADD_ROLE_RESPONSIBILITY_BUTTON_PLACEMENT.ABOVE;
    readonly buttonPlacementBelow = ADD_ROLE_RESPONSIBILITY_BUTTON_PLACEMENT.BELOW;

    @Input() studyResponsibilities?: StudyResponsibilities;
    @Input() studyRoles: StudyRole[] = [];
    @Input() isLogDocument = false;
    @Input() addResponsibilityButtonPlacement: AddRoleResponsibilityButtonPlacement = this.buttonPlacementBelow;

    @ViewChildren('responsibilitiesItemsRef') responsibilitiesItemsRef: QueryList<ElementRef>;

    readonly stepFriendlyName = DOA_LOG_TEMPLATE_STEP_NAMES.STUDY_RESPONSIBILITIES;
    readonly stepFormControlName = DOA_LOG_TEMPLATE_STEP_FORM_CONTROL_NAMES.STUDY_RESPONSIBILITIES;
    readonly responsibilitiesFormControlName = DOA_LOG_TEMPLATE_FORM_CONTROLS.RESPONSIBILITIES;
    readonly duplicateResponsibilityNameErrorMessage = 'Responsibility name is already in use';

    studyResponsibilitiesFormArray: FormArray;
    isNumberIdentifierFormControl: AbstractControl;

    formUtil = new FormUtil();
    private isDoaLogPhase2Enabled = new BehaviorSubject<boolean>(false);
    isDoaLogPhase2Enabled$ = this.isDoaLogPhase2Enabled.asObservable();

    private isBulkImportAllowed = new BehaviorSubject<boolean>(false);
    isBulkImportAllowed$ = this.isBulkImportAllowed.asObservable();

    private readonly destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.featureFlagService.getFlag(FEATURE_FLAGS.DOA_LOG_PHASE_2, false)
            .pipe(
                filter((isFlagEnabled) => isFlagEnabled !== undefined),
                tap((isFlagEnabled) => {
                    this.isDoaLogPhase2Enabled.next(isFlagEnabled);
                }),
                takeUntil(this.destroy$)
            ).subscribe();


        this.featureFlagService
            .getFlag(
                FEATURE_FLAGS.EBINDERS_BULKUPLOAD_DOALOGTEMPLATE_RESPONSIBILITIES,
                false
            )
            .pipe(
                filter((flag) => flag !== undefined),
                tap((flag) => {
                    const responsibilitiesCount = this.studyResponsibilities?.values?.length ?? 0;
                    const noResponsibilities = responsibilitiesCount === 0;

                    this.isBulkImportAllowed.next(noResponsibilities && flag);
                }),
                takeUntil(this.destroy$)
            )
            .subscribe();


        this.initFormControls();

        this.syncStepValidityWithFormGroupStatusChanges$().pipe(
            takeUntil(this.destroy$)
        ).subscribe();

        this.stepFormGroup.updateValueAndValidity();

        this.formUtil.scrollElementIntoView$().pipe(
            takeUntil(this.destroy$)
        ).subscribe();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onDrop(event: CdkDragDrop<string[]>) {
        FormUtil.reorderFormArrayFromDragDropEvent(event, this.studyResponsibilitiesFormArray);

        this.stepFormGroup.markAsDirty();
        this.studyResponsibilitiesFormArray.updateValueAndValidity();
    }

    onAddResponsibility(): void {
        this.addEmptyStudyResponsibility();

        this.formUtil.triggerScrollToElement(this.responsibilitiesItemsRef);
    }

    openBulkImportModal(): void {
        const modalRef = this.Modals.show(BulkImportResponsibilitiesComponent, {
            class: 'modal-lg',
            initialState: {
                numberOfExistingResponsibilities: this.studyResponsibilitiesFormArray.length
            }
        });

        modalRef.content.onFileImport.subscribe((importedData: any[]) => {
            this.clearEmptyResponsibilitiesBeforeImport();

            importedData.forEach((entry: any) => {
                const newResp = this.generateStudyResponsibilityFormGroup({
                    _id: uuid.v4(),
                    name: entry.responsibility,
                    isNew: true
                });

                this.studyResponsibilitiesFormArray.push(newResp);
            });

            this.studyResponsibilitiesFormArray.markAllAsTouched();
            this.studyResponsibilitiesFormArray.updateValueAndValidity();

            this.cdr.markForCheck();

            this.formUtil.triggerScrollToElement(this.responsibilitiesItemsRef);
            this.notificationsService.success(`${importedData.length} responsibilities successfully added`);
        });
    }

    private clearEmptyResponsibilitiesBeforeImport(): void {
        for (let i = this.studyResponsibilitiesFormArray.length - 1; i >= 0; i -= 1) {
            const control = this.studyResponsibilitiesFormArray.at(i);
            const { value } = control;

            if (value.name === '') {
                this.studyResponsibilitiesFormArray.removeAt(i);
            }
        }
    }

    addEmptyStudyResponsibility(): void {
        const emptyStudyResponsibility = this.generateStudyResponsibilityFormGroup();

        this.studyResponsibilitiesFormArray.push(emptyStudyResponsibility);
    }

    onRemoveResponsibility(index: number, studyResponsibility: StudyResponsibility): void {
        const isConnectedToStudyRole = this.studyRoles.some((studyRole) => {
            return studyRole.responsibilityIds.some((responsibilityId) => responsibilityId === studyResponsibility._id);
        });

        if (isConnectedToStudyRole) {
            const toastMessage = `Cannot delete ${studyResponsibility.name} because it is connected to a Study Role(s).`;
            this.notificationsService.warning(toastMessage);
            return;
        }

        this.removeFormArrayItem(index);
    }

    setIsNumberIdentifier(identifierType: 'number' | 'letter') {
        const isNumberIdentifier = identifierType === 'number';

        this.isNumberIdentifierFormControl.patchValue(isNumberIdentifier);
    }

    copyResponsibilitiesToClipboard() {
        const responsibilities = this.studyResponsibilitiesFormArray.value.map(({ name }) => name);

        const clipboardData = LogTemplateUtil.getMultiSelectOptionClipboardData(
            responsibilities,
            this.isNumberIdentifierFormControl.value
        );

        this.window.navigator.clipboard.writeText(clipboardData);
    }

    private initFormControls(): void {
        const responsibilities = this.getInitialStudyResponsibilityFormArrayValue();

        this.studyResponsibilitiesFormArray = this.formBuilder.array(
            responsibilities,
            [Validators.minLength(2), this.duplicateNamesValidator]
        );

        this.isNumberIdentifierFormControl = this.formBuilder.control(
            !!this.studyResponsibilities?.isNumberIdentifier,
            [Validators.required]
        );

        this.parentForm.addControl(
            this.stepFormControlName,
            this.formBuilder.group({
                [this.responsibilitiesFormControlName]: this.formBuilder.group({
                    values: this.studyResponsibilitiesFormArray,
                    isNumberIdentifier: this.isNumberIdentifierFormControl
                })
            })
        );
    }

    canDeleteResponsibility(responsibility): boolean {
        const hasEnoughResponsibilities = this.studyResponsibilitiesFormArray.length > this.MIN_RESPONSIBILITIES_LENGTH;
        return hasEnoughResponsibilities && (!this.isLogDocument || responsibility.value.isNew);
    }


    getFormControlErrorMessage(
        formControl: AbstractControl
    ): { message: string } {
        let message = '';

        switch (true) {
            case formControl.errors?.required:
            case formControl.errors?.blank:
                message = MESSAGES.invalidBlankMessage;
                break;
            case formControl.errors?.isDuplicate:
                message = this.duplicateResponsibilityNameErrorMessage;
                break;
            default:
                message = MESSAGES.valid100LongNameMessage;
        }

        return { message };
    }

    private getInitialStudyResponsibilityFormArrayValue(): FormGroup[] {
        let initialStudyResponsibilityFormArrayValue: FormGroup[];

        if (this.studyResponsibilities?.values?.length) {
            // if studyResponsibilities @Input() was provided, use it as the starting value
            initialStudyResponsibilityFormArrayValue = this.studyResponsibilities.values.map(
                (responsibility) => this.generateStudyResponsibilityFormGroup(responsibility)
            );
        }
        else {
            // else use a single, empty value as the starting point
            initialStudyResponsibilityFormArrayValue = [
                this.generateStudyResponsibilityFormGroup(),
                this.generateStudyResponsibilityFormGroup()
            ];
        }

        return initialStudyResponsibilityFormArrayValue;
    }


    private generateStudyResponsibilityFormGroup(
        existingStudyResponsibility?: StudyResponsibility
    ): FormGroup {
        const responsibility = existingStudyResponsibility ?? this.emptyStudyResponsibility;
        const isNew = !existingStudyResponsibility;

        return this.formBuilder.group({
            _id: responsibility._id,
            name: this.formBuilder.control(responsibility.name, [
                Validators.required,
                Validators.pattern(REGEX.names),
                notBlank,
                Validators.maxLength(100)
            ]),
            isNew
        });
    }

    private removeFormArrayItem(index: number): void {
        this.studyResponsibilitiesFormArray.removeAt(index);
        this.stepFormGroup.markAsDirty();
    }

    private get emptyStudyResponsibility(): StudyResponsibility {
        return {
            _id: uuid.v4(),
            name: ''
        };
    }


    get addResponsibilityTooltipValue() {
        return this.studyResponsibilitiesFormArray.controls.length >= this.MAX_RESPONSIBILITIES_LENGTH
            ? `Maximum number of responsibilities is ${this.MAX_RESPONSIBILITIES_LENGTH}.`
            : null;
    }


    private duplicateNamesValidator(formArray: FormArray): ValidationErrors | null {
        const nameCounts = new Map<string, number>();
        formArray.controls.forEach((group: FormGroup) => {
            const name = group.get('name').value;
            if (name) {
                nameCounts.set(name, (nameCounts.get(name) || 0) + 1);
            }
        });

        let hasDuplicates = false;
        formArray.controls.forEach((group: FormGroup) => {
            const name = group.get('name');

            if (name.value && nameCounts.get(name.value) > 1) {
                name.setErrors({ ...name.errors, isDuplicate: true });
                hasDuplicates = true;
            }
            else {
                // Remove the duplicate error if it exists (without removing other errors)
                if (name.errors) {
                    const errors = { ...name.errors };
                    delete errors.isDuplicate;
                    name.setErrors(Object.keys(errors).length ? errors : null);
                }
            }
        });

        return hasDuplicates ? { duplicateNames: true } : null;
    }

}
