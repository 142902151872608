import { Component, Input } from '@angular/core';

import style from './file-lock-icon.component.scss';

@Component({
    selector: 'file-lock-icon',
    template: `
        <span class="fa-stack fa-xs u-pointer-events-none file-lock-icon__stack test-stack"
              [ngClass]="{ 'file-lock-icon__stack--larger': isLarger }">
            <i id="file" class="fas fa-file fa-stack-1x fa-lg test-file"></i>
            <i id="lock"
               class="fas fa-lock fa-stack-1x file-lock-icon__lock test-lock"
               [ngClass]="{ 'file-lock-icon__lock--larger': isLarger }"
               aria-hidden="true">
            </i>
        </span>
    `,
    styles: [String(style)]
})
export class FileLockIconComponent {
    @Input() isLarger = false;
}
