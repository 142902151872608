import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ConfirmationModal } from '@app/shared/modals/confirmation-modal/confirmation-modal';
import { FloCheckboxComponent } from '@app/widgets/flo-checkbox/flo-checkbox.component';
import {
    ShareDocumentsVirtualTreeComponent
} from '@app/components/document-sharing/components/share-documents-virtual-tree/share-documents-virtual-tree.component';
import { SharedModule } from '@app/shared/shared.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { FormsModule } from '@angular/forms';
import { CurrentSessionService } from '@app/core/current-session.service';
import { DocumentSharingComponent } from './containers/document-sharing/document-sharing.component';
import { ExchangeEventsListComponent } from './components/exchange-events-list/exchange-events-list.component';
import { ShareDocumentsComponent } from './components/share-documents/share-documents.component';
import { ShareDocumentsSummaryComponent } from './components/share-documents/wizard-steps/share-documents-summary/share-documents-summary.component';
import { SelectShareDocumentsComponent } from './components/share-documents/wizard-steps/select-share-documents/select-share-documents.component';
import { DocumentSharingTabsContainer } from './containers/document-sharing-tabs-container/document-sharing-tabs-container.component';
import { ChooseTeamsComponent } from './components/choose-teams/choose-teams.component';
import { DocumentSharingPaginationComponent } from './components/document-sharing-pagination/document-sharing-pagination.component';
import { SelectTeams } from './components/share-documents/wizard-steps/select-share-teams/select-teams.component';
import { DocumentSharingStateService } from './services/share-documents/share-documents-state.service';
import { DocumentSharingApiService } from './services/share-documents/share-documents-api.service';
import { ExchangeEventsApiService } from './services/exchange-events/exchange-events-api.service';
import { ExchangeEventsStateService } from './services/exchange-events/exchange-events-state.service';
import { EntityItemComponent } from './components/entity-item/entity-item.component';

@NgModule({
    imports: [
        WidgetsModule,
        UIRouterModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        ScrollingModule,
        TooltipModule.forRoot(),
        FormsModule
    ],
    declarations: [
        ExchangeEventsListComponent,
        DocumentSharingComponent,
        ShareDocumentsComponent,
        ChooseTeamsComponent,
        EntityItemComponent,
        SelectTeams,
        DocumentSharingTabsContainer,
        SelectShareDocumentsComponent,
        DocumentSharingPaginationComponent,
        ShareDocumentsSummaryComponent,
        ConfirmationModal,
        ShareDocumentsVirtualTreeComponent
    ],
    entryComponents: [
        DocumentSharingComponent,
        ShareDocumentsComponent,
        ChooseTeamsComponent,
        FloCheckboxComponent,
        EntityItemComponent,
        SelectTeams,
        DocumentSharingTabsContainer,
        ShareDocumentsSummaryComponent,
        ConfirmationModal,
        ShareDocumentsVirtualTreeComponent
    ],
    exports: [],
    providers: [
        DocumentSharingStateService,
        DocumentSharingApiService,
        ExchangeEventsApiService,
        ExchangeEventsStateService,
        CurrentSessionService
    ]
})
export class DocumentSharingModule { }
