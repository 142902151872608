import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

import { DuplicateComponent } from './containers/duplicate/duplicate.component';
import { CreateNamesListComponent } from './containers/create-custom-names-list/create-names-list.component';
import { NamesPreviewComponent } from './components/names-preview/names-preview.component';
import { NamePreviewItemComponent } from './components/name-preview-item/name-preview-item.component';

const dependencies = [];

export const duplicateModule = angular
    .module('app.duplicate', dependencies)
    .directive('duplicate', downgradeComponent({ component: DuplicateComponent }) as ng.IDirectiveFactory)
    .directive('createNamesList', downgradeComponent({ component: CreateNamesListComponent }) as ng.IDirectiveFactory)
    .directive('namesPreview', downgradeComponent({ component: NamesPreviewComponent }) as ng.IDirectiveFactory)
    .directive('namePreviewItem', downgradeComponent({ component: NamePreviewItemComponent }) as ng.IDirectiveFactory);
