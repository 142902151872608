import {
    Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges
} from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import * as _ from 'lodash';

import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FolderizeTreeItem } from '../../shared/folders/folders.service.types';

import { FolderizeTreeFlatNode } from './folder-structure-tree.component.types';
import template from './folder-structure-tree.component.html';
import styles from './folder-structure-tree.component.scss';

@Component({
    selector: 'folder-structure-tree',
    template,
    styles: [String(styles)]
})
export class FolderStructureTreeComponent implements OnInit, OnChanges, OnDestroy {
    @Input() border = true;
    @Input() isLoading = false;
    @Input() tree: FolderizeTreeItem[];
    @Input() teamTimezone: string;
    @Input() offsetLeft = 10;
    @Input() radius;
    @Input() scrollBoxHeight = '300px';
    @Input() hasErrors = false;


    destroy$ = new Subject<void>();

    dataChange = new BehaviorSubject<FolderizeTreeItem[]>([]);

    treeControl = new FlatTreeControl<FolderizeTreeItem>(this.getNodeLevel, this.getIsNodeExpandable);
    dataSource: MatTreeFlatDataSource<FolderizeTreeItem, FolderizeTreeFlatNode>;
    treeFlattener = new MatTreeFlattener<FolderizeTreeItem, FolderizeTreeFlatNode>(
        this.nodeTransformer,
        this.getNodeLevel,
        this.getIsNodeExpandable,
        this.getNodeChildren
    );

    constructor() {
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    }

    ngOnInit(): void {
        this.dataChange
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.dataSource.data = _.cloneDeep(data);
                this.treeControl.expandAll();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.tree && changes.tree.currentValue) {
            this.dataChange.next(changes.tree.currentValue);
        }
    }

    private getNodeLevel({ level }: FolderizeTreeFlatNode): number {
        return level;
    }

    private getIsNodeExpandable({ hasChildren }: FolderizeTreeFlatNode): boolean {
        return hasChildren;
    }

    private nodeTransformer(node: FolderizeTreeItem, level: number): FolderizeTreeFlatNode {
        return {
            ...node,
            level,
            hasChildren: !!node && !!node.items && node.items.length > 0
        };
    }

    private getNodeChildren({ items }: FolderizeTreeItem): FolderizeTreeItem[] {
        return items;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
