import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './signature-disabled.component.html';

@Component({
    selector: 'signature-disabled',
    template
})
export class SignatureDisabledModalComponent {
    constructor(
        public modal: BsModalRef
    ) {}

    cancel(): void {
        this.modal.hide();
    }
}
