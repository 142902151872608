import { Component, OnInit } from '@angular/core';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { ProjectsService } from '@app/shared/projects/projects.service';
import { TeamService } from '@app/shared/teams/team.service';
import { SORT } from '@app/core/constants';
import { AdapterService } from '@app/shared/adapter/adapter.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { MultiSelectService } from '@app/shared/multi-select/multi-select.service';
import { StateService } from '@uirouter/core';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { forkJoin } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import {
    ApiError, Crumb, Project, Team, Timeline
} from '@app/shared/models';
import template from './timeline-detail-page.component.html';
import { DestroyItemComponent } from '../../compontents/destroy-item/destroy-item.component';
import { ItemDestroyEvent } from '../../compontents/destroy-item/destroy-item.component.types';
import { ModifyCompletionModalComponent } from '../../compontents/modify-completion-modal/modify-completion-modal.component';
import { Item } from './timeline-detail-page.component.types';

@Component({
    selector: 'timeline-detail',
    template
})
export class TimelineDetailPageComponent implements OnInit {
    SORT = SORT;
    timeline:Timeline;
    project: Project;
    adaptedItems: Item[] = [];
    selectedItems: Item[] = [];
    loadingData = false;
    isExpired = false;
    inProgress = false;
    isDone = false;
    sortName: string;
    multiSelect: any;
    crumbs: Crumb[];
    currentTeam: Team;


    constructor(
        private $state: StateService,
        private modalsService: ModalsService,
        private adapterService: AdapterService,
        private teamService: TeamService,
        private projectsService: ProjectsService,
        private multiSelectService: MultiSelectService,
        private currentSessionService: CurrentSessionService,
        private notificationsService: NotificationsService
    ) {}

    ngOnInit(): void {
        this.currentTeam = this.currentSessionService.getCurrentTeam();
        const stateParams = this.$state.params;

        if (!this.teamService.canViewProjectsAndTimelines()) {
            this.$state.go('app.select-team');
        }

        this.loadingData = true;

        forkJoin({
            project: this.projectsService.getProject(this.currentTeam.id, stateParams.projectId),
            timeline: this.projectsService.getFullTimeline(this.currentTeam.id, stateParams.timelineId)
        }).pipe(
            finalize(() => {
                this.loadingData = false;
            }),
            catchError((error: ApiError) => {
                this.notificationsService.error(error.error.message || 'Server Error: Please contact your administrator.');
                return [];
            })
        ).subscribe(({ project, timeline }) => {
            this.project = project;
            this.timeline = timeline;
            this.adaptedItems = (timeline.items || []).map((item) => this.adapterService.adaptBinderItem(item));
            this.isExpired = !timeline.isFinished && timeline.metrics.remainingDays <= 0;
            this.inProgress = timeline.inProgress;
            this.isDone = timeline.isFinished;
            this.multiSelect = this.multiSelectService.makeMultiSelect('selected').select;
            this.crumbs = this.getCrumbs();

            this.updateSort('name', false);
        });

    }

    goToItem(item) {
        if (item.type !== 'document') {
            return;
        }

        this.$state.go('app.team.document-show', {
            teamId: item.teamId,
            documentId: item.id,
            version: item.version
        });
    }

    select(event, index, items) {
        this.selectedItems = this.multiSelect(event, index, items);
    }

    toggleActions($event, index, items, item) {
        $event.preventDefault();

        if (!item.selected) {
            this.selectedItems.forEach((i) => {
                i.selected = false;
            });
            this.select($event, index, items);
        }
    }

    canActOnSelection() {
        return this.selectedItems.length
            && (this.canModifyCompletionDate()
                || this.canRemove());
    }

    canModifyCompletionDate() {
        return this.selectedItems.length === 1 && this.currentTeam.permissions.manageTimelines;
    }

    canEditTimeline() {
        return this.teamService.canEditIndividualTimeline();
    }

    canRemove() {
        return this.selectedItems.length > 0 && this.currentTeam.permissions.assignToTimelines;
    }

    remove() {
        if (!this.canRemove()) {
            return;
        }
        const items = this.selectedItems.map((item) => ({
            type: item.type,
            id: item.id,
            name: item.name
        }));
        const itemRemoveModal = this.modalsService.show(DestroyItemComponent, {
            animated: false,
            class: 'modal-md',
            initialState: {
                items,
                currentTimeline: this.timeline
            }
        });
        itemRemoveModal.content.onDestroy.subscribe((event: ItemDestroyEvent) => {
            const { updatePayload, message } = event;
            this.projectsService
                .editTimeline(this.currentTeam.id, this.timeline.id, updatePayload)
                .subscribe(
                    () => {
                        this.notificationsService.success(message);
                        this.$state.reload();
                        itemRemoveModal.hide();
                    },
                    ({ error }: { error: { message: string } }) => {
                        this.notificationsService.error(error.message || 'Server Error: Please contact your administrator.');
                        itemRemoveModal.hide();
                    }
                );
        });
    }

    modifyCompletionDate() {
        if (!this.canModifyCompletionDate() || this.selectedItems.length !== 1) {
            return;
        }

        const selected = this.selectedItems[0];
        const item = {
            objectType: selected.type,
            objectId: selected.id,
            completionDate: selected.state.timelineItemStatus.completionDate
        };
        const attributes = {
            name: selected.name,
            uploadedDate: selected.state.timelineItemStatus.dateRawFileUploaded,
            path: selected.fullPath || selected.path
        };

        const modifyCompletionModal = this.modalsService.show(ModifyCompletionModalComponent, {
            animated: false,
            class: 'modal-md',
            initialState: {
                item,
                attributes
            }
        });

        modifyCompletionModal.content.onSave.subscribe((event) => {
            this.projectsService
                .editTimelineItem(this.currentTeam.id, this.timeline.id, event.updatePayload)
                .subscribe(
                    () => {
                        event.onSuccess();
                        this.notificationsService.success(`Completion date updated for "${event.itemName}".`);
                        this.$state.reload();
                    },
                    ({ data }: { data: { message: string } }) => {
                        event.onError();
                        this.notificationsService.error(data.message || 'Server Error: Please contact your administrator.');
                    }
                );
        });
    }


    editTimeline() {
        if (!this.canEditTimeline()) {
            return;
        }

        this.$state.go('app.team.timeline-update', {
            teamId: this.timeline.teamId,
            projectId: this.timeline.projectId,
            timelineId: this.timeline.id
        });
    }

    hasVisibleBinders() {
        return this.adaptedItems.length;
    }

    private getValueByPath(obj, path) {
        return path.split('.').reduce((acc, key) => acc && acc[key], obj);
    }

    updateSort(sortName: string, isReversed?: boolean): void {
        this.sortName = sortName;
        this.SORT.set(sortName, isReversed);

        let sortedItems = this.adaptedItems.sort((a, b) => {
            return this.SORT.naturalSort({ value: this.getValueByPath(a, sortName) },
                { value: this.getValueByPath(b, sortName) });
        });

        if (this.SORT.isReversed) {
            sortedItems = sortedItems.reverse();
        }

        this.adaptedItems = [...sortedItems];
    }

    private getCrumbs() {
        const teamId = this.currentTeam.id;
        return [
            {
                name: 'Manage Projects',
                stateName: 'app.team.manage-projects',
                stateParams: { teamId }
            },
            {
                name: this.project.name,
                stateName: 'app.team.manage-project',
                stateParams: {
                    projectId: this.project.id,
                    teamId
                }
            },
            {
                name: this.timeline.name
            }
        ];
    }

}
