import {
    Component, EventEmitter, OnInit,
    Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';

import { CurrentSessionService } from '@app/core/current-session.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { getIconClassFromFileExtension } from '@app/shared/documents/get-icon-class-from-file-extension.util';
import { ApiError, Team } from '@app/shared/models';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { REGEX } from '@app/core/constants';
import { notBlank } from '@app/core/form-validators';
import { sortFolderStructureLexicographically } from '@app/widgets/sort/sort-folder-structure-lexicographically';
import { ImportStructureFileInfo } from '../../components/import-structure/import-structure.component';
import { StudyStartupService } from '../../study-startup.service';
import { StudyTemplateFilePreviewResponse } from '../../study-startup.service.types';
import template from './import-template-structure.component.html';
import styles from './import-template-structure.component.scss';
import { ImportStructureDetailsValidation } from '../../components/import-structure-details/import-structure-details.types';
import { NewTemplateStructure } from './import-template-structure.component.types';

@Component({
    selector: 'import-template-structure',
    template,
    styles: [String(styles)]
})

export class ImportTemplateStructureComponent implements OnInit {
    readonly progressSteps = { 1: 'Import Structure', 2: 'Create Template' };
    progress = 1;
    folderStructurePreview: StudyTemplateFilePreviewResponse = null;
    folderStructurePreviewIsLoading = false;
    fileInfo: ImportStructureFileInfo = null;
    currentTeam: Team;
    isProcessing = false;
    @Output() importStructureSuccess = new EventEmitter<NewTemplateStructure>();

    templateDetailsForm = new FormGroup({
        name: new FormControl(
            '',
            [
                Validators.required,
                Validators.minLength(ImportStructureDetailsValidation.templateNameMinLength),
                Validators.maxLength(ImportStructureDetailsValidation.templateNameMaxLength),
                Validators.pattern(REGEX.names),
                notBlank
            ]
        ),
        description: new FormControl(
            '',
            [
                Validators.minLength(ImportStructureDetailsValidation.templateDescriptionMinLength),
                Validators.maxLength(ImportStructureDetailsValidation.templateDescriptionMaxLength)
            ]
        )
    });

    constructor(
        private modalRef: BsModalRef,
        private StudyStartup: StudyStartupService,
        private CurrentSession: CurrentSessionService,
        private Notifications: NotificationsService
    ) { }

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
    }

    hideModal(): void {
        this.modalRef.hide();
    }

    setProgress(n: number): void {
        this.progress = Number(n);
    }

    incrementProgress(n: number): void {
        this.setProgress(Number(this.progress) + n);
    }

    onFileChange(file: File): void {
        if (!file) {
            this.folderStructurePreview = null;
            this.fileInfo = null;
            return;
        }

        const iconClass = getIconClassFromFileExtension(file.name);
        let error = null;
        if (iconClass !== 'far fa-file-excel') {
            error = 'File type is not supported';
        }
        else {
            const teamId = this.currentTeam.id;
            this.folderStructurePreviewIsLoading = true;
            this.StudyStartup.getFolderStructureFilePreview(teamId, file)
                .pipe(take(1))
                .subscribe(
                    (response) => {
                        this.folderStructurePreviewIsLoading = false;
                        const tree = sortFolderStructureLexicographically(response.tree);
                        this.folderStructurePreview = {
                            ...response,
                            tree
                        };
                    },
                    (errorResponse: ApiError) => {
                        this.folderStructurePreviewIsLoading = false;
                        if (errorResponse.error) {
                            return this.Notifications.error(errorResponse.error.message);
                        }
                        this.Notifications.unexpectedError();
                    }
                );
        }
        this.fileInfo = {
            iconClass,
            fileName: file.name,
            error
        };
    }

    create(): void {
        const params = {
            name: this.templateDetailsForm.controls.name.value.trim(),
            description: this.templateDetailsForm.controls.description.value.trim(),
            folderStructure: this.folderStructurePreview.folderStructure
        };
        const teamId = this.currentTeam.id;

        this.isProcessing = true;
        this.StudyStartup.createFolderStructure(teamId, params)
            .subscribe((response) => {
                this.importStructureSuccess.emit(response);
                this.Notifications.success('New Template Structure imported');
                this.hideModal();
            },
            (errorResponse: ApiError) => {
                this.isProcessing = false;
                if (errorResponse.error) {
                    return this.Notifications.error(errorResponse.error.message);
                }
                this.Notifications.unexpectedError();
            });
    }
}
