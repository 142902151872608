import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Platform } from '@angular/cdk/platform';

import { SharedModule } from '@app/shared/shared.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { HelpDeskComponent } from './containers/help-desk/help-desk.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        WidgetsModule
    ],
    providers: [
        { provide: Window, useValue: window },
        Platform
    ],
    declarations: [
        HelpDeskComponent
    ],
    exports: [
        HelpDeskComponent
    ],
    entryComponents: [
        HelpDeskComponent
    ]
})
export class HelpDeskModule { }
