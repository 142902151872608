import { BsModalRef } from 'ngx-bootstrap/modal';
import { StateService } from '@uirouter/angular';
import {
    ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit
} from '@angular/core';
import { GetStudiesParams } from '@app/shared/studies/studies.service.types';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import {
    filter, take, takeUntil, tap, map, catchError
} from 'rxjs/operators';
import {
    FormArray, FormBuilder, FormGroup, Validators
} from '@angular/forms';
import {
    BehaviorSubject, Observable, Subject
} from 'rxjs';
import { logEntryTypes } from '@florencehealthcare/florence-constants/lib/documents/log-entries';
import template from './doa-log-automation-modal.html';
import styles from './doa-log-automation-modal.scss';
import { StudiesService } from '../../../../shared/studies/studies.service';
import {
    GetTeamMember, LogTemplate, Site, Study,
    StudyResponsibilities,
    TeamMemberForm
} from '../../../../shared/models';
import { LogTemplatesService } from '../../../../shared/log-templates/log-templates.service';
import { TeamMemberResponsibilitiesChangedEvent, TeamMemberStartDateChangedEvent, TeamMemberStudyRoleChangedEvent } from '../doa-log-automation-review-step/doa-log-automation-review-step.component.types';
import { StudyRoleIdName } from '../../../../shared/study-roles/study-roles.types';


@Component({
    selector: 'doa-log-automation-modal',
    template,
    styles: [String(styles)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoaLogAutomationModal implements OnInit, OnDestroy {

    constructor(
        public modalRef: BsModalRef,
        private formBuilder: FormBuilder,
        private studiesService: StudiesService,
        private Notifications: NotificationsService,
        private $state: StateService,
        private logTemplatesService: LogTemplatesService
    ) {}

    @Input() modalTitle: string;
    @Input() handleConfirm: (confirmationData: any) => void;
    @Input() teamId: string;
    @Input() logTemplateId: string;

    doaAutomatedLogForm: FormGroup;
    isAutomationReviewStepActive = false

    private studies = new BehaviorSubject<Study[]>([]);
    studies$ = this.studies.asObservable();

    private selectedStudy = new BehaviorSubject<Study>({} as Study);
    selectedStudy$ = this.selectedStudy.asObservable();

    private isLoadingStudies = new BehaviorSubject<boolean>(false);
    isLoadingStudies$ = this.isLoadingStudies.asObservable();

    private studySites = new BehaviorSubject<Site[]>([]);
    studySites$ = this.studySites.asObservable();

    private selectedSite = new BehaviorSubject<Site>({} as Site);
    selectedSite$ = this.selectedSite.asObservable();

    private teamMembers = new BehaviorSubject<TeamMemberForm[]>([]);
    teamMembers$ = this.teamMembers.asObservable();

    private isLoadingStudySites = new BehaviorSubject<boolean>(false);
    isLoadingStudySites$ = this.isLoadingStudySites.asObservable();

    totalStudiesCount = 0;
    studiesPagination: GetStudiesParams = {
        pageNum: 1,
        pageSize: 20,
        withPagination: true
    }

    hasStartDateColumn = true;

    private logTemplate = new BehaviorSubject<LogTemplate>({} as LogTemplate);
    logTemplate$ = this.logTemplate.asObservable();

    studyRoles$: Observable<StudyRoleIdName[]> = this.logTemplate$.pipe(
        map((logTemplate) => logTemplate.roles?.map(({ studyRole }) => studyRole))
    );

    responsibilities$: Observable<StudyResponsibilities> = this.logTemplate$.pipe(
        map((logTemplate) => logTemplate.responsibilities)
    );

    private readonly destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.initForm();

        this.setTeamMembersFormArrayOnSiteChanges$().pipe(
            tap(() => {
                const updatedSiteTeamMembers = this.doaAutomatedLogForm.get('reviewAndCreate.teamMembers') as FormGroup;

                this.teamMembers.next(updatedSiteTeamMembers.getRawValue());
            }),
            takeUntil(this.destroy$)
        ).subscribe();

        this.getStudies();

        this.getLogTemplate();

        this.doaAutomatedLogForm.get('reviewAndCreate.teamMembers').valueChanges.pipe(
            tap(() => {
                this.updateTeamMembers();
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onSelectStudy(selectedStudy: Study): void {
        if (this.selectedSite.getValue()?.id) {
            this.setSelectedSite(null);
        }

        this.setSelectedStudy(selectedStudy);

        this.getStudySites(selectedStudy.id);
    }

    onSelectSite(selectedSite: Site): void {
        this.setSelectedSite(selectedSite);
    }

    fetchMoreStudies() {
        if (this.studies.getValue().length !== this.totalStudiesCount && this.totalStudiesCount > 0) {
            this.studiesPagination.pageNum += 1;
            this.getStudies();
        }
    }

    redirectToStudySiteTeam() {
        this.modalRef.hide();
        this.$state.go('app.team.study-edit', { studyId: this.selectedStudy.getValue().id });
    }

    onStudyRoleChanged({ userId, studyRole }: TeamMemberStudyRoleChangedEvent): void {
        const teamMemberFormGroup = this.getTeamMemberFormGroup(userId);

        this.setTeamMemberStudyRole(teamMemberFormGroup, studyRole);

        this.setTeamMemberResponsibilitiesForStudyRole(teamMemberFormGroup);
    }

    onResponsibilitiesChanged({ userId, responsibilityIds }: TeamMemberResponsibilitiesChangedEvent): void {
        const teamMemberFormGroup = this.getTeamMemberFormGroup(userId);

        this.setTeamMemberResponsibilitiesFormArray(teamMemberFormGroup, responsibilityIds);
    }

    onStartDateChange({ userId, startDate }: TeamMemberStartDateChangedEvent): void {
        const teamMemberFormGroup = this.getTeamMemberFormGroup(userId);

        this.setTeamMemberStartDate(teamMemberFormGroup, startDate);
    }

    onBulkStartDateChange(startDate: Date): void {
        const teamMembersFormArray = this.doaAutomatedLogForm.get('reviewAndCreate.teamMembers') as FormArray;

        teamMembersFormArray.controls.forEach((teamMemberFormControl) => {
            teamMemberFormControl.get('startDate').setValue(startDate);
        });
    }

    removeTeamMemberFormGroup({ userId }) {
        const teamMembersFormArray = this.doaAutomatedLogForm.get('reviewAndCreate.teamMembers') as FormArray;
        const teamMemberFormGroupIndex = teamMembersFormArray.value.findIndex((teamMember) => teamMember.userId === userId);

        if (teamMemberFormGroupIndex !== -1) {
            teamMembersFormArray.removeAt(teamMemberFormGroupIndex);
        }
    }

    modalConfirmation(secondaryConfirmation = false): void {
        const formValueSetupAutomation = this.doaAutomatedLogForm.value.setupAutomation;
        const formValueReviewAndCreate = this.doaAutomatedLogForm.value.reviewAndCreate;

        const params = {
            study: {
                id: formValueSetupAutomation.study.id,
                site: {
                    id: formValueSetupAutomation.study.site.id,
                    teamMembers: formValueReviewAndCreate.teamMembers
                }
            },
            ...(secondaryConfirmation ? { requestSignatures: true } : {})
        };

        this.handleConfirm(params);
    }

    stepChange(event: number): void {
        this.isAutomationReviewStepActive = event === 1;
    }

    private initForm(): void {
        this.doaAutomatedLogForm = this.formBuilder.group({
            setupAutomation: this.formBuilder.group({
                study: this.formBuilder.group({
                    id: this.formBuilder.control(null, [Validators.required]),
                    site: this.formBuilder.group({
                        id: this.formBuilder.control(null, [Validators.required])
                    })
                })
            }),
            reviewAndCreate: this.formBuilder.group({
                teamMembers: this.formBuilder.array([], [
                    Validators.minLength(1)
                ])
            })
        });
    }

    private generateTeamMemberFormGroup(
        teamMember: GetTeamMember,
        responsibilityIds: string[]
    ): FormGroup {
        const studyRoleId = teamMember.studyRole._id;
        const userId = teamMember.user.id;

        return this.formBuilder.group({
            userId: this.formBuilder.control(userId, [
                Validators.required
            ]),
            user: this.formBuilder.group({
                name: this.formBuilder.control({ value: teamMember?.user.name, disabled: true }),
                email: this.formBuilder.control({ value: teamMember?.user.email, disabled: true })
            }),
            studyRoleId: this.formBuilder.control(studyRoleId, [
                Validators.required
            ]),
            studyRole: this.formBuilder.group({
                name: this.formBuilder.control({ value: teamMember?.studyRole?.name, disabled: true })
            }),
            responsibilityIds: this.formBuilder.array(
                responsibilityIds.map((responsibilityId) => this.formBuilder.control(responsibilityId)),
                [Validators.minLength(1)]
            ),
            startDate: this.formBuilder.control(null)
        });
    }

    private getStudyRoleResponsibilities(studyRoleId: string): string[] {
        const studyRole = this.logTemplate.getValue().roles?.find((role) => role.studyRole._id === studyRoleId);

        if (!studyRole) {
            return [];
        }
        return studyRole.responsibilityIds;
    }

    // TODO: add pagination params
    private getStudies(): void {
        this.isLoadingStudies.next(true);
        this.studiesService.getStudies(this.teamId, this.studiesPagination).pipe(
            tap((getStudiesResponse) => {
                this.studies.next([...this.studies.getValue(), ...getStudiesResponse.items]);
                this.totalStudiesCount = getStudiesResponse.recordCount;
                this.isLoadingStudies.next(false);
            }),
            catchError((error) => {
                this.Notifications.error(error?.error?.message || 'Failed to fetch Studies');
                this.isLoadingStudies.next(true);
                return null;
            }),
            take(1)
        ).subscribe();
    }

    private getStudySites(studyId: string): void {
        this.isLoadingStudySites.next(true);

        this.studiesService.getStudySites(this.teamId, studyId).pipe(
            tap((getStudySitesResponse) => {
                this.studySites.next(getStudySitesResponse);

                this.isLoadingStudySites.next(false);
            }),
            catchError((error) => {
                this.Notifications.error(error?.error?.message || 'Failed to fetch Study Sites');
                this.isLoadingStudySites.next(true);
                return null;
            }),
            take(1)
        ).subscribe();
    }

    private getLogTemplate(): void {
        this.logTemplatesService.getLogTemplate(this.teamId, this.logTemplateId).pipe(
            tap((logTemplate) => {
                this.logTemplate.next(logTemplate);
                this.hasStartDateColumn = logTemplate.columns.some((column) => column.name === 'Start Date' && column.type === logEntryTypes.DATE);
            }),
            // TODO: add error handling
            take(1)
        ).subscribe();
    }

    private setTeamMembersFormArrayOnSiteChanges$(): Observable<Site> {
        return this.selectedSite$.pipe(
            filter((site) => !!site?.teamMembers?.length),
            tap((site: Site) => {
                this.setTeamMembersFormArray(site.teamMembers);
            })
        );
    }

    private setSelectedStudy(selectedStudy: Study): void {
        this.selectedStudy.next(selectedStudy);

        this.doaAutomatedLogForm.get('setupAutomation.study.id').setValue(selectedStudy.id);
    }

    private setSelectedSite(selectedSite: Site | null): void {
        this.selectedSite.next(selectedSite);

        this.doaAutomatedLogForm.get('setupAutomation.study.site.id').setValue(selectedSite?.id || null);
    }

    private setTeamMembersFormArray(teamMembers: GetTeamMember[]): void {
        const teamMembersFormArray = this.doaAutomatedLogForm.get('reviewAndCreate.teamMembers') as FormArray;

        teamMembersFormArray.clear();

        teamMembers.forEach((teamMember) => {
            const responsibilitiesIds = this.getStudyRoleResponsibilities(teamMember.studyRole._id);

            const teamMemberFormGroup = this.generateTeamMemberFormGroup(teamMember, responsibilitiesIds);

            if (responsibilitiesIds.length === 0) {
                teamMemberFormGroup.get('responsibilityIds').setErrors({
                    notFound: true
                });
            }

            teamMembersFormArray.push(teamMemberFormGroup);
        });
    }

    private setTeamMemberResponsibilitiesForStudyRole(teamMemberFormGroup: FormGroup): void {
        const studyRoleId = teamMemberFormGroup.get('studyRoleId').value;

        const responsibilityIds = this.getStudyRoleResponsibilities(studyRoleId);

        this.setTeamMemberResponsibilitiesFormArray(teamMemberFormGroup, responsibilityIds);
    }

    private setTeamMemberResponsibilitiesFormArray(teamMemberFormGroup: FormGroup, responsibilityIds: string[]): void {
        const teamMemberResponsibilitiesFormArray = teamMemberFormGroup.get('responsibilityIds') as FormArray;

        teamMemberResponsibilitiesFormArray.clear();

        responsibilityIds.forEach((responsibilityId) => {
            teamMemberResponsibilitiesFormArray.push(this.formBuilder.control(responsibilityId));
        });
    }

    private setTeamMemberStudyRole(teamMemberFormGroup: FormGroup, studyRole: StudyRoleIdName): void {
        teamMemberFormGroup.get('studyRoleId').setValue(studyRole._id);
        teamMemberFormGroup.get('studyRole.name').setValue(studyRole.name);
    }

    private setTeamMemberStartDate(teamMemberFormGroup: FormGroup, startDate: Date): void {
        teamMemberFormGroup.get('startDate').setValue(startDate);
    }

    private getTeamMemberFormGroup(userId: string): FormGroup {
        const teamMembersFormArray = this.doaAutomatedLogForm.get('reviewAndCreate.teamMembers') as FormArray;
        const teamMemberFormGroupIndex = teamMembersFormArray.value.findIndex((teamMember) => teamMember.userId === userId);

        return teamMembersFormArray.at(teamMemberFormGroupIndex) as FormGroup;
    }

    private updateTeamMembers(): void {
        const updatedTeamMembers = this.doaAutomatedLogForm.get('reviewAndCreate.teamMembers') as FormArray;

        this.teamMembers.next(updatedTeamMembers.getRawValue());
    }
}
