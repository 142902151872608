import * as _ from 'lodash';
import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import { Role } from '@app/shared/models';
import template from './delete-role.component.html';
import { DeleteRoleEvent } from './delete-role.component.types';

@Component({
    selector: 'delete-role',
    template
})
export class DeleteRoleComponent implements OnInit {

    @Input() role: Role;
    @Output() onDestroy = new EventEmitter<DeleteRoleEvent>();

    namePattern: string;
    confirmName: string;
    reason: string;
    isProcessing: boolean;

    deleteRoleForm: FormGroup;

    constructor(
        private modalInstance: BsModalRef,
        private fb: FormBuilder
    ) {

        this.confirmName = '';
        this.isProcessing = false;
        this.reason = '';
    }

    ngOnInit(): void {
        this.namePattern = `^${_.escapeRegExp(this.role.name)}$`;
        this.initForm();
    }

    initForm() {
        this.deleteRoleForm = this.fb.group({
            confirmName: ['', [Validators.required, notBlank, Validators.pattern(this.namePattern)]],
            reason: ['', [Validators.required, notBlank]]
        });
    }

    cancel(): void {
        this.modalInstance.hide();
    }

    destroy() {
        this.isProcessing = true;

        const eventData = {
            role: this.role,
            reason: this.deleteRoleForm.controls.reason.value,
            onSuccess: () => {
                this.modalInstance.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        };

        this.onDestroy.emit(eventData);
    }
}
