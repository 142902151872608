import { StateProvider, StateService } from '@uirouter/angularjs';
import { CurrentSessionService } from '../../core/current-session.service';
import { NotificationsService } from '../../core/notifications/notifications.service';


const studyStartupRouteGuard = {
    checkStudyStartupPermissions: [
        'CurrentSession',
        '$state',
        'Notifications',
        function checkStudyStartupPermissions(
            CurrentSession: CurrentSessionService,
            $state: StateService,
            Notifications: NotificationsService
        ): Promise<void> {
            const { permissions, id } = CurrentSession.getCurrentTeam();
            const hasAccess = permissions.manageTeam
                || permissions.manageTeamStudyStartup
                || permissions.createTeamStudies
                || permissions.importTeamStudyStructureTemplate
                || permissions.downloadTeamStructureTemplates
                || permissions.deleteTeamStructureTemplates;

            if (!hasAccess) {
                Notifications.error('User does not have permissions to perform this action');
                $state.go('app.team.binders', { teamId: id });
                return Promise.reject();
            }
            return Promise.resolve();
        }]
};


const studyStartupRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.study-startup', {
        url: '/study-startup',
        views: {
            '': 'studyStartup'
        },
        resolve: studyStartupRouteGuard
    });
};

studyStartupRoute.$inject = ['$stateProvider'];
export default studyStartupRoute;
