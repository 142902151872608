import { Component, OnInit } from '@angular/core';
import { Team, Crumb, Paywall } from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Pagination } from '@app/components/teams/components/manage-team-studies/manage-team-studies.types';
import { StudiesService } from '@app/shared/studies/studies.service';

import { FeatureFlagService } from '@app/core/feature-flag.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { filter, tap } from 'rxjs/operators';
import template from './monitor-groups-view.component.html';

@Component({
    selector: 'manage-monitor-groups',
    template
})
export class MonitorGroupsViewComponent implements OnInit {
    currentTeam: Team;
    crumbs: Crumb[];
    selectedMonitorGroupId: string|undefined = undefined;
    loadingGroups = false;
    groups: Paywall[];
    zendeskLink = 'https://florencehealthcare.zendesk.com/hc/en-us/articles/4405783653907';
    pagination = {
        sortDir: 'DESC'
    } as Pagination;

    studyProfileEntryNavMenuLocation = false

    constructor(
        private CurrentSession: CurrentSessionService,
        private StudyService: StudiesService,
        private FeatureFlags: FeatureFlagService
    ) {}

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.getStudyProfileEntryFlag();
        this.loadMonitorGroups();
    }

    getStudyProfileEntryFlag() {
        this.FeatureFlags.getFlag(FEATURE_FLAGS.STUDY_PROFILE_SIDEMENU_ENTRY, false).pipe(
            filter((flag) => {
                return flag !== undefined;
            })
        ).subscribe((value) => {
            this.studyProfileEntryNavMenuLocation = value;
            if (value) {
                this.crumbs = [{
                    name: 'Studies',
                    stateName: 'app.team.manage-studies-by-team',
                    stateParams: { teamId: this.currentTeam.teamId }
                },
                {
                    name: 'Manage Monitor Groups',
                    stateName: 'app.team.manage-monitor-groups',
                    stateParams: { teamId: this.currentTeam.id }
                }
                ];
            }
            this.crumbs = this.getCrumbs();
        });
    }

    private loadMonitorGroups(): void {
        this.StudyService
            .getTeamMonitorGroups(this.currentTeam.id)
            .subscribe((paywalls) => {
                this.groups = paywalls;
                this.loadingGroups = false;
            }, () => {
                this.groups = undefined;
                this.loadingGroups = false;
            });
    }

    private getCrumbs(): Crumb[] {
        if (this.crumbs) {
            return this.crumbs;
        }
        return [
            {
                name: 'Team Settings',
                stateName: 'app.team.manage-team-studies',
                stateParams: { teamId: this.currentTeam.id }
            },
            {
                name: 'Manage Monitor Groups',
                stateName: 'app.team.manage-monitor-groups',
                stateParams: { teamId: this.currentTeam.id }
            }
        ];
    }

    calculateDisplayValues(group: Paywall): string {
        const maxCharacters = 40;
        const displayedEntities = [];
        let suffix = '';
        let charCount = 0;
        for (let i = 0; i < group.domains.length; i += 1) {
            const entity = group.domains[i];
            const entityCharCount = entity.length + 1;
            if (i === 0 || charCount < maxCharacters) {
                displayedEntities.push(entity);
                charCount += entityCharCount;
            }
            else {
                break;
            }
        }
        const base = displayedEntities.join(', ');
        if (displayedEntities.length < group.domains.length) {
            suffix = `, and ${group.domains.length - displayedEntities.length} other(s)`;
        }
        return `${base}${suffix}`;
    }

    getStudiesCount(group: Paywall): number {
        if (!group.studyIds) {
            return 0;
        }
        return group.studyIds.length;
    }

    changeSort(): void {
        const currentDir = this.pagination.sortDir;
        this.pagination.sortDir = currentDir === 'ASC' ? 'DESC' : 'ASC';
        this.pagination.sortDir === 'DESC'
            ? this.groups.sort((a, b) => (a.name < b.name ? 1 : -1))
            : this.groups.sort((a, b) => (a.name < b.name ? -1 : 1));
    }

    select(group: Paywall): void {
        this.selectedMonitorGroupId = this.selectedMonitorGroupId === group.id ? undefined : group.id;
    }

    toggleActions($event, monitorGroup: Paywall): void {
        $event.preventDefault();
        if (this.selectedMonitorGroupId !== monitorGroup.id) {
            this.select(monitorGroup);
        }
    }
}
