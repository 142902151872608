import { Component, OnDestroy, OnInit } from '@angular/core';
import { DownloadCheckerService } from '@app/shared/downloads/download-checker.service';
import { Download, Crumb, Team } from '@app/shared/models';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DownloadsService } from '@app/shared/downloads/downloads.service';
import { MultiSelectService } from '@app/shared/multi-select/multi-select.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { SORT } from '@app/core/constants';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import * as _ from 'lodash';
import template from './downloads-index.component.html';

@Component({
    selector: 'downloads-index',
    template
})
export class DownloadsIndexComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<boolean>();
  public allSelected = false;
  public loadedDownloads = false;
  public crumbs: Crumb[] = [{ name: 'My Downloads' }];
  public selectedItems: Download[] = [];
  public downloads: Download[] = [];
  public visibleDownloads: Download[] = [];
  public currentTeam: Team;
  public multiSelect: any;
  public selectAllItems: any;
  SORT = SORT;

  constructor(
    private notifications: NotificationsService,
    private downloadChecker: DownloadCheckerService,
    private downloadsService: DownloadsService,
    private multiSelectService: MultiSelectService,
    private currentSessionService: CurrentSessionService,
    private apiErrorService: ApiErrorsService
  ) {}

  ngOnInit(): void {
      const { select, selectAll } = this.multiSelectService.makeMultiSelect('selected');
      this.multiSelect = select;
      this.selectAllItems = selectAll;
      this.currentTeam = this.currentSessionService.getCurrentTeam();

      this.getDownloads();

      this.downloadChecker.updatedDownload$
          .pipe(takeUntil(this.destroy$))
          .subscribe((updatedDownload: Download) => {
              const { id, status } = updatedDownload || {};
              if (!id) {
                  return;
              }
              const download = this.downloads.find((d) => d.id === id);
              if (download) {
                  download.status = status;
              }
          });
  }

  ngOnDestroy(): void {
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
  }

  getDownloads(): void {
      this.loadedDownloads = false;
      this.downloadsService.getDownloads(this.currentTeam.id).subscribe((downloads) => {
          this.downloads = downloads;
          this.visibleDownloads = this.downloads;
          this.loadedDownloads = true;
          this.updateSort('createdAt', true);
      });
  }

  updateSort(sortName: string, isReversed: boolean): void {
      SORT.set(sortName, isReversed);
      this.visibleDownloads = _.orderBy(
          this.visibleDownloads,
          [(binder) => binder[sortName].toString().toLowerCase()],
          [this.SORT.isReversed ? 'desc' : 'asc']
      );
  }

  selectAll(): void {
      this.selectedItems = this.selectAllItems(this.visibleDownloads, true);
      this.allSelected = true;
  }

  selectNone(): void {
      this.selectedItems = this.selectAllItems(this.visibleDownloads, false);
      this.allSelected = false;
  }

  select(event: any, index: number): void {
      this.selectedItems = this.multiSelect(event, index, this.visibleDownloads);
      this.allSelected = this.selectedItems.length === this.visibleDownloads.length;
  }

  toggleActions(event: Event, index: number, download: Download | any): void {
      event.preventDefault();
      if (!download.selected) {
          this.selectNone();
          this.select(event, index);
      }
  }

  openDownload(event: Event, download: Download): void {
      event.stopPropagation();
      if (download.status === 'Pending' || download.status === 'Failed') {
          return;
      }
      this.downloadsService.downloadItem(this.currentTeam.id, download);
  }

  deleteDownloads(): void {
      if (!this.selectedItems.length) {
          return;
      }

      this.downloadsService.destroy(this.currentTeam.id, this.selectedItems).toPromise().then(() => {
          this.selectedItems.forEach(({ id }) => {
              this.downloads = this.downloads.filter((d) => d.id !== id);
              this.visibleDownloads = this.visibleDownloads.filter((d) => d.id !== id);
          });
          this.selectedItems = [];
          this.getDownloads();
          this.notifications.success('Downloads deleted.');
      }).catch(this.apiErrorService.handleError);
  }

  onFilterChanged(filter: string): void {
      this.selectNone();
      this.visibleDownloads = this.downloads.filter((d) => d.filename.toLowerCase().includes(filter.toLowerCase()));
      this.updateSort(SORT.by, SORT.isReversed);
  }

  trackByIndex(index: number): number {
      return index;
  }

  tooltipText(download: Download): string {
      return download.status === 'Pending' ? 'Download in progress' : 'Click to download';
  }
}
