import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { MissingPermissionSaveEvent } from '@app/widgets/missing-permissions/missing-permissions.types';
import { SORT } from '@app/core/constants';
import { TeamService } from '@app/shared/teams/team.service';
import { PermissionsService } from '@app/shared/permissions/permissions.service';
import { PermissionValidatorService } from '@app/shared/permissions/permission-validator.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { MissingPermissionsComponent } from '@app/widgets/missing-permissions/missing-permissions.component';
import { CheckIsViewBinderPermissionMissingResponse, Item } from '@app/shared/permissions/permission-validator.service.types';
import { Role, UserWithRoles } from '@app/shared/models';
import template from './permissions-edit.component.html';

@Component({
    selector: 'permissions-edit',
    template
})
export class PermissionsEditComponent implements OnInit {

    @Input() item: Item;
    @Input() activeSubject: UserWithRoles | Role;

    @Output() postSaveAction = new EventEmitter<void>();

    isProcessing: boolean;
    hasPermissionTreeChanged: boolean;
    activeSubjectIndex: string;
    itemPermissions;
    expanded;


    constructor(
        private Teams: TeamService,
        private Permissions: PermissionsService,
        private PermissionValidator: PermissionValidatorService,
        private Modals: ModalsService,
        private modalInstance: BsModalRef
    ) {}

    SORT = SORT;


    ngOnInit(): void {
        this.expanded = {
            permissions: null,
            binders: null
        };

        this.isProcessing = false;
        this.hasPermissionTreeChanged = false;

        // Bound to subject select list. uses ng-repeat, as such, must be a string.
        this.activeSubjectIndex = '0';

        // Do we have a subject in mind already?
        if (this.activeSubject) {
            this.onSubjectSearchSelect(this.activeSubject);
        }
        else {
            this.refreshPermissionsTree();
        }

    }

    findRolesAndUsers = (filter: string) => {
        return this.Teams.searchRolesAndUsers(filter);
    }

    getFullPath() {

        // return this.itemPermissions.tree.fullPath;
        // won't work because team is often the default item till the subject and thus tree, is defined
        const { name, type } = this.item;
        let fullPath = '';
        if (type === 'team' || type === 'binder') {
            return name;
        }
        if (this.item.binderName) {
            fullPath += this.item.binderName;
        }
        fullPath += this.item.pathCanonical || '';
        if (type === 'document') {
            fullPath += fullPath ? `/${name}` : name;
        }
        return fullPath;
    }

    permissionTreeChanged() {
        this.hasPermissionTreeChanged = true;
    }

    /**
     * Handler: Upon selecting a subject from the Searchbox,
     * @param subject
     */
    onSubjectSearchSelect = (subject: UserWithRoles | Role) => {
        this.activeSubject = subject; // select recently searched subject as active
        this.refreshPermissionsTree();
    }

    refreshPermissionsTree() {
        if (!this.activeSubject) {
            return;
        }
        const permParams = {
            teamId: this.item.teamId,
            subjectId: this.activeSubject.id,
            subjectType: this.activeSubject.type,
            objectId: this.item.id,
            objectType: this.item.type
        };
        this.Teams.loadSubjectPermissionsForObject(permParams).subscribe(
            (permissions) => {
                this.itemPermissions = permissions;
                // HACK: Do this twice to ensure all levels are set
                if (this.itemPermissions.tree.binders) {
                    this.itemPermissions.tree.binders.forEach((binderTree) => {
                        this.Teams.setHasCheckedOrInheritedChildren(binderTree);
                        this.Teams.setHasCheckedOrInheritedChildren(binderTree);
                    });
                }
                else {
                    this.Teams.setHasCheckedOrInheritedChildren(this.itemPermissions.tree);
                    this.Teams.setHasCheckedOrInheritedChildren(this.itemPermissions.tree);
                }
            }
        );
    }

    cancel(): void {
        this.modalInstance.hide();
    }


    save() {
        this.isProcessing = true;

        this.PermissionValidator.getMissingPermissions({ item: this.item, itemPermissions: this.itemPermissions })
            .subscribe(
                (data: CheckIsViewBinderPermissionMissingResponse) => {
                    const { localPermissions, externalPermissions } = data;
                    const missingPermissions = [...localPermissions, ...externalPermissions];

                    if (!missingPermissions.length) {
                        return this._savePermissionTree();
                    }

                    const modal = this.Modals.show(MissingPermissionsComponent, {
                        class: 'modal-md',
                        initialState: {
                            activeSubjectName: this.activeSubject.name,
                            missingPermissions: missingPermissions.map((permission) => {
                                return {
                                    name: permission.permissionName,
                                    objectInfo: permission.objectInfo
                                };
                            })
                        }
                    });

                    modal.content.dismiss.subscribe(() => {
                        modal.hide();
                        this.isProcessing = false;
                    });

                    modal.content.onSave.subscribe((event: MissingPermissionSaveEvent) => {
                        event.onSave();
                        if (externalPermissions.length) {
                            const permissions = externalPermissions.map((p) => p.saveParams);
                            return this.Permissions
                                .saveMissing(this.item.teamId, {
                                    permissions
                                })
                                .subscribe(this._savePermissionTree.bind(this));
                        }
                        localPermissions.forEach((permission) => permission.fix());
                        return this._savePermissionTree();
                    });

                }
            );
    }

    _savePermissionTree() {

        this.itemPermissions.subjectId = this.itemPermissions.subject.id;
        this.itemPermissions.subjectType = this.itemPermissions.subject.type;

        this.Teams.updatePermissions(this.itemPermissions)
            .subscribe(
                () => {
                    this.postSaveAction.emit();
                    this.refreshPermissionsTree();
                    this.modalInstance.hide();
                }, () => {
                    this.postSaveAction.emit();
                    this.isProcessing = false;
                }
            );
    }

    expandAllFor(name, permissions) {
        if (!this.expanded[name]) {
            this.expanded[name] = true;
        }
        Object.keys(permissions).forEach((key) => {
            permissions[key].expanded = true;
            if (permissions[key].permissions) {
                this.expandAllFor(name, permissions[key].permissions);
            }
        });
    }

    collapseAllFor(name, permissions) {
        if (this.expanded[name]) {
            this.expanded[name] = false;
        }
        Object.keys(permissions).forEach((key) => {
            permissions[key].expanded = false;
            if (permissions[key].permissions) {
                this.collapseAllFor(name, permissions[key].permissions);
            }
        });
    }

    getIconItem() {
        return this.item;
    }
}
