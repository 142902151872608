import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './destroy-structure-template.component.html';
import { DestroyEvent } from './destroy-structure-template.component.types';
import { StructureTemplate } from '../imported-structures-table/imported-structures-table.component.types';

@Component({
    selector: 'destroy-structure-template',
    template
})
export class DestroyStructureTemplateComponent implements OnInit {
    isProcessing = false;
    reason = '';
    @Input() template: StructureTemplate;
    @Output() destroy = new EventEmitter<DestroyEvent>();
    form: FormGroup;

    constructor(
        private fb: FormBuilder,
        public modal: BsModalRef
    ) {}

    ngOnInit(): void {
        this.initForm();
    }

    destroyTemplate(): void {
        this.isProcessing = true;

        const reason = this.form.controls.reason && this.form.controls.reason.value;
        this.destroy.emit({
            data: { template: this.template, reason },
            onError: () => {
                this.isProcessing = false;
            }
        });
    }

    cancel(): void {
        this.modal.hide();
    }

    ctrlHasError(ctrl: AbstractControl): boolean {
        return ctrl.dirty && ctrl.value !== this.template.name;
    }

    get templateFormCtrls(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    private initForm(): void {
        this.form = this.fb.group({
            name: ['', [Validators.required, notBlank]],
            reason: ['', [Validators.required, notBlank]]
        });
    }
}
