import { Component } from '@angular/core';

import template from './single-sign-on.component.html';
import styles from './single-sign-on.component.scss';

@Component({
    selector: 'single-sign-on',
    template,
    styles: [String(styles)]
})
export class SingleSignOnComponent { }
