import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EntityModule } from '@app/widgets/entity/entity.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '@app/shared/shared.module';
import { AuditTrailModalComponent } from './components/audit-trail-modal/audit-trail-modal.component';
import { PermissionsModule } from '../permissions/permissions.module';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TooltipModule.forRoot(),
        CommonModule,
        WidgetsModule,
        EntityModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        PermissionsModule
    ],
    providers: [],
    declarations: [
        AuditTrailModalComponent
    ],
    entryComponents: [
        AuditTrailModalComponent
    ],
    exports: []
})
export class AuditTrailModule { }
