import { ApiError, Team } from '@app/shared/models';
import {
    Component, EventEmitter, HostListener, OnInit, Output
} from '@angular/core';
import { ProjectsService } from '@app/shared/projects/projects.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectTemplateItem } from '@app/shared/projects/projects.service.types';
import { notBlank } from '@app/core/form-validators';
import template from './projectizer.component.html';
import style from './projectizer.component.scss';

@Component({
    selector: 'projectizer',
    template,
    styles: [String(style)]
})
export class ProjectizerComponent implements OnInit {

    @Output() onSubmit = new EventEmitter<void>();

    currentTeam: Team;
    items: ProjectTemplateItem[] = [];

    isProcessingPreview = false;
    isProcessingSave = false;
    isTutorialVisible = false;

    projectizerForm: FormGroup;

    constructor(
        private Projects: ProjectsService,
        private CurrentSession: CurrentSessionService,
        private Notifications: NotificationsService,
        private modalInstance: BsModalRef,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.initForm();
    }

    initForm() {
        this.projectizerForm = this.fb.group({
            template: ['', [Validators.required, notBlank]]
        });
    }

    cancel(): void {
        this.modalInstance.hide();
    }

    preview() {
        this.isProcessingPreview = true;

        this.Projects.previewProjectizer(this.currentTeam.id, this.projectizerForm.controls.template.value)
            .subscribe(
                ({ tree }) => {
                    this.items = tree;
                    this.isProcessingPreview = false;
                }, ({ error }: ApiError) => {
                    this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                    this.items = [];
                    this.isProcessingPreview = false;
                }
            );
    }

    save() {
        this.isProcessingSave = true;

        this.Projects.saveProjectizer(this.currentTeam.id, this.projectizerForm.controls.template.value)
            .subscribe(
                () => {
                    this.onSubmit.emit();
                    this.Notifications.success('Project structure successfully imported.');
                    this.modalInstance.hide();
                },
                ({ error }: ApiError) => {
                    this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                    this.isProcessingSave = false;
                }
            );
    }

    @HostListener('keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter' && document.activeElement.id === 'toogleTutorialBtn') {
            this.isTutorialVisible = !this.isTutorialVisible;
            event.preventDefault();
        }
    }

}
