import { filter } from 'rxjs/operators';
import { AppConfigService } from '@app/shared/app-config/app-config.service';
import { Team, TeamPermissions, User } from '@app/shared/models';
import { SessionsService } from '@app/shared/sessions/sessions.service';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';

import {
    Component, Inject, Input, OnChanges, OnInit,
    SimpleChanges
} from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import { StateService } from '@uirouter/core';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { NeedHelpComponent } from '@app/components/need-help/containers/need-help/need-help.component';
import logo from '../../../../images/florence-logo.png';
import template from './navbar.component.html';
import styles from './navbar.component.scss';

@Component({
    template,
    styles: [String(styles)]
})

export class NavbarComponent implements OnInit, OnChanges {
    @Input() currentUser: User;
    @Input() currentTeam: Team;
    @Input() hasMultipleTeams: boolean;

    public environment: string = this.AppConfig.config.environment;
    public logo: string = logo;
    public canUpdateTeamProfile: boolean;
    public canViewUserRolesPermissions: boolean;
    public canManageTeamMembers: boolean;
    public canManageTeamNotifications: boolean;
    public canViewTeamProfile: boolean;
    public canViewProjectsAndTimelines: boolean;
    public canManageTeamTags: boolean;
    public canAccessStudyStartup: boolean;
    public canViewLogTemplatesPermissions: boolean;
    public canViewDocumentSharing: boolean;
    public canAccessStudyManagement: boolean;
    public canViewTeamInbox: boolean;
    public studyManagerEnabled: boolean;
    teamId: string;
    private readonly zendeskUrl = 'https://florencehealthcare.zendesk.com';

    constructor(
        private AppConfig: AppConfigService,
        private Sessions: SessionsService,
        private CurrentSession: CurrentSessionService,
        private featureFlags: FeatureFlagService,
        private $state: StateService,
        private modalsService: ModalsService,
        @Inject('Window') private window: Window
    ) {

    }

    ngOnInit(): void {
        if (!this.currentTeam) {
            this.currentTeam = this.CurrentSession.getCurrentTeam();
        }
        if (!this.currentUser) {
            this.currentUser = this.CurrentSession.getCurrentUser();
        }

        this.featureFlags.getFlag(FEATURE_FLAGS.STUDY_MANAGER_ENABLED, true).pipe(
            filter((flag) => flag !== undefined)
        ).subscribe((value) => {
            this.studyManagerEnabled = value;
        });

        this.checkPermissions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.currentTeam) {
            this.currentTeam = changes.currentTeam.currentValue;
            this.checkPermissions();
        }
    }

    signOut(): void {
        this.Sessions.destroy();
    }

    private checkPermissions(): void {
        if (!this.currentTeam) {
            return;
        }

        const perm = this.currentTeam.permissions || {} as TeamPermissions;
        this.canUpdateTeamProfile = perm.updateTeamProfile;
        this.canViewUserRolesPermissions = perm.manageTeam || perm.viewTeamUsersRolesPermissions;
        this.canManageTeamMembers = this._canManageTeamMembers(perm);
        this.canManageTeamNotifications = this._canManageTeamNotifications(perm);
        this.canViewTeamProfile = perm.manageTeam || perm.viewTeamProfile;
        this.canViewProjectsAndTimelines = this._canManageTeamProjects(perm) || this._canManageProjectTimelines(perm);
        this.canManageTeamTags = this._canManageTeamTags(perm);
        this.canAccessStudyStartup = this._canAccessStudyStartup(perm);
        const logTemplatesEnabled = this.currentTeam.settings.features.logTemplates;
        this.canViewLogTemplatesPermissions = logTemplatesEnabled && this._canViewLogTemplates(perm);
        this.canViewDocumentSharing = this._canViewDocumentSharing();
        this.canAccessStudyManagement = this._canAccessStudyManagement(perm);
        this.canViewTeamInbox = perm.manageTeamInbox || perm.viewTeamInbox;
    }

    private _canViewDocumentSharing() {
        return this.currentTeam && this.currentTeam.permissions && this.currentTeam.permissions.documentSharingView;
    }

    private _canManageTeamMembers(perm: TeamPermissions): boolean {
        return perm.manageTeam
            || perm.viewTeamUsersRolesPermissions
            || perm.inviteUsers
            || perm.uninviteUsers;
    }

    private _canManageTeamNotifications(perm: TeamPermissions): boolean {
        return perm.viewTeamUsersRolesPermissions && perm.teamManageTeamNotifications;
    }

    private _canManageTeamProjects(perm: TeamPermissions): boolean {
        return perm.viewDashboard
            && (perm.manageProjects
                || perm.importProjects
                || perm.createProjects
                || perm.updateProjects
                || perm.deleteProjects
                || perm.duplicateProjects
            );
    }

    private _canManageProjectTimelines(perm: TeamPermissions): boolean {
        return perm.viewDashboard
            && (perm.manageTimelines
                || perm.createTimeline
                || perm.updateTimeline
                || perm.deleteTimelines
                || perm.duplicateTimelines
                || perm.assignToTimelines
            );
    }

    private _canManageTeamTags(perm: TeamPermissions): boolean {
        return perm.manageTeam
            || perm.createTags
            || perm.updateTags
            || perm.deleteTags;
    }

    private _canAccessStudyStartup(perm: TeamPermissions): boolean {
        return perm.manageTeam
            || perm.manageTeamStudyStartup
            || perm.createTeamStudies
            || perm.importTeamStudyStructureTemplate
            || perm.downloadTeamStructureTemplates
            || perm.deleteTeamStructureTemplates;
    }

    private _canViewLogTemplates(perm: TeamPermissions): boolean {
        return perm.manageTeam
            || perm.manageLogTemplates
            || perm.viewLogTemplates;
    }

    private _canAccessStudyManagement(perm: TeamPermissions): boolean {
        return (perm.manageTeam || (
            perm.createBinder
            && perm.createTeamStudies
            && perm.importTeamStudyStructureTemplate
            && perm.createFolder
            && perm.labelAssign
            && perm.createTags
            && perm.labelCreate
            && perm.labelEdit
            && perm.folderImportTemplates
            && perm.manageTeamRolesAndPermissions
            && perm.viewTeamUsersRolesPermissions
            && perm.renameBinder
            && perm.renameFolder
            && perm.viewBinder
            && perm.viewNonPiiInDocument
            && perm.createEditTeamStudyProfiles
            && perm.viewTeamStudyProfiles
            && perm.assignTeamRoles
        ));
    }

    openNeedHelp(): void {
        const { support } = this.currentTeam.settings;
        const teamHasSupport = support && support.email && support.phone;
        if (!teamHasSupport) {
            const url = this.$state.href('app.help-desk', { return_to: this.zendeskUrl });
            this.window.open(url, '_blank');
            return;
        }

        const needHelpModal = this.modalsService.show(NeedHelpComponent, {
            animated: false,
            initialState: {},
            class: 'modal-md'
        });

        needHelpModal.content.dismiss.subscribe(() => {
            needHelpModal.hide();
        });
    }
}
