import { StateOrName, RawParams, StateService } from '@uirouter/angular';
import { ActionBarConfig } from '@app/shared/action-bar/action-bar.service.types';
import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './signature-complete.component.html';

@Component({
    selector: 'signature-complete',
    template
})

export class SignatureCompleteComponent implements OnInit {

    @Input() actionOptions: ActionBarConfig;

    nextDocumentLink: { state: StateOrName; params: RawParams } | any;
    hasNextDocument = false;
    message = 'You\'ve completed all your signature requests. Have a great day!';

    constructor(
        private Modal: BsModalRef,
        private $state: StateService
    ) {}

    ngOnInit(): void {
        this.hasNextDocument = this.actionOptions
            && this.actionOptions.barOptions
            && !!this.actionOptions.barOptions.completeActionText;
        this.nextDocumentLink = this.hasNextDocument && this.actionOptions.barOptions.actionLink;
        this.message = this.hasNextDocument ? this.actionOptions.barOptions.completeActionText : this.message;
    }

    navigateToNextDocument(): void {
        this.$state.go(this.nextDocumentLink.state, this.nextDocumentLink.params);
        this.cancel();
    }

    cancel(): void {
        this.Modal.hide();
    }
}
