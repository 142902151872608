import {
    Component, Input, Output, EventEmitter, SimpleChanges, OnChanges
} from '@angular/core';
import * as _ from 'lodash';
import { SelectionState, SelectionStates, Team } from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Connection } from '../../../../services/document-sharing.types';
import template from './select-teams.component.html';
import styles from './select-teams.component.scss';

@Component({
    selector: 'select-teams',
    template,
    styles: [String(styles)]
})

export class SelectTeams implements OnChanges {
    loadingRoot = true;

    @Input() allTeams: Connection[] = [];
    @Input() selectedTeams: Connection[] = [];
    @Output() selectTeams = new EventEmitter<Connection[]>();
    @Output() deselectTeams = new EventEmitter<Connection[]>();

    public filteredTeams: Connection[];
    public selectedTeamsIds = new Set<string>();
    public selectionState: SelectionStates = 'NONE';
    public currentTeam: Team;

    constructor(private CurrentSession: CurrentSessionService) {}

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedTeams) {
            this.selectedTeamsIds = new Set(this.selectedTeams.map((t) => t._id));
        }
        if (changes.allTeams) {
            this.loadingRoot = changes.allTeams.isFirstChange();

            this.filteredTeams = this.allTeams;
            if (this.filteredTeams) {
                this.filteredTeams = this.sortTeams(this.allTeams);
            }
        }

        this.determineSelectionState();
    }

    onTeamsFilter(filter: string) {
        if (filter?.length) {
            const filterRegex = new RegExp(_.escapeRegExp(filter), 'i');
            this.filteredTeams = this.sortTeams(
                this.allTeams.filter((team) => filterRegex.test(this.getConnectedTeamName(team)))
            );
            return;
        }

        this.filteredTeams = this.sortTeams(this.allTeams);
    }

    onSelectTeams(teams: Connection[]) {
        teams.forEach((team) => {
            this.selectedTeamsIds.add(team._id);
        });

        this.selectTeams.emit(teams);
    }

    onDeselectTeams(teams: Connection[]) {
        teams.forEach((team) => {
            this.selectedTeamsIds.delete(team._id);
        });
        this.deselectTeams.emit(teams);
    }

    toggleSelectTeam(team: Connection) {
        if (this.selectedTeamsIds.has(team._id)) {
            this.onDeselectTeams([team]);
        }
        else {
            this.onSelectTeams([team]);
        }
    }

    getTeamSelectionState(team: Connection): SelectionStates {
        return this.selectedTeamsIds.has(team._id) ? SelectionState.FULL : SelectionState.NONE;
    }

    toggleSelectAll() {
        if (this.selectionState === SelectionState.NONE || this.selectionState === SelectionState.PARTIAL) {
            this.onSelectTeams(this.filteredTeams);
        }
        else {
            this.onDeselectTeams(this.filteredTeams);
        }
    }

    sortTeams(teams: Connection[]): Connection[] {
        const selectedTeams = [];
        const notSelectedTeams = [];

        teams.forEach((t) => {
            this.selectedTeamsIds.has(t._id) ? selectedTeams.push(t) : notSelectedTeams.push(t);
        });

        return [...selectedTeams, ...notSelectedTeams];
    }

    determineSelectionState() {
        const filteredSelectedIds = this.filteredTeams
            ? this.filteredTeams.map((t) => t._id).filter((t) => this.selectedTeamsIds.has(t)) : [];
        if (filteredSelectedIds.length && filteredSelectedIds.length < this.filteredTeams.length) {
            this.selectionState = SelectionState.PARTIAL;
        }
        else if (filteredSelectedIds.length && this.filteredTeams && filteredSelectedIds.length === this.filteredTeams.length) {
            this.selectionState = SelectionState.FULL;
        }
        else {
            this.selectionState = SelectionState.NONE;
        }
    }

    getConnectedTeamName(connection: Connection): string {
        return connection.configs.find((config) => config.teamId !== this.currentTeam.id)?.name;
    }
}
