import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { UserTeamTraining } from '../../models/user-team-training.model';

@Injectable()
export class UserTrainingApiService {
    constructor(private http: HttpClient) { }

    private readonly url = {
        trainingQueue: (teamId: string) => `/api/teams/${teamId}/trainings`
    }

    getTrainings$(
        teamId: string,
        trainingCompleted?: boolean
    ): Observable<UserTeamTraining[]> {
        let params = new HttpParams();
        if (trainingCompleted !== undefined) {
            params = new HttpParams().set('filter', JSON.stringify({ trainingCompleted }));
        }

        return this.http.get<UserTeamTraining[]>(
            this.url.trainingQueue(teamId),
            { params }
        ).pipe(retry(3));
    }
}
