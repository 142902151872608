import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import {
    AbstractControl, FormBuilder, Validators
} from '@angular/forms';
import { REGEX } from '@app/core/constants';
import { UserProfile } from '@app/shared/models';
import { notBlank } from '@app/core/form-validators';
import template from './user-profile-form.component.html';
import { UserConstants } from '../../users.constants';
import { UserProfileOnSaveParams } from './user-profile-form.component.types';

@Component({
    selector: 'user-profile-form',
    template
})
export class UserProfileFormComponent {
    @Input() profile: UserProfile;
    @Input() notificationEmail: string;
    @Input() emailAlias: string;
    @Output() save = new EventEmitter<UserProfileOnSaveParams>();

    jobFunctions = UserConstants.jobFunctions;
    orgTypes = UserConstants.orgTypes;
    products = UserConstants.products;

    userProfileForm = this.fb.group({
        firstName: ['', [
            Validators.required,
            Validators.pattern(REGEX.noLeadingOrTrailingWithespace),
            Validators.pattern(REGEX.names),
            Validators.maxLength(250),
            notBlank
        ]],
        lastName: ['', [
            Validators.required,
            Validators.pattern(REGEX.noLeadingOrTrailingWithespace),
            Validators.pattern(REGEX.names),
            Validators.maxLength(250),
            notBlank
        ]],
        orgType: ['', [Validators.required, notBlank]],
        orgName: ['', [Validators.required, Validators.pattern(REGEX.noLeadingOrTrailingWithespace),
            Validators.pattern(REGEX.names), notBlank]],
        jobFunction: ['', [Validators.required, notBlank]],
        jobTitle: ['', [Validators.required, Validators.pattern(REGEX.noLeadingOrTrailingWithespace),
            Validators.pattern(REGEX.names), notBlank]]
    });

    constructor(
        private fb: FormBuilder
    ) {}

    onSave(): void {
        this.userProfileForm.markAsPristine();
    }

    updateUserProfile(): void {
        if (this.userProfileForm.valid) {
            this.save.emit({ profile: this.userProfileForm.value, onSave: this.onSave.bind(this) });
        }
    }

    onReset(): void {
        this.userProfileForm.reset();
        this.initializeForm();
    }

    ngOnInit(): void {
        this.initializeForm();
    }

    ctrlHasError(ctrl: AbstractControl): boolean {
        return ctrl.invalid && (ctrl.dirty || ctrl.touched);
    }

    private initializeForm(): void {
        if (this.profile.lastName) {
            this.userProfileForm.patchValue({ lastName: this.profile.lastName.trim() });
        }
        if (this.profile.firstName) {
            this.userProfileForm.patchValue({ firstName: this.profile.firstName.trim() });
        }
        if (this.profile.orgType) {
            this.userProfileForm.patchValue({
                orgType: this.profile.orgType
            });
        }
        if (this.profile.orgName) {
            this.userProfileForm.patchValue({
                orgName: this.profile.orgName.trim()
            });
        }
        if (this.profile.jobFunction) {
            this.userProfileForm.patchValue({
                jobFunction: this.profile.jobFunction
            });
        }
        if (this.profile.jobTitle) {
            this.userProfileForm.patchValue({
                jobTitle: this.profile.jobTitle.trim()
            });
        }
        this.userProfileForm.markAsPristine();
    }
}
