import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import template from './imported-structures-table.component.html';
import styles from './imported-structures-table.component.scss';
import { StructureTemplate } from './imported-structures-table.component.types';
@Component({
    selector: 'imported-structures-table',
    template,
    styles: [String(styles)]
})
export class ImportedStructuresTableComponent {
    @Input() loading: boolean;
    @Input() studies: StructureTemplate[] = null;
    @Input() hasDeletePermission = false;
    @Input() hasDownloadPermission = false;
    @Input() createdStructureId: string;
    @Input() hasViewAuditsPermission = false;
    @Output() preview = new EventEmitter<string>();
    @Output() delete = new EventEmitter<StructureTemplate>();
    @Output() download = new EventEmitter<string>();
    @Output() viewAudit = new EventEmitter<StructureTemplate>();

    previewAction(id: string): void {
        this.preview.emit(id);
    }

    deleteAction(selectedTemplate: StructureTemplate): void {
        if (!this.hasDeletePermission) {
            return;
        }

        this.delete.emit(selectedTemplate);
    }

    downloadTemplate(structureTemplate: StructureTemplate): void {
        this.download.emit(structureTemplate.id);
    }

    previewAuditTrails(selectedTemplate: StructureTemplate): void {
        if (!this.hasViewAuditsPermission) {
            return;
        }

        this.viewAudit.emit(selectedTemplate);
    }
}
