import { Inject, Injectable } from '@angular/core';
import { InboxDocument } from './team-inbox.types';

@Injectable()
export class TeamInboxStateService {
    readonly storageKey = 'inboxDocument';

    constructor(
        @Inject('Window') private $window: Window
    ) {}

    getInboxDocument(): InboxDocument {
        return JSON.parse(this.$window.localStorage.getItem(this.storageKey));
    }

    setInboxDocument(document: InboxDocument): void {
        this.$window.localStorage.setItem(this.storageKey, JSON.stringify(document));
    }
}
