'use strict';

module.exports = {
    _id: 1,
    folderId: 1,
    binderId: 1,
    subType: 1,
    dueDate: 1,
    expirationDate: 1,
    pendingTasksCount: 1,
    requestedSignaturesCount: 1,
    shortcutOf: 1,
    lineage: 1,
    path: 1,
    isBrokenShortcut: 1,
    type: 1,
    title: 1,
    signatures: 1,
    timelinesCount: 1,
    ext: 1,
    tags: 1,
    rawFilename: 1,
    'file.md5': 1,
    conversionStatus: 1,
    formStatus: 1,
    hasPii: 1,
    nextDueSignatureDate: 1,
    isLocked: 1,
    responsibilities: 1
};
