import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, pluck } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';

import {
    BatchResponseItem,
    BrowseTree,
    Document,
    Folder,
    FoldersArchives,
    LabeledEntity,
    NamesPreview,
    Study
} from '../models';
import {
    BaseFoldersServiceParams,
    BrowseHTTPParams,
    CreateFolderParams,
    CreatePlaceholderParams,
    DestroyParams,
    DocumentCountsParams,
    FolderCustomCloneParams,
    FoldersBrowseParams,
    FoldersCloneBatchParams,
    GetFolderNamesPreviewParams,
    UpdateJobTitleRequiredParams,
    FoldersCloneBatchBody,
    DocumentCountsResponse,
    FolderCustomCloneBody,
    FoldersMoveToParams,
    FolderizeResponse,
    FolderizeParams,
    FolderRenameParams,
    FolderRenameResponse,
    UpdateJobTitleRequiredResponseItem,
    DestroyFolderResponse,
    MoveFoldersResponse
} from './folders.service.types';

@Injectable()
export class FoldersService {
    private readonly url = {
        base: ({ teamId, binderId, folderId }: BaseFoldersServiceParams): string => `/api/teams/${teamId}/binders/${binderId}/folders/${folderId}`,
        folders: (teamId: string, binderId: string): string => `/api/teams/${teamId}/binders/${binderId}/folders`,
        browse: (teamId: string): string => `/api/teams/${teamId}/browse`,
        folderizer: (teamId: string, binderId: string): string => `/api/teams/${teamId}/binders/${binderId}/template`,
        counts: (teamId: string): string => `/api/teams/${teamId}/documents/counts`,
        placeholders: '/api/placeholders',
        labels: ({ teamId, binderId, folderId }: BaseFoldersServiceParams): string => `/api/teams/${teamId}/binders/${binderId}/folders/${folderId}/labels`,
        studies: ({ teamId, binderId, folderId }: BaseFoldersServiceParams): string => `/api/teams/${teamId}/binders/${binderId}/folders/${folderId}/study-profile`,
        cloneBatch: (teamId: string, binderId: string): string => `/api/teams/${teamId}/binders/${binderId}/folders/clone`,
        customClone: ({ teamId, binderId, folderId }: BaseFoldersServiceParams): string => `/api/teams/${teamId}/binders/${binderId}/folders/${folderId}/clone`,
        namesPreview: ({ teamId, binderId, folderId }: BaseFoldersServiceParams): string => `/api/teams/${teamId}/binders/${binderId}/folders/${folderId}/names-preview`,
        jobTitleRequired: (teamId: string, binderId: string): string => `/api/teams/${teamId}/binders/${binderId}/folders/job-title-required`,
        archives: (teamId: string): string => `/api/teams/${teamId}/archives`,
        exists: ({ teamId, binderId, folderName }): string => `/api/teams/${teamId}/binders/${binderId}/folders/name/${folderName}`
    };

    constructor(private $http: HttpClient) { }

    create({
        teamId, binderId, folderId, name
    }: CreateFolderParams): Observable<Folder> {
        const url = folderId
            ? this.url.base({ teamId, binderId, folderId })
            : this.url.folders(teamId, binderId);
        return this.$http
            .post<{ folder: Folder }>(url, { name })
            .pipe(pluck('folder'));
    }

    getDirectLabels(urlParams: BaseFoldersServiceParams): Observable<LabeledEntity[]> {
        const url = this.url.labels(urlParams);
        const params = new HttpParams().set('directOnly', 'true');
        return this.$http.get<LabeledEntity[]>(url, { params });
    }

    getFolderStudyProfile(params: BaseFoldersServiceParams): Observable<Study[]> {
        const url = this.url.studies(params);
        return this.$http.get<Study[]>(url);
    }

    getLabels(params: BaseFoldersServiceParams): Observable<LabeledEntity[]> {
        return this.$http.get<LabeledEntity[]>(this.url.labels(params));
    }

    createPlaceholder(params: CreatePlaceholderParams): Observable<Document> {
        if (!params.folderId) {
            // Set to undefined if falsey to pass server joi validation
            params.folderId = undefined;
        }

        const url = this.url.placeholders;
        return this.$http.post<{ document: Document }>(url, params)
            .pipe(pluck('document'));
    }

    destroy({
        teamId, binderId, id, reason
    }: DestroyParams): Observable<DestroyFolderResponse> {
        const urlParams = { teamId, binderId, folderId: id };
        const url = this.url.base(urlParams);
        return this.$http.delete<DestroyFolderResponse>(url, { params: { reason } });
    }

    browse({
        teamId, binderId, folderId, includeDocs = true, includeDecorations = true, includeArchived = true
    }: FoldersBrowseParams): Observable<BrowseTree> {
        const url = this.url.browse(teamId);
        const httpParams: BrowseHTTPParams = {
            includeDocs,
            includeDecorations,
            includeArchived,
            objectId: folderId || binderId,
            objectType: folderId ? 'folder' : 'binder'
        };
        const params = httpParams
            && Object.keys(httpParams).reduce((httpParam, param) => {
                return httpParams[param] !== undefined || httpParams[param] !== null
                    ? httpParam.set(param, httpParams[param])
                    : httpParam;
            }, new HttpParams());
        return this.$http.get<{ root: BrowseTree }>(url, { params })
            .pipe(pluck('root'));
    }

    documentCounts({ teamId, folderIds }: DocumentCountsParams): Observable<DocumentCountsResponse[]> {
        const folderIdsChunks = _.chunk(folderIds, 200);

        const makeBatchedCountRequest = (folderIdsChunk: string[]): Observable<DocumentCountsResponse> => {
            const params = { objectType: 'folders', objectIds: folderIdsChunk };
            const url = this.url.counts(teamId);
            return this.$http.get<DocumentCountsResponse>(url, { params });
        };

        return forkJoin(folderIdsChunks.map(makeBatchedCountRequest)).pipe(map(_.flatten));
    }

    cloneBatch({
        teamId, binderId, folders, destination
    }: FoldersCloneBatchParams): Observable<BatchResponseItem<Folder>[]> {
        const payload: FoldersCloneBatchBody = {
            destination,
            folderIds: folders.map((folder) => folder.id)
        };
        const url = this.url.cloneBatch(teamId, binderId);
        return this.$http.post<BatchResponseItem<Folder>[]>(url, payload);
    }

    customClone({
        teamId, binderId, folder, destination, folderCloneCustomName
    }: FolderCustomCloneParams): Observable<BatchResponseItem<Folder>[]> {
        const payload: FolderCustomCloneBody = {
            destination,
            cloneInstructions: [
                {
                    numberOfClones: 1,
                    name: folderCloneCustomName || folder.name
                }
            ]
        };
        const url = this.url.customClone({ teamId, binderId, folderId: folder.id });
        return this.$http.post<BatchResponseItem<Folder>[]>(url, payload);
    }

    moveTo({ teamId, destination, itemsBeingMoved }: FoldersMoveToParams): Observable<MoveFoldersResponse> {
        const binderId = destination.binderId || destination.id;
        const folderId = destination.type === 'folder' ? destination.id : null;
        const url = this.url.folders(teamId, itemsBeingMoved[0].binderId);
        const payload = {
            newBinderId: binderId,
            folderIds: itemsBeingMoved.map((item) => item.id),
            ...(folderId && { newFolderId: folderId })
        };
        return this.$http.patch<MoveFoldersResponse>(url, payload);
    }

    folderize({
        item, template, previewOnly = true
    }: FolderizeParams): Observable<FolderizeResponse> {
        const urlParams = {
            teamId: item.teamId,
            binderId: item.type === 'folder' ? item.binderId : item.id
        };
        const url = this.url.folderizer(urlParams.teamId, urlParams.binderId);
        const payload = {
            template,
            previewOnly,
            ...(item.type === 'folder' && { folderId: item.id })
        };
        return this.$http.post<FolderizeResponse>(url, payload);
    }

    rename({
        teamId, binderId, folderId, newName
    }: FolderRenameParams): Observable<FolderRenameResponse> {
        if (!newName) {
            return of({ folders: [], errors: [] });
        }

        const url = this.url.folders(teamId, binderId);
        const folderIds = [folderId];
        const body = { name: newName, folderIds };
        return this.$http.patch<FolderRenameResponse>(url, body);
    }

    updateJobTitleRequired({
        teamId, binderId, folderIds, jobTitleRequired
    }: UpdateJobTitleRequiredParams): Observable<UpdateJobTitleRequiredResponseItem[]> {
        const url = this.url.jobTitleRequired(teamId, binderId);
        return this.$http.patch<UpdateJobTitleRequiredResponseItem[]>(url, { folderIds, jobTitleRequired });
    }

    getNamesPreview({
        teamId, binderId, folderId, names
    }: GetFolderNamesPreviewParams): Observable<NamesPreview> {
        const payload = { entityType: 'document', names };
        const url = this.url.namesPreview({ teamId, binderId, folderId });
        return this.$http.post<NamesPreview>(url, payload);
    }

    getArchives(teamId: string): Observable<FoldersArchives> {
        return this.$http.get<FoldersArchives>(this.url.archives(teamId));
    }

    checkFolderExists(teamId: string, binderId: string, folderName: string): Observable<{name: string}> {
        return this.$http.get<{name: string}>(encodeURI(this.url.exists({ teamId, binderId, folderName })));
    }
}
