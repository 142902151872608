import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import * as _ from 'lodash';
import { CurrentSessionService } from '@app/core/current-session.service';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { sortBrowseTreeLexicographically } from '@app/widgets/sort/sort-browse-tree-lexicographically.util';
import { VirtualTreeSelectionMode } from '@app/widgets/virtual-tree/virtual-tree.component.types';
import { BindersService } from '@app/shared/binders/binders.service';
import {
    Binder, BrowseTree, Folder, Team
} from '@app/shared/models';
import { TeamService } from '@app/shared/teams/team.service';
import { BrowseParams } from '@app/shared/teams/teams.service.types';

import { SearchService } from '@app/shared/search/search.service';
import { calculateEntityPath } from '@app/shared/documents/calculate-entity-path.util';
import template from './choose-binders-folders.component.html';
import styles from './choose-binders-folders.component.scss';

@Component({
    selector: 'choose-binders-folders',
    template,
    styles: [String(styles)]
})
export class ChooseBindersFoldersComponent implements OnInit {
    @Input() objects: (Binder | Folder)[];
    @Output() change = new EventEmitter<(Binder | Folder)[]>();
    @Output() dismiss = new EventEmitter<void>();

    $destroy = new Subject<void>();
    selectionMode = VirtualTreeSelectionMode.MULTI_SELECT;
    inheritedItemIds: string[];
    loadingRoot = false;
    currentTeam: Team;
    binders: Binder[];
    filterLabel = 'Filter Binders and Folders';

    filteredNodes = [];

    constructor(
        private CurrentSession: CurrentSessionService,
        private Binders: BindersService,
        private Teams: TeamService,
        private Search: SearchService
    ) { }


    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.loadingRoot = true;
        this.Binders.getBinders(this.currentTeam.id, { includeArchived: false })
            .pipe(takeUntil(this.$destroy))
            .subscribe(
                (binders) => {
                    this.binders = sortByLexicographically(binders, 'name');
                    this.loadingRoot = false;
                }
            );
        this.resolveInheritedItems(this.objects || []);
    }

    getSelectedNodeIds = () => {
        return this.inheritedItemIds;
    }


    loadItem = (params: BrowseParams): ng.IPromise<BrowseTree> => {
        params.includeDocs = false;
        params.includeArchived = false;
        return this.Teams.browse(this.currentTeam.id, params)
            .toPromise().then((data) => {
                return sortBrowseTreeLexicographically(data, 'name');
            });
    }

    resolveInheritedItems(items): void {
        this.inheritedItemIds = _.flattenDeep(items.map((item) => {

            if (item.type === 'folder') {
                return [
                    item.binderId,
                    ...item.lineage
                ];
            }
            return [item.id];
        }));
    }

    onSelectionChange = ($event: { selectedItems: (Binder | Folder)[] }): void => {
        this.objects = $event.selectedItems;
        this.resolveInheritedItems($event.selectedItems);
        this.getSelectedNodeIds();
    }

    onToggleSelectAllEvent = ($event: boolean): void => {
        this.objects = $event ? this.binders : [];
    }

    isItemSelectable(item): boolean {
        return item.type !== 'document';
    }

    submitDisabled(): boolean {
        return !this.objects?.length;
    }

    dismissModal(): void {
        this.dismiss.emit();
    }

    proceed(): void {
        this.change.emit(this.objects);
        this.dismissModal();
    }

    filterBindersFolders($event): void {
        this.loadingRoot = true;
        this.Search.getBindersFolders($event).subscribe((data) => {
            this.filteredNodes = data.result;
            this.filteredNodes = this.filteredNodes.map(
                (entity) => {
                    entity.path = calculateEntityPath(entity);
                    return entity;
                }
            );
            this.loadingRoot = false;
        });
    }
}
