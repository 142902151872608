import { Component } from '@angular/core';

@Component({
    selector: 'main-app',
    template: `
    <ngx-loading-bar
    [includeSpinner]="false">
    </ngx-loading-bar>
    <ui-view></ui-view>
    `
})

export class AppComponent {}
