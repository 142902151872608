import {
    Directive, ElementRef, EventEmitter, Output
} from '@angular/core';


@Directive({
    selector: '[isOverflowing]'
})
export class IsOverflowingDirective {
    @Output() isOverflowing = new EventEmitter<boolean>();

    constructor(private elementRef: ElementRef<HTMLElement>) {
        this.handleElementResize();
    }

    private handleElementResize(): void {
        const resizeObserver = new ResizeObserver((entries) => {
            const element = entries.find(
                (entry) => entry.target === this.elementRef.nativeElement
            );

            if (element && element.target.scrollWidth > element.target.clientWidth) {
                this.isOverflowing.emit(true);
            }
            else {
                this.isOverflowing.emit(false);
            }
        });

        resizeObserver.observe(this.elementRef.nativeElement);
    }
}
