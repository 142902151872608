import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TeamSipIntegrationLink } from '@app/shared/models';

@Injectable()
export class TeamSipLinksService {
    readonly url = {
        base: (teamId: string): string => `/api/teams/${teamId}/team-sip-integration-links`,
        byId: (teamId: string, id: string): string => `/api/teams/${teamId}/team-sip-integration-links/${id}`,
        sponsorNames: (): string => '/api/sip-sponsor-names'
    }

    constructor(private http: HttpClient) {}

    getTeamSipLinks(teamId: string): Observable<TeamSipIntegrationLink[]> {
        return this.http.get<TeamSipIntegrationLink[]>(this.url.base(teamId));
    }

    createTeamSipLink(params: Partial<TeamSipIntegrationLink>): Observable<TeamSipIntegrationLink> {
        return this.http.post<TeamSipIntegrationLink>(this.url.base(params.teamId), params);
    }

    deleteTeamSipLink(params: Partial<TeamSipIntegrationLink>, reason: string): Observable<void> {
        return this.http.delete<void>(this.url.byId(params.teamId, params.id), { params: { reason } });
    }

    getSponsorNames(): Observable<{ sipSponsorId: string; sponsorName: string }[]> {
        return this.http.get<{ sipSponsorId: string; sponsorName: string }[]>(this.url.sponsorNames());
    }
}
