import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import { downgradeComponent } from '@angular/upgrade/static';

import documentSharingRoute from './document-sharing.route';
import { DocumentSharingComponent } from './containers/document-sharing/document-sharing.component';
import { DocumentSharingTabsContainer } from './containers/document-sharing-tabs-container/document-sharing-tabs-container.component';
import { ShareDocumentsSummaryComponent } from './components/share-documents/wizard-steps/share-documents-summary/share-documents-summary.component';
import { DocumentSharingPaginationComponent } from './components/document-sharing-pagination/document-sharing-pagination.component';

const dependencies = [uiRouter];

const documentSharingModule = angular
    .module('app.document-sharing', dependencies)
    .config(documentSharingRoute)
    .directive('documentSharing', downgradeComponent({ component: DocumentSharingComponent }) as ng.IDirectiveFactory)
    .directive('documentSharingTabsContainer', downgradeComponent({ component: DocumentSharingTabsContainer }) as ng.IDirectiveFactory)
    .directive('shareDocumentsSummary', downgradeComponent({ component: ShareDocumentsSummaryComponent }) as ng.IDirectiveFactory)
    .directive('documentSharingPaginationComponent', downgradeComponent({ component: DocumentSharingPaginationComponent }) as ng.IDirectiveFactory);

export default documentSharingModule;
