import { Injectable, OnDestroy } from '@angular/core';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import {
    BehaviorSubject, Observable
} from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AppConfigService } from '@app/shared/app-config/app-config.service';
import { Team, User } from '@app/shared/models';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService implements OnDestroy {
    multiContext: LaunchDarkly.LDMultiKindContext | null = null;
    client!: LaunchDarkly.LDClient;
    flags: LaunchDarkly.LDFlagSet = [];

    private _flagChange$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    flagChange$: Observable<any> = this._flagChange$.asObservable();

    constructor(
        private AppConfig: AppConfigService
    ) {}

    private _createUserContext(user: User) {
        return {
            kind: 'user',
            key: user?.id,
            name: user?.fullName,
            email: user?.email
        } as const;
    }

    private _createTeamContext(team: Team) {
        return {
            kind: 'ebinder-team',
            key: team.id
        } as const;
    }

    private _createSponsorContext(team: Team) {
        return {
            kind: 'ebinder-sponsor',
            key: team.sponsorSalesforceAccount?.id
        } as const;
    }

    async ngOnDestroy() {
        await this.client.close();
    }

    initialize(currentUser: User | undefined) {
        this.multiContext = {
            kind: 'multi',
            user: this._createUserContext(currentUser)
        } as LaunchDarkly.LDMultiKindContext;

        this.client = LaunchDarkly.initialize(
            this.AppConfig.config.launchDarklyClientSideId,
            this.multiContext
        );
    }

    patchContext(entity: Team | undefined) {
        this.multiContext = {
            ...this.multiContext && this.multiContext,
            'ebinder-team': this._createTeamContext(entity),
            ...entity?.sponsorSalesforceAccount?.id && { 'ebinder-sponsor': this._createSponsorContext(entity) }
        } as LaunchDarkly.LDMultiKindContext;

        if (this.client) {
            this.client.identify(this.multiContext, undefined);
        }
        else {
            this.client = LaunchDarkly.initialize(this.AppConfig.config.launchDarklyClientSideId, this.multiContext);
        }
    }

    getFlag(flagKey: string, defaultValue: LaunchDarkly.LDFlagValue): Observable<LaunchDarkly.LDFlagValue> {
        this.client.waitUntilReady().then(() => {
            this.flags = this.client.allFlags();
            const flagsArray = Object.entries(this.flags);

            flagsArray.filter(([key, value]) => {
                if (key === flagKey) {
                    this.setFlag(key, defaultValue);
                    return value;
                }
                return defaultValue;

            });

            this.client.on('initialized', (flags) => {
                this.flags = { ...flags };
            });

            this.client.on(`change:${flagKey}`, () => {
                this.setFlag(flagKey, defaultValue);
            });
        });

        return this.flagChange$.pipe(
            map((flags) => flags[flagKey]),
            distinctUntilChanged()
        );
    }

    setFlag(flagKey: string, defaultValue: any) {
        const flags = this._flagChange$.getValue();
        const newValue = this.client.variation(flagKey, defaultValue);
        const newFlags = { ...flags, [flagKey]: newValue };

        this._flagChange$.next(newFlags);
    }
}
