import {
    Component, EventEmitter, OnInit,
    Output
} from '@angular/core';
import { User } from '@app/shared/models';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { Auth0Service } from '@app/shared/sessions/auth0.service';
import { UsersService } from '@app/shared/users/users.service';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { AppConfigService } from '@app/shared/app-config/app-config.service';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import template from './password-expiry-reminder.component.html';
import styles from './password-expiry-reminder.component.scss';

@Component({
    selector: 'password-expiry-reminder',
    template,
    styles: [String(styles)]
})
export class PasswordExpiryReminderComponent implements OnInit {

    public user: User;
    @Output() dismiss = new EventEmitter<void>();

    constructor(
        private AppConfig: AppConfigService,
        private Users: UsersService,
        private Notifications: NotificationsService,
        private Auth0: Auth0Service,
        private CurrentSession: CurrentSessionService,
        private ApiError: ApiErrorsService
    ) {}

    ngOnInit() {
        this.user = this.CurrentSession.getCurrentUser();
    }

    cancel(): void {
        this.dismiss.emit();
    }

    sendResetLink(): void {
        this.Users.resetPasswordAuth0(this.user.emailCanonical).subscribe(() => {
            this.Notifications.success('Reset password link has been sent to your email, as security measure you will be logout!');
            this.dismiss.emit();
            timer(1000).pipe(take(1)).subscribe(() => {
                this.Auth0.logout({
                    returnTo: `${this.AppConfig.config.authHost}/#/sign-in?email=${this.user.emailCanonical}`, federated: true
                });
            });
        }, (error) => {
            this.ApiError.handleError(error);
        });

    }
}
