export enum SigningReasons {
  acknowledge = 'Acknowledge',
  approval = 'Approval',
  authorship = 'Authorship',
  certifiedCopy = 'Certified Copy',
  responsibility = 'Responsibility',
  review = 'Review'
}

export enum DeclineReasons {
  decline = 'Decline'
}

export interface Signature {
  user: {
    userId: string;
    name: string;
    email: string;
    notificationEmail?: string;
    jobTitle?: string;
    id?: string;
  };
  notifyRequestorWhenSigned: string;
  version: number;
  reason: SigningReasons|DeclineReasons;
  status: 'Pending' | 'Signed' | 'Declined';
  signatureComment: string;
  timestamp: Date|string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  requestedAt: string;
  entryRow?: number|null;
}

export enum SignatureTypes {
  any = 'Any',
  stamp = 'Stamp',
  addendum = 'Addendum',
  log = 'Log'
}

export interface SignatureRequest {
  notifyUsersOnSigned: string[];
  signByDate: Date|string|null;
  userId: string;
  teamId: string;
  objectId: string;
  entryId: string|null;
  entryColumnName?: string|null;
  entryRow?: number|null;
  reason: SigningReasons;
  method: SignatureTypes;
  updatedBy: string;
  createdBy: string;
  sequence: number;
  comments: SignatureRequestComment[];
  createdAt: string;
  updatedAt: string;
  id: string;
  isDue: boolean;
  user: {
    id: string;
    name: string;
    email: string;
  };
  requestedAt: string;
  isAlmostDue?: boolean;
}

export interface SignatureRequestComment {
  comment: string;
  requestorId: string;
  createdAt: string;
  updatedAt: string;
  user: {
    email: string;
    name: string;
  };
}

export interface SignatureRequestResponse {
  newRequests: SignatureRequest[];
  updatedRequests: SignatureRequest[];
  cancelledRequestCount: number;
  updatedAt?: string;
}
