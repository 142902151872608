import {
    Component, Input, EventEmitter, Output, OnInit
} from '@angular/core';
import {
    AbstractControl,
    FormBuilder, FormGroup, ValidationErrors, Validators
} from '@angular/forms';
import template from './confirm-destroy.component.html';
import { ConfirmDestroySubmitEvent } from './confirm-destroy.component.types';

@Component({
    selector: 'confirm-destroy',
    template
})
export class ConfirmDestroyComponent implements OnInit {
    @Input() warningText: string;
    @Input() bodyText: string;
    @Input() requireReason: boolean;
    @Input() confirmLabel: string;
    @Input() confirmValue: string;
    @Input() confirmVerb: string;
    @Input() confirmVerbProcessing: string;

    @Output() save = new EventEmitter<ConfirmDestroySubmitEvent>();
    @Output() dismiss = new EventEmitter<void>();

    form: FormGroup;
    isProcessing = false;

    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        this.confirmVerb = this.confirmVerb || 'Delete';
        this.confirmVerbProcessing = this.confirmVerbProcessing || 'Deleting';
        this.initForm();
    }

    dismissModal(): void {
        this.dismiss.emit();
    }

    onSave(): void {
        if (this.isProcessing) {
            return;
        }

        const reason = this.form.controls.reason && this.form.controls.reason.value;

        this.isProcessing = true;
        this.save.emit({
            data: { reason },
            onSuccess: () => {
                this.dismissModal();
            },
            onError: () => {
                this.isProcessing = false;
            }
        });
    }

    private initForm(): void {
        const controlConfig: { [key: string]: [string, ((control: AbstractControl) => ValidationErrors)[]] } = {};

        if (this.confirmLabel && this.confirmValue) {
            controlConfig.name = ['', [
                Validators.required,
                (control: AbstractControl): ValidationErrors | null => {
                    const validName = control.value === this.confirmValue;

                    return validName ? null : { wrongName: control.value };
                }
            ]];
        }

        if (this.requireReason) {
            controlConfig.reason = ['', [Validators.required]];
        }

        this.form = this.fb.group(controlConfig);
    }
}
