import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule, ScrollDispatcher, ViewportRuler } from '@angular/cdk/scrolling';
import { Platform } from '@angular/cdk/platform';

import { SharedModule } from '@app/shared/shared.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { GlobalViewComponent } from './containers/global-view/global-view.component';
import { GlobalViewItemComponent } from './components/global-view-item/global-view-item.component';
import { RootSelectComponent } from './components/global-view-select-root/global-view-select-root.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        WidgetsModule,
        ScrollingModule
    ],
    providers: [
        { provide: Window, useValue: window },
        ScrollDispatcher,
        Platform,
        ViewportRuler
    ],
    declarations: [
        GlobalViewComponent,
        GlobalViewItemComponent,
        RootSelectComponent
    ],
    exports: [
        GlobalViewComponent,
        GlobalViewItemComponent
    ],
    entryComponents: [
        GlobalViewComponent,
        GlobalViewItemComponent,
        RootSelectComponent
    ]
})
export class GlobalViewModule { }
