export enum ReportNames {
    pastDueDates = 'Placeholders Past Due',
    dueDates = 'Placeholders Due',
    dueDates2Days = 'Placeholders Due - In 2 Days',
    dueDates14Days = 'Placeholders Due - In 14 Days',
    dueDates30Days = 'Placeholders Due - In 30 Days',
    dueDates60Days = 'Placeholders Due - In 60 Days',
    pastExpirationDates = 'Documents Expired',
    expirationDates = 'Documents Expiring',
    expirationDates2Days = 'Documents Expiring - In 2 Days',
    expirationDates14Days = 'Documents Expiring - In 14 Days',
    expirationDates30Days = 'Documents Expiring - In 30 Days',
    expirationDates60Days = 'Documents Expiring - In 60 Days',
    signaturesCompleted = 'Signatures - Completed',
    signaturesMyQueue = 'Signatures - My Queue',
    signaturesPending = 'Signatures - Pending',
    signaturesDeclined = 'Signatures - Declined',
    signaturesSignByDate = 'Signatures - Sign By Date',
    tasksClosed = 'Tasks - Closed',
    tasksMyQueue = 'Tasks - My Queue',
    tasksPending = 'Tasks - Pending',
    approvalsApproved = 'Approvals - Approved',
    approvalsRejected = 'Approvals - Rejected',
    approvalsCancelled = 'Approvals - Cancelled',
    approvalsPendingSignatures = 'Approvals - Pending Signatures',
    approvalsReadyForApproval = 'Approvals - Ready for Approval',
    approvalsPendingForm = 'Approvals - Pending Form Completion',
    labels = 'Labels - Assigned',
    tags = 'Tags',
    studies = 'Study Profile',
    monitorReviewsOpen = 'Monitor Reviews - Open Query',
    monitorReviewsApproved = 'Monitor Reviews - Approved and Reviewed',
    sipDocumentStatuses = 'SIP - Document Statuses',
    documentsRecentlyUploaded = 'Documents - Recently Uploaded',
    documentsOverview = 'Documents - Overview',
    sipDocumentsSent = 'SIP - Documents Sent',
    qcReport = 'QC Review - General',
    qcReviewMyQueue = 'QC Review - My Queue'
}

export enum ReportGroupsNames {
    myQueue = 'my queue',
    documents = 'documents',
    labels = 'labels',
    placeholders = 'placeholders',
    reviews = 'Monitor Reviews',
    signatures = 'signatures',
    studyProfiles = 'study profiles',
    tags = 'tags',
    tasks = 'tasks',
    approvals = 'approvals',
    sip = 'SIP',
    qc = 'QC Reviews'
}
