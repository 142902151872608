import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';

import {
    Document
} from '@app/shared/models';

import { qcReviewStatuses } from '@florencehealthcare/florence-constants/lib/qc-reviews';
import { ApproveReviewEvent, RejectReviewEvent } from '@app/components/qc-reviews/components/document-qc-review/document-handle-qc-review.component.types';
import { QcAcceptEvent, QcDeclineEvent } from './log-toolbar.component.types';

import template from './log-toolbar.component.html';
import styles from './log-toolbar.component.scss';

@Component({
    selector: 'log-toolbar',
    template,
    styles: [String(styles)]
})
export class LogToolbarComponent implements OnInit, OnChanges {
    @Input() doc: Document;
    @Input() currentUserId: string;
    @Output() qcAcceptLog = new EventEmitter<QcAcceptEvent>();
    @Output() qcDeclineLog = new EventEmitter<QcDeclineEvent>();
    @Output() approveQcReview = new EventEmitter<ApproveReviewEvent>();
    @Output() rejectQcReview = new EventEmitter<RejectReviewEvent>();

    qcReviewAction;
    comment = '';
    reviewToConduct = null;
    isProcessingAccept = false;
    isProcessingDecline = false;
    showQcAcceptButton = false;
    showQcDeclineButton = false;
    showQcReviewButton = false;
    isDocumentLocked = false;
    qcReviewModalOpened = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.doc.currentValue.qcReview && !changes.doc.isFirstChange()) {
            this.qcButtonAcceptVisible();
            this.qcButtonDeclineVisible();
            this.qcButtonReviewVisible();
        }
    }

    ngOnInit(): void {
        this.qcButtonAcceptVisible();
        this.qcButtonDeclineVisible();
        this.qcButtonReviewVisible();
        this.isDocumentLocked = this.doc.isLocked;
    }

    checkPreviousCycles(currentCycle) : boolean {
        for (let i = 0; i < currentCycle; i += 1) {
            if (this.doc.qcReview.reviews[i].status !== qcReviewStatuses.APPROVED) {
                return false;
            }
        }

        return true;
    }

    qcButtonAcceptVisible() {
        if (this.doc.qcReview && this.doc.permissions.conductQCReview) {

            const nextReviewer = this.doc.qcReview.reviews.findIndex(
                (element) => element.reviewerIds.id === this.currentUserId
                && element.status === qcReviewStatuses.PENDING_REVIEW
            );

            switch (nextReviewer) {
                case 0:
                    this.showQcAcceptButton = true;
                    break;
                case -1:
                    this.showQcAcceptButton = false;
                    break;
                default:
                    this.showQcAcceptButton = this.checkPreviousCycles(nextReviewer);
            }
        }
    }

    qcButtonDeclineVisible() {
        if (this.doc.qcReview && this.doc.permissions.conductQCReview) {

            const nextReviewer = this.doc.qcReview.reviews.findIndex(
                (element) => element.reviewerIds.id === this.currentUserId
                && (element.status === qcReviewStatuses.PENDING_REVIEW || element.status === qcReviewStatuses.IN_REVIEW)
            );

            switch (nextReviewer) {
                case 0:
                    this.showQcDeclineButton = true;
                    break;
                case -1:
                    this.showQcDeclineButton = false;
                    break;
                default:
                    this.showQcDeclineButton = this.checkPreviousCycles(nextReviewer);
            }
        }
    }

    qcButtonReviewVisible() {
        if (this.doc.qcReview && this.doc.permissions.conductQCReview) {

            const nextReviewer = this.doc.qcReview.reviews.findIndex(
                (element) => element.reviewerIds.id === this.currentUserId
                && (element.status === qcReviewStatuses.IN_REVIEW
                    || element.status === qcReviewStatuses.IN_REJECTION_REVIEW)
            );

            switch (nextReviewer) {
                case 0:
                    this.showQcReviewButton = true;
                    break;
                case -1:
                    this.showQcReviewButton = false;
                    break;
                default:
                    this.showQcReviewButton = this.checkPreviousCycles(nextReviewer);
            }

            if (nextReviewer >= 0) {
                this.reviewToConduct = this.doc.qcReview.reviews[nextReviewer];
                this.qcReviewAction = this.reviewToConduct.status === qcReviewStatuses.IN_REJECTION_REVIEW ? 'REVISIT QC REVIEW' : 'QC REVIEW';
            }
        }
    }

    acceptQcReview() {
        this.isProcessingAccept = true;
        const event = {
            docId: this.doc.id as string,
            docVersion: this.doc.version,
            onError: () => {
                this.isProcessingAccept = false;
            },
            onSuccess: () => {
                this.isProcessingAccept = false;
            }
        };
        this.qcAcceptLog.emit(event);
    }

    declineQcReview() {
        this.isProcessingDecline = true;
        const event = {
            docId: this.doc.id as string,
            docVersion: this.doc.version,
            onError: () => {
                this.isProcessingDecline = false;
            },
            onClose: () => {
                this.isProcessingDecline = false;
            }
        };

        this.qcDeclineLog.emit(event);
    }

    openCloseQcReview($event) {
        this.comment = $event ? $event.comment : this.comment;
        this.qcReviewModalOpened = !this.qcReviewModalOpened;
    }

    approveReview($event) {
        this.approveQcReview.emit($event);
    }

    rejectReview($event) {
        this.rejectQcReview.emit($event);
    }

}
