const signingReasons = {
    reasons: [
        'Acknowledge',
        'Approval',
        'Authorship',
        'Responsibility',
        'Review',
        'Certified Copy'
    ].sort()
};

export default signingReasons;
