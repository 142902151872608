import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BinderDownloadOptions } from './binder-download.component.types';

import template from './binder-download.component.html';
import styles from './binder-download.component.scss';

@Component({
    selector: 'binder-download',
    template,
    styles: [String(styles)]
})
export class BinderDownloadComponent {
    @Input() options: BinderDownloadOptions;
    @Output() download = new EventEmitter<BinderDownloadOptions>();

    includeSignature = true;
    includeAllVersionsOfDocuments = false;

    constructor(
        private modalRef: BsModalRef
    ) { }

    onStartDownload(): void {
        this.download.emit({
            ...this.options,
            includeSignature: this.includeSignature,
            includeAllVersionsOfDocuments: this.includeAllVersionsOfDocuments
        });
        this.cancel();
    }

    cancel(): void {
        this.modalRef.hide();
    }
}
