import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StateService } from '@uirouter/core';
import { StudyStartupPayload } from '../../study-manager.service.types';
import { StudyManagerService } from '../../study-manager.service';

import template from './update-study-modal.component.html';
import styles from './update-study-modal.component.scss';

@Component({
    selector: 'update-study-modal',
    template,
    styles: [String(styles)]
})
export class UpdateStudyModal implements OnInit {
    @Input() teamId: string;
    @Input() payload: Partial<StudyStartupPayload>;
    @Output() save = new EventEmitter<void>();

    jobId: string;
    isButtonDisabled: boolean;

    constructor(
        private modalRef: BsModalRef,
        private studyManager: StudyManagerService,
        private $state: StateService,
        private Notifications: NotificationsService
    ) {
        this.jobId = '';
        this.isButtonDisabled = false;
    }

    ngOnInit(): void {
        this.jobId = '';
        this.isButtonDisabled = false;
    }

    hideModal(): void {
        this.modalRef.hide();
    }

    async updateStudy() {
        this.isButtonDisabled = true;
        try {
            const value = await this.studyManager.upsertStudyStartup(this.teamId, this.payload).toPromise();
            this.jobId = value.jobId;
        }
        catch (error) {
            this.jobId = 'undefined';
        }
        this.modalRef.hide();
        this.$state.go('app.team.study-manager', { teamId: this.teamId });
        if (this.jobId !== 'undefined') {
            this.Notifications.info('Study update successfully requested');
        }
        else {
            this.Notifications.error('The study couldn\'t be updated');
        }
    }
}
