import {
    Component,
    Input
} from '@angular/core';

@Component({
    selector: 'page-warning',
    template: `
    <div class="page-warning u-d-flex u-align-items-center">
        <span class="warning-icon warning-icon-container {{iconSizeCssClass}}" [ngStyle]="{margin: '0 0.2em'}">
            <i class="fas fa-exclamation-triangle text-{{severity}}"></i>
        </span>
        <span class="pl-2">
            <ng-content></ng-content>
        </span>
    </div>
  `
})
export class PageWarningComponent {
    @Input() severity = 'warning';
    @Input() iconSizeCssClass = 'fa-2x';
}
