import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import { downgradeComponent } from '@angular/upgrade/static';
import layoutRoute from './layout.route';
import appLayoutComponent from './containers/app-layout/app-layout.component';
import { NavbarComponent } from './components/navbar/navbar.component';

const dependencies = [
    uiRouter
];

const layoutModule = angular
    .module('app.layout', dependencies)
    .component('appLayout', appLayoutComponent)
    .directive('navbar', downgradeComponent({ component: NavbarComponent }) as ng.IDirectiveFactory)
    .config(layoutRoute);

export default layoutModule;
