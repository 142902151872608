import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import {
    Document,
    MonitorReview,
    Paywall
} from '@app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { filter } from 'rxjs/operators';
import {
    AbstractControl, FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { DocumentMonitorReviewService } from '../../document-monitor-review.service';
import { FeatureFlagService } from '../../../../core/feature-flag.service';
import { FEATURE_FLAGS } from '../../../../core/constants/feature-flags';
import { UpdateRecipientsParams } from '../monitor-review-potential-recipients/monitor-review-potential-recipients.component.types';
import template from './monitor-review-edit.component.html';
import styles from './monitor-review-edit.component.scss';
import { MonitorReviewEditSubmitEvent, MonitorReviewSubmitComment, StudyLabel } from './monitor-review-edit.component.types';

@Component({
    template,
    styles: [String(styles)]
})
export class MonitorReviewEditComponent implements OnInit {

    @Input() document: Document;
    @Input() monitorReviews: MonitorReview[];
    @Input() paywallOrgs: Paywall[];
    @Input() currentReview: MonitorReview;
    @Input() studyLabel;
    @Input() reviewEvents;
    @Input() reviewEventsNext: string;

    @Output() onMonitorReviewSubmit = new EventEmitter<MonitorReviewEditSubmitEvent>();
    @Output() onMonitorReviewCommentSubmit = new EventEmitter<MonitorReviewSubmitComment>();
    addCommentForm: FormGroup;
    newComment = '';
    recipientIds: string[];

    featureFlagResolved = false;
    filteredPaywallOrgs;
    filteredStudyLabel: StudyLabel;

    selectedPaywall: Paywall;
    selectedStudyValue: {
        id: string;
        value: string;
        studyId?: string;
    } = null;

    selectOnlyRelatedUPID = false;
    canSaveModal: boolean;
    enableSave: boolean;
    canComment: boolean;
    canUpdateStatus = false;
    isDocumentLocked = false;
    maxCommentLength = 500;
    _eventHistoryReset;
    eventHistoryReset$;

    constructor(
        private Modal: BsModalRef,
        private documentMonitorReviewService: DocumentMonitorReviewService,
        private fb: FormBuilder,
        private FeatureFlags: FeatureFlagService,
        private Notifications: NotificationsService
    ) {}

    ngOnInit(): void {
        // eventHistoryReset$ is used to notify the event history component to reset the scroll position to top,
        // and it must be set before the modal is opened
        this._eventHistoryReset = new Subject();
        this.eventHistoryReset$ = this._eventHistoryReset.asObservable();
        this.FeatureFlags.getFlag(FEATURE_FLAGS.UPID_RELATED_TO_MONITOR_GROUP, true).pipe(
            filter((flag) => flag !== undefined)
        ).subscribe((value) => {
            this.selectOnlyRelatedUPID = value;
            this.isDocumentLocked = this.document.isLocked;
            this.filteredStudyLabel = { ...this.studyLabel };
            this.filteredStudyLabel.values = this.filteredStudyLabel.values.filter((value) => value.studyId);
            this.filteredPaywallOrgs = [...this.paywallOrgs];

            if (this.currentReview) {
                this.selectedPaywall = this.paywallOrgs.find((paywall) => paywall.id === this.currentReview.paywallId);
                this.selectedStudyValue = {
                    id: this.currentReview.studyLabelValueId,
                    value: this.currentReview.studyLabelValueName
                };
            }

            this.canUpdateStatus = this.document.permissions.documentCreateCloseQuery;
            this.canComment = this.document.permissions.documentCommentOnMonitorReview;
            this.canSaveModal = this.canUpdateStatus || this.canComment;
            this._setDefaultSelections();
            this.featureFlagResolved = true;
        });

    }

    _setDefaultSelections() {
        if (this.paywallOrgs.length === 1) {
            [this.selectedPaywall] = this.paywallOrgs;

            if (this.selectOnlyRelatedUPID) {
                this.filteredStudyLabel.values = this.studyLabel.values.filter((value) => {
                    return value.studyId ? this.selectedPaywall.studyIds?.includes(value.studyId) ?? true : false;
                });
            }
        }

        this.initForm();

        if (this.filteredStudyLabel.values.length === 1) {
            [this.selectedStudyValue] = this.filteredStudyLabel.values;
        }
        this.updateSelectReview();
    }

    cancel(): void {
        this.Modal.hide();
    }

    updateSelectReview(paywall?, studyLabel?) {
        this.selectedPaywall = paywall || this.selectedPaywall;
        this.selectedStudyValue = studyLabel || this.selectedStudyValue;

        if (this.selectOnlyRelatedUPID && (paywall && !studyLabel)) {
            this.filteredStudyLabel.values = this.studyLabel.values.filter((value) => {
                return value.studyId ? this.selectedPaywall.studyIds?.includes(value.studyId) ?? true : false;
            });
            this.selectedStudyValue = this.filteredStudyLabel.values.length === 1 ? this.filteredStudyLabel.values[0] : undefined;
        }

        if (this.selectedPaywall && this.selectedStudyValue) {
            this.setCurrentReview();
        }
    }

    comment() {
        this.enableSave = this.canSaveModal && this._monitorReviewChanged()
            && !!this.selectedPaywall && !!this.selectedStudyValue;
    }

    setCurrentReview() {

        this.currentReview = _.cloneDeep(this.monitorReviews.find((review) => {
            return review.paywallId === this.selectedPaywall.id
                && review.studyLabelValueId === this.selectedStudyValue.id;
        }) || {} as MonitorReview);

        this.enableSave = this._canSave() && (!this.currentReview || Object.keys(this.currentReview).length === 0);
        this.getReviewEvents();
    }

    getReviewEvents() {
        if (!this.currentReview || Object.keys(this.currentReview).length === 0) {
            this.reviewEvents = [];
            delete this.reviewEventsNext;
            this._eventHistoryReset.next();
            return;
        }

        const documentId: string = this.currentReview.documentId as string;

        this.documentMonitorReviewService.getEventsForReview(documentId, this.currentReview.id)
            .subscribe(({ items, next }) => {
                this.reviewEvents = items;
                this.reviewEventsNext = next;
                this._eventHistoryReset.next();
            }, ({ error }) => {
                this.Notifications.error(error.message);
            });
    }

    getMoreReviewEvents() {
        const documentId: string = this.currentReview.documentId as string;
        this.documentMonitorReviewService.getEventsForReview(documentId, this.currentReview.id, this.reviewEventsNext)
            .subscribe(({ items, next }) => {
                this.reviewEvents = this.reviewEvents.concat(items);
                this.reviewEventsNext = next;
            }, ({ error }) => {
                this.Notifications.error(error.message);
            });
    }

    setReviewStatus(status) {
        this.currentReview.status = status;
        this.enableSave = this.currentReview.id ? this._canSave() && this._monitorReviewChanged() : this._canSave();
    }

    save() {
        this.currentReview = this._getMonitorReviewForSubmit();
        if (this.addCommentForm.value.newComment) {
            const isStatusChanged = this._monitorReviewStatusChanged();
            const notifyRecipients = !isStatusChanged;
            this.onMonitorReviewCommentSubmit.emit({
                currentReview: this.currentReview,
                comment: this.addCommentForm.value.newComment,
                notifyRecipients
            });
            if (isStatusChanged) {
                this.onMonitorReviewSubmit.emit({ ...this.currentReview, comment: this.addCommentForm.value.newComment });
            }
        }
        else {
            this.onMonitorReviewSubmit.emit({ ...this.currentReview, comment: this.addCommentForm.value.newComment });
        }
    }

    _canSave(): boolean {
        return this.canSaveModal && !this.addCommentForm.invalid;
    }

    _getMonitorReviewForSubmit() {
        return {
            ...this.currentReview,
            teamId: this.document.teamId,
            documentId: this.document.id,
            studyLabelId: this.studyLabel.id,
            studyLabelValueId: this.selectedStudyValue.id,
            paywallId: this.selectedPaywall.id,
            recipientIds: this.recipientIds
        };
    }

    _monitorReviewChanged(): boolean {
        if (!this.currentReview || !this.currentReview.id) {
            return true;
        }
        if (this.addCommentForm.value.newComment) {
            return !this.addCommentForm.invalid;
        }
        return this._monitorReviewStatusChanged();
    }

    _monitorReviewStatusChanged(): boolean {
        const originalReview = this.monitorReviews.find((review) => review.id === this.currentReview.id);
        return originalReview && originalReview.status !== this.currentReview.status;
    }

    updateSelectedRecipientIds($event: UpdateRecipientsParams): void {
        this.recipientIds = $event.selectedRecipientIds;
    }

    get commentFormCtrls(): { [key: string]: AbstractControl } {
        return this.addCommentForm.controls;
    }

    private initForm(): void {
        this.addCommentForm = this.fb.group({
            newComment: ['', [Validators.maxLength(this.maxCommentLength)]]
        });
    }
}
