import {
    Component, EventEmitter, HostListener, Input, OnInit, Output
} from '@angular/core';
import {
    FormArray, FormBuilder, FormControl, FormGroup, Validators
} from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { qcReviewStatuses } from '@florencehealthcare/florence-constants/lib/qc-reviews';
import template from './document-handle-qc-review.component.html';
import styles from './document-handle-qc-review.component.scss';
import { ApproveReviewEvent, RejectReviewEvent } from './document-handle-qc-review.component.types';

@Component({
    selector: 'document-handle-qc-review',
    template,
    styles: [String(styles)]

})
export class DocumentHandleQcReviewComponent implements OnInit {
    @Input() comment : string;
    @Input() qcReview;
    @Input() reviewToConduct;
    @Output() closeModal = new EventEmitter<{comment: string}>();
    @Output() approveReview = new EventEmitter<ApproveReviewEvent>();
    @Output() rejectReview = new EventEmitter<RejectReviewEvent>();

    modalHeight;
    submitButtonName : string;
    submitButtonProcessingName : string;
    reviewForm: FormGroup;
    progress = '';
    validReason = false;
    isProcessing = false;
    otherReasonShow = false;
    public rejectionReasons = [
        { reason: 'illegible', label: 'Illegible' },
        { reason: 'missingSignatures', label: 'Missing Signature(s)' },
        { reason: 'duplicateDocument', label: 'Duplicate Document' },
        { reason: 'notAppropriateNotNeeded', label: 'Not Appropriate/Not Needed' },
        { reason: 'missingPage', label: 'Missing Page(s)' },
        { reason: 'expired', label: 'Expired' },
        { reason: 'incorrectFilingLocation', label: 'Incorrect Filing Location' },
        { reason: 'wrongStudy', label: 'Wrong Study' },
        { reason: 'other', label: 'Other' }
    ];

    constructor(
        private fb: FormBuilder,
        public modal: BsModalRef
    ) {}

    ngOnInit(): void {
        this.initForm();
        this.calculateProgress();
        this.modalButtonApperance();
        this.calculateModalHeight();
    }

    calculateModalHeight() {
        if (window.innerHeight <= 765) {
            this.modalHeight = window.innerHeight - 280;
            this.modalHeight += this.reviewToConduct.status === 'In Rejection Review' ? 0 : 10;
            this.modalHeight = `${this.modalHeight}px`;
            this.modalHeight = window.innerHeight <= 470 ? '192px' : this.modalHeight;
        }
        else {
            this.modalHeight = this.reviewToConduct.status === 'In Rejection Review' ? '510px' : '440px';
        }
    }

    @HostListener('window:resize') onResize() {
        this.calculateModalHeight();
    }

    private calculateProgress() {
        const total = this.qcReview.reviews.length;
        const count = this.qcReview.reviews.reduce((acc, cur) => (cur.status === 'Approved' ? acc + 1 : acc), 1);
        this.progress = `${count}/${total}`;
    }

    modalButtonApperance() {
        if (this.reviewToConduct.status === qcReviewStatuses.IN_REJECTION_REVIEW) {
            this.submitButtonProcessingName = 'CONFIRMING';
            this.submitButtonName = 'CONFIRM';
            return;
        }

        this.reviewToConduct.status = this.reviewToConduct.status === qcReviewStatuses.IN_REVIEW ? 'In Review' : this.reviewToConduct.status;

        this.submitButtonProcessingName = 'SUBMITTING REVIEW';
        this.submitButtonName = 'SUBMIT REVIEW';
    }

    otherReasonConditionallyRequiredValidator(formGroup: FormGroup) {
        if (formGroup.value.rejectionReasons.includes('Other')) {
            return Validators.required(formGroup.get('otherReason')) ? {
                otherReasonFieldConditionallyRequired: true
            } : null;
        }

        return null;
    }

    private initForm(): void {
        this.reviewForm = this.fb.group({
            decision: ['', [Validators.required]],
            rejectionReasons: new FormArray([]),
            otherReason: [''],
            comment: [this.comment, [Validators.required, notBlank]]
        },
        {
            validators: [this.otherReasonConditionallyRequiredValidator]
        });

        this.reviewForm.get('decision').valueChanges
            .subscribe((value) => {
                if (value === 'reject') {
                    this.reviewForm.get('comment').setValidators([Validators.required, notBlank]);
                }
                else {
                    this.reviewForm.get('comment').clearValidators();
                }
                this.reviewForm.get('comment').updateValueAndValidity();
            });
    }

    onTypeChange(event): void {
        if (event.target.id === 'approve') {
            this.otherReasonShow = false;
        }
        this.reviewForm.setControl('rejectionReasons', new FormArray([]));
        this.validReason = false;
    }

    onCheckChange(event) {
        const formArray: FormArray = this.reviewForm.get('rejectionReasons') as FormArray;
        if (event.target.checked) {
            formArray.push(new FormControl(event.target.value));
        }
        else {
            let i = 0;
            formArray.controls.forEach((ctrl: FormControl) => {
                if (ctrl.value === event.target.value) {
                    formArray.removeAt(i);
                    return;
                }
                i += 1;
            });
        }

        this.otherReasonShow = (this.reviewForm.value.rejectionReasons.includes('Other') && this.reviewForm.value.decision === 'reject');
        this.validReason = this.reviewForm.value.rejectionReasons.length > 0;
    }

    submitReview() {
        if (this.isProcessing) {
            return;
        }

        this.isProcessing = true;
        const { comment } = this.reviewForm.value;
        if (this.reviewForm.value.decision === 'approve') {
            this.approveReview.emit({
                comment,
                onSuccess: () => {
                    this.closeModal.emit({ comment: '' });
                    this.isProcessing = false;
                },
                onError: () => {
                    this.isProcessing = false;
                }
            });
        }
        else {
            const values = this.reviewForm.value.rejectionReasons;

            const otherIndex = values.findIndex((value) => value === 'Other');

            if (otherIndex !== -1) {
                const { otherReason } = this.reviewForm.value;
                if (otherReason.length) {
                    values.splice(otherIndex, 1, otherReason);
                }
                else {
                    values.splice(otherIndex, 1);
                }
            }
            this.rejectReview.emit({
                comment,
                reasons: values,
                onSuccess: () => {
                    this.closeModal.emit({ comment: '' });
                },
                onError: () => {
                    this.isProcessing = false;
                }
            });
        }
    }

    close() {
        this.closeModal.emit({ comment: this.reviewForm.value.comment });
    }

}
