import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output
} from '@angular/core';

import styles from './upload-dropzone.component.scss';

@Component({
    selector: 'upload-dropzone',
    template: `
        <div class="upload-dropzone"
            [ngClass]="{
                'upload-dropzone--file-over': (fileOver | async),
                'upload-dropzone--unsupported-over': (unsupportedOver | async)
            }">
            <ng-content></ng-content>
        </div>
    `,
    styles: [String(styles)]
})
export class UploadDropzoneComponent {
    @Input() multiple = false;
    @Output() upload = new EventEmitter<FileList>();

    fileOver = new EventEmitter<boolean>();
    unsupportedOver = new EventEmitter<boolean>();

    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent): void {
        this.preventAndStop(event);

        if (!this.haveFiles(event.dataTransfer) || this.droppedDir(event.dataTransfer)) {
            this.unsupportedOver.emit(false);
            this.fileOver.emit(false);
            return;
        }
        this.fileOver.emit(false);

        this.upload.emit(event.dataTransfer.files);
    }

    @HostListener('dragover', ['$event'])
    onDragOver(event: DragEvent): void {
        this.preventAndStop(event);

        if (!this.haveFiles(event.dataTransfer)) {
            this.unsupportedOver.emit(true);
            return;
        }
        this.fileOver.emit(true);
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(event: DragEvent): void {
        this.preventAndStop(event);
        this.unsupportedOver.emit(false);
        this.fileOver.emit(false);
    }

    private preventAndStop(event: DragEvent): void {
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = 'copy';
        }
        event.preventDefault();
        event.stopPropagation();
    }

    private haveFiles(dataTransfer: DataTransfer): boolean {
        if (!dataTransfer || !dataTransfer.types || !dataTransfer.files) {
            return false;
        }

        if (dataTransfer.types.indexOf) {
            return dataTransfer.types.indexOf('Files') !== -1;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: IE property returns a DOMStringList.
        if (dataTransfer.types.contains) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return dataTransfer.types.contains('Files');
        }
    }

    private droppedDir(dataTransfer: DataTransfer): boolean {
        if (!dataTransfer.items) {
            return false;
        }

        for (let i = 0; i < dataTransfer.items.length; i += 1) {
            const item = dataTransfer.items[i];

            if (item.webkitGetAsEntry && item.webkitGetAsEntry() && item.webkitGetAsEntry().isDirectory) {
                return true;
            }
        }
        return false;
    }
}
