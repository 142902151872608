import { AbstractControl, ValidatorFn, FormArray } from '@angular/forms';

// Custom validator to check if the value has leading/trailing spaces or is empty after trimming
export const noLeadingTrailingSpaces = function noLeadingTrailingSpaces(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const trimmedValue = control.value ? control.value.trim() : '';
        const isInvalid = trimmedValue.length === 0 || trimmedValue !== control.value;

        return isInvalid ? { noLeadingTrailingSpaces: { value: control.value } } : null;
    };
};

// Custom validator to check if the value is unique within a FormArray
export const uniqueValueValidator = function uniqueValueValidator(array: FormArray | undefined): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!array || !array.controls) {
            return null;
        }

        const currentValue = control.value ? control.value.trim() : '';
        const duplicate = array.controls.some(
            (group) => group !== control.parent && group.get('currentValue')?.value.trim() === currentValue
        );

        return duplicate ? { uniqueValue: { value: control.value } } : null;
    };
};
