import { Injectable } from '@angular/core';
import { AppFeaturesService } from '@app/shared/app-features/app-features.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class DashboardsFeatureGuard {
    constructor(
    private appFeatures: AppFeaturesService,
    private notifications: NotificationsService
    ) {}

    canActivate(): Observable<boolean> {
        return this.appFeatures.assertDashboardEnabledForCurrentTeam().pipe(
            catchError((error) => {
                this.notifications.error(error);
                return throwError(() => error);
            })
        );
    }
}
