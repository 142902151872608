import {
    BehaviorSubject, combineLatest, from, Observable, of, Subject, Subscription
} from 'rxjs';
import {
    debounceTime,
    map, pluck, take, takeUntil, tap
} from 'rxjs/operators';
import * as _ from 'lodash';

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import { AdapterService } from '@app/shared/adapter/adapter.service';
import {
    Binder, BrowseTree, Folder
} from '@app/shared/models';
import { BrowseParams } from '@app/shared/teams/teams.service.types';
import { FILTERS } from '@florencehealthcare/florence-constants/lib/filters';
import {
    VirtualTreeNode,
    VirtualTreeFlatNode,
    VirtualTreeItemSelectedEvent,
    VirtualTreeSelectionMode
} from './virtual-tree.component.types';

import template from './virtual-tree.component.html';
import styles from './virtual-tree.component.scss';

@Component({
    selector: 'virtual-tree',
    template,
    styles: [String(styles)]
})

export class VirtualTreeComponent implements OnInit, OnChanges, OnDestroy {
    @Input() loadingTree: boolean;
    @Input() tree: VirtualTreeNode[];
    @Input() scrollBoxHeight = '400px';
    @Input() selectedNodes: VirtualTreeFlatNode[];
    @Input() reset = false;
    @Input() highlightedNodesIds = [];
    @Input() showTooltips: boolean;
    @Input() showFilter = true;
    @Input() selectionMode: VirtualTreeSelectionMode;
    @Input() loadBinderOnSelect: boolean;
    @Input() hideStatusIcons = false;
    @Input() isNodeClickable = false;
    @Input() isTreeExpanded = false;
    @Input() showNodeCheckbox: (node: VirtualTreeFlatNode) => boolean = () => false;
    @Input() isNodeDisplayable: (node: VirtualTreeNode) => boolean = () => true;
    @Input() isNodeSelectable: (node: VirtualTreeFlatNode) => boolean = () => false;
    @Input() isNodeDisabled: (node: VirtualTreeNode) => boolean = () => false;
    @Input() loadNode: (params: BrowseParams) => Promise<BrowseTree>;
    @Input() showHintBelowFilter = false;
    @Input() showSelectAll = false;
    @Input() showNameCheckbox = false;
    @Input() filterLabel = 'Filter Binders';
    @Input() reportsChooseBindersFoldersModalStyles = false;
    @Input() filteredNodes: (Binder | Folder)[];
    @Input() newFilterFlag = false;
    @Output() nodeSelected = new EventEmitter<VirtualTreeItemSelectedEvent<VirtualTreeFlatNode>>();
    @Output() nodeUnselected = new EventEmitter<VirtualTreeItemSelectedEvent<VirtualTreeFlatNode>>();
    @Output() nodeClicked = new EventEmitter<VirtualTreeFlatNode>();
    @Output() filterNodes = new EventEmitter<string>();
    @Output() toggleSelectAllEvent = new EventEmitter<boolean>();

    @ViewChild('virtualViewportTree', { static: true }) virtualViewport: CdkVirtualScrollViewport;

    destroy$ = new Subject<void>();

    dataChange = new BehaviorSubject<VirtualTreeNode[]>([]);
    filterChange = new BehaviorSubject<string>('');

    filteredDataChange = new BehaviorSubject<VirtualTreeNode[]>([]);

    loadingNodeId: string;
    expandedNodesIds: string[] = [];
    nodeSelection: SelectionModel<VirtualTreeFlatNode>;
    focusNodeTimer: ReturnType<typeof setTimeout>;
    checkboxSelectTimer: ReturnType<typeof setTimeout>;
    checkboxUpdateSubscription: Subscription;
    showFilteredView = false;
    minNumOfCharacters = 3;
    previousFilterValue: string;

    treeControl = new FlatTreeControl<VirtualTreeFlatNode>(this.getNodeLevel, this.getIsNodeExpandable);
    dataSource: MatTreeFlatDataSource<VirtualTreeNode, VirtualTreeFlatNode>;
    treeFlattener = new MatTreeFlattener<VirtualTreeNode, VirtualTreeFlatNode>(
        this.nodeTransformer,
        this.getNodeLevel,
        this.getIsNodeExpandable,
        this.getNodeChildren
    );

    selectAllButtonTextOptions = {
        selectAll: 'Select All',
        unselectAll: 'Unselect All'
    };

    toggleSelectAllText = this.selectAllButtonTextOptions.selectAll;

    constructor(protected Adapter: AdapterService) {
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    }

    ngOnInit(): void {
        const isMultipleSelectMode = this.selectionMode === VirtualTreeSelectionMode.MULTI_SELECT
            || this.selectionMode === VirtualTreeSelectionMode.HIERARCHICAL_MULTI_SELECT;
        this.nodeSelection = new SelectionModel<VirtualTreeFlatNode>(isMultipleSelectMode);

        if (this.newFilterFlag) {
            this.filteredDataChange.subscribe((res) => {
                this.treeControl.dataNodes = res;
                this.updateNodeSelection();
            });
        }

        combineLatest([
            this.dataChange,
            this.filterChange
        ]).pipe(
            takeUntil(this.destroy$),
            debounceTime(FILTERS.DEBOUNCE_TIME)
        ).subscribe(([data, filter]) => {
            let filteredData = this.filterTreeByDisplayability(data);
            if (this.newFilterFlag) {
                if (this.previousFilterValue && this.previousFilterValue === filter) {
                    return;
                }
                this.previousFilterValue = filter;
                // user has to enter at least 3 characters for search to be triggered
                if (!this.isWhitespaceString(filter) && filter.length >= this.minNumOfCharacters) {
                    this.filterNodes.emit(filter);
                    this.showFilteredView = true;
                }
                else if (this.showFilteredView) {
                    this.showFilteredView = false;
                    this.filteredNodes = [];
                    this.expandedNodesIds = [];
                }
            }
            if (!this.newFilterFlag && filter) {
                const clonedData = _.cloneDeep(filteredData);
                filteredData = this.filterTreeByName(clonedData, filter);
            }
            this.dataSource.data = filteredData;
            if (this.isTreeExpanded) {
                this.expandAllNodes();
            }
            if (data.length > 0) {
                this.updateNodeSelection();
                this.updateExpandedNodes();
            }

            this.setToggleSelectAllText();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.reset) {
            this.nodeSelection.clear();
            this.selectedNodes = [];
        }
        if (changes.loadingTree) {
            // Because tree data is not emptied on time scroll to top to avoid bad UX
            this.virtualViewport?.scrollToIndex(0);
        }

        if (
            this.showSelectAll
            && (changes.selectedNodes?.currentValue?.length !== changes.selectedNodes?.previousValue?.length)
        ) {
            this.setToggleSelectAllText();
        }

        if (changes.tree && changes.tree.currentValue) {
            this.dataChange.next(changes.tree.currentValue);
            if (this.nodeSelection?.selected) {
                this.nodeSelection.clear();
            }
        }

        if (this.newFilterFlag && changes.filteredNodes && changes.filteredNodes.currentValue) {
            this.filteredDataChange.next(changes.filteredNodes.currentValue);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onFilterInput(filter: string): void {
        this.filterChange.next(filter);
    }

    onNodeClicked(node: VirtualTreeFlatNode): void {
        if (this.isNodeClickable) {
            this.nodeClicked.emit(node);
        }
    }

    getConversionState(item: Document): { isFailed: boolean; inProgress: boolean } {
        return this.Adapter.getConversionState(item);
    }

    isWhitespaceString(str: string): boolean {
        return !str.replace(/\s/g, '').length;
    }

    toggleNodeSelection(node: VirtualTreeFlatNode): void {
        if (this.isNodeDisabled(node)) {
            return;
        }
        const updatedNode$ = this.shouldLoadBinder(node)
            ? this.loadNodeItems(node).pipe(tap(() => {
                this.expandedNodesIds.push(node.id);
                const dataNode = this.treeControl.dataNodes.find((item) => item.id === node.id);
                this.treeControl.expand(dataNode);
            }))
            : of<VirtualTreeFlatNode>(node);

        updatedNode$.subscribe((updatedNode) => {
            this.nodeSelection.toggle(updatedNode);

            if (this.selectionMode === VirtualTreeSelectionMode.HIERARCHICAL_MULTI_SELECT) {
                // Force update for descendants
                const descendants = this.treeControl.getDescendants(updatedNode)
                    .filter((el) => (el.type === 'document' ? !el.isLocked : this.isNodeSelectable(el)));
                this.nodeSelection.isSelected(updatedNode)
                    ? this.nodeSelection.select(...descendants)
                    : this.nodeSelection.deselect(...descendants);

                // Force update for the parent
                this.toggleAllParentsSelection(node);
            }

            const eventData = {
                item: updatedNode,
                selectedItems: this.nodeSelection.selected.filter((flatNode) => this.isNodeSelectable(flatNode))
            };
            this.nodeSelection.isSelected(updatedNode)
                ? this.nodeSelected.emit(eventData)
                : this.nodeUnselected.emit(eventData);
        });
    }

    toggleSelectAll() {
        if (!(this.showSelectAll || this.showNameCheckbox)) {
            return;
        }
        if (this.selectedNodes?.length) {
            this.nodeSelection.deselect(...this.nodeSelection.selected);
            this.selectedNodes = [];
            this.highlightedNodesIds = [];
            this.toggleSelectAllEvent.emit(false);
        }
        else {
            this.nodeSelection.select(...this.treeControl.dataNodes);
            this.selectedNodes = this.tree;
            this.highlightedNodesIds = this.selectedNodes.map((node) => node.id);
            this.toggleSelectAllEvent.emit(true);
        }

    }

    toggleSelectAllFiltered() {
        if (!(this.showSelectAll || this.showNameCheckbox || this.filterChange.value.length)) {
            return;
        }

        const selectedFilteredNodesFlat = this.nodeSelection.selected.filter(
            (node) => this.filteredNodes.find((filteredNode) => filteredNode.id === node.id)
        );

        if (selectedFilteredNodesFlat?.length) {
            this.nodeSelection.deselect(...selectedFilteredNodesFlat);
            this.selectedNodes = this.selectedNodes.filter(
                (node) => !this.filteredNodes.find((filteredNode) => filteredNode.id === node.id)
            );
            this.highlightedNodesIds = this.selectedNodes.map((node) => node.id);
            const eventData = {
                item: null,
                selectedItems: this.nodeSelection.selected.filter((flatNode) => this.isNodeSelectable(flatNode))
            };
            this.nodeUnselected.emit(eventData);
        }
        else {
            this.filteredNodes.forEach((node) => {
                this.toggleNodeSelection(node);
            });
        }

    }

    setToggleSelectAllText() {
        this.toggleSelectAllText = this.selectedNodes?.length
            ? this.selectAllButtonTextOptions.unselectAll
            : this.selectAllButtonTextOptions.selectAll;
    }

    areAllNodesSelected(): boolean {
        if (!this.newFilterFlag) {
            return this.selectedNodes?.filter((node) => node.type === 'binder').length === this.treeControl.dataNodes?.filter((node) => node.type === 'binder').length;
        }
        return this.selectedNodes?.filter((node) => (
            node.type === 'binder' || node.type === 'folder'
        )).length === this.treeControl.dataNodes?.filter((node) => (
            node.type === 'binder' || node.type === 'folder'
        )).length;
    }

    areSomeNodesSelected(): boolean {
        return this.selectedNodes?.length > 0 && !this.areAllNodesSelected();
    }

    noNodesSelected(): boolean {
        return !this.areSomeNodesSelected() && !this.areAllNodesSelected();
    }

    toggleNode(node: VirtualTreeFlatNode): void {
        const nodeIndex = this.expandedNodesIds.findIndex((id) => id === node.id);
        if (nodeIndex > -1) {
            this.expandedNodesIds.splice(nodeIndex, 1);
            this.treeControl.collapse(node);
            return;
        }

        this.expandedNodesIds.push(node.id);
        // fixing issue with screen reader (when node is expanded and new nodes are loaded focus is lost)
        this.focusNodeTimer = setTimeout(() => {
            clearTimeout(this.focusNodeTimer);
            if (document.activeElement.id !== node.id) {
                this.focusTreeNode(node);
            }
        }, 0);

        if (!this.shouldLoadNodeItems(node)) {
            const dataNode = this.treeControl.dataNodes.find((item) => item.id === node.id);
            this.treeControl.expand(dataNode);
            return;
        }
        this.loadNodeItems(node).subscribe();
    }

    isToggleNodeInvisible(node: VirtualTreeFlatNode): boolean {
        return node.type === 'document' || (node.items && node.items.length === 0);
    }

    allDescendantsSelected(node: VirtualTreeFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const selectableDescendants = descendants.filter((descendant) => this.isNodeSelectable(descendant));
        return selectableDescendants.length && selectableDescendants.every((child) => {
            return this.nodeSelection.isSelected(child);
        });
    }

    descendantsPartiallySelected(node: VirtualTreeFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const someDescendantsSelected = descendants.some((child) => this.nodeSelection.isSelected(child));
        const preselectedBindersHaveDescendants = this.selectedNodes
            && this.selectedNodes.findIndex((selectedNode) => selectedNode.binderId === node.id) !== -1;
        return !this.allDescendantsSelected(node)
            && (someDescendantsSelected || preselectedBindersHaveDescendants);
    }

    sharedDocumentSelected(node: VirtualTreeFlatNode): boolean {
        return this.selectedNodes?.filter((doc) => doc.exchangeEventId).some((doc) => doc.id === node.id);
    }

    isNodeSelected(node): boolean {
        return this.nodeSelection.selected.filter((n) => n.id === node.id).length > 0;
    }

    private toggleAllParentsSelection(node: VirtualTreeFlatNode): void {
        let parent = this.getParentNode(node);
        while (parent) {
            this.toggleNodeSelectionHierarchical(parent);
            parent = this.getParentNode(parent);
        }
    }

    private toggleNodeSelectionHierarchical(node: VirtualTreeFlatNode): void {
        const nodeSelected = this.nodeSelection.isSelected(node);
        const allDescendantsSelected = this.allDescendantsSelected(node);
        if (nodeSelected && !allDescendantsSelected) {
            this.nodeSelection.deselect(node);
        }
        else if (!nodeSelected && allDescendantsSelected) {
            this.nodeSelection.select(node);
        }
    }

    protected getParentNode(node: VirtualTreeFlatNode): VirtualTreeFlatNode | void {
        const currentLevel = this.getNodeLevel(node);

        if (currentLevel < 1) {
            return;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i -= 1) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getNodeLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
    }

    protected updateNodeSelection(): void {
        const selection = this.selectedNodes && this.selectedNodes.length > this.nodeSelection.selected.length
            ? [...this.selectedNodes]
            : [...this.nodeSelection.selected];
        const updatedNodeSelection = selection
            .map((node) => {
                const correspondingDataNode = this.treeControl.dataNodes.find((dataNode) => node && dataNode.id === node.id);
                return correspondingDataNode || node;
            })
            .filter((item) => item);
        this.nodeSelection.clear();
        if (this.selectedNodes && this.selectedNodes.length) {
            this.checkboxSelectTimer = setTimeout(() => {
                clearTimeout(this.checkboxSelectTimer);
                this.nodeSelection.select(...updatedNodeSelection);
            }, 0);
        }
        else {
            this.nodeSelection.select(...updatedNodeSelection);
        }

        if (this.selectionMode === VirtualTreeSelectionMode.HIERARCHICAL_MULTI_SELECT) {
            this.treeControl.dataNodes.slice().reverse().forEach((node) => {
                if (this.allDescendantsSelected(node)) {
                    this.nodeSelection.select(node);
                }
            });
        }
    }

    private getNodeLevel({ level }: VirtualTreeFlatNode): number {
        return level;
    }

    private getIsNodeExpandable({ hasChildren }: VirtualTreeFlatNode): boolean {
        return hasChildren;
    }

    private nodeTransformer(node: VirtualTreeNode, level: number): VirtualTreeFlatNode {
        return {
            ...node,
            level,
            hasChildren: node && node.items && node.items.length > 0
        };
    }

    private getNodeChildren({ items }: VirtualTreeNode): VirtualTreeNode[] {
        return items;
    }

    private shouldLoadBinder(node: VirtualTreeFlatNode): node is Binder {
        return node.type === 'binder'
            && this.loadBinderOnSelect
            && this.treeControl.getDescendants(node).length === 0;
    }

    private shouldLoadNodeItems(node: VirtualTreeFlatNode): node is Binder | Folder {
        return (node.type === 'binder' || node.type === 'folder')
            && !node.items
            && !this.treeControl.isExpanded(node);
    }

    private loadNodeItems(node: VirtualTreeFlatNode): Observable<VirtualTreeFlatNode> {
        if (this.shouldLoadNodeItems(node)) {
            this.loadingNodeId = node.id;

            return from(this.loadNode({
                objectId: node.id,
                objectType: node.type,
                ...(node.type === 'folder') && {
                    binderId: node.binderId,
                    lineage: node.lineage
                }
            })).pipe(
                take(1),
                pluck('items'),
                map((items) => {
                    delete this.loadingNodeId;
                    this.updateTree(node, items);
                    return this.treeControl.dataNodes.find((item) => node.id === item.id);
                })
            );
        }

        return of<VirtualTreeFlatNode>(node);
    }

    private focusTreeNode(node: VirtualTreeFlatNode): void {
        const elementTofocus = this.isToggleNodeInvisible(node)
            ? document.getElementById(`Checkbox_${node.id}`)
            : document.getElementById(node.id);

        if (elementTofocus) {
            elementTofocus.focus();
        }
        else {
            let nextElement: HTMLElement;

            if (node.type === 'binder') {
                const nextIndex = this.dataSource.data.findIndex((data) => data.id === node.id);
                let currentIndex = nextIndex;

                do {
                    currentIndex = (currentIndex + 1) % this.dataSource.data.length;
                    nextElement = document.getElementById(this.dataSource.data[currentIndex].id);
                } while (this.isToggleNodeInvisible(this.dataSource.data[currentIndex])
                    && currentIndex !== nextIndex);
            }
            else if (node.type === 'folder') {
                if (node.lineage && node.lineage.length > 1) {
                    nextElement = document.getElementById(node.lineage[node.lineage.length - 2]);
                }
                else {
                    nextElement = document.getElementById(node.binderId);
                }
            }

            if (nextElement) {
                nextElement.focus();
            }
        }
    }

    private updateTree(node: VirtualTreeFlatNode, data: VirtualTreeNode[]): void {
        const updatedData = this.dataChange.value;

        if (node.type === 'folder') {
            const binder = updatedData.find((item) => item.id === node.binderId);
            let updatedNode = binder;
            node.lineage.forEach((level) => {
                updatedNode = updatedNode.items.find((item) => item.id === level);
            });
            updatedNode.items = data;
        }
        else {
            updatedData.find((item) => node.id === item.id).items = data;
        }

        this.dataChange.next(updatedData);
    }

    protected filterTreeByDisplayability(nodes: VirtualTreeNode[]): VirtualTreeNode[] {
        return nodes.reduce((accumulator, currentNode) => {
            if (this.isNodeDisplayable(currentNode)) {
                if (currentNode.items) {
                    currentNode.items = this.filterTreeByDisplayability(currentNode.items);
                }
                accumulator.push(currentNode);
            }
            return accumulator;
        }, []);
    }

    protected filterTreeByName(nodes: VirtualTreeNode[], filter: string): VirtualTreeNode[] {
        const filterRegex = new RegExp(_.escapeRegExp(filter), 'i');
        const filteredNodes = nodes.filter((node) => filterRegex.test(node.name || node.title));
        return filteredNodes;
    }

    protected expandAllNodes() {
        this.treeControl.dataNodes
            .filter((element) => this.getIsNodeExpandable(element))
            .forEach((item) => {
                this.expandedNodesIds.push(item.id);
            });
    }

    protected updateExpandedNodes(): void {
        this.expandedNodesIds.forEach((id) => {
            const dataNode = this.treeControl.dataNodes.find((item) => item.id === id);
            this.treeControl.expand(dataNode);
        });
    }
}
