import uiRouter from '@uirouter/angularjs';
import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';
import { FolderizerComponent } from './folderizer.component';


const dependencies = [
    uiRouter
];

export const folderizer = angular
    .module('app.folderizer', dependencies)
    .directive('folderizer', downgradeComponent({ component: FolderizerComponent }) as ng.IDirectiveFactory);
