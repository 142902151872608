import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { AuditTrailService } from '@app/shared/audit-trail/audit-trail.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { StateService } from '@uirouter/core';
import { ROLE_BLINDED_STATUSES, SORT } from '@app/core/constants';
import { take } from 'rxjs/operators';
import { AccessDatesService } from '@app/shared/access-dates/access-dates.service';
import { PaginationPropertiesService } from '@app/shared/pagination-properties/pagination-properties.service';
import { AuditTrailModalComponent } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component';
import { AuditTrailSubject, Crumb, Team } from '@app/shared/models';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import * as _ from 'lodash';
import { TeamService } from '@app/shared/teams/team.service';
import { ManageAccessSubmitEvent } from '@app/widgets/manage-access/manage-access.component.types';
import { ManageAccessComponent } from '@app/widgets/manage-access/manage-access.component';
import { RolesService } from '../../roles.service';
import style from './manage-roles.component.scss';
import template from './manage-roles.component.html';
import { Pagination, Roles } from './manage-roles.component.types';
import { CreateRoleComponent } from '../../components/create-role/create-role.component';
import { EditRoleComponent } from '../../components/edit-role/edit-role.component';
import { RenameRoleComponent } from '../../components/rename-role/rename-role.component';
import { DuplicateRoleComponent } from '../../components/duplicate-role/duplicate-role.component';
import { DuplicateRoleEventParams } from '../../components/duplicate-role/duplicate-role.component.types';
import { DeleteRoleComponent } from '../../components/delete-role/delete-role.component';
import { DeleteRoleEvent } from '../../components/delete-role/delete-role.component.types';

@Component({
    template,
    styles: [String(style)]
})
export class ManageRolesComponent implements OnInit {
    SORT = SORT;
    currentTeam: Team
    roles: Roles;
    pagination: Pagination;
    crumbs: Crumb[];
    maxSize: number;
    loadingRoles = true;
    loadedRoles: boolean;
    showBoundaryLinkNumbers: boolean;
    filterValue: string;
    selectedRole: any;
    roleBlindedStatuses = ROLE_BLINDED_STATUSES;
    areBlindedUnblindedRolesEnabled = false;

    constructor(
        private $state: StateService,
        private rolesService: RolesService,
        private notificationsService: NotificationsService,
        private modalsService: ModalsService,
        private featureFlagService: FeatureFlagService,
        private auditTrailService: AuditTrailService,
        private accessDatesService: AccessDatesService,
        private currentSessionService: CurrentSessionService,
        private apiErrorService: ApiErrorsService,
        private PaginationProperties: PaginationPropertiesService,
        private teamService: TeamService
    ) {
        this.maxSize = this.PaginationProperties.getMaxSize();
        this.showBoundaryLinkNumbers = this.PaginationProperties.showBoundaryLinkNumbers();
        this.pagination = {
            pageNum: 1,
            pageSize: 20,
            sortBy: 'createdAt',
            sortDir: 'ASC',
            totalItems: 0
        };
    }

    ngOnInit(): void {
        this.crumbs = [{ name: 'Manage Roles' }];
        this.currentTeam = this.currentSessionService.getCurrentTeam();
        if (!this.canAccessRolesPage()) {
            this.$state.go('app.select-team');
        }

        this.sortChanged('name');

        this.featureFlagService
            .getFlag(FEATURE_FLAGS.BLINDED_UNBLINDED_ROLES, false)
            .subscribe((value) => {
                this.areBlindedUnblindedRolesEnabled = value
                    && this.currentTeam.permissions.manageBlindedUnblindedRoles;
            });

        this.fetchRoles();

    }

    canAccessRolesPage() {
        return this.currentTeam
            && (this.currentTeam.permissions.manageTeam || this.currentTeam.permissions.viewTeamUsersRolesPermissions);
    }

    fetchRoles() {
        const params = {
            teamId: this.currentTeam.id,
            ...(this.filterValue && {
                filter: {
                    value: this.filterValue,
                    type: 'name'
                }
            }),
            ...this.pagination
        };
        this.deselectAll();
        this.loadingRoles = true;

        this.rolesService
            .getRoles(params.teamId, {
                pageNum: params.pageNum,
                pageSize: params.pageSize,
                sortBy: params.sortBy,
                sortDir: params.sortDir,
                filter: params.filter
            })
            .subscribe(
                (roles) => {
                    this.loadedRoles = true;
                    this.roles = roles.roles;
                    this.pagination.totalItems = this.roles.recordCount;
                },
                (error) => {
                    this.notificationsService.error(error.error.message);
                },
                () => {
                    this.loadingRoles = false;
                }
            );
    }

    addRole(): void {
        if (this.currentTeam.permissions.manageTeamRolesAndPermissions) {

            const createRoleModal = this.modalsService.show(CreateRoleComponent, {
                animated: true,
                class: 'modal-md',
                initialState: {
                    areBlindedUnblindedRolesEnabled:
                        this.areBlindedUnblindedRolesEnabled
                }
            });

            createRoleModal.content.onCreate.subscribe((event) => {
                this.rolesService.createRole(this.currentTeam.id, {
                    name: event.name,
                    blindedStatus: event.blindedStatus
                })
                    .subscribe((role) => {
                        this.notificationsService.clearAll();
                        this.notificationsService.success(`Role ${role.name} created!`);
                        this.roles.items.unshift(role);
                        role.success = true;
                        setTimeout(() => {
                            role.success = false;
                        }, 5000);
                        event.onSuccess();
                    }, (errorData) => {
                        if (errorData.error && errorData.error.message) {
                            this.notificationsService.error(errorData.error.message);
                        }
                        else {
                            this.notificationsService.unexpectedError();
                        }
                        if (errorData?.error?.statusCode === 403) {
                            createRoleModal.hide();
                        }
                        event.onError();
                        throw errorData;
                    });
            });
        }
    }

    assignRole() {
        if (!this.currentTeam.permissions.viewTeamUsersRolesPermissions || !this.selectedRole) {
            return;
        }

        const { teamId } = this.selectedRole;
        const roleId = this.selectedRole.id;


        this.accessDatesService.getUsersForRole({ teamId, roleId }).subscribe((userRoles) => {
            const items = userRoles;

            const modalInstance = this.modalsService.show(ManageAccessComponent, {
                animated: false,
                class: 'modal-lg',
                keyboard: false,
                initialState: {
                    items,
                    subject: _.clone(this.selectedRole),
                    canAssignDates: this.currentTeam.permissions.manageTeamAccessControl,
                    canAssignRoles: this.currentTeam.permissions.assignTeamRoles,
                    itemType: 'user'
                }
            });

            modalInstance.content.submit.subscribe((event: ManageAccessSubmitEvent) => {
                const {
                    creates, updates, deletes
                } = event.data;
                this.teamService.updateRoleAssignmentMultiple(teamId, { creates, updates, deletes })
                    .subscribe(() => {
                        event.onSuccess();
                    }, event.onError);
            });

            modalInstance.content.dismiss.subscribe(() => modalInstance.hide());

        }, (error) => {
            this.notificationsService.error(error.error.message);
        });
    }

    openEditRoleModal() {

        this.rolesService.getUsersAssignedToRole(this.currentTeam.id, this.selectedRole.id)
            .pipe(take(1))
            .subscribe((results) => {
                const editRoleModal = this.modalsService.show(EditRoleComponent, {
                    class: 'modal-md',
                    animated: true,
                    initialState: {
                        role: _.cloneDeep(this.selectedRole),
                        usersAssignedToRole: results?.users?.current
                    }
                });

                editRoleModal.content.onEdit.subscribe((eventRename) => {
                    const { role, onSuccess, onError } = eventRename;
                    this.rolesService.updateRole(role)
                        .subscribe((role) => {
                            this.notificationsService.success('Role Updated!');
                            _.merge(_.find(this.roles.items, { id: role.id }), role);
                            role.success = true;
                            onSuccess();
                        }, (errorData) => {
                            if (errorData.error && errorData.error.message) {
                                this.notificationsService.error(errorData.error.message);
                            }
                            else {
                                this.notificationsService.unexpectedError();
                            }
                            if (errorData?.error?.statusCode === 403) {
                                editRoleModal.hide();
                            }
                            onError();
                            throw errorData;
                        });
                });
            });
    }

    openRenameRoleModal() {
        const renameRoleModal = this.modalsService.show(RenameRoleComponent, {
            class: 'modal-md',
            animated: true,
            initialState: {
                role: _.cloneDeep(this.selectedRole)
            }
        });

        renameRoleModal.content.onRename.subscribe((eventRename) => {
            const { role, onSuccess, onError } = eventRename;
            this.rolesService.updateRole(role)
                .subscribe((role) => {
                    this.notificationsService.success('Role Updated!');
                    _.merge(_.find(this.roles.items, { id: role.id }), role);
                    role.success = true;
                    setTimeout(() => {
                        role.success = false;
                    }, 5000);
                    onSuccess();
                }, (errorData) => {
                    if (errorData.error && errorData.error.message) {
                        this.notificationsService.error(errorData.error.message);
                    }
                    else {
                        this.notificationsService.unexpectedError();
                    }
                    if (errorData?.error?.statusCode === 403) {
                        renameRoleModal.hide();
                    }
                    onError();
                    throw errorData;
                });
        });
    }

    editRole() {
        if (!(this.currentTeam.permissions.manageTeamRolesAndPermissions && this.selectedRole)) {
            return;
        }

        this.areBlindedUnblindedRolesEnabled
            ? this.openEditRoleModal()
            : this.openRenameRoleModal();
    }

    duplicateRole() {
        if (!(this.currentTeam.permissions.manageTeamRolesAndPermissions && this.selectedRole)) {
            return;
        }

        const duplicateRoleModal = this.modalsService.show(DuplicateRoleComponent, {
            animated: true,
            class: 'modal-md',
            initialState: {
                role: this.selectedRole
            }
        });

        duplicateRoleModal.content.onDuplicate.subscribe((event: DuplicateRoleEventParams) => {
            this.rolesService.duplicateRole(event.role)
                .subscribe(
                    (role) => {
                        event.onSuccess();
                        this.notificationsService.success('Role duplicated!');
                        this.roles.items.unshift(role);
                        role.success = true;
                        setTimeout(() => {
                            role.success = false;
                        }, 5000);
                        this.deselectAll();
                        return role;
                    },
                    (errorData) => {
                        event.onError();
                        if (errorData.error && errorData.error.message) {
                            this.notificationsService.error(errorData.error.message);
                        }
                        else {
                            this.notificationsService.unexpectedError();
                        }
                        throw errorData;
                    }
                );
        });
    }

    deleteRole() {
        if (!(this.currentTeam.permissions.manageTeamRolesAndPermissions && this.selectedRole)) {
            return;
        }

        const deleteRoleModal = this.modalsService.show(DeleteRoleComponent, {
            animated: true,
            class: 'modal-md',
            initialState: {
                role: this.selectedRole
            }
        });

        deleteRoleModal.content.onDestroy.subscribe((data: DeleteRoleEvent) => {
            this.rolesService.deleteRole(data.role, data.reason)
                .subscribe(
                    () => {
                        this.notificationsService.success('Role Deleted!');
                        _.remove(this.roles.items, { id: data.role.id });

                        if (!this.roles.items.length) {
                            this.pagination.pageNum = Math.max(this.pagination.pageNum - 1, 1);
                        }

                        this.fetchRoles();
                        data.onSuccess();
                    },
                    () => {
                        data.onError();
                    }
                );
        });
    }

    openAuditTrailModal() {
        if ((this.currentTeam.permissions.viewTeamAuditTrail && this.selectedRole)) {

            // Special case. Since role cannot be an object in permissions, it cannot
            // be decorated with permissions like teams, binders, etc... So here
            // we supply the downloadTeamAuditTrail privilege which is the criteria
            // for downloading any audit trail at the role level.
            const permissionDecoratedRole = _.assign({}, this.selectedRole, {
                permissions: {
                    downloadTeamAuditTrail: this.currentTeam.permissions.downloadTeamAuditTrail
                }
            });

            const params = {
                subject: AuditTrailSubject.ROLE,
                teamId: permissionDecoratedRole.teamId,
                objectId: permissionDecoratedRole.id,
                overwrittenObjectId: permissionDecoratedRole.overwrittenPlaceholderId || null,
                limitToOverwritten: false,
                ...this.auditTrailService.auditPagination
            };

            this.auditTrailService.getAudits(params).subscribe(
                (audits) => {
                    this.modalsService.show(AuditTrailModalComponent, {
                        class: 'modal-lg',
                        initialState: {
                            data: audits,
                            item: permissionDecoratedRole,
                            subject: params.subject,
                            pagination: this.auditTrailService.auditPagination,
                            onPageChange: this.auditTrailService.getAudits.bind(this.auditTrailService)
                        }
                    });
                },
                (error) => {
                    this.apiErrorService.handleError(error);
                }
            );
        }
    }

    select(role) {
        if (role.selected) {
            role.selected = false;
            this.selectedRole = undefined;
        }
        else {
            this.deselectAll();
            role.selected = true;
            this.selectedRole = role;
        }
    }

    deselectAll() {
        if (this.selectedRole) {
            this.selectedRole.selected = false;
        }
        this.selectedRole = undefined;
    }

    trackByIndex(index: number): number {
        return index;
    }

    toggleActions($event, role) {
        $event.preventDefault();
        if (this.selectedRole !== role) {
            this.select(role);
        }
    }

    canActOnSelection() {
        return this.selectedRole
            && (this.currentTeam.permissions.viewTeamAuditTrail
                || this.currentTeam.permissions.manageTeamRolesAndPermissions
                || this.currentTeam.permissions.viewTeamUsersRolesPermissions);
    }

    openDirectPermissionReport(role) {
        this.$state.go('app.team.permission-report', {
            teamId: this.currentTeam.id,
            subjectId: role.id,
            subjectType: 'role',
            filter: 'PERM'
        });
    }

    canManageTeamRolesAndPermissions() {
        return this.currentTeam && this.currentTeam.permissions.manageTeamRolesAndPermissions && this.selectedRole;
    }

    sortChanged(sortProperty) {

        if (this.pagination.sortBy === sortProperty) {
            this.pagination.sortDir = this.pagination.sortDir === 'ASC' ? 'DESC' : 'ASC';
        }

        this.pagination.sortBy = sortProperty;
        this.fetchRoles();
    }

    pageChanged({ page }: { page: number }): void {
        this.pagination.pageNum = page;
        this.fetchRoles();
    }

    applyFilter(filter) {
        this.pagination.pageNum = 1;
        this.filterValue = filter;
        this.fetchRoles();
    }

    openRolesTemplates() {
        this.$state.go('app.team.manage-roles-templates', { teamId: this.currentTeam.id });
    }

}
