import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Connection } from '../connected-teams.types';

@Injectable()
export class ConnectedTeamsApiService {
    readonly url = {
        connectedTeams: (teamId: string): string => `/api/teams/${teamId}/connections`
    }

    constructor(
        private http: HttpClient
    ) {}

    public getConnectedTeams(teamId: string): Observable<Connection[]> {
        return this.http.get<Connection[]>(this.url.connectedTeams(teamId));
    }
}
