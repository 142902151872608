import { Component, Input, OnInit } from '@angular/core';

import { GetLogImportRowsPreviewResponse } from '@app/shared/documents-log-entries/document-log-entries.service.types';

import template from './log-import-rows-preview.component.html';
import styles from './log-import-rows-preview.component.scss';

@Component({
    selector: 'log-import-rows-preview',
    template,
    styles: [String(styles)]
})

export class LogImportRowsPreviewComponent implements OnInit {
    @Input() data: GetLogImportRowsPreviewResponse = null;

    gridStyles: { '-ms-grid-columns'?: string; 'grid-template-columns'?: string } = {};
    columnWidth: string;

    ngOnInit() {
        this.columnWidth = this.calcColumnWidth(this.data.columns.length);
        this.gridStyles = {
            '-ms-grid-columns': `(${this.columnWidth})[${this.data.columns.length}]`,
            'grid-template-columns': `repeat(${this.data.columns.length}, ${this.columnWidth})`
        };
    }

    private calcColumnWidth(colCount: number): string {
        switch (colCount) {
            case 1:
                return '740px';
            case 2:
                return '370px';
            case 3:
                return '250px';
            case 4:
            default:
                return '200px';
        }
    }
}
