import * as _ from 'lodash';
import { Injectable, Inject } from '@angular/core';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { HttpClient } from '@angular/common/http';
import { Team, Download, DownloadStatuses } from '@app/shared/models';
import {
    Subject, BehaviorSubject, Observable, interval, Subscription
} from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { getFilenameFromHeader } from './get-filename-form-header.util';

@Injectable({
    providedIn: 'root'
})
export class DownloadCheckerService {
    readonly url = {
        base: (teamId: string): string => `/api/teams/${teamId}/downloads`,
        download: (teamId: string, downloadId: string): string => `/api/teams/${teamId}/downloads/${downloadId}/download`
    };

    private pendingDownloads: Download[];
    private completeDownloadsCount = new BehaviorSubject<number>(0);
    public completeDownloadsCount$ = this.completeDownloadsCount.asObservable();
    private updatedDownload = new Subject<Download>();
    public updatedDownload$ = this.updatedDownload.asObservable();
    private interval$ = interval(5000);
    private polling: Subscription;
    private teamId: string;

    constructor(
        private http: HttpClient,
        @Inject('Window') private window: Window,
        private Notifications: NotificationsService,
        private CurrentSession: CurrentSessionService
    ) {}

    init(): void {
        this.CurrentSession.currentTeam$
            .pipe(distinctUntilChanged((prev, curr) => prev && curr && prev.id === curr.id))
            .subscribe(this.onTeamChanged.bind(this));
    }

    startPolling(): void {
        if (this.polling) {
            return;
        }
        this.polling = this.interval$.subscribe(() => {
            this.getPendingDownloads(this.teamId).subscribe(
                ({ downloads }) => {
                    if (!this.polling) {
                        return;
                    }
                    this.handleCompleted(this.teamId, downloads);
                    this.pendingDownloads = downloads;
                    if (!this.pendingDownloads.length) {
                        this.stopPolling();
                    }
                },
                () => {
                    this.stopPolling();
                }
            );
        });
    }

    stopPolling(): void {
        if (this.polling) {
            this.polling.unsubscribe();
            this.polling = undefined;
        }
    }

    itemDownloaded(download: Download): void {
        if (download.status !== DownloadStatuses.complete) {
            return;
        }
        download.status = DownloadStatuses.downloaded;
        const count = Math.max(this.completeDownloadsCount.getValue() - 1, 0);
        this.completeDownloadsCount.next(count);
    }

    downloadRequested(download: Download): void {
        this.pendingDownloads.push(download);
        this.startPolling();
    }

    downloadRemoved(downloads: Download[]): void {
        const completed = _.reduce(downloads, (acc, curr) => {
            return [DownloadStatuses.complete, DownloadStatuses.downloaded].includes(curr.status) ? acc + 1 : acc;
        }, 0);
        const count = Math.max(this.completeDownloadsCount.getValue() - completed, 0);
        this.completeDownloadsCount.next(count);
        this.pendingDownloads = _.differenceBy(this.pendingDownloads, downloads, 'id');
    }

    private onTeamChanged(team: Team): void {
        if (!team) {
            this.teamId = undefined;
            return this.stopPolling();
        }

        if (this.teamId === team.id) {
            return;
        }

        this.teamId = team.id;
        this.getCompleteDownloadsCount(this.teamId).subscribe(({ count }) => {
            this.completeDownloadsCount.next(count);
        });

        this.getPendingDownloads(this.teamId).subscribe(({ downloads }) => {
            this.pendingDownloads = downloads;

            if (this.pendingDownloads) {
                this.startPolling();
            }
        });
    }


    private handleCompleted(teamId: string, pendingDownloads: Download[]): void {
        const completed = _.differenceBy(this.pendingDownloads, pendingDownloads, 'id');
        if (completed.length) {
            this.completeDownloadsCount.next(this.completeDownloadsCount.getValue() + completed.length);
            completed.forEach((item) => this.handleSingleDownloadCompletion(teamId, item));
        }
    }

    private handleSingleDownloadCompletion(teamId: string, download: Download): void {
        download.status = DownloadStatuses.complete;
        this.updatedDownload.next(download);

        this.http.get<Blob>(this.url.download(teamId, download.id), {
            observe: 'response',
            responseType: 'blob' as 'json'
        })
            .subscribe(
                (response) => {
                    this.downloadToBrowser(response);

                    const href = `/#/app/teams/${teamId}/downloads`;
                    const message = `Your file has been downloaded to your computer and
                                <a class="page-action u-d-inline" href=${href}>My Download’s page</a>.`;
                    this.Notifications.success({ message, closeOnClick: false });

                    this.itemDownloaded(download);
                    this.updatedDownload.next(download);
                }
            );
    }

    private downloadToBrowser(response) {
        const blob = new Blob([response.body], {
            type: 'application/octet-stream'
        });

        const fileName = getFilenameFromHeader(response.headers.get('content-disposition'));
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(link.href);
    }

    private getPendingDownloads(teamId: string): Observable<{ count: number; downloads: Download[]}> {
        const params = {
            'filter': JSON.stringify({ status: DownloadStatuses.pending }) // eslint-disable-line quote-props
        };
        return this.http.get<{ count: number; downloads: Download[]}>(this.url.base(teamId), { params });
    }

    private getCompleteDownloadsCount(teamId: string): Observable<{ count: number; downloads: []}> {
        const params = {
            'filter': JSON.stringify({ status: DownloadStatuses.complete }), // eslint-disable-line quote-props
            'countOnly': 'true' // eslint-disable-line quote-props
        };
        return this.http.get<{ count: number; downloads: []}>(this.url.base(teamId), { params });
    }
}
