import {
    ChangeDetectionStrategy,
    Component, Input, OnDestroy, OnInit
} from '@angular/core';
import {
    catchError,
    distinctUntilChanged, take, takeUntil, tap
} from 'rxjs/operators';
import {
    AbstractControl, FormBuilder, FormControl, Validators
} from '@angular/forms';
import { StateService } from '@uirouter/core';
import {
    BehaviorSubject, Observable, Subject, of
} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import template from './doa-log-template-form.component.html';
import styles from './doa-log-template-form.component.scss';
import {
    CreateLogTemplateRequestBody,
    LogTemplate, StudyResponsibilities, StudyRole, StudyRoleResponsibilities, Team
} from '../../../../shared/models';
import { DOA_LOG_TEMPLATE_FORM_CONTROLS } from '../doa-log-template/doa-log-template.types';
import { notBlank } from '../../../../core/form-validators';
import { MESSAGES, REGEX, ROUTES } from '../../../../core/constants';
import { LogTemplateUtil } from '../../utils/log-template.util';
import { FORM_CONTROL_STATUS } from '../../../../shared/forms/constants/form-control-status';
import { ModalRef, ModalsService } from '../../../../shared/modal-helper/modals.service';
import { DoaLogTemplateRolesResponsibilitiesEditComponent } from '../doa-log-template/doa-log-template-roles-responsibilities-edit/doa-log-template-roles-responsibilities-edit.component';
import { LogTemplatesService } from '../../../../shared/log-templates/log-templates.service';
import { NotificationsService } from '../../../../core/notifications/notifications.service';
import { LogTemplateType } from '../log-template-type-selector/log-template-type-selector.component.types';
import { CurrentSessionService } from '../../../../core/current-session.service';
import { ConfirmationModal } from '../../../../shared/modals/confirmation-modal/confirmation-modal';
import { StudyRolesService } from '../../../../shared/study-roles/study-roles.service';

@Component({
    selector: 'doa-log-template-form',
    template,
    styles: [String(styles)],
    providers: [ModalsService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoaLogTemplateFormComponent implements OnInit, OnDestroy {
    constructor(
        private modalsService: ModalsService,
        private formBuilder: FormBuilder,
        private logTemplatesService: LogTemplatesService,
        public studyRolesService: StudyRolesService,
        private notificationsService: NotificationsService,
        private $state: StateService,
        private currentSessionService: CurrentSessionService
    ) {}

    @Input() doaLogTemplate: LogTemplate;
    @Input() canEdit: boolean;

    responsibilities: StudyResponsibilities;
    studyRoles: StudyRole[];

    private studyRoleResponsibilities = new BehaviorSubject<StudyRoleResponsibilities[]>([]);
    studyRoleResponsibilities$ = this.studyRoleResponsibilities.asObservable();

    doaLogTemplateForm = this.formBuilder.group({});

    currentTeam: Team;

    isSubmittingForm = new BehaviorSubject(false);
    isSubmittingForm$ = this.isSubmittingForm.asObservable();

    readonly entityName = 'DOA log template';

    private isFormValid = new BehaviorSubject(false);
    isFormValid$ = this.isFormValid.asObservable();

    logLegendFormControl: FormControl;
    generalInformationFormControl: FormControl;
    versionCommentsFormControl: FormControl;

    editRolesResponsibilitiesModalRef: ModalRef<DoaLogTemplateRolesResponsibilitiesEditComponent>;

    readonly logLegendFormControlName = DOA_LOG_TEMPLATE_FORM_CONTROLS.LEGEND;
    readonly generalInformationFormControlName = DOA_LOG_TEMPLATE_FORM_CONTROLS.INFORMATION;
    readonly versionCommentsFormControlName = DOA_LOG_TEMPLATE_FORM_CONTROLS.COMMENT;

    private $destroy = new Subject<void>();

    ngOnInit(): void {
        this.currentTeam = this.currentSessionService.getCurrentTeam();
        this.responsibilities = this.doaLogTemplate.responsibilities;
        this.studyRoles = this.doaLogTemplate.roles
            .map(({ studyRole, responsibilityIds }) => ({
                studyRole: studyRole._id,
                responsibilityIds
            }));
        this.studyRolesService.setStudyRolesList$.pipe(
            tap(() => {
                this.setStudyRoleResponsibilities();
            })
        ).subscribe();

        this.initFormControls();

        this.setIsFormValidOnStatusChanges$().pipe(
            takeUntil(this.$destroy)
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.$destroy.next();
        this.$destroy.complete();
    }

    getFormControlErrorMessage(
        formControl: AbstractControl
    ): { message: string } {
        let message = '';

        switch (true) {
            case formControl.errors?.required:
            case formControl.errors?.blank:
                message = MESSAGES.invalidBlankMessage;
                break;
            case formControl.errors?.maxlength?.requiredLength === 250:
                message = MESSAGES.validNameMessage;
                break;
            default:
                message = MESSAGES.validExtremelyLongTextMessage;
        }

        return { message };
    }

    openRolesResponsibilitiesEditModal(): void {
        this.editRolesResponsibilitiesModalRef = this.modalsService.show(DoaLogTemplateRolesResponsibilitiesEditComponent, {
            class: 'modal-lg',
            initialState: {
                existingStudyResponsibilities: { ...this.responsibilities },
                existingStudyRoles: [...this.studyRoles],
                studyRolesList$: this.studyRolesService.studyRolesList$,
                studyRolesMap$: this.studyRolesService.studyRolesMap$,
                isLoadingStudyRoles$: this.studyRolesService.isLoadingStudyRoles$
            }
        });

        this.editRolesResponsibilitiesModalRef.content.rolesResponsibilitiesEdited.pipe(
            tap(({ data, onSuccess }) => {
                const updatedResponsibilities = data.studyResponsibilities.responsibilities;
                const updatedStudyRoles = data.studyRoles.roles;

                this.responsibilities = updatedResponsibilities;
                this.studyRoles = updatedStudyRoles;

                this.setStudyRoleResponsibilities();
                this.doaLogTemplateForm.markAsDirty();
                onSuccess();
            }),
            take(1)
        ).subscribe();
    }

    onSaveTemplate(): void {
        const logTemplateId = this.doaLogTemplate.id.templateId;
        const updateLogTemplateRequestBody = this.formatLogTemplateUpdateRequestBody();

        this.isSubmittingForm.next(true);

        this.logTemplatesService.updateLogTemplate(
            this.currentTeam.id,
            logTemplateId,
            updateLogTemplateRequestBody
        ).pipe(
            tap(() => {
                this.onFormSubmittedSuccessfully();
            }),
            catchError((httpErrorResponse: HttpErrorResponse) => {
                this.onFormSubmissionError(httpErrorResponse);
                return of(null);
            }),
            take(1)
        ).subscribe();
    }

    onBack(): void {
        if (this.doaLogTemplateForm.pristine) {
            this.$state.go(ROUTES.doaLogs);
            return;
        }
        const unsavedChangesModal = this.openUnsavedChangesModal();

        unsavedChangesModal.content.handleConfirm = () => {
            this.modalsService.hide(1);
            this.$state.go(ROUTES.doaLogs);
        };
    }

    private setStudyRoleResponsibilities(): void {
        const studyRoleResponsibilities = this.mapRoleAndResponsibilities();

        this.studyRoleResponsibilities.next(studyRoleResponsibilities);
    }

    private formatResponsibilityNameWithIdenfitifer(
        studyRoleResponsibilityId: string,
        studyRoleResponsibilityName: string
    ): string {
        const studyResponsibilityIndex = this.getStudyResponsibilityIndex(studyRoleResponsibilityId);
        const identifier = LogTemplateUtil.getNumberOrLetterIdentifier(
            studyResponsibilityIndex,
            this.responsibilities.isNumberIdentifier
        );

        return `${identifier}. ${studyRoleResponsibilityName}`;
    }

    private getStudyResponsibilityIndex(responsibilityId: string) {
        return this.responsibilities.values.findIndex((r) => r._id === responsibilityId);
    }

    private mapRoleAndResponsibilities(): StudyRoleResponsibilities[] {
        const responsibilityIdNameDictionary = LogTemplateUtil.generateResponsibilityIdNameDictionary(
            this.responsibilities.values
        );

        const studyRoleResponsibilities = this.studyRoles?.map((role) => {
            const responsibilityNamesWithIdentifier = role.responsibilityIds
                .filter((studyRoleResponsibilityId) => responsibilityIdNameDictionary[studyRoleResponsibilityId] !== undefined)
                .sort(this.sortStudyRoleResponsibilities.bind(this))
                .map((studyRoleResponsibilityId) => {
                    const studyRoleResponsibilityName = responsibilityIdNameDictionary[studyRoleResponsibilityId];

                    return this.formatResponsibilityNameWithIdenfitifer(
                        studyRoleResponsibilityId,
                        studyRoleResponsibilityName
                    );
                });

            return {
                name: this.studyRolesService.getStudyRoleNameById(role.studyRole),
                responsibilities: responsibilityNamesWithIdentifier
            };
        });

        return studyRoleResponsibilities;
    }

    private sortStudyRoleResponsibilities(
        studyRoleResponsibilityA: string,
        studyRoleResponsibilityB: string
    ): number {
        const indexOfStudyRoleResponsibilityA = this.getStudyResponsibilityIndex(studyRoleResponsibilityA);
        const indexOfStudyRoleResponsibilityB = this.getStudyResponsibilityIndex(studyRoleResponsibilityB);

        return indexOfStudyRoleResponsibilityA - indexOfStudyRoleResponsibilityB;
    }

    private initFormControls(): void {
        this.initLogLegendFormControl();

        this.initGeneralInformationFormControl();

        this.initVersionCommentsFormControl();
    }

    private initLogLegendFormControl(): void {
        this.logLegendFormControl = this.formBuilder.control({
            value: this.doaLogTemplate.legend,
            disabled: !this.canEdit
        }, [
            Validators.pattern(REGEX.extremelyLongText),
            Validators.maxLength(10000),
            notBlank
        ]);

        this.doaLogTemplateForm.addControl(
            this.logLegendFormControlName,
            this.logLegendFormControl
        );
    }

    private initGeneralInformationFormControl(): void {
        this.generalInformationFormControl = this.formBuilder.control({
            value: this.doaLogTemplate.information,
            disabled: !this.canEdit
        }, [
            Validators.pattern(REGEX.extremelyLongText),
            Validators.maxLength(10000),
            notBlank
        ]);


        this.doaLogTemplateForm.addControl(
            this.generalInformationFormControlName,
            this.generalInformationFormControl
        );
    }

    private initVersionCommentsFormControl(): void {
        this.versionCommentsFormControl = this.formBuilder.control({
            value: '',
            disabled: !this.canEdit
        }, [
            Validators.pattern(REGEX.names),
            Validators.maxLength(250),
            notBlank
        ]);


        this.doaLogTemplateForm.addControl(
            this.versionCommentsFormControlName,
            this.versionCommentsFormControl
        );
    }

    private setIsFormValidOnStatusChanges$(): Observable<void> {
        return this.doaLogTemplateForm.statusChanges.pipe(
            distinctUntilChanged(),
            tap((status) => {
                const isValid = status === FORM_CONTROL_STATUS.valid;
                this.isFormValid.next(isValid);
            })
        );
    }

    private formatLogTemplateUpdateRequestBody(): CreateLogTemplateRequestBody {
        const doaLogTemplateFormValue = this.doaLogTemplateForm.getRawValue();

        const responsibilityIdNameDictionary = LogTemplateUtil.generateResponsibilityIdNameDictionary(
            this.responsibilities.values
        );
        const logTemplateStudyResponsibilitiesToCreate = LogTemplateUtil.formatResponsibilitiesToCreate(this.responsibilities);
        const { values: studyResponsibilityNames } = logTemplateStudyResponsibilitiesToCreate;

        const logTemplateStudyRolesToCreate = LogTemplateUtil.formatStudyRolesToCreate(
            this.studyRoles,
            responsibilityIdNameDictionary
        );
        const studyRoleIds = logTemplateStudyRolesToCreate.map(({ studyRole }) => studyRole);

        const logColumnsToCreate = LogTemplateUtil.formatLogColumnsToCreate(
            doaLogTemplateFormValue.columns,
            studyResponsibilityNames,
            studyRoleIds
        );

        const updateLogTemplateRequestBody = {
            ...doaLogTemplateFormValue,
            type: LogTemplateType.DOA,
            columns: logColumnsToCreate,
            responsibilities: logTemplateStudyResponsibilitiesToCreate,
            roles: logTemplateStudyRolesToCreate
        };

        return updateLogTemplateRequestBody;
    }

    private onFormSubmittedSuccessfully(): void {
        const successMessage = MESSAGES.successfullyUpdated(this.entityName);

        this.notificationsService.success(successMessage);
        this.isSubmittingForm.next(false);
        this.$state.go(ROUTES.doaLogs, { logType: LogTemplateType.DOA });
    }

    private onFormSubmissionError(httpErrorResponse: HttpErrorResponse): void {
        const { error } = httpErrorResponse;
        const isBadRequest = error.statusCode >= 400 && error.statusCode < 500;
        const errorMessage = isBadRequest
            ? error.message
            : MESSAGES.failedToCreate(this.entityName);

        this.notificationsService.error(errorMessage);
        this.isSubmittingForm.next(false);
    }

    private openUnsavedChangesModal(): ModalRef<ConfirmationModal> {
        const unsavedChangesModal = this.modalsService.show(ConfirmationModal, {
            initialState: {
                showModalCloseButton: true,
                confirmationTitle: 'Unsaved changes',
                confirmationMessage: 'You have unsaved changes. Are you sure you want to leave without saving?',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Discard'
            }
        });

        return unsavedChangesModal;
    }
}
