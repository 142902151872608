import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';
import { StateService } from '@uirouter/angularjs';

import {
    Announcement,
    User,
    Team,
    Crumb
} from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';

import { AnnouncementsService } from '../../annoucements.service';
import { GetAnnouncementsParams } from '../../annoucements.service.types';
import { Pagination, ReturnTo } from '../../components/announcements-table/announcements-table.component.types';
import { AnnouncementSendComponent } from '../announcement-send/announcement-send.component';

import template from './announcements-inbox.component.html';

@Component({
    selector: 'announcement-inbox',
    template
})
export class AnnouncementsInboxComponent implements OnInit {
    public team: Team;
    public user: User;
    public announcements: Announcement[];
    public announcementParams: GetAnnouncementsParams;
    public crumbs: Crumb[] = [{ name: 'Announcements' }];
    public isLoading = true;
    public canSendAnnouncement = false;
    public canViewTeamInbox = false;
    public recordCount: number;
    public returnTo: ReturnTo;
    public stateName: string;
    public userInboxTabStateParams: GetAnnouncementsParams;
    public teamInboxTabStateParams: GetAnnouncementsParams;
    public readonly tooltipDelay = 200;

    constructor(
        private $state: StateService,
        private Announcements: AnnouncementsService,
        private CurrentSession: CurrentSessionService,
        private Modals: ModalsService
    ) {}

    ngOnInit(): void {
        this.team = this.CurrentSession.getCurrentTeam();
        this.user = this.CurrentSession.getCurrentUser();
        this.stateName = this.$state.current.name;
        this.returnTo = {
            stateName: this.stateName,
            params: { ...this.$state.params }
        };
        this.announcementParams = _.defaults(
            _.pick(this.returnTo.params, ['teamId', 'type', 'pageNum', 'sortBy', 'sortDir']),
            { type: 'user' } as GetAnnouncementsParams
        );
        this.canSendAnnouncement = this.team.permissions.sendAnnouncements;
        this.canViewTeamInbox = this.team.permissions.viewAnnouncements;
        this.setTabsStateParams();
        this.fetchInboxData();
    }

    changeInboxView(type: 'user' | 'team' = 'user'): void {
        if (type === this.announcementParams.type) {
            return;
        }

        this.returnTo = {
            stateName: this.$state.current.name,
            params: { ...this.$state.params }
        };
        this.announcementParams = { teamId: this.announcementParams.teamId, type };
        this.fetchInboxData();
    }

    changePageOrSort($event: Pagination): void {
        _.assign(this.announcementParams, $event);
        this.fetchInboxData();
    }

    fetchInboxData(): void {
        this.isLoading = true;
        this.Announcements.getAnnouncements(this.team.id, this.user.id, this.announcementParams)
            .subscribe(
                (data) => {
                    this.announcements = data.items;
                    this.recordCount = data.recordCount;
                    this.returnTo.params = this.announcementParams;
                    this.isLoading = false;
                },
                () => {
                    this.isLoading = false;
                }
            );
    }

    openSendModal(): void {
        if (!this.canSendAnnouncement) {
            return;
        }

        const modalRef = this.Modals.show(AnnouncementSendComponent, {
            initialState: {
                teamId: this.team.id
            }
        });

        modalRef.content.save
            .pipe(take(1))
            .subscribe(() => {
                this.fetchInboxData();
            });
    }

    private setTabsStateParams(): void {
        this.userInboxTabStateParams = { ...this.announcementParams, type: 'user' };
        this.teamInboxTabStateParams = { ...this.announcementParams, type: 'team' };
    }
}
