import * as _ from 'lodash';

import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { MESSAGES } from '@app/core/constants';
import { Label } from '@app/shared/labels/labels.service.types';
import { LabeledEntity } from '@app/shared/models';

import { Updates } from '@app/components/binders/components/binder-form/binder-form.component.types';
import template from './create-new-option.component.html';
import styles from './create-new-option.component.scss';

@Component({
    selector: 'create-new-option',
    template,
    styles: [String(styles)]
})
export class CreateNewOptionComponent {
    @Input() isBinder: boolean;
    @Input() availableLabels: Label[];
    @Input() assignedLabels: LabeledEntity[] = [];
    @Input() form: FormGroup = null;
    @Output() onLabelsChanged = new EventEmitter<LabeledEntity[]>();

    readonly nameMessage = MESSAGES.validNameMessage;

    updates: Updates = {
        addedLabels: [],
        removedLabels: []
    };

    handleAssignLabels(params = []): void {
        const assignments = [];
        params.forEach((param) => {
            const {
                labelId,
                valueId,
                labelName,
                value
            } = param;
            const assignment = {
                labelId,
                labelName,
                valueId,
                value,
                objectId: undefined,
                objectType: this.isBinder ? 'binder' : 'folder'
            } as LabeledEntity;

            this.updates.addedLabels.push(assignment);
            assignments.push(assignment);
        });

        _.pullAllBy(this.updates.removedLabels, assignments, 'valueId');
        this.assignedLabels = this.assignedLabels.concat(assignments);
        this.form.markAsDirty();
        this.onLabelsChanged.emit(this.assignedLabels);
    }

    handleRemoveLabel(params): void {
        const {
            labelId,
            valueId,
            labelName,
            value
        } = params;
        const isAssigned = this.assignedLabels
            .find((l) => l.labelId === labelId
                && l.valueId === valueId
                && !l.objectId);
        if (!isAssigned) {
            return;
        }

        const assignment = {
            labelId,
            labelName,
            valueId,
            value,
            objectId: undefined,
            objectType: this.isBinder ? 'binder' : 'folder'
        } as LabeledEntity;

        this.updates.removedLabels.push(assignment);
        this.assignedLabels = this.assignedLabels.filter((label) => label.valueId !== assignment.valueId);
        _.pullAllBy(this.updates.addedLabels, [assignment], 'valueId');
        this.form.markAsDirty();
        this.onLabelsChanged.emit(this.assignedLabels);
    }
}
