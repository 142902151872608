import { DoaColumnTypes, LogEntryTypes } from '../../../../../shared/models';

export const DEFAULT_DOA_LOG_COLUMN_NAMES = {
    teamMember: 'Team member',
    studyRole: 'Study Role',
    responsibilities: 'Responsibilities',
    startDate: 'Start Date',
    endDate: 'End Date',
    staffSignature: 'Staff Signature',
    piSignature: 'PI Signature'
} as const;

export const DEFAULT_DOA_LOG_COLUMN_NAMES_WITH_METADATA = {
    [DEFAULT_DOA_LOG_COLUMN_NAMES.teamMember]: {
        columnType: LogEntryTypes.teamMember,
        doaColumnType: DoaColumnTypes.TEAM_MEMBER,
        canDelete: false
    },
    [DEFAULT_DOA_LOG_COLUMN_NAMES.studyRole]: {
        columnType: LogEntryTypes.singleSelect,
        doaColumnType: DoaColumnTypes.ROLE,
        canDelete: false
    },
    [DEFAULT_DOA_LOG_COLUMN_NAMES.responsibilities]: {
        columnType: LogEntryTypes.multiSelect,
        doaColumnType: DoaColumnTypes.RESPONSIBILITIES,
        canDelete: false
    },
    [DEFAULT_DOA_LOG_COLUMN_NAMES.startDate]: {
        columnType: LogEntryTypes.date,
        doaColumnType: DoaColumnTypes.START_DATE,
        canDelete: true
    },
    [DEFAULT_DOA_LOG_COLUMN_NAMES.endDate]: {
        columnType: LogEntryTypes.date,
        doaColumnType: DoaColumnTypes.END_DATE,
        canDelete: true
    },
    [DEFAULT_DOA_LOG_COLUMN_NAMES.staffSignature]: {
        columnType: LogEntryTypes.signature,
        doaColumnType: DoaColumnTypes.STAFF_SIGNATURE,
        canDelete: true
    },
    [DEFAULT_DOA_LOG_COLUMN_NAMES.piSignature]: {
        columnType: LogEntryTypes.signature,
        doaColumnType: DoaColumnTypes.PI_SIGNATURE,
        canDelete: true
    }
} as const;
