import { AuditTrailSortField } from '@app/shared/audit-trail/audit-trail.service.types';
import { TeamPermissions } from '@app/shared/models';

export enum AuditTrailTypes {
    role = 'role',
    team = 'team',
    binder = 'binder',
    folder = 'folder',
    document = 'document',
    logEntry = 'log-entry',
    logTemplate = 'log-template',
    user = 'user',
    studyTemplates = 'study-templates'
}

export type AuditTrailModalItem = {
    permissions?: TeamPermissions | { [key: string]: any };
    type?: AuditTrailTypes;
    name?: string;
    title?: string;
    binderName?: string;
    path?: string | string[] | { path: string }[];
    overwrittenPlaceholderId?: string;
    id: string;
    teamId?: string;
};

export type AuditTrailModalPagination = {
    pageSize: number;
    sortBy: AuditTrailSortField;
    sortDir: 'ASC' | 'DESC';
};
