import {
    Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectionStrategy
} from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Team } from '@app/shared/models';
import {
    DocumentSharingView,
    SortBy,
    ExchangeDocument,
    SortObject,
    SortingDirectionValues,
    ExchangeEventSortableProperties,
    ExchangeEvent,
    SortingDirections,
    DocumentDirection
} from '../../services/document-sharing.types';
import template from './exchange-events-list.component.html';
import styles from './exchange-events-list.component.scss';

@Component({
    selector: 'exchange-events-list',
    template,
    styles: [String(styles)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExchangeEventsListComponent implements OnInit, OnChanges {
    @Input() sharingView: DocumentSharingView = 'documentsReceived';
    @Input() documentDirection: DocumentDirection;
    @Input() exchangeEvents: ExchangeEvent[] = [];
    @Input() noResultsMessage: string[];
    @Input() isLoading = false;
    @Input() sorts: SortObject[] = [{ by: ExchangeEventSortableProperties.TIMESTAMP, direction: SortingDirections.DESC }];
    @Output() nextSort = new EventEmitter<SortObject>();

    public loadingSizes = ['40%', '45%', '30%'];
    public currentTeam: Team;

    public sortMap: Record<SortBy, SortingDirectionValues> = {
        documentName: 'ASC',
        timestamp: 'DESC',
        connectionName: 'ASC'
    };

    public sortBy = ExchangeEventSortableProperties;

    constructor(private CurrentSession: CurrentSessionService) { }

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.sorts && changes.sorts.currentValue) {
            this.sortMap = changes.sorts.currentValue.reduce(
                (acc: Record<SortBy, SortingDirectionValues>, sortObject: SortObject) => {
                    acc[sortObject.by] = sortObject.direction;
                    return acc;
                },
                {}
            );
        }
    }

    isPrimarySort(by: SortBy): boolean {
        return this.sorts[0].by === by;
    }

    handleSort(by: SortBy): void {
        const direction = this.sortMap[by];

        if (this.isPrimarySort(by)) {
            const oppositeDirection = direction === 'ASC' ? 'DESC' : 'ASC';
            this.nextSort.emit({ by, direction: oppositeDirection });
        }
        else {
            this.nextSort.emit({ by, direction });
        }

    }

    getConnectedTeamName(exchangeEvent: ExchangeEvent): string {
        const { configs } = exchangeEvent.connection;

        const otherTeamConfig = configs.find((config) => config.teamId !== this.currentTeam.id);
        return otherTeamConfig.name;
    }

    getDocumentWithType(document: ExchangeDocument) {
        return { ...document, type: 'document', subType: 'content' };
    }

    hasReceivingLocation(exchangeEvent: ExchangeEvent): boolean {
        const { configs } = exchangeEvent.connection;
        const otherTeamConfig = configs.find((config) => config.teamId === this.currentTeam.id);
        return !!otherTeamConfig?.receivingLocation;
    }
}
