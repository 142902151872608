import { StateProvider } from '@uirouter/angularjs';

const resolve = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    templateManagerFeatureGuard: ['AppFeatures', 'Notifications', '$q', 'currentTeam', async (AppFeatures, Notifications, $q, currentTeam): Promise<boolean> => {

        try {
            return await AppFeatures.assertRoleTemplateManagerEnabledForCurrentTeam().toPromise();
        }
        catch (error) {
            Notifications.error(error);
            return $q.reject();
        }
    }]
};

const rolesRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.manage-roles', {
        url: '/manage-roles',
        views: {
            '': 'manageRoles'
        }
    });

    $stateProvider.state('app.team.manage-roles-templates', {
        url: '/manage-roles/templates',
        views: {
            '': 'manageRolesTemplates'
        },
        resolve
    });

    $stateProvider.state('app.team.role-permissions', {
        url: '/manage-roles/:roleId',
        views: {
            '': 'rolePermissions'
        }
    });
};

rolesRoute.$inject = ['$stateProvider'];

export default rolesRoute;
