import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnInit
} from '@angular/core';
import { take } from 'rxjs/operators';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Crumb, Team } from '@app/shared/models';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { StateService } from '@uirouter/angularjs';
import { PaginationPropertiesService } from '@app/shared/pagination-properties/pagination-properties.service';
import { TeamInboxService } from '../../team-inbox.service';
import template from './team-inbox.component.html';
import {
    Filter, InboxDocument, Pagination, TeamInboxFilterableProperties
} from '../../team-inbox.types';

@Component({
    selector: 'team-inbox',
    template
})
export class TeamInboxComponent implements OnInit {
    ITEMS_PER_PAGE = 10;
    team: Team;
    crumbs: Crumb[] = [{ name: 'Team Inbox' }];
    documents: InboxDocument[];
    isLoading = true;
    showBoundaryLinkNumbers: boolean;
    maxSize: number;
    pagination: Pagination = {
        pageNumber: 1,
        pageSize: this.ITEMS_PER_PAGE
    };

    noResultsMessage = [
        'This is where your new documents from Sponsors and CROs will appear.',
        'As soon as you receive a document, it\'ll land here to be reviewed and stored.'
    ]

    constructor(
        private $state: StateService,
        private CurrentSession: CurrentSessionService,
        private Notifications: NotificationsService,
        private TeamInbox: TeamInboxService,
        private PaginationProperties: PaginationPropertiesService
    ) {}

    ngOnInit(): void {
        this.team = this.CurrentSession.getCurrentTeam();
        if (!(this.team?.permissions?.manageTeamInbox || this.team?.permissions?.viewTeamInbox)) {
            this.$state.go('app.select-team');
        }
        this.showBoundaryLinkNumbers = this.PaginationProperties.showBoundaryLinkNumbers();
        this.maxSize = this.PaginationProperties.getMaxSize();
        this.loadTeamInbox(this.pagination);
    }

    loadTeamInbox(pagination: Pagination, filter?: Filter): void {
        this.isLoading = true;
        const getInboxParams = {
            teamId: this.team.id,
            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
            ...filter?.filterType && { filterType: filter.filterType },
            ...filter?.filterValue && { filterValue: filter.filterValue }
        };
        this.TeamInbox.getTeamInbox(getInboxParams).pipe(take(1)).subscribe(
            (data) => {
                this.documents = data.items;
                this.pagination = {
                    pageNumber: data.pageNumber,
                    recordCount: data.recordCount,
                    pageSize: this.ITEMS_PER_PAGE
                };
                this.isLoading = false;
            },
            (error: HttpErrorResponse) => {
                if (error?.error?.message) {
                    this.Notifications.error(error.error.message);
                }
                else {
                    this.Notifications.unexpectedError();
                }
            }
        );
    }

    onFilterInput(filterInputValue: string): void {
        const trimmedInput = filterInputValue.trim();
        const pagination = {
            pageNumber: 1,
            pageSize: this.ITEMS_PER_PAGE
        };

        if (!trimmedInput) {
            // Reset filter
            this.loadTeamInbox(pagination);
            return;
        }

        const filter = {
            filterType: TeamInboxFilterableProperties.DOCUMENT_NAME,
            filterValue: trimmedInput
        };
        this.loadTeamInbox(pagination, filter);
    }

    handlePageChange(pagination: { page: number, itemsPerPage: number }) {
        if (pagination.page === this.pagination.pageNumber) {
            return;
        }
        this.loadTeamInbox({
            pageNumber: pagination.page,
            pageSize: pagination.itemsPerPage
        });
    }
}
