import { NgModule } from '@angular/core';
import { NotificationsService } from './notifications/notifications.service';
import { CurrentSessionService } from './current-session.service';
import SentryConfigService from './sentry-config/sentry-config.service';
import { FeatureFlagService } from './feature-flag.service';

@NgModule({
    imports: [
    ],
    providers: [
        NotificationsService,
        CurrentSessionService,
        FeatureFlagService,
        SentryConfigService
    ],
    declarations: [
    ],
    entryComponents: [
    ],
    exports: [
    ]
})
export class CoreModule {}
