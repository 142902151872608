import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetTeamInboxParams, PatchInboxDocumentParams, TeamInboxResponse } from './team-inbox.types';

@Injectable()
export class TeamInboxService {
    readonly url = {
        inbox: (teamId: string): string => `/api/teams/${teamId}/inbox`,
        updateDocumentStatus: (teamId: string, documentId: string): string => `/api/teams/${teamId}/inbox/documents/${documentId}`
    }

    constructor(
        private http: HttpClient
    ) { }

    public getTeamInbox(params: GetTeamInboxParams): Observable<TeamInboxResponse> {
        const { teamId, ...query } = params;
        let httpParams = new HttpParams();
        Object.entries(query).forEach(
            ([key, value]) => {
                if (value) {
                    httpParams = httpParams.set(key, value as unknown as string);
                }
            }
        );
        return this.http.get<TeamInboxResponse>(this.url.inbox(teamId), { params: httpParams });
    }

    public patchInboxDocument(params: PatchInboxDocumentParams): Observable<void> {
        const { teamId, documentId, action } = params;
        return this.http.patch<void>(this.url.updateDocumentStatus(teamId, documentId), { action });
    }
}
