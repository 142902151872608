import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Archive, ArchiveStatus } from '@app/shared/models';
import { getFilenameFromHeader } from '@app/shared/downloads/get-filename-form-header.util';
import { TabsDetails } from '@app/components/archive/containers/archive-tabs/archive-tabs.types';
import { CreateArchiveParams } from './archives.service.types';

@Injectable()
export class ArchivesService {

    selectedTab$ = new BehaviorSubject<TabsDetails['heading']>('Archived');
    private readonly url = {
        archives: (teamId: string): string => `/api/teams/${teamId}/archives`,
        download: (teamId: string, archiveId): string => `/api/teams/${teamId}/archives/${archiveId}/download`,
        cancel: (teamId: string, archiveId: string): string => `/api/teams/${teamId}/archives/${archiveId}`
    }

    constructor(
        private http: HttpClient,
        @Inject('Window') private window: Window
    ) { }

    createArchive(teamId: string, params: CreateArchiveParams): Observable<Archive> {
        return this.http.post<Archive>(this.url.archives(teamId), params);
    }

    getArchives(teamId: string, status: Archive['status'], name?: string): Observable<Archive[]> {
        const sortBy = status === ArchiveStatus.complete ? 'completedAt' : 'scheduledFor';
        const sortDir = status === ArchiveStatus.complete ? 'desc' : 'asc';

        const params = {
            status: status === ArchiveStatus.scheduled
                ? [ArchiveStatus.scheduled, ArchiveStatus.inProgress]
                : ArchiveStatus.complete,
            ...((name !== undefined) && { name }),
            sortBy,
            sortDir,
            asArray: 'true'
        };

        return this.http.get<Archive[]>(this.url.archives(teamId), { params });
    }

    downloadArchive(teamId: string, archiveId: string): Observable<HttpResponse<Blob>> {
        return this.http.get<Blob>(this.url.download(teamId, archiveId), {
            observe: 'response',
            responseType: 'blob' as 'json'
        }).pipe(
            tap((response: HttpResponse<Blob>) => {
                const blob = new Blob([response.body], {
                    type: 'application/octet-stream'
                });

                const fileName = getFilenameFromHeader(response.headers.get('content-disposition'));
                const blobUrl = URL.createObjectURL(blob);

                const link = this.window.document.createElement('a');
                link.href = blobUrl;
                link.download = fileName;
                link.click();

                URL.revokeObjectURL(link.href);
            })
        );
    }

    cancelArchive(teamId: string, archiveId: string): Observable<any> {
        return this.http.delete<any>(this.url.cancel(teamId, archiveId));
    }
}
