import { Injectable } from '@angular/core';
import { Binder } from '@app/shared/models';

@Injectable()
export class BinderNameResolverService {

    private bindersMap = new Map<string, string>();

    cacheBinders(binders: Binder[]): void {
        binders.forEach((binder) => {
            this.bindersMap.set(binder.id, binder.name);
        });
    }

    getBinderName(id: string): string {
        return this.bindersMap.get(id);
    }
}
