import * as _ from 'lodash';

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';

import { Entity } from '@app/shared/models';
import { EntityPathItem } from '@app/shared/documents/calculate-entity-path.util';

import styles from './entity-path.component.scss';
import template from './entity-path.component.html';

const fontSize = 14;
const fixedWidthMultiplier = 1.28571; // fa-fw width adjustment
const lgMultiplier = 1.33333; // fa-lg multiplier

@Component({
    selector: 'entity-path',
    template,
    styles: [String(styles)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityPathComponent implements OnInit, OnChanges {
    @Input() path: Entity[] | EntityPathItem[];

    width = 0;
    maxWidth = 0;
    pathIsSplit = false;
    beginningOfPath: Entity[] | EntityPathItem[];
    endOfPath: Entity[] | EntityPathItem[];

    characterWidth = (8 / 14) * fontSize;
    iconWidth = fontSize * fixedWidthMultiplier;
    ellipsisWidth = fontSize * fixedWidthMultiplier * lgMultiplier;
    slashWidth = fontSize;

    timer: ReturnType<typeof setTimeout>;
    shortenedName = '';

    constructor(
        private element: ElementRef,
        private changeDetectordRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.resizeComponent();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.path) {
            return;
        }
        this._splitUpPath();
    }

    @HostListener('window:resize')
    resizeComponent(): void {
        this.timer = setTimeout(() => {
            clearTimeout(this.timer);
            this.width = this._calculateWidthFromElement(this.element);
            this.maxWidth = Math.max(20, this.width - 20);
            this._splitUpPath();
            this.changeDetectordRef.markForCheck();
        }, 0);
    }


    isTeam(): boolean {
        const { totalPathWidth } = this._calculatePathWidths(this.path);
        if (this.path.length === 1 && this.path[0].type === 'team' && totalPathWidth > this.maxWidth) {
            this.shortenedName = this.path[0].name.slice(0, this.maxWidth).concat('...');
            return true;
        }
        return false;
    }

    private _calculateWidthFromElement(element: ElementRef): number {
        let width = element.nativeElement.clientWidth || 0;
        let parent = element.nativeElement.parentElement;
        while (width === 0 && parent) {
            width = (parent[0] || parent).clientWidth;
            parent = parent.parentElement;
        }
        return width;
    }

    private _splitUpPath(): void {
        this.pathIsSplit = false;
        const { elementIndexToWidth, totalPathWidth } = this._calculatePathWidths(this.path);
        if (totalPathWidth <= this.maxWidth || this.path.length <= 1) {
            // Shortcut and just display full path
            return;
        }

        // Include only a subset of path elements
        this.pathIsSplit = true;
        const {
            moreWorkToDo,
            expandStartPath,
            expandEndPath,
            getStartPath,
            getEndPath
        } = this._getPathSplitHelpers(elementIndexToWidth, this.path, this.maxWidth);

        let element;
        // Add team and binder if available
        element = _.get(this.path, '[0]', null);
        if (element && (element.type === 'binder' || element.type === 'team')) {
            expandStartPath();
        }
        element = _.get(this.path, '[1]', null);
        if (element && element.type === 'binder') {
            expandStartPath();
        }
        // Add containing element
        expandEndPath();
        // Add root element
        expandStartPath();

        // Continue expanding start and end path until we've filled up our space
        while (moreWorkToDo()) {
            expandEndPath();
            expandStartPath();
        }

        // Set paths
        this.beginningOfPath = getStartPath();
        this.endOfPath = getEndPath();
    }

    private _getPathSplitHelpers(
        elementIndexToWidth: number[],
        path: Entity[] | EntityPathItem[],
        maxWidth: number
    ): {
        expandStartPath: () => boolean;
        expandEndPath: () => boolean;
        getStartPath: () => Entity[] | EntityPathItem[];
        getEndPath: () => Entity[] | EntityPathItem[];
        moreWorkToDo: () => boolean;
    } {
        // Keep track of how many elements we have left and how much space we're consuming
        let remainingElements = path.length;
        let displayWidth = this.ellipsisWidth;

        // Initialize start and end slice ranges
        const startPathSlice = [0, 0];
        // End path always gets the last element
        const endPathSlice = [path.length - 1, path.length];
        displayWidth += elementIndexToWidth[path.length - 1];

        let startPathCanExpand = true;
        const expandStartPath = (): boolean => {
            if (!startPathCanExpand || remainingElements === 0) {
                return false;
            }
            const nextElementIndex = startPathSlice[1];
            const elementWidth = elementIndexToWidth[nextElementIndex];
            if (displayWidth + elementWidth > maxWidth) {
                startPathCanExpand = false;
                return false;
            }
            startPathSlice[1] = nextElementIndex + 1;
            remainingElements -= 1;
            displayWidth += elementWidth;
            return true;
        };

        let endPathCanExpand = true;
        const expandEndPath = (): boolean => {
            if (!endPathCanExpand || remainingElements === 0) {
                return false;
            }
            const nextElementIndex = endPathSlice[0] - 1;
            const elementWidth = elementIndexToWidth[nextElementIndex];
            if (displayWidth + elementWidth > maxWidth) {
                endPathCanExpand = false;
                return false;
            }
            endPathSlice[0] = nextElementIndex;
            remainingElements -= 1;
            displayWidth += elementWidth;
            return true;
        };
        const getStartPath = (): Entity[] | EntityPathItem[] => path.slice(...startPathSlice);
        const getEndPath = (): Entity[] | EntityPathItem[] => path.slice(...endPathSlice);
        const moreWorkToDo = (): boolean => (endPathCanExpand || startPathCanExpand) && remainingElements > 0;

        return {
            expandStartPath,
            expandEndPath,
            getStartPath,
            getEndPath,
            moreWorkToDo
        };
    }

    private _calculatePathWidths(path: Entity[] | EntityPathItem[]): {
        elementIndexToWidth: number[];
        totalPathWidth: number;
    } {
        // Calculate size of each path element
        let totalPathWidth = 0;
        const elementIndexToWidth: number[] = [];
        path.forEach((pathElement: Entity | EntityPathItem) => {
            if (!pathElement) {
                // Empty path elements display as a loading icon - so return width of spinner
                elementIndexToWidth.push(this.iconWidth);
            }
            else {
                const charCount = pathElement.name.length;
                const pathWidth = charCount * this.characterWidth + this.iconWidth + this.slashWidth;
                totalPathWidth += pathWidth;
                elementIndexToWidth.push(pathWidth);
            }

        });
        return {
            elementIndexToWidth,
            totalPathWidth
        };
    }
}
