import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MESSAGES, REGEX } from '@app/core/constants';
import { Label } from '@app/shared/labels/labels.service.types';
import { Binder, LabeledEntity } from '@app/shared/models';
import { Updates } from './binder-form.component.types';

import template from './binder-form.component.html';
import styles from './binder-form.component.scss';

@Component({
    selector: 'binder-form',
    template,
    styles: [String(styles)]
})
export class BinderFormComponent implements OnInit {
    @Input() binder: Partial<Binder> = {};
    @Input() availableLabels: Label[];
    @Input() assignedLabels: LabeledEntity[] = [];
    @Output() save = new EventEmitter<Updates>();

    readonly namePattern = REGEX.names;
    readonly nameMessage = MESSAGES.validNameMessage;
    readonly binderNameMinLength = 1;
    readonly binderNameMaxLength = 250;
    initialBinderName: string;
    isBinderNameChanged = true;

    isProcessing = false;
    isCreate: boolean;
    canEditName: boolean;
    originalDirectLabelsHash: { [key: string]: boolean };
    binderForm: FormGroup;
    updates: Updates = {
        addedLabels: [],
        removedLabels: []
    };

    constructor(
        private fb: FormBuilder,
        private modalRef: BsModalRef
    ) { }

    ngOnInit(): void {
        this.isCreate = !this.binder.id;
        this.updates.binderName = this.binder.name;
        this.canEditName = this.isCreate || (this.binder.permissions && this.binder.permissions.renameBinder);
        this.getOriginalDirectLabels();
        this.initForm();
    }

    private initForm(): void {
        this.binderForm = this.fb.group({
            name: [
                { value: this.binder.name || '', disabled: !this.canEditName },
                [
                    Validators.required,
                    Validators.minLength(this.binderNameMinLength),
                    Validators.maxLength(this.binderNameMaxLength),
                    Validators.pattern(this.namePattern)
                ]
            ]
        });
        this.initialBinderName = this.binderForm.controls.name.value;
    }

    handleKeyPress(event: KeyboardEvent) {
        if (event.key === 'Enter' && this.canSubmit()) {
            this.submit();
        }
    }

    private getOriginalDirectLabels(): void {
        // create hash of original label/value assignments to compare
        // when re-adding label/value assignments
        this.originalDirectLabelsHash = this.assignedLabels
            && this.assignedLabels.reduce((accum, label) => {
                if (label.objectId === this.binder.id) {
                    accum[label.labelId + label.valueId] = true;
                }
                return accum;
            }, {});
    }

    cancel(): void {
        if (!this.isProcessing) {
            this.modalRef.hide();
        }
    }

    handleAssignLabels(params = []): void {
        const assignments = [];
        params.forEach((param) => {
            const {
                labelId,
                valueId,
                labelName,
                value
            } = param;
            const assignment = {
                labelId,
                labelName,
                valueId,
                value,
                objectId: this.binder.id,
                objectType: 'binder'
            } as LabeledEntity;

            if (!this.originalDirectLabelsHash[labelId + valueId]) {
                this.updates.addedLabels.push(assignment);
            }
            assignments.push(assignment);
        });

        _.pullAllBy(this.updates.removedLabels, assignments, 'valueId');
        this.assignedLabels = this.assignedLabels.concat(assignments);
        this.binderForm.markAsDirty();
    }

    handleRemoveLabel(params): void {
        const {
            labelId,
            valueId,
            labelName,
            value
        } = params;
        const isAssigned = this.assignedLabels
            .find((l) => l.labelId === labelId
                && l.valueId === valueId
                && l.objectId === this.binder.id);
        if (!isAssigned) {
            return;
        }

        const assignment = {
            labelId,
            labelName,
            valueId,
            value,
            objectId: this.binder.id,
            objectType: 'binder'
        } as LabeledEntity;

        if (this.originalDirectLabelsHash[labelId + valueId]) {
            this.updates.removedLabels.push(assignment);
        }
        this.assignedLabels = this.assignedLabels.filter((label) => label.valueId !== assignment.valueId);
        _.pullAllBy(this.updates.addedLabels, [assignment], 'valueId');
        this.binderForm.markAsDirty();
    }

    canSubmit() {
        let canSubmit: boolean;
        const isBinderNameChanged = this.initialBinderName.trim() !== this.binderForm.controls.name.value.trim();

        canSubmit = !(this.binderForm.invalid || this.isProcessing || this.binderForm.pristine || !isBinderNameChanged);

        canSubmit = canSubmit || !(this.updates.addedLabels.length === 0 && this.updates.removedLabels.length === 0);

        return canSubmit;
    }

    submit(): void {
        if (!this.canSubmit()) {
            return;
        }

        this.isProcessing = true;
        this.updates = {
            ...this.updates,
            ...this.binderForm.value,
            onSave: () => {
                this.modalRef.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        };
        this.save.emit(this.updates);
    }
}
