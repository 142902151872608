import {
    Crumb, Tag, Team, TeamPermissions
} from '@app/shared/models';
import { StateService } from '@uirouter/core';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { TeamService } from '@app/shared/teams/team.service';
import { SORT } from '@app/core/constants';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CreateTagComponent } from '../../components/create-tag/create-tag.component';
import { TagCreateEvent } from '../../components/create-tag/create-tag.component.types';
import { TagsService } from '../../tags.service';
import { UpdateTagComponent } from '../../components/update-tag/update-tag.component';
import { TagUpdateEvent } from '../../components/update-tag/update-tag.component.types';
import { DeleteTagComponent } from '../../components/delete-tag/delete-tag.component';
import { TagDeleteEvent } from '../../components/delete-tag/delete-tag.component.types';
import template from './manage-tags.component.html';

@Component({
    selector: 'manage-tags',
    template
})
export class ManageTagsComponent implements OnInit {
    SORT = SORT;
    tags: Tag[];
    crumbs: Crumb[];
    loadingTags = false;
    selectedTag: Tag;
    currentTeam: Team;
    filter: string;
    filterText = '';
    openDropdownId: string | null = null;
    modalRef: BsModalRef;
    sortName: string;
    sortIsReversed: boolean;

    constructor(
        private Tags: TagsService,
        private Teams: TeamService,
        private stateService: StateService,
        private CurrentSession: CurrentSessionService,
        private Modals: ModalsService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.crumbs = [{ name: 'Manage Tags' }];
        this.loadingTags = false;
    }

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();

        if (!this.currentTeam || !this._canManageTeamTags(this.currentTeam.permissions)) {
            this.stateService.go('app.select-team');
        }

        this.loadingTags = true;
        this.Teams.getTags().subscribe((tags) => {
            this.tags = tags;
            this.updateSort('name', false);
            this.loadingTags = false;
        });
    }

    private _canManageTeamTags(perm: TeamPermissions): boolean {
        return perm.manageTeam
            || perm.createTags
            || perm.updateTags
            || perm.deleteTags;
    }

    canActOnSelection() {
        return this.selectedTag
            && (this.currentTeam.permissions.deleteTags
                || this.currentTeam.permissions.updateTags);
    }

    openCreateTag() {
        if (this.currentTeam.permissions.createTags) {
            const createTagModal = this.Modals.show(CreateTagComponent, {
                class: 'modal-md',
                initialState: {}
            });
            createTagModal.content.onCreate.subscribe((event: TagCreateEvent) => {
                const { tag } = event;
                return this.Tags.createTag(this.currentTeam.id, tag).subscribe(
                    (createdTag) => {
                        event.onSuccess();
                        this.addTag(createdTag);
                        this.updateSort(this.sortName, this.sortIsReversed);
                    },
                    () => {
                        event.onError();
                    }
                );
            });

        }
    }

    addTag(tag: Tag) {
        tag.success = true;
        this.tags = [...this.tags, tag];
        this.setSuccessTimeout(tag);
    }

    setSuccessTimeout(tag: Tag) {
        setTimeout(() => {
            tag.success = false;
            this.tags = [...this.tags];
        }, 5000);
    }

    openUpdateTag(mouseEvent: MouseEvent): void {
        mouseEvent.stopPropagation();
        this.clearDropdown();
        if (this.currentTeam.permissions.updateTags && this.selectedTag) {
            const updateTagModal = this.Modals.show(UpdateTagComponent, {
                class: 'modal-md',
                initialState: {
                    tag: _.cloneDeep(this.selectedTag)
                }
            });
            updateTagModal.content.onUpdate.subscribe((event: TagUpdateEvent) => {
                const { tag } = event;
                return this.Tags.updateTag(this.currentTeam.id, tag).subscribe(
                    (updatedTag) => {
                        event.onSuccess();
                        this.replaceTagData(updatedTag);
                    },
                    () => {
                        event.onError();
                    }
                );
            });
        }
    }

    replaceTagData(tag: Tag) {
        const { id } = tag;
        const oldTag = _.find(this.tags, { id });
        _.assign(oldTag, tag);
    }

    openDeleteTag(mouseEvent: MouseEvent): void {
        mouseEvent.stopPropagation();
        this.clearDropdown();
        if ((this.currentTeam.permissions.deleteTags && this.selectedTag)) {
            const deleteTagModal = this.Modals.show(DeleteTagComponent, {
                class: 'modal-md',
                initialState: {
                    tag: _.cloneDeep(this.selectedTag)
                }
            });
            deleteTagModal.content.destroyTag.subscribe((event: TagDeleteEvent) => {
                return this.Tags.deleteTag(this.currentTeam.id, this.selectedTag).subscribe(
                    () => {
                        event.onSuccess();
                        this.removeSelectedTag();
                        this.updateSort(this.sortName, this.sortIsReversed);
                    },
                    () => {
                        event.onError();
                    }
                );
            });
        }
    }

    removeSelectedTag() {
        this.tags.splice(this.tags.indexOf(this.selectedTag), 1);
        this.selectedTag = undefined;
    }

    select(tag: Tag) {
        if (tag.selected) {
            tag.selected = false;
            this.selectedTag = undefined;
        }
        else {
            this.tags.forEach((t) => {
                t.selected = false;
            });
            tag.selected = true;
            this.selectedTag = tag;
        }
    }

    toggleActions($event: MouseEvent, tag: Tag) {
        $event.preventDefault();
        if (this.openDropdownId !== tag.id) {
            this.openDropdownId = tag.id;
        }
        else {
            this.clearDropdown();
        }

        if (this.selectedTag !== tag) {
            this.select(tag);
        }
        this.changeDetectorRef.detectChanges();
    }

    clearDropdown() {
        this.openDropdownId = null;
    }

    updateSort(sortName: string, isReversed?: boolean): void {
        this.SORT.set(sortName, isReversed);
        this.tags = this.tags.sort((a, b) => {
            const aValue = a[sortName]?.trim() || '';
            const bValue = b[sortName]?.trim() || '';
            return this.SORT.naturalSort({ value: aValue }, { value: bValue });
        });
        if (this.SORT.isReversed) {
            this.tags.reverse();
        }
    }


    updateFilter(text: string): void {
        this.filter = text;
    }

    trackByTagId(index: number, tag: Tag) {
        return tag.id;
    }
}
