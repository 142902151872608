import { Component, Inject, OnInit } from '@angular/core';
import { SessionsService } from '@app/shared/sessions/sessions.service';
import { StateService } from '@uirouter/core';
import style from './help-desk.component.scss';
import template from './help-desk.component.html';

@Component({
    selector: 'help-desk',
    template,
    styles: [String(style)]
})
export class HelpDeskComponent implements OnInit {
    constructor(
        private $state: StateService,
        private Sessions: SessionsService,
        @Inject('Window') private window: Window
    ) { }

    ngOnInit(): void {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { return_to } = this.$state.params;
        this.Sessions.getHelpDeskUrl(return_to)
            .subscribe(({ url }) => {
                this.window.location.href = url;
            });
    }
}
