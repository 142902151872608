import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Document } from '@app/shared/models';
import { DocumentDestroyEvent } from './destroy-document.component.types';
import template from './destroy-document.component.html';

@Component({
    selector: 'destroy-document',
    template
})
export class DestroyDocumentComponent implements OnInit {
    isProcessing = false;
    reason = '';
    @Input() documents: Document[];
    @Input() hasOriginalDocuments = false;
    @Output() destroy = new EventEmitter<DocumentDestroyEvent>();
    form: FormGroup;

    crumbEntity;
    constructor(
        private fb: FormBuilder,
        public modal: BsModalRef
    ) {}

    ngOnInit(): void {
        this.initForm();
    }

    private setHeaderEntity(): void {
        const [crumbEntity] = this.documents;
        this.crumbEntity = this.documents.length > 1 ? { ...crumbEntity, type: 'folder' } : crumbEntity;
    }

    cancel(): void {
        this.modal.hide();
    }

    destroyDocuments(): void {
        this.isProcessing = true;

        const reason = this.form.controls.reason && this.form.controls.reason.value;
        this.destroy.emit({
            data: { documents: this.documents, reason }
        });
    }

    private initForm(): void {
        this.form = this.fb.group({
            reason: ['', [Validators.required, notBlank]]
        });

        this.setHeaderEntity();
    }
}
