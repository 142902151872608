import {
    Component, Input, OnChanges, SimpleChanges, Output, EventEmitter
} from '@angular/core';
import { RoleTemplatesResult, RoleTemplateAuditResult } from './roles-templates-preview.types';
import style from './roles-templates-preview.component.scss';
import template from './roles-templates-preview.component.html';
@Component({
    selector: 'roles-templates-preview',
    template,
    styles: [String(style)]
})
export class RolesTemplatesPreviewComponent implements OnChanges {
  @Input() results: RoleTemplatesResult;
  @Input() auditResults: RoleTemplateAuditResult[];
  @Output() onCreate = new EventEmitter<object>();

  resultType: 'studyNumber' | 'rolesTemplates';
  createCompleted = false;

  ngOnChanges(changes: SimpleChanges): void {
      if (changes.result) {
          this.results = changes.result.currentValue;
      }
      if (changes.auditResults) {
          this.auditResults = changes.auditResults.currentValue;
      }

      if (this.results && this.results.filters && this.results.filters.studyNumbers.length) {
          this.resultType = 'studyNumber';
      }
      else {
          this.resultType = 'rolesTemplates';
      }
  }

  getPracticeNames(): string {
      const practiceNames = this.results && this.results.filters && this.results.filters.practiceNames;
      return (practiceNames && practiceNames.length) ? practiceNames.join(', ') : 'No practice name specified';
  }

  create(): void {
      const event = this.results;
      this.createCompleted = true;
      this.auditResults = null;

      this.onCreate.emit(event);
  }

  auditResultsExpired(): boolean {
      const oneDay = 24 * 60 * 60 * 1000;
      const dateOfCreation = new Date(this.results.createdAt);
      const oneDayAgo = new Date().getTime() - oneDay;
      return oneDayAgo > dateOfCreation.getTime();
  }

  createTooltip(): string {
      if (this.results.action === 'preview' && (this.results.status === 'inProgress' || this.results.status === 'pending')) {
          return 'Cannot create until preview is complete';
      }
      if (this.results.action === 'preview' && this.results.status === 'failed') {
          return 'Cannot trigger creation of failed preview';
      }
      if (this.auditResultsExpired()) {
          return 'Preview results are older than 24 hours';
      }
  }

  disableCreate(): boolean {
      return !this.results.action
      || this.results.action === 'create'
      || (this.results.action === 'preview' && (this.results.status === 'inProgress' || this.results.status === 'pending'))
      || (this.results.action === 'preview' && this.results.status === 'failed')
      || !this.auditResults
      || !this.auditResults.length
      || this.auditResultsExpired();
  }

  hideCreate(): boolean {
      return this.results.action === 'create';
  }

  getFormattedText(text: string): string {
      let newText;
      if (text === 'completed') {
          newText = text.slice(0, -1);
      }
      else if (text === 'inProgress') {
          return 'In Progress';
      }
      else {
          newText = text || '';
      }
      return newText.charAt(0).toUpperCase() + newText.slice(1);
  }
}
