import * as _ from 'lodash';
import {
    Component, Input, OnInit, OnDestroy
} from '@angular/core';
import {
    AUDIT_TRAIL_SUBJECTS
} from '@florencehealthcare/florence-constants';
import * as moment from 'moment';
import { Subject, Observable } from 'rxjs';
import {
    debounceTime, filter, takeUntil, tap
} from 'rxjs/operators';
import { BindersService } from '@app/shared/binders/binders.service';
import { TeamService } from '@app/shared/teams/team.service';
import { DownloadsService } from '@app/shared/downloads/downloads.service';
import { AuditTrailFilterField, GetAuditsParams } from '@app/shared/audit-trail/audit-trail.service.types';
import {
    CursorPaginationResponse, AuditTrailSubject, AuditTrail, AuditTrailEventType,
    Signature,
    LogEntryTypes,
    Team
} from '@app/shared/models';
import { Cursor, CursorPageChangedEvent } from '@app/widgets/cursor-pagination/cursor-pagination.component.types';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { DynamicFilterTypeItem } from '@app/widgets/dynamic-filter/dynamic-filter.component';
import { StateService } from '@uirouter/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import { AuditTrailService } from '@app/shared/audit-trail/audit-trail.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { AuditTrailTypes, AuditTrailModalItem, AuditTrailModalPagination } from './audit-trail-modal.component.types';
import styles from './audit-trail-modal.component.scss';
import template from './audit-trail-modal.component.html';
@Component({
    selector: 'audit-trail-modal',
    template,
    styles: [String(styles)]
})
export class AuditTrailModalComponent implements OnInit, OnDestroy {
    @Input() data: CursorPaginationResponse<AuditTrail>;
    @Input() item: AuditTrailModalItem;
    @Input() subject: AuditTrailSubject;
    @Input() pagination: AuditTrailModalPagination;
    @Input() onPageChange: (params: GetAuditsParams) => Observable<CursorPaginationResponse<AuditTrail>>;

    textFilterSelected: DynamicFilterTypeItem;
    itemFilters = [];
    textFilter = '';
    itemFilterId: string;
    showPaginationElement: boolean;
    trails: AuditTrail[];
    next: string;
    trailPath: string;
    selectedItemFilter: any;
    overwrittenObjectId: string;
    limitToOverwritten: boolean;
    isUserProfileAuditTrail: boolean;
    canDownloadAuditTrail: boolean;
    logEntryUpdatesShown = {};
    logEntryUpdatesLoading = {};
    logEntryUpdates = {};
    currentTeam: Team;
    areLogEntryDetailsEnabled = false;

    private destroy$ = new Subject<void>();
    private paginationReset$ = new Subject<void>();
    teamTimezone: string;

    readonly textFilterTypes: DynamicFilterTypeItem[] = [
        { label: 'Name', filterField: AuditTrailFilterField.FRIENDLY_NAME },
        { label: 'Who', filterField: AuditTrailFilterField.EMAIL },
        { label: 'Action', filterField: AuditTrailFilterField.EVENT_TYPE }
    ];

    constructor(
        private readonly $state: StateService,
        private teamsService: TeamService,
        private currentSessionService: CurrentSessionService,
        private auditTrailService: AuditTrailService,
        private bindersService: BindersService,
        private downloadsService: DownloadsService,
        private featureFlagsService: FeatureFlagService,
        private apiErrorsService: ApiErrorsService,
        private notificationsService: NotificationsService,
        private modal: BsModalRef
    ) {
        // For filtering via text search or to a particular id
        [this.textFilterSelected] = this.textFilterTypes;
    }

    ngOnInit(): void {
        [this.textFilterSelected] = this.textFilterTypes;
        this.pagination = _.cloneDeep(this.pagination);
        this.showPaginationElement = this.data.hasNext;
        this.initData();
        this.currentTeam = this.currentSessionService.getCurrentTeam();
        this.teamTimezone = moment()
            .tz(this.currentTeam.settings.timezone)
            .format('Z')
            .split(':')
            .join('');

        this.featureFlagsService.getFlag(FEATURE_FLAGS.LOG_ENTRY_AUDIT_UPDATES, false).pipe(
            filter((flag) => flag !== undefined),
            debounceTime(300),
            tap((isFlagEnabled) => {
                this.areLogEntryDetailsEnabled = isFlagEnabled;
            })
        ).subscribe();

        this.paginationReset$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            this.pageChanged({ cursor: undefined, onSuccess: () => {} });
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getObjectName(trail: AuditTrail): string {
        let objectName = trail.uniqueObjectId.longFriendlyName || trail.uniqueObjectId.friendlyName;
        if (trail.eventType.startsWith('Task ')) {
            objectName = trail.uniqueObjectId.friendlyName;
        }
        if (trail.eventType === AuditTrailEventType.USER_PROFILE_INFORMATION_CHANGED) {
            objectName = `${trail.uniqueObjectId.friendlyName} User Profile`;
        }
        return objectName;
    }

    private initData(): void {
        this.isUserProfileAuditTrail = this.subject === AuditTrailSubject.USER_PROFILE;
        this.trails = this.data.items;
        this.next = this.data.next;

        const privHash = {
            role: 'downloadTeamAuditTrail',
            team: 'downloadTeamAuditTrail',
            binder: 'downloadBinderAuditTrail',
            folder: 'downloadFolderAuditTrail',
            document: 'downloadDocumentAuditTrail',
            [AuditTrailTypes.logEntry]: 'downloadDocumentAuditTrail',
            [AuditTrailTypes.logTemplate]: 'downloadTeamAuditTrail',
            [AuditTrailTypes.studyTemplates]: 'downloadTeamAuditTrail'
        };

        this.canDownloadAuditTrail = this.trails.length
            && this.item.permissions
            && this.item.permissions[privHash[this.item.type]];

        const tempTrailsArray = [];
        this.trailPath = this.item.type === AuditTrailTypes.binder ? this.item.name : this.item.binderName || '';
        let strPath = '';
        if (Array.isArray(this.item.path) && this.item.path.length) {
            const [last] = this.item.path.slice(-1);
            strPath = (last as { path: string }).path || (last as string);
        }
        else if (this.item.path) {
            strPath = this.item.path as string;
        }
        this.trailPath += this.trailPath.length && strPath.length ? `/${strPath}` : strPath;
        if (this.item.type === AuditTrailTypes.document || this.item.type === AuditTrailTypes.logEntry) {
            const name = this.item.name || this.item.title;
            this.trailPath += this.trailPath ? `/${name}` : name;

            if (this.isMultiItem) {
                this.itemFilters = [
                    {
                        value: 'combined',
                        label: 'Combined Audit Trail'
                    },
                    {
                        value: 'document',
                        label: 'Document\'s Audit Trail'
                    },
                    {
                        value: 'overwritten',
                        label: 'Placeholder\'s Audit Trail'
                    }
                ];

                if (!this.selectedItemFilter) {
                    this.overwrittenObjectId = this.item.overwrittenPlaceholderId;
                    [this.selectedItemFilter] = this.itemFilters;
                    this.limitToOverwritten = false;
                }
            }
        }

        this.trails.forEach((trail) => {
            this.processTrail(trail, tempTrailsArray);
        });
        this.trails = tempTrailsArray;
    }

    getClonePath(trail: AuditTrail): string {
        if (!trail.finalState.clones || trail.finalState.clones.length === 1) {
            const pathAfter = trail.finalState.clones && trail.finalState.clones.length > 0
                ? trail.finalState.clones[0].pathAfter : trail.finalState.pathAfter;
            return `${trail.originalState.pathBefore} > ${pathAfter}`;
        }
        return '';
    }

    private processTrail(trail: AuditTrail, tempTrailsArray: AuditTrail[]): void {
        if (trail.label) {
            tempTrailsArray.push(trail);
            return;
        }

        trail.isDeclinedSignature = [
            AuditTrailEventType.DOCUMENT_DECLINED,
            AuditTrailEventType.LOG_DECLINED,
            AuditTrailEventType.LOG_ENTRY_DECLINED,
            AuditTrailEventType.SHORTCUT_DECLINED
        ].includes(trail.eventType);

        trail.isSignature = trail.isDeclinedSignature || [
            AuditTrailEventType.DOCUMENT_SIGNED,
            AuditTrailEventType.LOG_SIGNED,
            AuditTrailEventType.LOG_ENTRY_SIGNED,
            AuditTrailEventType.SHORTCUT_SIGNED
        ].includes(trail.eventType);

        if (trail.originalState && trail.finalState) {
            this.setTrailDiffs(trail);
        }

        const finalState = trail.finalState || {};
        const originalState = trail.originalState || {};
        let path = finalState.path || originalState.path || this.item.path || '';
        let binderName = finalState.binderName || originalState.binderName || this.item.binderName || '';
        let title = trail.uniqueObjectId.version !== null ? trail.uniqueObjectId.friendlyName : '';

        if ([AuditTrailTypes.logEntry, AuditTrailTypes.document]
            .includes(finalState.type) || [AuditTrailTypes.logEntry, AuditTrailTypes.document].includes(originalState.type)) {
            const trailParams = {
                data: _.isEmpty(finalState) ? originalState : finalState,
                friendlyName: trail.uniqueObjectId.friendlyName
            };
            if (trailParams.data && trailParams.data.document) {
                path = path || trailParams.data.document.path || '';
                binderName = binderName || trailParams.data.document.binderName || '';
                const name = trailParams.data.document.name || trailParams.data.document.title || '';
                title = `${name} ${trailParams.friendlyName}`;
            }
        }

        let longName = this.buildFullPath(binderName, path, title);
        const isDocumentMoveOrDupe = [
            AuditTrailEventType.DOCUMENT_CLONED,
            AuditTrailEventType.DOCUMENT_BULK_CLONED,
            AuditTrailEventType.DOCUMENT_MOVED,
            AuditTrailEventType.PLACEHOLDER_CLONED,
            AuditTrailEventType.PLACEHOLDER_BULK_CLONED,
            AuditTrailEventType.PLACEHOLDER_MOVED,
            AuditTrailEventType.SHORTCUT_MOVED,
            AuditTrailEventType.LOG_MOVED
        ].includes(trail.eventType);

        if (isDocumentMoveOrDupe) {
            if (trail.uniqueObjectId.objectId === trail.originalState.id.documentId) {
                longName = this.buildFullPath(originalState.binderName, originalState.path, title);
            }
            this.setBeforeAndAfterPath(trail);
            this.obfuscateBulkEvent(trail);
            this.setCloneAfterPath(trail.finalState.clones);
        }

        if ([AuditTrailEventType.FOLDER_DUPLICATED, AuditTrailEventType.FOLDER_MOVED].includes(trail.eventType)) {
            this.setBeforeAndAfterPath(trail);
        }

        trail.uniqueObjectId.longFriendlyName = longName;
        this.setWho(trail);

        switch (trail.eventType) {
            case AuditTrailEventType.TEAMMATE_ADDED:
                this.setInvited(trail);
                break;
            case AuditTrailEventType.TEAMMATE_REMOVED:
                this.setRemoved(trail);
                break;
            case AuditTrailEventType.USER_PERMISSIONS_UPDATED:
            case AuditTrailEventType.ROLE_PERMISSIONS_UPDATED:
                if (trail.permissionDiff) {
                    this.teamsService.setHasCheckedOrInheritedChildren(trail.permissionDiff.permissions.tree);
                }
                break;
            case AuditTrailEventType.DOCUMENT_CATEGORY_UPDATED:
                this.setBeforeAndAfterCategory(trail);
                break;
            case AuditTrailEventType.LABEL_UPDATED:
                this.setUpdatedLabelValues(trail);
                break;
            case AuditTrailEventType.BINDER_LABELS_UPDATED:
            case AuditTrailEventType.FOLDER_LABELS_UPDATED:
            case AuditTrailEventType.BINDER_LABELS_UPDATED_VIA_STUDY_PROFILE:
            case AuditTrailEventType.FOLDER_LABELS_UPDATED_VIA_STUDY_PROFILE:
                this.setEntityLabelValues(trail);
                break;
            case AuditTrailEventType.STUDY_BINDERS_AND_FOLDERS_UPDATED:
            case AuditTrailEventType.SITE_BINDERS_AND_FOLDERS_UPDATED:
            case AuditTrailEventType.SITE_BINDERS_AND_FOLDERS_UPDATED_VIA_WORKFLOW_API:
                this.setLinkedStudyEntities(trail);
                break;
            case AuditTrailEventType.STUDY_PAYWALLS_UPDATED:
                this.setLinkedMonitorGroups(trail);
                break;
            case AuditTrailEventType.DOCUMENT_FAILED_TO_SEND_TO_INTEGRATED_SYSTEM:
                this.setDocumentFailedToSendInformation(trail);
                break;
            case AuditTrailEventType.QC_REVIEW_REJECTED:
                this.qcReviewRejected(trail);
                break;
            case AuditTrailEventType.QC_REVIEWER_CHANGED:
                this.qcReviewerChanged(trail);
                break;
            default: // do nothing
        }

        tempTrailsArray.push(trail);
    }

    private setTrailDiffs(trail: AuditTrail): void {
        if (trail.originalState.items && trail.finalState.items) {
            trail.itemDiff = _.difference(trail.finalState.items, trail.originalState.items);
            trail.itemDiffFrom = _.difference(trail.originalState.items, trail.finalState.items);
        }

        if (!trail.reason && trail.isSignature) {
            const signatures: Signature[] = trail.finalState.signatures.filter((sig: Signature) => sig.user.id === trail.createdBy && sig.status === 'Signed');
            const signature: Signature | undefined = _.maxBy(signatures, (sig: Signature) => sig.createdAt);
            trail.reason = signature?.reason;
        }

        if (trail.finalState.users && trail.finalState.type === AuditTrailTypes.role) {
            trail.itemDiff = _.differenceBy(trail.finalState.users.current, trail.originalState.users.current, 'id');
            trail.itemDiffFrom = _.differenceBy(trail.originalState.users.current, trail.finalState.users.current, 'id');
        }
    }

    private setInvited(trail: AuditTrail): void {
        trail.invited = {
            email: trail.finalState.invitedUser.email
        };
        if (trail.finalState.invitedUser.email !== trail.finalState.invitedUser.name) {
            trail.invited.name = trail.finalState.invitedUser.name;
        }
    }

    private setRemoved(trail: AuditTrail): void {
        trail.removed = {
            email: trail.finalState.removedUser.email
        };
        if (trail.finalState.removedUser.email !== trail.finalState.removedUser.name) {
            trail.removed.name = trail.finalState.removedUser.name;
        }
    }

    private setWho(trail: AuditTrail): void {
        trail.who = {
            name: trail.user.name,
            email: trail.email
        };
    }

    private setBeforeAndAfterCategory(trail: AuditTrail): void {
        trail.originalState.categoryName = _.get(trail, 'originalState.documentCategory.categoryName') || 'No Document Type Assigned';
        trail.originalState.statusName = _.get(trail, 'originalState.documentCategory.statusName') || 'No Document Status Assigned';
        trail.finalState.categoryName = _.get(trail, 'finalState.documentCategory.categoryName') || 'No Document Type Assigned';
        trail.finalState.statusName = _.get(trail, 'finalState.documentCategory.statusName') || 'No Document Status Assigned';
    }

    private setUpdatedLabelValues(trail: AuditTrail): void {
        const originalValHash = _.keyBy(trail.originalState.values, 'id');
        const finalValHash = _.keyBy(trail.finalState.values, 'id');
        const allIds = _.uniq(Object.keys(originalValHash).concat(Object.keys(finalValHash)));
        trail.addedValues = [];
        trail.removedValues = [];
        trail.updatedValues = [];

        allIds.forEach((id) => {
            if (!originalValHash[id]) {
                trail.addedValues.push(finalValHash[id]);
            }
            else if (!finalValHash[id]) {
                trail.removedValues.push(originalValHash[id]);
            }
            else if (originalValHash[id].value !== finalValHash[id].value) {
                originalValHash[id].finalValue = finalValHash[id].value;
                trail.updatedValues.push(originalValHash[id]);
            }
        });
    }

    private setEntityLabelValues(trail: AuditTrail): void {
        trail.removedValues = _.differenceBy(trail.originalState.labeledEntities, trail.finalState.labeledEntities, 'valueId');
        trail.addedValues = _.differenceBy(trail.finalState.labeledEntities, trail.originalState.labeledEntities, 'valueId');
    }

    private setLinkedStudyEntities(trail: AuditTrail): void {
        trail.removed = _.differenceBy(trail.originalState.linkedEntities, trail.finalState.linkedEntities, 'id');
        trail.added = _.differenceBy(trail.finalState.linkedEntities, trail.originalState.linkedEntities, 'id');
    }

    private setLinkedMonitorGroups(trail: AuditTrail): void {
        trail.removed = _.differenceBy(trail.originalState.paywalls, trail.finalState.paywalls, 'id');
        trail.added = _.differenceBy(trail.finalState.paywalls, trail.originalState.paywalls, 'id');
    }

    private setBeforeAndAfterPath(trail: AuditTrail): void {
        const origTitle = trail.originalState.type === AuditTrailTypes.document ? trail.originalState.title : null;
        const finalTitle = trail.finalState.type === AuditTrailTypes.document ? trail.finalState.title : null;
        trail.originalState.pathBefore = this.buildFullPath(trail.originalState.binderName, trail.originalState.path, origTitle);
        trail.finalState.pathAfter = this.buildFullPath(trail.finalState.binderName, trail.finalState.path, finalTitle);
    }

    private setCloneAfterPath(clones = []): void {
        clones.forEach((clone) => {
            clone.pathAfter = this.buildFullPath(clone.binderName, clone.path, clone.title);
        });
    }

    private setDocumentFailedToSendInformation(trail: AuditTrail): void {
        trail.documentFailedToSendUserInformation = (trail.finalState.details && trail.finalState.details.data && trail.finalState.details.data.message) || 'Failed to send eBinder document to external system';
    }

    private obfuscateBulkEvent(trail: AuditTrail): void {
        if (trail.eventType === AuditTrailEventType.DOCUMENT_BULK_CLONED) {
            trail.eventType = AuditTrailEventType.DOCUMENT_CLONED;
        }
        if (trail.eventType === AuditTrailEventType.PLACEHOLDER_BULK_CLONED) {
            trail.eventType = AuditTrailEventType.PLACEHOLDER_CLONED;
        }
    }

    get isMultiItem(): boolean {
        return this.item.type === AuditTrailTypes.document && !!this.item.overwrittenPlaceholderId;
    }

    setItemFilters(): void {
        switch (this.selectedItemFilter.value) {
            case 'combined':
                this.overwrittenObjectId = this.item.overwrittenPlaceholderId;
                this.limitToOverwritten = false;
                break;
            case 'document':
                this.overwrittenObjectId = null;
                this.limitToOverwritten = false;
                break;
            case 'overwritten':
                this.overwrittenObjectId = this.item.overwrittenPlaceholderId;
                this.limitToOverwritten = true;
                break;
            default:
        // do nothing
        }

        this.applyFilter();
    }

    private buildFullPath(binderName: string, path, title: string): string {
        let normalizedPath = path;
        if (Array.isArray(path)) {
            normalizedPath = normalizedPath.map((el) => el.name).join('/');
        }
        return [binderName, normalizedPath, title].filter(Boolean).join('/');
    }

    buildAuditTrailName(item: any): Promise<string | Observable<string> | void> {
        if (item.binderId) {
            return this.bindersService
                .getBinder(item.teamId, item.binderId)
                .toPromise()
                .then((binder) => {
                    const title = item.subType === 'folder' ? null : item.name;
                    return this.buildFullPath(binder.name, item.path, title);
                })
                .catch(this.apiErrorsService.handleError);
        }

        return Promise.resolve(item.name);
    }

    pageChanged(event: CursorPageChangedEvent): void {
        this._onPageChange(event.cursor)
            .then(() => {
                event.onSuccess();
            });
    }

    sortChanged(sortProperty): void {
        if (this.pagination.sortBy === sortProperty) {
            this.pagination.sortDir = this.pagination.sortDir === 'ASC' ? 'DESC' : 'ASC';
        }
        this.pagination.sortBy = sortProperty;
        this.applyFilter();
    }

    _onPageChange(cursor?: Cursor): Promise<void> {
        const params = this.getFilterParams(cursor);
        return this.onPageChange(params).toPromise().then((data) => {
            this.data = data;
            this.initData();
        });
    }

    isAllUserPermissionsRemovedEvent(trail: AuditTrail): boolean {
        return trail.eventType === AuditTrailEventType.ALL_USER_PERMISSIONS_REMOVED;
    }

    isUserEmailAliasUpdated(trail: AuditTrail): boolean {
        return trail.eventType === AuditTrailEventType.USER_ALIAS_UPDATED;
    }

    hasBeforeAndAfterState(trail: AuditTrail): boolean {
        return trail.eventType === AuditTrailEventType.LOG_DETAILS_UPDATED
        || trail.eventType === AuditTrailEventType.USER_PROFILE_INFORMATION_CHANGED;
    }

    getStateItems(trail: AuditTrail, isBefore: boolean): string[] {
        if (!this.hasBeforeAndAfterState(trail)) {
            return [];
        }

        switch (trail.eventType) {
            case AuditTrailEventType.LOG_DETAILS_UPDATED:
                return this.logDetailsUpdateDifference(trail, isBefore);
            case AuditTrailEventType.USER_PROFILE_INFORMATION_CHANGED:
                return this.userProfileInfoUpdateDifference(trail, isBefore);
            default:
                return [];
        }
    }

    private userProfileInfoUpdateDifference(trail: AuditTrail, isBefore: boolean): string[] {
        const oldProfile = trail && trail.originalState && trail.originalState.profile;
        const newProfile = trail && trail.finalState && trail.finalState.profile;

        const propLabelDefs = [
            { propName: 'orgType', label: 'Organization/Company type' },
            { propName: 'orgName', label: 'Organization/Company name' },
            { propName: 'jobFunction', label: 'Job function' },
            { propName: 'jobTitle', label: 'Job title' }
        ];

        const diffs: string[] = [];
        propLabelDefs.forEach(({ propName, label }) => {
            const oldValue = oldProfile && oldProfile[propName];
            const newValue = newProfile && newProfile[propName];
            if (oldValue !== newValue) {
                diffs.push(isBefore ? `${label}: ${oldValue}` : `${label}: ${newValue}`);
            }
        });

        return diffs;
    }

    private logDetailsUpdateDifference(trail: AuditTrail, isBefore: boolean): string[] {
        const logDetailsOriginal = {};

        const originalStateLogDetails = trail.originalState.logDetails
            ? trail.originalState.logDetails : trail.originalState.documentProperties.logDetails;

        originalStateLogDetails.forEach((logDetail) => {
            logDetailsOriginal[logDetail.name] = logDetail.value;
        });

        const result = [];

        const finalStateLogDetails = trail.finalState.logDetails
            ? trail.finalState.logDetails : trail.finalState.documentProperties.logDetails;

        finalStateLogDetails.forEach((logDetail) => {
            if (logDetailsOriginal[logDetail.name] !== logDetail.value) {
                result.push(isBefore ? `${logDetail.name}: ${logDetailsOriginal[logDetail.name]}` : `${logDetail.name}: ${logDetail.value}`);
            }
        });

        return result;
    }

    cancel(): void {
        this.modal.hide();
    }

    downloadAuditTrail(format: string, item: any, subject: any): void {
        if (!this.canDownloadAuditTrail) {
            return;
        }

        this.buildAuditTrailName(item)
            .then((name) => {
                const options = {
                    format,
                    teamId: item.teamId,
                    objectId: item.id,
                    subject,
                    name,
                    overwrittenObjectId: item.overwrittenPlaceholderId || ''
                };
                this.downloadsService.downloadAuditTrail(options)
                    .toPromise()
                    .then(() => {
                        const href = this.$state.href('app.team.downloads', { teamId: item.teamId });
                        const message = `<p>Starting download now! We'll notify you when your download is ready.</p>
                            Go to <a class="page-action u-d-inline u-font-weight-bold" href=${href}>MY DOWNLOADS</a> to view all downloads.`;
                        this.notificationsService.info(message);
                        this.cancel();
                    })
                    .catch((error) => {
                        if (error && error.error && error.error.message) {
                            this.notificationsService.error(error.error.message);
                        }
                        else {
                            this.notificationsService.unexpectedError();
                        }
                    });
            });
    }

    setFilter(filter: string): void {
        this.textFilter = filter;
        this.applyFilter();
    }

    onFilterTypeChange(filterType: DynamicFilterTypeItem): void {
        this.textFilterSelected = filterType;
    }

    getFilterParams(next?: Cursor): GetAuditsParams {
        const params = {
            subject: this.subject,
            teamId: this.item.teamId,
            objectId: this.item.id,
            overwrittenObjectId: this.overwrittenObjectId || '',
            limitToOverwritten: this.limitToOverwritten || false,
            ...this.pagination,
            ...next && { next },
            ...this.textFilter && {
                filter: {
                    value: this.textFilter,
                    type: this.textFilterSelected.filterField
                }
            }
        };

        return params;
    }

    private applyFilter(): void {
        if (!this.showPaginationElement) {
            this._onPageChange(undefined);
            return;
        }
        this.paginationReset$.next();
    }

    private qcReviewRejected(trail: AuditTrail): void {
        const { reviews } = trail.finalState.qcReview;
        const rejectedReview = reviews.find((review) => review.status === 'Rejected');
        trail.finalState.qcReview.rejectReason = rejectedReview.reasons.join('; ');
    }

    private qcReviewerChanged(trail: AuditTrail): void {
        const { reviews: oldReviews } = trail.originalState.qcReview;
        const { reviews: newReviews } = trail.finalState.qcReview;

        oldReviews.forEach((oldReview, index) => {
            if (oldReview.reviewerIds.id !== newReviews[index].reviewerIds.id) {
                trail.finalState.qcReview.oldReviewer = oldReview.reviewerIds.fullName
                    ? oldReview.reviewerIds.fullName : oldReview.reviewerIds.email;
                trail.finalState.qcReview.newReviewer = newReviews[index].reviewerIds.fullName
                    ? newReviews[index].fullName : newReviews[index].email;
            }
        });
    }

    changeLogEntriesUpdatesVisibility(id: string): void {
        if (!this.logEntryUpdates[id]) {
            this.logEntryUpdatesLoading[id] = true;
            this.auditTrailService.getAudit(this.currentTeam.id, id, AUDIT_TRAIL_SUBJECTS.LOG_ENTRY).subscribe((res) => {
                this.logEntryUpdates[id] = res;
                this.logEntryUpdatesLoading[id] = false;
            });
        }
        this.logEntryUpdatesShown[id] = !this.logEntryUpdatesShown[id];
    }

    getLogEntryAdditionInfo(audit: AuditTrail): string {
        const originalColumnValues = audit.originalState.columns.map((c) => ({ value: c.value, name: c.name, type: c.type }));
        const finalColumnValues = audit.finalState.columns.map((c) => ({ value: c.value, name: c.name, type: c.type }));
        let info = '';
        for (let i = 0; i < finalColumnValues.length; i += 1) {
            const originalValue = this.resolveValue(originalColumnValues[i]);
            const finalValue = this.resolveValue(finalColumnValues[i]);
            const fieldName = originalColumnValues[i].name;
            const logNameSection = `Log entry field name: <b>${fieldName}</b>`;
            const beforeSection = originalValue
                ? `<br>Before: <b>${originalValue}</b>`
                : '<br>Before: <b><em>No Value</em></b>';
            const afterSection = finalValue
                ? `<br>After: <b>${finalValue}</b>`
                : '<br>After: <b><em>No Value</em></b>';
            if (JSON.stringify(originalValue) !== JSON.stringify(finalValue)) {
                info += logNameSection;
                info += beforeSection;
                info += afterSection;
                info += '<br><br>';
            }
        }
        return info;
    }

    private resolveValue(column) {
        if (!column.value || !column.value.length) {
            return null;
        }
        if (column.type === LogEntryTypes.date) {
            return moment(column.value).format('DD-MMM-YYYY');
        }
        if (column.type === LogEntryTypes.multiSelect) {
            return column.value.map((i) => i.name).join(', ');
        }
        return column.value;
    }
}
