'use strict';

const AnnotationTypes = require('../annotations/annotationTypes');
const FormFieldType = require('../forms/field-type');

const formFieldTypeToAnnotationType = (formFieldType) => {

    switch (formFieldType) {
        case FormFieldType.SIGNATURE:
            return AnnotationTypes.signature;
        case FormFieldType.CHECKBOX:
        case FormFieldType.RADIO:
        case FormFieldType.TEXTBOX:
            return AnnotationTypes.add_text;
        default: '';
    }
};

module.exports = formFieldTypeToAnnotationType;
