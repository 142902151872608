import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NotificationsService } from '@app/core/notifications/notifications.service';
import { RelatedObjectRef, Role, User } from '@app/shared/models';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AnnouncementsService } from '../../annoucements.service';

import template from './announcement-send.component.html';
import styles from './announcement-send.component.scss';

@Component({
    selector: 'announcement-send',
    template,
    styles: [String(styles)]
})
export class AnnouncementSendComponent implements OnInit {
    @Input() teamId: string;
    @Output() save = new EventEmitter<void>();

    readonly maxAnnouncementTitleLength = 250;
    readonly maxAnnouncementMessageLength = 1000;
    readonly progressSteps = { 1: 'Add Details', 2: 'Select Users' };
    announcementForm: FormGroup;
    announcementRelatedObjects: RelatedObjectRef[] = [];
    recipients: (User | Role)[] = [];
    isProcessing = false;
    progress = 1;

    constructor(
        private Announcements: AnnouncementsService,
        private Notifications: NotificationsService,
        private fb: FormBuilder,
        private modalRef: BsModalRef
    ) { }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.announcementForm = this.fb.group({
            title: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(this.maxAnnouncementTitleLength)
                ]],
            message: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(this.maxAnnouncementMessageLength)
                ]]
        });
    }

    hideModal(): void {
        this.modalRef.hide();
    }

    setProgress(n: number): void {
        this.progress = Number(n);
    }

    incrementProgress(n: number): void {
        this.setProgress(Number(this.progress) + n);
    }

    onRecipientSelected(addedRecipient: User | Role): void {
        const newRecipients = [...this.recipients, addedRecipient];
        this.recipients = sortByLexicographically(newRecipients, 'name');
    }

    onRecipientUnselected(removedRecipient: User | Role): void {
        this.recipients = this.recipients.filter((recipient) => {
            return !(recipient.type === removedRecipient.type && removedRecipient.id === recipient.id);
        });
    }

    updateRelatedObjects(relatedObjects: RelatedObjectRef[]): void {
        this.announcementRelatedObjects = relatedObjects;
    }

    sendAnnouncement(): void {
        const userIds: string[] = [];
        const roleIds: string[] = [];
        this.recipients.forEach((recipient) => {

            if (recipient.type === 'user') {
                userIds.push(recipient._id);
            }
            else {
                roleIds.push(recipient.id);
            }
        });

        const params = {
            ...this.announcementForm.value,
            userIds,
            roleIds,
            relatedObjects: this.announcementRelatedObjects
        };
        this.isProcessing = true;

        this.Announcements.sendAnnouncement(this.teamId, params)
            .subscribe(
                (data) => {
                    this.Notifications.success('Announcement sent!');

                    if (data && data.failedRecipients && data.failedRecipients.length) {
                        data.failedRecipients.forEach((failedRecipient) => {
                            this.Notifications.error(`${failedRecipient.recipientType} with ID ${failedRecipient.recipientId} doesn't exist.`);
                        });
                    }

                    this.hideModal();
                    this.save.emit();
                },
                () => {
                    this.isProcessing = false;
                }
            );
    }
}
