import * as angular from 'angular';
import REGEX from './regex';
import SORT from './sort';
import MESSAGES from './messages';
import MONTHS from './months';
import { FileExtensions as EXTENSIONS } from './extensions';
import TABLE_CELL_TYPES from './table-cell-types';
import SIGNING_REASONS from './signing-reasons';
import DOCUMENT_STATUSES from './document-statuses';
import DOCUMENT_TYPES from './document-types';
import MONITOR_REVIEW_APPRPVED_STATUSES from './monitor-review-approved-statuses';
import MONITOR_REVIEW_ACTIVITIES from './monitor-review-activities';
import TASK_STATUSES from './tasks-statuses';
import ROUTES from './routes';
import ROLE_BLINDED_STATUSES from './role-blinded-statuses';
import SUBJECT_TYPES from './subject-types';

import { CheckboxStates as CHECKBOX_STATES } from './checkbox-states';

const dependencies = [
];

const constants = angular
    .module('app.core.constants', dependencies)
    .constant('EXTENSIONS', EXTENSIONS)
    .constant('MESSAGES', MESSAGES)
    .constant('MONTHS', MONTHS)
    .constant('REGEX', REGEX)
    .constant('SIGNING_REASONS', SIGNING_REASONS)
    .constant('DOCUMENT_STATUSES', DOCUMENT_STATUSES)
    .constant('DOCUMENT_TYPES', DOCUMENT_TYPES)
    .constant('MONITOR_REVIEW_APPRPVED_STATUSES', MONITOR_REVIEW_APPRPVED_STATUSES)
    .constant('MONITOR_REVIEW_ACTIVITIES', MONITOR_REVIEW_ACTIVITIES)
    .constant('TASK_STATUSES', TASK_STATUSES)
    .constant('SORT', SORT)
    .constant('TABLE_CELL_TYPES', TABLE_CELL_TYPES)
    .constant('CHECKBOX_STATES', CHECKBOX_STATES)
    .constant('ROUTES', ROUTES)
    .constant('ROLE_BLINDED_STATUSES', ROLE_BLINDED_STATUSES)
    .constant('SUBJECT_TYPES', SUBJECT_TYPES);

export default constants;

const NAMES_PREVIEW_LIMIT = 100;
const MAX_DATE_ISO = '9999-09-09T00:00:00.000Z';

export {
    EXTENSIONS,
    MESSAGES,
    MONTHS,
    REGEX,
    SIGNING_REASONS,
    DOCUMENT_STATUSES,
    DOCUMENT_TYPES,
    MONITOR_REVIEW_APPRPVED_STATUSES,
    MONITOR_REVIEW_ACTIVITIES,
    TASK_STATUSES,
    SORT,
    TABLE_CELL_TYPES,
    CHECKBOX_STATES,
    NAMES_PREVIEW_LIMIT,
    MAX_DATE_ISO,
    ROUTES,
    ROLE_BLINDED_STATUSES,
    SUBJECT_TYPES
};
