'use strict';

module.exports = {
    add_text: 'add_text',
    redaction: 'redaction',
    highlight: 'highlight',
    stamp: 'stamp',
    signature: 'signature',
    timestamp: 'timestamp'
};
