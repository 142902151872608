import { NgModule } from '@angular/core';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserModule } from '@angular/platform-browser';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UIRouterModule } from '@uirouter/angular';
import { DownloadsIndexComponent } from './containers/downloads-index/downloads-index.component';
import { downloadsRoutes } from './downloads.routes';


@NgModule({
    imports: [
        WidgetsModule,
        BrowserModule,
        UIRouterModule.forChild({ states: downloadsRoutes }),
        BrowserAnimationsModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot()
    ],
    providers: [],
    declarations: [DownloadsIndexComponent],
    entryComponents: [DownloadsIndexComponent],
    exports: []
})
export class DownloadsModule {}
