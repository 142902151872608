import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { Document } from '@app/shared/models/document.model';
import { Connection } from '../document-sharing.types';

@Injectable()
export class DocumentSharingStateService {

    public teams$ = new BehaviorSubject<Connection[] | null>([]);
    public exchangeName$ = new BehaviorSubject<string | null>(null);
    private documentsForExchange: Document[] = [];

    public set selectedDocumentsForExchange(documents: Document[]) {
        this.documentsForExchange = documents;
    }

    public get selectedDocumentsForExchange(): Document[] {
        return this.documentsForExchange;
    }

    public selectTeams(teams: Connection[]) {
        this.teams$.next(_.uniqBy([...this.teams$.getValue(), ...teams], '_id'));
    }

    public deselectTeams(teams: Connection[]) {
        const teamIds = teams.map((t) => t._id);
        this.teams$
            .next(this.teams$.getValue()
                .filter((t) => !teamIds.includes(t._id)));
    }

    public deselectDocument(document: Document) {
        this.documentsForExchange = this.documentsForExchange.filter((doc) => doc.id !== document.id);
    }

    public changeExchangeName(name: string) {
        this.exchangeName$.next(name);
    }

    public getExchangeName(): string {
        return this.exchangeName$.getValue();
    }
}
