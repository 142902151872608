import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Notification } from './notifications.service.types';


@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
  public clear$: Observable<void>;
  public notifications$: Observable<Notification>;
  private notifications: Subject<Notification>;
  private clear: Subject<void>;

  private toaster: ToastrService | undefined;

  constructor(private injector: Injector) {
      this.notifications = new Subject<Notification>();
      this.notifications$ = this.notifications.asObservable();

      this.notifications$.subscribe((notification) => this.displayNotification(notification));
      this.clear = new Subject<void>();
      this.clear$ = this.clear.asObservable();

  }


  private getToastr(): ToastrService {
      if (!this.toaster) {
          this.toaster = this.injector.get(ToastrService);
      }
      return this.toaster;
  }

  primary(message: Notification['message']): void {
      this.notifications.next({ message, type: 'primary' });
  }

  error(message: Notification['message']): void {
      this.notifications.next({ message, type: 'error' });
  }

  info(message: Notification['message'], options?: Notification['options']): void {
      this.notifications.next({ message, type: 'info', options });
  }

  success(message: Notification['message'], options?: Notification['options']): void {
      this.notifications.next({ message, type: 'success', options });
  }

  warning(message: Notification['message']): void {
      this.notifications.next({ message, type: 'warning' });
  }

  unexpectedError(): void {
      this.error('There was an unexpected error. Please contact your administrator.');
  }

  clearAll(): void {
      this.clear.next();
  }

  private displayNotification(notification: Notification): void {
      const message = notification.message.toString();
      const toaster = this.getToastr();
      const options = {
          ...notification.options,
          enableHtml: true
      };

      switch (notification.type) {
          case 'primary':
              toaster.info(message, null, options);
              break;
          case 'error':
              toaster.error(message, null, options);
              break;
          case 'info':
              toaster.info(message, null, options);
              break;
          case 'success':
              toaster.success(message, null, options);
              break;
          case 'warning':
              toaster.warning(message, null, options);
              break;
          default:
              toaster.show(message, null, options);
      }
  }
}
