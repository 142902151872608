import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import * as _ from 'lodash';

import template from './entity-link.component.html';

@Component({
    selector: 'entity-link',
    template
})
export class EntityLinkComponent implements OnInit {
    @Input() entity;
    @Input() latestVersion: boolean;
    isLinkable: boolean;
    destination: string;
    destinationParams: object;

    ngOnInit(): void {

        if (!this.entity) {
            return;
        }

        let baseObj;
        switch (this.entity.type) {
            case 'task':
                baseObj = this.entity.location || this.entity;
                if (!baseObj.version && baseObj.version !== 0) {
                    this.destination = 'app.team.document-show-most-recent';
                    this.destinationParams = {
                        teamId: baseObj.teamId,
                        documentId: baseObj.documentId,
                        taskId: this.entity.taskId || this.entity.id
                    };
                    break;
                }
                this.destination = 'app.team.document-show';
                this.destinationParams = {
                    teamId: baseObj.teamId,
                    documentId: baseObj.documentId,
                    taskId: this.entity.id || this.entity.taskId,
                    version: baseObj.version
                };
                break;
            case 'document':
                baseObj = _.isObject(this.entity.location) ? this.entity.location : this.entity;
                baseObj.version = baseObj.version ?? _.get(this.entity, 'id.version') ?? _.get(this.entity, 'documentVersion') ?? this.entity.version;
                if ((!baseObj.version && baseObj.version !== 0) || this.latestVersion) {
                    this.destination = 'app.team.document-show-most-recent';
                    this.destinationParams = {
                        teamId: baseObj.teamId,
                        documentId: baseObj.documentId || this.entity.id.documentId || this.entity.id,
                        ...baseObj.signatureRequestId && { signatureRequestId: baseObj.signatureRequestId }
                    };
                    break;
                }
                this.destination = 'app.team.document-show';
                this.destinationParams = {
                    teamId: baseObj.teamId,
                    documentId: baseObj.documentId || this.entity.id.documentId || this.entity.id,
                    version: baseObj.version || 0,
                    ...baseObj.contentVersion && { contentVersion: baseObj.contentVersion }
                };
                break;
            case 'binder':
                this.destination = 'app.team.folder';
                this.destinationParams = {
                    teamId: this.entity.teamId,
                    binderId: this.entity.id
                };
                break;
            case 'folder':
                this.destination = 'app.team.folder';
                this.destinationParams = {
                    teamId: this.entity.teamId,
                    binderId: this.entity.binderId,
                    folderId: this.entity.id
                };
                break;
            case 'label':
            default:
                this.destination = 'app.team.folder';
                this.destinationParams = {
                    teamId: this.entity.teamId,
                    binderId: this.entity.binderId,
                    ...(this.entity.objectType === 'folder') && {
                        folderId: this.entity.objectId
                    }
                };
                break;
        }
        this.isLinkable = !Object.values(this.destinationParams).some((destParam) => destParam === undefined);
    }
}
