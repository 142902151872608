import {
    Component, Input
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import template from './import-structure-details.component.html';
import styles from './import-structure-details.component.scss';
import { ImportStructureDetailsValidation } from './import-structure-details.types';


@Component({
    selector: 'import-structure-details',
    template,
    styles: [String(styles)]
})
export class ImportStructureDetailsComponent {
    @Input() templateDetailsForm: FormGroup;

    readonly templateNameMinLength = ImportStructureDetailsValidation.templateNameMinLength;
    readonly templateNameMaxLength = ImportStructureDetailsValidation.templateNameMaxLength;

    readonly templateDescriptionMinLength = ImportStructureDetailsValidation.templateDescriptionMinLength;
    readonly templateDescriptionMaxLength = ImportStructureDetailsValidation.templateDescriptionMaxLength;
}
