import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ScrollingModule } from '@angular/cdk/scrolling';

import { SharedModule } from '@app/shared/shared.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { EntityModule } from '@app/widgets/entity/entity.module';
import { ModalsModule } from '@app/widgets/modals/modals.module';
import { DocumentsSelectedTabComponent } from './components/documents-selected-tab/documents-selected-tab.component';
import { DocumentsSignersTabComponent } from './components/documents-signers-tab/documents-signers-tab.component';
import { DocumentsPendingSignaturesTabComponent } from './components/documents-pending-signatures-tab/documents-pending-signatures-tab.component';
import { LogEntrySignersTabComponent } from './components/log-entry-signers-tab/log-entry-signers-tab.component';
import { LogEntryPendingSignaturesTabComponent } from './components/log-entry-pending-signatures-tab/log-entry-pending-signatures-tab.component';
import { NoSigningPermissionsComponent } from './components/no-signing-permissions/no-signing-permissions.component';
import { SelectDocumentsComponent } from './components/select-documents/select-documents.component';
import { DocumentsSignatureRequestsComponent } from './containers/documents-signature-requests/documents-signature-requests.component';
import { LogEntrySignatureRequestsComponent } from './containers/log-entry-signature-requests/log-entry-signature-requests.component';
import { RequestorPermissionWarningComponent } from './components/requestor-permission-warning/requestor-permission-warning.component';
import { NoSigningPermissionsWarningComponent } from './components/no-signing-permissions-warning/no-signing-permissions-warning.component';
import { SignatureTypePipe } from './pipes/signature-type.pipe';
import { LogEntryBulkSignatureRequestsComponent } from './containers/log-entry-bulk-signature-requests/log-entry-bulk-signature-requests.component';
import { LogEntryBulkChooseSignersComponent } from './components/log-entry-bulk-choose-signers/log-entry-bulk-choose-signers.component';
import { LogEntryBulkRequestSignaturesTableComponent } from './components/log-entry-bulk-request-signatures-table/log-entry-bulk-request-signatures-table.component';
import { LogEntryBulkRequestSignaturesRowPreviewComponent } from './components/log-entry-bulk-request-signatures-row-preview/log-entry-bulk-request-signatures-row-preview.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        WidgetsModule,
        EntityModule,
        ScrollingModule,
        BsDropdownModule.forRoot(),
        ModalsModule
    ],
    declarations: [
        DocumentsSelectedTabComponent,
        DocumentsSignersTabComponent,
        DocumentsPendingSignaturesTabComponent,
        LogEntrySignersTabComponent,
        LogEntryPendingSignaturesTabComponent,
        NoSigningPermissionsComponent,
        SelectDocumentsComponent,
        DocumentsSignatureRequestsComponent,
        LogEntrySignatureRequestsComponent,
        RequestorPermissionWarningComponent,
        NoSigningPermissionsWarningComponent,
        SignatureTypePipe,
        LogEntryBulkSignatureRequestsComponent,
        LogEntryBulkChooseSignersComponent,
        LogEntryBulkRequestSignaturesTableComponent,
        LogEntryBulkRequestSignaturesRowPreviewComponent
    ],
    entryComponents: [
        DocumentsSelectedTabComponent,
        DocumentsSignersTabComponent,
        DocumentsPendingSignaturesTabComponent,
        LogEntrySignersTabComponent,
        LogEntryPendingSignaturesTabComponent,
        NoSigningPermissionsComponent,
        SelectDocumentsComponent,
        DocumentsSignatureRequestsComponent,
        LogEntrySignatureRequestsComponent,
        RequestorPermissionWarningComponent,
        NoSigningPermissionsWarningComponent,
        LogEntryBulkSignatureRequestsComponent,
        LogEntryBulkChooseSignersComponent,
        LogEntryBulkRequestSignaturesTableComponent,
        LogEntryBulkRequestSignaturesRowPreviewComponent
    ]
})
export class SignatureRequestsModule { }
