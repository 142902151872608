
import {
    Component, Input, Output, OnInit, EventEmitter
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SUBJECT_TYPES, ROLE_BLINDED_STATUSES } from '@app/core/constants';
import { notBlank, valueMatch } from '@app/core/form-validators';
import { Role, User } from '@app/shared/models';
import template from './confirm-blinded-roles-warning.component.html';
import styles from './confirm-blinded-roles-warning.component.scss';

@Component({
    selector: 'confirm-blinded-roles-warning',
    styles: [String(styles)],
    template
})
export class ConfirmBlindedRolesWarningComponent implements OnInit {
    @Input() data: Role[] | User[];
    @Input() dataType: string;
    @Input() subject: Role | User;
    @Output() confirmEvent = new EventEmitter();
    @Output() cancelEvent = new EventEmitter();

    confirmRiskForm: FormGroup;

    constructor(
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.initForm();
    }

    initForm() {
        this.confirmRiskForm = this.fb.group({
            confirmRisk: ['', [Validators.required, notBlank, valueMatch('I confirm', true)]]
        });
    }

    cancel() {
        this.cancelEvent.emit();
    }

    confirm() {
        this.confirmEvent.emit();
    }

    get roleBlindedStatuses() {
        return ROLE_BLINDED_STATUSES;
    }

    get subjectTypes() {
        return SUBJECT_TYPES;
    }
}
