import { Observable, forkJoin } from 'rxjs';
import {
    Component, Input, Output, EventEmitter, OnChanges, SimpleChanges
} from '@angular/core';
import {
    Binder, Study, BinderTreeSelectionItem, Site, StudyEntity, Paywall,
    Folder,
    Document
} from '@app/shared/models';
import { BindersService } from '@app/shared/binders/binders.service';
import { TeamService } from '@app/shared/teams/team.service';
import { UpdateLinkedEntitiesParams, UpdateMonitorGroupStudyIdsParams } from '@app/shared/studies/studies.service.types';
import { sortBrowseTreeLexicographically } from '@app/widgets/sort/sort-browse-tree-lexicographically.util';

import { ModalsService } from '@app/shared/modal-helper/modals.service';
import template from './study-details.component.html';
import { StudyLinkEntitiesComponent } from '../study-link-entities/study-link-entities.component';
import { MonitorGroupsListModalComponent } from '../monitor-groups-list/monitor-groups-list-modal.component';

@Component({
    selector: 'study-details',
    template
})
export class StudyDetailsComponent implements OnChanges {
    @Input() study: Study;
    @Input() studyMonitorGroups: Paywall[];
    @Input() canConnect: boolean;
    @Input() canConnectMonitorGroups: boolean;
    @Input() loadEntities: (teamId: string, studyId: string, siteId?: string) => Observable<StudyEntity[]>;
    @Input() linkEntities: (teamId: string, studyId: string, siteId: string | undefined, actions) => Observable<void>;
    @Input() linkMonitorGroups: (teamId: string, studyId: string, actions) => Observable<Paywall[]>;
    @Output() connect = new EventEmitter<{ selectedItems: BinderTreeSelectionItem[] }>();
    @Output() connectMonitorGroups = new EventEmitter<{ updates: UpdateMonitorGroupStudyIdsParams }>();
    public loading = true;
    public showMonitorGroups = false;
    isProcessing = false;
    public filteredSites: Site[];
    public accordions: { [siteId: string]: boolean } = {};
    public entities: {
        [siteId: string]: {
            visible: boolean;
            entities?: StudyEntity[];
        };
    } = {};

    constructor(
        private Binders: BindersService,
        private Teams: TeamService,
        private Modals: ModalsService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.study) {
            this.loading = changes.study.currentValue === undefined;
            this.filteredSites = this.study ? this.study.sites : [];
        }
    }

    onConnect(id: string): void {
        this.isProcessing = true;
        const selectedItems = this._loadEntities(id);
        forkJoin({
            binders: this.Binders
                .getBinders(this.study.teamId, { includeArchived: false }),
            selectedItems
        }).subscribe((data) => {
            this.isProcessing = false;
            const modalRef = this.Modals.show(StudyLinkEntitiesComponent, {
                class: 'modal-lg',
                initialState: {
                    studyName: `${this.study.uniqueProtocolId}: ${this.study.nickname}`,
                    loadSelected: () => data.selectedItems,
                    loadRoot: () => data.binders,
                    loadItem: (params =
                    {
                        includeDocs: false,
                        includeArchived: false
                    }) => this.Teams
                        .browse(this.study.teamId, { ...params })
                        .toPromise()
                        .then((result) => sortBrowseTreeLexicographically(result, 'name') as unknown as (Binder | Folder | Document)[])
                }
            });

            modalRef.content.onSubmit.subscribe((updates: UpdateLinkedEntitiesParams) => {
                this.updateEntities(id, updates).toPromise().then(() => {
                    if (this.entities[id] && this.entities[id].visible) {
                        this._loadEntities(id).subscribe((entities) => {
                            this.entities[id].entities = entities;
                        });
                    }
                }).finally(() => {
                    this.connect.emit();
                    modalRef.hide();
                });
            });
        }, () => {
            this.isProcessing = false;
        });

    }


    onConnectMonitorGroups(): void {
        const modal = this.Modals.show(MonitorGroupsListModalComponent, {
            animated: false,
            class: 'modal-md',
            initialState: {
                studyName: `${this.study.uniqueProtocolId}: ${this.study.nickname}`,
                studyId: this.study.id,
                teamId: this.study.teamId
            }
        });

        modal.content.onSubmit.subscribe((updates: UpdateMonitorGroupStudyIdsParams) => {
            this.updateMonitorGroups(updates).subscribe((r) => {
                this.studyMonitorGroups = r;
            });
        });

        modal.content.dismiss.subscribe(() => modal.hide());

        this.connectMonitorGroups.emit();
    }

    onFilter(filter): void {
        if (!this.study) {
            return;
        }

        this.filteredSites = this.study.sites.filter((site) => {
            return site.siteName.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) >= 0;
        });
    }

    onExpandAll(): void {
        if (!this.study) {
            return;
        }
        this.study.sites.forEach((s) => {
            this.onToggleSite(s.id, true);
        });
    }

    onCollapseAll(): void {
        if (!this.study) {
            return;
        }
        this.study.sites.forEach((s) => {
            this.accordions[s.id] = false;
            this.onToggleSite(s.id, false);
        });
    }

    onToggleSite(siteId: string, state: boolean): void {
        this.accordions[siteId] = state;
        this.entities[siteId] = { visible: false };
    }

    onShowEntities(id: string): void {
        if (this.entities[id] && this.entities[id].visible) {
            this.entities[id] = { visible: false };
            return;
        }
        this.entities[id] = { visible: true };

        this._loadEntities(id).subscribe((entities) => {
            this.entities[id].entities = entities;
        });
    }

    onShowMonitorGroups(): void {
        this.showMonitorGroups = !this.showMonitorGroups;
    }

    private _loadEntities(id: string): Observable<StudyEntity[]> {
        const siteId = id === this.study.id ? undefined : id;
        return this.loadEntities(this.study.teamId, this.study.id, siteId);
    }

    private updateEntities(id: string, updates: UpdateLinkedEntitiesParams): Observable<void> {
        const siteId = id === this.study.id ? undefined : id;
        return this.linkEntities(this.study.teamId, this.study.id, siteId, updates);
    }

    private updateMonitorGroups(updates: UpdateMonitorGroupStudyIdsParams): Observable<Paywall[]> {
        return this.linkMonitorGroups(this.study.teamId, this.study.id, updates);
    }
}
