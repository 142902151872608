import { Inject, Injectable, OnDestroy } from '@angular/core';
import * as Sentry from '@sentry/browser';

import { AppConfigService } from '@app/shared/app-config/app-config.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import {
    Observable, of, Subject, timer
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Auth0Service } from '../sessions/auth0.service';

interface Auth0Error extends Error {
    error: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    error_description: string;
    message: string;
}

@Injectable({
    providedIn: 'root'
})
export class Auth0ErrorService implements OnDestroy {
    private readonly destroy$ = new Subject();
    email:string;

    private loginRequiredError = 'login_required';

    private unauthorizedError = 'unauthorized';
    private accessDenied = 'access_denied';
    private verifyEmailMessage = 'Please verify your email before logging in.';

    readonly timeoutBeforeRedirectInMS = 1000;

    constructor(
        private AppConfig: AppConfigService,
        private auth0Service: Auth0Service,
        private notificationsService: NotificationsService,
        private currentSession: CurrentSessionService,
        @Inject('Window') private window: Window
    ) {}

    init(): Observable<unknown> {
        this.auth0Service.error$
            .pipe(takeUntil(this.destroy$))
            .subscribe((error) => {
                // error object was not correctly typed in @auth0/auth0-angular package
                const auth0Error = error as Auth0Error;
                if (this.isEmailVerifyError(auth0Error)) {
                    this.window.location.href = `${this.AppConfig.config.authHost}/#/confirmation?email=${encodeURIComponent(this.email)}`;
                }
                else if (auth0Error.error === this.loginRequiredError) {
                    this.auth0Service.loginWithRedirect({
                        isFullAuthRequired: true
                    });
                }
                else {
                    Sentry.captureMessage(`[${auth0Error.error}]: ${auth0Error.error_description}. ${auth0Error.message}`, Sentry.Severity.Fatal);
                    const description = this.isJsonString(error.message)
                        ? JSON.parse(error.message).description
                        : error.message;
                    const errorMessage = description || 'There was an unexpected error. Please contact your administrator.';
                    this.notificationsService.error(errorMessage);

                    timer(this.timeoutBeforeRedirectInMS)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe(() => {
                            this.auth0Service.logout({
                                returnTo: this.window.location.origin
                            });
                        });
                }
            });
        return of({});

    }

    private isEmailVerifyError(error: Auth0Error): boolean {
        if (
            (error.error === this.unauthorizedError || error.error === this.accessDenied) && this.isJsonString(error.message)
        ) {
            const { description } = JSON.parse(error.message);
            if (description === this.verifyEmailMessage) {
                this.email = JSON.parse(error.message).email;
                return true;
            }
            return false;
        }
        return false;
    }

    private isJsonString(object: string): boolean {
        try {
            JSON.parse(object);
        }
        catch (error) {
            return false;
        }
        return true;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
