import { Folder } from '@app/shared/models';

export interface FolderDownloadOptions {
    teamId: string;
    binderId: string;
    folders: Folder[];
    includeSignature?: boolean;
    includeAllVersionsOfDocuments?: boolean;
}

export const optionsAreFolderDownloadOptions = (options): options is FolderDownloadOptions => {
    return 'folders' in options;
};
