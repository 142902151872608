export const ConnectedTeamsSortableProperties = {
    TEAMNAME: 'teamName'
} as const;

export type SortBy = typeof ConnectedTeamsSortableProperties[keyof typeof ConnectedTeamsSortableProperties];

export const SortingDirections = {
    ASC: 'ASC',
    DESC: 'DESC'
} as const;

export type SortingDirectionValues = typeof SortingDirections[keyof typeof SortingDirections];

export interface SortObject {
    by: typeof ConnectedTeamsSortableProperties[keyof typeof ConnectedTeamsSortableProperties];
    direction: typeof SortingDirections[keyof typeof SortingDirections];
}

export const REGIONS = {
    US: 'us',
    DE: 'de',
    KP: 'kp'
} as const;

export type Region = typeof REGIONS[keyof typeof REGIONS];

export const CONFIG_TYPES = {
    EBINDER: 'ebinder'
} as const;

export type ConfigType = typeof CONFIG_TYPES[keyof typeof CONFIG_TYPES];

export interface Receiver {
    teamId: string;
    receivingLocation?: {
        binderId: string;
        folderId?: string;
    };
    region: string;
    configType: ConfigType;
}

export type Config = Receiver & { name: string };


export interface Connection {
    _id: string;
    name: string;
    configs: [Config, Config];
}

export const CONNECTION_STATUS_TYPES = {
    SENDING: 'Sending',
    RECEIVING: 'Receiving',
    BOTH: 'Both',
    DEACTIVATED: 'Deactivated'
} as const;

export type ConnectionStatusType = typeof CONNECTION_STATUS_TYPES[keyof typeof CONNECTION_STATUS_TYPES];

export interface ConnectedTeamsList {
    _id: string,
    binderId: string,
    folderId: string,
    teamName: string,
    connectionStatus: ConnectionStatusType
}
