import * as _ from 'lodash';
import {
    OnInit,
    Component
} from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Team, User } from '@app/shared/models';
import { SORT } from '@app/core/constants';
import {
    SessionActivityStatuses,
    SessionActivityEvents
} from '../../session-activity.constants';
import { SessionActivityService } from '../../session-activity.service';

import styles from './sessions-activity-show.component.scss';
import template from './sessions-activity-show.component.html';

@Component({
    selector: 'session-activity-show',
    template,
    styles: [String(styles)]
})
export class SessionsActivityShowComponent implements OnInit {

    private events: Array<any> = [];
    private currentTeam: Team;
    private currentUser: User;
    private filter: string;
    private decoratedEvents: Array<any>;
    SORT = SORT;

    constructor(private CurrentSession: CurrentSessionService, private SessionActivity: SessionActivityService) {}

    ngOnInit(): void {
        this.currentUser = this.CurrentSession.getCurrentUser();
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.events = this.SessionActivity.getUserActivityForTeam(this.currentTeam.id);

        const sortables = ['event', 'data.object.name', 'data.object.fullPath', 'data.when', 'status'];
        const whenSort = sortables[3];
        this.SORT.set(whenSort, false);

        this.decoratedEvents = this.decorateEvents();
    }

    trackByFn(index: number, event: any): string {
        return event.data.when + event.data.object.name + index;
    }

    private decorateEvents() {

        return _.map(this.events, (evt) => {

            evt.data.object.fullPath = this.getFullPath(evt.data.object);
            evt.details = this.getEventDetails(evt);
            return evt;
        });
    }

    clearHistory() {
        this.events = [];
        this.decoratedEvents = [];
        this.SessionActivity.clearUserActivityForTeam(this.currentTeam.id);
    }

    isSuccessful(status) {
        return status === SessionActivityStatuses.SUCCESSFUL;
    }

    getFullPath(object) {

        if (object.fullPath) {
            return object.fullPath;
        }
        let path = object.type === 'binder' ? object.name : object.binderName || '';
        if (_.isArray(object.path)) {
            const folderPath = object.path.length ? object.path.map((p) => p.name).join('/') : '';
            path += path && folderPath ? `/${folderPath}` : folderPath;
        }
        else {
            path += path && object.path ? `/${object.path}` : object.path || '';
        }
        const isUndefinedOrNull = path === 'undefined' || path === 'null';
        path = isUndefinedOrNull ? '' : path || '';
        return path;
    }

    sortEventsBy(property: string) {
        this.SORT.set(property, undefined);
        const isReversedResolved = property === 'data.when' ? !SORT.isReversed : SORT.isReversed;

        this.decoratedEvents.sort((a, b) => {
            const propertyPathArray = property.split('.');
            let aValue = propertyPathArray.reduce((obj, key) => obj[key], a);
            let bValue = propertyPathArray.reduce((obj, key) => obj[key], b);

            aValue = aValue && aValue.toString ? aValue.toString().toLowerCase() : aValue;
            bValue = bValue && bValue.toString ? bValue.toString().toLowerCase() : bValue;

            if (aValue < bValue) {
                return isReversedResolved ? 1 : -1;
            }
            if (aValue > bValue) {
                return isReversedResolved ? -1 : 1;
            }
            return 0;
        });
    }

    getPathWithName(entity) {

        const fullPath = this.getFullPath(entity);
        return fullPath ? `${fullPath}/${entity.name}` : entity.name;
    }

    getEventDetails(event) {
        if (event.event === SessionActivityEvents.SHORTCUT_CREATE) {
            return this.getShortcutEventDetails(event);
        }
        if (event.event === SessionActivityEvents.DOCUMENT_UPLOAD) {
            return this.getDocUploadEventDetails(event);
        }
        if (event.event === SessionActivityEvents.TASK_REMINDER) {
            return this.getTaskReminderEventDetails(event);
        }
        if (event.event === SessionActivityEvents.SIGNATURE_REMINDER) {
            return this.getSignatureReminderEventDetails(event);
        }
    }

    private getStatusIcon(status) {

        const suffix = status === SessionActivityStatuses.FAILED ? 'fa-times-circle text-danger' : 'fa-check-circle text-success';
        return `fa fa-lg ${suffix}`;
    }

    private getShortcutEventDetails(event) {

        const icon = this.getStatusIcon(event.status);
        const { origin, target } = event.data;
        const targetType = _.capitalize(target.type);
        let statusMsg = _.capitalize(event.status);
        statusMsg += event.data.error ? `: ${event.data.error}` : '';
        return [
            {
                label: 'Original File',
                entity: origin,
                displayValue: this.getPathWithName(origin)
            },
            {
                label: `Destination ${targetType}`,
                entity: target,
                displayValue: this.getFullPath(target)
            },
            {
                label: 'Status',
                icon,
                displayValue: statusMsg
            }
        ];
    }

    private getDocUploadEventDetails(event) {

        const entity = event.data.object;
        const icon = this.getStatusIcon(event.status);
        return [
            {
                label: 'Document',
                entity,
                displayValue: this.getPathWithName(entity)
            },
            {
                label: 'Size',
                displayValue: `${(entity.file.size / 1024.0).toFixed(2)} KB`
            },
            {
                label: 'Status',
                icon,
                displayValue: _.capitalize(event.status.toLowerCase())
            }
        ];
    }

    private getTaskReminderEventDetails(event) {

        const entity = event.data.object;
        const icon = this.getStatusIcon(event.status);
        let statusMsg = _.capitalize(event.status);
        statusMsg += event.data.error ? `: ${event.data.error}` : '';
        return [
            {
                label: 'Document',
                entity: event.data.document,
                displayValue: entity.fullPath
            },
            {
                label: 'Task',
                entity,
                displayValue: entity.name
            },
            {
                label: 'Status',
                icon,
                displayValue: statusMsg
            }
        ];
    }

    private getSignatureReminderEventDetails(event) {

        const entity = event.data.object;
        const icon = this.getStatusIcon(event.status);
        let statusMsg = _.capitalize(event.status);
        statusMsg += event.data.error ? `: ${event.data.error}` : '';
        const version = entity.contentVersion || entity.version;
        return [
            {
                label: 'Document',
                entity,
                displayValue: `${entity.fullPath} Version: ${version}`
            },
            {
                label: 'Reason',
                displayValue: entity.reason
            },
            {
                label: 'Status',
                icon,
                displayValue: statusMsg
            }
        ];
    }

    getObjectDisplayName(event) {

        const { object } = event.data;
        const { name } = object;
        if (event.event !== SessionActivityEvents.SIGNATURE_REMINDER) {
            return name;
        }
        const version = object.contentVersion || object.version;
        return `${name} Version: ${version}`;
    }

    linkName(event) {

        const eventType = event.event;
        if (eventType === SessionActivityEvents.DOCUMENT_UPLOAD || eventType === SessionActivityEvents.SHORTCUT_CREATE) {
            return this.isSuccessful(event.status);
        }
        return true;
    }

    updateFilter(text: string): void {
        this.filter = text;
    }
}
