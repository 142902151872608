import * as angular from 'angular';
import uiNotification from '@rastasheep/angular-ui-notification';
import { downgradeInjectable } from '@angular/upgrade/static';

import '@rastasheep/angular-ui-notification/dist/angular-ui-notification.css';
import notificationConfig from './notifications.config';
import { NotificationsService } from './notifications.service';
import './notifications.scss';

const dependencies = [
    uiNotification
];

const notifications = angular
    .module('app.core.notifications', dependencies)
    .config(notificationConfig)
    .factory('Notifications', downgradeInjectable(NotificationsService))
    .run(['Notifications', 'Notification', (
        Notifications: NotificationsService,
        Notification: ng.uiNotification.INotificationService
    ): void => {
        Notifications.notifications$.subscribe((n) => Notification(n.message, n.type));
        Notifications.clear$.subscribe(() => Notification.clearAll());
    }]);


export default notifications;
