import { Component, Input } from '@angular/core';
import { Binder, Folder } from '@app/shared/models';
import { StateOrName, StateService } from '@uirouter/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './structure-template-assigned.component.html';
import styles from './structure-template-assigned.component.scss';

@Component({
    selector: 'structure-template-assigned',
    template,
    styles: [String(styles)]
})

export class StructureTemplateAssignedComponent {
    @Input() data: Binder | Folder;
    @Input() dueDatesApplied = false;

    constructor(
        private $state: StateService,
        private modalRef: BsModalRef
    ) {}

    redirectToStructurePage(): void {
        if (!this.data) {
            return;
        }
        const state: StateOrName = 'app.team.folder';
        const params: {[key: string]: string;} = { teamId: this.data.teamId };
        if (this.data.type === 'binder') {
            params.binderId = this.data.id;
        }
        else {
            params.binderId = this.data.binderId;
            params.folderId = this.data.id;
        }
        this.$state.go(state, params);
        this.hideModal();
    }

    redirectToCreateStudyProfile(): void {
        if (!this.data) {
            return;
        }
        const params = { teamId: this.data.teamId };
        const state = 'app.team.study-create';
        this.$state.go(state, params);
        this.hideModal();
    }

    hideModal() {
        this.modalRef.hide();
    }

}
