/* eslint-disable @typescript-eslint/naming-convention */
import { Ng2StateDeclaration, StateService, Transition } from '@uirouter/angular';
import { Component } from '@angular/core';
import { TeamService } from '@app/shared/teams/team.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { AppConfigService } from '@app/shared/app-config/app-config.service';
import { Pendo } from '@app/core/analytics/pendo-script-initializer.service.types';
import { Auth0Service } from '@app/shared/sessions/auth0.service';
import { take } from 'rxjs/operators';
import { UsersService } from '@app/shared/users/users.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { HttpHeaders } from '@angular/common/http';
import { AppLayoutComponent } from './containers/app-layout/app-layout.component';


@Component({
    template: `<div class="app_layout__container">
      <ui-view></ui-view>
    </div>
    `
})
export class TeamContainerComponent {
}

export const layoutRoutes: Ng2StateDeclaration[] = [
    {
        name: 'home',
        url: '/',
        resolve: [
            {
                token: 'redirectCheck',
                deps: [StateService, Auth0Service],
                resolveFn: async (
                    $state: StateService,
                    auth0: Auth0Service
                ) => {
                    try {
                        const isLoggedIn = await auth0.isAuthenticated$.pipe(take(1)).toPromise();
                        if (isLoggedIn) {
                            return $state.go('app.select-team');
                        }
                        return $state.go('sign-in');
                    }
                    catch (error) {
                        return $state.go('sign-in');
                    }
                }
            }
        ]
    },
    {
        name: 'app.select-platform',
        url: '/select-platform',
        resolve: [
            {
                token: 'redirect',
                deps: [AppConfigService],
                resolveFn: (appConfigService: AppConfigService) => {
                    window.location.replace(`${appConfigService.config.authHost}/#/app/select-platform`);
                }
            }
        ]
    },
    {
        name: 'app.team',
        url: '/teams/:teamId',
        abstract: true,
        component: TeamContainerComponent,
        resolve: [
            {
                token: 'currentTeam',
                deps: [
                    Transition,
                    TeamService,
                    CurrentSessionService,
                    FeatureFlagService,
                    AppConfigService
                ],
                resolveFn: async (
                    transition: Transition,
                    teamService: TeamService,
                    currentSession: CurrentSessionService,
                    featureFlagService: FeatureFlagService,
                    appConfigService: AppConfigService
                ) => {
                    const { teamId } = transition.params();
                    const team = await teamService.loadById(teamId).toPromise();
                    currentSession.setCurrentTeam(team);

                    featureFlagService.patchContext(team);

                    if (appConfigService.config.pendoEnabled) {
                        const user = currentSession.getCurrentUser();
                        const window = (globalThis as any).window as Window & { pendo: Pendo };

                        window.pendo.initialize({
                            visitor: {
                                id: user.id
                            },
                            account: {
                                id: team.id,
                                teamName: team.name,
                                environment: appConfigService.config.environment,
                                job_title: user.profile.jobTitle,
                                job_function: user.profile.jobFunction,
                                organization_name: user.profile.orgName,
                                organization_type: user.profile.orgType
                            }
                        });
                    }
                    return team;
                }
            }
        ]
    },
    {
        name: 'sign-in',
        url: '/sign-in?&returnTo&userId&sessionExpired&email',
        params: {
            returnTo: '',
            userId: '',
            sessionExpired: '',
            email: ''
        },
        resolve: [
            {
                token: 'redirectTo',
                deps: [
                    Transition,
                    AppConfigService
                ],
                resolveFn: async (transition: Transition, appConfigService: AppConfigService) => {
                    const searchParams = new URLSearchParams();
                    const {
                        returnTo, userId, sessionExpired, email
                    } = transition.params();
                    if (returnTo) {
                        searchParams.set('returnTo', returnTo);
                    }
                    if (userId) {
                        searchParams.set('userId', userId);
                    }
                    if (sessionExpired) {
                        searchParams.set('sessionExpired', sessionExpired);
                    }
                    if (email) {
                        searchParams.set('email', email);
                    }

                    const window = (globalThis as any).window as Window;
                    const paramsString = searchParams.toString();
                    window.open(`${appConfigService.config.authHost}/#/sign-in${paramsString ? `?${paramsString}` : ''}`, '_self');
                }
            }
        ]

    },
    {
        name: 'app',
        url: '/app',
        component: AppLayoutComponent,
        resolve: [
            {
                token: 'loadUser',
                deps: [
                    UsersService,
                    CurrentSessionService,
                    NotificationsService,
                    FeatureFlagService,
                    AppConfigService
                ],
                resolveFn: async (
                    usersService: UsersService,
                    currentSessionService: CurrentSessionService,
                    notificationsService: NotificationsService,
                    featureFlagService: FeatureFlagService,
                    appConfigService: AppConfigService

                ) => {
                    try {
                        const user = await usersService.getCurrent().toPromise();
                        if (!currentSessionService.isRegistrationFinished(user)) {
                            const window = (globalThis as any).window as Window;
                            window.location.replace(`${appConfigService.config.authHost}`);
                        }
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        const headersToAdd = new HttpHeaders({ Range: 'bytes=0-1023' });
                        const headers: string[] = await usersService.getHeaders(headersToAdd).toPromise();
                        const canUsePartialRequests = headers.includes('range');

                        currentSessionService.setCanUsePartialRequests(canUsePartialRequests);
                        currentSessionService.setCurrentUser(user);
                        featureFlagService.initialize(user);
                    }
                    catch (error) {
                        notificationsService.error(error.message);
                    }
                }
            }
        ],
        data: {
            requiresAuth: true
        }
    }
];
