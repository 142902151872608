import {
    Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy
} from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Archive, BrowseTree, Team } from '@app/shared/models';
import { Observable, Subject } from 'rxjs';
import { SelectionState, SelectionStates } from '@app/components/document-sharing/models/checkbox-selection-state.model';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { ArchivesService } from '@app/components/archive/archives.service';
import { TabsDetails } from '@app/components/archive/containers/archive-tabs/archive-tabs.types';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { ConfirmationModal } from '@app/shared/modals/confirmation-modal/confirmation-modal';
import { filter, takeUntil } from 'rxjs/operators';
import { FEATURE_FLAGS } from '../../../core/constants/feature-flags';
import template from './archived-list.component.html';
import styles from './archived-list.component.scss';

@Component({
    selector: 'archived-list',
    template,
    styles: [String(styles)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArchivedListComponent implements OnInit, OnDestroy {
    @Input() archives: Archive[] | null;
    @Input() selectedArchives: Set<Archive['id']>;
    @Input() hasFilterValue: boolean;
    @Output() onArchiveSelection = new EventEmitter<Archive>();
    @Output() refresh = new EventEmitter();
    @Output() toggleAllArchives = new EventEmitter<SelectionStates>();

    @Input()
    selectedTab$: Observable<TabsDetails['heading']>;

    loadingSizes = ['40%', '45%', '30%'];

    currentTeam: Team;

    private readonly destroy$ = new Subject<void>();
    private enableRetentionPeriod = false;

    constructor(
        private CurrentSession: CurrentSessionService,
        private ArchiveService: ArchivesService,
        private Notifications: NotificationsService,
        private Modals: ModalsService,
        private FeatureFlags: FeatureFlagService
    ) {
        this.selectedTab$ = this.ArchiveService.selectedTab$;
    }

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.selectedTab$.pipe(
            takeUntil(this.destroy$),
            filter((tab) => tab !== 'Archived')
        ).subscribe(() => this.toggleAllArchives.emit(SelectionState.NONE));

        this.FeatureFlags
            .getFlag(FEATURE_FLAGS.LTA_RETENTION_PERIOD, false)
            .pipe(
                filter((value) => value !== undefined),
                takeUntil(this.destroy$)
            )
            .subscribe((value) => {
                this.enableRetentionPeriod = value;
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    isArchiveSelected(archive: Archive): boolean {
        return this.selectedArchives.has(archive.id);
    }

    downloadArchive(archive: Archive) {
        return this.ArchiveService.downloadArchive(this.currentTeam.id, archive.archiveId)
            .subscribe({
                next: () => {
                    this.Notifications.success(`Archive for ${archive.type} "${archive.name}" has been downloaded to your computer`);
                },
                error: async ({ error }) => {
                    const err = JSON.parse(await error.text());
                    if (err?.message) {
                        this.Notifications.error(err.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                }
            });
    }

    cancelScheduledArchive(archive: Archive) {
        this.Modals.show(ConfirmationModal, {
            initialState: {
                showModalCloseButton: true,
                confirmationTitle: 'Are you sure you want to cancel this archive?',
                confirmationMessage: `No ${archive.type} will be archived.`,
                cancelButtonText: 'Stay on this page',
                confirmButtonText: 'Cancel Archive',
                handleConfirm: () => {
                    this.ArchiveService.cancelArchive(this.currentTeam.id, archive.archiveId)
                        .subscribe(
                            () => {
                                this.Notifications.success(`Archive for "${archive.name}" is cancelled and removed from this page.`);
                                this.Modals.hide(1);
                                this.refresh.emit();
                            },
                            ({ error }) => {
                                if (error?.message) {
                                    this.Notifications.error(error.message);
                                }
                                else {
                                    this.Notifications.unexpectedError();
                                }
                                this.Modals.hide(1);
                            }
                        );
                }
            }
        });
    }

    selectStatus(): SelectionStates {
        if (this.selectedArchives.size === 0) {
            return SelectionState.NONE;
        }
        if (this.selectedArchives.size === this.archives?.length) {
            return SelectionState.FULL;
        }
        return SelectionState.PARTIAL;
    }

    selectArchive(archive: Archive) {
        this.onArchiveSelection.emit(archive);
    }

    toggleSelectAll() {
        if (this.selectStatus() === SelectionState.FULL) {
            this.toggleAllArchives.emit(SelectionState.NONE);
        }
        else {
            this.toggleAllArchives.emit(SelectionState.FULL);
        }
    }

    generateHrefParams(archive: Archive) {
        if (archive.type === 'binder') {
            return {
                teamId: this.currentTeam.id,
                binderId: archive.id
            };
        }
        return {
            teamId: this.currentTeam.id,
            binderId: archive.binderId,
            folderId: archive.id
        };
    }

    tooltipForDeletedArchivedSource(archive: Archive) {
        return `Archived ${archive.type === 'folder' ? 'folder' : 'binder'} has been deleted`;
    }
}
