import {
    Document, Team, DocumentSubTypes
} from '@app/shared/models';
import { EntityTypeService } from '@app/widgets/entity/entity-type.service';
import { REGEX, MESSAGES } from '@app/core/constants';
import {
    Component, Input, OnInit, Output, EventEmitter
} from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import {
    FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { notBlank } from '@app/core/form-validators';
import template from './document-form.html';
import { SubmitEvent } from './document-form.component.types';

@Component({
    template
})
export class DocumentFormComponent implements OnInit {
    @Input() formType: string;
    @Input() document: Document;
    @Output() submit = new EventEmitter<SubmitEvent>();
    documentForm: FormGroup;
    isProcessing = false;
    isCreatePlaceholder: boolean;
    isRenameDocument: boolean;
    documentType: string;
    instructionText = MESSAGES.validNameMessage;
    documentHasLockedShortcuts = false;
    renameShortcutsTooltipText = 'When checked shortcuts to this document will be renamed with the Document Name + " Shortcut". Leave unchecked to keep existing shortcut names';
    public team: Team

    constructor(
        private fb: FormBuilder,
        public modal: BsModalRef,
        private EntityType: EntityTypeService,
        private CurrentSession: CurrentSessionService
    ) {}

    ngOnInit(): void {
        this.team = this.CurrentSession.getCurrentTeam();
        this.initForm();
        this.setupDocument();
    }

    private setupDocument(): void {
        this.document = this.document || ({ subType: 'placeholder' } as Document);
        this.documentHasLockedShortcuts = (this.document.shortcuts
            && this.document.shortcuts.some((shortcut) => shortcut.isLocked))
            || this.document.hasLockedShortcuts;
        this.documentType = this.EntityType.get(this.document);
        this.isCreatePlaceholder = this.formType === 'create-placeholder';
        this.isRenameDocument = this.formType === 'rename-document';
        this.documentForm.patchValue({ name: this.document.name ?? '' });
    }

    cancel(): void {
        this.modal.hide();
    }

    save(): void {
        if (this.shouldDisableCreate()) {
            return;
        }
        this.isProcessing = true;
        this.submit.emit({
            data: { name: this.documentForm.value.name, renameShortcuts: this.documentForm.value.renameShortcuts }
        });
    }

    shouldDisableCreate(): boolean {
        return this.isProcessing || this.document.name === this.documentForm.value.name || this.documentForm.invalid;
    }

    canRenameShortcuts(): boolean {
        return this.document && this.document.id
            && this.document.subType && this.document.subType !== DocumentSubTypes.shortcut
            && this.document.subType !== DocumentSubTypes.log;
    }

    handleKeyPress(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.save();
        }
    }

    private initForm(): void {
        this.documentForm = this.fb.group({
            name: ['', [Validators.required, Validators.pattern(REGEX.names), notBlank]],
            renameShortcuts: [false, [Validators.required]]
        });
    }
}
