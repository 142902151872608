import {
    Component
} from '@angular/core';

import style from './required-icon.component.scss';

@Component({
    selector: 'required-icon',
    template: `
        <i class="required-asterisk fa fa-fw fa-asterisk text-danger" aria-hidden="true"></i>
        <span class="sr-only"> (required) </span>
    `,
    styles: [String(style)]
})
export class RequiredIconComponent {}
