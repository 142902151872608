import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityModule } from '@app/widgets/entity/entity.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { PageProgressComponent } from './page-progress/page-progress.component';

@NgModule({
    declarations: [
        PageProgressComponent
    ],
    entryComponents: [
        PageProgressComponent
    ],
    imports: [
        CommonModule,
        EntityModule,
        TooltipModule
    ],
    exports: [
        PageProgressComponent
    ],
    providers: [
        { provide: 'Window', useValue: window }
    ]
})
export class PagesModule { }
