import {
    SignatureTypes,
    SigningReasons,
    Document
} from '@app/shared/models';
import { CHECKBOX_STATES } from '@app/core/constants';

export interface DocumentsSignatureDataRowProps {
    method?: SignatureTypes;
    signatureTypeOptions?: Array<SignatureTypes>;
    reason?: SigningReasons;
    rowDataType?: 'all-signers-lack-sign-permission'
    | 'all-signers-have-pending-request'
    | 'file-unsupported'
    | 'shortcut-not-signable';
    hasSignersWithoutPermissions?: boolean;
    hasPendingSignatureRequests?: boolean;
    checked?: CHECKBOX_STATES;
    changed?: boolean;
}

export interface DocumentsTabHeaderRow {
    rowDataType: 'header-row';
    entityPath: {
        type: 'binder' | 'folder' | 'document';
        name: string;
    }[];
}

export type DocumentsSignatureDataRow = Document & DocumentsSignatureDataRowProps & { id: string };

export const isDocumentsTabDataRow = (object): object is DocumentsSignatureDataRow => {
    return object.rowDataType !== 'header-row';
};

export type DocumentsTabDataRow = DocumentsSignatureDataRow | DocumentsTabHeaderRow;
