import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import announcementsRoute from './annoucements.route';
import { AnnouncementsService } from './annoucements.service';
import { AnnouncementViewComponent } from './containers/announcement-view/announcement-view.component';
import { AnnouncementsInboxComponent } from './containers/announcements-inbox/announcements-inbox.component';
import { AnnouncementSendComponent } from './containers/announcement-send/announcement-send.component';
import { AnnouncementFormComponent } from './components/announcement-form/announcement-form.component';
import { AnnouncementsTableComponent } from './components/announcements-table/announcements-table.component';
import { UserRoleSelect } from './components/user-role-select/user-role-select.component';

const dependencies = [uiRouter];

const announcementsModule = angular
    .module('app.announcements', dependencies)
    .config(announcementsRoute)
    .factory('Announcements', downgradeInjectable(AnnouncementsService))
    .directive('announcementView', downgradeComponent({ component: AnnouncementViewComponent }) as ng.IDirectiveFactory)
    .directive('announcementsInbox', downgradeComponent({ component: AnnouncementsInboxComponent }) as ng.IDirectiveFactory)
    .directive('announcementForm', downgradeComponent({ component: AnnouncementFormComponent }) as ng.IDirectiveFactory)
    .directive('announcementSend', downgradeComponent({ component: AnnouncementSendComponent }) as ng.IDirectiveFactory)
    .directive('announcementsTable', downgradeComponent({ component: AnnouncementsTableComponent }) as ng.IDirectiveFactory)
    .directive('userRoleSelect', downgradeComponent({ component: UserRoleSelect }) as ng.IDirectiveFactory);

export default announcementsModule;
