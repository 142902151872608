import {
    Binder, Folder, Document, StudyEntity
} from '@app/shared/models';
import { VirtualTreeFlatNode, VirtualTreeItemSelectedEvent, VirtualTreeSelectionMode } from '@app/widgets/virtual-tree/virtual-tree.component.types';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './study-link-entities.component.html';
import { StudyLinkEntityEvent } from './study-link-entities.component.types';

@Component({
    template
})
export class StudyLinkEntitiesComponent implements OnInit {
    @Input() studyName: string;
    @Input() loadSelected: () => StudyEntity[];
    @Input() loadItem: () => Promise<(Binder | Document | Folder)[]>;
    @Input() loadRoot: () => Binder[];
    @Output() onSubmit = new EventEmitter<StudyLinkEntityEvent>();
    public loadingRoot = false;
    public isProcessing = false;
    public loadingDone = false;
    public items: Binder[];
    public selectionMode = VirtualTreeSelectionMode;
    public selectedItems: VirtualTreeFlatNode[] = [];
    private initiallySelectedItems: VirtualTreeFlatNode[] = [];
    private unchangedInitialySelectedItems: VirtualTreeFlatNode[] = [];
    public actions: {
        added: VirtualTreeFlatNode[];
        removed: VirtualTreeFlatNode[];
    } = { added: [], removed: [] };


    constructor(
        public modal: BsModalRef
    ) {

    }

    ngOnInit(): void {

        this.loadingRoot = true;

        this.items = sortByLexicographically(this.loadRoot(), 'name');
        this.selectedItems = this.loadSelected().map((e) => {
            return { type: e.entityType, id: e.entityId } as VirtualTreeFlatNode;
        });
        this.initiallySelectedItems = [...this.selectedItems];
        this.unchangedInitialySelectedItems = [...this.initiallySelectedItems];

        this.loadingRoot = false;
    }

    cancel(): void {
        this.modal.hide();
    }

    isItemSelectable(): boolean {
        return true;
    }

    isItemDisplayable(item): boolean {
        return item.subType !== 'document';
    }

    onItemSelected($event: VirtualTreeItemSelectedEvent<VirtualTreeFlatNode>): void {
        const wasSelected = this.unchangedInitialySelectedItems.find((i) => i.id === $event.item.id);
        this.actions.removed = this.actions.removed.filter((i) => i.id !== $event.item.id);
        if (!wasSelected) {
            this.actions.added.push($event.item);
        }
    }


    onItemUnselected($event: VirtualTreeItemSelectedEvent<VirtualTreeFlatNode>): void {
        const wasSelected = this.unchangedInitialySelectedItems.find((i) => i.id === $event.item.id);
        this.actions.added = this.actions.added.filter((i) => i.id !== $event.item.id);

        if (wasSelected) {
            this.initiallySelectedItems = this.initiallySelectedItems.filter((i) => i.id !== $event.item.id);
            this.selectedItems = this.initiallySelectedItems.filter((i) => i.id !== $event.item.id);
            this.actions.removed.push($event.item);
        }
    }


    submitDisabled(): boolean {
        return !(this.actions.added.length > 0 || this.actions.removed.length);
    }

    submit(): void {

        if (this.submitDisabled() || this.isProcessing) {
            return;
        }

        const actionsAdded = this.actions.added.length ? this.transformActions(this.actions.added) : [];
        const actionsRemoved = this.actions.removed.length ? this.transformActions(this.actions.removed) : [];

        this.isProcessing = true;
        this.onSubmit.emit({
            add: actionsAdded,
            remove: actionsRemoved,
            onSuccess: () => {
                this.modal.hide();
            },
            onError: () => {
                this.isProcessing = false;
                this.modal.hide();
            }
        });
    }

    private transformActions(actions: VirtualTreeFlatNode[]): { entityId: string; entityType: string }[] {
        return actions.map((a) => {
            return {
                entityId: a.id,
                entityType: a.type
            };
        });
    }
}
