import * as _ from 'lodash';
import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FolderizeTreeItem } from '@app/shared/folders/folders.service.types';
import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { StructureTemplate } from '@app/components/study-startup/components/imported-structures-table/imported-structures-table.component.types';
import template from './choose-structure-template.component.html';
import styles from './choose-structure-template.component.scss';

@Component({
    selector: 'choose-structure-template',
    styles: [String(styles)],
    template
})

export class ChooseStructureTemplateComponent implements OnInit {
    @Input() templates: StructureTemplate[] = [];
    @Input() teamTimezone: string = null;
    @Input() folderizeTreeItems: FolderizeTreeItem[] = [];
    @Input() selectedTemplate: StructureTemplate;
    @Input() selectedTemplateIsLoading = false;
    @Output() templateSelected: EventEmitter<StructureTemplate> = new EventEmitter<StructureTemplate>();

    filteredTemplates: StructureTemplate[] = [];
    filterTemplateControl = new FormControl(null);

    sortedTemplates: StructureTemplate[] = [];

    ngOnInit(): void {
        this.sortedTemplates = sortByLexicographically(_.cloneDeep(this.templates), 'name');
        this.filteredTemplates = this.sortedTemplates;
    }

    filterTemplates(filter: string): void {
        if (!filter) {
            this.filteredTemplates = this.sortedTemplates;
            return;
        }

        const filterCanonical = filter.trim().toLowerCase();
        this.filteredTemplates = this.sortedTemplates.filter((t) => t.name.toLowerCase().includes(filterCanonical));
    }

    onTemplateSelect($event: FilteredSelectEvent<StructureTemplate>): void {
        this.selectedTemplate = $event.added && $event.added.length ? $event.added[0] : null;
        this.templateSelected.emit(this.selectedTemplate);
    }

    onSelectionCleared(): void {
        this.selectedTemplate = null;
        this.templateSelected.emit(this.selectedTemplate);
    }
}
