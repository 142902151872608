import { Component } from '@angular/core';
import template from './doa-log-template-form-skeleton.component.html';
import styles from './doa-log-template-form-skeleton.component.scss';

@Component({
    selector: 'doa-log-template-form-skeleton',
    template,
    styles: [String(styles)]
})
export class DoaLogTemplateFormSkeletonComponent {}
