import * as _ from 'lodash';

const sort = {
    by: 'name',
    defaultIsReversed: false,
    isReversed: false,
    set: function set(columnName, isReversed) {
        if (this.by === columnName) {
            if (_.isUndefined(isReversed)) {
                this.isReversed = !this.isReversed;
            }
            else {
                this.isReversed = isReversed;
            }
        }
        else {
            if (_.isUndefined(isReversed)) {
                this.isReversed = this.defaultIsReversed;
            }
            else {
                this.isReversed = isReversed;
            }
            this.by = columnName;
        }
    },
    naturalSort: function naturalSort(a, b) {
        if (a && b) {
            // cast to strings
            const v1 = `${a.value}`;
            const v2 = `${b.value}`;

            return v1.localeCompare(v2, 'en', { numeric: true })
                // localeCompare returns 0 if values are numerically equal (like 01 and 1)
                // so do a length comparison in this case to make results consistent
                || v1.length - v2.length;
        }

        return 0;
    }
};

export default sort;
