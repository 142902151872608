import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './confirmation-modal.html';
import styles from './confirmation-modal.component.scss';

@Component({
    selector: 'confirmation-modal',
    template,
    styles: [String(styles)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationModal {

  @Input() showModalCloseButton = false;
  @Input() confirmationTitle: string;
  @Input() confirmationMessage: string;
  @Input() cancelButtonText = 'Cancel';
  @Input() confirmButtonText = 'Confirm';
  @Input() handleConfirm: ($event: MouseEvent) => void;

  constructor(public modalRef: BsModalRef) {}
}
