import { StateService } from '@uirouter/angularjs';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { FILTERS } from '@florencehealthcare/florence-constants/lib/filters';
import { Component, OnInit } from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import { AccessDatesService } from '@app/shared/access-dates/access-dates.service';
import { DownloadsService } from '@app/shared/downloads/downloads.service';
import { PaginationPropertiesService } from '@app/shared/pagination-properties/pagination-properties.service';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { AccessDatesReportResponseItem } from '@app/shared/access-dates/access-dates.service.types';
import { Team } from '@app/shared/models/team.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import style from './access-dates-report.component.scss';
import template from './access-dates-report.component.html';
import { FilterTypes, PaginationProperties } from './access-dates-report.component.types';

@Component({
    template,
    styles: [String(style)]
})
export class AccessDatesReportComponent implements OnInit {
    currentDate: Date;
    textFilterTypes: FilterTypes[];
    textFilterSelected: FilterTypes
    items: AccessDatesReportResponseItem[];
    pagination: PaginationProperties;
    team: Team;
    loading: boolean;
    showBoundaryLinkNumbers: boolean;
    isNewAccessDates: boolean;
    filterValue: string;
    maxSize: number;
    debouncedRefreshParams: () => void;
    constructor(
        private currentSessionService: CurrentSessionService,
        private accessDatesService: AccessDatesService,
        private paginationPropertiesService: PaginationPropertiesService,
        private downloadsService: DownloadsService,
        private $state: StateService,
        private notifications: NotificationsService,
        private apiError: ApiErrorsService,
        private featureFlagService: FeatureFlagService,
        private modal: BsModalRef
    ) {
        this.loading = true;
        this.currentDate = new Date();

        this.textFilterTypes = [
            { label: 'Name', type: 'name' },
            { label: 'Role', type: 'role' }
        ];
        [this.textFilterSelected] = this.textFilterTypes;
        this.filterValue = '';
        this.items = [];

        this.showBoundaryLinkNumbers = paginationPropertiesService.showBoundaryLinkNumbers();
        this.maxSize = paginationPropertiesService.getMaxSize();
        this.pagination = {
            pageNum: 1,
            pageSize: 20,
            sortBy: 'end',
            sortDir: 'DESC',
            totalItems: 0
        };

        this.debouncedRefreshParams = this.debounce(this.refreshReportParams, FILTERS.DEBOUNCE_TIME);
    }

    ngOnInit(): void {
        this.featureFlagService.getFlag(FEATURE_FLAGS.NEW_ACCESS_DATES_REPORT, true)
            .subscribe((value) => {
                this.isNewAccessDates = value;
            });

        this.team = this.currentSessionService.getCurrentTeam();
        this.accessDatesService.getAccessDatesReport({ teamId: this.team.id, ...this.pagination })
            .subscribe((res: any) => {
                if (!this.isNewAccessDates) {
                    this.items = res.items;
                    this.pagination.totalItems = res.recordCount;

                }
                else {
                    this.items = res.data;
                    this.pagination.totalItems = res.metadata.total;
                }
                this.loading = false;
            });
    }

    cancel(): void {
        this.modal.hide();
    }

    downloadAccessDates(format) {
        const downloadParams = {
            teamId: this.team.id,
            name: 'Access Dates Report',
            format
        };
        this.downloadsService.downloadAccessDatesReport(downloadParams)
            .subscribe(() => {
                const href = this.$state.href('app.team.downloads', { teamId: this.team.id });
                const message = `<p>Starting download now! We'll notify you when your download is ready.</p>
                    Go to <a class="page-action u-d-inline" href=${href}>MY DOWNLOADS</a> to view all downloads.`;
                this.notifications.info({ message, closeOnClick: false });
            },
            (error) => {
                this.apiError.handleError(error);
            });
    }

    isOngoingDate(date) {
        return new Date(date) >= this.accessDatesService.MAX_DATE;
    }

    daysSinceLastLoginWithRole(loginDate: string) {
        if (!loginDate) {
            return 'N/A';
        }
        const currentDate = new Date();
        const lastLoginDate = new Date(loginDate);
        const timeDifference = currentDate.getTime() - lastLoginDate.getTime();
        const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        return daysPassed;
    }

    debounce(func, delay): (...args: unknown[]) => void {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    }

    refreshReportParams(data) {
        this.loading = true;
        const params = {
            ...this.pagination,
            teamId: this.team.id,
            filterBy: '',
            filterValue: ''

        };

        if (data) {
            params.pageNum = data.page;
            params.pageSize = data.itemsPerPage;
        }

        if (this.filterValue) {
            params.filterValue = this.filterValue;
            params.filterBy = this.textFilterSelected.type;
        }

        this.accessDatesService.getAccessDatesReport(params)
            .subscribe((res: any) => {
                if (!this.isNewAccessDates) {
                    this.items = res.items;
                    this.pagination.totalItems = res.recordCount;
                }
                else {
                    this.items = res.data;
                    this.pagination.totalItems = res.metadata?.total;
                }

                this.loading = false;
            });
    }

    filterKeyPressed() {
        this.pagination.totalItems = 0;
        this.pagination.pageNum = 1;
        this.debouncedRefreshParams();
    }

    handleSort(type) {
        if (this.pagination.sortBy === type) {
            this.pagination.sortDir = this.pagination.sortDir === 'ASC' ? 'DESC' : 'ASC';
        }
        this.pagination.sortBy = type;
        this.debouncedRefreshParams();
    }

    determineStatusString(accessObject) {

        if (this.isNewAccessDates && !accessObject) {
            return 'N/A';
        }

        if (!accessObject.isActive) {
            return 'Inactive';
        }
        if (new Date(accessObject.end) < this.currentDate) {
            return 'Expired';
        }
        if (new Date(accessObject.start) > this.currentDate) {
            return 'Future';
        }

        return 'Active';
    }
}
