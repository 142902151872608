import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import {
    AdapterBinderItem, ConversionState, DeadlineStatus,
    DeadlineType, DueState, ExpirationState,
    ItemState, ProjectsState, ShortcutStatus,
    SignaturesState, TagsState, TasksState, TimelineItemStatus
} from './adapter.service.types';

@Injectable({
    providedIn: 'root'
})
export class AdapterService {
    // TODO: Resolve the type of the item and consider moving this method
    // to projects and timelines feature since is only used there
    adaptBinderItem(item): AdapterBinderItem {
        /**
         * A list of pared down properties - this may need to be expanded depending on future needs.
         * Its not a bad idea to try to limit what properties we actually depend on though.
         */
        return {
            id: item.id,
            name: item.name,
            type: item.type,
            subType: item.subType,
            teamId: item.teamId,
            binderId: item.binderId,
            folderId: item.folderId,
            version: item.version,
            filenameCanonical: item.filenameCanonical,
            path: item.path,
            fullPath: item.fullPath,
            ext: item.ext,
            updatedAt: item.updatedAt,
            updatedBy: item.updatedBy,
            formStatus: item.formStatus,
            isBrokenShortcut: item.isBrokenShortcut,
            state: this._getState(item)
        };
    }

    getConversionState(item): ConversionState | undefined {
        if (item.subType === 'placeholder' || item.originalDocumentSubType === 'placeholder') {
            return undefined;
        }

        const conversionStatus = item.conversionStatusBooleans || {};
        // The global view tree function does not return conversion status
        // booleans so we are duplicating the mapping on the backend. When
        // constants is shareable we can deduplicate this.
        const isPending = _.get(conversionStatus, 'isPending', item.conversionStatus === 'Pending');
        const isConverting = _.get(conversionStatus, 'isConverting', item.conversionStatus === 'In Progress');
        const isFailed = _.get(conversionStatus, 'isFailed', item.conversionStatus === 'Failed');
        const isTooLarge = _.get(conversionStatus, 'isTooLarge', item.conversionStatus === 'Too Large');
        const isUnsupported = _.get(conversionStatus, 'isUnsupported', item.conversionStatus === 'Unsupported File Type');

        const isFailedConversion = isFailed || isTooLarge || isUnsupported;
        const isPendingFormWithNonFailedConversion = item.formStatus === 'checking-form' && !isFailedConversion;
        return {
            inProgress: isConverting || isPending || isPendingFormWithNonFailedConversion,
            isFailed
        };
    }

    getIsPendingConversionState(item): boolean {
        const conversionState: ConversionState = this.getConversionState(item);
        return !!conversionState && conversionState.inProgress;
    }

    getSignatures(item): SignaturesState {
        const signatures = (item && item.signatures) || [];
        return {
            hasPendingSignatures: item.hasPendingSignatures,
            isFullySigned: item.isFullySigned,
            pending: item.pendingSignatures,
            signed: signatures.filter(({ status }) => status === 'Signed'),
            declined: signatures.filter(({ status }) => status === 'Declined')
        };
    }

    getExpirationState(item): ExpirationState | undefined {
        const hasExpirationDate = item.isExpired || item.isAlmostExpired || item.expirationDate;
        if (item.type !== 'document'
            || item.subType === 'placeholder'
            || item.originalDocumentSubType === 'placeholder'
            || !hasExpirationDate) {
            return undefined;
        }

        let status = DeadlineStatus.onTrack;
        if (item.isExpired) {
            status = DeadlineStatus.due;
        }
        else if (item.isAlmostExpired) {
            status = DeadlineStatus.almostDue;
        }

        return {
            type: DeadlineType.expiration,
            status,
            date: item.expirationDate
        };
    }

    getDueState(item): DueState | undefined {
        const hasDueDate = item.isDue || item.isAlmostDue || item.dueDate;
        if ((item.subType !== 'placeholder' && item.originalDocumentSubType !== 'placeholder') || !hasDueDate) {
            return undefined;
        }

        let status = DeadlineStatus.onTrack;
        if (item.isDue) {
            status = DeadlineStatus.due;
        }
        else if (item.isAlmostDue) {
            status = DeadlineStatus.almostDue;
        }

        return {
            type: DeadlineType.due,
            status,
            date: item.dueDate
        };
    }

    private _getState(item): ItemState {
        return {
            conversion: this.getConversionState(item),
            due: this.getDueState(item),
            expiration: this.getExpirationState(item),
            projects: this._getProjectsState(item),
            signatures: this.getSignatures(item),
            tasks: this._getTasksState(item),
            tags: this._getTagsState(item),
            // TODO: generalize 'timelineItemStatus' to 'timelines' with a hash by timeline id of the statuses
            timelineItemStatus: this._getTimelineItemStatus(item),
            shortcutStatus: this._getShortcutStatus(item)
        };
    }

    private _getTimelineItemStatus(item): TimelineItemStatus {
        return {
            completionDate: item.completionDate,
            dateRawFileUploaded: item.dateRawFileUploaded,
            normalizedCompletionDate: item.completionDate || item.dateRawFileUploaded
        };
    }

    private _getProjectsState(item): ProjectsState {
        return {
            projects: item.projects || []
        };
    }

    private _getShortcutStatus(item): ShortcutStatus {
        let isBrokenShortcut: boolean;
        if (item.type === 'document' && item.subType === 'shortcut') {
            isBrokenShortcut = item.isBrokenShortcut;
        }
        return { isBrokenShortcut };
    }

    private _getTagsState(item): TagsState {
        return {
            tags: item.tags
        };
    }

    private _getTasksState(item): TasksState {
        return {
            hasPendingTasks: item.hasPendingTasks,
            pending: item.pendingTasks
        };
    }
}
