import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnChanges
} from '@angular/core';

@Component({
    selector: 'subject-icon',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <span class="subject-icon">
        <i class="fa fa-fw"
           [ngClass]="{
               'fa-user': isUser,
               'fa-user-md': isRole,
               'fa-lg': large
            }"
           [tooltip]='subjectType | titlecase'
           placement="bottom">
        </i>
    </span>
  `
})
export class SubjectIconComponent implements OnChanges {
    public isUser: boolean;
    public isRole: boolean;

    @Input() large?: string;
    @Input() subjectType: string;

    ngOnChanges(): void {
        this.isUser = this.subjectType === 'user';
        this.isRole = this.subjectType === 'role';
    }
}
