import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaceSpecialCharsNG2'
})
export class ReplaceSpecialCharsPipeNG2 implements PipeTransform {
    transform(value = ''): string {
        return value.replace(/\W+/g, '');
    }
}
