import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from '@angular/core';
import { EventData, MonitorReviewEvent } from '@app/shared/models/monitor-review-event';
import {
    monitorReviewEvents
} from '@florencehealthcare/florence-constants';
import { Observable, Subject } from 'rxjs';
import template from './monitor-review-events.component.html';
import styles from './monitor-review-events.component.scss';

@Component({
    selector: 'monitor-review-events',
    template,
    styles: [String(styles)]
})

export class MonitorReviewEventsComponent implements OnChanges {

    @Input() events: MonitorReviewEvent[];
    @Input() hasNext: boolean;
    @Input() eventsReset$: Observable<any>;
    @Output() fetchMoreEvents = new EventEmitter<void>();

    isProcessing = false;

    ngOnChanges() {
        this.isProcessing = false;
    }

    getMoreEvents() {
        if (!this.hasNext || this.isProcessing) {
            return;
        }
        this.fetchMoreEvents.emit();
    }

    eventToLabel(eventData: EventData) {
        switch (eventData.eventType) {
            case monitorReviewEvents.monitorReviewEventTypes.APPROVAL:
                return 'Document Approved';
            case monitorReviewEvents.monitorReviewEventTypes.REVIEWED:
                return 'Document Reviewed';
            case monitorReviewEvents.monitorReviewEventTypes.STATUS_CHANGE:
                return `${eventData.status} Query`;
            case monitorReviewEvents.monitorReviewEventTypes.NEW_DOCUMENT_VERSION:
                return `New Version: V${eventData.documentVersion}`;
            case monitorReviewEvents.monitorReviewEventTypes.COMMENT:
            default:
                return eventData.eventType;
        }
    }

    getListOfNotifiedUsers(event: MonitorReviewEvent) {
        const notifiedUsers = [];
        event.notificationRecipients.forEach((recipient) => {
            notifiedUsers.push(recipient.fullName);
        });
        return notifiedUsers.join(', ');
    }
}
