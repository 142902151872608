import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CurrentSessionService } from '@app/core/current-session.service';
import { SearchResults } from './search.service.types';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    currentTeam = this.CurrentSession.getCurrentTeam();

    private readonly url = {
        search: '/api/search/index'
    };

    constructor(
        private http: HttpClient,
        private CurrentSession: CurrentSessionService
    ) { }

    getBindersFolders(searchTerm: string): Observable<SearchResults> {
        return this.http.post<SearchResults>(`${this.url.search}`, { searchTerm, teamIds: [this.currentTeam?.id] });
    }

}
