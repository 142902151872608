import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { Observable, throwError } from 'rxjs';
import { CurrentSessionService } from '@app/core/current-session.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { Team } from '../models';

@Injectable()
export class ApiErrorsService {
  private currentTeam: Team;
  private lastRedirectHash = {};

  constructor(
    private $state: StateService,
    private CurrentSession: CurrentSessionService,
    private Notifications: NotificationsService
  ) {
      this.CurrentSession.currentTeam$.subscribe((team) => {
          this.currentTeam = team;
      });
  }

  private redirectOnError(): void {
      if (this.currentTeam && !this.hasRecentlyRedirected('app.team.binders')) {
          this.$state.go('app.team.binders', { teamId: this.currentTeam.id });
          this.recordRedirect('app.team.binders');
      }
      else if (!this.hasRecentlyRedirected('sign-in')) {
          this.$state.go('sign-in');
          this.recordRedirect('sign-in');
      }
  }

  private recordRedirect(state: string): void {
      this.lastRedirectHash[state] = Date.now();
  }

  private hasRecentlyRedirected(state: string, threshold = 350): boolean {
      return this.lastRedirectHash[state] && (Date.now() - this.lastRedirectHash[state]) < threshold;
  }

  private displayApiError(errMessage: string): void {
      const message = errMessage || 'There was an unexpected error. Please try again. If the error still happens, please contact your administrator.';
      this.Notifications.error(message);
  }

  parseErrorMessage(apiError: HttpErrorResponse | Record<string, never> = {}): string {
      const errorMessagePaths = ['error.output.payload.message', 'error.data.message', 'error.message', 'message'];
      return errorMessagePaths.reduce((msg, path) => msg || _.get(apiError, path, ''), '');
  }

  handleError(apiError: HttpErrorResponse): Observable<never> {
      const msg = this.parseErrorMessage(apiError || {});
      this.displayApiError(msg);
      if (apiError && apiError.status === 403) {
          const passwordExpiredHeader = apiError.headers?.get('x-fhc-password-expired');
          if (!passwordExpiredHeader) {
              this.redirectOnError();
          }
      }
      return throwError(apiError);
  }

  handleErrorWithoutRedirectOn403(apiError: HttpErrorResponse): Observable<never> {
      const msg = this.parseErrorMessage(apiError || {});
      this.displayApiError(msg);
      return throwError(apiError);
  }
}
