import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IChipIcon } from './types/i-chip-icon';

import styles from './chip.component.scss';
import template from './chip.component.html';

@Component({
    selector: 'chip',
    template,
    styles: [String(styles)]
})
export class ChipComponent implements OnInit {
    @Input() text: string;

    @Input() color = '#F1F4F6';

    @Input() tooltipText = ''

    @Input() leadingIcon?: IChipIcon;

    @Input() trailingIcon?: IChipIcon;

    private isOverflowing = new BehaviorSubject<boolean>(false)

    tooltipDisplayText$: Observable<string>

    ngOnInit(): void {
        this.tooltipDisplayText$ = this.initializeTooltipDisplayText();
    }

    handleOverflow(isOverflowing: boolean): void {
        this.isOverflowing.next(isOverflowing);
    }

    private initializeTooltipDisplayText(): Observable<string> {
        return this.isOverflowing.asObservable().pipe(
            map((isOverflowing) => {
                return this.formattedTooltipDisplayText(isOverflowing);
            })
        );
    }

    private formattedTooltipDisplayText(isOverflowing: boolean): string {
        if (isOverflowing && !!this.tooltipText) {
            return this.text.concat(': ', this.tooltipText);
        } if (isOverflowing && !this.tooltipText) {
            return this.text;
        } if (!isOverflowing && !!this.tooltipText) {
            return this.tooltipText;
        }
        return '';

    }
}
