import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateLogTemplateRequestBody, LogTemplate } from '../models/log-template.model';
import {
    GetLogTemplatesParams,
    GetLogTemplatesResponse,
    MarkLogTemplatesPayload
} from './log-templates.service.types';
import { BatchResponseItem } from '../models';

@Injectable()
export class LogTemplatesService {
    private readonly url = {
        logTemplates: (teamId: string): string => `/api/teams/${teamId}/log-templates`,
        markLogTemplates: (teamId: string): string => `/api/teams/${teamId}/log-templates/mark`,
        logTemplate: (teamId: string, logTemplateId: string): string => `/api/teams/${teamId}/log-templates/${logTemplateId}`,
        logTemplateVersion: (teamId: string, logTemplateId: string, version: number): string => `/api/teams/${teamId}/log-templates/${logTemplateId}/versions/${version}`,
        logTemplateClone: (teamId: string, logTemplateId: string): string => `/api/teams/${teamId}/log-templates/${logTemplateId}/clone`
    }

    constructor(private http: HttpClient) { }

    getLogTemplates(teamId: string, getLogTemplatesParams: GetLogTemplatesParams):
        Observable<GetLogTemplatesResponse> {
        return this.http.get<GetLogTemplatesResponse>(this.url.logTemplates(teamId), {
            params: {
                ...getLogTemplatesParams,
                ...getLogTemplatesParams.pageNum && {
                    pageNum: String(getLogTemplatesParams.pageNum)
                },
                pageSize: String(getLogTemplatesParams.pageSize),
                filter: JSON.stringify(getLogTemplatesParams.filter)
            }
        });
    }

    getLogTemplate(teamId: string, logTemplateId: string): Observable<LogTemplate> {
        return this.http.get<LogTemplate>(this.url.logTemplate(teamId, logTemplateId));
    }

    getLogTemplateVersion(teamId: string, logTemplateId: string, version: number): Observable<LogTemplate> {
        return this.http.get<LogTemplate>(this.url.logTemplateVersion(teamId, logTemplateId, version));
    }

    createLogTemplate(teamId: string, logTemplate: CreateLogTemplateRequestBody): Observable<LogTemplate> {
        return this.http.post<LogTemplate>(this.url.logTemplates(teamId), logTemplate);
    }

    cloneLogTemplate(teamId: string, logTemplateId: string, newName: string): Observable<LogTemplate> {
        return this.http.post<LogTemplate>(this.url.logTemplateClone(teamId, logTemplateId), { newName });
    }

    updateLogTemplate(teamId: string, logTemplateId: string, logTemplate: CreateLogTemplateRequestBody): Observable<LogTemplate> {
        return this.http.patch<LogTemplate>(this.url.logTemplate(teamId, logTemplateId), logTemplate);
    }

    markLogTemplates(teamId: string, payload: MarkLogTemplatesPayload): Observable<BatchResponseItem<LogTemplate>[]> {
        return this.http.post<BatchResponseItem<LogTemplate>[]>(this.url.markLogTemplates(teamId), payload);
    }

    isLogTemplateNameUnique$(teamId: string, name: string, templateId?: string): Observable<boolean> {
        const params: GetLogTemplatesParams = {
            pageSize: 1,
            filter: { name, exactName: true }
        };

        return this.getLogTemplates(teamId, params).pipe(
            map((response) => {
                const filteredItems = response.items.filter((item) => item.id.templateId !== templateId);

                return filteredItems.length === 0;
            })
        );
    }
}
