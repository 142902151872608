import { Component, Input } from '@angular/core';

@Component({
    selector: 'name-email-block',
    template: `
    <div class="name-email-block">
        <div *ngIf="name" class="test-name">{{ name }}</div>
        <div class="test-email">{{ email }}</div>
    </div>
    `
})
export class NameEmailBlockComponent {
    @Input() email: string;
    @Input() name: string;
}
