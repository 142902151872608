import { Archive, ArchiveStatus, Binder } from '@app/shared/models';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './destroy-binder.component.html';
import styles from './destroy-binder.component.scss';
import { DestroyEvent } from './destroy-binder.component.types';

@Component({
    selector: 'destroy-binder',
    template,
    styles: [String(styles)]
})
export class DestroyBinderComponent implements OnInit {
    isProcessing = false;
    reason = '';
    @Input() binder: Binder;
    @Input() archive: Archive;
    @Output() destroy = new EventEmitter<DestroyEvent>();
    form: FormGroup;

    constructor(
        private fb: FormBuilder,
        public modal: BsModalRef
    ) {}

    ngOnInit(): void {
        this.initForm();
    }

    destroyBinder(): void {
        this.isProcessing = true;

        const reason = this.form.controls.reason && this.form.controls.reason.value;
        this.destroy.emit({
            data: { binder: this.binder, reason }
        });
    }

    cancel(): void {
        this.modal.hide();
    }

    ctrlHasError(ctrl: AbstractControl): boolean {
        return ctrl.dirty && ctrl.value !== this.binder.name;
    }

    get canDisplayScheduledText() {
        return this.archive && this.archive.status === ArchiveStatus.scheduled;
    }

    get canDisplayArchivedText() {
        return this.archive && this.archive.status === ArchiveStatus.complete;
    }

    get binderFormCtrls(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    private initForm(): void {
        this.form = this.fb.group({
            name: ['', [Validators.required, notBlank]],
            reason: ['', [Validators.required, notBlank, Validators.maxLength(250)]]
        });
    }
}
