import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {
    transform(text: string, length?: number): string {
        if (length === undefined) {
            return text;
        }

        if (text.length > length) {
            return `${text.substring(0, length)}...`;
        }
        return text;
    }
}
