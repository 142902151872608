import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';

import { CHECKBOX_STATES } from '@app/core/constants';
import { SignatureTypes, SigningReasons, Team } from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';

import template from './documents-selected-tab.component.html';
import styles from './documents-selected-tab.component.scss';
import { DocumentsSignatureDataRow, DocumentsTabDataRow } from './documents-selected-tab.types';


@Component({
    selector: 'documents-selected-tab',
    template,
    styles: [String(styles)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsSelectedTabComponent implements OnInit, OnChanges {
    @Output() selectDocuments = new EventEmitter<void>();
    @Output() removeDocuments = new EventEmitter<string[]>();
    @Output() selectSignatureTypes = new EventEmitter<{ type: SignatureTypes; ids: string[] }>();
    @Output() selectReasons = new EventEmitter<{ reason: SigningReasons; ids: string[] }>();
    @Input() data: DocumentsTabDataRow[] = [];
    documentCount: number;

    documentsHaveFixedSignatureType: boolean;
    someSignersHavePendingRequests: boolean;
    someSignersLackSignPermissions: boolean;

    bulkMode = false;
    headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
    checkedIds: Set<string> = new Set();
    signingReasons = Object.values(SigningReasons).sort();
    bulkReason: SigningReasons;
    bulkSignatureType: SignatureTypes;
    bulkSignatureTypeOptions: string[];
    currentTeam: Team;

    constructor(private CurrentSession: CurrentSessionService) {}

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        if (this.currentTeam.settings.signatures.disableAddendum) {
            this.bulkSignatureTypeOptions = [SignatureTypes.stamp];
        }
        else if (this.currentTeam.settings.signatures.disableAnnotation) {
            this.bulkSignatureTypeOptions = [SignatureTypes.addendum];
        }
        else {
            this.bulkSignatureTypeOptions = [
                SignatureTypes.any,
                SignatureTypes.addendum,
                SignatureTypes.stamp
            ];
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this.documentCount = this.data.filter((d: DocumentsSignatureDataRow) => d.type === 'document').length;
            this.refreshCheckedIds();
            if (!(this.data && this.data.length)) {
                this.bulkMode = false;
            }
            this.someSignersHavePendingRequests = this.data.some((d: DocumentsSignatureDataRow) => {
                return d.rowDataType === 'all-signers-have-pending-request'
                    || d.hasPendingSignatureRequests;
            });
            this.someSignersLackSignPermissions = this.data.some((d: DocumentsSignatureDataRow) => {
                return d.rowDataType === 'all-signers-lack-sign-permission'
                    || d.hasSignersWithoutPermissions;
            });
            this.documentsHaveFixedSignatureType = this.data.some((d: DocumentsSignatureDataRow) => {
                return d.signatureTypeOptions && d.signatureTypeOptions.length === 1;
            });
        }
    }

    private refreshCheckedIds(): void {
        this.checkedIds.clear();
        this.data.forEach((d: DocumentsSignatureDataRow) => {
            if (d.checked === CHECKBOX_STATES.SELECTED) {
                this.checkedIds.add(d.id);
            }
        });
        this.handleHeaderCheckboxStateOnRowChange();
    }

    private applyBulkValuesIfPresent(row?: DocumentsSignatureDataRow): void {
        if (!this.bulkMode) {
            return;
        }
        if (this.bulkReason) {
            this.onSignatureReasonSelect(this.bulkReason, row && row.id as string);
        }
        if (this.bulkSignatureType) {
            this.onSignatureTypeSelect(this.bulkSignatureType, row && row.id as string);
        }
    }

    toggleCheckedAllRows(): void {
        if (
            this.headerCheckboxState === CHECKBOX_STATES.NOT_SELECTED
            || this.headerCheckboxState === CHECKBOX_STATES.PARTIALLY_SELECTED
        ) {
            this.headerCheckboxState = CHECKBOX_STATES.SELECTED;
            this.data.forEach((d: DocumentsSignatureDataRow) => {
                if (d.type === 'document') {
                    d.checked = this.headerCheckboxState;
                    this.checkedIds.add(d.id as string);
                }
            });
        }
        else {
            this.headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
            this.data.forEach((d: DocumentsSignatureDataRow) => {
                if (d.type === 'document') {
                    d.checked = this.headerCheckboxState;
                    this.checkedIds.delete(d.id as string);
                }
            });
        }

        this.applyBulkValuesIfPresent();
    }

    private handleHeaderCheckboxStateOnRowChange(): void {
        if (!this.checkedIds.size) {
            this.headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
        }
        else if (this.checkedIds.size === this.documentCount) {
            this.headerCheckboxState = CHECKBOX_STATES.SELECTED;
        }
        else {
            this.headerCheckboxState = CHECKBOX_STATES.PARTIALLY_SELECTED;
        }
    }

    toggleCheckedRow($event: DocumentsSignatureDataRow): void {
        if ($event.checked && $event.checked === CHECKBOX_STATES.SELECTED) {
            $event.checked = CHECKBOX_STATES.NOT_SELECTED;
            this.checkedIds.delete($event.id as string);
        }
        else {
            $event.checked = CHECKBOX_STATES.SELECTED;
            this.checkedIds.add($event.id as string);
        }
        this.handleHeaderCheckboxStateOnRowChange();
        this.applyBulkValuesIfPresent($event);
    }

    onSignatureTypeSelect(type: SignatureTypes, id?: string): void {
        let ids = [id];
        if (!id) {
            this.bulkSignatureType = type;
            ids = Array.from(this.checkedIds);
        }

        if (!(ids && ids.length)) {
            return;
        }
        this.selectSignatureTypes.emit({ type, ids });
    }

    onSignatureReasonSelect(reason: SigningReasons, id?: string): void {
        let ids = [id];
        if (!id) {
            this.bulkReason = reason;
            ids = Array.from(this.checkedIds);
        }
        if (!(ids && ids.length)) {
            return;
        }
        this.selectReasons.emit({ reason, ids });
    }

    onDocumentsRemove(): void {
        const ids = Array.from(this.checkedIds);
        if (!(ids && ids.length)) {
            return;
        }
        this.removeDocuments.emit(ids);
        this.checkedIds.clear();
        this.headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
    }

    toggleBulkMode(): void {
        this.bulkMode = !this.bulkMode;
    }
}
