import { StateProvider } from '@uirouter/angularjs';

const globalViewRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.global-view', {
        url: '/global-view/:objectId/:objectType/:objectName',
        views: {
            '': 'globalView'
        },
        params: {
            objectId: '',
            objectType: '',
            objectName: '',
            isRootReselected: false
        }
    });
};

globalViewRoute.$inject = ['$stateProvider'];

export default globalViewRoute;
