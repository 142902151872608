import { NgModule } from '@angular/core';
import { SystemNotificationComponent } from './containers/system-notification/system-notification.component';
import { ModalsModule } from '../../widgets/modals/modals.module';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
    imports: [
        ModalsModule,
        SharedModule
    ],
    providers: [
    ],
    declarations: [
        SystemNotificationComponent
    ],
    entryComponents: [
        SystemNotificationComponent
    ],
    exports: [
    ]
})
export class SystemNotificationsModule {}
