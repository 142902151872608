import {
    Component,
    Output,
    EventEmitter,
    Input
} from '@angular/core';

import styles from './modal-close-button.component.scss';

@Component({
    selector: 'modal-close-button',
    template: `
    <button class="btn btn-link fa fa-times modal-close-btn u-z-index-100 test-close-modal-button"
        role="button"
        (click)="onClose()"
        aria-label="Close"
        [disabled]="isDisabled">
    </button>
    `,
    styles: [String(styles)]
})
export class ModalCloseButtonComponent {

    @Input() isDisabled = false;
    @Output() close = new EventEmitter<null>();

    onClose(): void {
        this.close.emit();
    }
}
