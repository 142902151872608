import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
    catchError, filter, shareReplay, switchMap, tap
} from 'rxjs/operators';
import { CurrentSessionService } from '@app/core/current-session.service';
import { TeamStudyRole } from '@app/components/teams/components/manage-study-roles/manage-study-roles.types';
import { StudyRoleIdName, StudyRolesMap, StudyTeamPreview } from './study-roles.types';
import { StudyRolesApiService } from './study-roles-api/study-roles-api.service';
import { NotificationsService } from '../../core/notifications/notifications.service';
import { MESSAGES } from '../../core/constants';

@Injectable()
export class StudyRolesService {
    private teamId = new BehaviorSubject<string | null>(null);
    teamId$ = this.teamId.asObservable();

    constructor(
        private studyRolesApiService: StudyRolesApiService,
        private notificationsService: NotificationsService,
        private currentSessionService: CurrentSessionService
    ) {
        this.currentSessionService.currentTeam$.pipe(
            tap((team) => {
                if (team?.id) {
                    this.teamId.next(team.id);
                }
            })
        ).subscribe();
    }

    private predefinedStudyRolesList = new BehaviorSubject<StudyRoleIdName[]>([]);
    predefinedStudyRolesList$ = this.predefinedStudyRolesList.asObservable();

    private studyRolesList = new BehaviorSubject<StudyRoleIdName[]>([]);
    studyRolesList$ = this.studyRolesList.asObservable();

    private predefinedStudyRolesMap = new BehaviorSubject<StudyRolesMap>(new Map());
    predefinedStudyRolesMap$ = this.predefinedStudyRolesMap.asObservable();

    private studyRolesMap = new BehaviorSubject<StudyRolesMap>(new Map());
    studyRolesMap$ = this.studyRolesMap.asObservable();

    isLoadingStudyRoles$ = this.studyRolesApiService.isLoadingStudyRoles$;

    setPredefinedStudyRolesList$: Observable<StudyRoleIdName[]> = this.studyRolesApiService.getStudyRoles$().pipe(
        tap((studyRolesList) => {
            const studyRoleTuples = studyRolesList
                .map((studyRole) => [studyRole._id, studyRole.name]) as Iterable<[string, string]>;

            this.predefinedStudyRolesMap.next(new Map(studyRoleTuples));
            this.predefinedStudyRolesList.next(studyRolesList);
        }),
        catchError(() => {
            this.notificationsService.error(MESSAGES.failedToLoad('study roles list'));
            return of(null);
        }),
        shareReplay(1)
    );


    setStudyRolesList$: Observable<StudyRoleIdName[] | TeamStudyRole[]> = this.teamId$.pipe(
        filter((teamId) => !!teamId),
        switchMap((teamId) => this.studyRolesApiService.getTeamStudyRoles$(teamId as string)),
        tap((studyRolesList) => {
            const transformedStudyRolesList: StudyRoleIdName[] = studyRolesList
                .map((studyRole) => ({ _id: studyRole.studyRoleId, name: studyRole.name, teamId: studyRole.teamId }));

            const studyRoleTuples = transformedStudyRolesList
                .map((studyRole) => [studyRole._id, studyRole.name]) as Iterable<[string, string]>;

            this.studyRolesList.next(transformedStudyRolesList);
            this.studyRolesMap.next(new Map(studyRoleTuples));
        }),
        catchError(() => {
            this.notificationsService.error(MESSAGES.failedToLoad('study roles list'));
            return of([]);
        })
    );


    getStudyRoleNameById(studyRoleId: StudyRoleIdName['_id']): string {
        return this.studyRolesMap.getValue().get(studyRoleId);
    }

    getFilePreview(teamId: string, file: File): Observable<StudyTeamPreview[]> {
        return this.studyRolesApiService.getFilePreview$(teamId, file);
    }

    getTeamStudyRoles(teamId: string): Observable<TeamStudyRole[]> {
        return this.studyRolesApiService.getTeamStudyRoles$(teamId);
    }

    createTeamStudyRoles(teamId: string, names: string[]): Observable<TeamStudyRole[]> {
        return this.studyRolesApiService.createTeamStudyRoles$(teamId, names);
    }

    updateTeamStudyRole(teamId: string, teamStudyRoleId: string, name: string): Observable<TeamStudyRole> {
        return this.studyRolesApiService.updateTeamStudyRole$(teamId, teamStudyRoleId, name);
    }

    deleteTeamStudyRole(teamId: string, teamStudyRoleId: string): Observable<{ acknowledged: boolean, deletedCount: number }> {
        return this.studyRolesApiService.deleteTeamStudyRole$(teamId, teamStudyRoleId);
    }
}
