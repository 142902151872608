import { Component, Input } from '@angular/core';
import { ApplyDueDatesComponent } from '@app/components/study-startup/containers/apply-due-dates/apply-due-dates.component';
import { AssignStructureTemplateResponse } from '@app/components/study-startup/study-startup.service.types';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './structure-template-applied-due-dates.component.html';
import styles from './structure-template-applied-due-dates.component.scss';

@Component({
    template,
    styles: [String(styles)]
})
export class StructureTemplateAppliedDueDatesComponent {
    @Input() data: AssignStructureTemplateResponse;
    @Input() showStructureTemplateAssignedModal = false;

    constructor(
        private modalRef: BsModalRef,
        private Modals: ModalsService
    ) { }

    hideModal(): void {
        this.modalRef.hide();
    }

    applyDueDates(): void {
        this.hideModal();
        this.Modals.show(ApplyDueDatesComponent, {
            class: 'modal-lg',
            initialState: {
                assignedStructure: this.data,
                showStructureTemplateAssignedModal: this.showStructureTemplateAssignedModal
            }
        });
    }
}
