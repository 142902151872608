import * as angular from 'angular';

import { downgradeComponent } from '@angular/upgrade/static';

import { SubjectIconComponent } from './subject-icon/subject-icon.component';
import { RequiredIconComponent } from './required-icon/required-icon.component';
import { ProjectIconComponent } from './projects-icon/projects-icon.component';
import { DeadlineIconComponent } from './deadline-icon/deadline-icon.component';
import { ArchiveIconComponent } from './archive-icon/archive-icon.component';
import { FileLockIconComponent } from './file-lock-icon/file-lock-icon.component';

const dependencies = [
];

const icons = angular
    .module('app.widgets.icons', dependencies)
    .directive('deadlineIcon', downgradeComponent({ component: DeadlineIconComponent }) as ng.IDirectiveFactory)
    .directive('projectsIcon', downgradeComponent({ component: ProjectIconComponent }) as ng.IDirectiveFactory)
    .directive('requiredIcon', downgradeComponent({ component: RequiredIconComponent }) as ng.IDirectiveFactory)
    .directive('subjectIcon', downgradeComponent({ component: SubjectIconComponent }) as ng.IDirectiveFactory)
    .directive('archiveIcon', downgradeComponent({ component: ArchiveIconComponent }) as ng.IDirectiveFactory)
    .directive('fileLockIcon', downgradeComponent({ component: FileLockIconComponent }) as ng.IDirectiveFactory);

export default icons;
