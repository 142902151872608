import {
    Component, Input
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import template from './choose-binder-options.component.html';
import styles from './choose-binder-options.component.scss';

@Component({
    selector: 'choose-binder-options',
    template,
    styles: [String(styles)]
})

export class ChooseBinderOptionsComponent {
    @Input() hasExistingBinders: boolean;
    @Input() assignStructureForm: FormGroup;

}
