import {
    Component, Input, OnInit, Output, EventEmitter
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Binder, Folder } from '@app/shared/models';
import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import * as _ from 'lodash';
import template from './choose-existing-option.component.html';
import styles from './choose-existing-option.component.scss';

@Component({
    selector: 'choose-existing-option',
    styles: [String(styles)],
    template
})

export class ChooseExistingOptionComponent implements OnInit {
    @Input() isBinder: boolean;
    @Input() dataList: (Binder | Folder) [] = [];
    @Input() previouslySelectedItem: Binder | Folder;
    @Output() selectedExistingItem: EventEmitter<(Binder| Folder)> = new EventEmitter<(Binder| Folder)>();
    isFilteringData: boolean;
    filteredList: (Binder | Folder) [] ;
    filterItemControl = new FormControl(null);

    sortedData: (Binder | Folder) [] = [];

    ngOnInit(): void {
        this.sortedData = sortByLexicographically(_.cloneDeep(this.dataList), 'name');
        this.filteredList = this.sortedData;
    }

    onFilterInputChange(filter: string): void {
        this.filteredList = _.filter(this.sortedData, (item) => {
            return item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
        });
    }

    setSelectedItem($event: FilteredSelectEvent<Binder>): void {
        this.selectedExistingItem.emit($event.added[0]);
    }

    onClearOfSelectedItem(): void {
        this.selectedExistingItem.emit(null);
    }

}
