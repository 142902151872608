import { VirtualTreeFlatNode } from '@app/widgets/virtual-tree/virtual-tree.component.types';

export const documentShareVersionLimit = 15;

export const DocumentShareVersionOptions = {
    LATEST_VERSION: 'latestVersion',
    ALL_VERSIONS: 'allVersions'
} as const;

export type DocumentShareSelectedVersionOption = keyof typeof DocumentShareVersionOptions;

export type VersionedVirtualTree = VirtualTreeFlatNode & { documentShareVersion: DocumentShareSelectedVersionOption };
