// This file exists because date-time-picker doesn't have the option to work with
// UTC dates exclusively.

import { moveRelativeToUTC } from './move-relative-to-utc';

/**
 * Create dateB from dateA so that dateB local time is the same as
 * dateA UTC time.
 * This way, when dateB is displayed it will have the value of
 * dateA UTC time.
 * In other words, converting from UTC to local is counteracted.
 * @param {Date|string} value - dateA
 * @returns {Date|string} - dateB
 */
export const moveTowardsUTC = (value: Date | string): Date | string => {
    return moveRelativeToUTC(value, +1);
};
