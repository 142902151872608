
var content = require("!!../../../../../../../node_modules/.pnpm/css-loader@2.1.1_webpack@4.47.0/node_modules/css-loader/dist/cjs.js!../../../../../../../node_modules/.pnpm/postcss-loader@3.0.0/node_modules/postcss-loader/src/index.js??postcss!../../../../../../../node_modules/.pnpm/resolve-url-loader@3.1.5/node_modules/resolve-url-loader/index.js!../../../../../../../node_modules/.pnpm/sass-loader@10.5.2_sass@1.77.8_sass@1.77.8_webpack@4.47.0/node_modules/sass-loader/dist/cjs.js!./global-search.component.scss");

if(typeof content === 'string') content = [[module.id, content, '']];

var transform;
var insertInto;



var options = {"hmr":true}

options.transform = transform
options.insertInto = undefined;

var update = require("!../../../../../../../node_modules/.pnpm/style-loader@0.23.1/node_modules/style-loader/lib/addStyles.js")(content, options);

if(content.locals) module.exports = content.locals;

if(module.hot) {
	module.hot.accept("!!../../../../../../../node_modules/.pnpm/css-loader@2.1.1_webpack@4.47.0/node_modules/css-loader/dist/cjs.js!../../../../../../../node_modules/.pnpm/postcss-loader@3.0.0/node_modules/postcss-loader/src/index.js??postcss!../../../../../../../node_modules/.pnpm/resolve-url-loader@3.1.5/node_modules/resolve-url-loader/index.js!../../../../../../../node_modules/.pnpm/sass-loader@10.5.2_sass@1.77.8_sass@1.77.8_webpack@4.47.0/node_modules/sass-loader/dist/cjs.js!./global-search.component.scss", function() {
		var newContent = require("!!../../../../../../../node_modules/.pnpm/css-loader@2.1.1_webpack@4.47.0/node_modules/css-loader/dist/cjs.js!../../../../../../../node_modules/.pnpm/postcss-loader@3.0.0/node_modules/postcss-loader/src/index.js??postcss!../../../../../../../node_modules/.pnpm/resolve-url-loader@3.1.5/node_modules/resolve-url-loader/index.js!../../../../../../../node_modules/.pnpm/sass-loader@10.5.2_sass@1.77.8_sass@1.77.8_webpack@4.47.0/node_modules/sass-loader/dist/cjs.js!./global-search.component.scss");

		if(typeof newContent === 'string') newContent = [[module.id, newContent, '']];

		var locals = (function(a, b) {
			var key, idx = 0;

			for(key in a) {
				if(!b || a[key] !== b[key]) return false;
				idx++;
			}

			for(key in b) idx--;

			return idx === 0;
		}(content.locals, newContent.locals));

		if(!locals) throw new Error('Aborting CSS HMR due to changed css-modules locals.');

		update(newContent);
	});

	module.hot.dispose(function() { update(); });
}