import { Input, Component } from '@angular/core';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { SigningPasscodeService } from '@app/shared/signing-passcode/signing-passcode.service';

@Component({
    selector: 'pin-expiry-warning',
    template: `
    <div class="input-group u-d-flex u-justify-content-between u-align-items-center">
        <span *ngIf="isPasscodeExpired" class="text-danger">
            Your Signing PIN has expired. Please reset your Signing PIN to sign the document.
        </span>
        <a class="text-uppercase ml-auto u-flex-shrink-0 p-0 test-resetMyPinBtn"
            (click)="requestResetSigningPasscode($event)"
            [tooltip]="isPasscodeExpired ? tooltipExpiredText : tooltipText"
            placement="bottom">
            Reset My Signing PIN?
        </a>
    </div>
    `
})
export class PinExpiryWarningComponent {
    @Input() isPasscodeExpired: boolean;
    tooltipText = 'Forgot your PIN? Click here and we\'ll email you reset instructions.';
    tooltipExpiredText = 'Has your Signing PIN expired? Click here and we\'ll email you reset instructions.';

    constructor(private SigningPasscode: SigningPasscodeService, private Notifications: NotificationsService) { }

    requestResetSigningPasscode(event): void {
        event.preventDefault();
        event.stopPropagation();
        this.SigningPasscode.resetSigningPasscode().subscribe(() => {
            this.Notifications.success('Instructions for setting your Signing PIN has been sent to your email');
        });
    }
}
