import {
    Component, DoCheck, Input
} from '@angular/core';
import * as _ from 'lodash';
import { Entity } from '@app/shared/models';
import { EntityTypeService } from '../entity-type.service';

import template from './humanized-entity-list.html';

@Component({
    selector: 'humanized-entity-list',
    template
})
export class HumanizedEntityListComponent implements DoCheck {
    @Input() entityList: Entity[];
    @Input() showTooltips = true;
    @Input() maxCharacters = 60;
    @Input() showEntityTypes: boolean;
    private sortedEntityList: Entity[];
    public isTruncated = false;
    public suffix = '';
    public displayedEntities: Entity[];

    constructor(private EntityType: EntityTypeService) { }

    ngDoCheck(): void {
        if (!this.entityList || !this.sortedEntityList || !(this.entityList.length === this.sortedEntityList.length)) {
            this.calculateDisplayValues();
        }
    }

    private calculateDisplayValues(): void {
        this.sortedEntityList = _.sortBy(this.entityList, ['name', 'subType', 'type']);
        this.displayedEntities = [];
        let charCount = 0;
        for (let i = 0; i < this.sortedEntityList.length; i += 1) {
            const entity = this.sortedEntityList[i];
            const entityCharCount = this.EntityType.get(entity).length + entity.name.length + 1;
            if (i === 0 || (charCount + entityCharCount) < this.maxCharacters) {
                this.displayedEntities.push(entity);
                charCount += entityCharCount;
            }
            else {
                break;
            }
        }
        this.isTruncated = false;
        if (this.displayedEntities.length < this.sortedEntityList.length) {
            this.isTruncated = true;
            this.suffix = `, and ${this.sortedEntityList.length - this.displayedEntities.length} other(s)`;
        }
    }
}
