import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component, EventEmitter, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
    BehaviorSubject, Observable, Subject, combineLatest
} from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import template from './doa-log-template-roles-responsibilities-edit.component.html';
import styles from './doa-log-template-roles-responsibilities-edit.component.scss';
import { ADD_ROLE_RESPONSIBILITY_BUTTON_PLACEMENT, DOA_LOG_TEMPLATE_STEP_NAMES, DoaLogTemplateEditRolesResponsibilitiesForm } from '../doa-log-template.types';
import { StudyResponsibilities, StudyRole } from '../../../../../shared/models';
import { StudyRoleIdName, StudyRolesMap } from '../../../../../shared/study-roles/study-roles.types';
import { EditRolesResponsibilitiesEvent } from './doa-log-template-roles-responsibilities-edit.types';

@Component({
    selector: 'doa-log-template-roles-responsibilities-edit',
    template,
    styles: [String(styles)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoaLogTemplateRolesResponsibilitiesEditComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        private modalRef: BsModalRef,
        private formBuilder: FormBuilder
    ) {}

    @Input() existingStudyResponsibilities: StudyResponsibilities;
    @Input() existingStudyRoles: StudyRole[];
    @Input() studyRolesList$: Observable<StudyRoleIdName[]>;
    @Input() studyRolesMap$: Observable<StudyRolesMap>;
    @Input() isLoadingStudyRoles$: Observable<boolean>;
    @Input() isLogDocument = false;
    @Output() rolesResponsibilitiesEdited = new EventEmitter<EditRolesResponsibilitiesEvent>();

    studyRolesResponsibilitiesForm = this.formBuilder.group({});

    formStudyResponsibilities = new BehaviorSubject<StudyResponsibilities>(<StudyResponsibilities>{});
    formStudyResponsibilities$ = this.formStudyResponsibilities.asObservable();

    formStudyRoles = new BehaviorSubject<StudyRole[]>([]);
    formStudyRoles$ = this.formStudyRoles.asObservable();

    private areResponsibilitiesChanged = new BehaviorSubject(false);
    areResponsibilitiesChanged$ = this.areResponsibilitiesChanged.asObservable();

    private areStudyRolesChanged = new BehaviorSubject(false);
    areStudyRolesChanged$ = this.areStudyRolesChanged.asObservable();

    private isSaveDisabled = new BehaviorSubject(true);
    isSaveDisabled$ = this.isSaveDisabled.asObservable();

    private isProcessing = new BehaviorSubject(false);
    isProcessing$ = this.isProcessing.asObservable();

    saveButtonText: string;
    addRoleButtonPlacement: string;
    readonly addResponsibilityButtonPlacement = ADD_ROLE_RESPONSIBILITY_BUTTON_PLACEMENT.ABOVE;

    readonly modalTitle = 'Edit Roles and Responsibilities';
    readonly stepNames = {
        studyResponsibilities: DOA_LOG_TEMPLATE_STEP_NAMES.STUDY_RESPONSIBILITIES,
        studyRoles: DOA_LOG_TEMPLATE_STEP_NAMES.STUDY_ROLES
    };

    private readonly destroy$ = new Subject();

    ngOnInit() {
        this.saveButtonText = this.isLogDocument ? 'Save' : this.modalTitle;
        this.addRoleButtonPlacement = this.isLogDocument
            ? ADD_ROLE_RESPONSIBILITY_BUTTON_PLACEMENT.TOP_LEFT : ADD_ROLE_RESPONSIBILITY_BUTTON_PLACEMENT.TOP_RIGHT;

        combineLatest([
            this.areResponsibilitiesChanged$,
            this.areStudyRolesChanged$
        ]).pipe(
            tap(([areResponsibilitiesChanged, areStudyRolesChanged]) => {
                this.isSaveDisabled.next(!areResponsibilitiesChanged && !areStudyRolesChanged);
            }),
            takeUntil(this.destroy$)
        ).subscribe();

        this.formStudyRoles.next(this.existingStudyRoles);
        this.formStudyResponsibilities.next(this.existingStudyResponsibilities);
    }

    ngAfterViewInit() {
        this.syncStudyResponsibilityChangesToStudyRoles();
        this.syncStudyRolesChangesToStudyResponsibilities();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onCloseModal(): void {
        this.modalRef.hide();
    }

    onEditRolesResponsibilities(): void {
        this.isProcessing.next(true);

        const formValue = <DoaLogTemplateEditRolesResponsibilitiesForm> this.studyRolesResponsibilitiesForm.getRawValue();

        this.rolesResponsibilitiesEdited.emit({
            data: formValue,
            onSuccess: () => this.modalRef.hide(),
            onError: () => {
                this.isProcessing.next(false);
                this.modalRef.hide();
            }
        });
    }

    private syncStudyResponsibilityChangesToStudyRoles(): void {
        const studyResponsibilitiesFormControl = <FormGroup> this.studyRolesResponsibilitiesForm.controls.studyResponsibilities;

        studyResponsibilitiesFormControl.controls.responsibilities.valueChanges.pipe(
            tap((responsibilities) => {
                this.formStudyResponsibilities.next(responsibilities);

                const wasResponsibilityAdded = responsibilities.values.some(({ isNew }) => !!isNew);

                if (this.areResponsibilitiesChanged.getValue() !== wasResponsibilityAdded) {
                    this.areResponsibilitiesChanged.next(wasResponsibilityAdded);
                }
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    private syncStudyRolesChangesToStudyResponsibilities(): void {
        const studyRolesFormGroup = <FormGroup> this.studyRolesResponsibilitiesForm.controls.studyRoles;

        studyRolesFormGroup.controls.roles.valueChanges.pipe(
            tap((roles) => {
                this.formStudyRoles.next(roles);

                const areRolesUpdated = roles.some(({ isNew, isChanged }) => !!isNew || !!isChanged);

                if (this.areStudyRolesChanged.getValue() !== areRolesUpdated) {
                    this.areStudyRolesChanged.next(areRolesUpdated);
                }
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }
}
