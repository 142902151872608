import {
    Directive,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { FormUtil } from '../../utils/form.util';

@Directive({
    selector: '[showIfControlInvalid]'
})
export class ShowIfControlInvalidDirective implements OnInit, OnDestroy {
    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef
    ) {}

    formControl: AbstractControl;

    @Input() set showIfControlInvalid(formControl: AbstractControl) {
        this.formControl = formControl;
    }

    private readonly destroy$ = new Subject<void>();

    ngOnInit(): void {
        combineLatest([
            this.formControl.valueChanges,
            this.formControl.statusChanges
        ]).pipe(
            distinctUntilChanged(),
            tap(() => {
                this.viewContainer.clear();

                if (FormUtil.controlHasError(this.formControl)) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                }
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
