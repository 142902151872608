import { HttpClient } from '@angular/common/http';
import * as angular from 'angular';

import { CdkDropList } from '@angular/cdk/drag-drop';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import 'ng-idle';
import 'ng-tags-input';

import { AccessDatesService } from './access-dates/access-dates.service';
import { ActionBarService } from './action-bar/action-bar.service';
import { AdapterService } from './adapter/adapter.service';
import { ApiErrorsService } from './api-error/api-errors.service';
import { AppFeaturesService } from './app-features/app-features.service';
import { AuditTrailService } from './audit-trail/audit-trail.service';
import { Auth0ErrorService } from './auth0-error/auth0-error.service';
import { BindersService } from './binders/binders.service';
import { CookiesService } from './cookies/cookies.service';
import { DownloadCheckerService } from './downloads/download-checker.service';
import { DownloadsService } from './downloads/downloads.service';
import { EllipsizedTextComponent } from './ellipsized-text/ellipsized-text.component';
import { FoldersService } from './folders/folders.service';
import { LabelsService } from './labels/labels.service';
import { LogTemplatesService } from './log-templates/log-templates.service';
import loginAlertsModule from './login-alerts';
import { MultiSelectService } from './multi-select/multi-select.service';
import { PaginationPropertiesService } from './pagination-properties/pagination-properties.service';
import { PasswordOrPinExpiryService } from './password-or-pin-expiry/password-or-pin-expiry.service';
import { PermissionValidatorService } from './permissions/permission-validator.service';
import { PermissionsService } from './permissions/permissions.service';
import { ProjectsService } from './projects/projects.service';
import { Auth0Service } from './sessions/auth0.service';
import { SessionsService } from './sessions/sessions.service';
import { SigningPasscodeService } from './signing-passcode/signing-passcode.service';
import { StudiesService } from './studies/studies.service';
import { SystemNotificationsStoreService } from './system-notifications/system-notifications-store.service';
import { SystemNotificationsService } from './system-notifications/system-notifications.service';
import { TeamService } from './teams/team.service';
import { UsersService } from './users/users.service';
import { TeamInboxCheckerService } from './teams-inbox/team-inbox-checker.service';
import { SessionIdleService } from './session-idle/session-idle-service.service';

const dependencies = [
    'ngIdle',
    'ngTagsInput',
    loginAlertsModule.name
];

const sharedModule = angular
    .module('app.shared', dependencies)
    .factory('AccessDates', downgradeInjectable(AccessDatesService))
    .factory('Adapter', downgradeInjectable(AdapterService))
    .factory('ApiErrors', downgradeInjectable(ApiErrorsService))
    .factory('AppFeatures', downgradeInjectable(AppFeaturesService))
    .factory('AuditTrail', downgradeInjectable(AuditTrailService))
    .factory('Binders', downgradeInjectable(BindersService))
    .factory('Downloads', downgradeInjectable(DownloadsService))
    .factory('Folders', downgradeInjectable(FoldersService))
    .factory('Labels', downgradeInjectable(LabelsService))
    .factory('MultiSelect', downgradeInjectable(MultiSelectService))
    .service('PaginationProperties', downgradeInjectable(PaginationPropertiesService))
    .factory('Permissions', downgradeInjectable(PermissionsService))
    .service('PermissionValidator', downgradeInjectable(PermissionValidatorService))
    .factory('SessionsService', downgradeInjectable(SessionsService))
    .factory('SigningPasscode', downgradeInjectable(SigningPasscodeService))
    .factory('LogTemplates', downgradeInjectable(LogTemplatesService))
    .factory('Studies', downgradeInjectable(StudiesService))
    .factory('Projects', downgradeInjectable(ProjectsService))
    .factory('Team', downgradeInjectable(TeamService))
    .factory('Users', downgradeInjectable(UsersService))
    .service('PasswordOrPinExpiry', PasswordOrPinExpiryService)
    .directive('cdkDropList', downgradeComponent({ component: CdkDropList }) as ng.IDirectiveFactory)
    .directive('ellipsizedText', downgradeComponent({ component: EllipsizedTextComponent }) as ng.IDirectiveFactory)
    .factory('ActionBar', downgradeInjectable(ActionBarService))
    .factory('DownloadChecker', downgradeInjectable(DownloadCheckerService))
    .factory('TeamInboxChecker', downgradeInjectable(TeamInboxCheckerService))
    .factory('SystemNotifications', downgradeInjectable(SystemNotificationsService))
    .factory('SystemNotificationsStore', downgradeInjectable(SystemNotificationsStoreService))
    .factory('Auth0Service', downgradeInjectable(Auth0Service))
    .factory('CookiesService', downgradeInjectable(CookiesService))
    .factory('Auth0ErrorService', downgradeInjectable(Auth0ErrorService))
    .factory('SessionIdleService', downgradeInjectable(SessionIdleService))
    .factory('http', downgradeInjectable(HttpClient));
export default sharedModule;
