import { CursorPaginationResponse, AuditTrailSubject, AuditTrail } from '@app/shared/models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GetAuditsParams, AuditTrailSortField, RequestData } from './audit-trail.service.types';
import { AuditTrailModalPagination } from '../../components/audit-trail/components/audit-trail-modal/audit-trail-modal.component.types';

@Injectable()
export class AuditTrailService {
    private readonly url = {
        base: (teamId: string, objectId: string): string => `/api/teams/${teamId}/auditTrail/${objectId}`,
        single: (teamId: string, id: string): string => `/api/teams/${teamId}/audit-trails/${id}`,
        userProfileBase: (userId: string): string => `/api/users/${userId}/auditTrail`
    };

    readonly auditPagination: AuditTrailModalPagination = {
        pageSize: 20,
        sortBy: AuditTrailSortField.TIMESTAMP,
        sortDir: 'DESC'
    };

    constructor(
        private http: HttpClient
    ) { }

    private getRequestData(params: GetAuditsParams): RequestData {

        const baseParams = {
            next: params.next,
            pageSize: params.pageSize,
            sortBy: params.sortBy,
            sortDir: params.sortDir,
            filter: params.filter
        };

        if (params.subject === AuditTrailSubject.USER_PROFILE) {

            return {
                url: this.url.userProfileBase(params.objectId),
                params: baseParams
            };
        }

        return {
            url: this.url.base(params.teamId, params.objectId),
            params: {
                ...baseParams,
                subject: params.subject,
                overwrittenObjectId: params.overwrittenObjectId,
                limitToOverwritten: params.limitToOverwritten
            }
        };
    }

    public getAudits(params: GetAuditsParams): Observable<CursorPaginationResponse<AuditTrail>> {
        const req = this.getRequestData(params);

        let httpParams = new HttpParams();
        Object.entries(req.params).forEach(
            ([key, value]) => {
                if (key !== 'filter' && value) {
                    httpParams = httpParams.set(key, value.toString());
                }
            }
        );
        if (params.filter) {
            httpParams = httpParams.set('filter', JSON.stringify(params.filter));
        }

        return this.http.get<CursorPaginationResponse<AuditTrail>>(req.url, { params: httpParams });
    }

    public getAudit(teamId: string, id: string, subject: string): Observable<AuditTrail> {
        return this.http.get<AuditTrail>(this.url.single(teamId, id), { params: { subject } });
    }
}
