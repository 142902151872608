import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
    Document,
    DocumentSubTypes,
    Signature,
    SignatureRequest
} from '@app/shared/models';

import { GetSignatureStatusResponse } from '@app/shared/documents/documents.service.types';
import template from './document-signature-list.component.html';
import styles from './document-signature-list.component.scss';


@Component({
    selector: 'signature-list',
    template,
    styles: [String(styles)]
})

export class DocumentSignatureListComponent {
    @Input() doc: Document;
    @Input() documentSignatureStatus: GetSignatureStatusResponse;
    @Input() pending: boolean;

    isProcessing: boolean;

    constructor(
        private modalRef: BsModalRef
    ) {
        this.isProcessing = false;
    }

    isLog(): boolean {
        return this.doc.subType === DocumentSubTypes.log;
    }

    resolveSignatureRow(signatureRequest: SignatureRequest | Signature): string {
        return signatureRequest.entryRow ? signatureRequest.entryRow.toString() : 'Entire Log';
    }

    cancel(): void {
        this.modalRef.hide();
    }
}
