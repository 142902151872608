import {
    Component, EventEmitter, Input, Output, OnInit
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserPrivacyConsent } from './user-privacy-consent.component.types';
import template from './user-privacy-consent.component.html';
@Component({
    selector: 'user-privacy-consent',
    template
})
export class UserPrivacyConsentComponent implements OnInit {
    @Input() privacyConsent: Partial<UserPrivacyConsent>;
    @Output() onSave = new EventEmitter<{ privacyConsent: Partial<UserPrivacyConsent> }>();
    agreeAll = false;
    userPrivacyConsentForm = this.fb.group({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EEAResident: [false, [Validators.required]],
        cookies: [false, [Validators.required]],
        customerTraining: [false, [Validators.required]],
        communications: [false, [Validators.required]],
        resources: [false, [Validators.required]]
    });

    constructor(
        private fb: FormBuilder
    ) {}

    private initializeForm(): void {
        this.userPrivacyConsentForm.patchValue({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            EEAResident: Boolean(this.privacyConsent.EEAResident),
            cookies: Boolean(this.privacyConsent.cookies),
            customerTraining: Boolean(this.privacyConsent.customerTraining),
            communications: Boolean(this.privacyConsent.communications),
            resources: Boolean(this.privacyConsent.resources)
        });
        this.userPrivacyConsentForm.markAsPristine();
    }

    private refreshAgreeAll(): void {
        this.agreeAll = this.userPrivacyConsentForm.value.customerTraining
            && this.userPrivacyConsentForm.value.communications
            && this.userPrivacyConsentForm.value.resources;
    }

    ngOnInit(): void {
        this.initializeForm();
        this.refreshAgreeAll();
    }

    toggleAgreeAll(): void {
        this.agreeAll = !this.agreeAll;
        this.userPrivacyConsentForm.patchValue({
            customerTraining: this.agreeAll,
            communications: this.agreeAll,
            resources: this.agreeAll
        });
        this.userPrivacyConsentForm.markAsDirty();
    }

    togglePrivacyConsentField(field: string): void {
        this.userPrivacyConsentForm.patchValue({
            [field]: !this.userPrivacyConsentForm.value[field]
        });
        this.refreshAgreeAll();
        this.userPrivacyConsentForm.markAsDirty();
    }

    updateUserPrivacyConsent(): void {
        this.onSave.emit(this.userPrivacyConsentForm.value);
        this.userPrivacyConsentForm.markAsPristine();
    }
}
