import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@app/shared/shared.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { DuplicateComponent } from './containers/duplicate/duplicate.component';
import { NamesPreviewComponent } from './components/names-preview/names-preview.component';
import { CreateNamesListComponent } from './containers/create-custom-names-list/create-names-list.component';
import { NamePreviewItemComponent } from './components/name-preview-item/name-preview-item.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        WidgetsModule,
        ReactiveFormsModule
    ],
    providers: [
    ],
    declarations: [
        NamesPreviewComponent,
        NamePreviewItemComponent,
        CreateNamesListComponent,
        DuplicateComponent
    ],
    entryComponents: [
        NamesPreviewComponent,
        NamePreviewItemComponent,
        CreateNamesListComponent,
        DuplicateComponent
    ],
    exports: [
    ]
})
export class DuplicateModule {}
