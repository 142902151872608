/**
 * Compares date object or date string values with
 * taking into account possible falsy values of the arguments
 * NOTE: It will return true if both arguments have falsy values
 * @returns {boolean}
 */
export const areDatesEqual = (date1: Date | string | null, date2: Date | string | null): boolean => {
    if ((!date1 && date2) || (date1 && !date2)) {
        return false;
    }
    if (!date1 && !date2) {
        return true;
    }
    return new Date(date1).getTime() === new Date(date2).getTime();
};
