import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

import { SigningPinExpiryNotificationComponent } from './containers/signing-pin-expiry-notification/signing-pin-expiry-notification.component';

const dependencies = [];

export const signingPinPolicyModule = angular
    .module('app.signingPinPolicy', dependencies)
    .directive('signingPinExpiryNotification', downgradeComponent({ component: SigningPinExpiryNotificationComponent }) as ng.IDirectiveFactory);
