import { NgModule } from '@angular/core';
import { ModalsModule } from '../../widgets/modals/modals.module';
import { PasswordExpiryReminderComponent } from './components/password-expiry-reminder.modal/password-expiry-reminder.component';
import { PasswordExpiredComponent } from './components/password-expired.modal/password-expired.component';
@NgModule({
    imports: [
        ModalsModule
    ],
    providers: [
    ],
    declarations: [PasswordExpiryReminderComponent, PasswordExpiredComponent],
    entryComponents: [PasswordExpiryReminderComponent, PasswordExpiredComponent],
    exports: []
})
export class PasswordModule {}
