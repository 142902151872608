'use strict';

module.exports = {
    qcReviewStatuses: {
        PENDING_REVIEW: 'Pending review',
        IN_REVIEW: 'In review',
        APPROVED: 'Approved',
        DECLINED: 'Declined',
        REJECTED: 'Rejected',
        IN_REJECTION_REVIEW: 'In Rejection Review',
        REJECTION_ACCEPTED: 'Rejection Accepted'
    }
};
