import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Connection, CreateExchangeParams } from '../document-sharing.types';

@Injectable()
export class DocumentSharingApiService {
    readonly url = {
        connections: (teamId: string): string => `/api/teams/${teamId}/connections`,
        exchange: (teamId: string): string => `/api/teams/${teamId}/exchange`
    }

    constructor(
        private http: HttpClient
    ) {}

    public createExchange(params: CreateExchangeParams) {
        return this.http.post(this.url.exchange(params.senderTeamId), params);
    }

    public getConnections(teamId: string): Observable<Connection[]> {
        return this.http.get<Connection[]>(this.url.connections(teamId));
    }
}
