import {
    Component,
    Input
} from '@angular/core';
import styles from './warning-well.component.scss';

@Component({
    selector: 'warning-well',
    template: `
    <div class="well warning-well">
        <p class="warning-well-header">
            <span class="warning-icon" [ngStyle]="{margin: '0 0.2em'}">
                <i class="fas fa-exclamation-triangle text-warning"></i>
            </span>
            <span class="test-warningHeader">
                {{header}}
            </span>
        </p>
        <span class="pl-2">
            <ng-content></ng-content>
        </span>
    </div>
    `,
    styles: [String(styles)]
})
export class WarningWellComponent {
    @Input() header = 'Are You Sure?';
}
