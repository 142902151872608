import {
    Component, Input, Output, EventEmitter, OnInit
} from '@angular/core';
import { Document, Folder } from '@app/shared/models';

import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { ImportViaEmailComponent } from '@app/widgets/import-via-email/import-via-email.component';
import template from './document-versions.component.html';
import styles from './document-versions.scss';

@Component({
    selector: 'document-versions',
    template,
    styles: [String(styles)]
})
export class DocumentVersionsComponent implements OnInit {
    @Input() doc: Document;
    @Input() currentDisplayVersion: number;
    @Output() setVersion = new EventEmitter<number>();
    @Output() uploadNewVersion = new EventEmitter<void>();
    versions;
    isBrokenShortcut: boolean;
    canReplaceOrEmail: boolean;
    latestVersion: number;
    isLatestVersion: boolean;
    isDocumentLocked = false;

    constructor(
        private Modals: ModalsService
    ) {}

    ngOnInit(): void {
        this.canReplaceOrEmail = this.doc.permissions.replaceDocument
            && (this.doc.subType !== 'shortcut' && this.doc.subType !== 'log');
        this.versions = this.doc.versions.sort((a, b) => b.number - a.number) || [];
        this.isDocumentLocked = this.doc.isLocked;
    }

    handleUploadNewVersion(): void {
        this.uploadNewVersion.emit();
    }

    handleSetVersion(version: number): void {
        this.setVersion.emit(version);
    }

    showEmailModal(): void {
        const importViaEmailModal = this.Modals.show(ImportViaEmailComponent, {
            class: 'modal-md',
            initialState: {
                resolve: {
                    teamId: this.doc.teamId,
                    binderId: this.doc.binderId,
                    folderId: this.doc.folderId,
                    docId: this.doc.id.toString(),
                    crumbsEntity: this.doc as unknown as Folder
                }
            }
        });

        importViaEmailModal.content.dismiss.subscribe(() => {
            importViaEmailModal.hide();
        });
    }
}
