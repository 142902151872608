import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit,
    Output
} from '@angular/core';
import {
    BehaviorSubject, combineLatest, Observable, Subject
} from 'rxjs';
import {
    ControlContainer, FormArray, FormControl, FormGroup
} from '@angular/forms';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    startWith,
    switchMap, take, takeUntil, tap
} from 'rxjs/operators';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import template from './study-site-team-edit.component.html';
import styles from './study-site-team-edit.component.scss';
import {
    Team, User,
    SelectedTeamMember,
    TeamMemberForm
} from '../../../../shared/models';
import { CurrentSessionService } from '../../../../core/current-session.service';
import { TeamService } from '../../../../shared/teams/team.service';
import { FilteredSelectEvent } from '../../../../widgets/filtered-select/filtered-select.component';
import { LoadUsersResponse } from '../../../../shared/teams/teams.service.types';
import { StudyRoleIdName } from '../../../../shared/study-roles/study-roles.types';

@Component({
    selector: 'study-site-team-edit',
    template,
    styles: [String(styles)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudySiteTeamEditComponent implements OnInit, OnDestroy {
    constructor(
        private currentSessionService: CurrentSessionService,
        private teamService: TeamService,
        private controlContainer: ControlContainer,
        private FeatureFlags: FeatureFlagService,
        private cdr: ChangeDetectorRef
    ) {}

    @Input() studyRoles: StudyRoleIdName[] = [];
    @Output() teamMembersAdded = new EventEmitter<FilteredSelectEvent<User>>();
    @Output() revertTeamMembers = new EventEmitter();
    @Output() teamMemberRemoved = new EventEmitter<User['id']>();
    @Output() teamMemberRoleChanged = new EventEmitter<{
        teamMemberIndex: number;
        studyRoleId: StudyRoleIdName['_id'];
    }>();

    get studySiteFormGroup(): FormGroup {
        return this.controlContainer.control as FormGroup;
    }

    get siteTeamMembersFormArray(): FormArray {
        return this.studySiteFormGroup.get('teamMembers') as FormArray;
    }

    private team: Team;

    private teamUsersList = new BehaviorSubject<User[]>([]);
    teamUsersList$ = this.teamUsersList.asObservable();

    private selectedTeamMembers = new BehaviorSubject<SelectedTeamMember[]>([]);
    selectedTeamMembers$ = this.selectedTeamMembers.asObservable();

    private isLoadingTeamUsers = new BehaviorSubject(false);
    isLoadingTeamUsers$ = this.isLoadingTeamUsers.asObservable();

    private isInitialUsersLoad = new BehaviorSubject(false);
    isInitialUsersLoad$ = this.isInitialUsersLoad.asObservable();

    private hasNextPage = new BehaviorSubject(false);
    hasNextPage$ = this.hasNextPage.asObservable();

    currentFilter = '';
    currentPageNum = 0;
    pageSize = 20;
    sortBy = 'profile.firstName';
    sortDir = 'ASC';

    private destroy$ = new Subject<void>();
    searchEnabled = true;

    private filterControls = new Map<string, FormControl>();
    private filteredListsSubject = new Map<string, BehaviorSubject<StudyRoleIdName[]>>();

    ngOnInit(): void {
        this.FeatureFlags.getFlag(FEATURE_FLAGS.EBINDERS_SEARCH_STUDY_ROLES, true).pipe(
            filter((flag) => flag !== undefined),
            debounceTime(300),
            tap((isFlagEnabled) => {
                this.searchEnabled = isFlagEnabled;
            })
        ).subscribe();

        this.isInitialUsersLoad.next(true);
        this.team = this.currentSessionService.getCurrentTeam();

        this.setInitiallySelectedTeamMembers();

        this.getTeamUsers$().pipe(
            tap(({ items: users, recordCount }) => {
                this.setTeamUsersList(users);

                this.setHasNextPage(recordCount);

                this.isInitialUsersLoad.next(false);
            }),
            switchMap(() => this.syncSelectedTeamMembersWithFormChanges$()),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onTeamMemberFiltered(filterVal: string): void {
        this.currentFilter = filterVal;
        this.resetCurrentPageNum();

        this.getTeamUsers$().pipe(
            tap(({ items: users, recordCount }) => {
                this.setTeamUsersList(users);

                this.setHasNextPage(recordCount);
            }),
            take(1)
        ).subscribe();
    }

    onTeamMemberSelect($event: FilteredSelectEvent<User>) {
        this.teamMembersAdded.emit($event);
        this.getFilterControl($event.added[0].id);
    }

    onCancelEditingTeamMembers() {
        this.revertTeamMembers.emit();
    }

    onRemoveTeamMember(userId: string): void {
        this.teamMemberRemoved.emit(userId);
    }

    onScrollEnd(): void {
        this.getTeamUsers$().pipe(
            tap(({ items: users, recordCount }) => {
                this.addToTeamUsersList(users);

                this.setHasNextPage(recordCount);
            }),
            take(1)
        ).subscribe();
    }

    onSelectStudyRole(teamMemberIndex: number, studyRoleId: string): void {
        this.teamMemberRoleChanged.emit({
            teamMemberIndex,
            studyRoleId
        });
    }

    private setInitiallySelectedTeamMembers(): void {
        const initiallySelectedTeamMembers = this.siteTeamMembersFormArray.getRawValue()
            .map((teamMember: TeamMemberForm) => this.formatSelectedTeamMember(teamMember));

        this.selectedTeamMembers.next(initiallySelectedTeamMembers);
    }

    private syncSelectedTeamMembersWithFormChanges$() {
        return this.siteTeamMembersFormArray.valueChanges.pipe(
            tap(() => {
                const updatedTeamMembersWithDetails = this.siteTeamMembersFormArray.getRawValue()
                    .map((teamMember: TeamMemberForm) => this.formatSelectedTeamMember(teamMember));

                this.selectedTeamMembers.next(updatedTeamMembersWithDetails);
            })
        );
    }

    private formatSelectedTeamMember(teamMember: TeamMemberForm): SelectedTeamMember {
        return {
            ...teamMember,
            id: teamMember.userId
        };
    }

    private getTeamUsers$(): Observable<LoadUsersResponse> {
        this.isLoadingTeamUsers.next(true);

        return this.teamService.loadUsers({
            teamId: this.team.id,
            pageNum: this.currentPageNum + 1,
            pageSize: this.pageSize,
            sortBy: this.sortBy,
            sortDir: this.sortDir,
            ...(this.currentFilter && { filter: this.currentFilter })
        }).pipe(
            tap(() => {
                this.incrementCurrentPageNum();

                this.isLoadingTeamUsers.next(false);
            }),
            take(1)
        );
    }

    private setTeamUsersList(users: User[]): void {
        this.teamUsersList.next(users);
    }

    private addToTeamUsersList(users: User[]): void {
        this.teamUsersList.next(this.teamUsersList.getValue().concat(users));
    }

    private incrementCurrentPageNum(): void {
        const newPageNum = this.currentPageNum + 1;
        this.currentPageNum = newPageNum;
    }

    private resetCurrentPageNum(): void {
        this.currentPageNum = 0;
    }

    private setHasNextPage(recordCount: number): void {
        const hasNextPage = recordCount > this.teamUsersList.getValue().length;

        this.hasNextPage.next(hasNextPage);
    }

    getFilterControl(memberId: string): FormControl {
        if (!this.filterControls.has(memberId)) {
            const control = new FormControl(null);
            this.filterControls.set(memberId, control);
            this.setupFilteredListObservable(memberId, control);
        }
        return this.filterControls.get(memberId);
    }

    getFilteredList(memberId: string): Observable<StudyRoleIdName[]> {
        if (!this.filteredListsSubject.has(memberId)) {
            this.filteredListsSubject.set(memberId, new BehaviorSubject<StudyRoleIdName[]>(this.studyRoles));
        }
        return this.filteredListsSubject.get(memberId).asObservable();
    }

    private setupFilteredListObservable(memberId: string, control: FormControl): void {
        combineLatest([
            control.valueChanges.pipe(
                startWith(''),
                distinctUntilChanged()
            ),
            this.selectedTeamMembers$,
            this.selectedTeamMembers$.pipe(
                map((members) => members.find((m) => m.id === memberId)?.studyRole)
            )
        ]).pipe(
            debounceTime(100),
            takeUntil(this.destroy$)
        ).subscribe(([searchTerm, teamMembers, initialSelection]) => {
            const term = searchTerm?.toLowerCase() || '';

            const selectedRoleIds = new Set(
                teamMembers
                    .filter((member) => member.id !== memberId)
                    .map((member) => member.studyRole?._id)
                    .filter((id) => id)
            );

            const filtered = this.studyRoles.filter((role) => {
                const isInitialRole = initialSelection?._id === role._id;
                const matchesSearch = role.name.toLowerCase().includes(term);
                const isAvailable = !selectedRoleIds.has(role._id);

                return isInitialRole || (matchesSearch && isAvailable);
            });

            this.filteredListsSubject.get(memberId)?.next(filtered);
        });
    }

    onFilterChanged(searchTerm: string, memberId: string): void {
        this.getFilterControl(memberId).setValue(searchTerm);
    }

    onSelectionCleared(memberId: string): void {
        this.getFilterControl(memberId).setValue('');
    }

    onChooseStudyRole(
        index: number,
        selectedStudyRoleId: FilteredSelectEvent<StudyRoleIdName>
    ) {
        this.onSelectStudyRole(index, selectedStudyRoleId.added[0]._id);
    }
}
