import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import teamInboxRoute from './team-inbox.route';
import { TeamInboxComponent } from './containers/team-inbox/team-inbox.component';
import { InboxDocumentPreviewComponent } from './containers/inbox-document-preview/inbox-document-preview.component';
import { TeamInboxStateService } from './team-inbox.state.service';

const dependencies = [uiRouter];

const teamInboxModule = angular
    .module('app.team-inbox', dependencies)
    .config(teamInboxRoute)
    .service('TeamInboxStateService', downgradeInjectable(TeamInboxStateService))
    .directive('documentInboxPreview', downgradeComponent({ component: InboxDocumentPreviewComponent }) as ng.IDirectiveFactory)
    .directive('teamInbox', downgradeComponent({ component: TeamInboxComponent }) as ng.IDirectiveFactory);

export default teamInboxModule;
