import { FileExtensions } from '@app/core/constants/extensions';

const isExtension = (fileNameOrExt: string, extensions: string[]): boolean => {
    const ext = fileNameOrExt.split('.').pop();
    return extensions.indexOf(ext) > -1;
};

export type FileIconClass = 'far fa-file' | 'far fa-file-image' | 'far fa-file-pdf' | 'far fa-file-word' | 'far fa-file-excel' | 'far fa-file-powerpoint' | 'far fa-file';

/**
 * Calculates correct icon css class for given filename or extension
 *
 * @param {string} fileNameOrExt - file name containing extension or extension of the file
 * valid value examples: 'filename.pdf', '.pdf', 'pdf' returns 'far fa-file-pdf'
 * @returns {string} - css class of the appropriate fontawesome file icon
 */
export const getIconClassFromFileExtension = (fileNameOrExt: string): FileIconClass => {
    if (isExtension(fileNameOrExt, FileExtensions.text)) {
        return 'far fa-file';
    }
    if (isExtension(fileNameOrExt, FileExtensions.image)) {
        return 'far fa-file-image';
    }
    if (isExtension(fileNameOrExt, FileExtensions.pdf)) {
        return 'far fa-file-pdf';
    }
    if (isExtension(fileNameOrExt, FileExtensions.word)) {
        return 'far fa-file-word';
    }
    if (isExtension(fileNameOrExt, FileExtensions.excel)) {
        return 'far fa-file-excel';
    }
    if (isExtension(fileNameOrExt, FileExtensions.powerpoint)) {
        return 'far fa-file-powerpoint';
    }
    return 'far fa-file';
};
