import { Entity } from '@app/shared/models';
import { Input, Component, OnChanges } from '@angular/core';
import { EntityTypeService } from '../entity-type.service';

@Component({
    selector: 'entity-name',
    template: '<span class="entity-name">{{ entityType }} <b>{{ entity.name }}</b></span>'
})
export class EntityNameComponent implements OnChanges {
    entityType: string;

    @Input() entity: Entity;

    constructor(private EntityType: EntityTypeService) { }

    ngOnInit(): void {
        this.setEntityType();
    }

    ngOnChanges(): void {
        this.setEntityType();
    }

    private setEntityType(): void {
        this.entityType = this.EntityType.get(this.entity);
    }
}
