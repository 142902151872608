import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChangePasswordResponse, GetUserResponse } from './users.types';
import { User } from '../models';
import { AppConfigService } from '../app-config/app-config.service';

@Injectable()
export class UsersService {
    private readonly url = {
        users: '/api/users',
        me: '/api/users/me',
        signInDates: `${this.AppConfig.config.authHost}/api/users/me/sign-in-dates`,
        changePassword: (userId: string): string => `/api/users/${userId}/change-password`,
        resetPasswordAuth0: '/api/users/reset-password-auth0',
        getHeaders: '/api/users/check-headers'
    };

    constructor(private http: HttpClient, private AppConfig: AppConfigService) {}

    changePassword({ id, oldPassword, newPassword }): Observable<ChangePasswordResponse> {
        return this.http.patch<ChangePasswordResponse>(this.url.changePassword(id), {
            id, oldPassword, newPassword
        });
    }

    getCurrent(): Observable<User> {
        return this.http.get<GetUserResponse>(this.url.me).pipe(
            map((data) => {
                return data.user;
            })
        );
    }

    getLastTwoSignInDates(): Observable<string[]> {
        return this.http.get<string[]>(this.url.signInDates, { withCredentials: true });
    }

    update(id, params): Observable<User> {
        return this.http.patch<GetUserResponse>(`${this.url.users}/${id}`, params).pipe(
            map((data) => {
                return data.user;
            })
        );
    }

    resetPasswordAuth0(email: string): Observable<User> {
        const body = { email };
        return this.http.patch<User>(this.url.resetPasswordAuth0, body);
    }

    getHeaders(headersToAdd: HttpHeaders): Observable<string[]> {
        return this.http.get<string[]>(this.url.getHeaders, { headers: headersToAdd });
    }
}
