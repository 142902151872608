import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { StudiesService } from '@app/shared/studies/studies.service';
import { Paywall } from '@app/shared/models';
import { UpdateMonitorGroupStudyIdsParams } from '@app/shared/studies/studies.service.types';

import template from './monitor-groups-list-modal.component.html';
import styles from './monitor-groups-list-modal.component.scss';

export interface PaywallTree extends Paywall {
    isChecked: boolean;
    isExpanded: boolean;
}

@Component({
    selector: 'monitor-groups-list-modal',
    template,
    styles: [String(styles)]
})
export class MonitorGroupsListModalComponent {
    @Input() teamId: string;
    @Input() studyName: string;
    @Input() studyId: string;
    @Output() dismiss = new EventEmitter<void>();
    @Output() onSubmit = new EventEmitter<UpdateMonitorGroupStudyIdsParams>();

    public monitorGroups: PaywallTree[];
    public loadingGroups = false;
    public isProcessing = false;
    private addedMonitorGroups = [];
    private removedMonitorGroups = [];

    constructor(
        private StudyService: StudiesService
    ) {}

    ngOnInit(): void {
        this.StudyService
            .getTeamMonitorGroups(this.teamId)
            .subscribe((paywalls) => {
                this.monitorGroups = paywalls.map((paywall) => {
                    return {
                        ...paywall,
                        isChecked: paywall.studyIds && paywall.studyIds.some((id) => id.toString() === this.studyId.toString()),
                        isExpanded: false
                    };
                });
                this.loadingGroups = false;
            }, () => {
                this.monitorGroups = undefined;
                this.loadingGroups = false;
            });
    }

    toggleExpansion(monitorGroup: PaywallTree): void {
        monitorGroup.isExpanded = !monitorGroup.isExpanded;
    }

    toggleChecked(monitorGroup: PaywallTree): void {
        monitorGroup.isChecked ? this.onItemUnselected(monitorGroup) : this.onItemSelected(monitorGroup);
        monitorGroup.isChecked = !monitorGroup.isChecked;
    }

    onItemUnselected(monitorGroup: PaywallTree): void {
        if (!this.addedMonitorGroups.find((e) => e === monitorGroup.id)) {
            this.removedMonitorGroups.push(monitorGroup.id);
        }
        this.addedMonitorGroups = this.addedMonitorGroups.filter((e) => e !== monitorGroup.id);
    }

    onItemSelected(monitorGroup: PaywallTree): void {
        if (!this.removedMonitorGroups.find((e) => e === monitorGroup.id)) {
            this.addedMonitorGroups.push(monitorGroup.id);
        }
        this.removedMonitorGroups = this.removedMonitorGroups.filter((e) => e !== monitorGroup.id);
    }

    canSave(): boolean {
        return !!this.addedMonitorGroups.length || !!this.removedMonitorGroups.length;
    }

    handleCancel(): void {
        this.dismiss.emit();
    }

    handleSave(): void {
        this.onSubmit.emit({
            added: this.addedMonitorGroups,
            removed: this.removedMonitorGroups
        });
        this.dismiss.emit();
    }
}
