'use strict';

exports.AUTH_STRATEGIES = {
    JWT_PUBLIC_API: 'jwt-token-public-api',
    JWT_AUTH0: 'jwt-token-auth0',
    JWT_COOKIE: 'jwt-cookie',
    JWT_COOKIE_TEAM_MEMBER: 'jwt-cookie-team-member',
    JWT_WORKFLO_MANAGER: 'jwt-workflo-manager',
    JWT_CS_APP: 'jwt-cs-app',
    JWT_M2M_TOKEN_API: 'jwt-m2m-token-api',
    POST_LOGIN_UPSERT: 'post-login-upsert',
    JWT_HEADER: 'jwt-header',
    BEARER_TOKEN: 'bearer-token',
    HMAC: 'hmac',
    JWT_USER: 'jwt-user'
};
