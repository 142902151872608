import { User } from '@app/shared/models';
import { REGEX, MESSAGES } from '@app/core/constants';
import {
    Component, EventEmitter, OnInit, Output
} from '@angular/core';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { UsersService } from '@app/shared/users/users.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import template from './change-password.component.html';
@Component({
    selector: 'change-password',
    template
})
export class ChangePasswordComponent implements OnInit {

    @Output() dismiss = new EventEmitter<void>();
    isProcessing = false;
    passwordMessage = MESSAGES.passwordMessage;
    passwordPattern = REGEX.password10;
    user: User;
    showTooltip = {
        confirmation: false,
        oldPassword: false,
        newPassword: false
    };

    passwordForm = this.fb.group({
        oldPassword: ['', [Validators.required, Validators.pattern(this.passwordPattern)]]
    });

    constructor(
        private fb: FormBuilder,
        private Users: UsersService,
        private CurrentSession: CurrentSessionService,
        private Notifications: NotificationsService
    ) {}

    ngOnInit(): void {
        this.user = this.CurrentSession.getCurrentUser();
    }

    validateConfirmation(control: AbstractControl): { pattern: boolean } {
        return control.value === this.passwordForm.value.newPassword
            ? null
            : { pattern: true };
    }

    registerPasswordFields({ password, confirmation }): void {
        this.passwordForm.addControl('newPassword', password);
        this.passwordForm.addControl('confirmationPassword', confirmation);
    }

    get ctrls(): { [key: string]: AbstractControl } {
        return this.passwordForm.controls;
    }

    cancel(): void {
        this.dismiss.emit();
    }

    save(): void {
        this.isProcessing = true;
        this.Users.changePassword({
            id: this.user.id,
            oldPassword: this.passwordForm.value.oldPassword,
            newPassword: this.passwordForm.value.newPassword
        })
            .subscribe(
                () => {
                    this.Notifications.success('User Password Successfully updated!');
                    this.dismiss.emit();
                }, (error) => {
                    this.Notifications.error(error.error.message);
                    this.dismiss.emit();
                },
                () => {
                    this.isProcessing = false;
                }
            );
    }
}
