import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { StateService } from '@uirouter/core';
import { SORT } from '@app/core/constants';
import { Crumb, Team } from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';
import { PaginationPropertiesService } from '@app/shared/pagination-properties/pagination-properties.service';
import { StudyManagerService } from '../../study-manager.service';
import { Pagination, JobStatus } from './job-status-table.types';
import template from './job-status-table.component.html';
import styles from './job-status-table.component.scss';

@Component({
    selector: 'job-status-table',
    template,
    styles: [String(styles)]
})

export class JobStatusTableComponent implements OnInit, OnChanges {

    crumbs: Crumb[] = [{ name: 'Job Status Table' }];
    currentTeam: Team;
    @Input() jobStatusData: {
        items: JobStatus[];
        recordCount: number;
    };

    @Output() pageUpdated = new EventEmitter<Pagination>();
    jobStatuses: JobStatus[];
    loading = true;
    maxSize: number;
    showBoundaryLinkNumbers: boolean;
    SORT = SORT;
    FAILED_STATE_NAME = 'Failed';
    pagination: Pagination = {
        pageNum: 1,
        pageSize: 20,
        sortBy: 'createdOn',
        sortDir: 'DESC'
    };

    constructor(
        private CurrentSession: CurrentSessionService,
        private PaginationProperties: PaginationPropertiesService,
        private StudyManager: StudyManagerService,
        private $state: StateService
    ) {
        this.showBoundaryLinkNumbers = this.PaginationProperties.showBoundaryLinkNumbers();
    }

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        const sortables = ['studyName', 'studyId', 'studyType', 'location', 'createdOn', 'status'];
        this.SORT.set(sortables[4], false);
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes.jobStatusData && changes.jobStatusData.currentValue) {
            this.loading = false;
            const { items, recordCount } = changes.jobStatusData.currentValue;
            this.jobStatuses = items;
            this.pagination.totalItems = recordCount;
        }
    }

    changePage({ page }): void {
        this.loading = true;
        this.pagination.pageNum = page;
        this.pageUpdated.emit(this.pagination);
    }

    changeSort(sortName: string): void {
        this.loading = true;
        this.pagination.sortBy = sortName;
        this.SORT.set(sortName, undefined);
        this.pagination.sortDir = this.SORT.isReversed ? 'DESC' : 'ASC';
        this.pageUpdated.emit(this.pagination);
    }

    async goToLocation(location: string): Promise<void> {
        const pathArray = location.split('/');
        const binder = await this.StudyManager.getBinderByName(this.currentTeam.id, pathArray[0]);
        if (pathArray.length > 1) {
            const folder = await this.StudyManager.getFolderByPath(this.currentTeam.id, binder.id, pathArray);
            this.$state.go('app.team.folder', { teamId: this.currentTeam.id, binderId: folder.binderId, folderId: folder.id });
        }
        else {
            this.$state.go('app.team.folder', { teamId: this.currentTeam.id, binderId: binder.id });
        }
    }

    normalizeAndFormatStatus(inputString: string): string {
        if (inputString === 'COMPLETED'
             || inputString === 'PENDING'
             || inputString === 'RUNNING'
             || inputString === 'RETRYING'
             || inputString === 'UNKNOWN'
             || inputString === 'PAUSED'
             || inputString === 'SCHEDULED') {
            return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
        }
        return this.FAILED_STATE_NAME;
    }
}
