export enum SystemNotificationType {
    DOWNTIME_BANNER = 'downtime-banner',
    RELEASE_NOTES = 'release-notes'
}

export interface SystemNotification {
    id: string;
    title?: string;
    type: SystemNotificationType;
    startDate: string;
    endDate: string;
    content: string;
    footer?: string;
    createdAt: string;
}

export interface SystemNotificationReading {
    id: string;
    userId: string;
    notificationId: string;
    createdAt: string;
}
