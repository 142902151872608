'use strict';

exports.logEntryTypes = {
    TEXT: 'text',
    DATE: 'date',
    SIGNATURE: 'signature',
    TEAM_MEMBER: 'team member',
    MULTISELECT: 'dropdown multi-select',
    SINGLESELECT: 'dropdown single-select'
};

exports.logEntryDetailsTypes = {
    TEXT: 'text',
    SELECT: 'select'
};


exports.logStatuses = {
    FINAL: 'finalized',
    IN_PROGRESS: 'in progress'
};
