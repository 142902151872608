import { Injectable } from '@angular/core';
import { UIRouter, Transition, StateService } from '@uirouter/angular';
import { take } from 'rxjs/operators';
import { Auth0Service } from '@app/shared/sessions/auth0.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class FriendlyForwardService {

    constructor(
        private uiRouter: UIRouter,
        private state: StateService,
        private auth0: Auth0Service,
        private notifications: NotificationsService,
        private location: Location
    ) {}

    init(): void {
        // onStart hook
        this.uiRouter.transitionService.onStart({}, (transition: Transition) => {
            return this.auth0.isAuthenticated$.pipe(take(1)).toPromise()
                .then((isAuthenticated) => {
                    const toState = transition.to();
                    const toParams = transition.params('to');

                    if (toState.data?.requiresAuth && !isAuthenticated) {
                        const returnTo = this.state.href(toState.name, toParams, { absolute: true });
                        return this.state.target('sign-in', { returnTo });
                    }
                    return true;
                });
        });

        // onSuccess hook
        this.uiRouter.transitionService.onSuccess({}, () => {
            const propertyToCatch = 'sessionResumed';
            const searchParams = new URLSearchParams(this.location.path().split('?')[1] || '');

            if (searchParams.has(propertyToCatch)) {
                this.notifications.success('Navigation to last page viewed before automatic sign-out');
                searchParams.delete(propertyToCatch);

                // Update URL without the sessionResumed parameter
                const baseUrl = this.location.path().split('?')[0];
                const newSearch = searchParams.toString();
                const newUrl = newSearch ? `${baseUrl}?${newSearch}` : baseUrl;
                this.location.replaceState(newUrl);
            }
        });
    }
}
