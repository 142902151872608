import * as _ from 'lodash';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import { catchError, map } from 'rxjs/operators';
import { Tag } from '@app/shared/models';
import { Observable } from 'rxjs';

@Injectable()
export class TagsService {

    constructor(
        private http: HttpClient,
        private ApiErrors: ApiErrorsService,
        private Notifications: NotificationsService
    ) {}

    readonly url = {
        tags: (teamId: string) => `/api/teams/${teamId}/tags`,
        tagsUpdate: (teamId: string, tagId: string) => `/api/teams/${teamId}/tags/${tagId}`
    };

    createTag(teamId, tagToCreate): Observable<Tag> {

        return this.http.post<{ tag: Tag; }>(this.url.tags(teamId), tagToCreate)
            .pipe(
                map(
                    ({ tag }) => {
                        this.Notifications.success('Tag Created!');
                        return tag;
                    }
                ),
                catchError((error) => this.ApiErrors.handleError(error))
            );
    }

    updateTag(teamId, tag) {

        return this.http.patch<{ tag: Tag; }>(this.url.tagsUpdate(teamId, tag.id), tag)
            .pipe(
                map(
                    (data) => {
                        this.Notifications.success('Tag Updated!');
                        return data.tag;
                    }
                ),
                catchError((error) => this.ApiErrors.handleError(error))
            );
    }

    deleteTag(teamId, tag) {

        return this.http.delete(this.url.tagsUpdate(teamId, tag.id), tag)
            .pipe(
                map(
                    () => {
                        this.Notifications.success(`Successfully deleted tag ${tag.name}!`);
                    }
                ),
                catchError((error) => this.ApiErrors.handleError(error))
            );
    }

}
