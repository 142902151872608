import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Observable, of } from 'rxjs';
import {
    catchError, filter, finalize, map, take
} from 'rxjs/operators';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import { CurrentSessionService } from '@app/core/current-session.service';
import { BindersService } from '@app/shared/binders/binders.service';
import { FoldersService } from '@app/shared/folders/folders.service';
import {
    GlobalViewTreeParams, GlobalViewCountKey,
    GlobalViewTotalCounts, GetObjectTreeResponse, GlobalViewMonitorReviewFilter
} from '@app/shared/teams/teams.service.types';
import {
    Team, Crumb, Binder, LabeledEntity, Folder, ApiError
} from '@app/shared/models';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { ModalStackHelperService } from '@app/shared/modal-helper/modal-stack-helper.service';
import { sortBrowseTreeLexicographically } from '@app/widgets/sort/sort-browse-tree-lexicographically.util';
import { ModalsService } from '@app/shared/modal-helper/modals.service';

import { TeamService } from '@app/shared/teams/team.service';
import { GlobalViewItem, globalViewItemIsFolder } from '../../components/global-view-item/global-view-item.types';
import { GlobalViewRoot, GlobalViewTreeFlatNode } from './global-view.component.types';

import template from './global-view.component.html';
import styles from './global-view.component.scss';
import { RootSelectComponent } from '../../components/global-view-select-root/global-view-select-root.component';
import { GlobalViewSelectRootEvent } from '../../components/global-view-select-root/global-view-select-root.types';

@Component({
    selector: 'global-view',
    template,
    styles: [String(styles)]
})
export class GlobalViewComponent implements OnInit {
    readonly EXPANDABLE_TREE_LIMIT = 45000;
    private readonly loadingTreeMessageDelay = 3000;
    expandAllEnabled = false;
    loadingTree = false;
    isProcessing = false;
    loadedTree = false;
    loadingDelayTimer;
    busy = false; // busy indicates if in the process of expanding/collapsing tree
    entityTooLarge = {
        state: false,
        entityName: ''
    };

    treeParams: GlobalViewTreeParams = { objectId: null, objectType: null };
    totalCounts: GlobalViewTotalCounts;
    root: GlobalViewRoot;

    currentTeam: Team;
    crumbs: Crumb[];

    treeControl: FlatTreeControl<GlobalViewTreeFlatNode> =
        new FlatTreeControl<GlobalViewTreeFlatNode>(this.getNodeLevel, this.getIsNodeExpandable);

    treeFlattener = new MatTreeFlattener<GlobalViewItem, GlobalViewTreeFlatNode>(
        this.nodeTransformer,
        this.getNodeLevel,
        this.getIsNodeExpandable,
        this.getNodeChildren
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    constructor(
        private $state: StateService,
        private Modals: ModalsService,
        private modalStackHelper: ModalStackHelperService,
        private Binders: BindersService,
        private Folders: FoldersService,
        private Team: TeamService,
        private CurrentSession: CurrentSessionService,
        private Notifications: NotificationsService
    ) {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
    }

    ngOnInit(): void {

        const {
            objectId, objectType, objectName, isRootReselected
        } = this.$state.params;
        this.treeParams = { objectId, objectType };
        this.treeParams.includeDocs = true;
        this.treeParams.includeArchived = false;
        this.loadTree(objectName, isRootReselected);
    }

    private getNodeLevel({ level }: GlobalViewTreeFlatNode): number {
        return level;
    }

    private getIsNodeExpandable({ hasChildren }: GlobalViewTreeFlatNode): boolean {
        return hasChildren;
    }

    private getNodeChildren({ items }: GlobalViewItem): GlobalViewItem[] {
        return items;
    }

    private nodeTransformer(node: GlobalViewItem, level: number): GlobalViewTreeFlatNode {
        return {
            ...node,
            level,
            hasChildren: node && node.items && node.items.length > 0
        };
    }

    loadRootEntityLabels(): void {
        if (this.root.directLabels) {
            return;
        }
        let labelsResult$: Observable<LabeledEntity[]>;
        if (this.root.type === 'binder') {
            labelsResult$ = this.Binders.getDirectLabels(this.currentTeam.id, this.root.id);
        }
        else {
            labelsResult$ = this.Folders.getDirectLabels({
                teamId: this.currentTeam.id, binderId: this.root.binderId, folderId: this.root.id
            });
        }
        labelsResult$.pipe(take(1)).subscribe(
            (labels) => {
                this.root.directLabels = labels;
            },
            this.handleErrorNotification
        );
    }

    filterDocsByName(nameFilter: string): void {
        this.treeParams.nameFilter = nameFilter;
        if (this.treeParams.nameFilter === '' || this.treeParams.nameFilter === null) {
            delete this.treeParams.nameFilter;
        }
        this.loadTree(this.root.name);
    }

    toggleFilter(statFilter: GlobalViewCountKey): void {
        this.treeParams.statFilter = (this.treeParams.statFilter === statFilter) || (statFilter in GlobalViewMonitorReviewFilter)
            ? null : statFilter;
        this.treeParams.monitorReviewFilter = (statFilter in GlobalViewMonitorReviewFilter)
            && (this.treeParams.monitorReviewFilter !== GlobalViewMonitorReviewFilter[statFilter])
            ? GlobalViewMonitorReviewFilter[statFilter] : null;

        this.loadTree(this.root.name);
    }

    chooseRoot():void {
        this.isProcessing = true;
        this.Binders.getBinders(this.currentTeam.id, { includeArchived: false })
            .subscribe((binders) => {
                this.isProcessing = false;
                const modal = this.Modals.show(RootSelectComponent, {
                    class: 'modal-lg',
                    initialState: {
                        loadRoot: () => binders,
                        loadItem: (params = {
                            includeDocs: false,
                            includeArchived: false
                        }) => this.Team.browse(this.currentTeam.id, { ...params })
                            .toPromise()
                            .then((result) => sortBrowseTreeLexicographically(result, 'name') as unknown as (Binder | Folder | Document)[])
                    }
                });

                modal.content.onSubmit.subscribe((target: GlobalViewSelectRootEvent) => {
                    const { type, id, name } = target.item;
                    this.treeParams.objectType = type;
                    this.loadedTree = this.treeParams.objectId === id;
                    this.treeParams.objectId = id;
                    target.onSuccess();
                    this.$state.go('.', { ...this.treeParams, objectName: name, isRootReselected: true });
                });
            });
    }

    private toggleAllNodesExpansionState(node: GlobalViewTreeFlatNode): void {
        this.treeControl.dataNodes.forEach((dataNode, index) => {
            if (index > 0 && globalViewItemIsFolder(dataNode)) {
                dataNode.expanded = !node.expanded;
            }
        });
        node.expanded = !node.expanded;
    }

    onExpandTree(node: GlobalViewTreeFlatNode): void {
        this.treeControl.expandAll();
        this.toggleAllNodesExpansionState(node);
    }

    onCollapseTree(node: GlobalViewTreeFlatNode): void {
        this.treeControl.getDescendants(node)
            .forEach((childNode) => this.treeControl.collapseDescendants(childNode));
        this.toggleAllNodesExpansionState(node);
    }

    onExpandNode(node: GlobalViewTreeFlatNode, isRoot: boolean): void {
        if (!isRoot) {
            node.expanded = true;
        }
        this.treeControl.expand(node);
    }

    onCollapseNode(node: GlobalViewTreeFlatNode, isRoot: boolean): void {
        if (!isRoot) {
            node.expanded = false;
        }
        this.treeControl.collapse(node);
    }

    private loadTree(rootName: string, isRootReselected = false): void {
        this.loadingTree = true;
        this.entityTooLarge = {
            state: false,
            entityName: ''
        };

        this.loadingDelayTimer = setTimeout(() => {
            this.loadingTree && this.Notifications.info('Fetching a lot of documents, it might take us a few more seconds...');
        }, this.loadingTreeMessageDelay);

        this.Team
            .getObjectTree(this.currentTeam.id, this.treeParams)
            .pipe(
                take(1),
                catchError((error) => {

                    const state = this.$state.params;

                    if (error.error.statusCode === 413) {
                        let message = `${rootName} has over 45,000 items and is too big to be shown.<br><br>`;
                        if (isRootReselected) {
                            message += 'You can expand it by clicking the “+” action and select a folder within it, or choose another binder.';
                        }
                        else {
                            message += 'You can select a folder from within it or choose another binder via the "choose binder or folder" button.';
                        }
                        this.entityTooLarge.state = true;
                        this.entityTooLarge.entityName = state.objectName;
                        this.Notifications.error(message);
                    }

                    this.root = null;

                    let itemId = null;
                    if (state.objectType === 'folder') {
                        itemId = { folderId: state.objectId };
                    }
                    else if (state.objectType === 'binder') {
                        itemId = { binderId: state.objectId };
                    }

                    if (itemId) {

                        return this.Folders.browse({
                            teamId: state.teamId,
                            includeDocs: false,
                            includeDecorations: false,
                            includeArchived: false,
                            ...itemId
                        }).pipe(
                            take(1),
                            map((res) => {
                                return { root: res };
                            })
                        );
                    }

                    return of({ root: null });
                }),
                filter<GetObjectTreeResponse>(Boolean),
                map((res) => ({
                    ...res,
                    root: sortBrowseTreeLexicographically(res.root, 'title')
                })),
                finalize(() => {
                    clearTimeout(this.loadingDelayTimer);
                    this.crumbs = this.getCrumbs();
                    this.loadingTree = false;
                    this.loadedTree = true;
                    if (!this.entityTooLarge.state) {
                        this.modalStackHelper.dismissAll();
                    }
                })
            ).subscribe({
                next: (res) => {

                    this.root = res.root;
                    this.dataSource.data = this.entityTooLarge.state
                        ? [undefined] : [this.root as unknown as GlobalViewItem];

                    this.totalCounts = this.totalCounts || res.totalCounts;
                    if (res.totalCounts && ((res.totalCounts[this.treeParams.statFilter || this.treeParams.monitorReviewFilter || 'documents']) < this.EXPANDABLE_TREE_LIMIT)) {
                        this.expandAllEnabled = true;
                    }

                    if (this.expandAllEnabled
                        && (this.treeParams.nameFilter || this.treeParams.statFilter || this.treeParams.monitorReviewFilter)) {
                        this.onExpandTree(this.treeControl.dataNodes[0]);
                    }
                    else {
                        if (this.treeControl.dataNodes && this.treeControl.dataNodes[0]) {
                            this.treeControl.dataNodes[0].expanded = false;
                        }
                    }
                },
                error: () => {

                    const message = 'Error while trying to get entity details';
                    this.Notifications.error(message);
                }
            });
    }

    private getCrumbs(): Crumb[] {
        const crumbs = [];

        if (!this.root) {
            crumbs.push({ name: 'Global View' });
            return crumbs;
        }

        crumbs.push({
            name: 'Global View',
            stateName: 'app.team.global-view',
            stateParams: {
                teamId: this.currentTeam.id,
                objectId: null,
                objectType: null
            },
            icon: 'fa-globe'
        });

        const isBinderRoot = this.root.type === 'binder';
        const binderName = isBinderRoot ? this.root.name : this.root.binderName;

        crumbs.push({
            name: binderName,
            stateName: 'app.team.global-view',
            stateParams: {
                teamId: this.currentTeam.id,
                objectId: this.root.binderId,
                objectType: 'binder',
                objectName: binderName
            },
            icon: 'fa-book u-font-style-italic'
        });

        if (isBinderRoot) {
            return crumbs;
        }

        this.root.path.split('/').forEach((path, index) => {
            crumbs.push({
                name: path,
                stateName: 'app.team.global-view',
                stateParams: {
                    teamId: this.currentTeam.id,
                    objectId: this.root.lineage[index],
                    objectType: 'folder',
                    objectName: path
                },
                icon: 'far fa-folder-open'
            });
        });
        return crumbs;
    }

    private handleErrorNotification({ error }: ApiError): void {
        const { message } = error;
        if (message) {
            return this.Notifications.error(message);
        }

        return this.Notifications.unexpectedError();
    }
}
