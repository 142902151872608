import { LogTemplate } from '@app/shared/models';
import { LogTemplateType } from '../../components/log-templates/components/log-template-type-selector/log-template-type-selector.component.types';

export interface GetLogTemplatesFilter {
    type?: LogTemplateType;
    isActive?: boolean;
    name?: string;
    exactName?: boolean
}

export const enum TemplateSortableProperties {
    NAME = 'name',
    NAME_CANONICAL = 'nameCanonical',
    UPDATED_AT = 'updatedAt',
    CREATED_AT = 'createdAt',
    TYPE = 'type',
    ID = 'id'
}

export const SortDirection = {
    ASC: 'asc',
    DESC: 'desc'
} as const;

export type SortDirectionValues = typeof SortDirection[keyof typeof SortDirection];

export interface GetLogTemplatesParams {
    pageSize?: number;
    next?: string;
    pageNum?: number;
    filter: GetLogTemplatesFilter;
    sortBy?: TemplateSortableProperties;
    sortDir?: SortDirectionValues;
}

export interface GetLogTemplatesResponse {
    items: LogTemplate[];
    next?: string;
    hasNext?: boolean;
    totalCount?: number;
}

export interface MarkLogTemplatesPayload {
    templateIds: string[];
    isActive: boolean;
}
