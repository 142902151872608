import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '@app/shared/shared.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrentSessionService } from '@app/core/current-session.service';
import { EntityModule } from '@app/widgets/entity/entity.module';
import { DocViewerModule } from '@florencehealthcare/doc-viewer';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TeamInboxComponent } from './containers/team-inbox/team-inbox.component';
import { TeamInboxService } from './team-inbox.service';
import { DocumentsListComponent } from './components/documents-list/documents-list.component';
import { InboxDocumentPreviewComponent } from './containers/inbox-document-preview/inbox-document-preview.component';
import { TeamInboxStateService } from './team-inbox.state.service';

@NgModule({
    imports: [
        WidgetsModule,
        UIRouterModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        ScrollingModule,
        TooltipModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        EntityModule,
        DocViewerModule,
        PaginationModule.forRoot()
    ],
    declarations: [
        TeamInboxComponent,
        InboxDocumentPreviewComponent,
        DocumentsListComponent
    ],
    entryComponents: [
        TeamInboxComponent,
        InboxDocumentPreviewComponent
    ],
    exports: [],
    providers: [
        CurrentSessionService,
        TeamInboxService,
        TeamInboxStateService
    ]
})
export class TeamInboxModule {}
