import { NotificationsService } from '@app/core/notifications/notifications.service';
import { Archive, ArchiveStatus } from '@app/shared/models/archive.model';
import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import {
    AbstractControl, FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { notBlank } from '@app/core/form-validators';
import { Folder } from '@app/shared/models';
import style from './destroy-folder.component.scss';
import template from './destroy-folder.component.html';
import { FolderDestroyEvent } from './destroy-folder.component.types';

@Component({
    template,
    styles: [String(style)]
})
export class DestroyFolderComponent implements OnInit {
    @Input() folder: Folder;
    @Input() hasOriginalDocuments: boolean;
    @Input() archive: Archive;
    @Output() onDestroy = new EventEmitter<FolderDestroyEvent>();
    destroyFolderForm: FormGroup;
    isProcessing: boolean;
    constructor(
        private modal: BsModalRef,
        private Notifications: NotificationsService,
        private fb: FormBuilder
    ) {
        this.isProcessing = false;
    }

    ngOnInit(): void {
        this.initForm();
    }

    get canDisplayScheduledText() {
        return this.archive && this.archive.status === ArchiveStatus.scheduled;
    }

    get canDisplayArchivedText() {
        return this.archive && this.archive.status === ArchiveStatus.complete;
    }

    cancel(): void {
        this.modal.hide();
    }

    destroy() {
        this.isProcessing = true;

        if (this.folder.documentCount > 500) {
            this.Notifications.error('Cannot delete folder that has more than 500 documents');
            this.isProcessing = false;
            this.modal.hide();
        }
        else {
            this.onDestroy.emit({
                folder: this.folder,
                reason: this.destroyFolderForm.value.reason
            });
        }

    }

    ctrlHasError(ctrl: AbstractControl): boolean {
        return ctrl.dirty && ctrl.value !== this.folder.name;
    }

    get folderFormCtrls(): { [key: string]: AbstractControl } {
        return this.destroyFolderForm.controls;
    }

    private initForm(): void {
        this.destroyFolderForm = this.fb.group({
            name: ['', [Validators.required, notBlank]],
            reason: ['', [Validators.required, notBlank, Validators.maxLength(250)]]
        });
    }
}
