import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import { downgradeComponent } from '@angular/upgrade/static';
import { logTemplatesRoute } from './log-templates.route';
import { LogTemplateViewComponent } from './containers/log-template-view/log-template-view.component';
import { TeamLogTemplatesComponent } from './containers/team-log-templates/team-log-templates.component';
import { LogTemplateFormComponent } from './components/log-template-form/log-template-form.component';
import { LogTemplateInactiveWarningComponent } from './components/log-template-inactive-warning/log-template-inactive-warning.component';
import { DuplicateLogTemplateComponent } from './components/duplicate-log-template/duplicate-log-template.component';
import { LogTemplateTypeSelectorComponent } from './components/log-template-type-selector/log-template-type-selector.component';
import { DoaLogTemplateCreateComponent } from './containers/doa-log-template-create/doa-log-template-create.component';
import { DoaLogTemplateCreateFormComponent } from './components/doa-log-template/doa-log-template-create-form/doa-log-template-create-form.component';
import { DoaLogTemplateDetailsStepComponent } from './components/doa-log-template/doa-log-template-details-step/doa-log-template-details-step.component';
import { DoaLogTemplateStudyResponsibilitiesStepComponent } from './components/doa-log-template/doa-log-template-study-responsibilities-step/doa-log-template-study-responsibilities-step.component';
import { DoaLogTemplateStudyRolesStepComponent } from './components/doa-log-template/doa-log-template-study-roles-step/doa-log-template-study-roles-step.component';
import { DoaLogTemplateCreateTemplateStepComponent } from './components/doa-log-template/doa-log-template-create-template-step/doa-log-template-create-template-step.component';
import { DoaLogTemplateFormComponent } from './components/doa-log-template-form/doa-log-template-form.component';
import { DoaLogTemplateViewComponent } from './containers/doa-log-template-view/doa-log-template-view.component';

const dependencies = [
    uiRouter
];

export const logTemplatesModule = angular
    .module('app.logTemplates', dependencies)
    .directive('logTemplateForm', downgradeComponent({ component: LogTemplateFormComponent }) as ng.IDirectiveFactory)
    .directive('logTemplateView', downgradeComponent({ component: LogTemplateViewComponent }) as ng.IDirectiveFactory)
    .directive('doaLogTemplateView', downgradeComponent({ component: DoaLogTemplateViewComponent }) as ng.IDirectiveFactory)
    .directive('doaLogTemplateForm', downgradeComponent({ component: DoaLogTemplateFormComponent }) as ng.IDirectiveFactory)
    .directive('doaLogTemplateCreate', downgradeComponent({ component: DoaLogTemplateCreateComponent }) as ng.IDirectiveFactory)
    .directive('doaLogTemplateCreateForm', downgradeComponent({ component: DoaLogTemplateCreateFormComponent }) as ng.IDirectiveFactory)
    .directive('logTemplateDetails', downgradeComponent({ component: DoaLogTemplateDetailsStepComponent }) as ng.IDirectiveFactory)
    .directive('doaLogTemplateStudyResponsibilities', downgradeComponent({ component: DoaLogTemplateStudyResponsibilitiesStepComponent }) as ng.IDirectiveFactory)
    .directive('doaLogTemplateStudyRoles', downgradeComponent({ component: DoaLogTemplateStudyRolesStepComponent }) as ng.IDirectiveFactory)
    .directive('logTemplateColumns', downgradeComponent({ component: DoaLogTemplateCreateTemplateStepComponent }) as ng.IDirectiveFactory)
    .directive('duplicateLogTemplate', downgradeComponent({ component: DuplicateLogTemplateComponent }) as ng.IDirectiveFactory)
    .directive('teamLogTemplates', downgradeComponent({ component: TeamLogTemplatesComponent }) as ng.IDirectiveFactory)
    .directive('logTemplateTypeSelector', downgradeComponent({ component: LogTemplateTypeSelectorComponent }) as ng.IDirectiveFactory)
    .directive('logTemplateInactiveWarning', downgradeComponent({ component: LogTemplateInactiveWarningComponent }) as ng.IDirectiveFactory)
    .config(logTemplatesRoute);
