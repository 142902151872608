import {
    Component,
    OnInit,
    Input,
    Output,
    Inject,
    ViewChild,
    ElementRef,
    EventEmitter
} from '@angular/core';
import { Folder } from '@app/shared/models';
import { SessionsService } from '@app/shared/sessions/sessions.service';
import style from './import-via-email.component.scss';
import template from './import-via-email.component.html';

@Component({
    template,
    styles: [String(style)]
})
export class ImportViaEmailComponent implements OnInit {
    @Input() resolve: { teamId: string; binderId: string; folderId?: string; docId?: string; crumbsEntity: Folder };
    @Input() showMRMWarningBanner = false;
    @Output() dismiss = new EventEmitter<void>();
    @ViewChild('emailDestinationInput', { static: true }) emailDestinationInput: ElementRef;

    private teamId: string;
    private docId: string;
    private folderId: string;
    private binderId: string;
    hasDocumentId: boolean;
    emailDestination = '';
    crumbsEntity: Folder;

    constructor(@Inject('Window') private window: Window, private sessionService: SessionsService) { }

    ngOnInit(): void {
        this.teamId = this.resolve.teamId;
        this.docId = this.resolve.docId;
        this.folderId = this.resolve.folderId;
        this.binderId = this.resolve.binderId;
        this.hasDocumentId = !!this.docId;
        this.emailDestination = this.getEmailDestination();
        this.crumbsEntity = this.resolve.crumbsEntity;

        this.window.setTimeout(this.selectInput.bind(this), 1);
    }

    selectInput(): void {
        this.emailDestinationInput.nativeElement.select();
    }

    private getEmailDestination(): string {
        let destination: string;
        if (this.docId) {
            destination = `${this.teamId}.${this.binderId}.${this.folderId || 'none'}.${this.docId}`;
        }
        else if (this.folderId) {
            destination = `${this.teamId}.${this.binderId}.${this.folderId}`;
        }
        else if (this.binderId) {
            destination = `${this.teamId}.${this.binderId}.none`;
        }

        return destination + this.sessionService.environment.emailConnectorPath;
    }

    copyToClipboard(): void {
        this.selectInput();
        this.window.document.execCommand('copy');
    }

    cancel(): void {
        this.dismiss.emit();
    }
}
