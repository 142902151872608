import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import {
    GetDocumentHistoriesPathParams,
    GetDocumentHistoriesQueryParams,
    GetDocumentHistoryResponse
} from './document-history.service.types';

@Injectable()
export class DocumentHistoryService {
    private readonly url = {
        base: ({
            document, exchangeEventId, pageNum, pageSize, sortDir, sortBy, filterBy, searchTerm
        }: GetDocumentHistoriesPathParams & GetDocumentHistoriesQueryParams) => `/api/documents/${document.id}/versions/${document.version}/history/${exchangeEventId}?pageNum=${pageNum}&pageSize=${pageSize}&sortDir=${sortDir}&sortBy=${sortBy}&filterBy=${filterBy}&searchTerm=${searchTerm}`
    };

    constructor(
        private http: HttpClient
    ) {}

    public getDocumentHistories(
        params: GetDocumentHistoriesPathParams & GetDocumentHistoriesQueryParams
    ): Observable<GetDocumentHistoryResponse> {
        const req = {
            url: this.url.base(params)
        };

        return this.http.get<GetDocumentHistoryResponse>(req.url);
    }
}
