import {
    BrowseNode,
    BrowseTree,
    DocumentSubTypes
} from '@app/shared/models';
import { VirtualTreeFlatNode } from '../virtual-tree/virtual-tree.component.types';

export const itemHasCheckbox = (item: VirtualTreeFlatNode, isItemPlaceholderList = []): boolean => {
    if (item.subType === DocumentSubTypes.placeholder) {
        return true;
    }

    if (item.items?.length) {
        if (item.items.find((element) => element.subType === DocumentSubTypes.placeholder)) {
            return true;
        }

        item.items.forEach((el) => {
            if (el.items?.length) {
                isItemPlaceholderList.push(itemHasCheckbox(el, isItemPlaceholderList));
            }
        });
    }

    return isItemPlaceholderList.some((el) => el === true);

};

const setDueItem = (tree: BrowseNode, selectedItems: BrowseNode, date: Date) => {
    if (tree.subType === DocumentSubTypes.placeholder && tree.documentId.documentId === selectedItems.id) {
        tree.dueDate = date;
        return tree;
    }

    if (tree.type === 'folder' && tree.items?.length) {
        for (let i = 0; i < tree.items.length; i += 1) {
            tree.items[i] = setDueItem(tree.items[i], selectedItems, date);
        }
    }

    return tree;

};


export const assignDueDate = (tree, selectedItems, date): BrowseTree[] => {
    if (tree.length) {
        for (let i = 0; i < tree.length; i += 1) {
            if (tree[i].items?.length) {
                for (let j = 0; j < tree[i].items.length; j += 1) {
                    tree[i].items[j] = setDueItem(tree[i].items[j], selectedItems, date);
                }
            }
            else {
                tree[i] = setDueItem(tree[i], selectedItems, date);
            }
        }
    }

    return tree;

};

export const hasPlaceholderInStructure = (nodes: BrowseNode[]): boolean => {
    if (!nodes || nodes.length === 0) {
        return false;
    }

    for (let i = 0; i < nodes.length; i += 1) {
        if (nodes[i].type === 'document' || hasPlaceholderInStructure(nodes[i].items)) {
            return true;
        }
    }

    return false;
};
