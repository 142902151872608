import * as angular from 'angular';

import { downgradeComponent } from '@angular/upgrade/static';

import { ModalCloseButtonComponent } from './modal-close-button/modal-close-button.component';
import { ModalProgressComponent } from './modal-progress/modal-progress.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';

const dependencies = [];

const modals = angular
    .module('app.widgets.modals', dependencies)
    .directive('modalHeader', downgradeComponent({ component: ModalHeaderComponent }) as ng.IDirectiveFactory)
    .directive('modalCloseButton', downgradeComponent({ component: ModalCloseButtonComponent }) as ng.IDirectiveFactory)
    .directive('modalProgress', downgradeComponent({ component: ModalProgressComponent }) as ng.IDirectiveFactory);

export default modals;
