import {
    Component, Input
} from '@angular/core';

import template from './strikethrough-info-row.component.html';
import styles from './strikethrough-info-row.component.scss';
import { PersonThatStruckThrough } from './strikethrough-info-row.component.types';

@Component({
    selector: 'strikethrough-info-row',
    template,
    styles: [String(styles)]
})
export class StrikeThroughInfoRowComponent {

    @Input() userThatStruckThrough: PersonThatStruckThrough;
    @Input() reasonForStrikeThrough: string;
    @Input() dateWhenStruckThrough: Date;

}
