'use strict';

module.exports = {
    user_update_profile: 'user_update_profile',
    user_manage_alias: 'user_manage_alias',
    user_view_teams: 'user_view_teams',
    user_view_profile: 'user_view_profile',
    user_update_users_subscriptions: 'user_update_users_subscriptions',
    user_view_user_on_team: 'user_view_user_on_team',
    user_view_user_mfa_policy: 'user_view_user_mfa_policy',

    user_view_marketing_notifications: 'user_view_marketing_notifications',
    user_update_marketing_notifications: 'user_update_marketing_notifications',

    user_view_system_notifications: 'user_view_system_notifications',
    user_manage_system_notifications: 'user_manage_system_notifications',

    user_view_user_profile_audit_trails: 'user_view_user_profile_audit_trails',
    user_download_user_profile_audit_trails: 'user_download_user_profile_audit_trails',

    act_on_behalf_of: 'act_on_behalf_of',

    team_manage: 'team_manage',
    team_update_profile: 'team_update_profile',
    team_update_mfa: 'team_update_mfa',
    team_invite: 'team_invite',
    team_uninvite: 'team_uninvite',
    team_view_profile: 'team_view_profile',
    team_rename: 'team_rename',
    team_member: 'team_member',
    team_member_limited: 'team_member_limited',
    team_manage_permissions: 'team_manage_permissions',
    team_manage_tasks: 'team_manage_tasks',
    team_view_audittrail: 'team_view_audittrail',
    team_download_audittrail: 'team_download_audittrail',
    team_manage_sso: 'team_manage_sso',
    team_manage_sip_links: 'team_manage_sip_links',
    team_manage_team_notifications: 'team_manage_team_notifications',
    team_manage_team_security: 'team_manage_team_security',
    team_manage_team_timeout: 'team_manage_team_timeout',

    team_manage_access_control: 'team_manage_access_control',
    team_assign_roles: 'team_assign_roles',
    team_manage_roles_and_permissions: 'team_manage_roles_and_permissions',
    team_view_users_roles_permissions: 'team_view_users_roles_permissions',
    team_run_role_manager: 'team_run_role_manager',
    job_title_required_in_signature_assign: 'job_title_required_in_signature_assign',
    list_requested_signatures: 'list_requested_signatures',

    password_policy_manage: 'password_policy_manage',
    signing_pin_policy_manage: 'signing_pin_policy_manage',
    formfield_placeholders_manage: 'formfield_placeholders_manage',
    signature_options_manage: 'signature_options_manage',
    document_monitor_review_status_manage: 'document_monitor_review_status_manage',
    reset_failed_or_timedout_conversions: 'reset_failed_or_timedout_conversions',
    team_digest_run: 'team_digest_run',

    team_create_tags: 'team_create_tags',
    team_update_tags: 'team_update_tags',
    team_delete_tags: 'team_delete_tags',
    team_view_dashboard: 'team_view_dashboard',

    team_manage_study_profiles: 'team_manage_study_profiles',
    team_create_edit_study_profiles: 'team_create_edit_study_profiles',
    team_view_study_profiles: 'team_view_study_profiles',

    team_manage_study_startup: 'team_manage_study_startup',
    team_create_studies: 'team_create_studies',
    team_import_study_structure_template: 'team_import_study_structure_template',
    team_delete_structure_templates: 'team_delete_structure_templates',
    team_download_structure_templates: 'team_download_structure_templates',

    team_manage_log_templates: 'team_manage_log_templates',
    team_archive_log_templates: 'team_archive_log_templates',
    team_create_log_templates: 'team_create_log_templates',
    team_edit_log_templates: 'team_edit_log_templates',
    team_duplicate_log_templates: 'team_duplicate_log_templates',
    team_view_log_templates: 'team_view_log_templates',

    team_manage_projects: 'team_manage_projects',
    team_import_projects: 'team_import_projects',
    team_create_projects: 'team_create_projects',
    team_update_projects: 'team_update_projects',
    team_delete_projects: 'team_delete_projects',
    team_duplicate_projects: 'team_duplicate_projects',
    team_manage_timelines: 'team_manage_timelines',
    team_build_timelines: 'team_build_timelines',
    team_create_timelines: 'team_create_timelines',
    team_update_timelines: 'team_update_timelines',
    team_delete_timelines: 'team_delete_timelines',
    team_duplicate_timelines: 'team_duplicate_timelines',
    team_assign_to_timelines: 'team_assign_to_timelines',
    team_view_timelines_dashboard: 'team_view_timelines_dashboard',
    team_export_docs_for_integration: 'team_export_docs_for_integration',
    team_create_integrated_audit: 'team_create_integrated_audit',

    team_manage_features: 'team_manage_features',
    team_fix_support_user: 'team_fix_support_user',

    paywalls_manage: 'paywalls_manage',

    create_binder: 'create_binder',
    binder_manage: 'binder_manage',
    binder_update: 'binder_update',
    binder_view: 'binder_view',
    binder_view_binder: 'binder_view_binder',
    binder_read_properties: 'binder_read_properties',
    binder_update_properties: 'binder_update_properties',
    binder_rename: 'binder_rename',
    binder_duplicate: 'binder_duplicate',
    binder_destroy: 'binder_destroy',
    binder_manage_tasks: 'binder_manage_tasks',
    binder_view_audittrail: 'binder_view_audittrail',
    binder_download_audittrail: 'binder_download_audittrail',
    binder_import_templates: 'binder_import_templates',

    create_folder: 'create_folder',
    folder_manage: 'folder_manage',
    folder_update: 'folder_update',
    folder_view: 'folder_view',
    folder_view_folder: 'folder_view_folder',
    folder_read_properties: 'folder_read_properties',
    folder_update_properties: 'folder_update_properties',
    folder_rename: 'folder_rename',
    folder_duplicate: 'folder_duplicate',
    folder_move: 'folder_move',
    folder_destroy: 'folder_destroy',
    folder_manage_tasks: 'folder_manage_tasks',
    folder_view_audittrail: 'folder_view_audittrail',
    folder_download_audittrail: 'folder_download_audittrail',

    create_document: 'create_document',
    document_replace: 'replace_document',
    document_send_integrated_system: 'document_send_integrated_system',
    document_send_team: 'document_send_team',
    document_send_sip: 'document_send_sip',
    document_update_external_status: 'document_update_external_status',
    document_create_log: 'document_create_log',
    document_manage: 'document_manage',
    document_update: 'document_update',
    document_update_properties: 'document_update_properties',
    document_update_category_and_status: 'document_update_category_and_status',
    document_update_doc_category: 'document_update_doc_category',
    document_update_doc_status: 'document_update_doc_status',
    document_mark_unmark_phi: 'document_mark_unmark_phi',
    document_view_pii: 'document_view_pii',
    document_view_non_pii: 'document_view_non_pii',
    document_view_deleted_pii_doc: 'view_deleted_pii_doc',
    document_view_deleted_non_pii_doc: 'view_deleted_non_pii_doc',
    document_destroy: 'document_destroy',
    document_download_non_pii_doc: 'document_download_non_pii_doc',
    document_download_pii_doc: 'document_download_pii_doc',
    document_duplicate: 'document_duplicate',
    document_move: 'document_move',
    document_rename: 'document_rename',
    document_annotate: 'document_annotate',
    document_sign: 'document_sign',
    document_request_signature: 'document_request_signature',
    document_stamp: 'document_stamp',
    document_redact: 'document_redact',
    document_add_text: 'document_add_text',
    document_highlight: 'document_highlight',
    document_copy_text: 'document_copy_text',
    document_finalize_form: 'document_finalize_form',
    document_log_manage: 'document_log_manage',
    document_log_update_details: 'document_log_update_details',
    document_log_entries_annotate: 'document_log_entries_annotate',
    document_log_entries_create: 'document_log_entries_create',
    document_log_entries_edit: 'document_log_entries_edit',
    document_log_entries_sign: 'document_log_entries_sign',
    document_log_entries_striketrough: 'document_log_entries_striketrough',
    document_manage_doc_dates: 'document_manage_doc_dates',
    document_update_due_date: 'document_update_due_date',
    document_update_exp_date: 'document_update_exp_date',
    document_set_due_date: 'document_set_due_date',
    document_set_exp_date: 'document_set_exp_date',
    document_manage_tasks: 'document_manage_tasks',
    document_view_audittrail: 'document_view_audittrail',
    document_download_audittrail: 'document_download_audittrail',
    document_assign_tags: 'document_assign_tags',
    document_create_training_assignment: 'document_create_training_assignment',
    document_manipulate_pages: 'document_manipulate_pages',
    document_rotate_pages: 'document_rotate_pages',
    document_delete_and_reorder_pages: 'document_delete_and_reorder_pages',

    document_manage_document_locking: 'document_manage_document_locking',
    document_lock: 'document_lock',
    document_unlock: 'document_unlock',
    document_move_locked: 'document_move_locked',

    document_manage_approval_workflow: 'document_manage_approval_workflow',
    document_approve: 'document_approve',
    document_reject: 'document_reject',
    document_cancel: 'document_cancel',
    document_initiate_approval_workflow: 'document_initiate_approval_workflow',

    document_manage_monitor_reviews: 'document_manage_monitor_reviews',
    document_approve_monitor_review: 'document_approve_monitor_review',
    document_reviewed_monitor_review: 'document_reviewed_monitor_review',
    document_comment_on_monitor_review: 'document_comment_on_monitor_review',
    document_create_close_query: 'document_create_close_query',
    document_view_monitor_review: 'document_view_monitor_review',
    document_share_via_document_sharing: 'document_share_via_document_sharing',
    document_sharing_create_audit_trail: 'document_sharing_create_audit_trail',
    document_sharing_view: 'document_sharing_view',
    document_sharing_manage: 'document_sharing_manage',
    document_sharing_share_docs: 'document_sharing_share_docs',

    manage_team_inbox: 'manage_team_inbox',
    view_team_inbox: 'view_team_inbox',
    perform_action_in_team_inbox: 'perform_action_in_team_inbox',

    create_document_shortcut: 'create_document_shortcut',

    document_qc_review_manage: 'document_qc_review_manage',
    document_qc_review_create_edit: 'document_qc_review_create_edit',
    document_conduct_qc_review: 'document_conduct_qc_review',
    document_qc_review_administrator: 'document_qc_review_administrator',

    download_manage: 'download_manage',
    download_view: 'download_view',
    download_update: 'download_update',
    download_delete: 'download_delete',
    download_download: 'download_download',

    announcement_send: 'announcement_send',
    announcement_view: 'announcement_view',

    label_manage: 'label_manage',
    label_list: 'label_list',
    label_assign: 'label_assign',
    label_create: 'label_create',
    label_edit: 'label_edit',
    label_delete: 'label_delete',
    label_value_manage: 'label_value_manage',
    label_value_add: 'label_value_add',
    label_value_update: 'label_value_update',
    label_value_delete: 'label_value_delete',

    archive_manage: 'archive_manage',
    archive_schedule: 'archive_schedule',
    archive_download: 'archive_download',
    archive_update: 'archive_update',
    archive_view: 'archive_view',

    manage_blinded_unblinded_roles: 'manage_blinded_unblinded_roles',

    manage_study_roles: 'manage_study_roles',
};
