import { StateService } from '@uirouter/angular';
import { Component, Input, OnInit } from '@angular/core';
import { Document, Timeline } from '@app/shared/models';
import { TeamService } from '@app/shared/teams/team.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { orderBy } from 'lodash';
import template from './document-timeline-list.component.html';
import { SORT } from '../../../../core/constants';

@Component({
    selector: 'document-timeline-list',
    template
})
export class DocumentTimelineListComponent implements OnInit {

    @Input() document: Document;
    @Input() timelines: Timeline[];
    @Input() pending: boolean;
    protected isProcessing = false;
    SORT = SORT;
    private sortables = ['projectName', 'timelineName'];

    constructor(
        private $state: StateService,
        private Teams: TeamService,
        private modalRef: BsModalRef
    ) {}

    ngOnInit(): void {
        this.sortTimelinesBy(this.sortables[0], false);
    }

    cancel(): void {
        this.modalRef.hide();
    }

    canViewTimeline() {
        return this.Teams.canManageIndividualTimeline();
    }

    openTimeline(timeline: Timeline) {
        if (this.canViewTimeline()) {
            this.$state.go('app.team.manage-project-timeline', {
                teamId: timeline.teamId,
                projectId: timeline.projectId,
                timelineId: timeline.timelineId
            });
            this.modalRef.hide();
        }
    }

    openProject(teamId: string, projectId: string) {
        this.$state.go('app.team.manage-project', {
            teamId,
            projectId
        });
        this.modalRef.hide();
    }

    sortTimelinesBy(property: string, isReversed?: boolean) {
        SORT.set(property, isReversed);
        this.timelines = orderBy(this.timelines, property, this.SORT.isReversed ? 'desc' : 'asc');
    }
}
