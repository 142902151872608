
import {
    Component, Input, Output, EventEmitter, OnInit
} from '@angular/core';
import {
    AbstractControl, FormBuilder, Validators, FormGroup
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as CustomValidators from '@app/core/form-validators';

import { REGEX } from '@app/core/constants';
import { forbiddenChars } from '@app/core/constants/messages';

import { LogTemplatesService } from '@app/shared/log-templates/log-templates.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { take } from 'rxjs/operators';
import { LogTemplate } from '@app/shared/models';
import template from './duplicate-log-template.component.html';
import styles from './duplicate-log-template.component.scss';
import { LogTemplateDuplicatedEvent } from './duplicate-log-template.component.types';
import { LogTemplateType } from '../log-template-type-selector/log-template-type-selector.component.types';

@Component({
    selector: 'duplicate-log-template',
    template,
    styles: [String(styles)]
})
export class DuplicateLogTemplateComponent implements OnInit {
    @Input() originalTemplateName: string;
    @Input() originalTemplateId: string;
    @Input() teamId: string;
    @Output() dismiss = new EventEmitter<void>();
    @Output() duplicated = new EventEmitter<LogTemplateDuplicatedEvent>();
    public duplicateTemplateForm: FormGroup;

    readonly errorMessages = {
        length: 'Maximum of 50 characters exceeded',
        required: 'Required field',
        pattern: `The following characters are not allowed: ${forbiddenChars}`,
        blank: 'Value cannot be blank'
    }

    readonly MAX_NAME_LENGTH = 50;

    constructor(
        private fb: FormBuilder,
        private LogTemplates: LogTemplatesService,
        private Notifications: NotificationsService,
        public modal: BsModalRef
    ) { }

    get newTemplateNameCtrl(): AbstractControl {
        return this.duplicateTemplateForm.controls.newTemplateName;
    }

    ngOnInit(): void {
        this.duplicateTemplateForm = this.fb.group({
            newTemplateName: ['', [
                Validators.required,
                Validators.pattern(REGEX.names),
                Validators.maxLength(this.MAX_NAME_LENGTH),
                CustomValidators.notBlank
            ]]
        });
    }

    cancel(): void {
        this.modal.hide();
    }

    onDuplicate(): void {
        const { newTemplateName: newName } = this.duplicateTemplateForm.value;

        this.LogTemplates.cloneLogTemplate(this.teamId.toString(), this.originalTemplateId.toString(), newName)
            .pipe(take(1))
            .subscribe((duplicatedTemplate: LogTemplate) => {
                this.duplicated.emit({ logTemplate: duplicatedTemplate });
                const successMessage = duplicatedTemplate.templateType === LogTemplateType.ELOG
                    ? 'Log template duplicated'
                    : 'DOA Log template duplicated';
                this.Notifications.success(successMessage);
                this.modal.hide();
            }, ({ error }) => {
                this.Notifications.error(error.message || 'Error occurred while duplicating log template');
                this.modal.hide();
            });
    }
}
