import {
    User, Document, SignatureRequest, LogEntry, Team, LogEntryTypes, Signature, DeclineReasons
} from '@app/shared/models';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentDeclineEventEmitter } from './document-decline.component.types';
import template from './document-decline.component.html';

@Component({
    selector: 'document-decline',
    template
})

export class DeclineDocumentComponent implements OnInit {

    @Input() doc: Document
    @Input() pendingSignature: SignatureRequest;
    @Input() currentUser: User;
    @Input() currentTeam: Team;
    @Input() logEntry: LogEntry;
    @Input() columnName: string;

    @Output() onDecline = new EventEmitter<DocumentDeclineEventEmitter>();


    columns?: string[];
    declineDocumentForm: FormGroup;
    isProcessing = false;

    readonly lengthError = 'Length must be between 1 and 500 characters';
    readonly reasonRequiredError = 'Reason is required';

    constructor(
        private fb: FormBuilder,
        private Modal: BsModalRef
    ) {
    }

    ngOnInit(): void {
        this.initLogEntryData();
        this.initForm();
    }

    private initLogEntryData(): void {
        this.columns = this.logEntry && this.logEntry.columns.reduce((columns: string[], column) => {
            const { type, value, signatureRequest } = column;
            const isDeclinable = type === LogEntryTypes.signature
                && (!value || ((value as Signature).status === 'Declined'))
                && signatureRequest
                && signatureRequest.userId === this.currentUser.id;

            return isDeclinable ? columns.concat(column.name) : columns;
        }, []);
    }

    initForm() {
        this.declineDocumentForm = this.fb.group({
            column: [this.logEntry && this.columns.length > 0 ? this.columns[0] : '', [this.logEntry ? Validators.required : Validators.nullValidator]],
            reason: ['', [Validators.required, Validators.maxLength(500)]]
        });
    }

    cancel(): void {
        this.Modal.hide();
    }

    save(): void {
        if (this.declineDocumentForm.invalid) {
            return;
        }

        this.isProcessing = true;
        this.onDecline.emit({
            signature: {
                email: this.currentUser.email,
                status: 'Declined',
                reason: DeclineReasons.decline,
                teamId: this.currentTeam.id,
                logEntryId: this.logEntry && this.logEntry.id && this.logEntry.id.logEntryId,
                column: this.declineDocumentForm.get('column').value,
                signatureComment: this.declineDocumentForm.get('reason').value
            },
            onSuccess: () => {
                this.Modal.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        });
    }
}
