'use strict';

module.exports = {
    review: 'Review',
    acknowledge: 'Acknowledge',
    approval: 'Approval',
    responsibility: 'Responsibility',
    authorship: 'Authorship',
    certifiedCopy: 'Certified Copy'
};
