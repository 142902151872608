import {
    Component, Input, EventEmitter, Output, OnInit
} from '@angular/core';
import {
    User, SigningReasons, Document, Team, SignatureRequest, LogEntry, DocumentSubTypes, LogEntryTypes, Signature
} from '@app/shared/models';
import { SIGNING_REASONS } from '@app/core/constants';
import { PasswordOrPinExpiryService } from '@app/shared/password-or-pin-expiry/password-or-pin-expiry.service';
import {
    FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors
} from '@angular/forms';
import { DocumentSignData, SignDocumentCloseReason, SignDocumentSaveEvent } from './sign-document.component.types';
import template from './sign-document.component.html';
import styles from './sign-document.component.scss';

@Component({
    selector: 'sign-document',
    template,
    styles: [String(styles)]
})
export class SignDocumentComponent implements OnInit {
    @Input() doc: Document;
    @Input() currentTeam: Team;
    @Input() currentUser: User;
    @Input() pendingSignature?: SignatureRequest | null;
    @Input() logEntry: LogEntry | undefined;
    @Input() selectedColumnName: string | undefined;
    @Input() jobTitle: string;

    @Output() save = new EventEmitter<SignDocumentSaveEvent>();
    @Output() dismiss = new EventEmitter<SignDocumentCloseReason>();

    form: FormGroup;

    isSigningLog: boolean;
    isSigningLogEntry: boolean;

    isSigningPasscodeExpired = false;
    isCertifiedCopy: boolean;
    signingPassphrase = '';
    passwordOrPasscode: 'Signing passcode' | 'Password';
    reasons = SIGNING_REASONS.reasons as SigningReasons[];
    columns: string[] = [];
    preselectedReason: SigningReasons | undefined;

    isProcessing = false;
    submitDisabled = true;

    constructor(
        private fb: FormBuilder,
        private PasswordOrPinExpiry: PasswordOrPinExpiryService
    ) {}

    ngOnInit(): void {
        this.configurePasswordOrPasscodeDetails();
        this.initLogEntryData();
        this.resolveCurrentPendingSignature();
        this.resolvePreselectedReason();
        this.initForm();
        this.resolveCertifiedCopy();

        this.form.controls.jobTitle.disable();
    }

    saveForm(): boolean {
        if (this.form.invalid || this.form.pristine || this.isProcessing) {
            return;
        }

        this.isProcessing = true;

        const passwordKey = this.currentUser.isRemote ? 'signingPasscode' : 'password';

        const signature: DocumentSignData = {
            email: this.currentUser.email,
            status: 'Signed',
            reason: this.form.controls.reason.value,
            teamId: this.currentTeam.id,
            logEntryId: this.logEntry && this.logEntry.id && this.logEntry.id.logEntryId,
            column: this.form.controls.column && this.form.controls.column.value,
            [passwordKey]: this.form.controls.signingPassphrase.value,
            jobTitle: this.form.controls.jobTitle.value
        };

        this.save.emit({
            data: signature,
            onSuccess: () => this.dismissModal('signed'),
            onError: () => {
                this.isProcessing = false;
            }
        });
    }

    dismissModal(dismissReason?: SignDocumentCloseReason): void {
        this.dismiss.emit(dismissReason);
    }

    private resolveCurrentPendingSignature(): void {
        this.pendingSignature = this.pendingSignature || this.doc.pendingSignatures.find((signature) => {
            if (signature.user.email !== this.currentUser.email) {
                return false;
            }

            if (this.isSigningLog && signature.entryId) {
                return false;
            }

            if (this.isSigningLogEntry && (!signature.entryId || signature.entryId !== this.logEntry.id.logEntryId)) {
                return false;
            }

            return true;
        });
    }

    private resolveCertifiedCopy(): void {
        this.isCertifiedCopy = this.form.controls.reason.value === SigningReasons.certifiedCopy;
    }

    private initForm(): void {
        const controlsConfig: Record<string, [string, ((control: AbstractControl) => ValidationErrors)[]]> = {};

        if (this.isSigningLogEntry) {
            controlsConfig.column = [this.selectedColumnName, [Validators.required]];
        }

        controlsConfig.reason = [this.preselectedReason, [Validators.required]];

        controlsConfig.signingPassphrase = ['', [
            Validators.required
        ]];

        controlsConfig.jobTitle = [this.jobTitle, [Validators.required]];

        this.form = this.fb.group(controlsConfig);

        this.form.controls.reason.valueChanges.subscribe(() => this.resolveCertifiedCopy());
    }

    private configurePasswordOrPasscodeDetails(): void {
        this.passwordOrPasscode = this.currentUser.isRemote ? 'Signing passcode' : 'Password';
        this.isSigningPasscodeExpired = this.currentUser.isRemote && this.PasswordOrPinExpiry.isExpired(
            this.currentTeam.settings.signingPINPolicy,
            this.currentUser.lastSigningPasscodeReset
        );
    }

    private initLogEntryData(): void {
        this.isSigningLog = this.doc.subType === DocumentSubTypes.log && !this.logEntry;
        this.isSigningLogEntry = this.doc.subType === DocumentSubTypes.log && !!this.logEntry;

        this.columns = this.isSigningLogEntry && this.logEntry.columns.reduce((columns: string[], column) => {
            const { type, value, signatureRequest } = column;

            const isSignable = type === LogEntryTypes.signature
                && (!value || (value as Signature).status === 'Declined')
                && signatureRequest
                && (signatureRequest.userId === this.currentUser.id && !column.signatureRequest.isDue);

            return isSignable ? columns.concat(column.name) : columns;
        }, []);

        this.selectedColumnName = this.columns.length === 1 ? this.columns[0] : this.selectedColumnName;

        if (this.isSigningLogEntry && !this.selectedColumnName) {
            const signPendingColumn = this.logEntry.columns.find((column) => column.signatureRequest
            && (column.signatureRequest.userId === this.currentUser.id && !column.signatureRequest.isDue));

            if (signPendingColumn) {
                this.selectedColumnName = signPendingColumn.name;
            }
        }

    }

    private resolvePreselectedReason(): void {
        this.preselectedReason = this.getPreselectedReason();
    }

    private getPreselectedReason(): SigningReasons | undefined {
        if (this.pendingSignature) {
            return this.pendingSignature.reason;
        }
        if (this.selectedColumnName) {
            const preselectedColumn = this.logEntry.columns.find(({ name }) => name === this.selectedColumnName);
            return preselectedColumn.signatureRequest && preselectedColumn.signatureRequest.reason;
        }
    }
}
