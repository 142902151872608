import { Component } from '@angular/core';
import template from './mrm-warning-banner.component.html';
import styles from './mrm-warning-banner.component.scss';

@Component({
    selector: 'mrm-warning-banner',
    template,
    styles: [String(styles)]
})
export class MrmWarningBannerComponent {
}
