import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    ViewChild,
    ElementRef,
    OnChanges,
    SimpleChanges
} from '@angular/core';

import template from './dynamic-filter.component.html';
import styles from './dynamic-filter.component.scss';

export interface DynamicFilterTypeItem {
    filterField: string;
    label: string;
}

@Component({
    selector: 'dynamic-filter',
    template,
    styles: [String(styles)]
})
export class DynamicFilterComponent implements OnInit, OnChanges {
    buttonHidden = true;
    @ViewChild('input', { static: true }) input: ElementRef;

    @Input() query = '';
    @Input() filterTypes: DynamicFilterTypeItem[] = [];
    @Input() selectedFilterType: DynamicFilterTypeItem;
    @Input() showFilterIcon? = true;
    @Input() customPlaceholder?: string;
    @Input() customSearchIcon?: string;
    @Output() filter = new EventEmitter<string>();
    @Output() filterTypeChange = new EventEmitter<DynamicFilterTypeItem>();

    ngOnInit(): void {
        this.input.nativeElement.value = this.query;
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges.query) {
            this.input.nativeElement.value = simpleChanges.query.currentValue || '';
        }
    }

    onFilterTypeSelect(filterType: DynamicFilterTypeItem): void {
        if (this.selectedFilterType && this.selectedFilterType.filterField === filterType.filterField) {
            return;
        }
        this.selectedFilterType = filterType;
        this.filterTypeChange.emit(filterType);
    }

    emit(): void {
        this.filter.emit(this.input.nativeElement.value);
    }

    keyPress($event: KeyboardEvent): void {
        // enter key pressed or input value emptied
        if ($event.keyCode === 13 || !this.input.nativeElement.value) {
            this.emit();
        }
    }

    focus(): void {
        this.buttonHidden = false;
    }

    blur(): void {
        this.buttonHidden = true;
    }

    get placeholderText() {
        return this.customPlaceholder || 'Filter by...';
    }
}
