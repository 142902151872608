import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import template from './search-select-create.component.html';
import styles from './search-select-create.component.scss';

@Component({
    selector: 'search-select-create',
    template,
    styles: [String(styles)]
})
export class SearchSelectCreateComponent implements OnInit, OnChanges {
    @Input() items: string[] = [];
    @Input() placeholder = 'Search...';
    @Output() selectItem = new EventEmitter<string>();

    public filteredItems$: Observable<string[]>;
    public selectedItem = '';
    public isCreatingNew = false;
    public showDropdown = false;

    ngOnInit(): void {
        this.filteredItems$ = of(this.items.sort());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.items) {
            this.filteredItems$ = of(changes.items.currentValue.sort());
        }
    }

    onSearchChange(query: string): void {
        const lowercaseQuery = query.toLowerCase();
        this.filteredItems$ = of(this.items).pipe(
            map((items) => items.filter((item) => item.toLowerCase().includes(lowercaseQuery)))
        );
        this.updateCreateOption(query);
    }

    updateCreateOption(query: string): void {
        const isMatch = this.items.some((item) => item.toLowerCase() === query.toLowerCase());
        this.isCreatingNew = !isMatch && query.length > 0;
    }

    onSelect(item: string): void {
        this.selectItem.emit(item);
        this.clearAndCloseDropdown();
    }

    onCreateNewRole(): void {
        if (!this.isCreatingNew) {
            return;
        }
        this.selectItem.emit(this.selectedItem.trim());
        this.clearAndCloseDropdown();
        this.isCreatingNew = false;
    }

    clearAndCloseDropdown(): void {
        this.selectedItem = '';
        this.onSearchChange(this.selectedItem);
        this.showDropdown = false;
    }

    openDropdown(): void {
        this.showDropdown = true;
    }

    closeDropdown(): void {
        setTimeout(() => {
            this.showDropdown = false;
        }, 200);
    }
}
