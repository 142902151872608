import * as _ from 'lodash';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TeamService } from '@app/shared/teams/team.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { PaginationPropertiesService } from '@app/shared/pagination-properties/pagination-properties.service';
import { Team, User } from '@app/shared/models';
import {
    CreateRolesTemplatesAuditParams,
    GetRolesTemplatesAuditsResponse,
    GetRolesTemplatesAuditsResultsResponse,
    RolesAndUsersCreateParams,
    RolesAndUsersCreateResponse,
    RolesTemplatesAudit,
    RolesTemplatesAuditResult,
    RolesToAssignToUsers
} from '@app/shared/teams/teams.service.types';
import { HttpErrorResponse } from '@angular/common/http';
import { RequestPreviewRoleTemplatesComponent } from '../../components/request-preview-role-templates/request-preview-role-templates.component';
import template from './manage-roles-templates.component.html';
import style from './manage-roles-templates.component.scss';
import { AssignUsersToRolesParams, AssignUsersToRolesPreviewEvent, RolesTemplatesStudyNumberOrTemplateEvent } from './manage-roles-templates.types';


@Component({
    selector: 'manage-roles-templates',
    template,
    styles: [String(style)]
})
export class ManageRolesTemplatesComponent implements OnInit, OnDestroy {

    currentTeam: Team;
    currentUser: User;

    manageType: string;

    readonly MANAGE_OPTIONS = {
        byStudyNumber: 'Roles by Study Number',
        byTemplates: 'Audit Roles',
        assignUsers: 'Assign Users to Roles'
    };

    pagination = {
        pageNum: 1,
        pageSize: 20
    };

    STATES = {
        pending: {
            id: 'pending',
            text: 'Pending'
        },
        inProgress: {
            id: 'inProgress',
            text: 'In progress'
        },
        completed: {
            id: 'completed',
            text: 'Complete'
        },
        failed: {
            id: 'failed',
            text: 'Failed'
        }
    };

    loadingResults: boolean;
    showingAuditDetails: boolean;

    maxSize: number;
    showBoundaryLinkNumbers: boolean;

    auditResults: RolesTemplatesAuditResult[] | RolesToAssignToUsers;
    previewInfoData: RolesTemplatesAudit;
    submitedParams: RolesAndUsersCreateParams | CreateRolesTemplatesAuditParams;
    resultType: string;

    previewInfoDataError: HttpErrorResponse | GetRolesTemplatesAuditsResponse;
    auditError: RolesAndUsersCreateResponse | GetRolesTemplatesAuditsResultsResponse;

    practiceNamesPretty: string;

    timer: NodeJS.Timeout;

    constructor(
        private Teams: TeamService,
        private CurrentSession: CurrentSessionService,
        private PaginationProperties: PaginationPropertiesService,
        private Modals: ModalsService
    ) {

        this.manageType = this.MANAGE_OPTIONS.byStudyNumber;
        this.loadingResults = false;
        this.showingAuditDetails = false;

        this.maxSize = this.PaginationProperties.getMaxSize();
        this.showBoundaryLinkNumbers = this.PaginationProperties.showBoundaryLinkNumbers();
    }

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.currentUser = this.CurrentSession.getCurrentUser();
        this._getAudits(this.currentUser.id);
    }

    ngOnDestroy(): void {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    setManageType(type: string) {
        this.manageType = type;
        this.auditResults = null;
        this.pagination.pageNum = 1;

        if (!this.isManageType(this.MANAGE_OPTIONS.assignUsers) && this.previewInfoData) {
            this._getRolesTemplatesAuditResults();
        }
    }

    isManageType(type: string) {
        return this.manageType === type;
    }

    alreadyAssignedEmails(audit: RolesTemplatesAuditResult | RolesToAssignToUsers) {
        if ('roles' in this.submitedParams) {
            const role = _.find(this.submitedParams.roles, { roleName: audit.roleName });

            if ('usersAssignments' in audit && audit.usersAssignments.length && audit.usersAssignments.length !== role.userEmails.length) {
                return role.userEmails.length - audit.usersAssignments.length;
            }
        }

        return 0;
    }

    previewAssignRolesToUsers(auditParams: AssignUsersToRolesPreviewEvent) {
        const action = 'preview';

        const assignUsersToRolesParams = {
            action,
            ...auditParams
        };

        this._previewAssignUsersToRoles(assignUsersToRolesParams);
    }

    createAssignRolesToUsers(params: AssignUsersToRolesPreviewEvent) {
        const action = 'create';
        const assignUsersToRolesParams = {
            action,
            ...params
        };

        this._createAssignUsersToRoles(assignUsersToRolesParams);
    }

    previewRolesTemplatesByStudyNumberOrTemplate(auditParams: RolesTemplatesStudyNumberOrTemplateEvent) {
        const action = 'preview';
        this._createAudit(action, auditParams);
    }

    create() {
        const action = 'create';
        this._createAudit(action, this.previewInfoData);
    }

    _createAudit(type: string, params: RolesTemplatesStudyNumberOrTemplateEvent) {

        const previewParams: CreateRolesTemplatesAuditParams = {
            ...params,
            action: type,
            requestedOn: new Date(),
            userId: this.currentUser.id
        };

        this.resultType = type;
        this.submitedParams = { ...previewParams };

        if (type === 'preview' && this.previewInfoData) {

            const previewModal = this.Modals.show(RequestPreviewRoleTemplatesComponent, {
                class: 'modal-lg',
                animated: false,
                initialState: {}
            });

            previewModal.content.onPreviewEvent.subscribe(() => {
                this._createRolesTemplatesAudit(previewParams);
                previewModal.hide();
            });
            return;
        }

        this._createRolesTemplatesAudit(previewParams);
    }

    _createRolesTemplatesAudit(params: CreateRolesTemplatesAuditParams) {
        this.Teams.createRolesTemplatesAudit(params)
            .subscribe(
                () => {
                    this.previewInfoDataError = null;
                },
                ({ error }) => {
                    this.previewInfoData = null;
                    this.previewInfoDataError = _.cloneDeep(error);
                }
            );
    }

    _previewAssignUsersToRoles(params: AssignUsersToRolesParams) {
        this.resultType = params.action;
        this.pagination.pageNum = 1;

        const previewParams: RolesAndUsersCreateParams = {
            ...params,
            userId: this.currentUser.id
        };
        this.submitedParams = { ...previewParams };

        this.Teams.rolesAndUsersCreate(previewParams)
            .subscribe(
                (data: RolesAndUsersCreateResponse) => {
                    this.auditResults = _.cloneDeep(data.roles || data.rolesTemplatesAuditResults);
                    this.auditError = null;
                },
                ({ data }) => {
                    this.auditResults = null;
                    this.auditError = _.cloneDeep(data);
                }
            );
    }

    _createAssignUsersToRoles(params: AssignUsersToRolesParams) {
        this.resultType = params.action;
        this.pagination.pageNum = 1;

        const createParams: RolesAndUsersCreateParams = {
            ...params,
            userId: this.currentUser.id
        };

        this.Teams.rolesAndUsersCreate(createParams)
            .subscribe(
                (data: RolesAndUsersCreateResponse) => {
                    this.auditResults = _.cloneDeep(data.roles || data.rolesTemplatesAuditResults);
                    this.auditError = null;
                },
                ({ data }) => {
                    this.auditResults = null;
                    this.auditError = _.cloneDeep(data);
                }
            );
    }

    _getAudits(userId: string) {
        this._getRolesTemplatesAudit(userId);
        this.timer = setInterval(() => this._getRolesTemplatesAudit(userId), 5000);
    }

    _getRolesTemplatesAudit(userId: string) {
        this.Teams.getRolesTemplatesAudits(userId)
            .subscribe(
                (data: GetRolesTemplatesAuditsResponse) => {
                    if (!this.previewInfoData
                    || this.previewInfoData.id !== data.rolesTemplatesAudits[0].id
                    || this.previewInfoData.status !== data.rolesTemplatesAudits[0].status) {

                        [this.previewInfoData] = data.rolesTemplatesAudits;
                        this.previewInfoDataError = null;

                        this._getRolesTemplatesAuditResults();
                    }
                },
                (data) => {
                    this.previewInfoData = null;
                    this.previewInfoDataError = _.cloneDeep(data);
                }
            );
    }

    _getRolesTemplatesAuditResults() {
        const params = {
            userId: this.previewInfoData.userId,
            id: this.previewInfoData.id
        };

        this.Teams.getRolesTemplatesAuditsResults(params)
            .subscribe(
                (resultsData: GetRolesTemplatesAuditsResultsResponse) => {
                    this.resultType = this.previewInfoData.action;
                    this.pagination.pageNum = 1;
                    this.auditResults = resultsData.rolesTemplatesAuditResults;
                    this.auditError = null;
                },
                (resultsData) => {
                    this.auditResults = null;
                    this.auditError = _.cloneDeep(resultsData);
                }
            );
    }

    clear() {
        this.auditResults = null;
        this.submitedParams = null;
        this.pagination.pageNum = 1;
    }

    noEntitiesMissing(entries: { email: string; missing?: boolean}[]) {
        return !_.find(entries, ['missing', true]);
    }

    showAuditForm() {
        this.showingAuditDetails = false;
    }

    showPracticeNames() {

        const { practiceNames } = this.previewInfoData.filters;

        if (practiceNames.length === 1) {
            return `${practiceNames[0]}`;
        }
        if (practiceNames.length > 1) {

            const listPracticeNames = practiceNames.map((name) => ` ${name}`);
            this.practiceNamesPretty = `${listPracticeNames}`;

            return `${practiceNames[0]}, and ${practiceNames.length - 1} others`;
        }
        return 'No practice name specified';
    }

    showAuditDetails() {
        this.showingAuditDetails = true;
    }

    capitalizeText(text: string) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    switchColumnNames() {
        if (this.previewInfoData.filters.rolesTemplateName) {
            return 'Role Template Name';
        }
        return 'Study Number';
    }

}
