'use strict';

module.exports = {
    DELETE: 500,
    DUPLICATE: 100,
    MOVE: 6000,
    LINK_STUDY_ENTITY: 15000,
    CHANGE_HAS_PII: 5000,
    GLOBAL_VIEW_TREE_DOCUMENTS: 45000,
    PERMISSION_OVERVIEW_BINDER_DOCUMENTS: 45000
};
