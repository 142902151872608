import { Transition, HookResult, StateService } from '@uirouter/angularjs';
import { take } from 'rxjs/operators';
import { Auth0Service } from '@app/shared/sessions/auth0.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';

const friendlyForwardConfig = (
    $transitions: Transition,
    $state: StateService,
    auth0: Auth0Service,
    Notifications: NotificationsService,
    $location: ng.ILocationService
): void => {
    $transitions.onStart({}, (transiton: Transition): HookResult => {
        return auth0.isAuthenticated$.pipe(take(1)).toPromise().then((isAuthenticated) => {
            const toState = transiton.to();
            const toParams = transiton.params('to');

            if (toState.data && toState.data.requiresAuth && !isAuthenticated) {
                const returnTo = $state.href(toState.name, toParams, { absolute: true });
                return $state.target('sign-in', { returnTo });
            }
        });
    });


    $transitions.onSuccess({}, () => {
        const propertyToCatch = 'sessionResumed';
        const locationSearch = $location.search();

        // eslint-disable-next-line no-prototype-builtins
        if (locationSearch.hasOwnProperty(propertyToCatch)) {
            Notifications.success('Navigation to last page viewed before automatic sign-out');
            $location.search(propertyToCatch, null);
        }
    });
};

friendlyForwardConfig.$inject = [
    '$transitions',
    '$state',
    'Auth0Service',
    'Notifications',
    '$location'
];

export default friendlyForwardConfig;
