import * as _ from 'lodash';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    Injectable
} from '@angular/core';

import {
    ApiError,
    Binder,
    Document,
    Entity,
    Folder,
    Project,
    Team,
    User
} from '@app/shared/models';
import { BindersService } from '@app/shared/binders/binders.service';
import { ProjectsService } from '@app/shared/projects/projects.service';
import { TeamService } from '@app/shared/teams/team.service';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';

@Injectable()
export class EntityPathService {
    constructor(
        private Teams: TeamService,
        private Binders: BindersService,
        private Projects: ProjectsService,
        private ApiError: ApiErrorsService,
        private Notifications: NotificationsService
    ) { }

    getPathForEntity(entity: Exclude<Entity, User>): Observable<Entity[]> {
        const entity$ = of(entity);

        switch (entity.type) {
            case 'team':
                return forkJoin([entity$]);
            case 'binder':
                return forkJoin([
                    this.getTeam(entity),
                    entity$
                ]);
            case 'folder':
                return forkJoin([
                    this.getTeam(entity),
                    this.getBinder(entity),
                    ...this.getFolderPath(entity)
                ]).pipe(map<Entity[], Entity[]>((x) => x.filter(Boolean)));
            case 'document':
            case 'log-entry':
                return forkJoin([
                    this.getTeam(entity),
                    this.getBinder(entity),
                    ...this.getFolderPath(entity),
                    entity$
                ]).pipe(map<Entity[], Entity[]>((x) => x.filter(Boolean)));
            case 'project':
                return forkJoin([
                    this.getTeam(entity),
                    entity$
                ]);
            case 'timeline':
                return forkJoin([
                    this.getTeam(entity),
                    this.getProject(entity) as unknown as Project,
                    entity$
                ]);
            default: {
                return forkJoin([
                    this.getTeam(entity),
                    entity$
                ]);
            }
        }
    }

    private getTeam(ent: Exclude<Entity, User>): Observable<Team> {
        const teamId = ent.type === 'team' ? ent.id : ent.teamId;
        return this.Teams.loadById(teamId);
    }

    private getBinder(ent: Folder | Document): ng.IPromise<Binder | void | Promise<void | Binder>> {
        if (!ent.binderId) {
            return Promise.resolve();
        }
        return this.Binders
            .getBinder(ent.teamId, ent.binderId)
            .toPromise()
            .catch(this.ApiError.handleError);
    }

    private getFolderPath(ent: Folder | Document): Observable<Entity>[] {
        const path = [] as Entity[];
        if (_.isArray(ent.path)) {
            // Make sure each element in the path has a type of folder
            path.push(...ent.path.map((e) => _.merge({}, e, { type: 'folder' })));
            const lastFolder = _.last(path);
            if (lastFolder && ent.type === 'folder' && (lastFolder as Folder).id !== ent.id) {
                // FIXME: fixMeId:shittyPathPop If we are on folders show the folder itself has been popped
                // from its own path. So we should add it back. See corresponding FIXME
                path.push({ name: ent.name, type: ent.type } as Entity);
            }
        }
        else if (_.isString(ent.path)) {
            path.push(...ent.path.split('/').map((pathElement, index) => ({
                // if there is no binderId, first item in path is binder
                type: (index === 0 && !ent.binderId) ? 'binder' : 'folder',
                name: pathElement.trim()
            }) as Entity));
        }
        return path.map((item) => of(item));
    }

    private getProject(ent): ng.IPromise<Project | void> {
        const projectId = ent.type === 'project' ? ent.id : ent.projectId;
        return this.Projects.getProject(ent.teamId, projectId)
            .toPromise()
            .then(
                (project) => project,
                ({ error }: ApiError) => {
                    this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                }
            );
    }
}
