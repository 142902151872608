
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import {
    MonitorReview, Document, BrowseNode, BrowseTree,
    Team,
    Folder
} from '@app/shared/models';
import { sortBrowseTreeLexicographically } from '@app/widgets/sort/sort-browse-tree-lexicographically.util';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { TagsAssignComponent } from '@app/widgets/tags-assign/tags-assign.component';
import { forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TimelineAssignComponent } from '@app/widgets/timeline-assign/timeline-assign.component';
import { mapTimelinesToDocumentProjects } from '@app/shared/documents/map-timelines-to-document-projects.util';
import { MoveComponent } from '@app/components/folders/components/move/move.component';
import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { TeamService } from '@app/shared/teams/team.service';
import { BindersService } from '@app/shared/binders/binders.service';
import { StateService } from '@uirouter/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import { DocumentService } from '@app/shared/documents/document.service';
import { DuplicateComponent } from '@app/components/duplicate/containers/duplicate/duplicate.component';
import { ImportViaEmailComponent } from '@app/widgets/import-via-email/import-via-email.component';
import { BrowseParams } from '@app/shared/teams/teams.service.types';
import { DocumentPlaceholderFillModes } from '@app/shared/documents/documents.service.types';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import { GetTimelinesResponseItem } from '@app/shared/projects/projects.service.types';
import { MoveFolderParams } from '@app/components/folders/components/move/move.component.types';
import styles from './document-placeholder-details.component.scss';
import template from './document-placeholder-details.component.html';
import {
    DocumentPlaceholderFillComponent
} from '../document-placeholder-fill/document-placeholder-fill.component';
import { DestroyDocumentComponent } from '../destroy-document/destroy-document.component';
import { DocumentDestroyEvent } from '../destroy-document/destroy-document.component.types';
import { LogCreateEvent } from '../log-create/log-create.component.types';
import { PlaceholderFillModes, ReplacePlaceholderOnSaveData } from '../document-placeholder-fill/document-placeholder-fill.component.types';
import { LogCreateComponent } from '../log-create/log-create.component';

@Component({
    selector: 'document-placeholder-details',
    template,
    styles: [String(styles)]
})
export class DocumentPlaceholderDetailsComponent implements OnInit {
    @Input() doc: Document;
    @Input() monitorReviewsEnabled: boolean;
    @Input() monitorReviews: MonitorReview[];
    @Output() onMonitorReviewsUpdate = new EventEmitter<void>();
    @Output() openTask = new EventEmitter<void>();

    isShortcut: boolean;
    currentTeam: Team;

    constructor(
        private Teams: TeamService,
        private Binders: BindersService,
        private $state: StateService,
        private Modals: ModalsService,
        private CurrentSession: CurrentSessionService,
        private ApiError: ApiErrorsService,
        private Notifications: NotificationsService,
        private Documents: DocumentService
    ) {}

    ngOnInit(): void {
        this.isShortcut = this.doc.subType === 'shortcut';
        this.currentTeam = this.CurrentSession.getCurrentTeam();
    }

    assignTags(): void {
        const entity = this.doc;

        const modalRef = this.Modals.show(TagsAssignComponent, {
            initialState: {
                currentTags: entity.tags,
                entity
            }
        });

        modalRef.content.save.subscribe(({ tagsToAssign, onSuccess, onError }) => {
            const tagIds = tagsToAssign.map((tag) => tag.id);
            modalRef.content.isProcessing = true;
            this.Teams.assignTags(entity.id.toString(), entity.type, tagIds)
                .subscribe(() => {
                    onSuccess();
                    this.doc.tags = tagsToAssign;
                }, onError);
        });
    }

    canReplaceDocWithNewLog(): boolean {
        return this.doc.permissions.replaceDocument && this.doc.subType !== 'shortcut' && this.currentTeam.permissions.createLog && this.currentTeam.settings.features.logTemplates;
    }

    filesAdded($files: File[]): void {
        if ($files && $files[0]) {
            this.Documents.replace(this.doc, $files[0]).subscribe();
        }
    }

    canReplaceDoc(): boolean {
        return this.doc.permissions.replaceDocument && this.doc.subType !== 'shortcut';
    }

    move(): void {

        const modalRef = this.Modals.show(MoveComponent, {
            class: 'modal-lg',
            initialState: {
                parentEntity: { binderName: this.doc.binderName } as BrowseTree,
                items: [this.doc] as BrowseNode[],
                loadRoot: () => this.Binders
                    .getBinders(this.currentTeam.id, { includeArchived: false })
                    .toPromise()
                    .then((binders) => sortByLexicographically(binders, 'name'))
                    .catch(this.ApiError.handleError),
                loadItem: (params: BrowseParams = {}) => this.Teams.browse(
                    this.currentTeam.id,
                    { ...params, includeArchived: false }
                )
                    .toPromise().then((data) => sortBrowseTreeLexicographically(data, 'name'))
            }
        });

        modalRef.content.move.subscribe((params: MoveFolderParams) => {
            this.Documents.moveTo(params.selectedFolder, [this.doc])
                .subscribe(() => {
                    modalRef.hide();
                    // in case the current view needs to update with newly moved documents
                    this.$state.go(this.$state.current, {}, { reload: true });
                }, () => {
                    modalRef.hide();
                });
        });
    }


    openCreateLogAndFillPlaceholderModal(): void {

        const logCreateModal = this.Modals.show(LogCreateComponent, {
            class: 'modal-md',
            initialState: {
                team: this.currentTeam,
                placeholderToFill: this.doc
            }
        });

        logCreateModal.content.dismiss.subscribe(() => {
            logCreateModal.hide();
        });

        logCreateModal.content.save.subscribe((event: LogCreateEvent) => {
            const logData = {
                id: this.doc.id,
                filename: event.data.name,
                logTemplateId: event.data.templateId,
                detailSelections: event.data.detailSelections,
                columnSelections: event.data.columnSelections
            };
            event.onSuccess();
            this.Documents.fillPlaceholderWithNewLog(logData).subscribe();
            logCreateModal.hide();
        });
    }


    duplicate(): void {
        const duplicateModal = this.Modals.show(DuplicateComponent, {
            class: 'modal-lg',
            initialState: {
                items: [this.doc]
            }
        });

        duplicateModal.content.dismiss.subscribe(() => {
            duplicateModal.hide();
        });
    }

    async deleteDocument() {
        this.Teams.hasOriginalDocuments(this.currentTeam.id, {
            objectType: 'documents',
            objectIds: [this.doc.id.toString()]
        }).subscribe((hasOriginalDocuments) => {
            const modal = this.Modals.show(DestroyDocumentComponent, {
                initialState: {
                    documents: [this.doc],
                    hasOriginalDocuments
                }
            });

            modal.content.destroy.pipe(
                switchMap(({ data: { documents, reason } }: DocumentDestroyEvent) => {
                    return forkJoin({
                        documentsDeleted: this.Documents.destroy(documents, reason),
                        removedDocuments: of(documents)
                    });
                })
            ).subscribe(({
                removedDocuments
            }) => {
                modal.hide();
                this.$state.go('app.team.folder', { teamId: removedDocuments[0].teamId, binderId: removedDocuments[0].binderId, folderId: removedDocuments[0].folderId });
            },
            ({ error }) => {
                const message = error.message || 'There was an unexpected error. Please contact your administrator.';
                this.Notifications.error(message);
            });
        });

    }

    openEmailModal(): void {
        const emailModal = this.Modals.show(ImportViaEmailComponent, {
            class: 'modal-md',
            initialState: {
                resolve: {
                    teamId: this.currentTeam.id,
                    binderId: this.doc.binderId,
                    folderId: this.doc.folderId,
                    docId: this.doc.id.toString(),
                    crumbsEntity: this.doc as unknown as Folder
                }
            }
        });

        emailModal.content.dismiss.subscribe(() => {
            emailModal.hide();
        });
    }


    handleOpenTask() {
        this.openTask.emit();
    }

    openFillPlaceholderModal(): void {
        const modalRef = this.Modals.show(DocumentPlaceholderFillComponent, {
            class: 'modal-md',
            initialState: {
                document: this.doc,
                loadRoot: () => this.Binders
                    .getBinders(this.currentTeam.id, { includeArchived: false })
                    .toPromise()
                    .then((binders) => sortByLexicographically(binders, 'name'))
                    .catch(this.ApiError.handleError),
                loadItem: async (params: BrowseParams = {}) => {
                    params.includeDocs = true;
                    params.includeArchived = false;
                    const data = await this.Teams.browse(this.currentTeam.id, params)
                        .toPromise();
                    return sortBrowseTreeLexicographically(data, 'name');
                }
            }
        });

        modalRef.content.onSubmit.subscribe((event: ReplacePlaceholderOnSaveData) => {
            const {
                id,
                originalDocumentId,
                fillMode,
                name
            } = event;

            const params = {
                id: id.toString(),
                originalDocumentId,
                fillMode: fillMode as DocumentPlaceholderFillModes,
                name
            };

            this.Documents.fillPlaceholder(params).subscribe(
                () => {
                    event.onSuccess();
                    if (event.fillMode === PlaceholderFillModes.CLONE) {
                        this.$state.go(this.$state.current, {}, { reload: true });
                    }
                },
                () => event.onError()
            );
        });
    }

    openAssignToTimelinesModal(document: Document): void {
        if (this.currentTeam.permissions.assignToTimelines) {
            const modalRef = this.Modals.show(TimelineAssignComponent, {
                initialState: { document }
            });

            modalRef.content.documentTimelinesUpdated
                .subscribe((timelines: GetTimelinesResponseItem[]) => {
                    this.doc.timelinesCount = timelines.length;
                    this.doc.projects = mapTimelinesToDocumentProjects(timelines);
                });
        }
    }

    goToVersionOfThisDoc(version: number) {
        return this.Documents.goToDocument({
            doc: this.doc,
            version
        });
    }
}
