import {
    Component, Input, OnInit
} from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Team } from '@app/shared/models';
import { StateService } from '@uirouter/core';
import { InboxDocument, Pagination } from '../../team-inbox.types';
import styles from './documents-list.component.scss';
import template from './documents-list.component.html';
import { TeamInboxStateService } from '../../team-inbox.state.service';
import { AcknowledgeTypes } from '../../team-inbox.constants';

@Component({
    selector: 'documents-list',
    template,
    styles: [String(styles)]
})
export class DocumentsListComponent implements OnInit {
    @Input() documents: InboxDocument[] = [];
    @Input() noResultsMessage: string[];
    @Input() isLoading = true;
    @Input() pagination: Pagination;

    loadingSizes = ['40%', '45%', '30%'];
    currentTeam: Team;
    pageNumber = 1;
    canOpenDocument: boolean;

    constructor(
        private $state: StateService,
        private CurrentSession: CurrentSessionService,
        private TeamInboxState: TeamInboxStateService
    ) { }

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.canOpenDocument = this.currentTeam.permissions.manageTeamInbox
            || this.currentTeam.permissions.performActionInTeamInbox;
    }

    openDocument(document: InboxDocument): void {
        this.TeamInboxState.setInboxDocument(document);
        this.$state.go('app.team.inbox-document-preview', {
            documentId: document.id.documentId,
            version: document.id.version
        });
    }

    getButtonTextByRequiredAction(requiredAction: string): string {
        switch (requiredAction) {
            case AcknowledgeTypes.ACCEPT_AND_MOVE:
            case AcknowledgeTypes.MOVE_AND_REQUEST_SIGNATURE:
            case AcknowledgeTypes.ACCEPT_AND_SIGN:
                return AcknowledgeTypes.ACCEPT_AND_MOVE;
            case AcknowledgeTypes.MOVE:
                return AcknowledgeTypes.MOVE;
            default:
                return AcknowledgeTypes.MOVE;
        }
    }
}
