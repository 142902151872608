import {
    Component,
    OnInit
} from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import {
    Digest, Team, TeamPermissions, User
} from '@app/shared/models';
import { StateService } from '@uirouter/core';
import * as _ from 'lodash';
import { TeamService } from '@app/shared/teams/team.service';
import {
    Days, DaysRanges, DigestNames,
    Frequency, Periods, Support, ZendeskLinks
} from '../../notifications.types';
import template from './manage-notifications.component.html';
import styles from './manage-notifications.component.scss';

@Component({
    selector: 'manage-notifications',
    template,
    styles: [String(styles)]
})
export class ManageNotificationsComponent implements OnInit {
    isLoading = true;
    shouldRenderDigests = false;
    currentTeam: Team;
    currentUser: User;
    digests: Digest[];
    showSettings = {};
    isTeamNotifications: boolean;
    digestsEnabled: boolean;
    userId: string;
    Support = Support;
    digestOrder = [
        DigestNames.signatureQueue,
        DigestNames.taskQueue,
        DigestNames.due2Days,
        DigestNames.due14Days,
        DigestNames.due30Days,
        DigestNames.due60Days,
        DigestNames.expiring2Days,
        DigestNames.expiring14Days,
        DigestNames.expiring30Days,
        DigestNames.expiring60Days
    ]

    defaultDigestSettings = {
        days: DaysRanges.everyDay,
        hour: 8
    }

    constructor(
        private Team: TeamService,
        private CurrentSession: CurrentSessionService,
        private $state: StateService
    ) {
        this.searchUsers = this.searchUsers.bind(this);
        this.getUserDigests = this.getUserDigests.bind(this);
    }

    ngOnInit(): void {

        const { isTeamNotifications } = this.$state.params;

        this.isTeamNotifications = isTeamNotifications;
        this.currentTeam = this.CurrentSession.getCurrentTeam();

        if (this.isTeamNotifications
            && (!this.currentTeam || !this._canManageTeamNotifications(this.currentTeam?.permissions))) {
            this.$state.go('app.select-team');
        }

        this.userId = this.CurrentSession.getCurrentUser().id;
        this.digestsEnabled = !this.currentTeam.settings.reports.disableDigests;

        if (!this.isTeamNotifications && this.digestsEnabled) {
            this.getDigests();
        }
    }

    private _canManageTeamNotifications(perm: TeamPermissions): boolean {
        return perm.viewTeamUsersRolesPermissions && perm.teamManageTeamNotifications;
    }

    getDigests(): void {
        this.shouldRenderDigests = true;
        this.Team.getDigests({ teamId: this.currentTeam.id, teamMemberId: this.userId }).subscribe((digests) => {
            this.digests = _.cloneDeep(digests);
            this.orderDigests();
            this.isLoading = false;
        });
    }

    orderDigests(): void {
        this.digests = this.digests.sort((d1, d2) => this.digestOrder.indexOf(d1.name) - this.digestOrder.indexOf(d2.name));
    }

    getDigestHours(digest: Digest): string {
        const { frequencySettings: { hour }, timezone } = digest;
        const twelveClockHour = (hour % 12) || 12;
        const hours = `${twelveClockHour}:00`;
        const period = hour < 12 ? Periods.am : Periods.pm;

        return `${hours} ${period} ${timezone}`;
    }

    getDayName(day: number): string {
        switch (day) {
            case 1:
                return Days.monday;
            case 2:
                return Days.tuesday;
            case 3:
                return Days.wednesday;
            case 4:
                return Days.thursday;
            case 5:
                return Days.friday;
            case 6:
                return Days.saturday;
            case 7:
                return Days.sunday;
            default:
                return Days.monday;
        }
    }

    getDigestFrequencyType(digest: Digest): string {
        switch (digest.notificationFrequency) {
            case Frequency.daily:
                return Frequency.daily;
            case Frequency.weekly:
                return `${this.getDayName(digest.frequencySettings.days[0])}s`;
            case Frequency.monthly:
                return Frequency.monthly;
            default:
                return '';
        }
    }

    getDigestSchedule(digest: Digest): string {
        switch (digest.notificationFrequency) {
            case Frequency.daily:
            case Frequency.weekly:
                return `${this.getDigestFrequencyType(digest)} at ${this.getDigestHours(digest)}`;
            case Frequency.monthly:
                return `${this.getDigestFrequencyType(digest)} on the First day of the month at ${this.getDigestHours(digest)}`;
            default:
                return '';
        }
    }

    isDigestActive(digest: Digest): boolean {
        const { notificationFrequency } = digest;
        return notificationFrequency && notificationFrequency !== Frequency.never;
    }

    toggleEmailDigest(digest: Digest): void {
        const { notificationFrequency } = digest;

        const { frequency, frequencySettings } = !notificationFrequency || notificationFrequency === Frequency.never
            ? { frequency: Frequency.daily, frequencySettings: this.defaultDigestSettings }
            : { frequency: Frequency.never, frequencySettings: digest.frequencySettings };

        const upsertParams = {
            teamId: this.currentTeam.id,
            digestToUpdate: { ...digest },
            frequency,
            frequencySettings,
            sendIfZero: false,
            teamMemberId: this.userId
        };

        this.Team.upsertDigestSubscription(upsertParams).subscribe((updatedDigest) => {
            _.assign(digest, updatedDigest);
        });
    }

    toggleSettings(digestId: string): void {
        this.showSettings[digestId] = !this.showSettings[digestId];
    }

    toggleSettingsOff(digestId: string): void {
        this.showSettings[digestId] = false;
    }

    updateDigestSettings(digest: Digest): void {
        const upsertParams = {
            teamId: this.currentTeam.id,
            digestToUpdate: { ...digest },
            frequency: digest.notificationFrequency,
            frequencySettings: digest.frequencySettings,
            sendIfZero: false,
            teamMemberId: this.userId
        };
        this.Team.upsertDigestSubscription(upsertParams).subscribe((updatedDigest) => {
            _.assign(digest, updatedDigest);
        });
    }


    searchUsers(filter: string): Promise<User[]> {
        return this.Team.searchRolesAndUsers(`user ${filter}`).toPromise() as Promise<User[]>;
    }

    getUserDigests(user: User): void {
        this.userId = user.id;
        this.getDigests();
    }

    displayUserNameAndEmail(user: User): string {
        return `${user.fullName} (${user.email})`;
    }

    getSupportEmail(): string {
        return this.currentTeam.settings.support.email || Support.EMAIL;
    }

    getSupportPhone(): string {
        return this.currentTeam.settings.support.phone || Support.PHONE;
    }

    getSupportPhoneUnformatted(): string {
        return this.currentTeam.settings.support.phoneUnformatted || Support.PHONE_UNFORMATTED;
    }

    getCrumbs(): {name: string}[] {
        return this.isTeamNotifications ? [{ name: 'Team Email Notifications' }] : [{ name: 'My Email Notifications' }];
    }

    getZendeskUrl(): string {
        return this.isTeamNotifications ? ZendeskLinks.teamNotifications : ZendeskLinks.myNotifications;
    }
}
