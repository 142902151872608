import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
    BatchResponseItem, LogEntry, SignatureRequest, User
} from '@app/shared/models';
import { NotificationsService } from '@app/core/notifications/notifications.service';

import {
    GetEntriesResponse,
    CreateUpdateLogEntryBody,
    UpdateLogEntrySignatureRequestsPayload,
    UpdateLogEntrySignatureRequestsResponse,
    BulkSignOrDeclineBody,
    GetEntriesPayload,
    GetPotentialSignersParams,
    GetLogImportRowsPreviewResponse,
    CreateLogEntriesParams,
    LogEntryStrikeTroughParams,
    LogEntryStrikeTroughResponse,
    SignLogEntriesResponse
} from './document-log-entries.service.types';

@Injectable({
    providedIn: 'root'
})
export class DocumentLogEntriesService {
    readonly url = {
        logEntryBase: (documentId: string): string => `/api/documents/logs/${documentId}/entry`,
        logEntriesBase: (documentId: string): string => `/api/documents/logs/${documentId}/entries`,
        logEntriesSign: (documentId: string): string => `api/documents/logs/${documentId}/entries/signatures`,
        logEntriesForVersion: (documentId: string, version: number): string => `/api/documents/logs/${documentId}/versions/${version}/entries`,
        logEntryVersionHistory: (documentId: string, logEntryId: string): string => `/api/documents/logs/${documentId}/entries/${logEntryId}/versions`,
        logEntrySingle: (documentId: string, logEntryId: string): string => `/api/documents/logs/${documentId}/entry/${logEntryId}`,
        logEntrySignatures: (documentId: string, logEntryId: string): string => `/api/documents/logs/${documentId}/entry/${logEntryId}/signatures`,
        potentialSigners: (documentId: string): string => `/api/documents/logs/${documentId}/potential-signers`,
        potentialTeamMembers: (documentId: string): string => `/api/documents/logs/${documentId}/potential-team-members`,
        pendingSignatureRequests: (documentId: string, logEntryId: string): string => `/api/documents/logs/${documentId}/entry/${logEntryId}/signature-requests`,
        updateSignatureRequests: '/api/documents/signature-requests',
        importRowsPreview: (teamId: string, documentId: string): string => `/api/teams/${teamId}/documents/logs/${documentId}/import-rows-preview`,
        createLogEntries: (teamId: string, documentId: string): string => `/api/teams/${teamId}/documents/logs/${documentId}/entries`,
        strikeThroughLogEntries: (documentId: string): string => `/api/documents/logs/${documentId}/strikethrough-log-entries`
    }

    constructor(
        private http: HttpClient,
        private Notifications: NotificationsService
    ) { }

    getLogEntries(documentId: string, teamId: string, payload: GetEntriesPayload,
        sortBy: string, sortDirection: string): Observable<GetEntriesResponse> {
        const url = payload.version
            ? this.url.logEntriesForVersion(documentId, payload.version)
            : this.url.logEntriesBase(documentId);

        let params = new HttpParams().set('teamId', teamId);

        if (payload.myPendingSignatures) {
            params = params.set('myPendingSignatures', payload.myPendingSignatures.toString());
        }

        if (payload.next) {
            params = params.set('next', payload.next);
        }

        if (sortBy) {
            params = params.set('sortBy', sortBy);
        }

        if (sortDirection) {
            params = params.set('sortDirection', sortDirection);
        }

        return this.http.get<GetEntriesResponse>(url, { params });
    }

    getLogEntryVersionHistory(
        documentId: string, teamId: string, { logEntryId }: { logEntryId: string; version: number }, documentVersion?
    ): Observable<LogEntry[]> {
        let params = new HttpParams().set('teamId', teamId);
        if (documentVersion) {
            params = params.set('maxVersion', documentVersion);
        }
        return this.http.get<LogEntry[]>(this.url.logEntryVersionHistory(documentId, logEntryId), { params });
    }

    createLogEntry(documentId: string, logEntry: CreateUpdateLogEntryBody): Observable<LogEntry> {
        return this.http.post<LogEntry>(this.url.logEntryBase(documentId), logEntry);
    }

    updateLogEntry(documentId: string, logEntryId: string, logEntry: CreateUpdateLogEntryBody): Observable<LogEntry> {
        return this.http.patch<LogEntry>(this.url.logEntrySingle(documentId, logEntryId), logEntry);
    }

    signLogEntries(documentId: string, signatures: BulkSignOrDeclineBody): Observable<SignLogEntriesResponse> {
        return this.http.post<BatchResponseItem<LogEntry>[]>(this.url.logEntriesSign(documentId), signatures)
            .pipe(
                tap({
                    error: ({ error }) => {
                        error && error.message
                            ? this.Notifications.error(error.message)
                            : this.Notifications.unexpectedError();
                    }
                }),
                map((responseItems) => {
                    let successes = 0;
                    let failures = 0;
                    const updatedEntries = responseItems.filter((item) => {
                        if (item.statusCode === 200) {
                            successes += 1;
                            return true;
                        }

                        failures += 1;
                        return false;
                    });

                    const status = signatures.status.toLowerCase();
                    const logEntries = updatedEntries.map(({ payload }) => payload as LogEntry);

                    return {
                        logEntries,
                        successes,
                        failures,
                        status
                    };
                })
            );
    }

    getEntrySignatures(documentId: string, logEntryId: string): ng.IPromise<object> {
        return this.http.get(this.url.logEntrySignatures(documentId, logEntryId)).toPromise();
    }

    getPotentialSigners(params: GetPotentialSignersParams): Observable<User[]> {

        let httpParams = new HttpParams();
        if (params.logEntryId) {
            httpParams = httpParams.append('logEntryId', params.logEntryId);
        }
        if (params.filter) {
            httpParams = httpParams.append('filter', params.filter);
        }

        return this.http.get<User[]>(this.url.potentialSigners(params.documentId), { params: httpParams })
            .pipe(tap(() => {
                return undefined;
            }, () => this.Notifications.unexpectedError()));
    }

    getPotentialTeamMembers(documentId: string, isCreate: boolean, filter?: string): Observable<User[]> {
        let params = new HttpParams().set('isCreate', `${isCreate}`);
        if (filter) {
            params = params.set('filter', filter);
        }
        return this.http.get<User[]>(
            this.url.potentialTeamMembers(documentId), { params }
        );
    }

    getPendingSignatureRequests(documentId: string, logEntryId: string): Observable<SignatureRequest[]> {
        return this.http.get<{ signatureRequests: SignatureRequest[] }>(this.url.pendingSignatureRequests(documentId, logEntryId))
            .pipe(
                tap({
                    error: ({ error }) => {
                        error && error.message
                            ? this.Notifications.error(error.message)
                            : this.Notifications.unexpectedError();
                    }
                }),
                map((data) => {
                    return data.signatureRequests;
                })
            );
    }

    updateSignatureRequests(
        payload: UpdateLogEntrySignatureRequestsPayload
    ): Observable<UpdateLogEntrySignatureRequestsResponse> {
        return this.http.patch<UpdateLogEntrySignatureRequestsResponse>(
            this.url.updateSignatureRequests, payload
        ).pipe(tap(([response]) => {
            if (response.statusCode === 200) {
                const {
                    createdRequests,
                    updatedRequests,
                    cancelledRequestCount
                } = response.payload;
                const segments = [
                    createdRequests.length && `${createdRequests.length} requested`,
                    updatedRequests.length && `${updatedRequests.length} reminded`,
                    cancelledRequestCount && `${cancelledRequestCount} canceled`
                ].filter(Boolean).join(', ');
                if (createdRequests.length || updatedRequests.length || cancelledRequestCount) {
                    this.Notifications.success(`Log row signatures: ${segments}`);
                }
            }
            else {
                this.Notifications.error('Log Row signatures: request failed.');
            }
        }, () => this.Notifications.unexpectedError()));
    }

    getLogImportRowsPreview(teamId: string, documentId: string, file: File): Observable<GetLogImportRowsPreviewResponse> {
        const url = this.url.importRowsPreview(teamId, documentId);

        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('filename', file.name);
        formData.append('size', `${file.size}`);

        const headers = new HttpHeaders();
        headers.set('Content-Type', undefined);

        return this.http.post<GetLogImportRowsPreviewResponse>(url, formData, { headers });
    }

    createLogEntries(params: CreateLogEntriesParams): Observable<LogEntry[]> {
        const url = this.url.createLogEntries(params.teamId, params.documentId);
        return this.http.post<LogEntry[]>(url, params.logEntries);
    }

    strikeThroughLogEntries(params: LogEntryStrikeTroughParams): Observable<LogEntryStrikeTroughResponse[]> {

        const url = this.url.strikeThroughLogEntries(params.documentId.toString());
        return this.http.post<LogEntryStrikeTroughResponse[]>(url, params);
    }
}
