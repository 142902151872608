import { Component } from '@angular/core';

import { DocumentSharingView } from '../../services/document-sharing.types';
import { TabsDetails } from './document-sharing-tabs-container.types';

import { ExchangeEventsStateService } from '../../services/exchange-events/exchange-events-state.service';
import template from './document-sharing-tabs-container.component.html';
import styles from './document-sharing-tabs-container.component.scss';

@Component({
    selector: 'document-sharing-tabs-container',
    template,
    styles: [String(styles)]
})
export class DocumentSharingTabsContainer {
    tabDetailsArray: TabsDetails[] = [
        {
            heading: 'Documents Received',
            view: 'documentsReceived',
            icon: ['fa', 'fa-inbox-in']
        },
        {
            heading: 'Documents Sent',
            view: 'documentsSent',
            icon: ['fa', 'fa-inbox-out']
        }
    ];

    constructor(
        private ExchangeEventsStateManagement: ExchangeEventsStateService
    ) {}

    handleSelectView(newView: DocumentSharingView): void {
        this.ExchangeEventsStateManagement.setDocumentSharingView(newView);
    }
}
