import { BsModalRef } from 'ngx-bootstrap/modal';

import {
    Component, Input, Output, EventEmitter
} from '@angular/core';

import template from './document-locking-modal.component.html';
import styles from './document-locking-modal.component.scss';

@Component({
    selector: 'document-locking-modal',
    template,
    styles: [String(styles)]
})
export class DocumentLockingModalComponent {
    @Input() isLocking: boolean;
    @Input() documentCount: number;
    @Input() isShortcutSelected: boolean;
    @Output() confirm = new EventEmitter<void>();;

    modalHeader: string;
    modalContent: string;
    isProcessing = false;
    readonly helpArticleUrl = 'https://florencehealthcare.zendesk.com/hc/en-us/articles/5354398509459';

    constructor(private modal: BsModalRef) { }

    onCancel(): void {
        this.modal.hide();
    }

    onConfirm(): void {
        this.isProcessing = true;
        this.confirm.emit();
    }
}
