import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { EntityLinkComponent } from './entity-link/entity-link.component';
import { EntityTypeIconComponent } from './entity-type-icon/entity-type-icon.component';
import { EntityPathComponent } from './entity-path/entity-path.component';
import { EntityPathService } from './entity-path.service';
import { EntityTypeService } from './entity-type.service';
import { EntityNameComponent } from './entity-name/entity-name.component';
import { HumanizedEntityListComponent } from './humanized-entity-list/humanized-entity-list.component';

@NgModule({
    imports: [
        TooltipModule.forRoot(),
        CommonModule,
        UIRouterModule
    ],
    declarations: [
        EntityLinkComponent,
        EntityTypeIconComponent,
        EntityPathComponent,
        EntityNameComponent,
        HumanizedEntityListComponent
    ],
    exports: [
        EntityPathComponent,
        EntityLinkComponent,
        EntityTypeIconComponent,
        HumanizedEntityListComponent,
        EntityNameComponent
    ],
    entryComponents: [
        EntityLinkComponent,
        EntityTypeIconComponent,
        EntityPathComponent,
        EntityNameComponent,
        HumanizedEntityListComponent
    ],
    providers: [
        EntityPathService,
        EntityTypeService
    ]
})
export class EntityModule { }
