import './search-dropdown.scss';
import {
    Component, Input, OnInit
} from '@angular/core';
import {
    Observable, of, Observer, from
} from 'rxjs';
import * as _ from 'lodash';
import { map, switchMap } from 'rxjs/operators';
import template from './search-dropdown.component.html';
import { QueryResult, QueryResultWithDisplayName } from './search-dropdown.coponent.types';
@Component({
    selector: 'search-dropdown',
    template
})

export class SearchDropdownComponent implements OnInit {
    @Input() onSelect: (subject: QueryResult) => void;
    @Input() query: (filter: string) => Promise<QueryResult[]> | Observable<QueryResult[]>;
    @Input() calculateDisplayName: (template: QueryResult) => string;
    @Input() placeholder = 'Search...'
    @Input() label: string;
    @Input() disableSelect: boolean;
    @Input() populateInputOnSelect: boolean;
    @Input() isRequired: boolean;

    public suggestions$: Observable<QueryResultWithDisplayName[]>;
    public selected: string;
    public isLoading: boolean;
    public readonly noResultsMessage = 'No results found! Try searching for something else.';

    ngOnInit(): void {
        this.suggestions$ = new Observable((observer: Observer<string>) => {
            observer.next(this.selected);
        }).pipe(
            switchMap((query) => {
                return query ? from(this.query(query)) : of([]);
            }),
            map((data: QueryResult[]) => {
                this.isLoading = false;
                if (!data.length) {
                    return [{ displayName: this.noResultsMessage }] as QueryResultWithDisplayName[];
                }
                return this.transformResult(data);
            })
        );
    }

    handleSelect({ item }: { item: QueryResultWithDisplayName }): void {

        if (item.displayName !== this.noResultsMessage) {
            this.selected = this.populateInputOnSelect ? item.displayName : null;
            this.onSelect(item);
            return;
        }

        this.selected = null;
    }

    transformResult(data: QueryResult[]): QueryResultWithDisplayName[] {
        return data.map((obj) => {
            const clone = _.cloneDeep(obj) as QueryResultWithDisplayName;

            clone.displayName = this.calculateDisplayName ? this.calculateDisplayName(clone)
                : `${clone.name} (${clone.type.toUpperCase()})`;
            return clone;
        });
    }

    typeaheadIsLoading(e: boolean): void {
        this.isLoading = e;
    }

}
