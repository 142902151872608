import { User } from '@app/shared/models/user.model';
import { BrowseTree } from '@app/shared/models/browse-tree.model';

export const ArchiveStatus = {
    scheduled: 'SCHEDULED',
    inProgress: 'IN_PROGRESS',
    complete: 'COMPLETE',
    failed: 'FAILED'
} as const;

export type ArchiveStatuses = typeof ArchiveStatus[keyof typeof ArchiveStatus];

export interface Archive {
    // id of the archived object
    id: string;
    // id of the archive in the database
    archiveId: string;
    name: string;
    type: 'binder' | 'folder' | 'document';
    status: ArchiveStatuses;
    scheduledFor: string;
    retentionPeriod: number;
    closeoutDate: string;
    startedAt: string;
    completedAt: string;
    user: User;
    binderId?: string;
    hasDownloadPermission?: boolean;
    hasCancelPermission?: boolean;
    deletedOrigin: boolean;
}
