const monitorReviewActivities = {
    activities: [
        'Approved',
        'Reviewed',
        'Open Query',
        'Closed Query',
        'No MRM activity'
    ].sort()
};

export default monitorReviewActivities;
