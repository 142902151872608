'use strict';

const orkesStatuses = {
    COMPLETED: 'COMPLETED',
    RUNNING: 'RUNNING',
    FAILED: 'FAILED'
};

module.exports = {
    orkesStatuses
};
