'use strict';

const BASE_FIELDS = ['id', 'nickname'];
const DB_AVAILABLE_FIELDS = ['sponsor', 'cro', 'uniqueProtocolId', 'device', 'condition'];
const GENERATED_FIELDS = ['timestamps'];
const ALLOWED_FIELDS = [...DB_AVAILABLE_FIELDS, ...GENERATED_FIELDS];
const ALL_FIELDS = [...BASE_FIELDS, ...ALLOWED_FIELDS];

module.exports = {
    BASE_FIELDS,
    ALLOWED_FIELDS,
    ALL_FIELDS,
    DB_AVAILABLE_FIELDS,
    GENERATED_FIELDS
};
