import {
    Component,
    EventEmitter,
    Output
} from '@angular/core';

import styles from './no-signing-permissions-warning.component.scss';

@Component({
    selector: 'no-signing-permissions-warning',
    template: `
        <div class="no-signing-permissions-warning">
            <i class="fas fa-lock no-signing-permissions-warning__icon"></i>
            <span class="no-signing-permissions-warning__message">
                Some signers lack signing permission for some documents.
                <a class="no-signing-permissions-warning__message-link"
                   (click)="viewDetailsClick.emit()">
                   View details
                </a>
            </span>
        </div>
    `,
    styles: [String(styles)]
})
export class NoSigningPermissionsWarningComponent {
    @Output() viewDetailsClick = new EventEmitter<void>();
}
