import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { AdapterService } from '@app/shared/adapter/adapter.service';
import { Document } from '@app/shared/models';
import * as _ from 'lodash';
import template from './document.viewer.unsupported.component.html';
import styles from './document.viewer.unsupported.component.scss';
@Component({
    selector: 'document-viewer-unsupported',
    template,
    styles: [String(styles)]
})
export class DocumentViewerUnsupportedComponent {

    @Input() doc: Document;
    @Output() onRequestDownload = new EventEmitter<void>();

    constructor(
        private Adapter: AdapterService
    ) {}

    isNaN(value: number): boolean {
        return _.isNaN(value);
    }

    showPendingIcon() {

        return this.Adapter.getIsPendingConversionState(this.doc);
    }

    handleRequestDownload() {
        this.onRequestDownload.emit();
    }
}
