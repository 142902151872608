import * as _ from 'lodash';
import { Document, User } from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';
import { PasswordOrPinExpiryService } from '@app/shared/password-or-pin-expiry/password-or-pin-expiry.service';
import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import { DefaultSigningReasonService } from '../../document-content-editor/services/default-signing-reason.service';

import template from './ndv-form-save.component.html';
import styles from './ndv-form-save.component.scss';
import { SaveFormModalEvent } from './ndv-form-save.component.types';

// this component is soley used for new doc viewer when saving the form
// document-form-save component will be deleted along with the old doc viewer
@Component({
    selector: 'ndv-form-save',
    template,
    styles: [String(styles)]
})
export class NdvFormSaveComponent implements OnInit {

    @Input() jobTitle = '';
    @Input() entity: Document;
    @Input() canFinalizeForm: boolean;
    @Input() mustFinalize: boolean;
    @Input() signatureCount: number;

    @Output() onSubmit = new EventEmitter<SaveFormModalEvent>();

    documentFormSaveModal: FormGroup;

    showPasswordTooltip = false;
    currentUser: User;
    isSigningPasscodeExpired = false;
    isProcessing = false;

    email: string;
    defaultSigningReason: string;
    requiredTooltipText: string;
    validTooltipText: string;

    constructor(
        private CurrentSession: CurrentSessionService,
        private DefaultSigningReason: DefaultSigningReasonService,
        private PasswordOrPinExpiry: PasswordOrPinExpiryService,
        private modalRef: BsModalRef,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.initForm();

        this.currentUser = this.CurrentSession.getCurrentUser();
        this.email = _.get(this.currentUser, 'email', null);

        this.isSigningPasscodeExpired = this.currentUser.isRemote && this.PasswordOrPinExpiry.isExpired(
            this.CurrentSession.getCurrentTeam().settings.signingPINPolicy,
            this.currentUser.lastSigningPasscodeReset
        );

        this.setPassphraseDetails();

        this.defaultSigningReason = this.DefaultSigningReason.getDefaultSigningReasonFromPending({
            doc: this.entity,
            userId: this.currentUser.id
        });

        this.isDefaultReasonSet();
    }

    initForm() {
        this.documentFormSaveModal = this.fb.group({
            reason: ['', [Validators.required, notBlank]],
            password: ['', [Validators.required, notBlank]]
        });
    }

    isDefaultReasonSet() {

        if (this.defaultSigningReason !== null) {
            this.onReasonChanged(this.defaultSigningReason);
        }
    }

    setPassphraseDetails() {

        if (this.currentUser.isRemote) {
            this.requiredTooltipText = 'Signing PIN required.';
            this.validTooltipText = 'Please enter a valid Signing PIN.';
        }
        else {
            this.requiredTooltipText = 'Password required.';
            this.validTooltipText = 'Please enter a valid password.';
        }
    }

    cancel(): void {
        this.modalRef.hide();
    }

    getModalData() {
        const saveFormData: SaveFormModalEvent = {
            email: this.email,
            shouldFinalize: false,
            jobTitle: this.jobTitle,
            onSuccess: () => {
                this.modalRef.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        };

        if (this.defaultSigningReason !== '') {
            saveFormData.reason = this.defaultSigningReason;
        }

        const password = this.documentFormSaveModal.controls.password.value;
        if (password !== '') {
            if (this.currentUser.isRemote) {
                saveFormData.signingPasscode = password;
            }
            else {
                saveFormData.password = password;
            }

        }

        return saveFormData;
    }

    canSaveDraft() {
        return true;
    }

    handleSaveDraft() {

        const data = this.getModalData();
        data.shouldFinalize = false;
        this.handleSave(data, true);
    }

    handleFinalize() {

        const data = this.getModalData();
        data.shouldFinalize = true;
        this.handleSave(data, false);
    }

    isValidForm() {
        return this.documentFormSaveModal?.valid;
    }


    handleSave(data: SaveFormModalEvent, saveAsDraft: boolean) {
        if (!saveAsDraft && this.signatureCount && !this.isValidForm()) {
            return;
        }
        if (this.documentFormSaveModal.controls.reason.value !== '') {
            data.reason = this.documentFormSaveModal.controls.reason.value;
        }

        this.isProcessing = true;
        this.onSubmit.emit(data);
    }

    onReasonChanged(reason: string) {
        this.documentFormSaveModal.controls.reason.setValue(reason);
    }

    shouldShowCertifiedCopyInfo(): boolean {
        return this.documentFormSaveModal.controls.reason.value === 'Certified Copy';
    }
}
