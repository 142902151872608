import { Component } from '@angular/core';

import { ArchivesService } from '@app/components/archive/archives.service';
import { TabsDetails } from './archive-tabs.types';

import template from './archive-tabs.component.html';
import styles from './archive-tabs.component.scss';

@Component({
    selector: 'archive-tabs',
    template,
    styles: [String(styles)]
})
export class ArchiveTabsContainer {
    tabDetailsArray: TabsDetails[] = [
        {
            heading: 'Archived',
            view: 'completedArchives'
        },
        {
            heading: 'Scheduled',
            view: 'scheduledArchives'
        }
    ];

    constructor(private ArchiveService: ArchivesService) {}

    handleSelectView(newView: string): void {
        const selectedTab = this.tabDetailsArray.find((tab) => tab.view === newView);
        if (selectedTab) {
            this.ArchiveService.selectedTab$.next(selectedTab.heading);
        }
    }
}
