import { APP_INITIALIZER, NgModule } from '@angular/core';
import { Location, CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { UIRouterModule } from '@uirouter/angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgIdleModule } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MatSnackBarModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { DocumentsModule } from './components/documents/documents.module';
import { DuplicateModule } from './components/duplicate/duplicate.module';
import { LogTemplatesModule } from './components/log-templates/log-templates.module';
import { NeedHelpModule } from './components/need-help/need-help.module';
import { ProjectsModule } from './components/projects/projects.module';
import { SigningPinPolicyModule } from './components/signing-pin-policy/signing-pin-policy.module';
import { SignatureRequestsModule } from './components/signature-requests/signature-requests.module';
import { StudiesModule } from './components/studies/studies.module';
import { SystemNotificationsModule } from './components/system-notifications/system-notifications.module';
import { TeamsModule } from './components/teams/teams.module';
import { UsersModule } from './components/users/users.module';
import { GlobalViewModule } from './components/global-view/global-view.module';
import { NotificationsModule } from './components/notifications/notifications.module';
import { PermissionsModule } from './components/permissions/permissions.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { WidgetsModule } from './widgets/widgets.module';
import { ApiRequestInterceptor } from './core/api-request.interceptor';
import { BindersModule } from './components/binders/binders.module';
import { FoldersModule } from './components/folders/folders.module';
import { ArchiveModule } from './components/archive/archive.module';
import { AnnouncementsModule } from './components/annoucements/announcements.module';
import { DocumentSharingModule } from './components/document-sharing/document-sharing.module';
import { TeamInboxModule } from './components/team-inbox/team-inbox.module';
import { SessionActivityShowModule } from './components/sessions/sessions.module';
import { RolesModule } from './components/roles/roles.module';
import { PasswordModule } from './components/password-policy/password.module';
import { StudyStartupModule } from './components/study-startup/study-startup.module';
import { StudyManagerModule } from './components/study-manager/study-manager.module';
import { ConnectedTeamsModule } from './components/connected-teams/connected-teams.module';
import { AppInitializationService } from './shared/app-initialization/app-initialization.service';
import { ReportsModule } from './components/reports/reports.module';
import { TagsModule } from './components/tags/tags.module';
import { FolderizerModule } from './components/folderizer/folderize.module';
import { AuditTrailModule } from './components/audit-trail/audit-trail.module';
import { DownloadsModule } from './components/downloads/downloads.module';
import { HelpDeskModule } from './components/help-desk/help-desk.module';
import { LayoutModule } from './layout/layout.module';
import { uiRouterConfigFn } from './routeConfig';
import { AppComponent } from './app.component';


@NgModule({
    declarations: [AppComponent],
    entryComponents: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        LoadingBarHttpClientModule,
        LoadingBarModule,
        MatSnackBarModule,
        NgIdleModule.forRoot(),
        UIRouterModule.forRoot({
            states: [],
            useHash: true,
            config: uiRouterConfigFn,
            deferIntercept: true
        }),
        SharedModule.forRoot(),
        CoreModule,
        WidgetsModule,
        BindersModule,
        FoldersModule,
        DocumentsModule,
        LogTemplatesModule,
        ProjectsModule,
        SigningPinPolicyModule,
        SignatureRequestsModule,
        StudiesModule,
        SystemNotificationsModule,
        DuplicateModule,
        GlobalViewModule,
        PermissionsModule,
        TeamsModule,
        NeedHelpModule,
        UsersModule,
        NotificationsModule,
        ArchiveModule,
        ModalModule.forRoot(),
        AnnouncementsModule,
        DocumentSharingModule,
        TeamInboxModule,
        StudyStartupModule,
        StudyManagerModule,
        SessionActivityShowModule,
        RolesModule,
        PasswordModule,
        ReportsModule,
        ConnectedTeamsModule,
        TagsModule,
        FolderizerModule,
        AuditTrailModule,
        BsDropdownModule.forRoot(),
        DownloadsModule,
        HelpDeskModule,
        NgIdleKeepaliveModule.forRoot(),
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            onActivateTick: true,
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        LayoutModule
    ],
    bootstrap: [AppComponent],
    providers: [
        Location,
        {
            provide: APP_INITIALIZER,
            useFactory: (service: AppInitializationService) => (): Promise<void> => service.init().toPromise(),
            deps: [AppInitializationService],
            multi: true
        },
        BsModalService,
        { provide: HTTP_INTERCEPTORS, useClass: ApiRequestInterceptor, multi: true }
    ]
})
export class AppModule {}
