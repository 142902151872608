import {
    Component,
    OnChanges,
    OnDestroy,
    OnInit
} from '@angular/core';

import { AdapterService } from '@app/shared/adapter/adapter.service';
import { VirtualTreeComponent } from '@app/widgets/virtual-tree/virtual-tree.component';

import { MatTreeFlatDataSource } from '@angular/material/tree';
import {
    DocumentShareVersionOptions, DocumentShareSelectedVersionOption, VersionedVirtualTree, documentShareVersionLimit
} from '@app/components/document-sharing/components/share-documents-virtual-tree/share-documents-virtual-tree.component.types';
import { VirtualTreeFlatNode, VirtualTreeNode } from '@app/widgets/virtual-tree/virtual-tree.component.types';


import { CurrentSessionService } from '@app/core/current-session.service';
import { Team } from '@app/shared/models';
import template from './share-documents-virtual-tree.component.html';
import styles from './share-documents-virtual-tree.component.scss';

@Component({
    selector: 'share-documents-virtual-tree',
    template,
    styles: [String(styles)]
})
export class ShareDocumentsVirtualTreeComponent extends VirtualTreeComponent implements OnInit, OnChanges, OnDestroy {

    hoveredNode: VirtualTreeNode;

    versionSelection = DocumentShareVersionOptions;
    versionLimit = documentShareVersionLimit;

    dataSource: MatTreeFlatDataSource<VirtualTreeNode, VersionedVirtualTree>;
    expandedVersionNodeIds: Set<VirtualTreeNode['id']> = new Set();

    currentTeam: Team;

    constructor(
        protected Adapter: AdapterService,
        protected CurrentSession: CurrentSessionService
    ) {
        super(Adapter, CurrentSession);
        this.currentTeam = this.CurrentSession.getCurrentTeam();
    }

    selectedDocumentVersionChanged(selectedVersion: DocumentShareSelectedVersionOption, node: VirtualTreeNode) {
        if (node) {
            this.updateNodeVersionSelection(node, selectedVersion);

            if (!this.selectedNodes.includes(node)) {
                this.toggleNodeSelection(node);
            }
        }
    }

    // finds the node that needs to be updated in the dataSource object and updates its version option selection
    private updateNodeVersionSelection(node: VirtualTreeNode, newVersion: DocumentShareSelectedVersionOption) {
        const parent = this.getParentNode(node);
        if (parent) {
            const originalNode = parent.items?.find((elem) => elem.id === node.id) as VersionedVirtualTree;
            originalNode.documentShareVersion = newVersion;
        }
    }

    isAllVersionsEnabled(): boolean {
        return this.currentTeam.settings.features.sharingAllVersions;
    }

    isVersionExpanded(node: VirtualTreeNode): boolean {
        return this.expandedVersionNodeIds.has(node.id);
    }

    toggleVersionSelection(node) {
        if (!this.isVersionExpanded(node)) {
            this.expandedVersionNodeIds.add(node.id);
        }
        else {
            this.expandedVersionNodeIds.delete(node.id);
        }
    }

    isSelectableDocument(node: VirtualTreeNode): boolean {
        return this.isNodeSelectable(node) && node.type === 'document';
    }

    isVersionSelectionHidden(node: VirtualTreeNode): boolean {
        return (node.type !== 'document' || (node !== this.hoveredNode && !this.nodeSelection.isSelected(node)) || !this.isAllVersionsEnabled());
    }

    isSelectedDocumentShared(node: VirtualTreeFlatNode): boolean {
        return this.selectedNodes?.some((doc) => doc.exchangeEventId && doc.id === node.id);
    }
}
