
import {
    Component, Input, Output, EventEmitter, OnInit
} from '@angular/core';
import {
    FormBuilder, Validators, FormControl, FormArray, ValidatorFn
} from '@angular/forms';

import template from './single-select-column-form.component.html';
import styles from './single-select-column-form.component.scss';

@Component({
    selector: 'single-select-column-form',
    template,
    styles: [String(styles)]
})
export class SingleSelectColumnFormComponent implements OnInit {
    @Input() singleSelectColumnOptions: string[] = [];
    @Input() optionValidators: ValidatorFn[] = [];
    @Output() singleSelectColumnOptionsFormUpdated = new EventEmitter<string[]>();

    readonly maxAllowedOptions = 40;
    readonly defaulSingleSelectNumberOfOptions = 1;
    readonly errorMessages = {
        length: 'Maximum of 100 characters exceeded.',
        required: 'Value cannot be empty'
    }

    optionsCount = this.singleSelectColumnOptions.length || 1;
    singleSelectColumnOptionsForm = this.fb.group({
        options: this.fb.array([], [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(40)
        ])
    });

    addNewOptionKey: number;

    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {
        this.singleSelectColumnOptionsForm.valueChanges.subscribe((value) => {
            this.onFormUpdated(value.options);
        });
        if (this.singleSelectColumnOptions.length) {
            this.optionsCtrl.clear();
            this.singleSelectColumnOptions.forEach((option) => {
                this.pushOptionControl(option);
            });
        }
        else {
            for (let i = 0; i < this.defaulSingleSelectNumberOfOptions; i += 1) {
                this.pushOptionControl();
            }
        }
    }

    trimValue(control: FormControl, onBlur: boolean) {
        const value = control.value.trim();

        if (value.length === 0 && !onBlur) {
            control.setErrors({ requiredErorr: this.errorMessages.required });
        }
        else if (onBlur) {
            control.setValue(value);
        }
    }

    get optionsCtrl(): FormArray {
        return this.singleSelectColumnOptionsForm.controls.options as FormArray;
    }

    onFormUpdated(options: string[]): void {
        this.optionsCount = options.length;
        this.optionsCtrl.pristine || this.singleSelectColumnOptionsFormUpdated?.emit(this.optionsCtrl.value);
    }

    onAddOption() {
        if (this.optionsCount >= this.maxAllowedOptions) {
            return;
        }
        this.pushOptionControl();
    }

    pushOptionControl(option = '') {
        this.optionsCtrl.push(new FormControl(option, this.optionValidators));
    }

    removeOptionControl(index: number) {
        this.optionsCtrl.removeAt(index);
        this.addNewOptionKey = this.optionsCount + 1;
        this.singleSelectColumnOptionsFormUpdated.emit(this.optionsCtrl.value);
    }

}
