import { Report } from '@app/shared/models';
import { ReportGroupsNames, ReportNames } from '../../report-names';

export const ReportTabHeaders = {
    [ReportNames.pastDueDates]: 'Past Due',
    [ReportNames.dueDates]: 'All',
    [ReportNames.pastExpirationDates]: 'Expired',
    [ReportNames.expirationDates]: 'Expiring',
    [ReportNames.signaturesCompleted]: 'Completed',
    [ReportNames.signaturesMyQueue]: 'Signatures',
    [ReportNames.signaturesPending]: 'Pending',
    [ReportNames.signaturesDeclined]: 'Declined',
    [ReportNames.signaturesSignByDate]: 'Expiring',
    [ReportNames.tasksClosed]: 'Closed',
    [ReportNames.tasksMyQueue]: 'Tasks',
    [ReportNames.tasksPending]: 'Pending',
    [ReportNames.approvalsApproved]: 'Approved',
    [ReportNames.approvalsRejected]: 'Rejected',
    [ReportNames.approvalsCancelled]: 'Cancelled',
    [ReportNames.approvalsPendingSignatures]: 'Pending Signatures',
    [ReportNames.approvalsReadyForApproval]: 'Ready For Approval',
    [ReportNames.approvalsPendingForm]: 'Pending Form Completion',
    [ReportNames.labels]: 'All',
    [ReportNames.tags]: 'All',
    [ReportNames.studies]: 'Study Profile',
    [ReportNames.monitorReviewsOpen]: 'Queried',
    [ReportNames.monitorReviewsApproved]: 'Monitored',
    [ReportNames.sipDocumentStatuses]: 'Document Statuses',
    [ReportNames.documentsRecentlyUploaded]: 'Recent',
    [ReportNames.documentsOverview]: 'Overview - All',
    [ReportNames.sipDocumentsSent]: 'Documents Sent',
    [ReportNames.qcReport]: 'QC Review',
    [ReportNames.qcReviewMyQueue]: 'QC Reviews'
};

export interface ReportGroup {
    name: ReportGroupsNames;
    isVisible: boolean;
    activeTabIndex: number;
    reports: {
        index: number;
        isVisible: boolean;
        header: typeof ReportTabHeaders[keyof typeof ReportTabHeaders];
        reportName: ReportNames;
    }[]
}

export interface ReportsIndexReport extends Report {
    index?: number;
    reportName: string;
}

export const ReportGroups = [
    {
        name: ReportGroupsNames.myQueue,
        isVisible: true,
        activeTabIndex: 1,
        reports: [
            {
                index: 1,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.signaturesMyQueue],
                reportName: ReportNames.signaturesMyQueue
            },
            {
                index: 2,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.tasksMyQueue],
                reportName: ReportNames.tasksMyQueue
            },
            {
                index: 3,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.qcReviewMyQueue],
                reportName: ReportNames.qcReviewMyQueue
            }
        ]
    },
    {
        name: ReportGroupsNames.documents,
        isVisible: true,
        activeTabIndex: 1,
        reports: [
            {
                index: 1,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.expirationDates],
                reportName: ReportNames.expirationDates
            },
            {
                index: 2,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.documentsRecentlyUploaded],
                reportName: ReportNames.documentsRecentlyUploaded
            },
            {
                index: 3,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.documentsOverview],
                reportName: ReportNames.documentsOverview,
                minWidth: '1750px'
            }
        ]
    },
    {
        name: ReportGroupsNames.labels,
        isVisible: true,
        activeTabIndex: 1,
        reports: [
            {
                index: 1,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.labels],
                reportName: ReportNames.labels
            }
        ]
    },
    {
        name: ReportGroupsNames.placeholders,
        isVisible: true,
        activeTabIndex: 1,
        reports: [
            {
                index: 1,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.dueDates],
                reportName: ReportNames.dueDates
            }
        ]
    },
    {
        name: ReportGroupsNames.reviews,
        isVisible: true,
        activeTabIndex: 1,
        reports: [
            {
                index: 1,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.monitorReviewsApproved],
                reportName: ReportNames.monitorReviewsApproved
            },
            {
                index: 2,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.monitorReviewsOpen],
                reportName: ReportNames.monitorReviewsOpen
            }
        ]
    },
    {
        name: ReportGroupsNames.signatures,
        isVisible: true,
        activeTabIndex: 1,
        reports: [
            {
                index: 1,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.signaturesCompleted],
                reportName: ReportNames.signaturesCompleted
            },
            {
                index: 2,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.signaturesDeclined],
                reportName: ReportNames.signaturesDeclined
            },
            {
                index: 3,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.signaturesSignByDate],
                reportName: ReportNames.signaturesSignByDate
            },
            {
                index: 4,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.signaturesPending],
                reportName: ReportNames.signaturesPending
            }
        ]
    },
    {
        name: ReportGroupsNames.studyProfiles,
        isVisible: true,
        activeTabIndex: 1,
        reports: [
            {
                index: 1,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.studies],
                reportName: ReportNames.studies
            }
        ]
    },
    {
        name: ReportGroupsNames.tags,
        isVisible: true,
        activeTabIndex: 1,
        reports: [
            {
                index: 1,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.tags],
                reportName: ReportNames.tags
            }
        ]
    },
    {
        name: ReportGroupsNames.tasks,
        isVisible: true,
        activeTabIndex: 1,
        reports: [
            {
                index: 1,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.tasksClosed],
                reportName: ReportNames.tasksClosed
            },
            {
                index: 2,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.tasksPending],
                reportName: ReportNames.tasksPending
            }
        ]
    },
    {
        name: ReportGroupsNames.qc,
        isVisible: true,
        activeTabIndex: 1,
        reports: [
            {
                index: 1,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.qcReport],
                reportName: ReportNames.qcReport
            }
        ]
    },
    {
        name: ReportGroupsNames.approvals,
        isVisible: true,
        activeTabIndex: 1,
        reports: [
            {
                index: 1,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.approvalsApproved],
                reportName: ReportNames.approvalsApproved
            },
            {
                index: 2,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.approvalsCancelled],
                reportName: ReportNames.approvalsCancelled
            },
            {
                index: 3,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.approvalsPendingForm],
                reportName: ReportNames.approvalsPendingForm
            },
            {
                index: 4,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.approvalsPendingSignatures],
                reportName: ReportNames.approvalsPendingSignatures
            },
            {
                index: 5,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.approvalsReadyForApproval],
                reportName: ReportNames.approvalsReadyForApproval
            },
            {
                index: 6,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.approvalsRejected],
                reportName: ReportNames.approvalsRejected
            }
        ]
    },
    {
        name: ReportGroupsNames.sip,
        isVisible: true,
        activeTabIndex: 1,
        reports: [
            {
                index: 1,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.sipDocumentStatuses],
                reportName: ReportNames.sipDocumentStatuses
            },
            {
                index: 2,
                isVisible: true,
                header: ReportTabHeaders[ReportNames.sipDocumentsSent],
                reportName: ReportNames.sipDocumentsSent
            }
        ]
    }
];
