'use strict';

exports.approvalsStatuses = {
    APPROVED: 'approved',
    REJECTED: 'rejected',
    PENDING: 'pending',
    CANCELLED: 'cancelled'
};

exports.UPDATABLE_APPROVALS_STATUSES = [
    'approved',
    'rejected',
    'cancelled'
];
