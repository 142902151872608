import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './destroy-project-or-timeline.component.html';
import { DestroyProjectOrTimelineType, ProjectOrTimelineDeleteEvent } from './destroy-project-or-timeline.component.types';

@Component({
    selector: 'destroy-project-or-timeline',
    template
})
export class DestroyProjectOrTimelineComponent implements OnInit {
    @Input() name: string;
    @Input() type: DestroyProjectOrTimelineType;

    @Output() onDestroy = new EventEmitter<ProjectOrTimelineDeleteEvent>();

    isProcessing: boolean;

    constructor(
        private modalInstance: BsModalRef
    ) {}

    ngOnInit(): void {
        this.isProcessing = false;
    }

    cancel(): void {
        this.modalInstance.hide();
    }

    destroy() {
        this.isProcessing = true;

        const projectOrTimelineDeleteEvent = {
            onSuccess: () => {
                this.modalInstance.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        };

        this.onDestroy.emit(projectOrTimelineDeleteEvent);
    }
}
