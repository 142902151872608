import {
    Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren
} from '@angular/core';
import {
    FormArray, FormBuilder, FormControl, FormGroup, Validators
} from '@angular/forms';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Team } from '@app/shared/models';
import { StateService } from '@uirouter/core';
import { REGEX, ROUTES } from '@app/core/constants';
import { StudyRolesService } from '@app/shared/study-roles/study-roles.service';
import {
    BehaviorSubject, forkJoin, Observable, of, throwError
} from 'rxjs';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import {
    catchError, debounceTime, filter, switchMap, tap
} from 'rxjs/operators';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { TeamStudyRole } from './manage-study-roles.types';

import template from './manage-study-roles.component.html';
import styles from './manage-study-roles.component.scss';
import {
    BulkImportStudyRolesComponent
} from '../bulk-import-study-roles/bulk-import-study-roles.component';

@Component({
    selector: 'manage-study-roles',
    template,
    styles: [String(styles)]
})
export class ManageStudyRolesComponent implements OnInit {
    constructor(
        private CurrentSession: CurrentSessionService,
        private studyRolesService: StudyRolesService,
        private FeatureFlags: FeatureFlagService,
        private $state: StateService,
        private Notifications: NotificationsService,
        private formBuilder: FormBuilder,
        private Modals: ModalsService
    ) {}

    @ViewChild('container', { static: false }) container: ElementRef;
    @ViewChildren('roleInput') roleInputs: QueryList<ElementRef>;

    nameValidationErrMessage = 'Length must be between 1 and 50 characters and may consist of any characters except the following ^ : < > “ | ? *';
    nameTakenErrMessage = 'Role name already exists.';
    allStudyRoleNames: string[];
    teamStudyRolesForm: FormGroup;
    teamStudyRoles: TeamStudyRole[] = [];
    currentTeam: Team;
    isFetchingTeamStudyRoles = true;
    isProcessing = false;
    availableRoles$ = new BehaviorSubject<string[]>([]);
    isEditMode = false;
    usedNameValues: string[];
    originalTeamStudyRoles: TeamStudyRole[];
    canBulkUploadRoles = false;

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.redirectIfFeatureFlagDisabled();
        this.FeatureFlags.getFlag(FEATURE_FLAGS.EBINDERS_BULKUPLOAD_STUDYROLES, false).pipe(
            filter((flag) => flag !== undefined)
        ).subscribe((value) => {
            this.canBulkUploadRoles = value;
        });

        if (!this.currentTeam.permissions.manageStudyRoles) {
            this.$state.go(ROUTES.binders);
            return;
        }
        this.studyRolesService.setPredefinedStudyRolesList$.subscribe();
        this.studyRolesService.predefinedStudyRolesList$.subscribe((studyRoles) => {
            this.allStudyRoleNames = studyRoles
                .filter((role) => role.teamId === '*')
                .map((role) => role.name);
        });
        this.resolveTeamStudyRoles().subscribe();
    }

    private redirectIfFeatureFlagDisabled(): void {
        this.FeatureFlags.getFlag(FEATURE_FLAGS.STUDY_ROLES, true).pipe(
            filter((flag) => flag !== undefined),
            debounceTime(300),
            tap((isFlagEnabled) => {
                if (!isFlagEnabled) {
                    this.$state.go(ROUTES.binders);
                }
            })
        ).subscribe();
    }

    private resolveTeamStudyRoles() {
        return this.studyRolesService.getTeamStudyRoles(this.currentTeam.id)
            .pipe(
                tap((studyRoles) => {

                    this.teamStudyRoles = studyRoles;
                    if (!this.teamStudyRoles.length) {
                        this.isEditMode = true;
                    }

                    this.originalTeamStudyRoles = [...studyRoles];

                    this.availableRoles$.next(this.allStudyRoleNames);

                    this.initFormControls();
                    this.isFetchingTeamStudyRoles = false;
                })
            );
    }

    initFormControls(): void {
        const initialTeamStudyRolesFormArrayValue = this.getInitialTeamStudyRolesFormArrayValue();

        this.teamStudyRolesForm = this.formBuilder.group({
            teamStudyRoles: this.formBuilder.array(
                initialTeamStudyRolesFormArrayValue,
                this.studyRolesArrayValidator.bind(this)
            )
        });

        this.teamStudyRolesArray?.controls.forEach((ctrl) => {
            ctrl.get('name').valueChanges.subscribe(() => {
                this.teamStudyRolesArray.updateValueAndValidity();
            });
        });
    }


    private getInitialTeamStudyRolesFormArrayValue(): FormGroup[] {
        return this.teamStudyRoles.map(
            (role) => this.formBuilder.group({
                name: this.createNameCtrl(role.name),
                id: this.createTeamStudyRoleIdCtrl(role.id),
                teamId: this.createTeamIdCtrl(role.teamId),
                roleEditDisabled: this.createRoleEditDisabledCtrl(role.roleInUse)
            })
        );
    }

    isNameCtrlEditable(ctrl: FormControl): boolean {
        if (!this.isEditMode) {
            return false;
        }
        if (this.allStudyRoleNames.includes(ctrl.value.name)) {
            return false;
        }
        return !ctrl.value.roleEditDisabled;
    }

    isNameCtrlDeletable(ctrl: FormControl): boolean {
        if (!this.teamStudyRoles.length) {
            return true;
        }
        if (!this.isEditMode) {
            return false;
        }
        return !ctrl.value.roleEditDisabled;
    }

    private createNameCtrl(name: string): FormControl {
        return this.formBuilder.control(
            name,
            [
                Validators.required,
                Validators.pattern(REGEX.studyRoleName),
                Validators.minLength(1),
                Validators.maxLength(50)
            ]
        );
    }

    private createTeamStudyRoleIdCtrl(id: string): FormControl {
        return this.formBuilder.control(id);
    }

    private createTeamIdCtrl(teamId: string): FormControl {
        return this.formBuilder.control(teamId);
    }

    private createRoleEditDisabledCtrl(editDisabled: boolean): FormControl {
        return this.formBuilder.control(editDisabled);
    }

    private studyRolesArrayValidator(): { [key: string]: boolean } | null {
        const isDuplicateValuesMap = new Map();
        const values = this.teamStudyRolesArray?.controls.map((ctrl) => {
            const name = ctrl.value.name.trim();
            if (!isDuplicateValuesMap.get(name)) {
                isDuplicateValuesMap.set(name, 1);
            }
            else {
                isDuplicateValuesMap.set(name, isDuplicateValuesMap.get(name) + 1);
            }
            return name;
        }) || [];

        this.usedNameValues = Array.from(isDuplicateValuesMap)
            .filter(([, value]) => value > 1)
            .map(([key]) => key);


        const uniqueValues = new Set(values);
        if (uniqueValues.size !== values?.length) {
            return { duplicate: true };
        }

        if (values?.length < 1 || values?.length > 100) {
            return { lengthOutOfRange: true };
        }

        return null;
    }

    get teamStudyRolesArray(): FormArray {
        return this.teamStudyRolesForm?.get('teamStudyRoles') as FormArray;
    }

    enterEditMode(): void {
        this.isEditMode = true;
    }

    onRemoveRole(index: number, disabled: boolean): void {
        if (disabled) {
            return;
        }
        this.teamStudyRolesArray.removeAt(index);
        this.teamStudyRolesArray.markAsDirty();
    }

    resetForm(shouldExistEnterMode: boolean): void {
        this.teamStudyRolesArray.clear();
        if (shouldExistEnterMode) {
            this.isEditMode = false;
        }
        this.initFormControls();
    }

    addRole(name: string): void {
        const disabled = !this.isEditMode;

        const newRole = this.formBuilder.group({
            name: this.createNameCtrl(name),
            id: this.createTeamStudyRoleIdCtrl(null),
            teamId: this.createTeamIdCtrl(this.currentTeam.id),
            roleEditDisabled: this.createRoleEditDisabledCtrl(disabled)
        });
        newRole.markAsDirty();
        this.teamStudyRolesArray.push(newRole);
        this.teamStudyRolesArray.updateValueAndValidity();

        setTimeout(() => {
            this.scrollToBottom();
            this.focusLastRoleInput();
        }, 0);
    }

    private scrollToBottom(): void {
        if (this.container) {
            this.container.nativeElement.scrollTop = this.container.nativeElement.scrollHeight;
        }
    }

    private focusLastRoleInput(): void {
        if (this.roleInputs && this.roleInputs.last) {
            this.roleInputs.last.nativeElement.focus();
        }
    }

    isSaveDisabled(): boolean {
        const originalTeamStudyRoles = this.teamStudyRoles.map((role) => role.name).sort();
        const finalTeamStudyRoles = this.teamStudyRolesArray.controls.map((ctrl) => ctrl.value?.name || null).sort();
        return JSON.stringify(originalTeamStudyRoles) === JSON.stringify(finalTeamStudyRoles) || this.teamStudyRolesArray.status === 'INVALID';
    }

    onSubmit(): void {
        this.isProcessing = true;

        const currentTeamStudyRoles = this.teamStudyRolesArray.controls.map((ctrl) => ({
            name: ctrl.value?.name || null,
            id: ctrl.value?.id || null
        })) as TeamStudyRole[];

        const finalRoles = currentTeamStudyRoles.map((finalRole) => {
            const sameOriginalRole = !finalRole.id && this.originalTeamStudyRoles.find(
                (originalRole) => originalRole.name === finalRole.name
            );
            if (sameOriginalRole) {
                return sameOriginalRole;
            }
            return finalRole;
        });
        const {
            createdRoles, deletedRoles, updatedRoles
        } = this.getRoleChanges(this.originalTeamStudyRoles, finalRoles);


        this.studyRolesService.getTeamStudyRoles(this.currentTeam.id).pipe(
            switchMap((latestStudyRoles) => {
                const lockedRoles = latestStudyRoles.filter(
                    (role) => {
                        const isRoleMarkedForDeletion = deletedRoles.some((delRole) => delRole.id === role.id);
                        const isRoleMarkedForUpdate = updatedRoles.some((updRole) => updRole.id === role.id);
                        return role.roleInUse && (isRoleMarkedForDeletion || isRoleMarkedForUpdate);
                    }
                );

                if (lockedRoles.length > 0) {
                    this.resetForm(true);
                    return throwError(null);
                }

                const createdCall$ = this.handleCreatedRoles(createdRoles);
                const deletedCall$ = this.handleDeletedRoles(deletedRoles);
                const updatedCall$ = this.handleUpdatedRoles(updatedRoles);

                return forkJoin([createdCall$, deletedCall$, updatedCall$]);
            }),
            switchMap(() => {
                return this.resolveTeamStudyRoles();
            }),
            tap(() => {
                this.Notifications.success('Study role(s) updated successfully.');
                this.isEditMode = false;
                this.isProcessing = false;
            }),
            catchError((error) => {
                this.Notifications.error('Study role(s) not updated successfully. Some updated roles are currently in use.');
                this.isProcessing = false;
                return of(error);
            })
        ).subscribe();
    }


    private handleCreatedRoles(createdRoles: TeamStudyRole[]): Observable<TeamStudyRole[]> {
        if (!createdRoles.length) {
            return of(null);
        }

        const roleNames = createdRoles.map((role) => role.name);
        return this.studyRolesService.createTeamStudyRoles(this.currentTeam.id, roleNames);
    }


    private handleDeletedRoles(deletedRoles: TeamStudyRole[]): Observable<{ acknowledged: boolean, deletedCount: number }[]> {
        if (!deletedRoles.length) {
            return of(null);
        }

        const deleteRequests$ = deletedRoles
            .map((role) => this.studyRolesService.deleteTeamStudyRole(this.currentTeam.id, role.id));

        return forkJoin(deleteRequests$);
    }


    private handleUpdatedRoles(updatedRoles: TeamStudyRole[]): Observable<TeamStudyRole[]> {
        if (!updatedRoles.length) {
            return of(null);
        }

        const updateRequests$ = updatedRoles.map(
            (role) => this.studyRolesService.updateTeamStudyRole(this.currentTeam.id, role.id, role.name)
        );

        return forkJoin(updateRequests$);
    }

    private getRoleChanges(originalRoles: TeamStudyRole[], finalRoles: TeamStudyRole[]) {
        const createdRoles = finalRoles.filter((finaRole) => finaRole.name && !finaRole.id);

        const deletedRoles = originalRoles
            .filter((originalRole) => !finalRoles.some((finaRole) => finaRole.id === originalRole.id));

        const updatedRoles = finalRoles.filter(
            (finalRole) => originalRoles.some(
                (origRole) => origRole.id === finalRole.id && origRole.name && finalRole.name && origRole.name !== finalRole.name
            )
        );

        return { createdRoles, deletedRoles, updatedRoles };
    }

    openBulkImportModal(): void {
        const modalRef = this.Modals.show(BulkImportStudyRolesComponent, {
            class: 'modal-lg',
            initialState: {
                numberOfExistingStudyRoles: this.teamStudyRoles.length
            }
        });

        modalRef.content.onFileImport.subscribe((importedData: any[]) => {
            this.enterEditMode();

            importedData.forEach((entry: any) => {
                this.addRole(entry.role);
            });

            this.Notifications.success(`${importedData.length} study roles successfully added`);
        });
    }
}
