import { Inject, Injectable } from '@angular/core';

import {
    Auth0Client,
    RedirectLoginOptions,
    LogoutOptions,
    GetTokenSilentlyOptions,
    RedirectLoginResult,
    GetTokenSilentlyVerboseResponse
} from '@auth0/auth0-spa-js';

import {
    of,
    from,
    Observable,
    iif,
    defer,
    throwError,
    BehaviorSubject,
    Subject
} from 'rxjs';

import {
    concatMap,
    tap,
    map,
    catchError,
    switchMap,
    filter,
    take
} from 'rxjs/operators';

import { AppConfigService } from '../app-config/app-config.service';

export type AppState = {
    target?: string;
    'ext-sessionExpired'?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class Auth0Service {
    private auth0Client: Auth0Client;

    private isLoadingSubject$ = new BehaviorSubject<boolean>(true);
    readonly isLoading$ = this.isLoadingSubject$.asObservable();

    private errorSubject$ = new Subject<Error>();
    readonly error$ = this.errorSubject$.asObservable();
    readonly isAuthenticated$ = this.isLoading$.pipe(
        filter((loading) => !loading),
        switchMap(() => from(this.auth0Client.isAuthenticated()))
    );

    constructor(@Inject('Window') private window: Window, private AppConfig: AppConfigService) { }

    _getAuth0ClientService(): Auth0Client {
        return new Auth0Client({
            domain: this.AppConfig.config.auth0Domain,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            client_id: this.AppConfig.config.auth0ClientId,
            audience: this.AppConfig.config.auth0Audience,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            redirect_uri: window.location.origin,
            useRefreshTokens: false,
            cookieDomain: this.AppConfig.config.florenceBaseDomain
        });
    }

    init(): Observable<void> {
        this.auth0Client = this._getAuth0ClientService();

        const checkSessionOrCallback$ = (isCallback: boolean) => iif(
            () => isCallback,
            this.handleRedirectCallback(),
            defer(() => this.auth0Client.checkSession())
        );

        return this.shouldHandleCallback()
            .pipe(
                switchMap((isCallback) => checkSessionOrCallback$(isCallback).pipe(
                    catchError((error) => {
                        this.errorSubject$.next(error);
                        this.isLoadingSubject$.next(false);
                        return of(undefined);
                    })
                )),
                tap(() => {
                    this.isLoadingSubject$.next(false);
                }),
                map(() => undefined),
                take(1)
            );
    }

    loginWithRedirect(
        options?: RedirectLoginOptions<AppState>
    ): Observable<void> {
        return from(this.auth0Client.loginWithRedirect(options));
    }

    logout(options?: LogoutOptions): void {
        this.isLoadingSubject$.next(true);

        const logout = this.auth0Client.logout(options) || of(null);
        from(logout).subscribe(() => {
            this.isLoadingSubject$.next(false);
        });
    }

    getAccessTokenSilently(
        options: GetTokenSilentlyOptions = {}
    ): Observable<string | GetTokenSilentlyVerboseResponse> {
        this.isLoadingSubject$.next(true);
        return of(this.auth0Client).pipe(
            concatMap((client) => client.getTokenSilently(options)),
            tap(() => {
                this.isLoadingSubject$.next(false);
            }),
            catchError((error) => {
                this.isLoadingSubject$.next(false);
                return throwError(error);
            })
        );
    }

    handleRedirectCallback(
        url?: string
    ): Observable<RedirectLoginResult<AppState>> {
        return defer(() => this.auth0Client.handleRedirectCallback(url))
            .pipe(
                tap((result) => {
                    const redirectTarget = result?.appState?.target || '/';
                    this.window.location.href = redirectTarget;
                })
            );
    }


    private shouldHandleCallback(): Observable<boolean> {
        return of(this.window.location.search).pipe(
            map((search) => {
                return (
                    (search.includes('code=') || search.includes('error='))
                    && search.includes('state=')
                );
            })
        );
    }
}
