export interface TeamSettingsTab {
    state: string;
    index: number;
    permCheck: string;
    onLoad?: string;
    href?: string;
}

export interface TeamSettingsTabs {
    [key: string]: TeamSettingsTab;
}

export enum TeamTimeoutPeriod {
    default = 15,
    extended = 30,
}
