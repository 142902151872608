import { Team } from '@app/shared/models';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CurrentSessionService } from '@app/core/current-session.service';
import template from './inactivity.warning.component.html';

@Component({
    template
})
export class InactivityWarningComponent implements OnInit {
  currentTeam: Team;
  inactivityLogOffTime: number;

  constructor(
    private CurrentSession: CurrentSessionService,
    private modal: BsModalRef
  ) { }

  ngOnInit(): void {
      this.currentTeam = this.CurrentSession.getCurrentTeam();
      if (this.currentTeam && this.currentTeam.settings.inactivityTimeout) {
          this.inactivityLogOffTime = this.currentTeam.settings.inactivityTimeout;
      }
      else {
          this.inactivityLogOffTime = 15;
      }
  }

  cancel(): void {
      this.modal.hide();
  }

}
