import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Document, Team, ApiError } from '@app/shared/models';
import { calculateEntityPath, EntityPathItem } from '@app/shared/documents/calculate-entity-path.util';
import { GetTimelinesResponseItem } from '@app/shared/projects/projects.service.types';
import { CurrentSessionService } from '@app/core/current-session.service';
import { ProjectsService } from '@app/shared/projects/projects.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { sortByLexicographically } from '../sort/sort-by-lexicographically.util';

import template from './timeline-assign.component.html';
import styles from './timeline-assign.component.scss';

@Component({
    selector: 'timeline-assign',
    template,
    styles: [String(styles)]
})
export class TimelineAssignComponent implements OnInit {
    @Input() document: Document;
    @Output() documentTimelinesUpdated = new EventEmitter<GetTimelinesResponseItem[]>();

    entityPath: EntityPathItem[];
    isProcessing = false;
    initialTimelinesLoaded = false;
    initialTimelines: GetTimelinesResponseItem[] = [];
    currentTimelines$ = new BehaviorSubject<GetTimelinesResponseItem[]>([]);
    otherTimelines$ = new BehaviorSubject<GetTimelinesResponseItem[]>([]);
    timelinesChanged$ = of(false);
    private currentTeam: Team;

    constructor(
        private modalRef: BsModalRef,
        private Projects: ProjectsService,
        private CurrentSession: CurrentSessionService,
        private Notifications: NotificationsService
    ) { }

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.entityPath = calculateEntityPath(this.document, this.currentTeam);
        const timelineObservables = [
            this.Projects.getTimelines(this.document.teamId, { prependProjectName: true }),
            this.Projects.getTimelines(this.document.teamId, { objectId: this.document.id as string, prependProjectName: true })
        ];
        forkJoin(timelineObservables)
            .pipe(take(1))
            .subscribe(([teamTimelines, docTimelines]) => {
                this.initialTimelines = docTimelines;
                this.currentTimelines$.next(sortByLexicographically([...docTimelines], 'name'));

                const unassignedTimelines = teamTimelines.filter((t) => !docTimelines.find((dt) => dt.id === t.id));
                this.otherTimelines$.next(sortByLexicographically([...unassignedTimelines], 'name'));
                this.initialTimelinesLoaded = true;
            },
            (error) => {
                if (error.error && error.error.message) {
                    this.Notifications.error(error.error.message);
                }
                else {
                    this.Notifications.unexpectedError();
                }
                this.cancel();
            });
        this.timelinesChanged$ = this.currentTimelines$.asObservable()
            .pipe(map(this.areTimelinesChanged));
    }

    cancel(): void {
        this.modalRef.hide();
    }

    onTimelineRemove(removedTimeline: GetTimelinesResponseItem): void {
        const newCurrentTimelines = this.currentTimelines$.getValue().filter((t) => t.id !== removedTimeline.id);
        const newOtherTimelines = sortByLexicographically([...this.otherTimelines$.getValue(), removedTimeline], 'name');
        this.currentTimelines$.next(newCurrentTimelines);
        this.otherTimelines$.next(newOtherTimelines);
    }

    onTimelineAssign(asignedTimeline: GetTimelinesResponseItem): void {
        const newCurrentTimelines = sortByLexicographically([...this.currentTimelines$.getValue(), asignedTimeline], 'name');
        const newOtherTimelines = this.otherTimelines$.getValue().filter((t) => t.id !== asignedTimeline.id);
        this.currentTimelines$.next(newCurrentTimelines);
        this.otherTimelines$.next(newOtherTimelines);
    }

    save(): void {
        const timelines = this.currentTimelines$.getValue();
        if (this.isProcessing) {
            return;
        }
        this.isProcessing = true;

        const timelineIds = timelines.map((t) => t.id);
        this.Projects.saveTimelineAssignments(this.currentTeam.id, this.document.id as string, timelineIds)
            .pipe(take(1))
            .subscribe(
                () => {
                    this.documentTimelinesUpdated.emit(timelines);
                    this.Notifications.success(`Assigned Timelines updated for '${this.document.title}'!`);
                    this.modalRef.hide();
                },
                ({ error }: ApiError) => {
                    this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                    this.modalRef.hide();
                }
            );
    }

    private areTimelinesChanged = (currentTimelines: GetTimelinesResponseItem[]): boolean => {
        const someTimelinesRemoved = this.initialTimelines.some((initial) => {
            return !currentTimelines.find((current) => current.id === initial.id);
        });
        const someTimelinesAdded = currentTimelines.length > this.initialTimelines.length;
        return someTimelinesRemoved || someTimelinesAdded;
    }
}
