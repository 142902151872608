import {
    Component, OnInit,
    Output,
    EventEmitter,
    Input
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MESSAGES, REGEX } from '@app/core/constants';
import { Role } from '@app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RenameRoleEvent } from './rename-role.component.types';
import template from './rename-role.component.html';
import styles from './rename-role.component.scss';

@Component({
    selector: 'rename-role',
    template,
    styles: [String(styles)]
})
export class RenameRoleComponent implements OnInit {
  @Input() role: Role;
  @Output() onRename = new EventEmitter<RenameRoleEvent>();
  originalName: string;
  isProcessing = false;
  showTooltip = {
      roleName: false
  };

  roleNamePattern = REGEX.names;
  nameMessage = MESSAGES.validNameMessage;
  renameRoleForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private modal: BsModalRef
  ) {}

  ngOnInit(): void {
      this.initForm();
      this.originalName = this.role.name;
      this.renameRoleForm.controls.roleName.setValue(this.role.name);
  }

  cancel(): void {
      this.modal.hide();
  }

  save(): void {
      if (this.renameRoleForm.invalid) {
          return;
      }

      this.isProcessing = true;
      this.role.name = this.renameRoleForm.value.roleName;
      const renameEvent = {
          role: this.role,
          onError: () => {
              this.isProcessing = false;
          },
          onSuccess: () => {
              this.modal.hide();
          }
      };

      this.onRename.emit(renameEvent);
  }

  handleKeyPress(event: KeyboardEvent): void {
      if (event.which === 13 && this.renameRoleForm.valid
        && this.renameRoleForm.value.roleName.trim() !== this.originalName.trim()) {
          this.save();
      }
  }

  initForm(): void {
      this.renameRoleForm = this.fb.group({
          roleName: ['', [Validators.required, Validators.pattern(this.roleNamePattern)]]
      });
  }

}
