import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
    AfterViewInit,
    Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild, ViewChildren
} from '@angular/core';

import { CHECKBOX_STATES } from '@app/core/constants';
import { SigningReasons } from '@app/shared/models';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LogEntryBulkRequestSignatureTableDataRow } from '../../containers/log-entry-bulk-signature-requests/log-entry-bulk-signature-requests.types';

import template from './log-entry-bulk-request-signatures-table.component.html';
import styles from './log-entry-bulk-request-signatures-table.component.scss';

@Component({
    selector: 'log-entry-bulk-request-signatures-table',
    template,
    styles: [String(styles)]
})
export class LogEntryBulkRequestSignaturesTableComponent implements OnChanges, AfterViewInit, OnDestroy {
    @ViewChild(CdkVirtualScrollViewport, { static: false }) virtualScroll: CdkVirtualScrollViewport;
    @ViewChildren('pop') popoverRef;

    @Input() tableData: LogEntryBulkRequestSignatureTableDataRow[];
    @Input() isSingleSigner: boolean;
    @Output() rowRemoved = new EventEmitter<LogEntryBulkRequestSignatureTableDataRow>();
    @Output() notifyMeUpdated = new EventEmitter<LogEntryBulkRequestSignatureTableDataRow>();
    @Output() emailSignerUpdated = new EventEmitter<LogEntryBulkRequestSignatureTableDataRow>();
    @Output() signatureReasonSelected = new EventEmitter<{
        reason: SigningReasons;
        rowData: LogEntryBulkRequestSignatureTableDataRow;
    }>();

    @Output() signByDateSelected = new EventEmitter<{
        date: Date;
        rowData: LogEntryBulkRequestSignatureTableDataRow;
    }>();

    bulkMode = false;
    bulkSignByDate: Date = null;
    bulkReason: SigningReasons = null;
    bulkNotifyMe = CHECKBOX_STATES.NOT_SELECTED;
    bulkEmailSigner = CHECKBOX_STATES.NOT_SELECTED;
    destroy$ = new Subject<void>();
    headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
    minDate = new Date();
    rowsSelected = 0;
    signingReasons = Object.values(SigningReasons).sort();
    showPickerBulk: boolean;
    dateTimePositionConfig = {
        left: -205,
        top: 40
    };

    ngOnChanges(changes: SimpleChanges): void {
        const tableDataLengthHasChanged = changes.tableData.previousValue
            && changes.tableData.currentValue.length !== changes.tableData.previousValue.length;
        if (tableDataLengthHasChanged) {
            this.updateRowsSelected();
        }
    }

    ngAfterViewInit(): void {
        this.virtualScroll.elementScrolled()
            .pipe(takeUntil(this.destroy$))
            .subscribe((scrollable: Event): void => {
                if (scrollable) {
                    this.popoverRef._results.forEach((item: PopoverDirective) => item.hide());
                }
            });
    }

    toggleBulkAssign(): void {
        this.bulkMode = !this.bulkMode;
    }

    onTogglePicker(type: 'bulk' | 'single', row: LogEntryBulkRequestSignatureTableDataRow): void {
        if (type === 'bulk') {
            this.showPickerBulk = !this.showPickerBulk;
        }
        else if (type === 'single' && row) {
            row.dropDownShow = !row.dropDownShow;
        }
    }

    toggleIsCheckedAllRows(): void {
        const selectableRows = this.tableData.filter((row) => this.rowCanBeSelected(row));

        if (
            this.headerCheckboxState === CHECKBOX_STATES.NOT_SELECTED
            || this.headerCheckboxState === CHECKBOX_STATES.PARTIALLY_SELECTED
        ) {
            this.headerCheckboxState = CHECKBOX_STATES.SELECTED;
            selectableRows.forEach((row: LogEntryBulkRequestSignatureTableDataRow) => {
                row.checked = this.headerCheckboxState;
                this.applyBulkValuesIfPresent(row);
            });
        }
        else {
            this.headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
            selectableRows.forEach((row: LogEntryBulkRequestSignatureTableDataRow) => {
                row.checked = this.headerCheckboxState;
            });
        }

        this.updateRowsSelected();
    }

    toggleIsCheckedRow(row: LogEntryBulkRequestSignatureTableDataRow): void {
        if (row.checked && row.checked === CHECKBOX_STATES.SELECTED) {
            row.checked = CHECKBOX_STATES.NOT_SELECTED;
        }
        else {
            row.checked = CHECKBOX_STATES.SELECTED;
        }

        this.handleHeaderCheckboxStateOnRowChange();
        this.applyBulkValuesIfPresent(row);
    }

    onBulkSignatureReasonSelect(reason: SigningReasons): void {
        this.bulkReason = reason;

        this.tableData
            .filter((row) => row.checked)
            .forEach((row) => this.onSignatureReasonSelect(reason, row));
    }

    onBulkSignByDateChange(value: Date): void {
        this.bulkSignByDate = value;

        this.tableData
            .filter((row) => row.checked)
            .forEach((row) => this.onSignByDateSelect(value, row));
    }

    onBulkNotifyMeCheck(): void {
        this.bulkNotifyMe = this.bulkNotifyMe === CHECKBOX_STATES.NOT_SELECTED
            ? CHECKBOX_STATES.SELECTED
            : CHECKBOX_STATES.NOT_SELECTED;

        this.tableData
            .filter((row) => row.checked && this.shouldUpdateNotifyMe(row))
            .forEach((row) => this.onNotifyMeUpdated(row));
    }

    onBulkEmailSignerCheck(): void {
        this.bulkEmailSigner = this.bulkEmailSigner === CHECKBOX_STATES.NOT_SELECTED
            ? CHECKBOX_STATES.SELECTED
            : CHECKBOX_STATES.NOT_SELECTED;

        this.tableData
            .filter((row) => row.checked && this.shouldUpdateEmailSigner(row))
            .forEach((row) => this.onEmailSignerUpdated(row));
    }

    onSignatureReasonSelect(
        reason: SigningReasons,
        rowData: LogEntryBulkRequestSignatureTableDataRow
    ): void {
        this.signatureReasonSelected.emit({ reason, rowData });
    }

    onSignByDateSelect(value: Date, rowData: LogEntryBulkRequestSignatureTableDataRow): void {
        this.signByDateSelected.emit({ date: value, rowData });
    }

    onRowRemoved(rowData: LogEntryBulkRequestSignatureTableDataRow): void {
        this.rowRemoved.emit(rowData);
    }

    onNotifyMeUpdated(rowData: LogEntryBulkRequestSignatureTableDataRow): void {
        this.notifyMeUpdated.emit(rowData);
    }

    onEmailSignerUpdated(rowData: LogEntryBulkRequestSignatureTableDataRow): void {
        this.emailSignerUpdated.emit(rowData);
    }

    toggleBulkMode(): void {
        this.bulkMode = !this.bulkMode;
    }

    private handleHeaderCheckboxStateOnRowChange(): void {
        const selectableRows = this.tableData.filter((row) => this.rowCanBeSelected(row));

        if (selectableRows.every((row) => row.checked)) {
            this.headerCheckboxState = CHECKBOX_STATES.SELECTED;
        }
        else if (selectableRows.some((row) => row.checked)) {
            this.headerCheckboxState = CHECKBOX_STATES.PARTIALLY_SELECTED;
        }
        else {
            this.headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
        }

        this.updateRowsSelected();
    }

    private updateRowsSelected(): void {
        this.rowsSelected = this.tableData.filter((row) => row.checked && this.rowCanBeSelected(row)).length;
    }

    private rowCanBeSelected(row: LogEntryBulkRequestSignatureTableDataRow): boolean {
        return !row.signatureColumnHasPendingSignatures
            && !row.signatureColumnHasSignatures
            && !row.otherColumHasPendingSignatureForUser;
    }

    private applyBulkValuesIfPresent(row: LogEntryBulkRequestSignatureTableDataRow): void {
        if (!this.bulkMode) {
            return;
        }

        if (this.bulkReason) {
            this.onSignatureReasonSelect(this.bulkReason, row);
        }

        if (this.bulkSignByDate) {
            this.onSignByDateSelect(this.bulkSignByDate, row);
        }

        if (this.shouldUpdateNotifyMe(row)) {
            this.onNotifyMeUpdated(row);
        }

        if (this.shouldUpdateEmailSigner(row)) {
            this.onEmailSignerUpdated(row);
        }
    }

    private shouldUpdateNotifyMe(row: LogEntryBulkRequestSignatureTableDataRow): boolean {
        return (this.bulkNotifyMe === CHECKBOX_STATES.NOT_SELECTED && row.notifyMe)
            || (this.bulkNotifyMe === CHECKBOX_STATES.SELECTED && !row.notifyMe);
    }

    private shouldUpdateEmailSigner(row: LogEntryBulkRequestSignatureTableDataRow): boolean {
        return (this.bulkEmailSigner === CHECKBOX_STATES.NOT_SELECTED && row.emailSigner)
            || (this.bulkEmailSigner === CHECKBOX_STATES.SELECTED && !row.emailSigner);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
