import { StateOrName, RawParams } from '@uirouter/angularjs';

export const ThemeColors = {
    BLUE: 'blue',
    GREEN: 'green',
    ORANGE: 'orange'
} as const;

export type ActionBarInternalLink = {
    state: StateOrName;
    params: RawParams
}

export interface BarOptions {
    descriptionText: string;
    descriptionTextHelper?: string;
    descriptionLink: ActionBarInternalLink | string;
    actionText: string;
    actionLink: ActionBarInternalLink | string;
    completeActionText?: string;
}
