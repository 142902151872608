import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { AppConfigService } from './app-config.service';

const dependencies = [];

const AppConfigModule = angular
    .module('app.shared.appConfig', dependencies)
    .factory('AppConfigService', downgradeInjectable(AppConfigService));

export default AppConfigModule;
