import {
    CreateLogTemplateStudyResponsibilities,
    CreateLogTemplateStudyRole,
    DoaColumnTypes,
    LogEntryTypes,
    LogTemplateColumn,
    StudyResponsibilities,
    StudyResponsibility,
    StudyRole
} from '../../../shared/models';
import { DEFAULT_DOA_LOG_COLUMN_NAMES_WITH_METADATA } from '../components/doa-log-template/doa-log-columns/doa-log-columns.types';

export class LogTemplateUtil {
    static getNumberOrLetterIdentifier(
        index: number,
        isNumberIdentifier: boolean
    ): string {
        return isNumberIdentifier
            ? `${index + 1}`
            : LogTemplateUtil.numberToLetter(index + 1);
    }

    static numberToLetter(index: number): string {
        const alphabetLength = 26;
        // if index corresponds to a single alphabet character
        if (index <= alphabetLength) {
            // return the single alphabetical character
            return String.fromCharCode(64 + index); // A=65
        }
        // if index is higher than the alphabet goes, use multiple letters (i.e., AA, AB, AC)
        const quotient = Math.floor((index - 1) / alphabetLength);
        const remainder = (index - 1) % alphabetLength;

        return this.numberToLetter(quotient) + this.numberToLetter(remainder + 1);
    }

    static generateResponsibilityIdNameDictionary(
        studyResponsibilities: StudyResponsibility[]
    ): { [id: StudyResponsibility['_id']]: StudyResponsibility['name'] } {
        const responsibilityIdNameDictionary = {};

        studyResponsibilities.forEach((responsibility) => {
            responsibilityIdNameDictionary[responsibility._id] = responsibility.name;
        });

        return responsibilityIdNameDictionary;
    }

    static formatResponsibilitiesToCreate(
        { isNumberIdentifier, values }: StudyResponsibilities
    ): CreateLogTemplateStudyResponsibilities {
        const responsibilityValuesWithIdRemoved = values.map((responsibility) => responsibility.name);

        return {
            isNumberIdentifier,
            values: responsibilityValuesWithIdRemoved
        };
    }

    static formatStudyRolesToCreate(
        roles: StudyRole[],
        responsibilityIdNameDictionary: { [id: string]: string }
    ): CreateLogTemplateStudyRole[] {
        const rolesWithResponsibilityNames = roles.map(({ studyRole, responsibilityIds }) => {
            const roleWithResponsibilityNames = {
                studyRole,
                responsibilities: this.transformResponsibilityIdsToNames(responsibilityIds, responsibilityIdNameDictionary)
            };

            return roleWithResponsibilityNames;
        });

        return rolesWithResponsibilityNames;
    }

    static formatLogColumnsToCreate(
        columns: LogTemplateColumn[],
        responsibilityNames: string[],
        studyRoleIds: string[]
    ): LogTemplateColumn[] {
        return columns.map((column) => {
            const {
                type,
                name,
                multiselectColumnOptions,
                singleselectColumnOptions,
                isNumberIdentifier
            } = column;

            const isMultiSelectColumnType = type === LogEntryTypes.multiSelect;
            const isSingleSelectColumn = type === LogEntryTypes.singleSelect;

            const doaColumnType = DEFAULT_DOA_LOG_COLUMN_NAMES_WITH_METADATA[name]?.doaColumnType;
            const isResponsibilitiesColumn = doaColumnType === DoaColumnTypes.RESPONSIBILITIES;
            const isStudyRoleColumn = doaColumnType === DoaColumnTypes.ROLE;

            const formattedColumn = {
                name,
                type,
                ...(doaColumnType && { doaType: doaColumnType }),
                ...(isMultiSelectColumnType && {
                    multiselectColumnOptions: isResponsibilitiesColumn ? responsibilityNames : multiselectColumnOptions,
                    isNumberIdentifier
                }),
                ...((isSingleSelectColumn && !isStudyRoleColumn) && {
                    singleselectColumnOptions
                }),
                ...(isStudyRoleColumn && {
                    studyRoleColumnOptions: studyRoleIds
                })
            };

            return formattedColumn;
        });
    }

    static formatDoaLogRolesResponsibilitiesToAdd(responsibilities: StudyResponsibilities, studyRoles: StudyRole[]) {
        const newResponsibilities = responsibilities.values
            .filter(({ isNew }) => !!isNew)
            .map(({ name }) => name);

        const updatedStudyRoles = studyRoles
            .filter(({ isNew, isChanged }) => !!isNew || !!isChanged)
            .map(({ studyRole, responsibilityIds }) => {
                const responsibilityIdNameDictionary = this.generateResponsibilityIdNameDictionary(responsibilities.values);

                return {
                    studyRole,
                    responsibilities: this.transformResponsibilityIdsToNames(responsibilityIds, responsibilityIdNameDictionary)
                };
            });

        return {
            ...(newResponsibilities.length && { responsibilities: newResponsibilities }),
            ...(updatedStudyRoles.length && { roles: updatedStudyRoles })
        };
    }

    static getMultiSelectOptionClipboardData(values: string[], isNumberIdentifier: boolean): string {
        const clipboardData = values.map((value, i) => {
            const displayIndex = LogTemplateUtil.getIndexDisplay(i, isNumberIdentifier);

            return `${displayIndex}. ${value}`;
        });

        return JSON.stringify(clipboardData)
            .replace(/["[\]]/g, '') // removing [] and ""
            .replace(/,/g, '\n'); // Add a new line after each responsibility
    }

    private static getIndexDisplay(index: number, isNumberIdentifier: boolean): string {
        if (!isNumberIdentifier) {
            return LogTemplateUtil.numberToLetter(index + 1);
        }
        return (index + 1).toString();
    }

    private static transformResponsibilityIdsToNames(
        responsibilityIds: string[],
        responsibilityIdNameDictionary: { [id: string]: string }
    ): string[] {
        return responsibilityIds.map((responsibilityId) => responsibilityIdNameDictionary[responsibilityId]);
    }
}
