import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { Team } from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';
import template from './bulk-import-responsibilities.component.html';
import styles from './bulk-import-responsibilities.component.scss';
import templateWithFreeTextResponsibilities from '../../../../../../../assets/doa-log-template-responsibilities.xlsx';
import { LogTemplatesService } from '../../../../../../shared/log-templates/log-templates.service';

interface ResponsibilitiesFilePreview {
    responsibility: string
    error?: string
}

@Component({
    selector: 'bulk-import-responsibilities',
    template,
    styles: [String(styles)]
})
export class BulkImportResponsibilitiesComponent implements OnInit {
    @Output() onFileImport: EventEmitter<ResponsibilitiesFilePreview[]> = new EventEmitter();
    @Input() numberOfExistingResponsibilities: number;

    public currentTeam: Team;
    public isProcessingFileUpload = false;
    public uploadedFile: File;
    public templateResponsibilitiesUploaded = false;
    public errorCount = 0;
    public loadingSizes = ['40%', '45%', '30%'];
    public template: string;
    public previewData: ResponsibilitiesFilePreview[];

    constructor(
        private modalRef: BsModalRef,
        private Notifications: NotificationsService,
        private logTemplatesService: LogTemplatesService,
        private CurrentSession: CurrentSessionService
    ) {}

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();

        this.template = templateWithFreeTextResponsibilities;
    }

    onFileAdded($event: FileList): void {
        this.isProcessingFileUpload = true;
        if ($event.length > 1) {
            this.Notifications.error('Please upload a single file.');
        }

        const file = $event.item(0);
        const extension = file.name.split('.').pop();
        if (!(extension?.toLowerCase() === 'xlsx') && !(extension?.toLowerCase() === 'csv')) {
            this.Notifications.error('Only .xlsx or .csv files are supported.');
            return;
        }

        this.uploadedFile = file;
        this.templateResponsibilitiesUploaded = true;

        this.logTemplatesService.getLogTemplateResponsibilitiesFilePreview$(this.currentTeam.teamId, file)
            .subscribe((data) => {
                this.previewData = [...data];


                this.isProcessingFileUpload = false;
                this.countErrors();
            }, ({ error }) => {
                this.Notifications.error(error.message || 'Failed to process the file. Please try again.');
                this.removeFile();
                this.isProcessingFileUpload = false;
            });
    }

    hideModal(): void {
        this.modalRef.hide();
    }

    private countErrors(): void {
        this.errorCount = this.previewData.filter((data) => data.error).length;
    }

    removeFile(): void {
        this.uploadedFile = null;
        this.errorCount = 0;
        this.previewData = [];
        this.templateResponsibilitiesUploaded = false;
    }

    onSubmit(): void {
        this.onFileImport.emit(this.previewData);
        this.hideModal();
    }
}
