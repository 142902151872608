import {
    Directive, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
    selector: '[lineClamp]'
})
export class LineClampDirective implements OnChanges, OnDestroy {
    @Input() lineClamp = 1;
    @Output() clamped = new EventEmitter<boolean>();

    private readonly ELLIPSIS_CHARACTER = '\u2026';
    private readonly TRAILING_WHITESPACE_AND_PUNCTUATION_REGEX = /[ .,;!?'‘’“”\-–—]+$/;
    private readonly LINE_THRESHOLD = 3; // tolerance between measured line and expected in pixels

    private timer: number;
    private resizeSub: Subscription;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        @Inject('Window') private window: Window
    ) {
        this.resizeSub = fromEvent(this.window, 'resize')
            .pipe(throttleTime(500))
            .subscribe(() => this.clamp());
    }

    ngOnChanges(): void {
        this.timer = setTimeout(() => {
            this.clamp();
        });
    }

    private clamp(): void {
        const { nativeElement } = this.elementRef;

        const lineHeight = parseInt(this.window.getComputedStyle(nativeElement).lineHeight, 10);
        const maximumHeight = this.lineClamp * lineHeight;

        nativeElement.style.cssText += 'overflow:hidden;overflow-wrap:break-word;word-wrap:break-word';
        // Exit if text does not overflow `rootElement`.
        if (nativeElement.scrollHeight - maximumHeight <= this.LINE_THRESHOLD) {
            return;
        }

        const clamped = this.truncateElementNode(
            nativeElement,
            nativeElement,
            maximumHeight
        );
        this.clamped.emit(clamped);
    }

    private truncateElementNode(element: HTMLElement | ChildNode, rootElement: HTMLElement, maximumHeight: number): boolean {
        const { childNodes } = element;
        let i = childNodes.length - 1;
        while (i > -1) {
            const childNode = childNodes[i];
            i -= 1;
            if (childNode.nodeType === 1) {
                return this.truncateElementNode(childNode, rootElement, maximumHeight);
            }
            if (childNode.nodeType === 3) {
                return this.truncateTextNode(childNode, rootElement, maximumHeight);
            }

            element.removeChild(childNode);
        }
        return false;
    }

    private truncateTextNode(textNode: ChildNode, rootElement: HTMLElement, maximumHeight: number): boolean {
        let lastIndexOfWhitespace: number;
        let { textContent } = textNode;
        while (textContent.length > 1) {
            lastIndexOfWhitespace = textContent.lastIndexOf(' ');
            if (lastIndexOfWhitespace === -1) {
                break;
            }
            textNode.textContent = textContent.substring(0, lastIndexOfWhitespace);
            if (rootElement.scrollHeight - maximumHeight <= this.LINE_THRESHOLD) {
                textNode.textContent = textContent;
                break;
            }
            ({ textContent } = textNode);
        }
        return this.truncateTextNodeByCharacter(textNode, rootElement, maximumHeight);
    }

    private truncateTextNodeByCharacter(textNode: ChildNode, rootElement: HTMLElement, maximumHeight: number): boolean {
        let { textContent } = textNode;
        let { length } = textContent;
        while (length > 1) {
            // Trim off one trailing character and any trailing punctuation and whitespace.
            textContent = textContent
                .substring(0, length - 1)
                .replace(this.TRAILING_WHITESPACE_AND_PUNCTUATION_REGEX, '');
            ({ length } = textContent);
            textNode.textContent = textContent + this.ELLIPSIS_CHARACTER;
            if (rootElement.scrollHeight - maximumHeight <= this.LINE_THRESHOLD) {
                return true;
            }
        }
        return false;
    }

    ngOnDestroy(): void {
        clearTimeout(this.timer);
        this.resizeSub.unsubscribe();
    }
}
