'use strict';

const INTEGRATION_USER_STUDY_STARTUP_PERMISSIONS = [
    'labelAssign',
    'assignTeamRoles',
    'createBinder',
    'createFolder',
    'createTags',
    // needs to be kept in sync with
    // https://github.com/FlorenceHC/oncore-integration-setup/blob/a87347966f90a1fd3abdec675175e28fd89d75b5/src/create-api-user.ts#L129
    'labelCreate',
    'labelEdit',
    'labelValueManage',
    'folderImportTemplates',
    'inviteUsers',
    'manageTeamRolesAndPermissions',
    'renameBinder',
    'renameFolder',
    'viewBinder',
    'viewNonPiiInDocument',
    'viewTeamStudyProfiles',
    'viewTeamUsersRolesPermissions',
    'createEditTeamStudyProfiles'
];

module.exports = INTEGRATION_USER_STUDY_STARTUP_PERMISSIONS;
