import {
    Component, EventEmitter, Output, Input
} from '@angular/core';

import template from './warning-modal.component.html';
import styles from './warning-modal.component.scss';

@Component({
    selector: 'warning-modal',
    styles: [String(styles)],
    template
})
export class WarningModalComponent {
    @Input() header: string;
    @Input() content: string;
    @Input() primaryButton: string;
    @Input() secondaryButton: string;

    @Output() save = new EventEmitter<void>();
    @Output() dismiss = new EventEmitter<void>();

    dismissModal(): void {
        this.dismiss.emit();
    }

    onSave(): void {
        this.save.emit();
        this.dismiss.emit();
    }
}
