import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const valueMatch = function valueMatch(value: string, caseInsensitive = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        const controlValue = control.value;

        if (!controlValue) {
            return { valueMatch: true };
        }

        const compareValue = caseInsensitive ? controlValue.toLowerCase() : controlValue;
        const targetValue = caseInsensitive ? value.toLowerCase() : value;

        return compareValue !== targetValue ? { valueMatch: true } : null;
    };
};
