import {
    NgModule,
    ModuleWithProviders,
    Provider
} from '@angular/core';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { HttpClientModule } from '@angular/common/http';
import { ScrollToTopDirective } from '@app/shared/scroll-to-top/scroll-to-top.directive';
import { ScrollDetectBottomDirective } from './scroll-detect-bottom/scroll-detect-bottom.directive';
import { BindersService } from './binders/binders.service';
import { PaginationPropertiesService } from './pagination-properties/pagination-properties.service';
import { SigningPasscodeService } from './signing-passcode/signing-passcode.service';
import { ProjectsService } from './projects/projects.service';
import { LogTemplatesService } from './log-templates/log-templates.service';
import { StudiesService } from './studies/studies.service';
import { TeamService } from './teams/team.service';
import { ActionBarService } from './action-bar/action-bar.service';
import { DownloadCheckerService } from './downloads/download-checker.service';
import { DownloadsService } from './downloads/downloads.service';
import { SystemNotificationsService } from './system-notifications/system-notifications.service';
import { SystemNotificationsStoreService } from './system-notifications/system-notifications-store.service';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { FoldersService } from './folders/folders.service';
import { AdapterService } from './adapter/adapter.service';
import { ModalStackHelperService } from './modal-helper/modal-stack-helper.service';
import { PermissionsService } from './permissions/permissions.service';
import { PermissionValidatorService } from './permissions/permission-validator.service';
import { SessionsService } from './sessions/sessions.service';
import { UsersService } from './users/users.service';
import { PasswordOrPinExpiryService } from './password-or-pin-expiry/password-or-pin-expiry.service';
import { EllipsisPipe } from './ellipsis/ellipsis.pipe';
import { DaysRemainingPipe } from './days-remaining/days-remaining.pipe';
import { AccessDatesService } from './access-dates/access-dates.service';
import { MultiSelectService } from './multi-select/multi-select.service';
import { AppFeaturesService } from './app-features/app-features.service';
import { AuditTrailService } from './audit-trail/audit-trail.service';
import { LabelsService } from './labels/labels.service';
import { LoginAlertsService } from './login-alerts/login-alerts.service';
import { DocumentHistoryService } from './document-history/document-history.service';
import { EllipsizedTextComponent } from './ellipsized-text/ellipsized-text.component';
import { AppAutoFocusDirective } from './auto-focus/auto-focus.directive';
import { SearchService } from './search/search.service';
import { CapitalizePipe } from './capitalize/capitalize.pipe';
import { DocumentService } from './documents/document.service';
import { TeamInboxCheckerService } from './teams-inbox/team-inbox-checker.service';
import { ApiErrorsService } from './api-error/api-errors.service';
import { HasValuePipe } from './has-value/has-value.pipe';
import { LimitToPipe } from './limit-to/limit-to.pipe';
import { ValuesPipe } from './values-pipe/values.pipe';
import { SortByDatePipe } from './sort-by-date/sort-by-date.pipe';
import { NumberOrLetterIdentifierPipe } from '../components/log-templates/pipes/number-or-letter-identifier.pipe';
import { IdleMonitorService } from './idle-service/idle-service.service';
import { SessionIdleService } from './session-idle/session-idle-service.service';
import { UserTrainingModule } from './user-training/user-training.module';

const upgradeProvider = (provide, injectorName: string): Provider => ({
    provide,
    useFactory: ($injector) => $injector.get(injectorName),
    deps: ['$injector']
});

// https://angular.io/guide/styleguide#style-04-10
@NgModule({
    imports: [
        TooltipModule.forRoot(),
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        PopoverModule.forRoot(),
        HttpClientModule,
        UserTrainingModule
    ],
    providers: [
        { provide: 'Window', useValue: window },
        AccessDatesService,
        BindersService,
        PaginationPropertiesService,
        SigningPasscodeService,
        ProjectsService,
        AppFeaturesService,
        TeamService,
        FoldersService,
        LabelsService,
        AdapterService,
        ActionBarService,
        LogTemplatesService,
        UsersService,
        StudiesService,
        DownloadsService,
        DownloadCheckerService,
        IdleMonitorService,
        TeamInboxCheckerService,
        SystemNotificationsService,
        SystemNotificationsStoreService,
        MultiSelectService,
        ModalStackHelperService,
        PermissionsService,
        PermissionValidatorService,
        SessionsService,
        upgradeProvider(PasswordOrPinExpiryService, 'PasswordOrPinExpiry'),
        ApiErrorsService,
        AuditTrailService,
        DocumentHistoryService,
        LoginAlertsService,
        SearchService,
        DocumentService,
        SessionIdleService
    ],
    declarations: [
        EllipsisPipe,
        SafeHtmlPipe,
        DaysRemainingPipe,
        CapitalizePipe,
        EllipsizedTextComponent,
        AppAutoFocusDirective,
        ScrollToTopDirective,
        ScrollDetectBottomDirective,
        HasValuePipe,
        LimitToPipe,
        ValuesPipe,
        SortByDatePipe,
        NumberOrLetterIdentifierPipe
    ],
    exports: [
        TooltipModule,
        BsDropdownModule,
        EllipsisPipe,
        SafeHtmlPipe,
        DaysRemainingPipe,
        CapitalizePipe,
        PopoverModule,
        TabsModule,
        EllipsizedTextComponent,
        AppAutoFocusDirective,
        ScrollToTopDirective,
        ScrollDetectBottomDirective,
        HasValuePipe,
        LimitToPipe,
        ValuesPipe,
        SortByDatePipe,
        NumberOrLetterIdentifierPipe
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule
        };
    }
}
