import { Ng2StateDeclaration } from '@uirouter/angular';
import { DashboardTimelinesComponent } from './containers/dashboard-timelines/dashboard-timelines.component';
import { DashboardsFeatureGuard } from './dashboard-feature-guard';
import { ProjectsPageComponent } from './containers/projects-page/projects-page.component';
import { ProjectsDetailPageComponent } from './containers/projects-detail-page/projects-detail-page.component';
import { TimelineDetailPageComponent } from './containers/timeline-detail-page/timeline-detail-page.component';
import { TimelineUpdateComponent } from './containers/timeline-update/timeline-update.component';
import { DashboardTimelineDetailPageComponent } from './containers/dashboard-timeline-detail-page/dashboard-timeline-detail-page.component';

const resolve = [{
    token: 'dashboardsFeatureGuard',
    deps: [DashboardsFeatureGuard],
    resolveFn: (guard: DashboardsFeatureGuard) => guard.canActivate().subscribe()
}];

const commonData = { requiresAuth: true };

export const projectDashboardRoutes: Ng2StateDeclaration [] = [
    {
        name: 'app.team.dashboard-timelines',
        url: '/dashboard-timelines/:projectId',
        params: {
            projectId: ''
        },
        component: DashboardTimelinesComponent,
        data: commonData,
        resolve
    },
    {
        name: 'app.team.dashboard-timeline-detail',
        url: '/projects/:projectId/dashboards/timeline/:timelineId',
        component: DashboardTimelineDetailPageComponent,
        data: commonData,
        resolve
    },
    {
        name: 'app.team.manage-projects',
        url: '/projects',
        component: ProjectsPageComponent,
        data: commonData,
        resolve
    },
    {
        name: 'app.team.manage-project',
        url: '/projects/:projectId',
        component: ProjectsDetailPageComponent,
        data: commonData,
        resolve
    },
    {
        name: 'app.team.manage-project-timeline',
        url: '/projects/:projectId/timeline/:timelineId',
        component: TimelineDetailPageComponent,
        data: commonData,
        resolve
    },
    {
        name: 'app.team.timeline-update',
        url: '/projects/:projectId/timelines/:timelineId/edit',
        component: TimelineUpdateComponent,
        data: commonData,
        resolve
    }
];
