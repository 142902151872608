import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';

import {
    Binder,
    BrowseNode,
    BrowseTree,
    Digest,
    Document,
    Folder,
    Role,
    Tag,
    Task,
    Team,
    TeamPermissions,
    User,
    UserRole,
    UserWithRoles
} from '@app/shared/models';
import {
    Observable, forkJoin, of, throwError
} from 'rxjs';
import {
    catchError, map, switchMap, tap
} from 'rxjs/operators';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import * as _ from 'lodash';
import { DocumentCategory } from '@app/components/documents/components/assign-category/assign-category.component.types';
import { StateService } from '@uirouter/core';
import { SessionActivityService } from '@app/components/sessions/session-activity.service';
import { FailedShortcutCreation, FailedTaskReminder, SuccessfulTaskReminder } from '@app/components/sessions/session-activity.service.types';
import { GetRoleResponse } from '@app/components/roles/roles.service.types';
import { ApiErrorsService } from '../api-error/api-errors.service';
import {
    AssignTagsResponse,
    BrowseParams,
    BulkUpdateTaskParams,
    BulkUpdateTaskResponse,
    CreateDocumentShortcutReponse,
    CreateRolesTemplatesAuditParams,
    CreateRolesTemplatesAuditResponse,
    CreateShortcutResult,
    ExecuteRemoveAllPermissionsParams,
    GetDigestsInput,
    GetObjectTreeResponse,
    GetRolesTemplatesAuditsResponse,
    GetRolesTemplatesAuditsResultsParams,
    GetRolesTemplatesAuditsResultsResponse,
    GlobalViewTreeParamValue,
    GlobalViewTreeParams,
    LoadUsersParams,
    LoadUsersResponse,
    RoleTemplate,
    RolesAndUsersCreateParams,
    RolesAndUsersCreateResponse,
    TreeWithDocs,
    UpdatePermissionsParams,
    UpdateRoleAssignmentMultipleParams,
    UpsertDigestSubscriptionParams
} from './teams.service.types';
import { GetEffectivePermissionsParams } from '../permissions/permissions.service.types';
import { PermissionTree } from '../models/permission-tree.model';
import { ItemPermission } from '../permissions/permission-validator.service.types';

@Injectable()
export class TeamService {
    currentTeam: Team;

    readonly customErrorMessages = 'There was an unexpected error. Please try again. If the error still happens, please contact your administrator.'

    readonly url = {
        base: '/api/teams',
        show: (teamId: string) => `/api/teams/${teamId}`,
        users: (teamId: string) => `/api/teams/${teamId}/users`,
        tags: (teamId: string) => `/api/teams/${teamId}/tags`,
        rolesTemplatesAudit: (teamId: string, userId: string) => `/api/teams/${teamId}/users/${userId}/roles-templates-audits`,
        rolesTemplatesAuditResults: (teamId: string, userId: string, rolesTemplatesAuditId: string) => `/api/teams/${teamId}/users/${userId}/roles-templates-audits/${rolesTemplatesAuditId}/results`,
        rolesAndUsers: (teamId: string) => `/api/teams/${teamId}/roles-and-users`,
        rolesTemplates: (teamId: string) => `/api/teams/${teamId}/roles-templates`,
        task: (teamId: string) => `/api/teams/${teamId}/tasks`,
        taskUsers: (teamId: string, objectId: string, objectType: string) => `/api/teams/${teamId}/task-managers?objectId=${objectId}&objectType=${objectType}`,
        userPermissions: (teamId: string) => `/api/teams/${teamId}/users/permissions`,
        digests: (teamId: string, teamMemberId: string) => `/api/teams/${teamId}/digests?teamMemberId=${teamMemberId}`,
        digestsSubscriptionsCreate: (teamId: string, digestId: string) => `/api/teams/${teamId}/digests/${digestId}/digest-subscriptions`,
        digestsSubscriptionsUpdate: (teamId: string, digestId: string, subscriptionId: string) => `/api/teams/${teamId}/digests/${digestId}/digest-subscriptions/${subscriptionId}`,
        subject: (teamId: string) => `/api/teams/${teamId}/subject`,
        permissions: (teamId: string) => `/api/teams/${teamId}/permissions`,
        browse: (teamId: string) => `/api/teams/${teamId}/browse`,
        originalDocuments: (teamId: string) => `/api/teams/${teamId}/documents/originals`,
        shortcuts: (teamId: string) => `/api/teams/${teamId}/documents/shortcuts`,
        categories: (teamId: string) => `/api/teams/${teamId}/document-categories`,
        rolesAndUsersSearch: (teamId: string) => `/api/teams/${teamId}/roles-and-users/search`,
        accessUpdate: (teamId: string) => `/api/teams/${teamId}/user-roles`,
        treeWithDocs: (teamId: string, binderId: string, includeArchived: boolean) => `/api/tree/teams/${teamId}/binders/${binderId}?includeDocs=true&includeArchived=${includeArchived}`,
        objectTree: (teamId: string) => `/api/teams/${teamId}/tree`,
        createShortcuts: (teamId: string) => `/api/teams/${teamId}/shortcuts`,
        userRoles: (teamId: string, userId: string): string => `/api/teams/${teamId}/users/${userId}/roles`
    };

    constructor(
        private CurrentSession: CurrentSessionService,
        private http: HttpClient,
        private ApiErrors: ApiErrorsService,
        private Notifications: NotificationsService,
        private $state: StateService,
        private SessionActivity: SessionActivityService
    ) {
        this.CurrentSession.currentTeam$.subscribe((team: Team) => {
            this.currentTeam = team;
        });
    }

    private getCurrentTeamPermissions(): TeamPermissions | Record<string, never> {
        return (this.currentTeam && (this.currentTeam.permissions || {})) || {};
    }

    canManageTeamTags(): boolean {
        const perms = this.getCurrentTeamPermissions();
        return perms.manageTeam || perms.createTags || perms.updateTags || perms.deleteTags;
    }

    canManageTeamProjects(): boolean {
        const perms = this.getCurrentTeamPermissions();
        return perms.viewDashboard
            && (perms.manageProjects
                || perms.importProjects
                || perms.createProjects
                || perms.updateProjects
                || perms.deleteProjects
                || perms.duplicateProjects
            );
    }

    canManageProjectTimelines(): boolean {
        const perms = this.getCurrentTeamPermissions();
        return perms.viewDashboard
            && (perms.manageTimelines
                || perms.createTimeline
                || perms.updateTimeline
                || perms.deleteTimelines
                || perms.duplicateTimelines
                || perms.assignToTimelines
            );
    }

    canViewProjectsAndTimelines() {
        return this.canManageTeamProjects() || this.canManageProjectTimelines();
    }

    canManageIndividualTimeline() {
        const perms = this.getCurrentTeamPermissions();
        return perms.viewDashboard
            && (perms.manageTimelines
                || perms.updateTimeline
                || perms.assignToTimelines
            );
    }

    canEditIndividualTimeline() {
        const perms = this.getCurrentTeamPermissions();
        return perms.viewDashboard
            && (perms.manageTimelines
                || perms.updateTimeline
            );
    }

    loadById(teamId: string): Observable<Team> {

        const url = this.url.show(teamId);

        return this.http
            .get<{ team: Team }>(url)
            .pipe(
                map(({ team }) => {
                    return team;
                }),
                catchError((error) => this.ApiErrors.handleError(error))
            );
    }

    loadUsers({
        teamId,
        pageNum,
        pageSize,
        sortBy,
        sortDir,
        filter
    }: LoadUsersParams): Observable<LoadUsersResponse> {

        const loadUrl = this.url.users(teamId);

        let params = new HttpParams();

        if (pageNum !== undefined) {
            params = params.append('pageNum', String(pageNum));
        }
        if (pageSize !== undefined) {
            params = params.append('pageSize', String(pageSize));
        }
        if (sortBy !== undefined) {
            params = params.append('sortBy', sortBy);
        }
        if (sortDir !== undefined) {
            params = params.append('sortDir', sortDir);
        }
        if (filter !== undefined) {
            params = params.append('filter', filter);
        }

        return this.http.get<LoadUsersResponse>(loadUrl, { params })
            .pipe(
                map((response) => {
                    return response;
                }),
                catchError((error) => this.ApiErrors.handleError(error))
            );
    }


    getUserRole(teamId: string, userId: string): Observable<Role[]> {
        return this.http.get<Role[]>(this.url.userRoles(teamId, userId));
    }

    resendInvitation(user: User): Observable<User[]> {

        const url = this.url.users(this.currentTeam.id);

        const body = { emails: [user.email] };

        const params = {
            isReminder: 'true'
        };

        return this.http.post<{ users: User[] }>(url, body, { params })
            .pipe(
                map(({ users }) => {
                    this.Notifications.success(`Invitation resent to ${users[0].email}.`);
                    return users;
                }),
                catchError((error) => this.ApiErrors.handleError(error))
            );
    }

    inviteUsersToTeam(emails: string[], sendNotifications: boolean): Observable<User[]> {

        const url = this.url.users(this.currentTeam.id);

        const body = { emails };

        let params = new HttpParams();
        params = params.append('sendNotifications', String(sendNotifications));

        return this.http
            .post<{ users: User[] }>(url, body, { params })
            .pipe(
                map(({ users }) => {
                    if (users.length === emails.length) {
                        this.Notifications.success(`${users.length} member(s) added to the team!`);
                    }
                    else if (users.length === 0) {
                        this.Notifications.success(`${emails.length} member(s) already on team.`);
                    }
                    else {
                        this.Notifications.success(`${emails.length - users.length} member(s) already on team.<br>${users.length} member(s) added to the team!`);
                    }

                    return users;
                }),
                catchError((error) => this.ApiErrors.handleError(error))
            );
    }

    removeUserFromTeam(user: User): Observable<boolean | void> {

        const url = `${this.url.users(this.currentTeam.id)}/${user.id}`;

        return this.http
            .delete<boolean>(url)
            .pipe(
                map((result) => {
                    this.Notifications.success(`${user.email} has been removed from the team.`);
                    return result;
                }),
                catchError((error) => this.ApiErrors.handleError(error))
            );
    }

    assignTags(objectId: string, objectType: string, tagIds: string[]): Observable<number> {

        const url = `${this.url.tags(this.currentTeam.id)}/assign/${objectId}`;

        return this.http
            .patch<AssignTagsResponse>(url, { objectType, tagIds })
            .pipe(
                map((response: AssignTagsResponse) => {
                    this.Notifications.success('Tags Updated!');
                    return response.result.updatedCount;
                }),
                catchError((error) => this.ApiErrors.handleError(error))
            );
    }

    getTags(teamId = ''): Observable<Tag[]> {

        const url = this.url.tags(teamId || this.currentTeam.id);

        return this.http
            .get<{ tags: Tag[] }>(url)
            .pipe(
                map(({ tags }) => {
                    return tags;
                }),
                catchError((error) => this.ApiErrors.handleError(error))
            );
    }

    createRolesTemplatesAudit(params: CreateRolesTemplatesAuditParams): Observable<CreateRolesTemplatesAuditResponse> {
        return this.http
            .post<CreateRolesTemplatesAuditResponse>(this.url.rolesTemplatesAudit(this.currentTeam.id, params.userId), params)
            .pipe(
                map((data) => {
                    this.Notifications.clearAll();
                    this.Notifications.success('Audit requested');
                    return data;
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    getRolesTemplatesAudits(userId: string): Observable <GetRolesTemplatesAuditsResponse> {

        let params = new HttpParams();

        params = params.append('ignoreLoadingBar', String(true));

        return this.http
            .get<GetRolesTemplatesAuditsResponse>(this.url.rolesTemplatesAudit(this.currentTeam.id, userId), { params })
            .pipe(
                map((data) => {
                    return data;
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    getRolesTemplatesAuditsResults(
        requestParams: GetRolesTemplatesAuditsResultsParams
    ):Observable <GetRolesTemplatesAuditsResultsResponse> {

        let params = new HttpParams();

        params = params.append('ignoreLoadingBar', String(true));

        return this.http
            .get<GetRolesTemplatesAuditsResultsResponse>(this.url.rolesTemplatesAuditResults(
                this.currentTeam.id,
                requestParams.userId,
                requestParams.id
            ), { params })
            .pipe(
                map((data) => {
                    return data;
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    rolesAndUsersCreate(params: RolesAndUsersCreateParams): Observable <RolesAndUsersCreateResponse> {
        return this.http
            .post<RolesAndUsersCreateResponse>(this.url.rolesAndUsers(this.currentTeam.id), params)
            .pipe(
                map((data) => {
                    this.Notifications.clearAll();
                    const actionToShow = params.action === 'preview' ? 'Preview' : 'Assign';
                    this.Notifications.success(`Role Users ${actionToShow} finished!`);
                    return data;
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    rolesTemplates(): Observable<RoleTemplate[]> {
        return this.http
            .get<{rolesTemplates: RoleTemplate[]}>(this.url.rolesTemplates(this.currentTeam.id))
            .pipe(
                map(({ rolesTemplates }) => {
                    return rolesTemplates;
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    createTask(teamId: string, task: Task): Observable<void> {
        return this.http
            .post<Task>(this.url.task(teamId), task)
            .pipe(
                map(() => {
                    this.Notifications.success('Task Created!');
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    updateTask(teamId: string, task: Task): Observable<void> {
        return this.http
            .patch<Task>(`${this.url.task(teamId)}/${task.id}`, task)
            .pipe(
                map(() => {
                    this.Notifications.success('Task Updated!');
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    deleteTask(teamId: string, task: Task): Observable<void> {
        return this.http
            .delete<Task>(`${this.url.task(teamId)}/${task.id}`)
            .pipe(
                map(() => {
                    this.Notifications.success(`Successfully deleted task ${task.name}!`);
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    getTask(teamId: string, taskId: string): Observable<Task> {
        return this.http
            .get<{ task: Task }>(`${this.url.task(teamId)}/${taskId}`)
            .pipe(
                map(({ task }) => {
                    return task;
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    getTaskUsers(teamId: string, objectId: string, objectType: string): Observable<User[]> {
        const loadUrl = this.url.taskUsers(teamId, objectId, objectType);

        return this.http.get<User[]>(loadUrl)
            .pipe(
                map((user) => {
                    return user;
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    getAll(): Observable<Team[]> {

        let params = new HttpParams();

        params = params.append('skipDecoration', String(true));

        return this.http.get<{ teams: Team[]}>(this.url.base, { params })
            .pipe(
                map(({ teams }) => {
                    return teams;
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    getUserObjectPermissions(objectId: string, objectType: string): Observable<{ [key: string]: boolean }> {
        const params = {
            objectId,
            objectType
        };

        const teamId = this.currentTeam.id;
        return this.http
            .get<{ permissions: { [key: string]: boolean } }>(this.url.userPermissions(teamId), { params })
            .pipe(
                map(({ permissions }) => {
                    return permissions;
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    getDigests({ teamId, teamMemberId }: GetDigestsInput): Observable<Digest[]> {
        return this.http
            .get<{digests: Digest[]}>(this.url.digests(teamId, teamMemberId))
            .pipe(
                map(({ digests }) => {
                    return digests;
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    upsertDigestSubscription({
        teamId,
        digestToUpdate,
        frequency,
        sendIfZero,
        frequencySettings,
        teamMemberId
    }: UpsertDigestSubscriptionParams): Observable<Digest> {

        const params = {
            teamId,
            digestId: digestToUpdate.id,
            subscriptionId: undefined
        };

        let urlName = this.url.digestsSubscriptionsCreate(teamId, params.digestId);
        if (digestToUpdate.notificationSubscriptionId) {
            params.subscriptionId = digestToUpdate.notificationSubscriptionId;
            urlName = this.url.digestsSubscriptionsUpdate(teamId, params.digestId, params.subscriptionId);
        }

        return this.http.patch<{ digest: Digest}>(urlName, {
            frequency, sendIfZero, frequencySettings, teamMemberId
        })
            .pipe(
                map(({ digest }) => {
                    this.Notifications.success('Email Notification Preferences Updated!');
                    return digest;

                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    getPRSubject(teamId: string, subjectId: string, subjectType: string): Observable<UserWithRoles> {

        let params = new HttpParams();

        params = params.append('subjectId', subjectId);
        params = params.append('subjectType', subjectType);
        params = params.append('withRoles', String(true));

        return this.http
            .get<UserWithRoles>(this.url.subject(teamId), { params })
            .pipe(
                map((subject) => {
                    return subject;

                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    loadSubjectPermissionsForObject(params: GetEffectivePermissionsParams): Observable<ItemPermission> {

        const { teamId } = params;
        const query = _.pick(params, [
            'subjectId',
            'subjectType',
            'objectId',
            'objectType'
        ]);
        return this.http
            .get<ItemPermission>(this.url.permissions(teamId), { params: query })
            .pipe(
                map((permissions) => {
                    return permissions;

                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    updatePermissions(perms: UpdatePermissionsParams): Observable<Role> {

        return this.http
            .patch<{ role: Role}>(this.url.permissions(perms.subject.teamId || this.currentTeam.id), perms)
            .pipe(
                map(({ role }) => {
                    this.Notifications.success('Permissions Updated!');
                    return role;
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    browse(teamId: string, params: BrowseParams): Observable<BrowseTree> {

        let httpParams = new HttpParams();
        Object.keys(params).forEach((key) => {
            const value = params[key];
            if (Array.isArray(value)) {
                value.forEach((item) => {
                    httpParams = httpParams.append(key, item);
                });
            }
            else if (value !== undefined) {
                httpParams = httpParams.set(key, value.toString());
            }
        });

        return this.http
            .get<{ root: BrowseTree }>(this.url.browse(teamId), { params: httpParams })
            .pipe(
                map((data) => {
                    return data.root;
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    hasOriginalDocuments(teamId: string, params: { objectType: string, objectIds: string[] }): Observable<boolean> {

        return this.http
            .get<{hasOriginalDocuments: boolean}>(this.url.originalDocuments(teamId), { params })
            .pipe(
                map(({ hasOriginalDocuments }) => {
                    return hasOriginalDocuments;
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    hasShortcuts(teamId: string, params: { objectType: 'folders', objectIds: string[] }): Observable<boolean> {

        return this.http
            .get<{ hasShortcuts: boolean }>(this.url.shortcuts(teamId), { params })
            .pipe(
                map(({ hasShortcuts }) => {
                    return hasShortcuts;
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    getCategories(teamId: string): Observable<DocumentCategory[]> {
        return this.http
            .get<{ documentCategories: DocumentCategory[]}>(this.url.categories(teamId))
            .pipe(
                map(({ documentCategories }) => {
                    return documentCategories;
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    searchRolesAndUsers(filter: string): Observable<(User | Role)[]> {
        const teamId = this.currentTeam.id;

        return this.http
            .get<(User | Role)[]>(this.url.rolesAndUsersSearch(teamId), { params: { filter } })
            .pipe(
                map((response) => {
                    return response;
                }),
                catchError(() => {
                    return of([]);
                })
            );
    }

    updateRoleAssignmentMultiple(
        teamId: string, { creates, updates, deletes }: UpdateRoleAssignmentMultipleParams
    ): Observable<{ created: UserRole[]; updated: UserRole[] } | void> {
        const url = this.url.accessUpdate(teamId);

        if (creates && creates.length > 0) {
            return this.http.post<{ created: UserRole[] }>(url, creates).pipe(
                switchMap((createdResponse) => this.updateAndDeleteRoleAssignments(teamId, { updates, deletes }).pipe(
                    map((updated) => {
                        this.Notifications.success('User Roles Updated!');

                        return {
                            created: createdResponse.created,
                            updated: updated ? updated.updated : []
                        };
                    }),
                    catchError((error) => {
                        return this.ApiErrors.handleError(error);
                    })
                )),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
        }
        return this.updateAndDeleteRoleAssignments(teamId, { updates, deletes }).pipe(
            map((updated) => {
                this.Notifications.success('User Roles Updated!');

                return {
                    created: [],
                    updated: updated ? updated.updated : []
                };
            }),
            catchError((error) => {
                return this.ApiErrors.handleError(error);
            })
        );

    }

    private updateAndDeleteRoleAssignments(
        teamId: string, { updates, deletes }: UpdateRoleAssignmentMultipleParams
    ): Observable<{ updated: UserRole[] } | void> {
        const url = this.url.accessUpdate(teamId);

        const updateObservable = updates.length ? this.http.patch<{ updated: UserRole[] }>(url, updates) : of(undefined);
        const deleteObservable = deletes.length
            ? this.http.request<void>('delete', url, { body: deletes })
            : of(undefined);

        return forkJoin([updateObservable, deleteObservable]).pipe(
            // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-unused-vars
            map(([updatedResponse, _]) => {
                const updated = updatedResponse ? updatedResponse.updated : [];
                return { updated };
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    getObjectTree(teamId: string, params: GlobalViewTreeParams): Observable<GetObjectTreeResponse> {
        if (!params.objectId) {
            return of({});
        }

        let httpParams = new HttpParams();

        const appendParam = (key: string, value: GlobalViewTreeParamValue) => {
            if (value !== undefined && value !== null) {
                httpParams = httpParams.append(key, String(value));
            }
        };

        appendParam('objectId', params.objectId);
        appendParam('objectType', params.objectType);
        appendParam('includeDocs', params.includeDocs);
        appendParam('nameFilter', params.nameFilter);
        appendParam('statFilter', params.statFilter);
        appendParam('monitorReviewFilter', params.monitorReviewFilter);
        appendParam('includeArchived', params.includeArchived);

        return this.http
            .get<GetObjectTreeResponse>(this.url.objectTree(teamId), { params: httpParams })
            .pipe(
                map((data: GetObjectTreeResponse) => {
                    return data;
                }),
                catchError((error) => {
                    if (error.error.statusCode !== 413) {
                        this.ApiErrors.handleError(error);
                    }
                    return throwError(error);
                })
            );
    }

    loadTreeWithDocs(teamId: string, binderId: string, includeArchived = true): Observable<BrowseNode> {
        return this.http.get<TreeWithDocs>(this.url.treeWithDocs(teamId, binderId, includeArchived))
            .pipe(
                map((data) => {
                    return data.trees[0];
                }),
                catchError((error) => this.ApiErrors.handleError(error))
            );
    }

    getPrBinderArray(teamId: string, binderId: string, includeArchived = true, params = {}): Observable<BrowseTree> {
        return this.http
            .get<{ trees: BrowseTree[]}>(this.url.treeWithDocs(teamId, binderId, includeArchived), { params })
            .pipe(
                map((data) => {
                    return data.trees[0];
                }),
                catchError((error) => {
                    if (error.error.statusCode !== 413) {
                        return this.ApiErrors.handleError(error);
                    }
                    return throwError(error);
                })
            );
    }

    update(teamId: string, updatedFields: { [key: string]: unknown }): Observable<Team | Observable<never>> {

        const fields = Object.keys(updatedFields).filter((key) => !_.isUndefined(updatedFields[key]));
        if (fields.length === 0) {
            console.warn('Attempt to update team without supplying any fields to update');
            return of(null);
        }

        return this.http.patch<{ team: Team}>(`${this.url.base}/${teamId}`, updatedFields)
            .pipe(
                map((res) => {
                    const updatedTeam = _.get(res, 'team', false);
                    if (!updatedTeam) {
                        console.error('Successful update request returned no team data');
                        return throwError(() => new Error('No team data'));
                    }
                    const fieldsString = fields.map((field) => {
                        switch (field) {
                            case 'passwordPolicy':
                            case 'enablePasswordPolicy':
                                return 'password policy';
                            case 'disableAddendum':
                                return 'addendum signature';
                            case 'disableAnnotation':
                                return 'annotation signature';
                            case 'disableMonitorApprovedStatus':
                            case 'disableMonitorReviewedStatus':
                                return 'monitor review options';
                            case 'formFieldPlaceholders':
                                return 'form field text';
                            case 'signingPINPolicy':
                                return 'Signing PIN policy';
                            case 'mfaPolicy':
                                return 'Two Factor Authentication';
                            case 'support':
                                return 'support contact';
                            case 'automaticBinderOwnerPermissions':
                                return 'Automatic Binder Owner Permissions';
                            case 'automaticDocumentOwnerPermissions':
                                return 'Automatic Document Owner Permissions';
                            case 'inactivityTimeout':
                                return 'Timeout Period';
                            default:
                                return field;
                        }
                    });
                    const uniqueFieldsString = _.keys(_.keyBy(fieldsString)).join(', ');
                    this.Notifications.success(`Team ${uniqueFieldsString} updated.`);
                    return updatedTeam;
                }),
                catchError((error) => {
                    return this.ApiErrors.handleError(error);
                })
            );
    }

    setHasCheckedOrInheritedChildren(item: PermissionTree): void {
        if (item.allChildrenHidden || item.isHidden) {
            item.hasCheckedChildren = false;
        }
        else if (item.permissions && !item.isHidden) {
            Object.keys(item.permissions).forEach((key) => {
                const priv = item.permissions[key];
                this.setHasCheckedOrInheritedChildren(priv);

                if (!priv.isHidden && (priv.has || priv.hasCheckedChildren)) {
                    item.hasCheckedChildren = true;
                }
                if (priv.inherited || priv.hasInheritedChildren) {
                    item.hasInheritedChildren = true;
                }
            });
        }
    }

    executeRemoveAllPermissions(teamId: string, subject: User): Observable<ExecuteRemoveAllPermissionsParams> {

        const options = {
            headers: new HttpHeaders({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'Content-Type': 'application/json;charset=utf-8'
            }),
            body: {
                subjectId: subject.id,
                subjectType: subject.type,
                onlyActive: subject.onlyActive
            }
        };

        return this.http
            .delete<ExecuteRemoveAllPermissionsParams>(
                this.url.permissions(teamId),
                options
            )
            .pipe(
                tap(() => {
                    const currentUserId = this.CurrentSession.getCurrentUser()?.id;
                    if (subject.type === 'user' && currentUserId && subject.id === currentUserId) {
                        this.$state.go('app.team.binders', { teamId });
                    }
                }),
                tap(() => this.Notifications.success(`${subject.name}'s Permissions Removed!`)),
                catchError((error) => this.ApiErrors.handleError(error))
            );

    }

    private _generateShortcutRepresentation(doc: Document, target: Binder | Folder) {
        const props = [
            'type',
            'teamId'
        ];
        const shortcutRep = {
            path: _.get(target, 'path', ''),
            binderName: _.get(target, 'binderName', target.name),
            name: `${doc.name} - Shortcut`,
            subType: 'shortcut',
            id: _.get(target, 'id.documentId', doc.id),
            version: _.get(target, 'id.version', doc.version)
        };
        return _.assign(shortcutRep, _.pick(doc, props));
    }


    createShortcuts(
        teamId: string,
        documentIds: string[],
        targets: {id: string, type: string}[]
    ): Observable<CreateShortcutResult[]> {
        const postData = {
            originalDocumentsIds: documentIds,
            targets: targets.map((target) => ({ id: target.id, type: target.type }))
        };

        return this.http.post<CreateDocumentShortcutReponse>(this.url.createShortcuts(teamId), postData)
            .pipe(
                map((data: CreateDocumentShortcutReponse) => {
                    if (data.results.length > 0) {
                        data.results.forEach((result) => {

                            const params = {
                                when: new Date(Date.now()),
                                shortcut: result.shortcut,
                                origin: result.originalDocument,
                                target: result.target
                            };
                            this.SessionActivity.pushSuccessfulShortcutCreation(teamId, params);
                        });
                        const baseMsg = `Successfully created ${data.results.length} shortcut(s)!`;
                        const msg = this.SessionActivity.getNotificationMessage(teamId, baseMsg);
                        this.Notifications.success(msg);
                    }

                    if (data.errors.length > 0) {
                        data.errors.forEach((result) => {
                            const params = {
                                when: new Date(Date.now()),
                                shortcut: this._generateShortcutRepresentation(result.originalDocument, result.target),
                                origin: result.originalDocument,
                                target: result.target,
                                error: this.ApiErrors.parseErrorMessage(result.error)
                            } as unknown as FailedShortcutCreation;
                            this.SessionActivity.pushFailedShortcutCreation(teamId, params);
                        });
                        const baseMsg = `Failed to create ${data.errors.length} shortcut(s)!`;
                        const msg = this.SessionActivity.getNotificationMessage(teamId, baseMsg);
                        this.Notifications.error(msg);
                    }
                    return data.results;
                }),
                catchError((error) => this.ApiErrors.handleError(error))
            );
    }

    private _formatTaskForSessionActivity({
        teamId, object, when, error
    }) {
        return {
            when,
            task: {
                teamId,
                binderId: object.binderId,
                folderId: object.folderId,
                documentId: object.documentId,
                id: object.taskId,
                version: object.version,
                type: 'task',
                name: object.taskName,
                fullPath: [object.binderName, object.path, object.name].filter(Boolean).join('/')
            },
            document: {
                teamId,
                binderId: object.binderId,
                folderId: object.folderId,
                id: object.documentId,
                version: object.version,
                type: 'document',
                name: object.name,
                fullPath: [object.binderName, object.path, object.name].filter(Boolean).join('/')
            },
            error
        };
    }

    bulkUpdateTask(params: BulkUpdateTaskParams, selectedItems: Task[]): Observable<void> {

        const { teamId } = params;
        const selectedItemsHash = _.keyBy(selectedItems, 'taskId');

        const uri = this.url.task(teamId);
        return this.http.patch(uri, params)
            .pipe(
                map((data: BulkUpdateTaskResponse) => {
                    const when = new Date(Date.now());
                    data.results.forEach((result) => {

                        const object = selectedItemsHash[result.identifiers.taskId];
                        if (object) {
                            const activityParams = this._formatTaskForSessionActivity({
                                teamId, object, when, error: null
                            }) as unknown as SuccessfulTaskReminder;
                            this.SessionActivity.pushSuccessfulTaskReminder(teamId, activityParams);
                        }
                    });
                    data.errors.forEach((error) => {

                        const object = selectedItemsHash[error.identifiers.taskId];
                        if (object) {
                            const activityParams = this._formatTaskForSessionActivity({
                                teamId, object, when, error: error.error.message
                            }) as unknown as FailedTaskReminder;
                            this.SessionActivity.pushFailedTaskReminder(teamId, activityParams);
                        }
                    });

                    if (data.results.length) {
                        const baseMsg = `Successfully sent ${data.results.length} reminder(s)!`;
                        const msg = this.SessionActivity.getNotificationMessage(teamId, baseMsg);
                        this.Notifications.success(msg);
                    }

                    if (data.errors.length) {
                        const baseMsg = `Failed to send ${data.errors.length} reminder(s)!`;
                        const msg = this.SessionActivity.getNotificationMessage(teamId, baseMsg);
                        this.Notifications.error(msg);
                    }
                }),
                catchError((error) => this.ApiErrors.handleError(error))
            );
    }
}
