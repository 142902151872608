import { Component, Input } from '@angular/core';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { User } from '@app/shared/models';
import { SigningPasscodeService } from '@app/shared/signing-passcode/signing-passcode.service';
import { UsersService } from '@app/shared/users/users.service';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import { ResetPasswordInfoComponent } from '../reset-password-info/reset-password-info.component';
import template from './user-security-settings.component.html';

@Component({
    selector: 'user-security-settings',
    template
})
export class UserSecuritySettingsComponent {
    @Input() user: User;
    isProcessing = false;

    constructor(
        private Modals: ModalsService,
        private SigningPasscode: SigningPasscodeService,
        private Notifications: NotificationsService,
        private Users: UsersService,
        private ApiError: ApiErrorsService
    ) {}

    changeSignPin(event): void {
        this.SigningPasscode.resetSigningPasscode().subscribe(() => {
            this.Notifications.success('Instructions for setting your Signing PIN has been sent to your email');
        });
        event.preventDefault();
        event.stopPropagation();
    }

    resetPassword(): void {
        this.isProcessing = true;
        this.Users.resetPasswordAuth0(this.user.emailCanonical).subscribe(() => {
            this.isProcessing = false;
            this.Modals.show(ResetPasswordInfoComponent, {
                initialState: {}
            });
        },
        ((error) => {
            this.isProcessing = false;
            this.ApiError.handleError(error);
        }));
    }
}
