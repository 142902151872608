/* eslint-disable hapi/hapi-capitalize-modules */

'use strict';

const internalAudits = require('./internal-audits');
const auditsToDrop = require('./audits-to-drop');

module.exports = {
    EBINDER_INTERNAL_FRIENDLY_EVENTS: internalAudits.EBINDER_INTERNAL_FRIENDLY_EVENTS,
    EBINDER_INTERNAL_EVENTS: internalAudits.EBINDER_INTERNAL_EVENTS,
    MSQ_QUEUE_EVENTS: internalAudits.MSQ_QUEUE_EVENTS,
    AUDITS_TO_DROP: auditsToDrop.AUDITS_TO_DROP
};
