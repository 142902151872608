import { FolderizeTreeItem } from '@app/shared/folders/folders.service.types';
import { sortByLexicographically } from './sort-by-lexicographically.util';

const isDocument = (node: FolderizeTreeItem): boolean => {
    return node.type === 'document';
};

const lexSortNode = (node: FolderizeTreeItem): FolderizeTreeItem => {
    if (isDocument(node)) {
        return node;
    }
    if (!(node.items && node.items.length)) {
        return node;
    }
    node.items = sortByLexicographically(node.items, 'name')
        .map((nodeItem) => lexSortNode(nodeItem));
    return node;
};

export const sortFolderStructureLexicographically = (tree: FolderizeTreeItem[]): FolderizeTreeItem[] => {
    let sortedTree = tree;
    if (tree && tree.length) {
        sortedTree = sortByLexicographically(tree, 'name')
            .map((node) => lexSortNode(node));
    }
    return sortedTree;
};
