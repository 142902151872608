import {
    Project, Signature, SignatureRequest, Tag, Task
} from '@app/shared/models';

export enum DeadlineStatus {
    due = 'due',
    almostDue = 'almost due',
    onTrack = 'on track'
}

export enum DeadlineType {
    expiration = 'expiration',
    due = 'due'
}

export type ConversionState = {
    inProgress: boolean;
    isFailed: boolean;
};

export type DueState = {
    type: DeadlineType.due;
    status: DeadlineStatus;
    date: string;
};

export type ExpirationState = {
    type: DeadlineType.expiration;
    status: DeadlineStatus;
    date: string;
}

export type SignaturesState = {
    hasPendingSignatures: boolean;
    isFullySigned: boolean;
    signed: Signature[];
    declined: Signature[];
    pending: SignatureRequest[];
}

export type TasksState = {
    hasPendingTasks: boolean;
    pending: Task[];
}

export type ProjectsState = {
    projects: Project[];
}

export type TimelineItemStatus = {
    completionDate: string;
    dateRawFileUploaded: string;
    normalizedCompletionDate: string;
}

export type ShortcutStatus = {
    isBrokenShortcut: boolean | undefined;
}

export type TagsState = {
    tags: Tag[];
}

export type ItemState = {
    conversion: ConversionState;
    due: DueState;
    expiration: ExpirationState;
    projects: ProjectsState;
    signatures: SignaturesState;
    tasks: TasksState;
    tags: TagsState;
    timelineItemStatus: TimelineItemStatus;
    shortcutStatus: ShortcutStatus;
};

export type AdapterBinderItem = {
    id: string;
    name: string;
    type: string;
    subType: string;
    teamId: string;
    binderId: string;
    folderId: string;
    version: number;
    filenameCanonical: string;
    path: string;
    fullPath: string;
    ext: string;
    updatedAt: string;
    updatedBy: string;
    formStatus: string;
    isBrokenShortcut: boolean;
    state: ItemState;
};
