
import { Ng2StateDeclaration } from '@uirouter/angular';
import { ArchiveContainerComponent } from './containers/archive-container/archive-container.component';
import { ArchiveWizardComponent } from './containers/archive-wizard/archive-wizard.component';
import { ArchiveDownloadComponent } from './containers/archive-download/archive-download.component';

const commonData = { requiresAuth: true };

export const archiveRoutes: Ng2StateDeclaration[] = [
    {
        name: 'app.team.long-term-archives',
        url: '/archive-list',
        params: {
            teamId: ''
        },
        component: ArchiveContainerComponent,
        data: commonData
    },
    {
        name: 'app.team.archive-wizard',
        url: '/archives-wizard/:objectId/:objectType/:objectName',
        params: {
            objectId: '',
            objectType: '',
            objectName: ''
        },
        component: ArchiveWizardComponent,
        data: commonData
    },
    {
        name: 'archives',
        url: '/archives-download/teams/:teamId/archives/:archiveId',
        params: {
            teamId: '',
            archiveId: ''
        },
        component: ArchiveDownloadComponent,
        data: commonData
    }
];
