import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    CheckMissingParams, CheckMissingPermissionsResponse, DownloadPermissionsParams, GetEffectivePermissionsParams,
    GetEffectivePermissionsResponseObject,
    GetTeamBindersVisibilityParams, GetTeamBindersVisiblityResponse, SaveMissingParams, SaveMissingResponse
} from './permissions.service.types';
import { getFilenameFromHeader } from '../downloads/get-filename-form-header.util';

@Injectable()
export class PermissionsService {
    readonly url = {
        permissions: (teamId: string): string => `/api/teams/${teamId}/permissions`,
        checkPermissions: (teamId: string): string => `/api/teams/${teamId}/permissions/needed`,
        permissionOverview: (teamId: string): string => `/api/teams/${teamId}/permissions/download`,
        effectivePermissions: (teamId: string): string => `/api/teams/${teamId}/permissions/list`,
        teamBindersVisible: (teamId: string): string => `/api/teams/${teamId}/permissions/team-binders-visible`
    }

    constructor(
         private $http: HttpClient
    ) {}

    checkMissing(teamId: string, params: CheckMissingParams): Observable<CheckMissingPermissionsResponse[]> {
        let httpParams = new HttpParams();
        Object.entries(params).forEach(
            ([key, value]) => {
                if (value !== null && value !== undefined) {
                    httpParams = httpParams.set(key, value);
                }
            }
        );

        return this.$http
            .get<CheckMissingPermissionsResponse[]>(this.url.checkPermissions(teamId), { params: httpParams });
    }

    saveMissing(teamId: string, params: SaveMissingParams): Observable<SaveMissingResponse[]> {
        return this.$http
            .patch<SaveMissingResponse[]>(this.url.checkPermissions(teamId), params);
    }

    downloadPermissions(params: DownloadPermissionsParams): void {
        let openUrl = this.url.permissionOverview(params.teamId);
        openUrl += `?subjectId=${params.subjectId}&subjectType=${params.subjectType}&filter=${params.filter}&format=${params.format}`;

        // add binderIds to url
        if (params.binderIds && params.binderIds.length) {
            params.binderIds.forEach((id) => {
                openUrl += `&binderIds=${id}`;
            });
        }

        this.$http.get<Blob>(openUrl, {
            observe: 'response',
            responseType: 'blob' as 'json'
        })
            .subscribe(
                (response) => {
                    const blob = new Blob([response.body], {
                        type: 'application/octet-stream'
                    });

                    const fileName = getFilenameFromHeader(response.headers.get('content-disposition'));
                    const blobUrl = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.download = fileName;
                    link.click();

                    URL.revokeObjectURL(link.href);
                }
            );
    }

    getEffectivePermissions(params: GetEffectivePermissionsParams): Observable<GetEffectivePermissionsResponseObject[]> {
        let httpParams = new HttpParams();
        Object.entries(params).forEach(
            ([key, value]) => {
                if (value !== null && value !== undefined) {
                    httpParams = httpParams.set(key, value);
                }
            }
        );

        return this.$http
            .get<GetEffectivePermissionsResponseObject[]>(this.url.effectivePermissions(params.teamId), { params: httpParams })
            .pipe(
                map((data) => {
                    const arrayResult = Object.entries(data).map(([key, value]) => {
                        value.name = key;
                        return value;
                    });

                    return arrayResult;
                })
            );
    }

    getTeamBindersVisibility(teamId, params: GetTeamBindersVisibilityParams): Observable<GetTeamBindersVisiblityResponse> {
        let httpParams = new HttpParams();
        Object.entries(params).forEach(
            ([key, value]) => {
                if (value !== null && value !== undefined) {
                    httpParams = httpParams.set(key, value);
                }
            }
        );

        return this.$http
            .get<GetTeamBindersVisiblityResponse>(this.url.teamBindersVisible(teamId), { params: httpParams });
    }
}
