import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ElementRef
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    takeUntil
} from 'rxjs/operators';

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import uuid from 'uuid';
import styles from './filterable-list.component.scss';
import template from './filterable-list.component.html';

type ListItem = {
    [key: string]: string;
}

@Component({
    selector: 'filterable-list',
    template,
    styles: [String(styles)]
})
export class FilterableListComponent implements OnInit, OnChanges, OnDestroy {
    @Input() items: ListItem[];
    @Input() displayProperty: keyof ListItem;
    @Input() filterPlaceholder: string;
    @Input() actionButtonText: string;
    @Input() noItemsText: string;
    @Output() actionButtonClick = new EventEmitter<ListItem>();

    @ViewChild('filter', { static: false }) filterInput: ElementRef;
    @ViewChild(CdkVirtualScrollViewport, { static: false }) scrollViewport: CdkVirtualScrollViewport;

    private filteredItems: ListItem[] = [];
    private idPropName = `${uuid()}_id`
    private originalItemsHash = {}

    private readonly destroy$ = new Subject<void>();
    filterControl = new FormControl(null);
    readonly filterInputDebounceTime = 400;
    readonly visibleItemsCount = 8;
    readonly itemHeight = 40;

    private highlight(value: string, segment: string): string {
        if (!segment) {
            return value;
        }

        const reg = new RegExp(segment, 'gi');
        const match = value.match(reg);

        if (!match) {
            return value;
        }

        return value.replace(reg, `<b>${match[0]}</b>`);
    }

    private assignInternalIds(): void {
        this.items = this.items.map((item) => {
            const id = uuid();
            this.originalItemsHash[id] = item;

            return {
                ...item,
                [this.idPropName]: id
            };
        });
    }

    ngOnInit(): void {
        this.assignInternalIds();
        this.filteredItems = this.items;
        this.filterControl.valueChanges.pipe(
            filter((v) => v !== null),
            takeUntil(this.destroy$),
            debounceTime(this.filterInputDebounceTime),
            distinctUntilChanged()
        ).subscribe((filterValue) => {
            this.updateListMembers(filterValue);
        });
    }

    private updateListMembers(filterValue: string): void {
        if (filterValue && filterValue.length) {
            this.filteredItems = this.items.filter((item) => {
                const reg = new RegExp(filterValue, 'gi');
                return item[this.displayProperty].match(reg);
            }).map((item) => {
                const highlightedProperty = this.highlight(item[this.displayProperty].toString(), filterValue);
                const highlightedItem = {
                    ...item,
                    [this.displayProperty]: highlightedProperty
                };
                return highlightedItem;
            });
        }
        else {
            this.filteredItems = this.items;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (JSON.stringify(changes.items.currentValue) !== JSON.stringify(changes.items.previousValue)) {
            this.assignInternalIds();
            this.updateListMembers(this.filterControl.value);
        }
    }

    onItemActionClick(item: ListItem): void {
        const itemToPublish = this.originalItemsHash[item[this.idPropName]];
        delete itemToPublish[this.idPropName];
        this.actionButtonClick.emit(itemToPublish);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
