import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { BrowseTree, Document } from '@app/shared/models';
import { BrowseParams } from '@app/shared/teams/teams.service.types';
import {
    VirtualTreeFlatNode,
    VirtualTreeItemSelectedEvent,
    VirtualTreeNode,
    VirtualTreeSelectionMode
} from '@app/widgets/virtual-tree/virtual-tree.component.types';
import { Observable } from 'rxjs';
import template from './select-share-documents.component.html';
import styles from './select-share-documents.component.scss';

@Component({
    selector: 'select-share-documents',
    template,
    styles: [String(styles)]
})
export class SelectShareDocumentsComponent implements OnInit, OnChanges {
    selectionMode = VirtualTreeSelectionMode.MULTI_SELECT;
    loadingRoot = true;
    @Input() exchangeName: string;
    @Input() binders: Observable<VirtualTreeNode[]>;
    @Input() loadItem: (params: BrowseParams) => Promise<BrowseTree>;
    @Input() onSelectionChange: (
        $event: VirtualTreeItemSelectedEvent<VirtualTreeFlatNode>
    ) => void;

    @Input() selectedDocuments: Document[];

    @Output() updateExchangeNameEvent = new EventEmitter<string>();

    isItemSelectable(node: VirtualTreeFlatNode): boolean {
        return node.type === 'document';
    }

    isItemDisplayable(node: VirtualTreeNode): boolean {
        return node.type === 'binder'
            || node.type === 'folder'
            || (node.type === 'document' && node?.formStatus === 'no-form');
    }

    ngOnInit() {
        this.binders.toPromise().then(() => {
            this.loadingRoot = false;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.exchangeName) {
            this.exchangeName = changes.exchangeName.currentValue;
        }
    }

    isSharedDocumentSelected(): boolean {
        return this.selectedDocuments.some((doc) => doc.exchangeEventId);
    }

    /*  WARNING: Uncomment this part of the code when set/edit exchangeName is available again

    sendExchangeNameEvent(event) {
        this.updateExchangeNameEvent.emit(event.target.value);
    } */
}
