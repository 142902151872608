export const forbiddenChars = '\\ / : < > " | ? *';

export const Messages = {
    validNameMessage: `Length must be between 1 and 250 characters and may consist of any characters except the following ${forbiddenChars}.`,
    validLongNameMessage: `Length must be between 1 and 2000 characters and may consist of any characters except the following ${forbiddenChars}.`,
    validMediumNameMessage: `Length must be between 1 and 1000 characters and may consist of any characters except the following ${forbiddenChars}.`,
    validExtremelyLongTextMessage: `Length must be between 1 and 10000 characters and may consist of any characters except the following ${forbiddenChars}.`,
    validShortNameMessage: `Length must be between 1 and 50 characters and may consist of any characters except the following ${forbiddenChars}.`,
    valid100LongNameMessage: `Length must be between 1 and 100 characters and may consist of any characters except the following ${forbiddenChars}.`,
    validOptionalNameMessage: `May consist of any characters except the following ${forbiddenChars}.`,
    validTeamNameMessage: `Length must be between 1 and 250 characters and may consist of any characters except the following ${forbiddenChars}. Team names must also be unique`,
    passwordMessage: 'Passwords must be at least 10 characters long, with an uppercase letter, a lowercase letter, and a number.',
    signingPasscodeMessage: 'Signing PIN must be at least 4 characters long',
    invalidCharactersMessage: `This field may consist of any characters except the following ${forbiddenChars}.`,
    invalidLengthMessage: 'Length must be between 1 and 250 characters',
    invalidBlankMessage: 'Value cannot be blank',
    mandatoryFieldMessage: 'This field is mandatory',
    forbiddenChars,
    premiumFeatureErrorMessage: (featureName: string) => `${featureName} is a premium feature. Please contact customer support to upgrade.`,
    successfullyCreated: (entity: string) => `${entity} successfully created.`,
    successfullyUpdated: (entity: string) => `${entity} successfully updated.`,
    failedToCreate: (entity: string) => `Failed to create ${entity}. Please contact Florence customer support if the problem persists.`,
    failedToLoad: (entity: string) => `Failed to load ${entity}. Please refresh the page to try again, or contact Florence support if the problem persists.`,
    nonUniqueValue: (entity: string, value: string) => `Your team already has a ${entity} with that ${value}. Update to use a unique ${value} and then create the template.`
};

export default Messages;
