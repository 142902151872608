/**
 * Utility to extract the file name from the Content-Disposition header
 * of the download file response
 * @param {string} headerValue - the value of the Content-Disposition header
 * @returns {string} - the filename string with extension
 *
 * @example
 * // returns "filename.jpg"
 * getFilenameFromHeader('form-data; name="fieldName"; filename="filename.jpg"')
 * @example
 * // returns "all col types log new.jpg"
 * getFilenameFromHeader('attachment; filename="all%20col%20types%20log%20new.pdf"')
 */
export const getFilenameFromHeader = (headerValue: string): string => {
    const split = headerValue.split(';');
    const filenameHeaderValue = split.find((e) => e.includes('filename='));
    return decodeURIComponent(filenameHeaderValue.split('"')[1]);
};
