import {
    Component, OnInit
} from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import { StateService } from '@uirouter/core';
import { Document } from '@app/shared/models';
import { DocumentService } from '@app/shared/documents/document.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { QCReviewService } from '@app/components/documents/qc-review.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { AddQcReviewEvent, ChangeReviewerQcReviewEvent, ReviewCycle } from '@app/components/documents/qc-review.services.types';
import { qcReviewStatuses } from '@florencehealthcare/florence-constants/lib/qc-reviews';
import { RemoveQcReviewCycleComponent } from '../remove-qc-review-cycle/remove-qc-review-cycle.component';
import styles from './view-manage-qc-review.component.scss';
import template from './view-manage-qc-review.component.html';
import { DeleteQcReviewComponent } from '../delete-qc-review/delete-qc-review.component';
import { AddChangeQcReviewComponent } from '../add-change-qc-review/add-change-qc-review.component';
import { ReviewFindingsQcReviewComponent } from '../review-findings-qc-review/review-findings-qc-review.component';

@Component({
    selector: 'view-manage-qc-review',
    template,
    styles: [String(styles)]
})

export class ViewManageQcReviewComponent implements OnInit {
    doc: Document;
    docTotalReviews: number;
    qcReviewStatus: string;
    href: string;
    canManageQcReview: boolean;
    reviewHeader: string;
    loadingUsers = false;
    requestorAgreedRejectedFindings = false;
    qcStatusMap = new Map([
        [qcReviewStatuses.PENDING_REVIEW, 'Pending QC Review'],
        [qcReviewStatuses.IN_REVIEW, 'In QC Review'],
        [qcReviewStatuses.APPROVED, 'QC Review Approved'],
        [qcReviewStatuses.DECLINED, 'QC Review Declined'],
        [qcReviewStatuses.REJECTED, 'QC Review Rejected'],
        [qcReviewStatuses.IN_REJECTION_REVIEW, 'In Rejection Review'],
        [qcReviewStatuses.REJECTION_ACCEPTED, 'QC Review Rejected']
    ]);

    constructor(
        private $state: StateService,
        private CurrentSession: CurrentSessionService,
        private Modals: ModalsService,
        private QcReviewService: QCReviewService,
        private Notifications: NotificationsService,
        private DocumentService: DocumentService
    ) {
    }

    ngOnInit() {
        this.loadDocument();
        this.href = this.generateItemHref();
    }

    loadDocument() {
        const { teamId, documentId, version } = this.$state.params;
        this.DocumentService.load(documentId, version, null).subscribe((res) => {
            this.doc = res;
            if (!this.doc.qcReview) {
                this.Notifications.error('QC Review does not exist for the provided document version');
                const navParams = { ...{ teamId, documentId, version }, contentVersion: null };
                this.$state.go('app.team.document-show', navParams, {});
            }
            else {
                this.canManageQcReview = this.doc.permissions.qcReviewAdministrator
                    || (this.isUserQcRequestor() && this.doc.permissions.documentCreateEditQCReview);
                this.reviewHeader = this.setHeader();
                this.docTotalReviews = res.qcReview.reviews?.length;
                this.doc.qcReview.reviews.forEach((review) => {
                    if (qcReviewStatuses.REJECTION_ACCEPTED === review.status) {
                        this.requestorAgreedRejectedFindings = true;
                    }
                });
            }
        }, () => {
            const navParams = { ...{ teamId }, contentVersion: null };
            this.$state.go('app.team.binders', navParams, {});
        });
    }

    isSSOUser(user): boolean {
        if (user.fullName === ''
            || (user.fullName && user.fullName.toString() === user.email)) {
            return true;
        }
        return false;
    }

    canManage(status) {
        const possibleStatuses = [
            qcReviewStatuses.PENDING_REVIEW,
            qcReviewStatuses.IN_REVIEW,
            qcReviewStatuses.DECLINED,
            qcReviewStatuses.REJECTED
        ];

        if (possibleStatuses.includes(status)) {
            return true;
        }

        return false;
    }

    canDeleteOrChangeReviewer(status) {
        const possibleStatuses = [
            qcReviewStatuses.PENDING_REVIEW,
            qcReviewStatuses.IN_REVIEW,
            qcReviewStatuses.DECLINED
        ];

        if (possibleStatuses.includes(status)) {
            return true;
        }

        return false;
    }


    setHeader() {
        if (this.canManageQcReview) {
            return 'Manage QC Review';
        }
        return 'View QC Review';
    }

    generateItemHref(): string {
        const { teamId, documentId, version } = this.$state.params;
        const href = this.$state.href('app.team.document-show', { teamId, documentId, version });
        return href;
    }

    isUserQcRequestor(): boolean {
        return this.doc.qcReview?.requestorId.id === this.CurrentSession.getCurrentUser().id;
    }

    openDeleteQcReviewModal() {
        const modal = this.Modals.show(DeleteQcReviewComponent, {
            initialState: {}
        });

        modal.content.deleteQcReview.subscribe((event) => {

            const { teamId, documentId, version } = this.$state.params;

            const documentVersion = Number(version);

            this.QcReviewService.deleteQcReview({ teamId, documentId, documentVersion })
                .subscribe(() => {
                    const successMessage = 'QC Review successfully deleted';
                    event.onSuccess();
                    this.Notifications.success(successMessage);
                    const navParams = { ...{ teamId, documentId, version }, contentVersion: null };
                    this.$state.go('app.team.document-show', navParams, {});
                },
                (err) => {
                    event.onError();
                    if (err.error && err.error.message) {
                        this.Notifications.error(err.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                });
        });

    }

    openAddQcReviewModal() {
        const documentVersion = this.doc.version;
        const userId = this.CurrentSession.getCurrentUser().id;
        const entity = this.doc;

        const modal = this.Modals.show(AddChangeQcReviewComponent, {
            initialState: {
                entity,
                documentVersion,
                userId
            }
        });

        const { teamId, documentId } = this.$state.params;

        modal.content.addQcReview.subscribe((event: AddQcReviewEvent) => {
            const { review } = event;
            this.QcReviewService.addQcReview({
                documentId, documentVersion, teamId, review
            }).subscribe((res) => {
                const successMessage = 'Review cycle successfully added';
                event.onSuccess();
                this.Notifications.success(successMessage);
                this.doc.qcReview.reviews = res.reviews;
                this.docTotalReviews = res.reviews?.length;
                this.loadDocument();
            },
            (err) => {
                event.onError();
                if (err.error && err.error.message) {
                    this.Notifications.error(err.error.message);
                }
                else {
                    this.Notifications.unexpectedError();
                }
            });

        });

    }

    public trackItem(index: number, item) {
        return item.trackId;
    }

    openChangeReviewerModal(index, reviewCycle): void {
        const documentVersion = this.doc.version;
        const userId = this.CurrentSession.getCurrentUser().id;
        const entity = this.doc;
        const modal = this.Modals.show(AddChangeQcReviewComponent, {
            initialState: {
                entity,
                documentVersion,
                userId,
                index,
                reviewCycle
            }
        });

        const { teamId, documentId } = this.$state.params;


        modal.content.changeReviewerQcReview.subscribe((event: ChangeReviewerQcReviewEvent) => {
            const { review, cycleId } = event;
            this.QcReviewService.changeReviewerQcReview({
                documentId, documentVersion, teamId, review, cycleId
            }).subscribe((res) => {
                const successMessage = 'Review cycle successfully added';
                event.onSuccess();
                this.Notifications.success(successMessage);
                this.doc.qcReview.reviews = res.reviews;
                this.docTotalReviews = res.reviews?.length;
            },
            (err) => {
                event.onError();
                if (err.error && err.error.message) {
                    this.Notifications.error(err.error.message);
                }
                else {
                    this.Notifications.unexpectedError();
                }
            });

        });

    }

    removeCycle(index, cycleId): void {
        const modal = this.Modals.show(RemoveQcReviewCycleComponent, {
            initialState: {
                index,
                totalReviews: this.docTotalReviews
            }
        });

        modal.content.deleteQcReviewCycle.subscribe((event) => {

            const { teamId, documentId, version } = this.$state.params;

            const documentVersion = Number(version);

            this.QcReviewService.removeQcReviewCycle({
                teamId, documentId, documentVersion, cycleId
            })
                .subscribe(() => {
                    const successMessage = 'QC Review Cycle successfully removed';
                    event.onSuccess();
                    this.Notifications.success(successMessage);

                    if (this.docTotalReviews === 1) {
                        const navParams = { ...{ teamId, documentId, version }, contentVersion: null };
                        this.$state.go('app.team.document-show', navParams, {});
                    }
                    else {
                        this.loadDocument();
                    }
                },
                (err) => {
                    event.onError();
                    if (err.error && err.error.message) {
                        this.Notifications.error(err.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                });
        });
    }

    reviewFindings(index: number, review: ReviewCycle): void {
        const currentTeam = this.CurrentSession.getCurrentTeam();
        const modal = this.Modals.show(ReviewFindingsQcReviewComponent, {
            initialState: {
                index,
                totalReviews: this.docTotalReviews,
                entity: this.doc,
                currentTeam,
                review
            }
        });

        modal.content.rejectionAgreeReviewFindings.subscribe((event) => {

            const { teamId, documentId, version } = this.$state.params;
            const { comment } = event;
            const documentVersion = Number(version);

            const params = {
                teamId,
                documentId,
                documentVersion,
                comment
            };

            this.QcReviewService.rejectionAgreeQcReview(params)
                .subscribe((res) => {
                    event.onSuccess();
                    this.doc.qcReview.reviews = res.reviews;
                    this.requestorAgreedRejectedFindings = true;
                },
                (err) => {
                    event.onError();
                    if (err.error && err.error.message) {
                        this.Notifications.error(err.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                });
        });

        modal.content.rejectionDisagreeReviewFindings.subscribe((event) => {

            const { teamId, documentId, version } = this.$state.params;
            const { comment } = event;
            const documentVersion = Number(version);

            this.QcReviewService.rejectionDisagreeQcReview({
                teamId, documentId, documentVersion, comment
            })
                .subscribe((res) => {
                    event.onSuccess();
                    this.doc.qcReview.reviews = res.reviews;
                    this.loadDocument();
                },
                (err) => {
                    event.onError();
                    if (err.error && err.error.message) {
                        this.Notifications.error(err.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                });
        });

    }
}
