import { Injectable } from '@angular/core';
import * as _ from 'lodash';

/**
 * This service handles getting the signing reason off of a document for the current user
 */

@Injectable()
export class DefaultSigningReasonService {

    getDefaultSigningReasonFromPending({ doc, userId }): string {

        if (doc.pendingSignatures && doc.pendingSignatures.length) {
            const pendingSignature = _.find(doc.pendingSignatures, (signatureRequest) => signatureRequest.userId === userId);
            return pendingSignature ? pendingSignature.reason : null;
        }
        return null;
    }

}