import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { BannerThemes } from './banner.types';

import template from './banner.component.html';
import styles from './banner.component.scss';
@Component({
    selector: 'banner',
    template,
    styles: [String(styles)]
})
export class BannerComponent {
    @Input() theme: BannerThemes = BannerThemes.DEFAULT;
    @Input() canDismiss = true;
    @Output() dismiss = new EventEmitter<void>();

    onDismissed(): void {
        this.dismiss.emit();
    }
}
