import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    SaveFormParams,
    UpdateFormResponse
} from './document-form.service.types';

@Injectable({ providedIn: 'root' })
export class DocumentFormService {

    readonly url = {
        updateForm: (teamId: string, documentId: string, documentVersion: number): string => `/api/teams/${teamId}/documents/${documentId}/versions/${documentVersion}/form-fields`
    };

    constructor(
        private http: HttpClient
    ) { }

    updateForm(params: SaveFormParams): Observable<UpdateFormResponse | []> {

        const body = {
            signaturesXfdfString: params.signaturesXfdfString,
            formFields: params.formFields,
            reason: params.reason,
            shouldFinalize: params.shouldFinalize,
            password: params.password,
            signingPasscode: params.signingPasscode
        };

        return this.http.patch<UpdateFormResponse>(
            this.url.updateForm(params.teamId, params.documentId, params.documentVersion),
            body
        );

    }
}
