import {
    Component,
    Input
} from '@angular/core';

import template from './name-preview-item.component.html';
import styles from './name-preview-item.component.scss';

@Component({
    selector: 'name-preview-item',
    template,
    styles: [String(styles)]
})
export class NamePreviewItemComponent {
    @Input() data: {
        error: string;
        name: string;
    };
}
