import { Observable } from 'rxjs';
import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import { Entity, User } from '@app/shared/models';
import { EntityPathService } from '@app/widgets/entity/entity-path.service';

import template from './modal-header.component.html';

@Component({
    selector: 'modal-header',
    template
})
export class ModalHeaderComponent implements OnInit {
    @Input() entity?: Exclude<Entity, User>;

    path$: Observable<Entity[]>;

    constructor(private EntityPath: EntityPathService) {}

    ngOnInit(): void {
        if (!this.entity) {
            return;
        }

        this.path$ = this.EntityPath.getPathForEntity(this.entity);
    }
}
