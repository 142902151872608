'use strict';

exports.IMPORT_TYPES = {
    PLACEHOLDER: 'placeholder',
    FAX: 'fax',
    EMAIL: 'email', // email attachments
    EMAIL_CONTENT: 'emailContent', // email content
    PRINTER: 'printer',
    DIRECT: 'direct',
    MIGRATION: 'Migration',
    SHORTCUT: 'shortcut',
    DUPLICATE: 'duplicate',
    MOVE: 'move',
    API: 'api',
    SIP_INTEGRATION: 'sipIntegration',
    EHUB: 'eHub', // eHub === SiteLink
    EHUB_LOG_DISTRIBUTION: 'ehub_log_distribution',
    DOC_SHARING: 'doc-sharing',
    ECONSENT: 'eConsent',
    PLACEHOLDER_FILL_NEW_LOG: 'newlog',
    V1_PRINTER_CONNECTOR: 'Printer Connector'
};
