/* eslint-disable no-restricted-syntax */

'use strict';

// The list of msg queue events used in eBinders.
const MSQ_QUEUE_EVENTS = {
    MQ_DOWNLOAD_CREATED: 'DownloadCreated',
    MQ_DOCUMENT_CREATED: 'DocumentCreated',
    MQ_CONVERTER: 'DocumentInConverterQueue',
    MQ_MIGRATION: 'DocumentInMigrationQueue',
    MQ_DIGEST_SUBSCRIPTION_PENDING: 'DigestSubscriptionPending',
    MQ_ANNOUNCEMENT_SENT: 'AnnouncementSent',
    MQ_ROLE_TEMPLATE_AUDIT_CREATED: 'RoleTemplateAuditCreated',
    MQ_DOCUMENT_EXCHANGE_START: 'DocumentExchangeStart',
    MQ_ARCHIVAL_SCHEDULED: 'ArchivalScheduled',
    MQ_ARCHIVAL_COMPLETED: 'ArchivalCompleted'
};

// The list of internal events in eBinders.
const EBINDER_INTERNAL_EVENTS = {
    CHILD_FOLDER_PATH_UPDATED: 'childFolderPathUpdated',
    CHILD_DOCUMENT_STUDY_ATTRIBUTES_ASSIGNED: 'childDocumentStudyAttributesAssigned',
    CHILD_DOCUMENT_PATH_UPDATED: 'childDocumentPathUpdated',
    CHILD_PLACEHOLDER_PATH_UPDATED: 'childPlaceholderPathUpdated',
    CHILD_SHORTCUT_PATH_UPDATED: 'childShortcutPathUpdated',
    CHILD_LOG_PATH_UPDATED: 'childLogPathUpdated',
    CHILD_FOLDER_MOVED: 'childFolderMoved',
    CHILD_DOCUMENT_MOVED: 'childDocumentMoved',
    CHILD_PLACEHOLDER_MOVED: 'childPlaceholderMoved',
    CHILD_SHORTCUT_MOVED: 'childShortcutMoved',
    CHILD_LOG_MOVED: 'childLogMoved',
    CHILD_BINDER_LABELS_UPDATED: 'childBinderLabelsUpdated',
    CHILD_FOLDER_LABELS_UPDATED: 'childFolderLabelsUpdated',
    DOCUMENT_EXTRACTED: 'documentExtracted',
    DOCUMENT_APPROVE_REJECT_STARTED: 'documentApproveRejectStarted',
    DOCUMENT_FORM_CHECK_COMPLETED: 'documentFormCheckCompleted',
    SHORTCUT_ORIGINAL_DOC_DUE_DATE_SET: 'shortcutOriginalDocDueDateSet',
    SHORTCUT_ORIGINAL_DOC_EXP_DATE_SET: 'shortcutOriginalDocExpDateSet',
    SHORTCUT_ORIGINAL_PLACEHOLDER_FILLED: 'shortcutOriginalPlaceholderFilled',
    USER_ENTERS_TEAM: 'UserEntersTeam',
    TEAM_FEATURES_UPDATED: 'teamFeaturesUpdated',
    DOCUMENT_EXPORTED_TO_INTEGRATION: 'documentExportedToIntegration',
    ARCHIVAL_STARTED: 'archivalStarted',
    FOLDER_LOCATION_CHANGED: 'folderLocationChanged',
    ...MSQ_QUEUE_EVENTS
};

// The list of friendly internal audit trails used in eBinders.
const EBINDER_INTERNAL_FRIENDLY_EVENTS = {
    childFolderPathUpdated: 'Child Folder Path Updated',
    childDocumentStudyAttributesAssigned: 'Child Document Study Attributes Assigned',
    childDocumentPathUpdated: 'Child Document Path Updated',
    childPlaceholderPathUpdated: 'Child Placeholder Path Updated',
    childShortcutPathUpdated: 'Child Shortcut Path Updated',
    childLogPathUpdated: 'Child Log Path Updated',
    childFolderMoved: 'Child Folder Moved',
    childDocumentMoved: 'Child Document Moved',
    childPlaceholderMoved: 'Child Placeholder Moved',
    childShortcutMoved: 'Child Shortcut Moved',
    childLogMoved: 'Child Log Moved',
    childBinderLabelsUpdated: 'Child Binder Labels Updated',
    childFolderLabelsUpdated: 'Child Folder Labels Updated',
    documentExtracted: 'Document Extracted',
    documentApproveRejectStarted: 'Document Approve/Reject Started',
    documentFormCheckCompleted: 'Document Form Check Completed',
    shortcutOriginalDocDueDateSet: 'Shortcut Original Document Due Date Set',
    shortcutOriginalDocExpDateSet: 'Shortcut Original Document Exp Date Set',
    shortcutOriginalPlaceholderFilled: 'Shortcut Original Placeholder Filled',
    UserEntersTeam: 'User Enters Team',
    teamFeaturesUpdated: 'Team Features Updated',
    documentExportedToIntegration: 'Document Exported to External System',
    archivalStarted: 'Long-term Archive Started',
    folderLocationChanged: 'Folder Location Changed'
};

module.exports = {
    EBINDER_INTERNAL_FRIENDLY_EVENTS,
    EBINDER_INTERNAL_EVENTS,
    MSQ_QUEUE_EVENTS
};
