const tableCellTypes = {
    type: 'type',
    date: 'date',
    name: 'name',
    text: 'text',
    checkbox: 'checkbox',
    username: 'username',
    multiUsername: 'multiUsername',
    location: 'location',
    status: 'status',
    qcStatus: 'qcStatus',
    nameVersion: 'nameVersion'
};

export default tableCellTypes;
