import { NgModule } from '@angular/core';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserModule } from '@angular/platform-browser';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UIRouterModule } from '@uirouter/angular';
import { layoutRoutes, TeamContainerComponent } from './layout.routes';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AppLayoutComponent } from './containers/app-layout/app-layout.component';


@NgModule({
    imports: [
        WidgetsModule,
        BrowserModule,
        UIRouterModule.forChild({ states: layoutRoutes }),
        BrowserAnimationsModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot()
    ],
    providers: [],
    declarations: [TeamContainerComponent, NavbarComponent, AppLayoutComponent],
    entryComponents: [TeamContainerComponent, NavbarComponent, AppLayoutComponent],
    exports: []
})
export class LayoutModule {}
