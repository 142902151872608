import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    Input
} from '@angular/core';
import { from, Subject } from 'rxjs';
import { Entity, Tag } from '@app/shared/models';
import { TeamService } from '@app/shared/teams/team.service';
import { takeUntil } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SORT } from '@app/core/constants';
import styles from './tags-assign.component.scss';
import template from './tags-assign.component.html';

@Component({
    selector: 'tags-assign',
    template,
    styles: [String(styles)]
})
export class TagsAssignComponent implements OnInit, OnDestroy {
    @Input() currentTags: ListItem[] = []
    @Input() entity: Entity;

    @Output() save = new EventEmitter<SaveEventPayload>();

    assignedTags: ListItem[]
    private readonly assignedTagsFilterPlaceholder = 'Type here to filter currently assigned tags'
    private readonly assignedTagsNoItemsText = 'There are currently no assigned tags.';
    private readonly assignedTagsActionButtonText = 'Remove'
    private readonly assignedTagsDisplayProperty = 'name';

    availableTagsToAssign: ListItem[]
    private readonly availableTagsToAssignFilterPlaceholder = 'Type here to filter other tags'
    private readonly availableTagsToAssignNoItemsText = 'There are no tags available to assign.';
    private readonly availableTagsToAssignActionButtonText = 'Assign'
    private readonly availableTagsToAssignDisplayProperty = 'name';
    isProcessing: boolean;
    areTagsLoading: boolean;

    SORT = SORT;

    private readonly destroy$ = new Subject<void>();

    private readonly sortByName = 'name';

    constructor(private Teams: TeamService, private modal: BsModalRef) {}

    ngOnInit(): void {
        this.assignedTags = [...this.currentTags].sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        this.loadTeamTags();
    }

    private loadTeamTags(): void {
        this.areTagsLoading = true;
        from(this.Teams.getTags())
            .pipe(
                takeUntil(this.destroy$)
            ).subscribe((tags: Tag[]) => {
                this.availableTagsToAssign = tags.filter((tag) => {
                    return !this.currentTags.some((currentTag) => {
                        return currentTag.name === tag.name;
                    });
                }).map((tag): ListItem => {
                    return {
                        name: tag.name,
                        // add this prefix truncated to display property(in this case name),
                        // when you want to propagate to filterable-list something with
                        // truncate and tooltip logic, for this scenario it will check names longer than 69 chars and
                        // truncate it and show full name in tooltip
                        ...(tag.name.length > 59 && { truncatedname: 'true' }),
                        id: tag.id
                    };
                });

                this.updateSort();
                this.areTagsLoading = false;
            });
    }

    updateSort(): void {
        this.SORT.set(this.sortByName, false);
        this.availableTagsToAssign = this.availableTagsToAssign.sort((a, b) => {
            const aValue = a.name?.trim() || '';
            const bValue = b.name?.trim() || '';
            return this.SORT.naturalSort({ value: aValue }, { value: bValue });
        });
    }

    onSubmit(): void {
        this.save.emit({
            tagsToAssign: this.assignedTags,
            onSuccess: () => {
                this.dismissModal();
            },
            onError: () => {
                this.isProcessing = false;
                this.dismissModal();
            }
        });
    }

    dismissModal(): void {
        this.modal.hide();
    }

    onRemoveTagActionClick(clickedTag): void {
        this.assignedTags = this.assignedTags.filter((tag) => {
            return tag.id !== clickedTag.id;
        });

        this.availableTagsToAssign = [...this.availableTagsToAssign].concat([clickedTag]).sort((a, b) => {
            return a.name.localeCompare(b.name);
        });

        this.updateSort();
    }

    onAssignTagActionClick(clickedTag): void {
        this.availableTagsToAssign = this.availableTagsToAssign.filter((tag) => {
            return tag.id !== clickedTag.id;
        });

        this.assignedTags = [...this.assignedTags].concat([clickedTag]).sort((a, b) => {
            return a.name.localeCompare(b.name);
        });

        this.updateSort();
    }

    canSubmit(): boolean {
        return (JSON.stringify(this.currentTags) !== JSON.stringify(this.assignedTags)) && !this.isProcessing;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
