import { DocumentId } from '@app/shared/models';

export interface ReplacePlaceholderOnSaveData {
    id: string | DocumentId,
    originalDocumentId: string,
    fillMode: string,
    name: string,
    onSuccess: () => void;
    onError: () => void;
}

export const PlaceholderFillModes = {
    MOVE: 'move',
    CLONE: 'clone',
    SHORTCUT: 'shortcut'
};

export const FillMethodsFriendlyNames = {
    DOCUMENT_MOVE: 'Document Move',
    DOCUMENT_DUPLICATE: 'Document Duplicate',
    SHORTCUT: 'Shortcut'
};


export const TITLE_MODES = {
    ORIGINAL_PLACEHOLDER: 'originalPlaceholder',
    ORIGINAL_DOC: 'originalDoc',
    CUSTOM: 'custom'
};
