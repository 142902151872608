import { StateProvider } from '@uirouter/angularjs';

const announcementsRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.announcements', {
        url: '/announcements?&sortBy&sortDir&pageNum&filterBy&type',
        views: {
            '': 'announcementsInbox'
        },
        params: {
            // make params dynamic, do not reload state when some of them change
            sortBy: { dynamic: true },
            sortDir: { dynamic: true },
            pageNum: { dynamic: true },
            filterBy: { dynamic: true },
            type: { dynamic: true }
        }
    });

    $stateProvider.state('app.team.announcement-view', {
        url: '/announcements/:announcementId',
        params: { returnTo: null },
        views: {
            '': 'announcementView'
        }
    });
};

announcementsRoute.$inject = ['$stateProvider'];
export default announcementsRoute;
