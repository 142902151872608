import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import { downgradeComponent } from '@angular/upgrade/static';

import notificationsRoute from './notifications.route';
import { ManageNotificationsComponent } from './containers/manage-notifications/manage-notifications.component';
import { NotificationSettingsComponent } from './components/notification-settings-modal/notification-settings.component';

const dependencies = [uiRouter];

const notificationsModule = angular
    .module('app.notifications', dependencies)
    .config(notificationsRoute)
    .directive('manageNotifications', downgradeComponent({ component: ManageNotificationsComponent }) as ng.IDirectiveFactory)
    .directive('notificationSettings', downgradeComponent({ component: NotificationSettingsComponent }) as ng.IDirectiveFactory);

export default notificationsModule;
