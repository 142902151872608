import {
    Directive, EventEmitter, HostListener, Input, Output
} from '@angular/core';

@Directive({
    selector: '[scrolledToBottom]'
})
export class ScrollDetectBottomDirective {
    @Input('scrollDetectBottomDistance') scrollDetectBottomDistance = 0;
    @Output('scrolledToBottom') scrolledToBottom = new EventEmitter<void>()

    @HostListener('scroll', ['$event'])
    onScroll(event): void {
        if (event.target.scrollHeight - (event.target.offsetHeight + event.target.scrollTop) <= this.scrollDetectBottomDistance) {
            this.scrolledToBottom.emit(event);
        }
    }
}
