import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import SentryConfigService from './sentry-config.service';


angular.module('ngSentry', []);

const errors = angular
    .module('app.shared.errors', ['ngSentry'])
    .factory('SentryConfigService', downgradeInjectable(SentryConfigService))
    .run(['SentryConfigService', () => {
        return undefined;
    }]); // initiate service on bootstrap

export default errors;
