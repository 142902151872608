/* eslint-disable eqeqeq */
import {
    Component, OnDestroy, OnInit, Input
} from '@angular/core';
import {
    BehaviorSubject,
    Subject
} from 'rxjs';
import {
    Team, Crumb, Study, StudyWithTeamSipIntegrationLink, SiteWithTeamSipIntegrationLink, StudyEntity
} from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';
import { StudiesService } from '@app/shared/studies/studies.service';
import { StateService } from '@uirouter/core';
import { FolderizeTreeItem } from '@app/shared/folders/folders.service.types';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { set } from 'lodash';
import {
    TeamConfiguration, StudyConfig, StudyStartupPayload, ListJobs
} from '../../study-manager.service.types';
import { StudyManagerService } from '../../study-manager.service';
import template from './update-study.component.html';
import styles from './update-study.component.scss';
import { UpdateStudyModal } from '../../components/update-study-modal/update-study-modal.component';


@Component({
    selector: 'study-manager-update',
    template,
    styles: [String(styles)]
})

export class UpdateStudyComponent implements OnInit, OnDestroy {

    @Input() teamConfig: TeamConfiguration;
    @Input() team: Team;

    crumbs: Crumb[] = [
        {
            name: 'Study Manager',
            stateName: 'app.team.study-manager'
        },
        {
            name: 'Update Study'
        }
    ];

    mockedSite : SiteWithTeamSipIntegrationLink;
    readonly progressSteps = { 1: 'Select Study', 2: 'Edit Study Info', 3: 'Review and Update Study' };
    selectedStudyId: string|undefined = undefined;
    progress = 1;
    private studiesRes = new BehaviorSubject<{ items: Study[]; recordCount: number }>(undefined);
    studiesRes$ = this.studiesRes.asObservable();

    sites: SiteWithTeamSipIntegrationLink[];
    study: StudyWithTeamSipIntegrationLink;
    oldStudy: StudyWithTeamSipIntegrationLink;
    tree: FolderizeTreeItem[] = undefined;
    studyConfig: StudyConfig;
    studyEntities: StudyEntity[];
    oldNickname: string;
    payload: Partial<StudyStartupPayload>;
    linkedEntity: StudyEntity;
    jobId: string;
    jobs: ListJobs;
    rootContainerPath: string;
    loadingStudyData = false;
    loadingDataDone = false;

    $destroy = new Subject<void>();

    constructor(
        private CurrentSession: CurrentSessionService,
        private Studies: StudiesService,
        private $state: StateService,
        private StudyManager: StudyManagerService,
        private Modals: ModalsService
    ) {}

    async ngOnInit() {
        this.team = this.CurrentSession.getCurrentTeam();
        this.loadTeamStudies(this.team);
        this.selectedStudyId = undefined;
        this.getTeamConfig();
        this.teamConfig = this.$state.params.teamConfig;
    }

    ngOnDestroy(): void {
        this.$destroy.next();
        this.$destroy.complete();
    }

    desposeTeamStudies(): void {
        this.studiesRes.next(undefined);
    }

    loadTeamStudies(params): void {
        if (this.progress === 1) {
            this.desposeTeamStudies();
        }

        this.Studies.getStudies(this.team.id, params).subscribe((value) => {
            this.studiesRes.next(value);
        });
    }

    onSelectedStudy(studyId: string): void {
        if (this.loadingStudyData === false) {
            this.loadingDataDone = false;
            this.selectedStudyId = studyId;
            if (this.selectedStudyId !== undefined) {
                this.loadingStudyData = true;
                this.getStudyObject();
            }
        }
    }

    getTeamConfig() {
        this.StudyManager.getTeamConfig(this.team.id)
            .subscribe(
                (teamConfiguration) => {
                    this.teamConfig = teamConfiguration;
                }
            );
    }

    getListJobs(study: StudyWithTeamSipIntegrationLink) {
        /*
        if uniqueProtocolId is not valid, return null and in that case we will not show
        details like study name, study type
        there are couple of cases for this
        first is for studies which are not created by workflow API
        second is for studies which are created by workflow API but
        their unique protocol length is not valid (between 2 and 50)
        */
        if (study.uniqueProtocolId.length > 2 && study.uniqueProtocolId.length < 50) {
            this.StudyManager.listJobs(this.team.id, study.uniqueProtocolId)
                .subscribe(
                    (jobs) => {
                        this.jobs = jobs;
                        this.study.nickname = '';
                        this.study.studyType = 'Select Study Type';
                        if (this.jobs && this.jobs.length > 0) {
                            /*
                                search jobs and find study name and study type
                            */
                            let count = 0;
                            do {
                                if (this.jobs[count].studyId === study.uniqueProtocolId) {
                                    this.study.nickname = this.jobs[count].studyName;
                                    this.study.studyType = this.jobs[count].studyType;
                                }
                                count += 1;
                            }
                            while (count < this.jobs.length && this.study.studyType === 'Select Study Type' && this.study.nickname === '');
                        }
                        this.loadingStudyData = false;
                        this.loadingDataDone = true;
                    }
                );
        }
        else {
            this.study.nickname = '';
            this.study.studyType = 'Select Study Type';
            this.loadingStudyData = false;
            this.loadingDataDone = true;
        }
    }

    getLinkedStudyEntities() {

        this.Studies.getStudyLinkedEntities(this.team.id, this.selectedStudyId)
            .subscribe((data: StudyEntity[]) => {
                this.studyEntities = data;
            });
    }

    // helper function - get whole object for selected study in first step
    getStudyObject() {
        this.Studies.getStudy(this.team.id, this.selectedStudyId)
            .subscribe(
                (study: StudyWithTeamSipIntegrationLink) => {
                    this.study = study;
                    this.oldStudy = study;
                    this.oldNickname = this.study.nickname;
                    this.getListJobs(study);
                }
            );
    }

    getTreeForStructurePreview() {
        if (this.teamConfig !== null) {
            this.teamConfig.teamConfiguration.studies.forEach((study) => {
                if (study.type === this.study.studyType) {
                    this.tree = study.tree;
                    this.studyConfig = study;
                }
            });
        }
    }

    cancelUpdateStudyFlow() {
        this.$state.go('app.team.study-manager', { teamId: this.team.id });
    }

    setProgress(n: number): void {
        if (n == 1) {
            this.backToFirstStepOfUpdateStudy();
        }
        else if (n == 2) {
            this.backToSecondStepOfUpdateStudy();
        }
    }

    // methods for changing steps in flow
    goToSecondStepUpdateStudy() {
        this.getLinkedStudyEntities();
        this.progress = 2;
    }

    backToFirstStepOfUpdateStudy() {
        this.loadingStudyData = false; // set on default value
        this.loadingDataDone = false; // set on default value
        this.progress = 1;
        this.selectedStudyId = undefined; // when user come back to first step need to choose study again
    }

    goToThirdStepUpdateStudy() {
        this.getTreeForStructurePreview();
        this.progress = 3;
    }

    backToSecondStepOfUpdateStudy() {
        this.progress = 2;
    }

    findLinkedEntityForStudy() {
        this.linkedEntity = this.studyEntities.find((studyEntity) => studyEntity.entityName === this.oldNickname);
    }

    goToReviewPage({ study }: {study: Study}): void {
        this.study = study;
        this.findLinkedEntityForStudy();

        this.payload = {
            studyId: this.study.uniqueProtocolId,
            studyName: this.study.nickname,
            studyType: this.study.studyType,
            rootContainerPath: '',
            createGlobalBinders: true,
            studyProfile: {
                conditionOrDiseaseArea: this.study.condition,
                deviceOrDrug: this.study.device,
                croName: this.study.cro,
                sponsor: {
                    sponsorName: this.study.sponsor
                }
            }
        };

        if (this.linkedEntity && this.linkedEntity.entityType === 'folder') {
            if (this.linkedEntity.entityName === this.linkedEntity.entityPath) {
                this.payload.rootContainerPath = this.linkedEntity.entityBinderName;
            }
            else {
                this.payload.rootContainerPath = `${this.linkedEntity.entityBinderName}/${this.linkedEntity.entityPath}`.split('/').slice(0, -1).join('/');
            }
        }
        this.goToThirdStepUpdateStudy();
    }

    removeEmptyFields(obj): Partial<StudyStartupPayload> {
        return Object.entries(obj)
            .filter(([_, value]) => (value !== null && value !== ''))
            .reduce(
                (acc, [key, value]) => ({ ...acc, [key]: value === Object(value) ? this.removeEmptyFields(value) : value }),
                {}
            );
    }

    updateStudy() {
        const reducedPayload = this.removeEmptyFields(this.payload);
        this.Modals.show(UpdateStudyModal, {
            initialState: {
                teamId: this.team.id,
                payload: reducedPayload
            }
        });
    }

}
