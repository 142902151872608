import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    CreateAnnotationParams,
    CreateAnnotationResponse
} from './document-annotation.service.types';

@Injectable({ providedIn: 'root' })
export class DocumentAnnotationsService {

    readonly url = {
        createAnnotations: (teamId: string, documentId: string, documentVersion: number): string => `/api/teams/${teamId}/documents/${documentId}/versions/${documentVersion}/annotations`
    };

    constructor(
        private http: HttpClient
    ) { }

    createAnnotations(params: CreateAnnotationParams): Observable<CreateAnnotationResponse | []> {

        const body = {
            xfdfString: params.xfdfString,
            reason: params.reason,
            signingReason: params.signingReason,
            email: params.email,
            password: params.password,
            signingPasscode: params.signingPasscode,
            pages: params.pages,
            pageManipulationReason: params.pageManipulationReason
        };

        return this.http.post<CreateAnnotationResponse>(
            this.url.createAnnotations(params.teamId, params.documentId, params.documentVersion),
            body
        );

    }
}
