import * as angular from 'angular';

import asyncFilter from './async-filter';

const dependencies = [
];

const stringFilters = angular
    .module('app.widgets.asyncFilter', dependencies)
    .filter('async', asyncFilter);

export default stringFilters;
