import { Component, Input } from '@angular/core';

@Component({
    selector: 'name-email',
    template: `
    <span class="name-email">
        <span *ngIf="email && name && email !== name && !shorten">
            {{name}} - <a class="indentedLink test-mailToLink" href="mailto:{{email}}">{{email}}</a>
        </span>
        <span *ngIf="email && name && email !== name && shorten">
            <a href="mailto:{{email}}" class="test-mailToLink">{{name}}</a>
        </span>
        <span *ngIf="email && (!name || email === name)">
            <a href="mailto:{{email}}" class="test-mailToLink">{{email}}</a>
        </span>
        <span *ngIf="!email && name">
            {{name}}
        </span>
    </span>
    `
})
export class NameEmailComponent {
    @Input() shorten: boolean;
    @Input() email: string;
    @Input() name: string;
}
