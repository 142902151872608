import {
    Component, Input, EventEmitter, Output, OnInit, OnDestroy, ViewChild, ElementRef
} from '@angular/core';
import {
    FormBuilder, Validators, FormArray, AbstractControl, FormControl, FormGroup
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import {
    LogEntry, Column, Document, LogEntryTypes, User, SelectOptions, DoaColumnTypes,
    SelectOption
} from '@app/shared/models';
import { moveAwayFromUTC, moveTowardsUTC } from '@app/shared/date-time';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { ReasonModalComponent } from '@app/widgets/reason/reason-modal.component';
import { ReasonEvent } from '@app/widgets/reason/reason-modal.component.types';
import { calculateEntityPath } from '@app/shared/documents/calculate-entity-path.util';
import { CurrentSessionService } from '@app/core/current-session.service';

import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';
import { DocumentLogEntriesService } from '@app/shared/documents-log-entries/document-log-entries.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { LogEntryRawFormData, LogEntrySaveData } from './log-entry-form.component.types';
import styles from './log-entry-form.component.scss';
import template from './log-entry-form.component.html';
import { FeatureFlagService } from '../../../../core/feature-flag.service';
import { FEATURE_FLAGS } from '../../../../core/constants/feature-flags';
import { LogTemplateType } from '../../../log-templates/components/log-template-type-selector/log-template-type-selector.component.types';

@Component({
    selector: 'log-entry-form',
    template,
    styles: [String(styles)]
})
export class LogEntryFormComponent implements OnInit, OnDestroy {
    @ViewChild('columnInput', { static: false }) columnInput: ElementRef;
    @Input() columns: Column[];
    @Input() columnWidth: string;
    @Input() logEntry?: LogEntry;
    @Input() doc?: Document;
    @Input() isProcessing: boolean;
    @Output() cancel = new EventEmitter<void>();
    @Output() save = new EventEmitter<LogEntrySaveData>();
    @Output() saveAndContinue = new EventEmitter<LogEntrySaveData>();
    private maxTextLength = 1000;
    columnOrderHash: { [key: number]: Partial<Column> & { selectOptions: SelectOptions } } = {};
    controlsSetup: boolean;
    isNew: boolean;
    isEdited: boolean;
    nextVersion: number;
    gridStyles: { '-ms-grid-columns'?: string; 'grid-template-columns'?: string } = {};
    logEntryForm = this.fb.group({
        comment: ['', Validators.maxLength(this.maxTextLength)],
        columns: this.fb.array([])
    });

    users: User[] = [];
    loadingUsers = false;
    initiallySelectedUser: User[] = [];
    selectedUser: User[] = [];
    initialUsersLoad = true;
    filterUserControl = new FormControl(null, Validators.required);
    hasTeamMemberColumn = false;
    doaResponsibilitiesColumnIndex?: number;
    doaStudyRolesColumnIndex?: number;
    templateType = LogTemplateType.ELOG;
    private enableExperimentalEnhancements = false;
    showDatePickers: boolean[] = [];
    dropdownPositionConfig = {};
    initialValues = [];

    private destroy = new Subject();
    private destroyTimeout: NodeJS.Timeout;

    constructor(
        private fb: FormBuilder,
        private modalsService: ModalsService,
        private CurrentSession: CurrentSessionService,
        private DocumentLogEntries: DocumentLogEntriesService,
        private Notifications: NotificationsService,
        private FeatureFlags: FeatureFlagService
    ) { }

    get teamMemberColumn(): Column {
        return this.logEntry?.columns.find((column) => column.type === LogEntryTypes.teamMember);
    }

    loadUsers(userFilter: string): void {
        this.loadingUsers = true;

        const documentId = this.doc.id as string;

        this.DocumentLogEntries.getPotentialTeamMembers(documentId, this.isNew, userFilter).subscribe(
            (data) => {
                this.users = data;
                this.loadingUsers = false;
                if (!userFilter) {
                    this.selectedUser = [];
                }

                if (this.logEntry && this.initialUsersLoad) {
                    if (this.teamMemberColumn?.value) {
                        const teamMember = this.users.find((user) => user.id === this.teamMemberColumn.value);
                        if (teamMember) {
                            this.initiallySelectedUser = [teamMember];
                            this.selectedUser = [teamMember];
                        }
                        else {
                            this.filterUserControl.setValue(this.teamMemberColumn.value);
                            const user = <User>{ id: this.teamMemberColumn.value };
                            this.selectedUser = [user];
                        }
                    }
                }
                this.initialUsersLoad = false;
            },
            (error: HttpErrorResponse) => {
                const message = (error && error.error && error.error.message);
                message ? this.Notifications.error(message) : this.Notifications.unexpectedError();
            }
        );
    }

    onUsersSelect($event: FilteredSelectEvent<User>): void {
        this.selectedUser = $event.added;
        this.logEntryForm.markAsDirty();
        if (this.selectedUser.length) {
            const teamMemberCtlr = (this.logEntryForm.controls.columns as FormArray).controls.find(
                (column) => Object.keys(column.value)[0] === this.teamMemberColumn.name
            );
            teamMemberCtlr.patchValue({ [this.teamMemberColumn.name]: this.selectedUser[0].id });
            this.isEdited = true;
        }
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();

        clearTimeout(this.destroyTimeout);
    }

    ngOnInit(): void {
        this.gridStyles = {
            '-ms-grid-columns': `95px 62px (${this.columnWidth})[${this.columns.length}]  85px`,
            'grid-template-columns': `95px 62px repeat(${this.columns.length}, ${this.columnWidth}) 85px`
        };
        this.isNew = !this.logEntry;
        this.nextVersion = this.logEntry ? this.logEntry.id.version + 1 : 1;
        this.initForm(this.logEntry && this.logEntry.columns as Column[]);
        this.hasTeamMemberColumn = this.columns.some((column) => column.type === LogEntryTypes.teamMember);
        if (this.hasTeamMemberColumn) {
            this.loadUsers('');
        }

        this.FeatureFlags.getFlag(FEATURE_FLAGS.LOG_TEMPLATE_ENHANCEMENTS, true).pipe(
            filter((flag) => flag !== undefined)
        ).subscribe((value) => {
            this.enableExperimentalEnhancements = value;
        });

        this.setTemplateType();

        if (this.templateType === LogTemplateType.DOA) {
            this.setDoaResponsibilitiesColumnIndex();
            this.setDoaRolesColumnIndex();
        }
    }

    private setTemplateType(): void {
        this.templateType = this.doc?.documentProperties?.templateType || LogTemplateType.ELOG;
    }

    private setDoaResponsibilitiesColumnIndex(): void {
        this.doaResponsibilitiesColumnIndex = this.columns
            .findIndex((column) => column.doaType === DoaColumnTypes.RESPONSIBILITIES);
    }

    private setDoaRolesColumnIndex(): void {
        this.doaStudyRolesColumnIndex = this.columns
            .findIndex((column) => column.doaType === DoaColumnTypes.ROLE);
    }

    private clearAllSelectionsFromMultiselectColumn(columnIndex: number): void {
        const column = this.columnOrderHash[columnIndex];

        column.selectOptions.options.forEach((option) => {
            option.isSelected = false;
        });
    }

    private getFormattedSelectOptions = (({ name, type, value }: Column) => {
        let formatedSelectOptions = null;
        if (type === LogEntryTypes.multiSelect || type === LogEntryTypes.singleSelect) {
            const docPropertiesColumns = this.doc?.documentProperties?.columns || [];
            const colSpec = docPropertiesColumns.find(({ name: n, type: t }) => n === name && t === type);
            const selectOptionsSpec = colSpec && colSpec.selectOptions;
            formatedSelectOptions = {
                ...selectOptionsSpec,
                options: (selectOptionsSpec.options || []).map((option) => {
                    const normalizedValue = Array.isArray(value) ? value : [value];
                    return {
                        ...option,
                        isSelected: normalizedValue.includes(option.id)
                    };
                })
            };
        }
        return formatedSelectOptions;
    })

    initForm(columns: Column[] = this.columns): void {
        const columnCtrl = this.logEntryForm.controls.columns as FormArray;
        columns.forEach(({
            name, type, value = ''
        }, i) => {

            this.columnOrderHash[i] = {
                name,
                type,
                selectOptions: this.getFormattedSelectOptions({ name, type, value })
            };
            const parsedValue = type === LogEntryTypes.date ? moveTowardsUTC(value as string) : value;

            this.initialValues.push({
                [name]: parsedValue
            });

            columnCtrl.push(this.fb.group({
                [name]: [parsedValue, []]
            }));
        });
        // TODO: find a better fix than setTimeout.
        // This is a fix for IE. In IE, because logEntryForm's comment input has a placeholder,
        // the form is marked as dirty (not pristine). Calling this.logEntryForm.markAsPristine()
        // in any hook does not fix the problem. Calling it in setTimeout does.
        this.destroyTimeout = setTimeout(() => this.logEntryForm.markAsPristine(), 100);

        this.onChanges();
    }

    getLetterForIndex(index: number): string {
        const letterCode = 65 + (index % 26);
        let letter = String.fromCharCode(letterCode);
        const repeatCount = Math.floor(index / 26);
        if (repeatCount > 0) {
            letter = this.getLetterForIndex(repeatCount - 1) + letter;
        }
        return letter;
    }

    toggleOptionSelection(columnIndex: number, optionIndex: number): void {
        const newSelection = !this.columnOrderHash[columnIndex].selectOptions.options[optionIndex].isSelected;
        const column = this.columnOrderHash[columnIndex];
        const selectedOption: SelectOption = column.selectOptions.options[optionIndex];
        selectedOption.isSelected = newSelection;

        const columnCtrl = this.logEntryForm.controls.columns as FormArray;
        const formGroup = columnCtrl.controls[columnIndex] as FormGroup;
        formGroup.controls[column.name]
            .setValue(column.selectOptions.options.filter((opt) => opt.isSelected).map((opt) => opt.id));

        this.logEntryForm.markAsDirty();
    }

    getDropdownPlaceholderText(columnIndex: number) {
        const selectedOptions = [];
        let placeholderText = null;

        const { options } = this.columnOrderHash[columnIndex].selectOptions;

        for (let i = 0; i < options.length; i += 1) {
            if (options[i].isSelected) {
                selectedOptions.push(i + 1);
            }
        }

        const isLetterIdentifier = this.columnOrderHash[columnIndex].selectOptions.isNumberIdentifier === false;

        if (selectedOptions.length > 0) {
            const identifiers = isLetterIdentifier
                ? selectedOptions.map((index) => this.getLetterForIndex(index - 1))
                : selectedOptions;
            placeholderText = identifiers.join(', ');
        }
        else {
            placeholderText = 'Multi-select options';
        }

        return placeholderText;
    }

    getSingleSelectDropdownValue(columnIndex: number): string {
        return this.columnOrderHash[columnIndex]?.selectOptions?.options?.find((option) => option.isSelected)?.name
            || 'Single-select option';
    }

    selectSingleSelectOption(columnIndex: number, optionIndex: number): void {
        const column = this.columnOrderHash[columnIndex];
        const selectedOption: SelectOption = column.selectOptions.options[optionIndex];

        column.selectOptions.options.forEach((option) => {
            option.isSelected = false;
        });
        selectedOption.isSelected = true;

        const columnCtrl = this.logEntryForm.controls.columns as FormArray;
        const formGroup = columnCtrl.controls[columnIndex] as FormGroup;
        formGroup.controls[column.name]
            .setValue(selectedOption.id ?? selectedOption.name);

        const isStudyRoleColumn = columnIndex === this.doaStudyRolesColumnIndex;
        if (isStudyRoleColumn) {
            this.clearAllSelectionsFromMultiselectColumn(this.doaResponsibilitiesColumnIndex);
            this.setResponsibilitiesOptions(selectedOption);
        }

        this.logEntryForm.markAsDirty();
    }

    private setResponsibilitiesOptions(selectedOption: SelectOption): void {
        const { roles, responsibilities } = this.doc;
        const responsibilitiesColumn = this.columnOrderHash[this.doaResponsibilitiesColumnIndex];
        const studyRole = roles.find((role) => role.studyRole === selectedOption.studyRoleId);

        studyRole?.responsibilityIds.forEach((responsibilityId) => {
            const responsibility = responsibilities.values
                .find((responsibilityValue) => responsibilityValue._id === responsibilityId);

            const responsibilitySelectOptionIndex = responsibilitiesColumn.selectOptions.options
                .findIndex((option) => option.name.toLowerCase() === responsibility.name.toLowerCase());

            this.toggleOptionSelection(this.doaResponsibilitiesColumnIndex, responsibilitySelectOptionIndex);
        });
    }

    columnInvalid(ctrl: AbstractControl): boolean {
        return ctrl.invalid && (ctrl.dirty || ctrl.touched);
    }

    getLength(ctrl: AbstractControl, name: string) {
        if (ctrl && ctrl.value && !name) {
            return ctrl.value.length;
        }
        if (ctrl && ctrl.value && name && ctrl.value[name]) {
            return (ctrl.value && ctrl.value[name]).length;
        }
        return 0;
    }

    excedeedLength(ctrl: AbstractControl, name: string): boolean {
        let tooLong = false;
        if (ctrl && ctrl.value && !name) {
            tooLong = ctrl.value.length > this.maxTextLength;
        }
        if (ctrl && ctrl.value !== null && ctrl.value[name] !== null) {
            tooLong = this.getLength(ctrl, name) > this.maxTextLength;
        }
        tooLong ? this.logEntryForm.setErrors({ tooLong }) : null;
        return tooLong;
    }

    onTogglePicker(index: number): void {
        this.showDatePickers[index] = !this.showDatePickers[index];
    }

    getCtrlValue(ctrl: AbstractControl, name): Date | string | undefined {
        // only for using with date-time-picker while running ng1/ng2 hybrid
        return ctrl.value && ctrl.value[name];
    }

    setCtrlValue(ctrl: AbstractControl, name: string, value: Date): void {
        // only for using with date-time-picker while running ng1/ng2 hybrid
        ctrl.patchValue({ [name]: value });
        ctrl.markAsDirty();
    }

    submitForm(isContinuing = false): void {
        if (this.logEntryForm.invalid) {
            return;
        }

        const emitter = isContinuing ? this.saveAndContinue : this.save;

        if (this.logEntry) {
            return this.requireAReasonAndSave(emitter);
        }

        this.closeDatePickerPopup();

        emitter.emit(this.formatLogEntry(this.logEntryForm.value));
    }

    private getFormattedSingleSelectedValue = (selectOptions: SelectOptions): string => {
        const selected = selectOptions.options.find((option) => option.isSelected);
        if (selected) {
            return selected.id;
        }
        return null;
    }

    private getFormattedMultiSelectedValue = (selectOptions: SelectOptions): string[] => {
        const selected = selectOptions.options.filter((option) => option.isSelected);
        if (selected.length) {
            return selected.map((s) => s.id);
        }
        return [];
    }

    formatLogEntry({ comment = '', columns }: LogEntryRawFormData): Omit<LogEntrySaveData, 'reason'> {
        return {
            comment,
            columns: Object.values(this.columnOrderHash).map(({ name, type, selectOptions }, i) => ({
                name,
                type,
                ...(columns[i][name] && type !== LogEntryTypes.signature && type !== LogEntryTypes.teamMember)
                && { value: columns[i][name] },
                ...(columns[i][name] && type === LogEntryTypes.date) && { value: moveAwayFromUTC(columns[i][name] as string) },
                ...(type === LogEntryTypes.teamMember && this.selectedUser.length) && { value: this.selectedUser[0].id },
                ...(type === LogEntryTypes.multiSelect)
                && this.getFormattedMultiSelectedValue(selectOptions) && {
                    value: this.getFormattedMultiSelectedValue(selectOptions)
                },
                ...(type === LogEntryTypes.singleSelect)
                && this.getFormattedSingleSelectedValue(selectOptions) && {
                    value: this.getFormattedSingleSelectedValue(selectOptions)
                }
            }))
        };
    }

    submitFormAndReset(): void {
        this.submitForm(true);
        this.logEntryForm.reset();
        this.filterUserControl.reset();
        this.columns.forEach((el, i) => {
            this.columnOrderHash[i].selectOptions?.options.forEach((option) => {
                option.isSelected = false;
            });
        });

        if (this.hasTeamMemberColumn) {
            this.initiallySelectedUser = [];
            this.selectedUser = [];
            this.filterUserControl.setValue(null);
            this.loadUsers('');
        }

        if (this.enableExperimentalEnhancements) {
            this.columnInput?.nativeElement?.focus();
        }
    }

    handleCancel(): void {
        this.closeDatePickerPopup();
        this.cancel.emit();
    }

    closeDatePickerPopup(): void {
        if (window.document.getElementsByClassName('datetime-picker-dropdown').length) {
            const element = window.document.getElementsByClassName('datetime-picker-dropdown')[0];
            element.remove();
        }
    }

    onChanges(): void {
        const columnsInitValue = this.logEntryForm.controls.columns.value;
        this.logEntryForm.valueChanges.pipe(takeUntil(this.destroy)).subscribe((val) => {

            let isEdited = false;
            if (val.comment) {
                isEdited = val.comment.trim() !== '';
            }

            if (!isEdited) {
                Object.keys(columnsInitValue).forEach((columnKey) => {
                    Object.keys(columnsInitValue[columnKey]).forEach((key) => {
                        if (val.columns[columnKey][key] instanceof Date) {
                            const oldDate = columnsInitValue[columnKey][key];
                            const newDate = val.columns[columnKey][key];

                            if (!oldDate && newDate) {
                                isEdited = true;
                            }
                            else {
                                isEdited = isEdited || oldDate.getTime() !== newDate.getTime();
                            }
                        }
                        else if (typeof val.columns[columnKey][key] === 'string' && key !== 'Team Member') {
                            isEdited = isEdited || columnsInitValue[columnKey][key] !== val.columns[columnKey][key].trim();

                            if (val.columns[columnKey][key].trim() !== val.columns[columnKey][key]) {
                                val.columns[columnKey][key] = val.columns[columnKey][key].trim();
                            }
                        }
                        else if (key === 'Team Member') {
                            if (this.selectedUser.length) {
                                isEdited = true;
                            }
                        }
                    });
                });
            }
            this.isEdited = isEdited;
        });
    }

    private requireAReasonAndSave(emitter: EventEmitter<LogEntrySaveData>): void {
        const modalRef = this.modalsService.show(ReasonModalComponent, {
            initialState: {
                header: 'Edit Log Row',
                path: this.doc && calculateEntityPath(this.doc, this.CurrentSession.getCurrentTeam())
            }
        });

        modalRef.content.dismiss.subscribe(() => {
            modalRef.hide();
        });

        modalRef.content.save
            .pipe(takeUntil(modalRef.content.dismiss))
            .subscribe(({ data: { reason }, onSuccess }: ReasonEvent) => {
                emitter.emit({
                    ...this.formatLogEntry(this.logEntryForm.value),
                    reason
                });

                onSuccess();
                this.logEntryForm.markAsPristine();
            });
    }

    onMultiSelectChange(): void {
        if (!this.isNew) {
            this.isEdited = true;
            this.logEntryForm.markAsDirty();
        }
    }

    isEllipsisActive(element: HTMLElement): boolean {
        return element ? (element.offsetWidth < element.scrollWidth) : false;
    }

    isTeamMemberColumnInvalid(): boolean {
        return this.hasTeamMemberColumn && this.filterUserControl.invalid;
    }

    isEntryEdited(): boolean {

        const columnsInitValue = this.initialValues;
        const columnsCurrentValue = this.logEntryForm.value.columns;
        return JSON.stringify(columnsInitValue) !== JSON.stringify(columnsCurrentValue);
    }

    isSaveButtonDisabled(): boolean {
        return !this.logEntryForm.valid || this.isProcessing
        || (!this.isNew && this.logEntryForm.pristine)
        || (!this.isNew && !this.isEntryEdited())
        || this.isTeamMemberColumnInvalid();
    }

    isSaveAndContinueButtonDisabled(): boolean {
        return !this.logEntryForm.valid || this.isProcessing
            || this.isTeamMemberColumnInvalid();
    }
}
