import {
    ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit,
    Output
} from '@angular/core';
import {
    BehaviorSubject, Observable, Subject
} from 'rxjs';
import {
    ControlContainer, FormArray, FormGroup
} from '@angular/forms';
import {
    switchMap, take, takeUntil, tap
} from 'rxjs/operators';
import template from './study-site-team-edit.component.html';
import styles from './study-site-team-edit.component.scss';
import {
    Team, User,
    SelectedTeamMember,
    TeamMemberForm
} from '../../../../shared/models';
import { CurrentSessionService } from '../../../../core/current-session.service';
import { TeamService } from '../../../../shared/teams/team.service';
import { FilteredSelectEvent } from '../../../../widgets/filtered-select/filtered-select.component';
import { LoadUsersResponse } from '../../../../shared/teams/teams.service.types';
import { StudyRoleIdName } from '../../../../shared/study-roles/study-roles.types';

@Component({
    selector: 'study-site-team-edit',
    template,
    styles: [String(styles)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudySiteTeamEditComponent implements OnInit, OnDestroy {
    constructor(
        private currentSessionService: CurrentSessionService,
        private teamService: TeamService,
        private controlContainer: ControlContainer
    ) {}

    @Input() studyRoles: StudyRoleIdName[] = [];
    @Output() teamMembersAdded = new EventEmitter<FilteredSelectEvent<User>>();
    @Output() revertTeamMembers = new EventEmitter();
    @Output() teamMemberRemoved = new EventEmitter<User['id']>();
    @Output() teamMemberRoleChanged = new EventEmitter<{
        teamMemberIndex: number;
        studyRoleId: StudyRoleIdName['_id'];
    }>();

    get studySiteFormGroup(): FormGroup {
        return this.controlContainer.control as FormGroup;
    }

    get siteTeamMembersFormArray(): FormArray {
        return this.studySiteFormGroup.get('teamMembers') as FormArray;
    }

    private team: Team;

    private teamUsersList = new BehaviorSubject<User[]>([]);
    teamUsersList$ = this.teamUsersList.asObservable();

    private selectedTeamMembers = new BehaviorSubject<SelectedTeamMember[]>([]);
    selectedTeamMembers$ = this.selectedTeamMembers.asObservable();

    private isLoadingTeamUsers = new BehaviorSubject(false);
    isLoadingTeamUsers$ = this.isLoadingTeamUsers.asObservable();

    private isInitialUsersLoad = new BehaviorSubject(false);
    isInitialUsersLoad$ = this.isInitialUsersLoad.asObservable();

    private hasNextPage = new BehaviorSubject(false);
    hasNextPage$ = this.hasNextPage.asObservable();

    currentFilter = '';
    currentPageNum = 0;
    pageSize = 20;
    sortBy = 'profile.firstName';
    sortDir = 'ASC';

    private $destroy = new Subject<void>();

    ngOnInit(): void {
        this.isInitialUsersLoad.next(true);
        this.team = this.currentSessionService.getCurrentTeam();

        this.setInitiallySelectedTeamMembers();

        this.getTeamUsers$().pipe(
            tap(({ items: users, recordCount }) => {
                this.setTeamUsersList(users);

                this.setHasNextPage(recordCount);

                this.isInitialUsersLoad.next(false);
            }),
            switchMap(() => this.syncSelectedTeamMembersWithFormChanges$()),
            takeUntil(this.$destroy)
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.$destroy.next();
        this.$destroy.complete();
    }

    onTeamMemberFiltered(filter: string): void {
        this.currentFilter = filter;
        this.resetCurrentPageNum();

        this.getTeamUsers$().pipe(
            tap(({ items: users, recordCount }) => {
                this.setTeamUsersList(users);

                this.setHasNextPage(recordCount);
            }),
            take(1)
        ).subscribe();
    }

    onTeamMemberSelect($event: FilteredSelectEvent<User>) {
        this.teamMembersAdded.emit($event);
    }

    onCancelEditingTeamMembers() {
        this.revertTeamMembers.emit();
    }

    onRemoveTeamMember(userId: string): void {
        this.teamMemberRemoved.emit(userId);
    }

    onScrollEnd(): void {
        this.getTeamUsers$().pipe(
            tap(({ items: users, recordCount }) => {
                this.addToTeamUsersList(users);

                this.setHasNextPage(recordCount);
            }),
            take(1)
        ).subscribe();
    }

    onSelectStudyRole(teamMemberIndex: number, studyRoleId: string): void {
        this.teamMemberRoleChanged.emit({
            teamMemberIndex,
            studyRoleId
        });
    }

    private setInitiallySelectedTeamMembers(): void {
        const initiallySelectedTeamMembers = this.siteTeamMembersFormArray.getRawValue()
            .map((teamMember: TeamMemberForm) => this.formatSelectedTeamMember(teamMember));

        this.selectedTeamMembers.next(initiallySelectedTeamMembers);
    }

    private syncSelectedTeamMembersWithFormChanges$() {
        return this.siteTeamMembersFormArray.valueChanges.pipe(
            tap(() => {
                const updatedTeamMembersWithDetails = this.siteTeamMembersFormArray.getRawValue()
                    .map((teamMember: TeamMemberForm) => this.formatSelectedTeamMember(teamMember));

                this.selectedTeamMembers.next(updatedTeamMembersWithDetails);
            })
        );
    }

    private formatSelectedTeamMember(teamMember: TeamMemberForm): SelectedTeamMember {
        return {
            ...teamMember,
            id: teamMember.userId
        };
    }

    private getTeamUsers$(): Observable<LoadUsersResponse> {
        this.isLoadingTeamUsers.next(true);

        return this.teamService.loadUsers({
            teamId: this.team.id,
            pageNum: this.currentPageNum + 1,
            pageSize: this.pageSize,
            sortBy: this.sortBy,
            sortDir: this.sortDir,
            ...(this.currentFilter && { filter: this.currentFilter })
        }).pipe(
            tap(() => {
                this.incrementCurrentPageNum();

                this.isLoadingTeamUsers.next(false);
            }),
            take(1)
        );
    }

    private setTeamUsersList(users: User[]): void {
        this.teamUsersList.next(users);
    }

    private addToTeamUsersList(users: User[]): void {
        this.teamUsersList.next(this.teamUsersList.getValue().concat(users));
    }

    private incrementCurrentPageNum(): void {
        const newPageNum = this.currentPageNum + 1;
        this.currentPageNum = newPageNum;
    }

    private resetCurrentPageNum(): void {
        this.currentPageNum = 0;
    }

    private setHasNextPage(recordCount: number): void {
        const hasNextPage = recordCount > this.teamUsersList.getValue().length;

        this.hasNextPage.next(hasNextPage);
    }
}
