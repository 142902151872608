import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
    GetStructureTemplateByIdResponse,
    DeleteStructureTemplateResponse,
    GetStructureTemplatesResponse,
    StudyTemplateCreateParams,
    StudyTemplateCreateResponse,
    StudyTemplateFilePreviewResponse,
    AssignStructureTemplateParams,
    AssignStructureTemplateResponse
} from './study-startup.service.types';

@Injectable()
export class StudyStartupService {
    private readonly url = {
        filePreview: (teamId: string): string => `/api/teams/${teamId}/study-template/file-preview`,
        studyTemplate: (teamId: string): string => `/api/teams/${teamId}/study-template`,
        structureTemplatePreview:
            (structureTemplateId: string, teamId: string): string => `/api/teams/${teamId}/study-template/${structureTemplateId}`,
        studyTemplateId: (teamId: string, studyTemplateId: string): string => `/api/teams/${teamId}/study-template/${studyTemplateId}`,
        assignStructure: (teamId: string, studyTemplateId: string): string => `/api/teams/${teamId}/study-template/${studyTemplateId}/assign-structure`
    };

    constructor(
        private $http: HttpClient
    ) { }

    getFolderStructureFilePreview(teamId: string, file: File): Observable<StudyTemplateFilePreviewResponse> {
        const url = this.url.filePreview(teamId);

        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('filename', file.name);
        formData.append('size', `${file.size}`);

        const headers = new HttpHeaders();
        headers.set('Content-Type', undefined);

        let params = new HttpParams();
        params = params.set('ignoreDueDate', 'true');
        return this.$http.post<StudyTemplateFilePreviewResponse>(url, formData, { headers, params });
    }

    getFolderStructureById(id: string, teamId: string): Observable<GetStructureTemplateByIdResponse> {
        const url = this.url.structureTemplatePreview(id, teamId);

        return this.$http.get<GetStructureTemplateByIdResponse>(url);
    }

    createFolderStructure(teamId: string, params: StudyTemplateCreateParams): Observable<StudyTemplateCreateResponse> {
        const url = this.url.studyTemplate(teamId);

        return this.$http.post<StudyTemplateCreateResponse>(url, params);
    }

    getStructureTemplates(teamId: string): Observable<GetStructureTemplatesResponse[]> {
        const url = this.url.studyTemplate(teamId);

        return this.$http.get<GetStructureTemplatesResponse[]>(url);
    }

    deleteStructureTemplate(teamId: string, studyTemplateId: string, reason: string):
        Observable<DeleteStructureTemplateResponse> {
        const url = this.url.studyTemplateId(teamId, studyTemplateId);

        return this.$http.delete<DeleteStructureTemplateResponse>(url, { params: { reason } });
    }

    assignStructure(teamId: string, studyTemplateId: string, params: AssignStructureTemplateParams):
        Observable<AssignStructureTemplateResponse> {
        const url = this.url.assignStructure(teamId, studyTemplateId);

        return this.$http.post<AssignStructureTemplateResponse>(url, params);
    }
}
