import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { orderBy } from 'lodash';
import { SORT } from '@app/core/constants';
import { Document, MonitorReview } from '@app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './monitor-review-list.component.html';

@Component({
    template
})
export class MonitorReviewListComponent implements OnInit {
    @Input() document: Document;
    @Input() monitorReviews: MonitorReview[];
    @Output() monitorReviewClick = new EventEmitter<MonitorReview>();
    SORT = SORT;

    constructor(public Modal: BsModalRef) {}

    ngOnInit(): void {
        this.updateSort('updatedAt', true);
    }

    cancel(): void {
        this.Modal.hide();
    }

    updateSort(sortName :string, isReversed: boolean): void {
        this.SORT.set(sortName, isReversed);
        this.monitorReviews = orderBy(this.monitorReviews, sortName, this.SORT.isReversed ? 'desc' : 'asc');
    }

    openMonitorReview(monitorReview: MonitorReview) {
        this.monitorReviewClick.emit(monitorReview);
        this.Modal.hide();
    }
}
