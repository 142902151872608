import {
    Component, Input, OnInit
} from '@angular/core';
import {
    SignatureRequest, SignatureRequestComment
} from '@app/shared/models';
import { DisplayedComment } from './signature-comments-viewer.component.types';
import template from './signature-comments-viewer.component.html';

@Component({
    selector: 'signature-comments-viewer',
    template
})
export class SignatureCommentsViewerComponent implements OnInit {
    @Input() pendingSignatures: SignatureRequest[];
    @Input() currentUserEmail: string;

    currentComment: DisplayedComment;

    comments: SignatureRequestComment[] = [];
    disablePrevious: boolean;
    disableNext: boolean;

    ngOnInit(): void {
        this.comments = this.getCommentsFromPendingSignature();

        this.currentComment = {
            page: 1,
            expanded: false,
            comment: this.comments[0]
        };

        this.resolveButtonsState();
    }

    previousComment(): void {
        this.changePage(-1);
    }

    nextComment(): void {
        this.changePage(1);
    }

    toggleCommentExpanded(): void {
        this.currentComment.expanded = !this.currentComment.expanded;
    }

    private getCommentsFromPendingSignature(): SignatureRequestComment[] {
        const firstPendingSignature = this.pendingSignatures.find((pendingSignature) => {
            if (!pendingSignature) {
                return false;
            }

            return pendingSignature.user.email === this.currentUserEmail;
        });

        return firstPendingSignature ? firstPendingSignature.comments : [];
    }

    private resolveButtonsState(): void {
        this.disablePrevious = this.currentComment.page === this.comments.length - (this.comments.length - 1);
        this.disableNext = this.currentComment.page === this.comments.length;
    }

    private changePage(direction: -1 | 1): void {
        const page = this.currentComment.page + direction;

        this.currentComment = {
            page,
            comment: this.comments[page - 1],
            expanded: false
        };

        this.resolveButtonsState();
    }
}
