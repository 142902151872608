import { Pipe } from '@angular/core';
import { SignatureTypes } from '../../../shared/models';

@Pipe({
    name: 'signatureType'
})
export class SignatureTypePipe {
    transform(value: SignatureTypes): string {
        if (value === SignatureTypes.log) {
            return 'Entire Log';
        }
        return value;
    }
}
