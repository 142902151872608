const documentTypes = {
    types: [
        'Document',
        'Form',
        'Log',
        'Placeholder',
        'Shortcut'
    ].sort()
};

export default documentTypes;
