import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    Component, ElementRef, Input, Output, OnInit, EventEmitter
} from '@angular/core';
import { Document } from '@app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import styles from './assign-category.component.scss';
import template from './assign-category.component.html';
import { AssignCategorySubmitEvent, DocumentCategory, DocumentCategoryStatus } from './assign-category.component.types';

@Component({
    template,
    styles: [String(styles)]
})
export class AssignCategoryComponent implements OnInit {
    @Input() canUpdateStatus: boolean;
    @Input() document: Document
    @Input() canUpdateCategory: boolean;
    @Input() categories: DocumentCategory[];
    @Output() onStatusUpdate = new EventEmitter<AssignCategorySubmitEvent>();

    categoryOrStatusChanged = false;
    assignCategoryForm: FormGroup;

    statuses: DocumentCategoryStatus[];
    isProcessing = false;
    get categoryId(): string|null {
        if (!this.assignCategoryForm) {
            return;
        }
        const control = this.assignCategoryForm.get('categoryId');

        if (!control) {
            return null;
        }

        const { value } = control;

        return value === 'null' ? null : value;
    }

    set categoryId(value: string) {
        if (!this.assignCategoryForm) {
            return;
        }
        const control = this.assignCategoryForm.get('categoryId');
        if (control) {
            control.setValue(value);
        }
    }

    get statusId(): string|null {
        if (!this.assignCategoryForm) {
            return;
        }
        const control = this.assignCategoryForm.get('statusId');

        if (!control) {
            return null;
        }

        const { value } = control;

        return value === 'null' ? null : value;
    }

    set statusId(value: string) {
        if (!this.assignCategoryForm) {
            return;
        }
        const control = this.assignCategoryForm.get('statusId');
        if (control) {
            control.setValue(value);
        }
    }


    constructor(
        private fb: FormBuilder,
        private modal: BsModalRef,
        private elementRef: ElementRef
    ) {}

    ngOnInit(): void {
        this.categories = [{ id: null, name: 'No Document Type' }].concat(this.categories);
        this.initForm();
    }

    initForm(): void {
        this.assignCategoryForm = this.fb.group({
            categoryId: [{ value: null, disabled: !this.canUpdateCategory }, Validators.required],
            statusId: [{ value: '', disabled: !this.canUpdateStatus }, Validators.required]
        });

        this.setDefaultSelections();
    }

    setDefaultSelections() {
        const { documentCategory } = this.document;
        if (!documentCategory || !documentCategory.categoryId) {
            return;
        }

        this.categoryId = documentCategory.categoryId;
        this.statusId = documentCategory.statusId;
        this.focusCurrentStatus();
        this.setAvailableStatuses();
    }

    focusCurrentStatus() {
        if (!this.statusId) {
            return;
        }
        setTimeout(() => {
            const statusInput = this.elementRef.nativeElement.querySelector(`input[value="${this.statusId}"]`);
            statusInput && statusInput.focus();
        });
    }

    preventClosingIfProcessing(event: Event, reason: string, closed: boolean) {
        const notAllowedToClose = reason && reason !== 'cancel' && !closed;

        if (this.isProcessing && notAllowedToClose) {
            event.preventDefault();
        }
    }

    cancel(): void {
        this.modal.hide();
    }

    onCategoryChange(): void {
        this.statusId = null;
        this.setAvailableStatuses();
        this.categoryOrStatusChanged = this.isDocumentCategoryOrStatusChanged();
    }

    onStatusChange(): void {
        this.categoryOrStatusChanged = this.isDocumentCategoryOrStatusChanged();
    }

    private isDocumentCategoryOrStatusChanged(): boolean {
        const { documentCategory } = this.document;
        return documentCategory.statusId !== this.statusId || documentCategory.categoryId !== this.categoryId;
    }

    setAvailableStatuses(): void {
        const category = this.categories.find((c) => c.id === this.categoryId);
        this.statuses = (category && category.statuses) || [];
    }

    saveModal() {
        this.isProcessing = true;
        const documentCategory = {
            categoryId: this.categoryId || null,
            statusId: this.statusId
        };

        this.onStatusUpdate.emit({
            ...documentCategory,
            onSuccess: () => this.modal.hide(),
            onError: () => {
                this.isProcessing = false;
                this.modal.hide();
            }
        });
        this.modal.hide();
    }

    sortStatues(statuses: DocumentCategoryStatus[]): DocumentCategoryStatus[] {
        return statuses.sort((a, b) => a.order - b.order);
    }

}
