import { Injectable } from '@angular/core';

import * as _ from 'lodash';

@Injectable()
export class MultiSelectService {
    /**
     * Factory to return select function
     * @param {string} property - The property to use as the selection indicator
     * @returns {function} The multi select function
     */
    makeMultiSelect(property): any {

        const selectionProperty = property || 'selected';
        let previouslySelectedIndex = 0;
        /**
         * Returns selected items of items based on the given params
         *
         * @param {Object} event - the click event
         * @param {Number} index - The clicked item's index
         * @param {Array} items - The array of selectable items, including the currently clicked one
         */
        return {
            select: function select(event, index, items): any {

                const item = items[index];
                if (!item) {
                    return [];
                }

                if (event && event.shiftKey) {
                    const selectedIndex = previouslySelectedIndex > index ? index - 1 : index + 1;

                    _.range(previouslySelectedIndex, selectedIndex).forEach((i) => {
                        items[i][selectionProperty] = true;
                    });
                }
                else {
                    item[selectionProperty] = !item[selectionProperty];

                    if (item[selectionProperty]) {
                        previouslySelectedIndex = index;
                    }
                }

                return _.filter(items, selectionProperty);
            },
            selectAll: function selectAll(items, flag): any {
                items.forEach((item) => {
                    item[selectionProperty] = flag;
                });
                return flag ? [...items] : [];
            }
        };
    }
}
