import {
    Component, OnInit, OnDestroy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { AdapterService } from '@app/shared/adapter/adapter.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PermissionsService } from '@app/shared/permissions/permissions.service';
import { Role, UserWithRoles } from '@app/shared/models';
import { PermissionsEditComponent } from '../permissions-edit/permissions-edit.component';
import template from './permission-info-modal.component.html';
import style from './permission-info-modal.component.scss';

@Component({
    selector: 'permission-info-modal',
    template,
    styles: [String(style)]
})
export class PermissionInfoModalComponent implements OnInit, OnDestroy {
  @Input() item:any;
  @Input() subject: UserWithRoles | Role;
  @Input() onlyDirect = false;
  @Input() editable = false;
  @Input() disableEdit = false;
  @Input() effectivePermissions: any[];
  @Output() onUpdate = new EventEmitter<void>();
  private routerSubscription: Subscription;

  constructor(
    private modal: BsModalRef,
    private modalsService: ModalsService,
    private permissionsService: PermissionsService,
    private adapterService: AdapterService
  ) {}

  ngOnInit(): void {
      this.setEntityState();
  }

  ngOnDestroy(): void {
      if (this.routerSubscription) {
          this.routerSubscription.unsubscribe();
      }
  }

  sizeOf(obj: any): number {
      return Object.keys(obj).length;
  }

  private setEntityState(): void {
      this.item.state = { conversion: this.adapterService.getConversionState(this.item) };

      if (!this.effectivePermissions.length) {
          return;
      }
      this.effectivePermissions.forEach((ep) => {
          if (ep.list.length) {
              ep.list.forEach((obj) => {
                  obj.objectEntity.state = { conversion: this.adapterService.getConversionState(obj.objectEntity) };
              });
          }
      });
  }

  cancel(): void {
      this.modal.hide();
  }

  /**
     * This method is passed as an event handler to the permissionsEditDialog to be triggered after a save happens.
     * After saving, we want to refresh the view both here and for the permission report.
  */
  afterEdit(): void {
      this.permissionsService.getEffectivePermissions({
          subjectId: this.subject.id,
          subjectType: this.subject.type,
          objectId: this.item.id,
          objectType: this.item.type,
          teamId: this.item.teamId
      }).subscribe((permissions) => {
          this.effectivePermissions = permissions;
      });

      this.onUpdate.emit();
  }

  openPermissionsModal(): void {
      if (this.disableEdit) {
          return;
      }

      const modal = this.modalsService.show(PermissionsEditComponent, {
          class: 'modal-lg',
          initialState: {
              item: this.item,
              activeSubject: this.subject
          }
      });

      modal.content.postSaveAction.subscribe(() => {
          this.afterEdit();
      });
  }

  download(format: string): void {
      const options = {
          format,
          teamId: this.item.teamId,
          subjectId: this.subject.id,
          subjectType: this.subject.type,
          objectId: this.item.id,
          objectType: this.item.type
      };
      this.permissionsService.downloadPermissions(options);
  }
}
