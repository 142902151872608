import {
    Binder, Document, DocumentSubTypes, Folder, Team
} from '../models';

export interface EntityPathItem {
    type: 'team' | 'binder' | 'folder' | 'document';
    subType: DocumentSubTypes;
    name: string;
    ext?: string;
}

export const calculateEntityPath = (entity: Binder | Folder | Document, team?: Team): EntityPathItem[] => {

    const entityPath: EntityPathItem[] = [];
    if (team) {
        entityPath.push({ type: 'team', subType: null, name: team.name });
    }
    let entityName: string;
    let path = null;
    if (entity.type === 'binder') {
        entityName = entity.name;
    }
    else if (entity.type === 'folder') {
        const { binderName } = entity;
        path = entity.path.substr(0, entity.path.lastIndexOf(entity.name));
        if (path.endsWith('/')) {
            path = path.substr(0, path.length - 1);
        }
        entityName = entity.name;
        entityPath.push({ type: 'binder', subType: null, name: binderName });
    }
    else if (entity.type === 'document') {
        const { binderName } = entity;
        path = entity.path;
        entityName = entity.title;
        entityPath.push({ type: 'binder', subType: null, name: binderName });
    }

    if (Array.isArray(path)) {
        path.forEach((pathElement) => {
            entityPath.push({
                type: 'folder',
                name: pathElement.name,
                subType: null
            });
        });
    }
    else if (typeof path === 'string' && path.length) {
        path.split('/').forEach((pathElement) => {
            entityPath.push({
                type: 'folder',
                name: pathElement,
                subType: null
            });
        });
    }
    const entityPathObject: EntityPathItem = {
        name: entityName,
        subType: entity.type === 'document' ? entity.subType : null,
        type: entity.type
    };

    if (entity.type === 'document' && entity.subType === 'content') {
        entityPathObject.ext = entity.ext;
    }

    entityPath.push(entityPathObject);
    return entityPath;
};
