import tableCellTypes from '@app/core/constants/table-cell-types';
import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit
} from '@angular/core';
import { Document } from '@app/shared/models';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import template from './view-related-documents-details.html';
import style from './view-related-documents-details.scss';

@Component({
    template,
    styles: [String(style)]
})
export class ViewRelatedDocumentsDetailsComponent implements OnInit {
    @Input() documentKind: string;
    @Input() baseDocument: Document;
    @Output() dismiss = new EventEmitter<void>();

    relatedDocuments;
    tableConfig = [];

    ngOnInit(): void {
        this.tableConfig = [
            {
                renderType: tableCellTypes.type,
                headerName: 'Type',
                displayProperty: null, // type
                isSortable: true,
                sortProperty: 'ext', // defaults to displayProperty
                widthValue: 2
            },
            {
                renderType: tableCellTypes.name,
                headerName: 'Name',
                displayProperty: 'name',
                isSortable: true,
                widthValue: 9,
                truncate: true
            },
            {
                renderType: tableCellTypes.text,
                headerName: 'Relationship',
                displayProperty: 'friendlyRelationship',
                isSortable: true,
                widthValue: 6
            },
            {
                renderType: tableCellTypes.text,
                headerName: 'Location',
                displayProperty: 'location',
                isSortable: true,
                widthValue: 9,
                truncate: true
            },
            {
                renderType: tableCellTypes.date,
                headerName: 'Last Modified',
                displayProperty: 'updatedAt',
                isSortable: true,
                widthValue: 4
            }
        ];
        this.relatedDocuments = sortByLexicographically(this.baseDocument.relatedDocuments, 'title');
    }

    cancel(): void {
        this.dismiss.emit();
    }

    onNameCellClick(): void {
        this.cancel();
    }
}
