import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import { downgradeComponent } from '@angular/upgrade/static';
import helpDeskRoute from './help-desk.route';
import { HelpDeskComponent } from './containers/help-desk/help-desk.component';

const dependencies = [
    uiRouter
];

const helpDesk = angular
    .module('helpDesk', dependencies)
    .config(helpDeskRoute)
    .directive('helpDeskComponent', downgradeComponent({ component: HelpDeskComponent }) as ng.IDirectiveFactory);

export default helpDesk;
