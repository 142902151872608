import { StateProvider, Transition, UrlService } from '@uirouter/angularjs';
import { AppConfigService } from '@app/shared/app-config/app-config.service';
import { TeamService } from '@app/shared/teams/team.service';
import { CurrentSessionService } from '@app/core/current-session.service';

const teamsRoute = ($stateProvider: StateProvider, $urlServiceProvider: UrlService): void => {

    $stateProvider.state('app.team.manage-team-members', {
        url: '/manage-team-members',
        views: {
            '': 'manageTeamMembers'
        }
    });

    $stateProvider.state('app.team.manage-team-settings', {
        url: '/manage-team-settings',
        views: {
            '': 'manageTeamSettings'
        },
        resolve: {
            currentTeam: [
                '$transition$',
                'Team',
                'CurrentSession',
                function loadCurrentTeam(
                    $transition$: Transition,
                    teams: TeamService,
                    currentSession: CurrentSessionService
                ): ng.IPromise<void> {
                    return teams.loadById($transition$.params().teamId)
                        .toPromise().then((team) => {
                            currentSession.setCurrentTeam(team);
                        });
                }]
        }
    });

    $stateProvider.state('app.team.manage-team-labels', {
        url: '/manage-team-settings/labels',
        views: {
            '': 'manageTeamSettings'
        },
        data: {
            tab: 'labels'
        }
    });

    $stateProvider.state('app.team.manage-team-studies', {
        url: '/manage-team-settings/studies',
        views: {
            '': 'manageTeamSettings'
        },
        data: {
            tab: 'studies'
        }
    });

    $stateProvider.state('app.team.manage-studies-by-team', {
        url: '/studies',
        views: {
            '': 'manageStudiesByTeam'
        }
    });

    $stateProvider.state('app.team.manage-team-sso', {
        url: '/manage-team-settings/sso',
        views: {
            '': 'manageTeamSettings'
        },
        data: {
            tab: 'sso'
        }
    });

    $stateProvider.state('app.team.manage-team-sip-links', {
        url: '/manage-team-settings/sip-config',
        views: {
            '': 'manageTeamSettings'
        },
        data: {
            tab: 'teamSipLinks'
        }
    });

    $stateProvider.state('app.team.manage-security', {
        url: '/manage-team-settings/security',
        views: {
            '': 'manageTeamSettings'
        },
        data: {
            tab: 'security'
        }
    });

    $stateProvider.state('app.team.manage-study-roles', {
        url: '/manage-study-roles',
        views: {
            '': 'manageTeamSettings'
        },
        data: {
            tab: 'manageStudyRoles'
        }
    });

    // Fallback for old (non-existing) /manage-team-profile
    $urlServiceProvider.rules.when(
        '/app/teams/:teamId/manage-team-profile',
        '/app/teams/:teamId/manage-team-settings'
    );

    $stateProvider.state('app.select-team', {
        url: '/select-team',
        onEnter: ['$window', 'AppConfigService', function onEnter($window, appConfigService: AppConfigService): void {
            $window.location.replace(`${appConfigService.config.authHost}/#/app/select-team`);
        }]
    });
};

teamsRoute.$inject = ['$stateProvider', '$urlServiceProvider'];

export default teamsRoute;
