import {
    Crumb, Study, Team, TeamPermissions
} from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StudiesService } from '@app/shared/studies/studies.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { filter } from 'rxjs/operators';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { StateService } from '@uirouter/angular';
import template from './manage-team-studies-container.html';


@Component({
    selector: 'manage-studies-by-team',
    template
})
export class ManageTeamStudiesContainer implements OnInit {
    team: Team;
    studiesRes = new BehaviorSubject<{ items: Study[]; recordCount: number }>(undefined);
    studiesRes$ = this.studiesRes.asObservable()
    crumbs: Crumb[] = [{ name: 'Studies' }];
    studyProfileEntryNavMenuLocation = false;
    canManageMonitorGroups: boolean;
    canEdit: boolean;

    constructor(
        private CurrentSession: CurrentSessionService,
        private Studies: StudiesService,
        private FeatureFlags: FeatureFlagService,
        private $state: StateService
    ) { }

    ngOnInit(): void {
        this.getStudyProfileEntryFlag();
        this.team = this.CurrentSession.getCurrentTeam();

        this.canManageMonitorGroups = this.team && this.team.permissions.managePaywalls;
        this.canEdit = this.team && this.team.permissions.createEditTeamStudyProfiles;

        const perm = this.team ? this.team.permissions : {} as TeamPermissions;
        if (!(perm.manageTeamStudyProfiles || perm.viewTeamStudyProfiles)) {
            this.$state.go('home');
            return;
        }

        this.loadTeamStudies({
            pageNum: 1,
            pageSize: 20,
            sortBy: 'uniqueProtocolId',
            sortDir: 'ASC'
        });
    }

    getStudyProfileEntryFlag() {
        this.FeatureFlags.getFlag(FEATURE_FLAGS.STUDY_PROFILE_SIDEMENU_ENTRY, false).pipe(
            filter((flag) => {
                return flag !== undefined;
            })
        ).subscribe((value) => {
            this.studyProfileEntryNavMenuLocation = value;
            if (!value) {
                this.$state.go('app.team.manage-team-studies');
            }
        });
    }

    loadTeamStudies(params): void {
        this.Studies.getStudies(this.team.id, params).subscribe(
            (value) => {
                this.studiesRes.next(value);
            },
            (error) => {
                if (error.status) {
                    switch (error.status) {
                        case 403:
                            this.$state.go('app.team.binders');
                            break;
                        default:
                            console.log(`An unexpected error occurred:  ${error.message}`);
                    }
                }
                else {
                    console.log('An error occurred:', error.message);
                }
                this.studiesRes.next(null);
            }
        );
    }
}
