import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { REGEX, MESSAGES } from '@app/core/constants';
import {
    Component, EventEmitter, Input, Output, OnInit
} from '@angular/core';
import {
    Binder, Folder, LabeledEntity, Team
} from '@app/shared/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Updates } from '@app/components/binders/components/binder-form/binder-form.component.types';
import { calculateEntityPath, EntityPathItem } from '@app/shared/documents/calculate-entity-path.util';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Label } from '@app/shared/labels/labels.service.types';
import * as CustomValidators from '@app/core/form-validators';
import template from './folder-form.component.html';
import styles from './folder-form.component.scss';
import { FolderOptions } from './folder-form.component.types';

@Component({
    selector: 'folder-form',
    template,
    styles: [String(styles)]
})

export class FolderFormComponent implements OnInit {
    @Input() folder: Partial<Folder> = {};
    @Input() availableLabels: Label[];
    @Input() assignedLabels: LabeledEntity[];
    @Input() options: FolderOptions;
    @Input() parent: Folder | Binder;
    @Output() save = new EventEmitter<Updates>();

    readonly namePattern = REGEX.names;
    readonly nameMessage = MESSAGES.validNameMessage;
    readonly folderNameMinLength = 1;
    readonly folderNameMaxLength = 250;

    initialFolderName: string;
    isCreate: boolean;
    folderForm: FormGroup;
    isProcessing = false;
    canEditName: boolean;
    path: EntityPathItem[];
    currentTeam: Team;
    originalDirectLabelsHash: { [key: string]: boolean };
    updates: Updates = {
        addedLabels: [],
        removedLabels: []
    }

    constructor(
        private fb: FormBuilder,
        private modalRef: BsModalRef,
        private CurrentSession: CurrentSessionService
    ) { }

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.isCreate = !this.folder.id;
        if (!this.isCreate) {
            this.path = calculateEntityPath({
                ...this.options.folders[0],
                binderName: this.parent.type === 'binder' ? this.parent.name : this.parent.binderName
            }, this.currentTeam);
        }
        this.updates.folderName = this.folder.name;
        this.canEditName = this.isCreate || (this.folder && this.folder.permissions.renameFolder);
        this.getOriginalDirectLabels();
        this.initForm();
    }

    initForm(): void {
        this.folderForm = this.fb.group({
            name: [
                { value: this.folder.name || '', disabled: !this.canEditName },
                [
                    Validators.required,
                    Validators.minLength(this.folderNameMinLength),
                    Validators.maxLength(this.folderNameMaxLength),
                    Validators.pattern(this.namePattern),
                    CustomValidators.notBlank
                ]
            ]
        });

        this.initialFolderName = this.folderForm.controls.name.value;
    }

    handleKeyPress(event: KeyboardEvent) {
        if (event.key === 'Enter' && this.canSubmit()) {
            this.submit();
        }
    }

    getOriginalDirectLabels(): void {
        // create hash of original label/value assignments to compare
        // when re-adding label/value assignments
        this.originalDirectLabelsHash = this.assignedLabels
            && this.assignedLabels.reduce((accum, label) => {
                if (label.objectId === this.folder.id) {
                    accum[label.labelId + label.valueId] = true;
                }
                return accum;
            }, {});
    }

    cancel(): void {
        if (!this.isProcessing) {
            this.modalRef.hide();
        }
    }

    handleAssignLabels(params = []): void {
        const assignments = [];
        params.forEach((param) => {
            const {
                labelId,
                valueId,
                labelName,
                value
            } = param;
            const assignment = {
                labelId,
                labelName,
                valueId,
                value,
                objectId: this.folder.id,
                objectType: 'folder'
            } as LabeledEntity;

            if (!this.originalDirectLabelsHash[labelId + valueId]) {
                this.updates.addedLabels.push(assignment);
            }
            assignments.push(assignment);
        });

        _.pullAllBy(this.updates.removedLabels, assignments, 'valueId');
        this.assignedLabels = this.assignedLabels.concat(assignments);
        this.folderForm.markAsDirty();
    }

    handleRemoveLabel(params: Partial<LabeledEntity>): void {
        const {
            labelId,
            valueId,
            labelName,
            value
        } = params;
        const isAssigned = this.assignedLabels
            .find((l) => l.labelId === labelId
                && l.valueId === valueId
                && l.objectId === this.folder.id);
        if (!isAssigned) {
            return;
        }

        const assignment = {
            labelId,
            labelName,
            valueId,
            value,
            objectId: this.folder.id,
            objectType: 'folder'
        } as LabeledEntity;

        if (this.originalDirectLabelsHash[labelId + valueId]) {
            this.updates.removedLabels.push(assignment);
        }
        let [inheritedLabels, directLabels] = _.partition(this.assignedLabels,
            (labeled) => labeled.objectId !== assignment.objectId);

        directLabels = directLabels.filter((label) => label.valueId !== assignment.valueId);
        this.assignedLabels = [...inheritedLabels, ...directLabels];
        _.pullAllBy(this.updates.addedLabels, [assignment], 'valueId');
        this.folderForm.markAsDirty();
    }

    canSubmit() {
        let canSubmit: boolean;
        const isFolderNameChanged = this.initialFolderName.trim() !== this.folderForm.controls.name.value.trim();

        canSubmit = !(this.folderForm.invalid || this.isProcessing || this.folderForm.pristine || !isFolderNameChanged);

        canSubmit = canSubmit || !(this.updates.addedLabels.length === 0 && this.updates.removedLabels.length === 0);

        return canSubmit;
    }

    submit(): void {
        if (!this.canSubmit()) {
            return;
        }

        this.isProcessing = true;
        this.updates = {
            ...this.updates,
            ...this.folderForm.value,
            onSave: () => {
                this.modalRef.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        };
        this.save.emit(this.updates);
    }
}
