import {
    Component, Input, EventEmitter, Output, OnInit
} from '@angular/core';
import {
    FormBuilder, Validators, AbstractControl, FormGroup, FormControl
} from '@angular/forms';
import { REGEX, MESSAGES } from '@app/core/constants';
import { CurrentSessionService } from '@app/core/current-session.service';
import { notBlank } from '@app/core/form-validators';
import { calculateEntityPath, EntityPathItem } from '@app/shared/documents/calculate-entity-path.util';
import { LogMetadataType } from '@app/shared/documents/documents.service.types';
import { Document } from '@app/shared/models';

import template from './log-edit-metadata.component.html';
import { LogEditMetadataEvent } from './log-edit-metadata.component.types';

@Component({
    selector: 'log-edit-metadata',
    template
})
export class LogEditMetadataComponent implements OnInit {
    @Input() metadataType: LogMetadataType;
    @Input() document: Document;

    @Output() dismiss = new EventEmitter<void>();
    @Output() save = new EventEmitter<LogEditMetadataEvent>();

    capitalizedType: string;
    entityPath: EntityPathItem[];
    metadataInput: AbstractControl;
    form: FormGroup;

    isProcessing = false;
    metadataError = MESSAGES.validExtremelyLongTextMessage;
    originalValue: string;
    shouldRequireReason = true;

    constructor(
        private fb: FormBuilder,
        private CurrentSession: CurrentSessionService
    ) { }

    ngOnInit(): void {
        this.capitalizedType = this.metadataType.charAt(0).toUpperCase() + this.metadataType.slice(1);
        this.entityPath = calculateEntityPath(this.document, this.CurrentSession.getCurrentTeam());
        this.originalValue = this.document[this.metadataType];
        this.shouldRequireReason = !!this.originalValue;
        this.initForm(this.metadataType, this.originalValue);
    }

    private initForm(field: LogMetadataType, value: string): void {
        this.form = this.fb.group({
            [field]: [value, [
                Validators.required,
                Validators.maxLength(10000),
                Validators.pattern(REGEX.extremelyLongText),
                notBlank
            ]]
        });
        if (this.shouldRequireReason) {
            this.form.addControl('reason', new FormControl('', [Validators.required, notBlank]));
        }
        else {
            this.form.addControl('comment', new FormControl('', [notBlank]));
        }
        this.metadataInput = this.form.get(field);
    }

    submitDisabled(): boolean {
        return this.isProcessing || this.form.invalid || this.metadataInput.value.trim() === this.originalValue;
    }

    dismissModal(): void {
        this.dismiss.emit();
    }

    saveForm(): void {
        if (this.submitDisabled()) {
            return;
        }

        this.isProcessing = true;
        const reasonOrCommentField = this.shouldRequireReason ? 'reason' : 'comment';
        const reasonOrCommentValue = this.form.controls[reasonOrCommentField].value;
        this.save.emit({
            data: {
                [this.metadataType]: this.form.controls[this.metadataType].value.trim(),
                ...reasonOrCommentValue && { [reasonOrCommentField]: reasonOrCommentValue }
            },
            onSuccess: () => this.dismissModal(),
            onError: () => {
                this.isProcessing = false;
                this.dismissModal();
            }
        });
    }
}
