import { LogTemplateType } from '../../components/log-templates/components/log-template-type-selector/log-template-type-selector.component.types';
import { LogEntryTypes } from './log-entry.model';
import { Signature, SignatureRequest } from './signature.model';
import { StudyResponsibilities, StudyRole } from './study.model';

export interface DocumentSIPStatus {
    teamId: string;
    documentId: string;
    version: number;
    sipVersion: number;
    status: SIPStatusTypes;
    createdBy: string;
    updatedBy: string;
    createdAt: 'date';
}

export interface DocumentSIPStatusProperties {
    version: number;
    SIPUpdates: Array<DocumentSIPStatus>;
}

export enum SIPStatusTypes {
    approved = 'Approved',
    rejected = 'Rejected',
    requestForRevision = 'Request for Revision',
}

export interface DocumentPropertyColumn {
    name: string;
    type: LogEntryTypes;
    selectOptions?: {
        isNumberIdentifier?: boolean;
        options: {
            id: string;
            name: string;
            studyRoleId?: string;
        }[]
    }
}

export interface DocumentProperties {
    externalId?: string;
    sourceSystem?: string;
    approval?: {
        status: string;
        updatedBy: string;
        updatedByEmail: string;
        reason: string;
    };
    columns?: DocumentPropertyColumn[];
    templateVersion?: {
        templateId: string;
        version: number;
        name: string;
    };
    templateType?: LogTemplateType;
}

export interface LogDetail {
    name: string;
    value: string;
    immutable?: boolean;
}

export enum DocumentSubTypes {
    content = 'content',
    log = 'log',
    shortcut = 'shortcut',
    placeholder = 'placeholder'
}

export type DocumentId = {
    documentId: string;
    version: number;
};

export type DocumentPathArray = {
    id: string;
    name: string;
    path: string;
    type: string;
}[]

export type DocumentTag = {
    id: string;
    name: string;
}

export type DocumentProject = {
    projectId: string;
    projectName: string;
    timelines: {
        timelineId: string;
        timelineName: string;
    }[];
}

export interface Document {
    binderName: string;
    shortcutOf: {
        documentId: string | null;
        lockedToDocumentVersion?: number;
    };
    workingCopy: {
        lock: string | null;
        lockedBy: string | null;
        version: number;
    };
    documentCategory: {
        categoryId: string | null;
        statusId: string | null;
    };
    dateRawFileReplaced: string | null;
    dateRawFileUploaded: string | null;
    dueDate: Date;
    folderId: string;
    hasPii: boolean;
    isBrokenShortcut: boolean;
    originalPlaceholderTitle: string | null;
    overwrittenPlaceholderId: string | null;
    path: null | string | DocumentPathArray;
    pathCanonical: string;
    lineage: string[];
    sourceId: string | null;
    isLatestVersion: boolean;
    isConvertToPdf: boolean;
    requestedSignaturesCount: number;
    pendingTasksCount: number;
    timelinesCount: number;
    projects?: DocumentProject[];
    conversionRetries: number;
    createdBy: string;
    binderId: string;
    teamId: string;
    pageCount: number;
    conversionStatus:
    'N/A' |
    'Pending' |
    'Complete' |
    'Failed' |
    'In Progress' |
    'Too Large' |
    'Unsupported File Type';
    importedVia:
    'placeholder' |
    'fax' |
    'email' |
    'emailContent' |
    'printer' |
    'direct' |
    'Migration' |
    'shortcut' |
    'duplicate' |
    'move' |
    'eHub' |
    'SiteLink\u2122' |
    'api';
    friendlyImportedVia:
    'Placeholder' |
    'Fax Connector' |
    'Email Attachment' |
    'Email Communication' |
    'Printer Connector' |
    'Direct' |
    'Migrated' |
    'Shortcut' |
    'Duplicate' |
    'Move' |
    'SiteLink\u2122 Bulk Distribution' |
    'eHub Bulk Distribution' |
    'API' | 'eConsent';
    subType: DocumentSubTypes;
    rawFilenameCanonical: string;
    rawFilename: string;
    title: string;
    titleCanonical: string;
    ext: string;
    extCanonical: string;
    updatedBy: string;
    formStatus: 'checking-form' | 'no-form' | 'form-in-progress' | 'form-finalized';
    formFields?: object[]; // TODO
    relatedDocuments: any[] ; // TODO
    hasPendingSignatures?: boolean;
    signatures: Signature[];
    tags: DocumentTag[];
    tasks: []; // TODO
    dateAttachmentUploaded?: string;
    transaction?: {
        transactionId: string;
    };
    comment: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    filename: string;
    filenameCanonical: string;
    id: DocumentId | string;
    version: number;
    logDetails?: LogDetail[];
    logStatus: 'in progress' | 'finalized' | null;
    legend?: string;
    information?: string;
    responsibilities?: StudyResponsibilities;
    roles?: StudyRole[];
    isShortcut: boolean;
    shortcuts?: Document[];
    pendingSignatures: SignatureRequest[];
    isDue: boolean;
    isAlmostDue: boolean;
    isExpired: boolean;
    isAlmostExpired: boolean;
    expirationDate: Date;
    isLocked: boolean;
    hasLockedShortcuts?: boolean;
    conversionStatusBooleans: {
        isPending: boolean;
        isConverting: boolean;
        isComplete: boolean;
        isFailed: boolean;
        isTooLarge: boolean;
        isUnsupported: boolean;
    };
    isFullySigned: boolean;
    type: 'document';
    originalDocumentSubType?: 'content' | 'placeholder';
    permissions?: { [key: string]: boolean };
    versions?: {
        comment: string;
        createdAt: string;
        updatedAt: string;
        fileExt: string;
        number: number;
        hasPii: boolean;
    }[];
    originalDocument?: Document;
    file?: {
        size?: number;
    };
    originalDocumentVersion?: number;
    documentProperties?: DocumentProperties;
    documentSIPStatus?: Array<DocumentSIPStatus>;
    qcReview?: any;
    exchangeEventId?: string;
    isDownloadable?: boolean;
    sortType?: string;
    href?: string;
}

export interface SendToSipDocVersionInput {
    name: string;
    number: number;
    isLatest: boolean;
}
