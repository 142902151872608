import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './request-preview-role-templates.component.html';

@Component({
    template
})
export class RequestPreviewRoleTemplatesComponent {
    @Output() onPreviewEvent = new EventEmitter<boolean>();
    constructor(private modal: BsModalRef) {}

    cancel(): void {
        this.modal.hide();
    }

    onPreview() {
        this.onPreviewEvent.emit(true);
    }
}
