import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalSearchResponse } from './global-search.service.types';

@Injectable({
    providedIn: 'root'
})
export class GlobalSearchService {
    private readonly url = (teamId: string): string => `/api/teams/${teamId}/search`;

    constructor(private http: HttpClient) {}

    public search(teamId: string, text: string, pageNum: number, pageSize: number): Observable<GlobalSearchResponse> {
        const uri: string = this.url(teamId);
        const params = new HttpParams()
            .append('text', text)
            .append('pageNum', pageNum.toString())
            .append('pageSize', pageSize.toString());

        return this.http.get<GlobalSearchResponse>(uri, { params, observe: 'response' })
            .pipe(
                map((response) => {
                    return {
                        ...response.body,
                        offline: (response.status === 204)
                    };
                })
            );
    }
}
