import {
    Component,
    Input,
    ViewChild,
    HostListener
} from '@angular/core';
import { AdapterService } from '@app/shared/adapter/adapter.service';
import { Document } from '@app/shared/models';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { GlobalSearchService } from './global-search.service';

import template from './global-search.component.html';
import styles from './global-search.component.scss';

@Component({
    selector: 'global-search',
    template,
    styles: [String(styles)]
})
export class GlobalSearchComponent {
    @Input() teamId: string;
    @ViewChild('dropdown', { static: true }) dropdown: BsDropdownDirective;

    maxSize = 4;
    isInputFocused = false;
    searchExecuted = false;
    total: number;
    items: Document[];
    isProcessing: boolean;
    offline: boolean;
    searchText: string;
    clickedInside: boolean;
    pagination = {
        pageSize: 10,
        pageNum: 1,
        totalItems: 0
    };

    constructor(private GlobalSearch: GlobalSearchService, private Adapter: AdapterService) {}


    @HostListener('click')
    clickInside(): void {
        this.clickedInside = true;
    }

    @HostListener('document:click')
    clickOutside(): void {
        if (!this.clickedInside) {
            this.focusOut();
            this.dropdown.hide();
        }
        this.clickedInside = false;
    }

    public getConversionState(item: Document): {isFailed: boolean; inProgress: boolean} {
        return this.Adapter.getConversionState(item);
    }

    public onSearch(): void {
        if (this.searchText && !this.isInputFocused) {
            this.clear();
            this.dropdown.hide();
        }
        else if (this.total && this.searchExecuted) {
            this.hide();
            this.dropdown.hide();
        }
        else {
            this.search();
            if (this.searchText) {
                this.dropdown.show();
            }
        }
    }

    public getSearchButtonClass(): string {
        if (this.searchText && !this.isInputFocused) {
            return 'global-search__clear-button';
        }
        return 'btn btn-primary global-search__input-button';
    }

    public getSearchButtonIconClass(): string {
        if (this.isProcessing) {
            return 'fa fa-spinner fa-spin fa-fw';
        }
        if (this.searchText && !this.isInputFocused) {
            return 'fas fa-times';
        }
        if (this.total && this.searchExecuted) {
            return 'fas fa-caret-up';
        }
        if (!this.items && this.searchExecuted) {
            return 'fas fa-caret-down';
        }
        return 'fas fa-arrow-right';
    }

    public onPageChange({ page }): void {
        this.pagination.pageNum = page;
        this.search();
    }

    public search(): void {
        if (!this.searchText || this.isProcessing) {
            return;
        }
        this.isInputFocused = true;
        this.isProcessing = true;
        this.GlobalSearch
            .search(this.teamId, this.searchText, this.pagination.pageNum, this.pagination.pageSize)
            .subscribe((data) => {
                this.isProcessing = false;
                if (data.offline) {
                    this.offline = true;
                    this.dropdown.show();
                    return;
                }
                this.items = data.items;
                this.total = data.total <= 200 ? data.total : 200;
                this.searchExecuted = true;
                if (this.searchText) {
                    this.dropdown.show();
                }
            }, () => {
                // set isProcessing to false on err
                this.isProcessing = false;
            });
    }

    public delete(): void {
        this.clearResults();
        this.pagination.pageNum = 1;
        this.searchExecuted = false;
    }

    private clear(): void {
        this.searchText = '';
        this.clearResults();
        this.isInputFocused = false;
        this.searchExecuted = false;
    }

    private hide(): void {
        this.clearResults();
    }

    private clearResults(): void {
        this.items = null;
        this.total = null;
    }

    public focusIn(): void {
        if (this.items) {
            this.dropdown.show();
        }
        this.isInputFocused = true;
    }

    public focusOut(): void {
        this.isInputFocused = false;
    }
}
