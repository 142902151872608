import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { CheckIsViewBinderPermissionMissingResponse, GetMissingPermissionsParams, ItemPermission } from './permission-validator.service.types';
import { PermissionsService } from './permissions.service';
import { PermissionMiniModel } from './permissions.service.types';

@Injectable()
export class PermissionValidatorService {
    constructor(private permissions: PermissionsService) { }

    getMissingPermissions(params: GetMissingPermissionsParams): Observable<CheckIsViewBinderPermissionMissingResponse> {
        const { item, itemPermissions } = params;
        switch (item.type) {
            case 'team':
                return this._checkMissingTeamPermissions(itemPermissions);
            case 'binder':
                return this._checkIsViewBinderPermissionMissing(itemPermissions.tree.permissions);
            case 'folder':
            case 'document':
                return this._getMissingOnParrent(params);
            default:
                // noop
        }
    }

    private _checkMissingTeamPermissions(itemPermissions: ItemPermission):
        Observable<CheckIsViewBinderPermissionMissingResponse> {
        const { manageTeam, manageTeamInbox } = itemPermissions.tree.permissions;
        const { manageAccessAndTeamPermissions, sendAnnouncements, manageLogTemplates } = manageTeam.permissions;

        // check for team-level viewBinder
        return this._checkIsViewBinderPermissionMissing({
            downloadDocumentWithPii: itemPermissions.tree.permissions.downloadDocumentWithPii,
            viewPiiInDocument: itemPermissions.tree.permissions.viewPiiInDocument,
            manageBinder: manageTeam.permissions.manageBinder
        }).pipe(map((missing) => {
            // check for viewTeamUsersRolesPermissions
            if (!manageAccessAndTeamPermissions.permissions.viewTeamUsersRolesPermissions.has
                // these permissions need viewTeamUsersRolesPermissions to be able to work properly
                && (_.some(manageAccessAndTeamPermissions.permissions, 'has') || sendAnnouncements.has)) {

                const { viewTeamUsersRolesPermissions } = manageAccessAndTeamPermissions.permissions;
                missing.localPermissions.push({
                    permissionName: viewTeamUsersRolesPermissions.name,
                    fix: () => {
                        viewTeamUsersRolesPermissions.has = true;
                        manageAccessAndTeamPermissions.has = _.every(manageAccessAndTeamPermissions.permissions, 'has');
                        manageTeam.has = _.every(manageTeam.permissions, 'has');
                    }
                });
            }
            // check if user has some children of manageLogTemplates, but not viewLogTemplates
            if (!manageLogTemplates.permissions.viewLogTemplates.has && _.some(manageLogTemplates.permissions, 'has')) {
                const { viewLogTemplates } = manageLogTemplates.permissions;
                missing.localPermissions.push({
                    permissionName: viewLogTemplates.name,
                    fix: () => {
                        viewLogTemplates.has = true;
                        manageLogTemplates.has = _.every(manageLogTemplates.permissions, 'has');
                        manageTeam.has = _.every(manageTeam.permissions, 'has');
                    }
                });
            }
            // check if user has some children of manageTeamInbox, but not viewTeamInbox
            if (!manageTeamInbox.permissions.viewTeamInbox.has && _.some(manageTeamInbox.permissions, 'has')) {
                const { viewTeamInbox } = manageTeamInbox.permissions;
                missing.localPermissions.push({
                    permissionName: viewTeamInbox.name,
                    fix: () => {
                        viewTeamInbox.has = true;
                        manageTeamInbox.has = _.every(manageTeamInbox.permissions, 'has');
                        manageTeam.has = _.every(manageTeam.permissions, 'has');
                    }
                });
            }
            return missing;
        }));
    }

    private _checkIsViewBinderPermissionMissing(permissions: {
        [key: string]: PermissionMiniModel;
    }, objectInfo = undefined):
        Observable<CheckIsViewBinderPermissionMissingResponse> {
        const { downloadDocumentWithPii, viewPiiInDocument, manageBinder } = permissions;

        if (!manageBinder.permissions.viewBinder.has && !manageBinder.permissions.viewBinder.inherited
            && (downloadDocumentWithPii.has || viewPiiInDocument.has || manageBinder.hasCheckedChildren)) {

            const { viewBinder } = manageBinder.permissions;

            return of({
                localPermissions: [{
                    permissionName: viewBinder.name,
                    objectInfo,
                    fix: (): void => {

                        manageBinder.hasCheckedChildren = true;
                        viewBinder.has = true;
                        viewBinder.hasCheckedChildren = true;
                        Object.keys(viewBinder.permissions).forEach((permission) => {
                            viewBinder.permissions[permission].has = true;
                        });
                        manageBinder.has = _.every(manageBinder.permissions, 'has');
                    }
                }],
                externalPermissions: []
            });
        }
        return of({
            localPermissions: [],
            externalPermissions: []
        });
    }

    private _getMissingOnParrent(params: GetMissingPermissionsParams): Observable<CheckIsViewBinderPermissionMissingResponse> {
        const { item, itemPermissions } = params;
        if (!_.some(itemPermissions.tree.permissions, (value) => value.has || value.hasCheckedChildren)) {
            return of({
                localPermissions: [],
                externalPermissions: []
            });
        }

        return this.permissions
            .checkMissing(item.teamId, {
                subjectId: itemPermissions.subject.id,
                subjectType: itemPermissions.subject.type,
                objectId: item.id,
                objectType: item.type
            })
            .pipe(map((missing) => {
                return {
                    localPermissions: [],
                    externalPermissions: missing.map((suggested) => ({
                        permissionName: suggested.privName,
                        objectInfo: { objectName: suggested.name, objectType: suggested.objectType },
                        saveParams: suggested
                    }))
                };
            }));
    }
}
