const notificationConfig = (NotificationProvider: ng.uiNotification.INotificationProvider): void => {
    NotificationProvider.setOptions({
        delay: 5000,
        startTop: 70,
        startRight: 10,
        verticalSpacing: 10,
        horizontalSpacing: 10,
        positionX: 'right',
        positionY: 'top',
        closeOnClick: true
    });
};

notificationConfig.$inject = ['NotificationProvider'];

export default notificationConfig;
