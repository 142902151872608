import {
    Component, EventEmitter, OnInit, Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { REGEX } from '@app/core/constants';
import { notBlank } from '@app/core/form-validators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './label-create.component.html';
import { LabelCreateEvent, Label } from './label-create.component.types';

@Component({
    template
})
export class LabelCreateComponent implements OnInit {

    @Output() onCreateLabel = new EventEmitter<LabelCreateEvent>();
    createLabelForm: FormGroup;
    isProcessing: boolean;
    valuesError: string;
    readonly maxLength: 250;
    label: Label;

    namePattern = REGEX.labelNameValue;

    constructor(
        private fb: FormBuilder,
        private modal: BsModalRef
    ) {

        this.isProcessing = false;
        this.label = {
            description: '',
            name: '',
            valuesText: '',
            values: []
        };

    }

    ngOnInit(): void {
        this.initForm();
    }

    cancel(): void {
        this.modal.hide();
    }

    validateValues() {
        if (!this.label.valuesText) {
            return;
        }

        this.label.values = this.label.valuesText.split('\n');
        // determine if a value is used more than once
        // or if a value contains special characters
        const reusedValues = [];
        const invalidValues = [];
        const tooLongValues = [];
        const valuesHash = {};
        this.label.values.forEach((v) => {
            valuesHash[v] = valuesHash[v] ? valuesHash[v] + 1 : 1;
        });
        Object.keys(valuesHash).forEach((v) => {
            if (valuesHash[v] > 1) {
                reusedValues.push(v);
            }
            if (!this.namePattern.test(v)) {
                invalidValues.push(v);
            }
            if (v.length >= this.maxLength) {
                tooLongValues.push(v);
            }
        });
        this.valuesError = '';
        if (reusedValues.length) {
            this.valuesError += `Values must be unique. These values appear more than once: ${reusedValues.join(',')}. `;
        }
        if (invalidValues.length) {
            this.valuesError += `Values cannot contain special characters > : ! # ^. These values are invalid: ${invalidValues.join(',')}.`;
        }
        if (tooLongValues.length) {
            this.valuesError += `Values cannot exceed ${this.maxLength} characters. There are ${tooLongValues.length} value(s) that are too long.`;
        }
    }

    onSubmit() {
        this.validateValues();
        if (this.valuesError) {
            return;
        }

        const {
            description,
            name,
            values
        } = this.label;

        const filteredValues = values.filter((value) => value.trim() !== '');

        const params = {
            description,
            name,
            values: filteredValues
        };

        this.isProcessing = true;
        this.onCreateLabel.emit({
            label: params,
            onSuccess: () => {
                this.cancel.bind(this);
                this.modal.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        });
    }

    private initForm(): void {
        this.createLabelForm = this.fb.group({
            name: ['', [Validators.required, Validators.pattern(REGEX.names), notBlank]],
            description: [''],
            values: ['', [Validators.required, notBlank]]
        });

        this.createLabelForm.valueChanges.subscribe((formValues) => {
            this.label.name = formValues.name;
            this.label.description = formValues.description;
            this.label.valuesText = formValues.values;
        });
    }
}
