import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { VirtualTreeSelectionMode } from '@app/widgets/virtual-tree/virtual-tree.component.types';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
    BrowseNode,
    BrowseTree,
    Document
} from '@app/shared/models';

import { DOCUMENT_TYPES } from '@florencehealthcare/florence-constants/lib/documents';
import template from './shortcuts-import.component.html';
import styles from './shortcuts-import.component.scss';
import { ImportShorcutsEvent } from './shortcuts-import.component.types';

@Component({
    selector: 'shortcuts-import',
    template,
    styles: [String(styles)]
})

export class ShortcutsImportComponent implements OnInit {

    @Input() entity: BrowseTree;
    @Input() loadItem;
    @Input() loadRoot;

    @Output() save = new EventEmitter<ImportShorcutsEvent>();


    private isProcessing: boolean;
    currentPath: string[];
    loadedRoot: boolean;
    loadingRoot: boolean;
    items: BrowseNode[];
    selectedItems: Document[];

    selectionMode: string;

    constructor(
        private modalInstance: BsModalRef
    ) {}

    ngOnInit(): void {
        this.selectionMode = VirtualTreeSelectionMode.MULTI_SELECT;

        this.isProcessing = false;
        this.loadedRoot = false;
        this.loadingRoot = true;
        this.selectedItems = [];

        this.currentPath = [this.entity.binderId].concat(this.entity.lineage);

        this.loadRoot().then((items) => {
            this.items = items;
            this.loadingRoot = false;
            this.loadedRoot = true;
        });
    }

    isItemSelectable(item) {
        return item.type === 'document' && [DOCUMENT_TYPES.CONTENT, DOCUMENT_TYPES.PLACEHOLDER].indexOf(item.subType) >= 0;
    }

    isItemDisabled(item) {
        return item.type !== DOCUMENT_TYPES.SHORTCUT && item.isLocked;
    }

    isItemDisplayable(item) {

        return item.subType !== DOCUMENT_TYPES.SHORTCUT;
    }

    binderTreeSelectionChange($event) {
        this.selectedItems = $event.selectedItems;
    }

    submitDisabled() {
        return !(this.selectedItems.length > 0);
    }

    cancel(): void {
        this.modalInstance.hide();
    }

    handleSubmit() {
        if (this.submitDisabled() || this.isProcessing) {
            return;
        }

        this.isProcessing = true;

        const params = {
            selectedItems: this.selectedItems,
            onError: () => {
                this.isProcessing = false;
            },
            onSuccess: () => {
                this.isProcessing = false;
                this.modalInstance.hide();
            }
        };

        this.save.emit(params);
    }
}
