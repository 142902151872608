import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { Column, User } from '@app/shared/models';
import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';
import { SignerOptions, SignersFormControls } from '../../containers/log-entry-bulk-signature-requests/log-entry-bulk-signature-requests.types';

import template from './log-entry-bulk-choose-signers.component.html';
import styles from './log-entry-bulk-choose-signers.component.scss';

@Component({
    selector: 'log-entry-bulk-choose-signers',
    template,
    styles: [String(styles)]
})
export class LogEntryBulkChooseSignersComponent implements OnInit {
    @Input() signersForm: FormGroup;
    @Input() potentialSigners: User[] = [];
    @Input() initiallySelectedSigners: User[] = [];
    @Input() loadingPotentialSigners = false;
    @Input() signatureColumns: Column[];
    @Input() hasTeamMemberColumn: boolean;
    @Output() signersFilterChange = new EventEmitter<string>();
    @Output() selectSigners = new EventEmitter<FilteredSelectEvent<User>>();
    @Output() selectionCleared = new EventEmitter<void>();

    isSearchSignersVisible = false;
    varietyRadioLabelTooltip = 'The “Team Member” column is required for this feature.';

    ngOnInit(): void {
        this.isSearchSignersVisible = this.signersForm.get(SignersFormControls.SIGNER_OPTION).value === SignerOptions.SINGLE;

        this.signersForm.get(SignersFormControls.SIGNER_OPTION).valueChanges.subscribe((option) => {
            const signerValue = option === SignerOptions.VARIETY
                ? null
                : this.initiallySelectedSigners[0];
            this.signersForm.patchValue({ signer: signerValue });

            this.isSearchSignersVisible = option === SignerOptions.SINGLE;
        });
    }

    get signerControl(): AbstractControl {
        return this.signersForm.get('signer');
    }

    onSignersFilterChange($event: string): void {
        this.signersFilterChange.emit($event);
    }

    onSignersSelect($event: FilteredSelectEvent<User>): void {
        this.selectSigners.emit($event);
    }

    onSelectionCleared(): void {
        this.selectionCleared.emit();
    }
}
