
export const SessionActivityStatuses = {
    FAILED: 'FAILED',
    SUCCESSFUL: 'SUCCESSFUL'
};

export const SessionActivityEvents = {
    DOCUMENT_UPLOAD: 'Upload Document',
    SHORTCUT_CREATE: 'Create Shortcut',
    SIGNATURE_REMINDER: 'Signature Reminder',
    TASK_REMINDER: 'Task Reminder'
};
