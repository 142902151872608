import {
    Component, Input
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { StateService } from '@uirouter/core';
import { formatDate } from '@angular/common';
import { BrowseTree } from '@app/shared/models';
import styles from './summary.component.scss';
import template from './summary.component.html';
import { NotificationsService } from '../../../../../core/notifications/notifications.service';

@Component({
    selector: 'summary',
    template,
    styles: [String(styles)]
})
export class SummaryComponent {

    @Input() hrefState: string;
    @Input() hrefParams: any;
    @Input() scheduleArchiveForm: FormGroup;
    @Input() teamId: string;
    @Input() object: BrowseTree;

    public get archivalDateControl(): AbstractControl {
        return this.scheduleArchiveForm.get('archivalDate');
    }

    constructor(
        private readonly $state: StateService,
        private Notifications: NotificationsService
    ) {}

    private readonly locale = 'en-US';

    onViewArchives() {
        const date = formatDate(this.archivalDateControl.value, 'dd-MMM-yyyy', this.locale);
        this.Notifications.success(`Archive for "${this.object.name}" is successfully scheduled for <b>${date}</b>.`);

        this.$state.go('app.team.long-term-archives', { teamId: this.teamId });
    }

    onFinish() {
        const date = formatDate(this.archivalDateControl.value, 'dd-MMM-yyyy', this.locale);
        const href = this.$state.href('app.team.long-term-archives', { teamId: this.teamId });
        this.Notifications.success(`Archive for "${this.object.name}" is successfully scheduled for <b>${date}</b>.
        <a style="text-transform: none; text-decoration: underline;" href=${href}>View all Archives</a>`);

        this.$state.go(this.hrefState, this.hrefParams);
    }

}
