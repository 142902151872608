import { Component, Input, OnInit } from '@angular/core';
import { Timeline } from '@app/shared/models';
import style from './timeline-metrics.component.scss';
import template from './timeline-metrics.component.html';
@Component({
    selector: 'timeline-metrics',
    template,
    styles: [String(style)]
})
export class TimelineMetricsComponent implements OnInit {
    @Input() timeline: Timeline;

    isExpired: boolean;
    inProgress: boolean;
    isDone: boolean;

    ngOnInit(): void {
        this.isExpired = !this.timeline.isFinished && this.timeline.metrics.remainingDays <= 0;
        this.inProgress = this.timeline.inProgress;
        this.isDone = this.timeline.isFinished;
    }
}
