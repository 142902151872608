import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter, OnInit
} from '@angular/core';
import { PaginationPropertiesService } from '@app/shared/pagination-properties/pagination-properties.service';
import { Study, Team } from '@app/shared/models';

import { CurrentSessionService } from '@app/core/current-session.service';
import { StateService } from '@uirouter/core';
import { Pagination } from './manage-team-studies.types';
import template from './manage-team-studies.component.html';

@Component({
    selector: 'manage-team-studies',
    template
})
export class ManageTeamStudiesComponent implements OnInit, OnChanges {
    @Input() canEdit: boolean;
    @Input() canManageMonitorGroups: boolean;
    @Input() studyData: {
        items: Study[];
        recordCount: number;
    };

    @Output() pageUpdated = new EventEmitter<Pagination>();
    studies: Study[];
    selectedStudyId: string|undefined = undefined;
    loading = true;
    maxSize: number;
    showBoundaryLinkNumbers: boolean;
    pagination: Pagination = {
        pageNum: 1,
        pageSize: 20,
        sortBy: 'uniqueProtocolId',
        sortDir: 'DESC'
    };

    _currentTeam: Team;

    constructor(
        PaginationProperties: PaginationPropertiesService,
        private CurrentSession: CurrentSessionService,
        private $state: StateService
    ) {
        this.showBoundaryLinkNumbers = PaginationProperties.showBoundaryLinkNumbers();
        this.maxSize = PaginationProperties.getMaxSize();
    }

    ngOnInit(): void {
        this._currentTeam = this.CurrentSession.getCurrentTeam();

        if (!this._currentTeam.permissions || !this._currentTeam.permissions.viewTeamStudyProfiles) {
            this.$state.go('app.select-team');
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.studyData && changes.studyData.currentValue) {
            this.loading = false;
            const { items, recordCount } = changes.studyData.currentValue;
            this.studies = items;
            this.pagination.totalItems = recordCount;
            this.selectedStudyId = undefined;
        }
    }

    select(study: Study): void {
        this.selectedStudyId = this.selectedStudyId === study.id ? undefined : study.id;
    }

    toggleActions($event, study: Study): void {
        $event.preventDefault();
        if (this.selectedStudyId !== study.id) {
            this.select(study);
        }
    }

    changePage({ page }): void {
        this.pagination.pageNum = page;
        this.pageUpdated.emit(this.pagination);
    }

    changeSort(): void {
        const currentDir = this.pagination.sortDir;
        this.pagination.sortDir = currentDir === 'ASC' ? 'DESC' : 'ASC';
        this.pageUpdated.emit(this.pagination);
    }
}
