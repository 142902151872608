import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
    Team, BrowseNode, BrowseTree
} from '@app/shared/models';
import {
    VirtualTreeFlatNode, VirtualTreeItemSelectedEvent, VirtualTreeNode, VirtualTreeSelectionMode
} from '@app/widgets/virtual-tree/virtual-tree.component.types';
import { calculateEntityPath, EntityPathItem } from '@app/shared/documents/calculate-entity-path.util';
import { MoveFolderParams } from './move.component.types';
import template from './move.component.html';
import styles from './move.component.scss';

@Component({
    selector: 'move',
    template,
    styles: [String(styles)]
})
export class MoveComponent implements OnInit {

    @Input() items: BrowseNode[];
    @Input() parentEntity: BrowseTree;
    @Input() loadRoot;
    @Input() loadItem;
    @Output() move = new EventEmitter<MoveFolderParams>();

    currentTeam: Team;
    selectedFolder: VirtualTreeFlatNode;
    isProcessing = false;
    loadingRoot = false;
    actingType: string;
    currentPath: string[];
    rootItems: VirtualTreeNode[];
    selectionMode = VirtualTreeSelectionMode;
    entityPath: EntityPathItem[];
    isDocSharingReceivingLocation = false;
    breadcrumb: BrowseNode | BrowseTree;

    constructor(
        private CurrentSession: CurrentSessionService,
        private modalRef: BsModalRef
    ) { }

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.breadcrumb = this.items.length > 1 ? this.parentEntity : this.items[0];
        const entity = { ...this.items[0], binderName: this.parentEntity.binderName };
        this.entityPath = calculateEntityPath(entity, this.currentTeam);
        this.actingType = this.items[0].type;
        this.currentPath = (this.items?.length && [this.items[0].binderId].concat(...this.items.map((i) => i.lineage))) || [];
        this.loadingRoot = true;
        this.items.forEach((item, index) => {
            if (this.items[index]?.isDocSharingReceivingLocation) {
                this.isDocSharingReceivingLocation = true;
            }
        });
        this.loadRoot().then((rootItems) => {
            this.rootItems = rootItems;
            this.loadingRoot = false;
        });
    }

    cancel(): void {
        this.modalRef.hide();
    }

    close(): void {
        if (!this.isProcessing) {
            this.modalRef.hide();
        }
    }

    save(): void {
        this.isProcessing = true;

        this.move.emit({
            selectedFolder: this.selectedFolder,
            actingType: this.actingType
        });
    }

    isItemSelectable(): boolean {
        return true;
    }

    onItemSelected($event: VirtualTreeItemSelectedEvent<VirtualTreeFlatNode>): void {
        this.selectedFolder = $event.item;
    }

    onItemUnselected(): void {
        this.selectedFolder = undefined;
    }
}
