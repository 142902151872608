import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { Document, Task } from '@app/shared/models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import template from './document-task-list.component.html';
import styles from './document-task-list.component.scss';
@Component({
    selector: 'document-task-list',
    template,
    styles: [String(styles)]
})

export class DocumentTaskListComponent {
    @Input() doc: Document;
    @Output() openTaskModal = new EventEmitter<Task>();

    private onHiddenSubscription: Subscription;


    constructor(
        private modalRef: BsModalRef,
        private modalService: BsModalService
    ) {}

    openUpdateTask(task: Task) {
        this.modalRef.hide();

        this.onHiddenSubscription = this.modalService.onHidden.subscribe(() => {
            this.onHiddenSubscription.unsubscribe();

            this.openTaskModal.emit(task);
        });
    }

    cancel(): void {
        this.modalRef.hide();
    }
}
