import { Inject, Injectable } from '@angular/core';
import {
    CompleteReportDataParams,
    SpecificReportParams,
    TeamReportParams
} from './reports.service.types';

type ReportState = Record<string, any>;

@Injectable()
export class ReportsIndexStateService {
    readonly storageKey = 'reportParams';

    constructor(
        @Inject('Window') private $window: Window
    ) {}

    getReportParams(teamId: string, reportId: string): CompleteReportDataParams | null {
        const params = JSON.parse(this.$window.localStorage.getItem(this.storageKey));
        return params
            && params[teamId]
            && params[teamId][reportId] ? { ...params[teamId][reportId], ...params[teamId].teamState } : null;
    }

    setReportParams(
        teamId: string,
        reportId: string,
        reportSpecificParams?: SpecificReportParams,
        teamReportParams?: TeamReportParams
    ): void {
        const oldState: Record<string, ReportState> = JSON.parse(
            this.$window.localStorage.getItem(this.storageKey) || '{}'
        );

        if (!oldState[teamId]) {
            oldState[teamId] = { [reportId]: {}, teamState: {} };
        }
        else if (!oldState[teamId][reportId]) {
            oldState[teamId][reportId] = {};
        }

        if (reportSpecificParams) {
            oldState[teamId][reportId] = reportSpecificParams;
        }

        if (teamReportParams) {
            oldState[teamId].teamState = teamReportParams;
        }

        this.$window.localStorage.setItem(this.storageKey, JSON.stringify(oldState));
    }

    clearReportParams(teamId: string): void {
        const oldState = JSON.parse(this.$window.localStorage.getItem(this.storageKey) || null);

        if (!oldState) {
            return;
        }

        delete oldState[teamId];

        if (!Object.keys(oldState).length) {
            this.$window.localStorage.removeItem(this.storageKey);
            return;
        }

        this.$window.localStorage.setItem(this.storageKey, JSON.stringify(oldState));
    }
}
