'use strict';

const AnnotationTypes = require('./annotationTypes');

module.exports = {
    [AnnotationTypes.add_text]: 'Added Text',
    [AnnotationTypes.redaction]: 'Redacted',
    [AnnotationTypes.highlight]: 'Highlighted',
    [AnnotationTypes.stamp]: 'Stamped',
    [AnnotationTypes.signature]: 'Signed'
};
