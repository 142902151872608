import { CurrentSessionService } from '@app/core/current-session.service';
import { UsersService } from '@app/shared/users/users.service';
import { AuditTrailService } from '@app/shared/audit-trail/audit-trail.service';
import {
    User, Team, UserPrivacyConsent, AuditTrailSubject
} from '@app/shared/models';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { ReasonEvent } from '@app/widgets/reason/reason-modal.component.types';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { AuditTrailModalComponent } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component';
import { Component, OnInit } from '@angular/core';
import { ReasonModalComponent } from '@app/widgets/reason/reason-modal.component';
import template from './user-profile.component.html';

@Component({
    selector: 'app-user-profile',
    template
})
export class UserProfileComponent implements OnInit {
    user: User;
    currentTeam: Team;
    reason: string;
    crumbs = [{ name: 'User Profile' }];

    constructor(
        private Users: UsersService,
        private CurrentSession: CurrentSessionService,
        private Notifications: NotificationsService,
        private AuditTrail: AuditTrailService,
        private Modals: ModalsService
    ) { }

    ngOnInit(): void {
        this.user = this.CurrentSession.getCurrentUser();
        this.currentTeam = this.CurrentSession.getCurrentTeam();
    }

    openAuditTrailModal(): void {
        const item = {
            id: this.user.id
        };

        const params = {
            subject: AuditTrailSubject.USER_PROFILE,
            teamId: null,
            objectId: item.id,
            overwrittenObjectId: null,
            limitToOverwritten: false,
            ...this.AuditTrail.auditPagination
        };

        this.AuditTrail.getAudits(params).subscribe((audits) => {
            this.Modals.show(AuditTrailModalComponent, {
                class: 'modal-lg',
                initialState: {
                    data: audits,
                    item,
                    subject: AuditTrailSubject.USER_PROFILE,
                    pagination: this.AuditTrail.auditPagination,
                    onPageChange: this.AuditTrail.getAudits.bind(this.AuditTrail)
                }
            });
        },
        ({ error }) => {
            const message = error.message || 'There was an unexpected error.  Please contact your administrator.';
            this.Notifications.error(message);
        });
    }

    saveUserProfileUpdate({ profile, onSave }): void {
        const modalRef = this.Modals.show(ReasonModalComponent, {
            animated: false,
            class: 'modal-md',
            initialState: {
                header: 'Reason for Change',
                reasonLabel: 'Please provide a reason for the recent profile changes:'
            }
        });

        modalRef.content.save.subscribe(({ data: { reason }, onSuccess, onError }: ReasonEvent) => {
            this.Users.update(this.user.id, { profile: { ...profile, reason } })
                .subscribe((user) => {
                    onSave();
                    onSuccess();
                    if (user) {
                        this.user = { ...this.user, ...user };
                        this.CurrentSession.setCurrentUser(this.user);
                        this.Notifications.success('User Profile Successfully updated!');
                    }
                }, onError);
        });

        modalRef.content.dismiss.subscribe(() => modalRef.hide());
    }

    saveUserPrivacyConsentUpdate(privacyConsent: Partial<UserPrivacyConsent>): void {
        this.Users.update(this.user.id, { privacyConsent })
            .subscribe((user) => {
                if (user) {
                    this.user = { ...this.user, ...user };
                    this.CurrentSession.setCurrentUser(this.user);
                    this.Notifications.success('Your preferences have been saved. You can always update your communication and cookie preferences on your Profile Page under the Privacy Options tab.');
                }
            });
    }
}
