import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    AbstractControl, FormBuilder, Validators
} from '@angular/forms';
import { REGEX, MESSAGES } from '@app/core/constants';
import { Team, TeamSipIntegrationLink } from '@app/shared/models';

import template from './team-sip-link-create.component.html';

export interface SaveParams {
    params: Partial<TeamSipIntegrationLink>;
    close: () => void;
    cb: () => void;
}

@Component({
    selector: 'team-sip-link-create',
    template
})
export class TeamSipLinkCreateComponent implements OnInit {
    @Input() team: Team;
    @Input() sponsors: {
        sipSponsorId: string;
        sponsorName: string;
    }[];

    @Output() cancel = new EventEmitter<void>();
    @Output() save = new EventEmitter<SaveParams>();

    validNameMessage = MESSAGES.validNameMessage;
    isProcessing = false;
    createForm = this.fb.group({
        sipSponsor: ['', [
            Validators.required,
            Validators.pattern(REGEX.names),
            Validators.maxLength(250)
        ]],
        sipStudyId: ['', [
            Validators.required,
            Validators.pattern(REGEX.names),
            Validators.maxLength(250)
        ]],
        sipFacilityId: ['', [
            Validators.required,
            Validators.pattern(REGEX.names),
            Validators.maxLength(250)
        ]]
    });

    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        this.createForm.addControl('teamId', this.fb.control(this.team.id, [Validators.required]));
    }

    get ctrls(): { [key: string]: AbstractControl } {
        return this.createForm.controls;
    }

    ctrlHasError(ctrl: AbstractControl): boolean {
        return ctrl.invalid && (ctrl.dirty || ctrl.touched);
    }

    handleCancel(): void {
        this.cancel.emit();
    }

    saveForm(): void {
        this.isProcessing = true;
        this.save.emit({
            params: this.createForm.value,
            close: this.handleCancel.bind(this),
            cb: () => {
                this.isProcessing = false;
            }
        });
    }
}
