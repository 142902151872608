import {
    Component, Input, Output, EventEmitter
} from '@angular/core';
import { SelectionStates } from '@app/shared/models';
import template from './flo-checkbox.component.html';
import styles from './flo-checkbox.component.scss';


@Component({
    selector: 'flo-checkbox',
    template,
    styles: [String(styles)]
})
export class FloCheckboxComponent {
    @Input() checkboxDisabled: boolean;
    @Input() disableReason: string;
    @Input() selectionState: SelectionStates;

    @Output() sendSelectionStateEvent = new EventEmitter<SelectionStates>();

    constructor() {
        this.checkboxDisabled = false;
    }

    public toggleChecked(): void {
        if (this.checkboxDisabled) {
            return;
        }

        if (this.selectionState === 'NONE' || this.selectionState === 'PARTIAL') {
            this.sendSelectionStateEvent.emit('FULL');
        }
        else {
            this.sendSelectionStateEvent.emit('NONE');
        }
    }
}
