import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import studyManagerRoute from './study-manager.route';
import { StudyManagerComponent } from './containers/study-manager/study-manager.component';
import { CreateStudyComponent } from './containers/create-study/create-study.component';
import { UpdateStudyComponent } from './containers/update-study/update-study.component';
import { StudyManagerService } from './study-manager.service';

const dependencies = [uiRouter];

export const StudyManagerModule = angular
    .module('app.studyManager', dependencies)
    .config(studyManagerRoute)
    .factory('StudyManagerService', downgradeInjectable(StudyManagerService))
    .directive('studyManager', downgradeComponent({ component: StudyManagerComponent }) as ng.IDirectiveFactory)
    .directive('createStudy', downgradeComponent({ component: CreateStudyComponent }) as ng.IDirectiveFactory)
    .directive('updateStudy', downgradeComponent({ component: UpdateStudyComponent }) as ng.IDirectiveFactory);
