import {
    Component,
    Injectable,
    Input
} from '@angular/core';

import styles from './session-activity-details.component.scss';
import template from './session-activity-details.component.html';

@Component({
    selector: 'session-activity-details',
    template,
    styles: [String(styles)]
})

@Injectable({ providedIn: 'root' })
export class SessionActivityDetailsComponent {
    @Input() details: any[];
}
