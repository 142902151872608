import {
    Component,
    EventEmitter,
    OnInit,
    Output
} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './log-entry-strikethrough.component.html';
import { StrikeThroughLogEntriesEvent } from './log-entry-strikethrough.component.types';

@Component({
    selector: 'log-entry-strikethrough',
    template
})
export class LogEntryStrikethroughComponent implements OnInit {
    @Output() save = new EventEmitter<StrikeThroughLogEntriesEvent>();

    form: FormGroup;
    isProcessing = false;
    private maxReasonLength = 1000;

    constructor(
        private fb: FormBuilder,
        public modal: BsModalRef
    ) {}

    ngOnInit(): void {
        this.initForm();
    }

    cancel(): void {
        this.modal.hide();
    }

    getLength(ctrl: AbstractControl) {
        if (ctrl && ctrl.value) {
            return (ctrl.value).length;
        }
        return 0;
    }

    excedeedLength(ctrl: AbstractControl): boolean {
        if (ctrl && ctrl.value !== null) {

            const tooLong = this.getLength(ctrl) > this.maxReasonLength;
            if (tooLong) {
                this.form.setErrors({ tooLong });
            }
            return tooLong;
        }
        return false;
    }

    strikeThroughLogRows(): void {
        this.isProcessing = true;

        const params = {
            reason: this.form.controls.reason.value,
            onError: () => {
                this.isProcessing = false;
            },
            onSuccess: () => {
                this.isProcessing = false;
                this.modal.hide();
            }
        };

        this.save.emit(params);
    }

    private initForm(): void {
        this.form = this.fb.group({
            reason: ['', [Validators.required, notBlank, Validators.maxLength(this.maxReasonLength)]]
        });

    }
}
