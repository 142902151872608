import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

import {
    Binder,
    BindersArchives,
    DecoratedBinder,
    LabeledEntity,
    NamesPreview,
    Study
} from '@app/shared/models';
import {
    BinderDocumentsCount,
    GetBinderNamesPreviewParams,
    GetBindersParams,
    RenameBinderParams
} from './binders.service.types';

@Injectable()
export class BindersService {
    readonly url = {
        binders: (teamId: string): string => `/api/teams/${teamId}/binders`,
        binder: ({ teamId, binderId }): string => `/api/teams/${teamId}/binders/${binderId}`,
        counts: (teamId: string): string => `/api/teams/${teamId}/documents/counts`,
        labels: ({ teamId, binderId }): string => `/api/teams/${teamId}/binders/${binderId}/labels`,
        studies: ({ teamId, binderId }): string => `/api/teams/${teamId}/binders/${binderId}/study-profile`,
        namesPreview: ({ teamId, binderId }): string => `/api/teams/${teamId}/binders/${binderId}/names-preview`,
        archives: (teamId: string): string => `/api/teams/${teamId}/archives`,
        jobTitleRequired: ({ teamId, binderId }): string => `/api/teams/${teamId}/binders/${binderId}/job-title-required`,
        exists: ({ teamId, binderName }): string => `/api/teams/${teamId}/binders/name/${binderName}`
    };

    constructor(
        private $http: HttpClient
    ) { }

    createBinder(teamId: string, name: string): Observable<Binder> {
        return this.$http
            .post<{ binder: Binder }>(this.url.binders(teamId), { name })
            .pipe(pluck('binder'));
    }

    getDirectLabels(teamId: string, binderId: string): Observable<LabeledEntity[]> {
        const params = new HttpParams().set('directOnly', 'true');
        return this.$http.get<LabeledEntity[]>(this.url.labels({ teamId, binderId }), { params });
    }

    getBinderStudyProfile(teamId: string, binderId: string): Observable<Study[]> {
        return this.$http.get<Study[]>(this.url.studies({ teamId, binderId }));
    }

    getBinders(teamId: string, binderParams: GetBindersParams = {}): Observable<Binder[]> {
        let params = new HttpParams();
        Object.entries(binderParams).forEach(
            ([key, value]) => {
                if (value !== null || value !== undefined) {
                    params = params.set(key, value);
                }
            }
        );

        return this.$http
            .get<{ binders: Binder[] }>(this.url.binders(teamId), { params })
            .pipe(pluck('binders'));
    }

    getDecoratedBinders(teamId: string, binderParams: GetBindersParams = {}): Observable<DecoratedBinder[]> {
        binderParams.includeDecorations = true;

        let params = new HttpParams();
        Object.entries(binderParams).forEach(
            ([key, value]) => {
                if (value !== null || value !== undefined) {
                    params = params.set(key, value);
                }
            }
        );

        return this.$http
            .get<{ binders: DecoratedBinder[] }>(this.url.binders(teamId), { params })
            .pipe(pluck('binders'));
    }

    loadDocumentCounts(teamId: string, binderIds: string[]): Observable<BinderDocumentsCount[]> {
        const binderIdsChunks = _.chunk(binderIds, 200);

        const makeBatchedCountRequest = (binderIdsChunk: string[]): Observable<BinderDocumentsCount[]> => {
            const params = {
                objectType: 'binders',
                objectIds: binderIdsChunk
            };
            return this.$http.get<BinderDocumentsCount[]>(this.url.counts(teamId), { params });
        };

        return forkJoin(binderIdsChunks.map(makeBatchedCountRequest))
            .pipe(map(_.flatten)) as Observable<BinderDocumentsCount[]>;
    }

    getBinder(teamId: string, binderId: string): Observable<Binder> {
        return this.$http.get<Binder>(this.url.binder({ teamId, binderId }));
    }

    removeBinder(binder: Binder, reason: string): Observable<void> {
        const urlParams = {
            teamId: binder.teamId,
            binderId: binder.id
        };
        return this.$http
            .delete<void>(
                this.url.binder(urlParams),
                { params: { reason }, responseType: 'text' as 'json' }
            );
    }

    renameBinder(params: RenameBinderParams): Observable<Binder | void> {
        const {
            binderName,
            ...urlParams
        } = params;
        if (!binderName) {
            return of();
        }
        return this.$http
            .patch<{ binder: Binder }>(this.url.binder(urlParams), { name: binderName })
            .pipe(pluck('binder'));
    }

    getNamesPreview(params: GetBinderNamesPreviewParams): Observable<NamesPreview> {
        const {
            names,
            ...urlParams
        } = params;
        const body = { entityType: 'document', names };
        return this.$http.post<NamesPreview>(this.url.namesPreview(urlParams), body);
    }

    getArchives(teamId: string): Observable<BindersArchives> {
        const params = new HttpParams().set('objectType', 'binder');
        return this.$http.get<BindersArchives>(this.url.archives(teamId), { params });
    }

    updateJobTitleRequired(teamId: string, binderId: string, jobTitleRequired: boolean): Observable<{binder: Binder}> {
        return this.$http.patch<{binder: Binder}>(this.url.jobTitleRequired({ teamId, binderId }), { jobTitleRequired });
    }

    checkBinderExists(teamId: string, binderName: string): Observable<{name: string}> {
        return this.$http.get<{name: string}>(encodeURI(this.url.exists({ teamId, binderName })));
    }
}
