'use strict';

exports.subTypes = {
    USO_CUSTOM: 'usoCustom'
};

exports.attributesTypes = {
    APPROVAL_NOT_REQUIRED: { key: 'approvalNotRequired', value: 'true' },
    SKIP_EMAIL_NOTIFICATION: { key: 'skipSignatureRequestEmail', value: 'true' }
};
