import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Notification } from './notifications.service.types';

@Injectable()
export class NotificationsService {
    public notifications$: Observable<Notification>;
    public clear$: Observable<void>;
    private notifications: Subject<Notification>;
    private clear: Subject<void>;

    constructor() {
        this.notifications = new Subject<Notification>();
        this.notifications$ = this.notifications.asObservable();

        this.clear = new Subject<void>();
        this.clear$ = this.clear.asObservable();
    }

    primary(message: Notification['message']): void {
        this.notifications.next({ message, type: 'primary' });
    }

    error(message: Notification['message']): void {
        this.notifications.next({ message, type: 'error' });
    }

    info(message: Notification['message']): void {
        this.notifications.next({ message, type: 'info' });
    }

    success(message: Notification['message']): void {
        this.notifications.next({ message, type: 'success' });
    }

    warning(message: Notification['message']): void {
        this.notifications.next({ message, type: 'warning' });
    }

    unexpectedError(): void {
        this.error('There was an unexpected error. Please contact your administrator.');
    }

    clearAll(): void {
        this.clear.next();
    }
}
