import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { REGEX } from '@app/core/constants';
import { notBlank } from '@app/core/form-validators';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { BindersService } from '@app/shared/binders/binders.service';
import { FolderizeTreeItem } from '@app/shared/folders/folders.service.types';
import { Label } from '@app/shared/labels/labels.service.types';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { TeamService } from '@app/shared/teams/team.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { LabeledEntity, Binder } from '@app/shared/models';
import { sortFolderStructureLexicographically } from '@app/widgets/sort/sort-folder-structure-lexicographically';
import { StructureTemplateAssignedComponent } from '@app/widgets/structure-template/structure-template-assigned/structure-template-assigned.component';
import { StructureTemplateAppliedDueDatesComponent } from '@app/widgets/structure-template-applied-due-dates/structure-template-applied-due-dates.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { hasPlaceholderInStructure } from '@app/widgets/structure-template/structure-template-apply-due-dates.util';
import { StructureTemplate } from '../../components/imported-structures-table/imported-structures-table.component.types';
import { StudyStartupService } from '../../study-startup.service';
import { AssignStructureTemplateParams, AssignStructureTemplateResponse } from '../../study-startup.service.types';
import template from './assign-structure-template-binder.component.html';
import styles from './assign-structure-template-binder.component.scss';

@Component({
    selector: 'assign-structure',
    styles: [String(styles)],
    template
})

export class AssignStructureTemplateBinderComponent implements OnInit, OnDestroy {
    @Input() assignStructureHasExistingBinders: boolean;
    @Input() availableLabels: Label[] = [];
    @Input() teamId: string;
    @Input() teamTimezone: string = null;
    @Input() templates: StructureTemplate[] = [];
    @Input() binders: Binder[];

    private nextButtonDisabled = new BehaviorSubject<boolean>(false);
    nextButtonDisabled$ = this.nextButtonDisabled.asObservable();
    private readonly destroy$ = new Subject<void>();

    readonly progressSteps = {
        1: 'Binder Options',
        2: 'Identify Binder',
        3: 'Apply Template'
    };

    selectedExistingBinder: Binder;

    assignStructureForm: FormGroup = new FormGroup({
        assignStructureOption: new FormControl('newBinder', [Validators.required])
    });

    progress = 1;

    readonly namePattern = REGEX.names;
    readonly binderNameMinLength = 1;
    readonly binderNameMaxLength = 250;

    binderForm: FormGroup = new FormGroup({
        name: new FormControl('',
            [
                Validators.required,
                Validators.minLength(this.binderNameMinLength),
                Validators.maxLength(this.binderNameMaxLength),
                Validators.pattern(this.namePattern),
                notBlank
            ])
    });

    addedLabels: LabeledEntity[] = [];
    selectedTemplate: StructureTemplate;
    folderizeTreeItems: FolderizeTreeItem[] = [];
    isProcessing = false;
    templateStructureIsLoading = false;
    templateHasErrors = false;

    constructor(
        private Modals: ModalsService,
        private modalRef: BsModalRef,
        private Binders: BindersService,
        private Notifications: NotificationsService,
        private StudyStartup: StudyStartupService,
        private Teams: TeamService,
        private CurrentSession: CurrentSessionService
    ) { }

    ngOnInit(): void {
        this.binderForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.nextButtonDisabled.next(this.progress === 2
                && this.assignStructureForm.controls.assignStructureOption.value === 'newBinder'
                && (this.binderForm.pristine || this.binderForm.invalid));
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    get f(): { [key: string]: AbstractControl } {
        return this.assignStructureForm.controls;
    }

    hideModal(): void {
        this.modalRef.hide();
    }

    setProgress(n: number): void {
        this.progress = Number(n);

        if (this.progress === 2 && this.assignStructureForm.controls.assignStructureOption.value === 'newBinder') {
            this.nextButtonDisabled.next(this.binderForm.pristine || this.binderForm.invalid);
        }
        else if (this.progress === 2 && this.assignStructureForm.controls.assignStructureOption.value === 'existingBinder') {
            this.nextButtonDisabled.next(!this.selectedExistingBinder);
        }
        else {
            this.nextButtonDisabled.next(false);
        }
    }

    incrementProgress(n: number): void {
        if (this.progress === 2 && this.assignStructureForm.controls.assignStructureOption.value === 'newBinder' && n === 1) {
            this.Binders
                .checkBinderExists(this.teamId, this.binderForm.controls.name.value.trim())
                .pipe(take(1))
                .subscribe((binder) => {
                    this.Notifications.error(`Binder name ${binder.name} is not available. Please choose another binder name.`);
                }, (error: HttpErrorResponse) => {
                    if (error.status === 404) {
                        this.setProgress(Number(this.progress) + n);
                    }
                    else if (error.error && error.error.message) {
                        this.Notifications.error(error.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                });
        }
        else {
            this.setProgress(Number(this.progress) + n);
        }
    }

    onLabelsChanged(addedLabels: LabeledEntity[]): void {
        this.addedLabels = addedLabels;
    }

    setSelectedBinder($event: Binder): void {
        this.selectedExistingBinder = $event;
        this.binderForm.controls.name.setValue('');
        this.nextButtonDisabled.next(!this.selectedExistingBinder);
    }

    onTemplateSelected(selectedTemplate: StructureTemplate): void {
        this.selectedTemplate = selectedTemplate;

        if (!selectedTemplate) {
            this.folderizeTreeItems = [];
            return;
        }

        this.templateStructureIsLoading = true;
        this.StudyStartup.getFolderStructureById(selectedTemplate.id, this.teamId)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    this.folderizeTreeItems = sortFolderStructureLexicographically(data.tree);
                    this.templateStructureIsLoading = false;
                    this.templateHasErrors = data.errExists;
                },
                (error: HttpErrorResponse) => {
                    this.templateStructureIsLoading = false;
                    this.folderizeTreeItems = [];
                    this.templateHasErrors = true;
                    const message = (error && error.error && error.error.message);
                    message ? this.Notifications.error(message) : this.Notifications.unexpectedError();
                }
            );
    }

    private openConfirmationModal(assignStructureResponse: AssignStructureTemplateResponse): void {
        this.Teams.loadById(this.teamId).subscribe((team) => {
            this.CurrentSession.setCurrentTeam(team);

            const canManageDocumentDueAndExpirationDates = team.permissions.manageDocumentDates;

            if (canManageDocumentDueAndExpirationDates && hasPlaceholderInStructure(assignStructureResponse.structure)) {
                this.Modals.show(StructureTemplateAppliedDueDatesComponent, {
                    initialState: {
                        data: assignStructureResponse,
                        showStructureTemplateAssignedModal: true
                    }
                });
            }
            else {
                this.Modals.show(StructureTemplateAssignedComponent, {
                    initialState: {
                        data: assignStructureResponse.binder
                    }
                });
            }
        });
    }

    submit(): void {
        if (this.templateHasErrors) {
            return;
        }

        this.isProcessing = true;

        const params: AssignStructureTemplateParams = {
            objectName: this.binderForm.controls.name.value.trim(),
            objectType: 'binder',
            addedLabels: this.addedLabels,
            folderId: null,
            binderId: this.selectedExistingBinder ? this.selectedExistingBinder.id : null,
            isFlat: false
        };

        this.StudyStartup.assignStructure(this.teamId, this.selectedTemplate.id, params)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    this.hideModal();
                    this.openConfirmationModal(data);
                },
                (error: HttpErrorResponse) => {
                    const message = (error && error.error && error.error.message);
                    message ? this.Notifications.error(message) : this.Notifications.unexpectedError();
                    this.isProcessing = false;
                }
            );
    }
}
