import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CurrentSessionService } from '@app/core/current-session.service';
import { BindersService } from '@app/shared/binders/binders.service';
import {
    Team,
    Binder,
    Folder,
    Document,
    BrowseTree,
    DocumentSubTypes
} from '@app/shared/models';
import { TeamService } from '@app/shared/teams/team.service';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import { BrowseParams } from '@app/shared/teams/teams.service.types';
import { VirtualTreeItemSelectedEvent, VirtualTreeSelectionMode } from '@app/widgets/virtual-tree/virtual-tree.component.types';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { sortBrowseTreeLexicographically } from '@app/widgets/sort/sort-browse-tree-lexicographically.util';

import template from './select-documents.component.html';
import styles from './select-documents.component.scss';

@Component({
    selector: 'select-documents',
    template,
    styles: [String(styles)]
})
export class SelectDocumentsComponent implements OnInit {
    @Input() initiallySelectedDocuments: Document[] = [];
    @Output() save = new EventEmitter<{
        added: Document[];
        removedIds: string[];
    }>();

    selectedInBinderTree: Document[] = [];
    highlightedItems: string[] = [];
    addedDocumentsHash: { [key: string]: Document } = {};
    removedDocumentsHash: { [key: string]: Document } = {};
    currentTeam: Team;
    binderTreeRootItems: Binder[];
    binderTreeSelectionMode = VirtualTreeSelectionMode.MULTI_SELECT;
    isBinderTreeLoading = false;
    totalSelected = 0;
    private bindersHash: { [key: string]: Binder } = {};

    constructor(
        private Teams: TeamService,
        private Binders: BindersService,
        private CurrentSession: CurrentSessionService,
        public modal: BsModalRef,
        private ApiError: ApiErrorsService
    ) {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
    }

    ngOnInit(): void {
        this.totalSelected = this.initiallySelectedDocuments.length;
        this.selectedInBinderTree = this.initiallySelectedDocuments.slice();
        this.highlightedItems = this.getHighlightedItems(this.initiallySelectedDocuments);
        this.isBinderTreeLoading = true;
        this.Binders
            .getBinders(this.currentTeam.id, { includeArchived: false })
            .toPromise()
            .then((rootItems) => {
                this.binderTreeRootItems = sortByLexicographically(rootItems, 'name');
                this.bindersHash = rootItems.reduce((acc, binder) => {
                    acc[binder.id] = binder;
                    return acc;
                }, {});
                this.isBinderTreeLoading = false;
            })
            .catch(this.ApiError.handleError);
    }

    private getHighlightedItems(documents: Document[]): string[] {
        const items = new Set<string>();
        documents.forEach((d) => {
            items.add(d.binderId);
            d.lineage.forEach((l) => items.add(l));
            items.add(d.id as string);
        });
        return Array.from(items);
    }

    private decorateWithBinderName(document: Document): Document {
        document.binderName = this.bindersHash[document.binderId].name;
        return document;
    }

    loadBinderTreeItem = (browseParams: BrowseParams): ng.IPromise<BrowseTree> => {
        return this.Teams.browse(this.currentTeam.id, { ...browseParams, includeDocs: true, includeArchived: false })
            .toPromise().then((data) => sortBrowseTreeLexicographically(data, 'name'));
    }

    isItemSelectable = (item: Binder | Folder | Document): boolean => {
        return item.type === 'document';
    }

    isItemDisabled(item) {
        return item.type !== 'shortcut' && item.isLocked;
    }

    isItemDisplayable = (item: Binder | Folder | Document): boolean => {
        let isDisplayable = true;
        if (item.type === 'document') {
            const approvalStatus = item.documentProperties
                && item.documentProperties.approval
                && item.documentProperties.approval.status;

            isDisplayable = !item.isBrokenShortcut
                && item.subType !== DocumentSubTypes.placeholder
                && approvalStatus !== 'cancelled'
                && approvalStatus !== 'rejected';
        }
        return isDisplayable;
    }

    onItemSelected = ($event: VirtualTreeItemSelectedEvent<Document>): void => {
        const addedDocument = $event.item;
        const documentId = addedDocument.id as string;

        this.addedDocumentsHash[documentId] = this.decorateWithBinderName(addedDocument);
        this.totalSelected += 1;

        if (this.removedDocumentsHash[documentId]) {
            delete this.removedDocumentsHash[documentId];
        }
    }

    onItemUnselected = ($event: VirtualTreeItemSelectedEvent<Document>): void => {
        const removedDocument = $event.item;
        const documentId = removedDocument.id as string;

        this.removedDocumentsHash[documentId] = removedDocument;
        this.totalSelected -= 1;

        if (this.addedDocumentsHash[documentId]) {
            delete this.addedDocumentsHash[documentId];
        }
    }

    onSave(): void {
        const removedIds = [];
        this.initiallySelectedDocuments.forEach((d) => {
            const documentId = d.id as string;
            if (this.addedDocumentsHash[documentId]) {
                delete this.addedDocumentsHash[documentId];
            }
            if (this.removedDocumentsHash[documentId]) {
                removedIds.push(documentId);
            }
        });
        const added = Object.values(this.addedDocumentsHash);
        if (!removedIds.length && !added.length) {
            this.modal.hide();
            return;
        }
        this.save.emit({ added, removedIds });
    }
}
