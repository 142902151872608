import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '@app/shared/shared.module';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LogTemplateFormComponent } from './components/log-template-form/log-template-form.component';
import { LogTemplateViewComponent } from './containers/log-template-view/log-template-view.component';
import { MultiChoiceFormComponent } from './components/multi-choice-form/multi-choice-form.component';
import { LogTemplateInactiveWarningComponent } from './components/log-template-inactive-warning/log-template-inactive-warning.component';
import { DuplicateLogTemplateComponent } from './components/duplicate-log-template/duplicate-log-template.component';
import { TeamLogTemplatesComponent } from './containers/team-log-templates/team-log-templates.component';
import { MultiSelectColumnFormComponent } from './components/multi-select-column-form/multi-select-column-form.component';
import { SingleSelectColumnFormComponent } from './components/single-select-column-form/single-select-column-form.component';
import { LogTemplateTypeSelectorComponent } from './components/log-template-type-selector/log-template-type-selector.component';
import { DoaLogTemplateCreateComponent } from './containers/doa-log-template-create/doa-log-template-create.component';
import { DoaLogTemplateCreateFormComponent } from './components/doa-log-template/doa-log-template-create-form/doa-log-template-create-form.component';
import { DoaLogTemplateDetailsStepComponent } from './components/doa-log-template/doa-log-template-details-step/doa-log-template-details-step.component';
import { DoaLogTemplateStudyResponsibilitiesStepComponent } from './components/doa-log-template/doa-log-template-study-responsibilities-step/doa-log-template-study-responsibilities-step.component';
import { DoaLogTemplateStudyRolesStepComponent } from './components/doa-log-template/doa-log-template-study-roles-step/doa-log-template-study-roles-step.component';
import { DoaLogTemplateCreateTemplateStepComponent } from './components/doa-log-template/doa-log-template-create-template-step/doa-log-template-create-template-step.component';
import { FormDirectivesModule } from '../../shared/forms/directives/form-directives.module';
import { DoaLogTemplateFormComponent } from './components/doa-log-template-form/doa-log-template-form.component';
import { DoaLogTemplateViewComponent } from './containers/doa-log-template-view/doa-log-template-view.component';
import { DoaLogColumnsComponent } from './components/doa-log-template/doa-log-columns/doa-log-columns.component';
import { DoaLogTemplateDetailsFormComponent } from './components/doa-log-template/doa-log-template-details-form/doa-log-template-details-form.component';
import { DoaLogTemplateRolesResponsibilitiesEditComponent } from './components/doa-log-template/doa-log-template-roles-responsibilities-edit/doa-log-template-roles-responsibilities-edit.component';
import { DoaLogTemplateFormSkeletonComponent } from './components/doa-log-template-form/ui/doa-log-template-form-skeleton.component';
import { ModalsModule } from '../../widgets/modals/modals.module';
import { StudyRolesApiService } from '../../shared/study-roles/study-roles-api/study-roles-api.service';
import { StudyRolesService } from '../../shared/study-roles/study-roles.service';
import { logTemplatesRoute } from './log-templates.routes';

@NgModule({
    imports: [
        BsDropdownModule.forRoot(),
        PaginationModule.forRoot(),
        TooltipModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        UIRouterModule.forChild({ states: logTemplatesRoute }),
        WidgetsModule,
        SharedModule,
        SortableModule.forRoot(),
        DragDropModule,
        FormDirectivesModule,
        ModalsModule
    ],
    providers: [
        StudyRolesApiService,
        StudyRolesService
    ],
    declarations: [
        TeamLogTemplatesComponent,
        LogTemplateFormComponent,
        DoaLogTemplateFormComponent,
        LogTemplateViewComponent,
        DoaLogTemplateCreateComponent,
        DoaLogTemplateCreateFormComponent,
        DoaLogTemplateDetailsStepComponent,
        DoaLogTemplateViewComponent,
        DoaLogTemplateStudyResponsibilitiesStepComponent,
        DoaLogTemplateStudyRolesStepComponent,
        DoaLogTemplateCreateTemplateStepComponent,
        MultiChoiceFormComponent,
        MultiSelectColumnFormComponent,
        SingleSelectColumnFormComponent,
        LogTemplateInactiveWarningComponent,
        DuplicateLogTemplateComponent,
        LogTemplateTypeSelectorComponent,
        DoaLogColumnsComponent,
        DoaLogTemplateDetailsFormComponent,
        DoaLogTemplateRolesResponsibilitiesEditComponent,
        DoaLogTemplateFormSkeletonComponent
    ],
    entryComponents: [
        TeamLogTemplatesComponent,
        LogTemplateFormComponent,
        DoaLogTemplateFormComponent,
        LogTemplateViewComponent,
        DoaLogTemplateViewComponent,
        DoaLogTemplateCreateComponent,
        DoaLogTemplateCreateFormComponent,
        DoaLogTemplateDetailsStepComponent,
        DoaLogTemplateStudyResponsibilitiesStepComponent,
        DoaLogTemplateStudyRolesStepComponent,
        DoaLogTemplateCreateTemplateStepComponent,
        MultiChoiceFormComponent,
        MultiSelectColumnFormComponent,
        SingleSelectColumnFormComponent,
        LogTemplateInactiveWarningComponent,
        DuplicateLogTemplateComponent,
        LogTemplateTypeSelectorComponent,
        DoaLogColumnsComponent,
        DoaLogTemplateDetailsFormComponent,
        DoaLogTemplateRolesResponsibilitiesEditComponent,
        DoaLogTemplateFormSkeletonComponent,
        LogTemplateTypeSelectorComponent
    ],
    exports: [
    ]
})
export class LogTemplatesModule { }
