import {
    Component,
    Input
} from '@angular/core';

import styles from './scrollable-box.component.scss';

@Component({
    selector: 'scrollable-box',
    template: `
        <div class="scrollable-box"
             [ngClass]="{'scrollable-box--greyBorder': greyBorder}"
             [ngStyle]="{'height.px': height}">
            <ng-content></ng-content>
        </div>
    `,
    styles: [String(styles)]
})
export class ScrollableBoxComponent {
    @Input() height = 300;
    @Input() greyBorder = false;
}
