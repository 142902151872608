import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    Connection, GetActiveConnectionsParams, GetExchangeEventParams, GetExchangeEventsResponse
} from '../document-sharing.types';

@Injectable()
export class ExchangeEventsApiService {
    readonly url = {
        exchangeEvents: (teamId: string): string => `/api/teams/${teamId}/exchange-events`,
        activeConnections: (teamId: string): string => `/api/teams/${teamId}/connections/active`
    }

    constructor(
        private http: HttpClient
    ) {}

    public getExchangeEvents(params: GetExchangeEventParams): Observable<GetExchangeEventsResponse> {
        const { teamId, ...payload } = params;

        return this.http.post<GetExchangeEventsResponse>(this.url.exchangeEvents(teamId), payload);
    }

    public getActiveConnections(params: GetActiveConnectionsParams): Observable<Connection[]> {
        const { teamId, isReceiver } = params;
        let httpParams = new HttpParams();
        httpParams = httpParams.append('isReceiver', isReceiver.toString());

        return this.http.get<Connection[]>(this.url.activeConnections(teamId), { params: httpParams });
    }
}
