import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { SortObject, ConnectedTeamsList } from '../connected-teams.types';

@Injectable()
export class ConnectedTeamsStateService {
    private originalConnectedTeamsList = new BehaviorSubject<ConnectedTeamsList[]>([]);
    originalConnectedTeamsList$ = this.originalConnectedTeamsList.asObservable();

    private connectedTeamsList = new BehaviorSubject<ConnectedTeamsList[]>([]);
    connectedTeamsList$ = this.connectedTeamsList.asObservable();

    private isLoading = new BehaviorSubject<boolean>(false);
    isLoading$ = this.isLoading.asObservable();

    private sorts = new BehaviorSubject<SortObject[]>([
        { by: 'teamName', direction: 'DESC' }
    ]);

    sorts$ = this.sorts.asObservable();

    public getOriginalConnectedTeamsList() {
        return this.originalConnectedTeamsList.getValue();
    }

    public setOriginalConnectedTeamsList(value): void {
        this.originalConnectedTeamsList.next(value);
        this.setConnectedTeamsList(value);
    }

    public getConnectedTeamsList() {
        return this.connectedTeamsList.getValue();
    }

    public setConnectedTeamsList(value): void {
        this.connectedTeamsList.next(value);
    }

    public getIsLoading(): boolean {
        return this.isLoading.getValue();
    }

    public setIsLoading(value: boolean): void {
        this.isLoading.next(value);
    }

    public filterByValue(filterValue: string): void {
        const filteredConnectedTeams = [];
        this.originalConnectedTeamsList.getValue().forEach((connectedTeam: ConnectedTeamsList) => {
            if (connectedTeam.teamName.toLowerCase().includes(filterValue.toLowerCase())) {
                filteredConnectedTeams.push(connectedTeam);
            }
        });
        this.setConnectedTeamsList(filteredConnectedTeams);
        this.sortBy(this.sorts.getValue()[0]);
    }

    public sortBy(sort): void {
        const copyOriginalConnectedTeamsList = _.cloneDeep(this.connectedTeamsList.getValue());
        this.sorts.next([sort]);
        copyOriginalConnectedTeamsList.sort((a, b) => (sort.direction === 'DESC' ? 1 : -1) * a[sort.by].localeCompare(b[sort.by]));
        this.setConnectedTeamsList(copyOriginalConnectedTeamsList);
    }
}
