'use strict';

module.exports = {
    TEXT: [
        'txt',
        'lst',
        'xfd',
        'xml'
    ],
    IMAGE: [
        'jpg',
        'jpeg',
        'tiff',
        'tif',
        'png',
        'bmp'
    ],
    PDF: [
        'pdf'
    ],
    WORD: [
        'doc',
        'dot',
        'docx',
        'docm',
        'dotx',
        'dotm',
        'docb'
    ],
    EXCEL: [
        'xls',
        'xlt',
        'xlm',
        'xlsx',
        'xlsm',
        'xltx',
        'xltm',
        'xlsb',
        'xla',
        'xlam',
        'xll',
        'xlw'
    ],
    POWERPOINT: [
        'ppt',
        'pot',
        'pps',
        'pptx',
        'pptm',
        'potx',
        'potm',
        'ppam',
        'ppsx',
        'ppsm',
        'sldx',
        'sldm'
    ]
};
