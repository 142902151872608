
import {
    Component,
    Input
} from '@angular/core';
import { ROLE_BLINDED_STATUSES } from '@app/core/constants';
import { Role } from '@app/shared/models';
import template from './blinded-status.component.html';
import styles from './blinded-status.component.scss';

@Component({
    selector: 'blinded-role-status',
    template,
    styles: [String(styles)]
})
export class BlindedRoleStatusComponent {
  @Input() role: Role;

  get roleBlindedStatuses() {
      return ROLE_BLINDED_STATUSES;
  }
}
