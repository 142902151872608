import { StateProvider } from '@uirouter/angularjs';

const reportsRoute = ($stateProvider: StateProvider): void => {

    $stateProvider.state('app.team.reports', {
        url: '/reports/:reportId',
        params: {
            reportId: {
                value: '',
                dynamic: true
            }
        },
        views: {
            '': 'reportsIndex'
        }
    });
};

reportsRoute.$inject = ['$stateProvider'];

export default reportsRoute;
