import { Project } from '@app/shared/models';

export interface NewProjectData {
    name: string;
    description: string;
}

export interface EditProjectEmitData {
    project: Project | NewProjectData;
    onSuccess: () => void;
    onError: () => void;
}

export enum EditProjectModalModes {
    CREATE = 'create',
    EDIT = 'edit',
    DUPLICATE = 'duplicate',
    UPDATE = 'update'
}
