import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import * as _ from 'lodash';

import template from './projects-icon.component.html';
import style from './projects-icon.component.scss';

@Component({
    selector: 'projects-icon',
    template,
    styles: [String(style)]
})
export class ProjectIconComponent implements OnInit {
    @Input() showTooltip?: boolean;
    @Input() tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
    @Input() state: {
        projects: {
            projectId: string;
            projectName: string;
            timelines: {
                timelineId: string;
                timelineName: string;
            }[];
        }[];
    };

    ngOnInit(): void {
        this.showTooltip = _.isUndefined(this.showTooltip) ? true : this.showTooltip;
        this.tooltipPlacement = _.isUndefined(this.tooltipPlacement) ? 'right' : this.tooltipPlacement;
    }
}
