import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { DashboardProject, Project, Timeline } from '@app/shared/models';
import {
    CreateTimelineFields,
    EditTimelineFileds,
    EditTimelineItemFileds,
    GetTimelinesParams,
    GetTimelinesResponse,
    GetTimelinesResponseItem,
    ProjectTemplateTree
} from './projects.service.types';

@Injectable()
export class ProjectsService {
    readonly url = {
        projects: (teamId: string): string => `/api/teams/${teamId}/projects`,
        projectById: (teamId: string, id: string): string => `/api/teams/${teamId}/projects/${id}`,
        projectTemplate: (teamId: string): string => `/api/teams/${teamId}/projects/template`,
        timelines: (teamId: string): string => `/api/teams/${teamId}/timelines`,
        timelinesItem: (teamId: string, itemId: string): string => `/api/teams/${teamId}/timelines/item/${itemId}`,
        timelinesItems: (teamId: string): string => `/api/teams/${teamId}/timelines/items`,
        timelinesByProject: (teamId: string, projectId: string): string => `/api/teams/${teamId}/timelines/project/${projectId}`,
        timelineItems: (teamId: string): string => `/api/teams/${teamId}/timelines/items`,
        timelineById: (teamId: string, id: string): string => `/api/teams/${teamId}/timelines/${id}`,
        timelineItem: (teamId: string, timelineId: string): string => `/api/teams/${teamId}/timelines/${timelineId}/item`
    };

    constructor(private $http: HttpClient) { }

    getAllProjects(teamId: string): Observable<Project[]> {
        return this.$http
            .get<{ projects: Project[] }>(this.url.projects(teamId))
            .pipe(pluck('projects'));
    }

    createProject(teamId: string, project: Project): Observable<Project> {
        return this.$http
            .post<{ project: Project }>(this.url.projects(teamId), project)
            .pipe(pluck('project'));
    }

    editProject(teamId: string, project: Project): Observable<Project> {
        return this.$http
            .patch<{ updatedProject: Project }>(this.url.projectById(teamId, project.id), project)
            .pipe(pluck('updatedProject'));
    }

    deleteProject(teamId: string, project: Project): Observable<void> {
        return this.$http.delete<void>(this.url.projectById(teamId, project.id));
    }

    duplicateProject(teamId: string, project: Project): Observable<Project> {
        return this.$http
            .post<{ project: Project }>(this.url.projectById(teamId, project.id), project)
            .pipe(pluck('project'));
    }

    getProject(teamId: string, id: string): Observable<Project> {
        return this.$http
            .get<{ project: Project }>(this.url.projectById(teamId, id))
            .pipe(pluck('project'));
    }

    getProjectTimelines(teamId: string, projectId: string): Observable<Timeline[]> {
        return this.$http
            .get<{ timelines: Timeline[] }>(this.url.timelinesByProject(teamId, projectId))
            .pipe(pluck('timelines'));
    }

    getFullTimeline(teamId: string, timelineId: string): Observable<Timeline> {
        const url = `${this.url.timelineById(teamId, timelineId)}?includeMetrics=true`;
        return this.$http.get<{ timeline: Timeline }>(url)
            .pipe(pluck('timeline'));
    }

    createTimeline(teamId: string, fields: CreateTimelineFields): Observable<Timeline> {
        return this.$http
            .post<{ timeline: Timeline }>(this.url.timelines(teamId), fields)
            .pipe(pluck('timeline'));
    }

    editTimeline(
        teamId: string,
        timelineId: string,
        timelineUpdate: EditTimelineFileds
    ): Observable<Timeline> {
        return this.$http
            .patch<{ timeline: Timeline }>(this.url.timelineById(teamId, timelineId), timelineUpdate)
            .pipe(pluck('timeline'));
    }

    editTimelineItem(
        teamId: string,
        timelineId: string,
        updatePayload: EditTimelineItemFileds
    ): Observable<Timeline> {
        return this.$http
            .patch<{ timeline: Timeline }>(this.url.timelineItem(teamId, timelineId), updatePayload)
            .pipe(pluck('timeline'));
    }

    deleteTimeline(teamId: string, timeline: Timeline): Observable<void> {
        const options = {
            body: { ids: [timeline.id] }
        };
        return this.$http.request<void>('DELETE', this.url.timelines(teamId), options);
    }

    duplicateTimeline(teamId: string, timeline: Timeline): Observable<Timeline> {
        return this.$http
            .post<{ timeline: Timeline }>(this.url.timelineById(teamId, timeline.id), timeline)
            .pipe(pluck('timeline'));
    }

    getDashboardProject(teamId: string, id: string): Observable<DashboardProject> {
        const options = { params: new HttpParams().set('includeDashboard', 'true') };
        return this.$http.get<Project>(this.url.projectById(teamId, id), options)
            .pipe(pluck('project'));
    }

    saveTimelineAssignments(teamId: string, itemId: string, timelineIds: string[]): Observable<Record<string, unknown>> {
        return this.$http
            .patch<Record<string, unknown>>(this.url.timelinesItem(teamId, itemId), { timelines: timelineIds });
    }

    saveBulkTimelineAssignments(teamId: string, documentIds: string[], timelineIds: string[]): Observable<[]> {
        const body = {
            timelines: timelineIds,
            itemIds: documentIds
        };
        return this.$http.patch<[]>(this.url.timelinesItems(teamId), body);
    }

    getTimelines(teamId: string, getTimelinesParams: GetTimelinesParams): Observable<GetTimelinesResponseItem[]> {
        const params = getTimelinesParams
            && Object.keys(getTimelinesParams).reduce((httpParam, param) => {
                return getTimelinesParams[param] ? httpParam.set(param, getTimelinesParams[param]) : httpParam;
            }, new HttpParams());
        return this.$http
            .get<GetTimelinesResponse>(this.url.timelines(teamId), { params })
            .pipe(pluck('timelines'));
    }

    previewProjectizer(teamId: string, template: string): Observable<ProjectTemplateTree> {
        return this.$http
            .post<ProjectTemplateTree>(this.url.projectTemplate(teamId), { template, previewOnly: true });
    }

    saveProjectizer(teamId: string, template: string): Observable<ProjectTemplateTree> {
        return this.$http
            .post<ProjectTemplateTree>(this.url.projectTemplate(teamId), { template, previewOnly: false });
    }
}
