import {
    Component,
    Input
} from '@angular/core';

@Component({
    selector: 'archive-icon',
    template: `
        <span class="fa-stack fa-xs">
            <i id="database" class="fas fa-server fa-rotate-180 fa-stack-2x {{getColorClass()}}"></i>
            <i id="circle" class="fas fa-circle fa-stack-1x fa-inverse fa-lg offset"></i>
            <i id="clock" class="fas fa-clock fa-stack-1x offset {{getColorClass()}}"></i>
        </span>
    `,
    styles: ['.offset { padding-top: 0.5rem; padding-left: 1.25rem; }']
})

export class ArchiveIconComponent {
    @Input() status: string;

    getColorClass(): string {
        switch (this.status) {
            case 'SCHEDULED':
            case 'IN_PROGRESS':
                return 'text-warning';
            case 'COMPLETE':
                return 'text-success';
            case 'FAILED':
                return 'text-danger';
            default:
        }
    }
}
