import * as angular from 'angular';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import { EntityLinkComponent } from './entity-link/entity-link.component';
import { EntityTypeIconComponent } from './entity-type-icon/entity-type-icon.component';
import { EntityTypeService } from './entity-type.service';
import { EntityPathComponent } from './entity-path/entity-path.component';
import { EntityPathService } from './entity-path.service';
import { EntityNameComponent } from './entity-name/entity-name.component';
import { HumanizedEntityListComponent } from './humanized-entity-list/humanized-entity-list.component';

const dependencies = [
];

const entity = angular
    .module('app.widgets.entity', dependencies)
    .service('EntityPath', downgradeInjectable(EntityPathService))
    .factory('EntityType', downgradeInjectable(EntityTypeService))
    .directive('entityName', downgradeComponent({ component: EntityNameComponent }) as ng.IDirectiveFactory)
    .directive('humanizedEntityList', downgradeComponent({ component: HumanizedEntityListComponent }) as ng.IDirectiveFactory)
    .directive('entityLink', downgradeComponent({ component: EntityLinkComponent }) as ng.IDirectiveFactory)
    .directive('entityTypeIcon', downgradeComponent({ component: EntityTypeIconComponent }) as ng.IDirectiveFactory)
    .directive('entityPath', downgradeComponent({ component: EntityPathComponent }) as ng.IDirectiveFactory);

export default entity;
