import { StateProvider, StateService } from '@uirouter/angularjs';
import { CurrentSessionService } from '../../core/current-session.service';
import { NotificationsService } from '../../core/notifications/notifications.service';


const studyManagerRouteGuard = {
    checkStudyStartupPermissions: [
        'CurrentSession',
        '$state',
        'Notifications',
        function checkstudyManagerPermissions(
            CurrentSession: CurrentSessionService,
            $state: StateService,
            Notifications: NotificationsService
        ): Promise<void> {
            const { permissions, id } = CurrentSession.getCurrentTeam();
            const hasAccess = permissions.manageTeam
                || (
                    permissions.createBinder
                    && permissions.createTeamStudies
                    && permissions.importTeamStudyStructureTemplate
                    && permissions.createFolder
                    && permissions.labelAssign
                    && permissions.createTags
                    && permissions.labelCreate
                    && permissions.labelEdit
                    && permissions.folderImportTemplates
                    && permissions.manageTeamRolesAndPermissions
                    && permissions.viewTeamUsersRolesPermissions
                    && permissions.renameBinder
                    && permissions.renameFolder
                    && permissions.viewBinder
                    && permissions.viewNonPiiInDocument
                    && permissions.createEditTeamStudyProfiles
                    && permissions.viewTeamStudyProfiles
                    && permissions.assignTeamRoles
                );

            if (!hasAccess) {
                Notifications.error('User does not have permissions to perform this action');
                $state.go('app.team.binders', { teamId: id });
                return Promise.reject();
            }
            return Promise.resolve();
        }]
};


const studyManagerRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.study-manager', {
        url: '/study-manager?&sortBy&sortDir&pageNum&filterBy&type',
        views: {
            '': 'studyManager'
        },
        params: {
            sortBy: { dynamic: true },
            sortDir: { dynamic: true },
            pageNum: { dynamic: true },
            filterBy: { dynamic: true },
            type: { dynamic: true }
        },
        resolve: studyManagerRouteGuard
    });
    $stateProvider.state('app.team.study-manager-create', {
        url: '/study-manager/create-study',
        views: {
            '': 'createStudy'
        },
        params: {
            teamConfig: null
        },
        resolve: studyManagerRouteGuard
    });
    $stateProvider.state('app.team.study-manager-update', {
        url: '/study-manager/update-study',
        views: {
            '': 'updateStudy'
        },
        params: {
            teamConfig: null
        },
        resolve: studyManagerRouteGuard
    });
};

studyManagerRoute.$inject = ['$stateProvider'];
export default studyManagerRoute;
