import {
    Component, Output, EventEmitter, Input, OnChanges
} from '@angular/core';
import { FolderizeTreeItem } from '@app/shared/folders/folders.service.types';
import { FileIconClass } from '@app/shared/documents/get-icon-class-from-file-extension.util';

import { StudyTemplateFilePreviewResponse } from '../../study-startup.service.types';

import template from './import-structure.component.html';
import styles from './import-structure.component.scss';


export type ImportStructureFileInfo = { iconClass: FileIconClass; fileName: string; error: string };
@Component({
    selector: 'import-structure',
    template,
    styles: [String(styles)]
})
export class ImportStructureComponent implements OnChanges {
    @Input() filePreview: StudyTemplateFilePreviewResponse = null;
    @Input() filePreviewIsLoading = false;
    @Input() teamTimezone: string = null;
    @Input() fileInfo: ImportStructureFileInfo = null;
    @Output() fileChange = new EventEmitter<File>();
    errorsCount = 0;

    ngOnChanges(): void {
        if (this.filePreview && this.filePreview.errExists) {
            this.errorsCount = this.getErrorsCountFromPreview(this.filePreview.tree);
        }
        else {
            this.errorsCount = 0;
        }
    }

    onFilesAdded($event: FileList): void {
        this.fileChange.emit($event.item(0));
    }

    clearFile(): void {
        this.fileChange.emit(null);
    }

    private getErrorsCountFromPreview(tree: FolderizeTreeItem[]): number {
        return tree.reduce((acc, node) => {
            let currentCount = acc + node.errors.length;
            if (node.items && node.items.length) {
                currentCount += this.getErrorsCountFromPreview(node.items);
            }
            return currentCount;
        }, 0);
    }
}
