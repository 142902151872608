import {
    Component
} from '@angular/core';
import {
    AbstractControl, FormArray, FormBuilder, Validators, FormControl
} from '@angular/forms';
import { StateService } from '@uirouter/angular';
import {
    Team, Crumb, Paywall
} from '@app/shared/models';
import { filter, finalize, tap } from 'rxjs/operators';
import { CurrentSessionService } from '@app/core/current-session.service';
import { StudiesService } from '@app/shared/studies/studies.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { MESSAGES, REGEX } from '@app/core/constants';
import * as CustomValidators from '@app/core/form-validators';

import { FeatureFlagService } from '@app/core/feature-flag.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { messages } from './monitor-group-form.notifications';
import template from './monitor-group-form.component.html';
import styles from './monitor-group-form.component.scss';

@Component({
    selector: 'monitor-group-form',
    template,
    styles: [String(styles)]
})
export class MonitorGroupFormComponent {
    currentTeam: Team;
    crumbs: Crumb[];
    nameValidationErrMessage = MESSAGES.validShortNameMessage;
    nameTakenErrMessage = messages.monitorGroupNameTakenErr;
    domainValidationErrMessage = messages.domainValidationErrMessage;
    domainTakenErrMessage = messages.domainTakenErrMessage;
    monitorGroup: Paywall | null = null;
    monitorGroupsNames: string[] = [];
    isDuplicate: boolean;
    isLoading = true;
    domainsChanged = true;
    searchInProgress = false;
    proposedDomains: string[] = [];
    availableDomains: string[];
    studyProfileEntryNavMenuLocation = false
    monitorGroupForm = this.fb.group({
        name: ['', [
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern(REGEX.names)
        ]],
        domains: this.fb.array([], [
            Validators.required,
            Validators.minLength(1)
        ])
    });

    domainsInput = new FormControl('', [
        Validators.pattern(REGEX.domain),
        CustomValidators.noDuplicates(this.ctrlsDomains)
    ]);

    constructor(
        private $state: StateService,
        private CurrentSession: CurrentSessionService,
        private Studies: StudiesService,
        private Notifications: NotificationsService,
        private fb: FormBuilder,
        private FeatureFlags: FeatureFlagService
    ) {
        this.isDuplicate = this.$state.params.isDuplicate === 'true';
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.getMonitorGroupNames(this.$state.params.monitorGroupId);
        this.getMonitorGroup(this.$state.params.monitorGroupId);
    }

    initialize(): void {
        this.getStudyProfileEntryFlag();
        this.initializeForm(this.monitorGroup);
    }

    getStudyProfileEntryFlag() {
        this.FeatureFlags.getFlag(FEATURE_FLAGS.STUDY_PROFILE_SIDEMENU_ENTRY, false).pipe(
            filter((flag) => {
                return flag !== undefined;
            })
        ).subscribe((value) => {
            this.studyProfileEntryNavMenuLocation = value;
            this.crumbs = this.getCrumbs(this.monitorGroup);
        });
    }

    getMonitorGroup(monitorGroupId: string): void {
        if (!monitorGroupId) {
            this.monitorGroup = null;
            this.initialize();
            this.isLoading = false;
            return;
        }
        this.Studies
            .getMonitorGroup(this.currentTeam.id, monitorGroupId)
            .pipe(finalize(() => {
                this.initialize();
                this.isLoading = false;
            }))
            .subscribe((monitorGroup) => {
                this.monitorGroup = monitorGroup;
                if (this.isDuplicate) {
                    this.monitorGroup.studies = [];
                }
            }, () => {
                this.monitorGroup = null;
            });
    }

    getMonitorGroupNames(monitorGroupId: string): void {
        this.Studies
            .getTeamMonitorGroups(this.currentTeam.id)
            .pipe(finalize(() => this.initialize()))
            .subscribe((monitorGroups) => {
                const [currentMonitorGroup] = [...monitorGroups].filter((monitorGroup) => monitorGroup.id === monitorGroupId);
                const providedMonitorGroupName = !this.isDuplicate && currentMonitorGroup ? currentMonitorGroup.name : '';
                this.monitorGroupsNames = monitorGroups.map((monitorGroup) => monitorGroup.name)
                    .filter((monitorGroupName) => monitorGroupName !== providedMonitorGroupName);
            });
    }

    isMonitorGroupNameTaken(name: string): boolean {
        return this.monitorGroupsNames.some((monitorGroupName) => monitorGroupName === name);
    }

    initializeForm(monitorGroup: Paywall): void {
        if (!monitorGroup) {
            return;
        }
        if (!this.isDuplicate) {
            this.monitorGroupForm.patchValue({ name: monitorGroup.name });
        }
        monitorGroup.domains.forEach((domain) => {
            this.addDomain(domain);
        });
        this.monitorGroupForm.markAsPristine();
    }

    get ctrls(): { [key: string]: AbstractControl } {
        return this.monitorGroupForm.controls;
    }

    get ctrlsDomains(): FormArray {
        return this.ctrls.domains as FormArray;
    }

    canAddDomain(): boolean {
        return !!this.domainsInput.value && this.domainsInput.valid;
    }

    addDomains(): void {
        const domains = this.parseDomainsInput(this.domainsInput.value);
        domains.forEach((domain) => {
            this.addDomain(domain);
        });
        this.domainsInput.reset();
        this.proposedDomains = [];
    }

    addDomain(domain: string): void {
        const domainIsDuplicate = this.isDomainDuplicate(domain);
        if (domainIsDuplicate) {
            return;
        }
        this.ctrlsDomains.push(new FormControl(domain, [
            Validators.required,
            Validators.pattern(REGEX.domain),
            Validators.maxLength(50)
        ]));
        this.monitorGroupForm.markAsDirty();
        this.domainsChanged = true;
    }

    domainClick(domain: string): void {
        this.addDomain(domain);
        this.domainsInput.reset();
        this.proposedDomains = [];
    }

    isDomainDuplicate(domain: string): boolean {
        const isDuplicate = this.ctrlsDomains.controls
            .filter((existingControl) => existingControl.value === domain);
        return !!isDuplicate.length;
    }

    removeDomain(ctrls: FormArray, index: number): void {
        ctrls.removeAt(index);
        this.monitorGroupForm.markAsDirty();
        this.domainsChanged = true;
    }

    searchDomains(value: string): void {
        if (!value) {
            this.proposedDomains = [];
            return;
        }
        this.Studies
            .getTeamMonitorGroupsDomains(this.currentTeam.id, value)
            .subscribe((domains) => {
                this.proposedDomains = domains;
                this.searchInProgress = true;
            }, () => {
                this.proposedDomains = [];
                this.searchInProgress = true;
            });
    }

    displayDomains(): string[] {
        if (!this.domainsChanged && !this.searchInProgress) {
            return this.availableDomains;
        }
        const usedDomains = this.ctrlsDomains.controls.map((control) => control.value);
        this.availableDomains = this.proposedDomains.filter((item) => !usedDomains.includes(item));
        this.domainsChanged = false;
        this.searchInProgress = false;
        return this.availableDomains;
    }

    parseDomainsInput(domains: string): string[] {
        return domains.split(',').map((i) => i.trim());
    }

    onPaste(event: ClipboardEvent): void {
        this.domainsInput.setValue(event.clipboardData.getData('text'));
        this.addDomains();
        this.domainsInput.reset();
        event.preventDefault();
    }

    ctrlHasError(ctrl: AbstractControl): boolean {
        return ctrl.invalid && (ctrl.dirty || ctrl.touched);
    }

    domainsHaveError(): boolean {
        return this.ctrlsDomains.controls.some((control) => control.invalid);
    }

    private getCrumbs(group: Paywall): Crumb[] {
        const crumbs = [];
        if (this.studyProfileEntryNavMenuLocation) {
            crumbs.push({
                name: 'Studies',
                stateName: 'app.team.manage-studies-by-team',
                stateParams: { teamId: this.currentTeam.teamId }
            });
        }
        else {
            crumbs.push({
                name: 'Team Settings',
                stateName: 'app.team.manage-team-studies',
                stateParams: { teamId: this.currentTeam.teamId }
            });
        }

        crumbs.push({
            name: 'Manage Monitor Groups',
            stateName: 'app.team.manage-monitor-groups',
            stateParams: { teamId: this.currentTeam.id }
        });

        if (group && group.name && !this.isDuplicate) {
            crumbs.push({
                name: group.name,
                stateName: 'app.team.monitor-group-form',
                stateParams: { teamId: this.currentTeam.teamId }
            });
        }
        else {
            crumbs.push({
                name: this.isDuplicate ? 'Duplicate Monitor Group' : 'Create Monitor Group',
                stateName: 'app.team.monitor-group-form',
                stateParams: { teamId: this.currentTeam.teamId }
            });
        }
        return crumbs;
    }

    saveDisabled(): boolean {
        return this.isMonitorGroupNameTaken(this.ctrls.name.value)
            || this.monitorGroupForm.pristine || this.monitorGroupForm.invalid;
    }

    onSave(): void {
        if (!this.$state.params.monitorGroupId || this.isDuplicate) {
            this.Studies
                .createMonitorGroup(this.currentTeam.id, this.monitorGroupForm.value)
                .subscribe({
                    next: () => {
                        this.Notifications.success(messages.monitorGroupCreated);
                        this.$state.go('app.team.manage-monitor-groups', {});
                    },
                    error: () => {
                        this.Notifications.error(messages.monitorGroupCreatedErr);
                    }
                });
            return;
        }
        this.Studies
            .updateMonitorGroup(this.currentTeam.id, this.$state.params.monitorGroupId, this.monitorGroupForm.value)
            .subscribe({
                next: () => {
                    this.Notifications.success(messages.monitorGroupUpdated);
                    this.$state.go('app.team.manage-monitor-groups', {});
                },
                error: ({ error }) => {
                    const message = error && error.statusCode >= 500 ? messages.unknownErr : messages.monitorGroupUpdatedErr;
                    this.Notifications.error(message);
                }
            });
    }
}
