import { Component, Input } from '@angular/core';

@Component({
    selector: 'document-locked-watermark',
    template: `
    <svg width="635" height="421" viewBox="0 0 635 421" overflow="auto" fill="none" xmlns="http://www.w3.org/2000/svg" class="test-svg">
        <g opacity="0.4" class="test-g">
            <path d="M85.4238 301.849L50.02 291.679L63.7954 316.629L85.007 304.917C85.0985 303.888 85.2374 302.865 85.4238 301.849Z" fill="#D4D4D4"/>
            <path d="M102.976 379.279L85.9845 348.505C83.9659 344.838 83.2931 340.579 84.0822 336.463C84.8714 332.348 87.0729 328.633 90.3074 325.96L88.9266 323.459C87.1117 320.177 85.8805 316.605 85.2875 312.901L67.4151 322.769C63.7808 324.776 59.4478 323.531 57.5429 320.081L43.7676 295.131L9.37876 314.118C6.89136 315.491 5.05008 317.794 4.25999 320.52C3.4699 323.246 3.79571 326.171 5.16576 328.652L49.9357 409.739C51.3057 412.22 53.6077 414.055 56.3354 414.838C59.063 415.622 61.9928 415.29 64.4802 413.917L112.472 387.419C108.426 385.939 105.053 383.049 102.976 379.279Z" fill="#D4D4D4"/>
            <path d="M98.6453 328.132L94.3975 320.438C92.6862 317.405 91.5942 314.062 91.1847 310.602C90.7752 307.143 91.0562 303.635 92.0115 300.281C92.9669 296.928 94.5776 293.796 96.7507 291.065C98.9238 288.334 101.616 286.06 104.672 284.372C107.729 282.685 111.088 281.618 114.557 281.234C118.025 280.85 121.534 281.155 124.881 282.133C128.228 283.111 131.346 284.742 134.056 286.932C136.766 289.122 139.013 291.827 140.668 294.891L144.916 302.585L147.487 301.165C150.215 299.662 153.426 299.3 156.415 300.158C159.405 301.017 161.929 303.026 163.434 305.744L180.425 336.518C181.924 339.239 182.279 342.445 181.413 345.433C180.546 348.42 178.529 350.945 175.804 352.452L124.391 380.839C121.664 382.342 118.453 382.705 115.463 381.846C112.473 380.987 109.95 378.978 108.446 376.259L91.4554 345.485C89.9547 342.764 89.5982 339.558 90.4643 336.57C91.3303 333.582 93.348 331.058 96.0739 329.551L98.6453 328.132ZM108.929 322.454L134.64 308.258L130.392 300.565C128.515 297.164 125.36 294.65 121.622 293.577C117.884 292.503 113.869 292.957 110.46 294.839C107.052 296.721 104.528 299.877 103.446 303.612C102.363 307.348 102.809 311.357 104.687 314.757L108.929 322.454Z" fill="#D4D4D4"/>
            <path d="M207.514 334.945L156.037 241.711L174.159 231.706L216.862 309.05L264.398 282.805L273.171 298.694L207.514 334.945Z" fill="#D4D4D4"/>
            <path d="M308.235 281.048C302.632 284.141 297.067 285.957 291.539 286.496C286.01 287.036 280.776 286.556 275.837 285.057C270.936 283.422 266.47 280.918 262.438 277.547C258.406 274.176 255.085 270.127 252.475 265.399C249.817 260.585 248.135 255.573 247.43 250.365C246.725 245.157 247.01 240.088 248.286 235.157C249.601 230.09 251.959 225.361 255.36 220.97C258.761 216.579 263.263 212.837 268.866 209.744C274.469 206.65 279.991 204.858 285.431 204.368C290.959 203.828 296.174 204.376 301.074 206.011C306.014 207.51 310.5 209.945 314.532 213.317C318.563 216.688 321.908 220.781 324.567 225.596C327.177 230.323 328.835 235.291 329.54 240.499C330.245 245.707 329.984 250.82 328.756 255.838C327.481 260.769 325.142 265.43 321.741 269.821C318.34 274.212 313.837 277.954 308.235 281.048ZM270.597 255.394C272.288 258.458 274.273 261.018 276.55 263.073C278.866 264.993 281.32 266.437 283.911 267.405C286.589 268.325 289.312 268.706 292.081 268.548C294.801 268.303 297.431 267.479 299.97 266.078C302.508 264.676 304.606 262.89 306.263 260.718C307.872 258.459 308.956 255.976 309.517 253.267C310.165 250.51 310.226 247.621 309.7 244.599C309.261 241.529 308.196 238.461 306.504 235.397C304.861 232.421 302.857 229.929 300.492 227.922C298.215 225.867 295.737 224.379 293.059 223.459C290.468 222.49 287.813 222.129 285.092 222.374C282.324 222.532 279.67 223.312 277.131 224.714C274.593 226.115 272.519 227.946 270.91 230.205C269.302 232.463 268.173 234.971 267.525 237.728C266.964 240.436 266.903 243.326 267.342 246.396C267.868 249.418 268.953 252.418 270.597 255.394Z" fill="#D4D4D4"/>
            <path d="M328.098 223.475C325.44 218.66 323.758 213.648 323.053 208.44C322.348 203.233 322.677 198.139 324.04 193.16C325.403 188.181 327.786 183.496 331.187 179.105C334.588 174.714 339.09 170.971 344.693 167.878C352.222 163.721 359.507 161.812 366.548 162.151C373.676 162.442 380.004 164.489 385.53 168.291L371.228 183.042C368.338 181.324 365.305 180.486 362.129 180.526C358.904 180.479 355.803 181.277 352.827 182.92C350.288 184.322 348.215 186.152 346.606 188.411C344.949 190.583 343.797 193.047 343.148 195.803C342.539 198.424 342.454 201.27 342.893 204.34C343.419 207.362 344.528 210.405 346.22 213.469C347.911 216.533 349.94 219.069 352.304 221.076C354.669 223.083 357.147 224.571 359.738 225.539C362.416 226.459 365.139 226.84 367.908 226.683C370.628 226.437 373.214 225.638 375.665 224.284C378.817 222.544 381.271 220.161 383.027 217.136C384.871 214.061 385.689 211.096 385.483 208.24L405.586 203.995C406.122 210.553 404.595 216.993 401.004 223.317C397.413 229.64 391.809 234.904 384.193 239.109C378.59 242.203 373.025 244.019 367.497 244.558C361.969 245.097 356.735 244.617 351.795 243.118C346.807 241.532 342.253 239.077 338.133 235.754C334.053 232.295 330.708 228.202 328.098 223.475Z" fill="#D4D4D4"/>
            <path d="M468.619 190.782L432.201 171.994L427.894 186.537L439.205 207.022L421.609 216.738L368.682 120.878L386.278 111.163L418.397 169.335L428.862 123.12L447.64 112.752L438.129 156.042L487.397 180.414L468.619 190.782Z" fill="#D4D4D4"/>
            <path d="M519.936 164.162C514.421 167.207 508.923 169.043 503.444 169.67C497.916 170.209 492.682 169.729 487.742 168.23C482.802 166.731 478.272 164.32 474.153 160.997C470.121 157.626 466.8 153.576 464.19 148.849C461.484 143.947 459.778 138.891 459.073 133.684C458.319 128.388 458.624 123.251 459.987 118.272C461.302 113.205 463.66 108.476 467.062 104.085C470.55 99.6457 475.096 95.8793 480.699 92.7859C486.302 89.6925 491.823 87.9004 497.264 87.4096C502.792 86.8705 507.982 87.3746 512.834 88.922C517.774 90.421 522.216 92.8803 526.161 96.2999C530.192 99.6712 533.489 103.677 536.051 108.316C536.679 109.455 537.24 110.573 537.732 111.672C538.312 112.722 538.708 113.646 538.919 114.443L485.737 143.806C487.498 146.375 489.484 148.42 491.694 149.941C493.905 151.462 496.253 152.508 498.738 153.077C501.223 153.647 503.733 153.746 506.269 153.374C508.805 153.002 511.211 152.188 513.488 150.931C516.989 148.997 519.813 146.353 521.959 142.998C524.143 139.507 525.032 136.047 524.623 132.617L542.044 128.482C542.405 135.136 540.727 141.717 537.01 148.224C533.331 154.596 527.64 159.909 519.936 164.162ZM514.884 112.807C511.595 107.883 507.397 104.832 502.291 103.653C497.225 102.338 492.284 103.01 487.469 105.668C485.105 106.973 483.115 108.644 481.497 110.679C479.919 112.579 478.752 114.708 477.998 117.066C477.244 119.424 476.922 121.944 477.031 124.625C477.228 127.258 477.94 129.892 479.167 132.527L514.884 112.807Z" fill="#D4D4D4"/>
            <path d="M591.192 124.82C586.464 127.43 581.58 128.928 576.538 129.313C571.496 129.698 566.636 129.068 561.959 127.424C557.281 125.78 552.927 123.273 548.895 119.901C544.903 116.394 541.529 112.146 538.774 107.156C536.019 102.166 534.201 97.1151 533.321 92.0038C532.528 86.8442 532.595 81.8957 533.52 77.1582C534.446 72.4207 536.283 68.0936 539.032 64.1768C541.782 60.26 545.389 57.0691 549.853 54.604C554.931 51.8006 560.16 50.5128 565.54 50.7406C571.008 50.9201 575.968 52.3512 580.419 55.034L558.813 15.9023L576.409 6.18701L618.17 81.8241C619.185 83.6625 620.217 84.8065 621.265 85.256C622.312 85.7056 623.605 85.5633 625.141 84.8291L633.406 99.799C630.681 102.103 628.355 103.787 626.429 104.851C623.278 106.591 620.26 107.229 617.376 106.765C614.493 106.301 612.219 104.872 610.555 102.478L607.768 98.3621C607.646 104.141 606.025 109.377 602.904 114.07C599.823 118.627 595.919 122.21 591.192 124.82ZM587.522 107.313C589.186 106.394 590.748 105.189 592.209 103.697C593.621 102.118 594.782 100.392 595.69 98.5198C596.598 96.6476 597.234 94.697 597.599 92.668C598.051 90.5906 598.125 88.5511 597.819 86.5494L588.756 70.1351C586.993 68.5958 585.019 67.4008 582.836 66.5501C580.692 65.5634 578.474 64.9603 576.183 64.7406C573.93 64.385 571.692 64.3646 569.466 64.6792C567.329 64.9456 565.385 65.5621 563.634 66.5288C561.008 67.9788 558.871 69.9012 557.223 72.296C555.663 74.6424 554.65 77.2574 554.186 80.1409C553.674 82.9369 553.681 85.8459 554.207 88.868C554.733 91.8901 555.746 94.758 557.244 97.4719C558.839 100.361 560.795 102.765 563.111 104.685C565.427 106.604 567.925 108.024 570.603 108.944C573.282 109.864 576.049 110.221 578.905 110.015C581.849 109.76 584.721 108.86 587.522 107.313Z" fill="#D4D4D4"/>
        </g>
    </svg>
    `
})
export class DocumentLockedWatermarkComponent { }
