import { Component } from '@angular/core';

@Component({
    selector: 'page-info',
    template: `
    <div class="u-d-flex">
        <span>
            <i class="fa fa-lg fa-info-circle pl-1"></i>
        </span>
        <span class="pl-4">
            <ng-content></ng-content>
        </span>
    </div>
  `
})
export class PageInfoComponent {}
