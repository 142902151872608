import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

import template from './toggle.component.html';
import styles from './toggle.component.scss';

@Component({
    selector: 'toggle',
    template,
    styles: [String(styles)]
})
export class ToggleComponent {
    @Input() label: string;
    @Input() checked: boolean;
    @Input() disabled: boolean;
    @Output() toggle = new EventEmitter<void>();

    toggleOnOff(): void {
        this.toggle.emit();
    }
}
