import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit
} from '@angular/core';
import { Digest } from '@app/shared/models';

import {
    Frequency, DaysRanges, Periods, FriendlyDaysSelection
} from '../../notifications.types';
import template from './notification-settings.component.html';
import styles from './notification-settings.component.scss';

@Component({
    selector: 'notification-settings',
    template,
    styles: [String(styles)]
})
export class NotificationSettingsComponent implements OnInit {
    @Input() digest: Digest;
    @Output() updatedDigest = new EventEmitter<Digest>();
    @Output() dismiss = new EventEmitter<string>();

    options = {
        frequencies: [Frequency.daily, Frequency.weekly, Frequency.monthly],
        days: {
            ranges: [FriendlyDaysSelection.everyDay, FriendlyDaysSelection.weekdays],
            singles: [
                FriendlyDaysSelection.monday,
                FriendlyDaysSelection.tuesday,
                FriendlyDaysSelection.wednesday,
                FriendlyDaysSelection.thursday,
                FriendlyDaysSelection.friday,
                FriendlyDaysSelection.saturday,
                FriendlyDaysSelection.sunday
            ]
        },
        hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        periods: [Periods.am, Periods.pm]
    }

    selection: {
        frequency: string | undefined;
        day: FriendlyDaysSelection | undefined;
        period: string | undefined;
        hour: number | undefined;
    } = {
        frequency: undefined,
        day: undefined,
        period: undefined,
        hour: undefined
    };

    ngOnInit(): void {
        this.initializeOptionSelections();
    }

    initializeOptionSelections(): void {
        this.initializeFrequency();
        this.initializeDay();
        this.initiateHourAndPeriod();
    }

    initializeFrequency(): void {
        const frequency = this.digest.notificationFrequency || Frequency.daily;
        this.selection.frequency = frequency;
    }

    initializeDay(): void {
        if (this.selection.frequency === Frequency.monthly) {
            this.selection.day = undefined;
            return;
        }
        const days = this.digest.frequencySettings.days || DaysRanges.everyDay;
        if (days.length === DaysRanges.everyDay.length) {
            this.selection.day = FriendlyDaysSelection.everyDay;
            return;
        }
        if (days.length === DaysRanges.weekdays.length) {
            this.selection.day = FriendlyDaysSelection.weekdays;
            return;
        }
        const [dayNumberValue] = days;
        const dayStringValue = this.options.days.singles[dayNumberValue - 1];
        this.selection.day = dayStringValue;
    }

    initiateHourAndPeriod(): void {
        const militaryHours = this.digest.frequencySettings.hour || 0;
        this.selection.period = militaryHours >= 12 ? Periods.pm : Periods.am;
        this.selection.hour = (militaryHours % 12) || 12;
    }

    selectOption(option: string, value: string): void {
        if (option === 'frequency') {
            this.selection.day = undefined;
        }
        this.selection[option] = undefined;
        if (option === 'hour') {
            this.selection[option] = +value.slice(0, -3);
            return;
        }
        this.selection[option] = value;
    }

    isOptionSelected(option: string, value: string): boolean {
        return this.selection[option] === value;
    }

    isSetDisabled(): boolean {
        let isSetDisabled = false;
        const shouldSkipCheck = this.selection.frequency === Frequency.monthly;
        Object.keys(this.selection).forEach((option) => {
            if (option === 'day' && shouldSkipCheck) {
                return;
            }
            if (!this.selection[option]) {
                isSetDisabled = true;
            }
        });
        return isSetDisabled;
    }

    set(): void {
        const days = this.resolveDaysValue();
        const hour = this.resolveHoursValue();
        const updatedDigest = this.digest;
        updatedDigest.notificationFrequency = this.selection.frequency;
        updatedDigest.frequencySettings = {
            hour,
            days
        };
        this.updatedDigest.emit(updatedDigest);
        this.dismiss.emit(updatedDigest.id);
    }

    resolveDaysValue(): number[] {
        if (this.selection.frequency === Frequency.monthly) {
            return [1];
        }
        if (this.selection.day === FriendlyDaysSelection.everyDay) {
            return DaysRanges.everyDay;
        }
        if (this.selection.day === FriendlyDaysSelection.weekdays) {
            return DaysRanges.weekdays;
        }
        return [+this.options.days.singles.indexOf(this.selection.day) + 1];
    }

    resolveHoursValue(): number {
        const timeIncrement = this.selection.period === Periods.pm ? 12 : 0;
        return (this.selection.hour % 12) + timeIncrement;
    }

    close(): void {
        this.dismiss.emit(this.digest.id);
    }
}
