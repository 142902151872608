import * as _ from 'lodash';
import { Component, Input, OnInit } from '@angular/core';
import { SORT } from '@app/core/constants';
import { SIGNING_STATUS } from '@florencehealthcare/florence-constants';
import { Document, Signature } from '@app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ColumnConfig, Sorter } from '@app/widgets/tables/generic-table/generic-table.component.types';
import { DocumentService } from '@app/shared/documents/document.service';
import styles from './document-signatures.component.scss';
import template from './document-signatures.component.html';

@Component({
    selector: 'document-signatures',
    template,
    styles: [String(styles)]
})
export class DocumentSignaturesComponent implements OnInit {
    @Input() doc: Document;
    @Input() signatures: Signature[];

    currentVersion: number;

    currentSignatures: Signature[];
    previousSignatures: Signature[];
    loadedSignatures: boolean;
    activeTab = 0;

    tableConfig: ColumnConfig[];
    currentSorter: Sorter;
    previousSorter: Sorter;

    SORT = SORT;

    constructor(
        private Documents: DocumentService,
        private modalRef: BsModalRef
    ) {}

    ngOnInit(): void {
        this.currentVersion = this.Documents.getCurrentDocDisplayVersion(this.doc);
        this.createTableConfig();
        this.organizeSignatures();
    }

    setActiveTab(index: number) {
        this.activeTab = index;
    }

    private organizeSignatures() {

        this.currentSignatures = [];
        this.previousSignatures = [];

        this.signatures.forEach((signature: Signature) => {

            if (signature.status === SIGNING_STATUS.pending) {
                return;
            }

            if (signature.version === this.currentVersion) {
                this.currentSignatures.push(signature);
            }
            else if (signature.version < this.currentVersion) {
                this.previousSignatures.push(signature);
            }
        });

        this.loadedSignatures = true;
    }

    private createTableConfig(): void {
        this.tableConfig = [
            {
                renderType: 'text',
                headerName: 'Version',
                displayProperty: 'version',
                isSortable: true,
                widthValue: 1,
                widthClass: ''
            },
            {
                renderType: 'text',
                headerName: 'Signer',
                displayProperty: 'user.name',
                isSortable: true,
                widthValue: 2,
                widthClass: ''
            },
            {
                renderType: 'text',
                headerName: 'Reason',
                displayProperty: 'reason',
                isSortable: true,
                widthValue: 2,
                widthClass: ''
            },
            {
                renderType: 'date',
                headerName: 'Signed At',
                displayProperty: 'timestamp',
                isSortable: true,
                widthValue: 2,
                dateOnly: true,
                widthClass: ''
            }
        ];

        this.currentSorter = {
            defaultSortProperty: 'timestamp',
            SORT: _.clone(this.SORT)
        };

        this.previousSorter = {
            defaultSortProperty: 'timestamp',
            SORT: _.clone(this.SORT)
        };
    }

    cancel(): void {
        this.modalRef.hide();
    }
}
