// This file exists because date-time-picker doesn't have the option to work with
// UTC dates exclusively.

import { moveRelativeToUTC } from './move-relative-to-utc';

/**
 * Create dateB from dateA so that dateB UTC time is the same as
 * dateA local time.
 * This way, when dateB is converted to UTC and sent to the server,
 * it will have the value of dateA local time.
 * In other words, converting from local to UTC is counteracted.
 * @param {Date|string} value - dateA
 * @returns {Date|string} - dateB
 */
export const moveAwayFromUTC = (value: Date | string): Date | string => {
    return moveRelativeToUTC(value, -1);
};
