import {
    Component, Input
} from '@angular/core';
import { FolderizeTreeItem } from '@app/shared/folders/folders.service.types';
import { BsModalRef } from 'ngx-bootstrap/modal';

import template from './imported-structure-preview.component.html';
import styles from './imported-structure-preview.component.scss';

@Component({
    selector: 'imported-structure-preview',
    template,
    styles: [String(styles)]
})
export class ImportedStructurePreviewComponent {
    @Input() folderizeTreeItems: FolderizeTreeItem[];
    @Input() teamTimezone: string = null;

    constructor(
        private modalRef: BsModalRef
    ) { }

    hideModal(): void {
        this.modalRef.hide();
    }
}
