import * as moment from 'moment-timezone';
import { forkJoin, of } from 'rxjs';
import {
    distinctUntilChanged, filter, switchMap, catchError, take
} from 'rxjs/operators';

import { CurrentSessionService } from '@app/core/current-session.service';
import { PasswordOrPinExpiryService } from '@app/shared/password-or-pin-expiry/password-or-pin-expiry.service';
import { SystemNotificationsStoreService } from '@app/shared/system-notifications/system-notifications-store.service';
import { User, Team, SystemNotification } from '@app/shared/models';
import { PasswordExpiryReminderComponent } from '@app/components/password-policy/components/password-expiry-reminder.modal/password-expiry-reminder.component';
import { SystemNotificationComponent } from '@app/components/system-notifications/containers/system-notification/system-notification.component';
import { SigningPinExpiryNotificationComponent } from '@app/components/signing-pin-policy/containers/signing-pin-expiry-notification/signing-pin-expiry-notification.component';
import { Inject, Injectable } from '@angular/core';
import { UsersService } from '../users/users.service';
import { ModalStackHelperService } from '../modal-helper/modal-stack-helper.service';
import { ModalsService } from '../modal-helper/modals.service';

@Injectable()
export class LoginAlertsService {
    private alertInfoLocalStorageKey = 'last_login_alert_showed_time';

    constructor(
        @Inject('Window') private window: Window,
        private CurrentSession: CurrentSessionService,
        private Users: UsersService,
        private modalsService: ModalsService,
        private PasswordOrPinExpiry: PasswordOrPinExpiryService,
        private SystemNotificationsStore: SystemNotificationsStoreService,
        private modalStackHelper: ModalStackHelperService
    ) {
        this.CurrentSession.currentUser$.pipe(
            distinctUntilChanged((previousUser, currentUser) => {
                const previousUserId = previousUser && previousUser.id;
                const currentUserId = currentUser && currentUser.id;
                const userLogout = previousUserId && !currentUserId;
                const isUserSame = previousUserId && currentUserId && previousUserId === currentUserId;

                if (userLogout) {
                    this.removeLastPassCodeAlertShowInfo();
                    this.modalStackHelper.dismissAll();
                }
                return !!isUserSame;
            }),
            filter((currentUser) => Boolean(currentUser) && Boolean(currentUser.isConfirmed)),
            switchMap((currentUser) => {
                return forkJoin({
                    currentUser: of(currentUser),
                    currentTeam: this.CurrentSession.currentTeam$.pipe(filter<Team>(Boolean), take(1)),
                    systemNotifications: this.SystemNotificationsStore.getDisplayableNotifications(currentUser.id),
                    lastTwoSignInDates: this.Users.getLastTwoSignInDates().pipe(catchError(() => of(undefined)))
                });
            })
        ).subscribe(({
            currentUser,
            currentTeam,
            systemNotifications,
            lastTwoSignInDates
        }) => {
            if (systemNotifications.length) {
                systemNotifications.forEach((notification) => {
                    this.showSystemNotification(notification);
                });
            }
            if (this.shouldShowPasswordOrSigningModal(currentTeam, currentUser, lastTwoSignInDates)) {
                if (currentUser.isRemote) {
                    this.showSigningPinExpiry(currentTeam, currentUser);
                }
                else {
                    this.showPasswordExpiry();
                }
            }
        });
    }

    private showSystemNotification(notification: SystemNotification): void {
        const modalRef = this.modalsService.show(SystemNotificationComponent, {
            initialState: {
                notification
            },
            class: 'modal-lg'
        });
        modalRef.content.dismiss.subscribe(() => {
            modalRef.hide();
        });
    }

    private wasAlertAlreadyShownToTheUser(lastAlertShownTime: string): boolean {
        if (moment().isSame(moment(lastAlertShownTime), 'day')) {
            return true;
        }
        return false;
    }

    private wasUserSignedInEarlierToday(previousAuthDate: string): boolean {
        return moment().isSame(moment(previousAuthDate), 'day');
    }

    private showSigningPinExpiry(team: Team, currentUser: User): void {
        const isSigningPinExpired = this.PasswordOrPinExpiry.isExpired(
            team.settings.signingPINPolicy,
            currentUser.lastSigningPasscodeReset
        );
        this.setLastPassCodeAlertShowInfo();
        const modalRef = this.modalsService.show(SigningPinExpiryNotificationComponent, {
            initialState: {
                signingPinExpired: isSigningPinExpired
            }
        });
        modalRef.content.dismiss.subscribe(() => {
            modalRef.hide();
        });
    }

    private shouldShowPasswordOrSigningModal(team: Team, user: User, lastTwoSignInDates: string[] | undefined): boolean {
        if (!(lastTwoSignInDates && lastTwoSignInDates.length)) {
            return false;
        }

        const lastAlertShownTime = this.getLastPassCodeAlertShowInfo();
        if (lastAlertShownTime && this.wasAlertAlreadyShownToTheUser(lastAlertShownTime)) {
            return false;
        }
        if (this.wasUserSignedInEarlierToday(lastTwoSignInDates[1])) {
            return false;
        }
        return user.isRemote ? this.shouldShowSigningPinExpiry(team, user) : this.shouldShowPasswordExpiry(team, user);
    }

    private shouldShowPasswordExpiry(team: Team, user: User): boolean {
        if (!team.settings.features.passwordPolicy) {
            return false;
        }
        return this.PasswordOrPinExpiry.shouldShowExpiryReminderModal(
            team.settings.passwordPolicy,
            user.lastPasswordReset
        );
    }

    private shouldShowSigningPinExpiry(team: Team, user: User): boolean {
        if (!team.settings.signingPINPolicy.isEnabled) {
            return false;
        }

        const isSigningPinExpired = this.PasswordOrPinExpiry.isExpired(
            team.settings.signingPINPolicy,
            user.lastSigningPasscodeReset
        );
        const isSigningPINAboutToExpire = this.PasswordOrPinExpiry.shouldShowExpiryReminderModal(
            team.settings.signingPINPolicy,
            user.lastSigningPasscodeReset
        );
        return isSigningPinExpired || isSigningPINAboutToExpire;
    }


    private showPasswordExpiry(): void {
        this.setLastPassCodeAlertShowInfo();

        const modal = this.modalsService.show(PasswordExpiryReminderComponent, {
            initialState: {}
        });
        modal.content.dismiss.subscribe(() => {
            modal.hide();
        });
    }

    private getLastPassCodeAlertShowInfo(): string {
        return this.window.localStorage.getItem(this.alertInfoLocalStorageKey);
    }

    private setLastPassCodeAlertShowInfo(): void {
        this.window.localStorage.setItem(this.alertInfoLocalStorageKey, new Date().toISOString());
    }

    private removeLastPassCodeAlertShowInfo(): void {
        this.window.localStorage.removeItem(this.alertInfoLocalStorageKey);
    }
}
