import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

import template from './dropdown-info.component.html';
import styles from './dropdown-info.component.scss';

@Component({
    selector: 'dropdown-info',
    template,
    styles: [String(styles)]
})
export class DropdownInfoComponent {
    @Input() mainText: string;
    @Input() actionText: string;
    @Input() dropdownLabel: string;
    @Input() dropdownItems: string[];
    @Input() readonly = false;
    @Input() canRemove = true;

    @Output() remove = new EventEmitter<void>();
    @Output() actionClick = new EventEmitter<void>();
}
