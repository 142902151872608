import * as _ from 'lodash';
import { ApiError, Project, Team } from '@app/shared/models';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import { TeamService } from '@app/shared/teams/team.service';
import { ProjectsService } from '@app/shared/projects/projects.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { SORT } from '@app/core/constants';
import template from './projects-page.component.html';
import { DestroyProjectOrTimelineComponent } from '../../compontents/destroy-project/destroy-project-or-timeline.component';
import { DestroyProjectOrTimelineType, ProjectOrTimelineDeleteEvent } from '../../compontents/destroy-project/destroy-project-or-timeline.component.types';
import { EditProjectModalComponent } from '../../compontents/edit-project-modal/edit-project-modal.component';
import { EditProjectEmitData, EditProjectModalModes } from '../../compontents/edit-project-modal/edit-project-modal.component.types';
import { ProjectizerComponent } from '../projectizer/projectizer.component';
import { ProjectsPageProject } from './projects-page.component.types';

@Component({
    selector: 'projects-page',
    template
})
export class ProjectsPageComponent implements OnInit {

    projects: ProjectsPageProject[] = [];

    currentTeam: Team;
    teamId: string;

    selectedProject: ProjectsPageProject;
    crumbs = [{ name: 'Manage Projects' }];
    loadingData: boolean;
    filter: string;
    openDropDown: string;

    SORT = SORT;

    constructor(
        private $state: StateService,
        private CurrentSession: CurrentSessionService,
        private Teams: TeamService,
        private Projects: ProjectsService,
        private Notifications: NotificationsService,
        private Modals: ModalsService
    ) {
        this.loadingData = false;
    }

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.teamId = this.currentTeam.id;
        if (!this.Teams.canViewProjectsAndTimelines()) {
            this.$state.go('app.select-team');
        }
        this._loadProjects();
    }

    _loadProjects() {
        this.loadingData = true;
        return this.Projects.getAllProjects(this.teamId)
            .subscribe(
                (projects) => {
                    this.projects = projects.map((project) => {
                        return { ...project, href: this.generateProjectHref(project) };
                    });
                    this.updateSort('name', false);
                    this.loadingData = false;
                }, ({ error }: ApiError) => {
                    this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                }
            );
    }

    select(selectedProject: ProjectsPageProject) {

        const currentSelectionState = selectedProject.selected;
        this.projects.forEach((project) => {
            project.selected = false;
        });
        selectedProject.selected = !currentSelectionState;

        if (!selectedProject.selected) {
            this.selectedProject = undefined;
            return;
        }

        this.selectedProject = selectedProject;
    }

    canCreate() {
        return this.currentTeam.permissions.createProjects;
    }

    create() {
        if (!this.canCreate()) {
            return;
        }

        const createProjectModal = this.Modals.show(EditProjectModalComponent, {
            class: 'modal-md',
            initialState: {
                project: undefined,
                editMode: EditProjectModalModes.CREATE
            }
        });

        createProjectModal.content.onSave.subscribe((event: EditProjectEmitData) => {
            return this.Projects.createProject(this.teamId, event.project as Project)
                .subscribe(
                    () => {
                        event.onSuccess();
                        this.Notifications.success(`Project '${event.project.name}' created!`);
                        this.$state.reload();
                    }, ({ error }: ApiError) => {
                        event.onError();
                        this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                    }
                );
        });
    }

    canEdit() {
        return this.currentTeam.permissions.updateProjects && this.selectedProject;
    }

    edit() {
        if (!this.canEdit()) {
            return;
        }

        const editProjectModal = this.Modals.show(EditProjectModalComponent, {
            class: 'modal-md',
            initialState: {
                project: this.selectedProject,
                editMode: EditProjectModalModes.UPDATE
            }
        });

        editProjectModal.content.onSave.subscribe((event: EditProjectEmitData) => {
            return this.Projects.editProject(this.teamId, event.project as Project)
                .subscribe(
                    () => {
                        event.onSuccess();
                        this.Notifications.success(`Project '${event.project.name}' updated!`);
                        this.$state.reload();
                    }, ({ error }: ApiError) => {
                        event.onError();
                        this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                    }
                );
        });
    }

    remove() {
        if (this.canRemove()) {

            const destroyProjectModal = this.Modals.show(DestroyProjectOrTimelineComponent, {
                class: 'modal-md',
                initialState: {
                    name: this.selectedProject.name,
                    type: DestroyProjectOrTimelineType.PROJECT
                }
            });

            destroyProjectModal.content.onDestroy.subscribe((event: ProjectOrTimelineDeleteEvent) => {
                this.Projects.deleteProject(this.teamId, this.selectedProject)
                    .subscribe(
                        () => {
                            event.onSuccess();
                            this.Notifications.success(`Project '${this.selectedProject.name}' deleted!`);
                            this.selectedProject = null;
                            this.$state.reload();
                        }, ({ error }: ApiError) => {
                            event.onError();
                            this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                        }
                    );
            });
        }
    }

    canRemove() {
        return this.currentTeam.permissions.deleteProjects && this.selectedProject;
    }

    duplicate() {
        if (!this.canDuplicate()) {
            return;
        }

        const duplicateProjectModal = this.Modals.show(EditProjectModalComponent, {
            class: 'modal-md',
            initialState: {
                project: _.cloneDeep(this.selectedProject),
                editMode: EditProjectModalModes.DUPLICATE
            }
        });

        duplicateProjectModal.content.onSave.subscribe((event: EditProjectEmitData) => {
            return this.Projects.duplicateProject(this.teamId, event.project as Project)
                .subscribe(
                    () => {
                        event.onSuccess();
                        this.Notifications.success(`Project '${event.project.name}' duplicated!`);
                        this.$state.reload();
                    }, ({ error }: ApiError) => {
                        event.onError();
                        this.Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                    }
                );
        });
    }

    canDuplicate() {
        return this.currentTeam.permissions.duplicateProjects && this.selectedProject;
    }

    canProjectize() {
        return this.currentTeam.permissions.importProjects;
    }

    projectize() {
        if (!this.canProjectize()) {
            return;
        }

        const projectizerModal = this.Modals.show(ProjectizerComponent, {
            class: 'modal-lg',
            initialState: {}
        });

        projectizerModal.content.onSubmit.subscribe(() => {
            this._loadProjects();
        });

    }

    private generateProjectHref(project: ProjectsPageProject): string {
        return this.$state.href('app.team.manage-project', { teamId: this.teamId, projectId: project.id });
    }

    updateSort(sortName: string, isReversed?: boolean): void {
        this.SORT.set(sortName, isReversed);
        const sortedProjects = this.projects.sort((a, b) => {
            return this.SORT.naturalSort({ value: a[sortName] }, { value: b[sortName] });
        });
        if (this.SORT.isReversed) {
            sortedProjects.reverse();
        }
        this.projects = [...sortedProjects];
    }

    canActOnSelection() {
        return this.canRemove()
            || this.canDuplicate()
            || this.canEdit();
    }

    canActOnCreate() {
        return this.canCreate()
            || this.canProjectize();
    }

    toggleActions($event: PointerEvent, project: ProjectsPageProject) {
        $event.preventDefault();
        if (this.selectedProject !== project) {
            this.select(project);
            this.openDropDown = project.id;
        }
        this.openDropDown = undefined;
    }

    updateFilter(text: string): void {
        this.filter = text;
    }
}
