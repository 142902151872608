/* eslint-disable max-len */
import {
    Component, OnInit, Input, Output, EventEmitter
} from '@angular/core';
import {
    SiteWithTeamSipIntegrationLink, StudyEntity, StudyManager, Team
} from '@app/shared/models';
import { FolderizeTreeItem } from '@app/shared/folders/folders.service.types';
import styles from './review-study.component.scss';
import template from './review-study.component.html';
import { StudyConfig, StudyStartupPayload, TeamConfiguration } from '../../study-manager.service.types';
import { RoleAndPermissionObject, PermissionObject } from './review-study.types';
@Component({
    selector: 'review-study',
    template,
    styles: [String(styles)]
})

export class ReviewStudyComponent implements OnInit {
    @Input() study: StudyManager;
    @Input() siteVisible: boolean;
    @Input() site: SiteWithTeamSipIntegrationLink;
    @Input() team: Team;
    @Input() tree: FolderizeTreeItem[];
    @Input() teamConfig: TeamConfiguration;
    @Input() studyConfig: StudyConfig;
    @Input() studyEntities: StudyEntity[];
    @Input() oldNickname: string;
    @Input() payload: StudyStartupPayload;
    @Output() entityName = new EventEmitter<string>();

    isProcessing = false;
    public loading = true;
    roleAndPermissionsObjectArray: RoleAndPermissionObject[] = [];
    roleAndPermissionObject: RoleAndPermissionObject;
    permissionObject: PermissionObject;
    linkedEntity: Partial<StudyEntity>;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    ngOnInit(): void {
        this.roleAndPermissionsObjectArray = [];
        this.loading = false;
        this.fillStudyRolesAndPermissions();
    }

    toggleItem(element: HTMLElement): void {
        const button = element.closest('.review-study-component__collapsible') as HTMLButtonElement;
        const content = button.nextElementSibling as HTMLElement;
        if (content.style.display === 'block') {
            content.style.display = 'none';
            button.querySelector('.review-study-component__arrow').textContent = '▼';
        }
        else {
            content.style.display = 'block';
            button.querySelector('.review-study-component__arrow').textContent = '▲';
        }
    }

    // for improvment
    // findLinkedEntityForStudy() {
    //     this.linkedEntity = this.studyEntities.find((studyEntity) => studyEntity.entityName === this.oldNickname);
    // }

    applyNamingConvention(): string {
        const { namingConvention } = this.studyConfig;
        const requiredProps = namingConvention
            .match(/{(.+?)}/g)
            .map((prop) => prop.replace(/[{}]/g, ''));

        let name = namingConvention;

        requiredProps.forEach((prop) => {
            name = name.replace(new RegExp(`{(${prop})}`, 'g'), this.payload[`${prop}`] || '');
        });

        return name;
    }

    getEntityPath(): string {
        const folderName = this.applyNamingConvention();
        return `${this.study.rootContainerPath}/${folderName}`;
    }

    fillStudyRolesAndPermissions() {

        // iterate through roles and taking informations

        this.studyConfig.roles.forEach((role) => {
            this.roleAndPermissionObject = {
                roleName: '',
                number: 0,
                permissions: []
            };
            this.roleAndPermissionObject.roleName = role.name;
            this.roleAndPermissionObject.number = role.number;
            this.roleAndPermissionsObjectArray.push(this.roleAndPermissionObject);
        });

        // for improvment
        // if (this.studyEntities) {
        //     // find linked entity for study
        //     this.findLinkedEntityForStudy();
        // }
        // else {

        if (this.study.rootContainerPath) {
            this.linkedEntity = {
                entityType: 'folder',
                entityName: this.applyNamingConvention(),
                entityPath: this.getEntityPath()
            };
            [this.linkedEntity.entityBinderName] = this.study.rootContainerPath.split('/');
            this.entityName.emit(this.linkedEntity.entityName);
        }
        else {
            this.linkedEntity = {
                entityType: 'binder',
                entityName: this.applyNamingConvention(),
                entityBinderName: this.applyNamingConvention()
            };
            this.entityName.emit(this.linkedEntity.entityName);
        }
        // }

        if (this.linkedEntity) {
            // iterate through permissions and taking informations
            this.studyConfig.permissions.forEach((permission) => {


                this.permissionObject = {
                    roleNumber: permission.roleNumber,
                    objectType: permission.objectType,
                    perm: {
                        type: '',
                        location: permission.location,
                        permissions: permission.privilegesFullName
                    }
                };

                // need to determine exact location
                /**
                     * Scenario
                     * There are 4 different cases and depends of permission.objectType
                     * permission.objectType can be [team, binder, folder, study]
                     * if objectType === team => location will be NAME of the TEAM
                     * the prerequisite is that within linked entities for a study only one can be with the same stduy nickname and that field entityName
                     * if objectType === binder => location will be entityBinderName
                     * if objectType === folder => location will be entityBinderName/entityPath
                     * and on the end
                     * if objectType === study if entityType is binder or folder
                     *              if entityType === binder location will be entityBinderName
                     *              if entityType === folder location will be entityBinderName/entityPath
                     */

                if (permission.objectType === 'team') {
                    this.permissionObject.perm.type = 'team';
                    this.permissionObject.perm.location = this.team.name;
                }
                else if (permission.objectType === 'binder') {
                    this.permissionObject.perm.type = 'binder';
                    this.permissionObject.perm.location = this.linkedEntity.entityBinderName;
                }
                else if (permission.objectType === 'folder') {
                    this.permissionObject.perm.type = 'folder';
                    this.permissionObject.perm.location = `${this.linkedEntity.entityBinderName}/${this.linkedEntity.entityPath}`;
                }
                else if (permission.objectType === 'study') {
                    this.permissionObject.perm.location = (this.linkedEntity.entityType === 'binder') ? this.linkedEntity.entityBinderName : `${this.linkedEntity.entityBinderName}/${this.linkedEntity.entityPath}`;
                    this.permissionObject.perm.type = this.linkedEntity.entityType;
                }


                this.roleAndPermissionsObjectArray.forEach((object) => {
                    if (object.number === this.permissionObject.roleNumber) {
                        object.permissions.push(this.permissionObject.perm);
                    }
                });

            });
        }


        // iterate through globalBinderConfiguration and taking information
        this.teamConfig.globalBinderConfigurations.forEach((coniguration) => {

            coniguration.permissions.forEach((permission) => {

                this.permissionObject = {
                    roleNumber: permission.roleNumber,
                    objectType: permission.objectType,
                    perm: {
                        type: 'binder',
                        location: permission.path,
                        permissions: permission.privilegesFullName
                    }
                };

                this.roleAndPermissionsObjectArray.forEach((object) => {
                    if (object.number === this.permissionObject.roleNumber) {
                        object.permissions.push(this.permissionObject.perm);
                    }
                });
            });
        });
    }

}
