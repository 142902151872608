import {
    Directive, ElementRef, Input, OnDestroy, OnInit
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Directive({
    selector: '[scrollToTop]'
})
export class ScrollToTopDirective implements OnInit, OnDestroy {
    @Input('scrollToTop') trigger: Observable<any>;

    private subscription: Subscription;

    constructor(private el: ElementRef) {}

    ngOnInit() {
        if (this.trigger) {
            this.subscription = this.trigger.subscribe(this.scrollToTop);
        }
    }

    ngOnDestroy() {
        this.unsubscribe();
    }

    private unsubscribe() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private scrollToTop() {
        setTimeout(() => {
            this.el.nativeElement.scrollTop = 0;
        });
    }
}
