import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';
import { DocumentsSignatureRequestsComponent } from './containers/documents-signature-requests/documents-signature-requests.component';
import { LogEntrySignatureRequestsComponent } from './containers/log-entry-signature-requests/log-entry-signature-requests.component';
import { LogEntryBulkSignatureRequestsComponent } from './containers/log-entry-bulk-signature-requests/log-entry-bulk-signature-requests.component';
import { LogEntryBulkChooseSignersComponent } from './components/log-entry-bulk-choose-signers/log-entry-bulk-choose-signers.component';
import { LogEntryBulkRequestSignaturesTableComponent } from './components/log-entry-bulk-request-signatures-table/log-entry-bulk-request-signatures-table.component';

const dependencies = [];

export const signatureRequestsModule = angular
    .module('app.signatureRequests', dependencies)
    .directive('documentsSignatureRequests', downgradeComponent({ component: DocumentsSignatureRequestsComponent }) as ng.IDirectiveFactory)
    .directive('logEntrySignatureRequests', downgradeComponent({ component: LogEntrySignatureRequestsComponent }) as ng.IDirectiveFactory)
    .directive('logEntryBulkSignatureRequests', downgradeComponent({ component: LogEntryBulkSignatureRequestsComponent }) as ng.IDirectiveFactory)
    .directive('logEntryBulkChooseSigners', downgradeComponent({ component: LogEntryBulkChooseSignersComponent }) as ng.IDirectiveFactory)
    .directive('logEntryBulkRequestSignaturesTable', downgradeComponent({ component: LogEntryBulkRequestSignaturesTableComponent }) as ng.IDirectiveFactory);
