import { StateProvider } from '@uirouter/angularjs';

const helpDeskRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.help-desk', {
        url: '/help-desk?return_to',
        views: {
            '': 'helpDeskComponent'
        }
    });
};

helpDeskRoute.$inject = [
    '$stateProvider'
];

export default helpDeskRoute;
