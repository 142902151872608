import { Document, SigningReasons } from '../models';

export const getDefaultSigningReason = (doc: Document, userId: string): SigningReasons => {

    if (doc.pendingSignatures && doc.pendingSignatures.length) {
        const pendingSignature = doc.pendingSignatures.find((signatureRequest) => signatureRequest.userId === userId);
        return pendingSignature ? pendingSignature.reason : null;
    }

    return null;
};
