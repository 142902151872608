import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { StateService } from '@uirouter/core';
import { take, takeUntil } from 'rxjs/operators';
import {
    forkJoin, interval, of, Subject
} from 'rxjs';
import * as _ from 'lodash';

import { NotificationsService } from '@app/core/notifications/notifications.service';
import {
    Team, Crumb, Binder, AuditTrailSubject
} from '@app/shared/models';
import { AuditTrailService } from '@app/shared/audit-trail/audit-trail.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { DownloadsService } from '@app/shared/downloads/downloads.service';
import { BindersService } from '@app/shared/binders/binders.service';
import { LabelsService } from '@app/shared/labels/labels.service';
import { AuditTrailModalComponent } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component';
import { Label } from '@app/shared/labels/labels.service.types';
import { sortFolderStructureLexicographically } from '@app/widgets/sort/sort-folder-structure-lexicographically';
import { ImportTemplateStructureComponent } from '../import-template-structure/import-template-structure.component';

import { StudyStartupService } from '../../study-startup.service';
import { StructureTemplate } from '../../components/imported-structures-table/imported-structures-table.component.types';
import { ImportedStructurePreviewComponent } from '../../components/imported-structure-preview/imported-structure-preview.component';
import { DestroyStructureTemplateComponent } from '../../components/destroy-structure-template/destroy-structure-template.component';
import { DestroyEvent } from '../../components/destroy-structure-template/destroy-structure-template.component.types';
import { AuditTrailModalItem, AuditTrailTypes } from '../../../audit-trail/components/audit-trail-modal/audit-trail-modal.component.types';

import template from './study-startup.component.html';
import styles from './study-startup.component.scss';
import { AssignStructureTemplateBinderComponent } from '../assign-structure-template-binder/assign-structure-template-binder.component';
import { AssignStructureTemplateFolderComponent } from '../assign-structure-template-folder/assign-structure-template-folder.component';

@Component({
    selector: 'study-startup',
    template,
    styles: [String(styles)]
})

export class StudyStartupComponent implements OnInit, OnDestroy {
    binders: Binder[] = [];
    availableLabels: Label[] = [];
    crumbs: Crumb[] = [{ name: 'Structure Templates' }];
    team: Team;
    canStartANewStudy = false;
    canImportStudyTemplate = false;
    canDeleteStudyTemplate = false;
    canDownloadStudyTemplate = false;
    createdStudyId: string;
    canViewAudits = false;
    public isLoading = true;
    public studies: StructureTemplate[] = [];
    readonly newTemplateHighlightDuration = 5000;

    $destroy = new Subject<void>();

    constructor(
        private Binders: BindersService,
        private CurrentSession: CurrentSessionService,
        private Modals: ModalsService,
        private StudyStartup: StudyStartupService,
        private Downloads: DownloadsService,
        private $state: StateService,
        private Notifications: NotificationsService,
        private Labels: LabelsService,
        private AuditTrails: AuditTrailService
    ) { }

    ngOnInit(): void {
        this.team = this.CurrentSession.getCurrentTeam();
        const perms = this.team.permissions;
        this.canStartANewStudy = perms.manageTeam || perms.manageTeamStudyStartup || perms.createTeamStudies;
        this.canImportStudyTemplate = perms.manageTeam || perms.manageTeamStudyStartup || perms.importTeamStudyStructureTemplate;
        this.canDeleteStudyTemplate = perms.manageTeam || perms.manageTeamStudyStartup || perms.deleteTeamStructureTemplates;
        this.canDownloadStudyTemplate = perms.manageTeam || perms.manageTeamStudyStartup || perms.downloadTeamStructureTemplates;
        this.canViewAudits = perms.manageTeam || perms.viewTeamAuditTrail;
        this.fetchStudyData();
        this.fetchBinders();
        this.fetchLabels();
    }

    ngOnDestroy(): void {
        this.$destroy.next();
        this.$destroy.complete();
    }

    fetchBinders(): void {
        this.isLoading = true;
        this.Binders.getBinders(this.team.id, { includeArchived: false })
            .pipe(takeUntil(this.$destroy))
            .subscribe(
                (binders) => {
                    this.binders = binders;
                },
                (error: HttpErrorResponse) => {
                    const message = (error && error.error && error.error.message);
                    message ? this.Notifications.error(message) : this.Notifications.unexpectedError();
                }
            );
    }

    fetchLabels(): void {
        this.Labels.getLabels(this.team.id)
            .pipe(takeUntil(this.$destroy))
            .subscribe((availableLabels) => {
                this.availableLabels = availableLabels;
            });
    }

    fetchStudyData(): void {
        this.isLoading = true;
        this.StudyStartup.getStructureTemplates(this.team.id)
            .subscribe(
                (data) => {
                    this.studies = data;
                    this.isLoading = false;
                },
                () => {
                    this.isLoading = false;
                }
            );
    }

    openImportTemplateStructure(): void {
        const modalRef = this.Modals.show(ImportTemplateStructureComponent, {
            class: 'modal-lg',
            initialState: {}
        });

        modalRef.content.importStructureSuccess
            .pipe(take(1))
            .subscribe((newStudy) => {
                this.studies.unshift(newStudy);

                this.createdStudyId = newStudy.id;

                interval(this.newTemplateHighlightDuration)
                    .pipe(
                        take(1),
                        takeUntil(this.$destroy)
                    ).subscribe(() => {
                        this.createdStudyId = undefined;
                    });
            });
    }

    openTemplateStructurePreview(id: string): void {

        this.StudyStartup.getFolderStructureById(id, this.team.id)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    const tree = sortFolderStructureLexicographically(data.tree);
                    this.Modals.show(ImportedStructurePreviewComponent, {
                        class: 'modal-lg',
                        initialState: {
                            folderizeTreeItems: tree,
                            teamTimezone: this.team.settings.timezone
                        }
                    });
                },
                (error: HttpErrorResponse) => {
                    const message = (error && error.error && error.error.message);
                    message ? this.Notifications.error(message) : this.Notifications.unexpectedError();
                }
            );
    }

    deleteSelected(deleteTemplate: StructureTemplate): void {
        const modal = this.Modals.show(DestroyStructureTemplateComponent, {
            initialState: {
                template: deleteTemplate
            }
        });

        modal.content.destroy.pipe(takeUntil(this.$destroy)).subscribe((event: DestroyEvent) => {
            if (!event) {
                return null;
            }

            return forkJoin([
                this.StudyStartup.deleteStructureTemplate(this.team.id, event.data.template.id, event.data.reason),
                of(event.data.template)
            ]).subscribe(
                ([, removedStructureTemplate]) => {
                    _.remove(this.studies, { id: removedStructureTemplate.id });
                    this.Notifications.success(`Structure Template ${removedStructureTemplate.name} deleted.`);
                    modal.hide();
                },
                (error: HttpErrorResponse) => {
                    const message = (error && error.error && error.error.message) || 'There was an unexpected error.  Please contact your administrator.';
                    this.Notifications.error(message);
                    if (event.onError) {
                        event.onError();
                    }
                }
            );
        });
    }

    downloadStructureTemplate(id: string): void {
        this.Downloads.downloadStudyStructureTemplate({ id, teamId: this.team.id })
            .subscribe(
                () => {
                    const href = this.$state.href('app.team.downloads', { teamId: this.team.id });
                    const message = `<p>Starting download now! We'll notify you when your download is ready.</p>
                            Go to <a class="page-action u-d-inline u-font-weight-bold" href=${href}>MY DOWNLOADS</a> to view all downloads.`;
                    this.Notifications.info(message);
                }, (error: HttpErrorResponse) => {
                    if (error.error && error.error.message) {
                        this.Notifications.error(error.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                }
            );
    }

    assignBinderStructure(): void {
        this.Modals.show(AssignStructureTemplateBinderComponent, {
            class: 'modal-md',
            initialState: {
                assignStructureHasExistingBinders: !!this.binders.length,
                availableLabels: this.availableLabels,
                teamId: this.team.id,
                teamTimezone: this.team.settings.timezone,
                templates: this.studies,
                binders: this.binders
            }
        });
    }

    assignFolderStructure(): void {
        this.Modals.show(AssignStructureTemplateFolderComponent, {
            class: 'modal-md',
            initialState: {
                binders: this.binders,
                teamId: this.team.id,
                availableLabels: this.availableLabels,
                teamTimezone: this.team.settings.timezone,
                templates: this.studies
            }
        });
    }

    openAuditTrailsModal(studyStructureTemplate?: StructureTemplate): void {
        if (!this.canViewAudits) {
            return;
        }
        const auditTrailParams: AuditTrailModalItem = {
            permissions: this.team.permissions,
            ...studyStructureTemplate && { name: studyStructureTemplate.name },
            id: studyStructureTemplate ? studyStructureTemplate.id : this.team.id,
            teamId: this.team.id
        };
        if (studyStructureTemplate) {
            auditTrailParams.name = studyStructureTemplate.name;
            auditTrailParams.id = studyStructureTemplate.id;
            auditTrailParams.type = AuditTrailTypes.studyTemplates;
        }
        else {
            auditTrailParams.name = this.team.name;
            auditTrailParams.id = this.team.id;
            auditTrailParams.type = AuditTrailTypes.team;
        }
        this.AuditTrails.getAudits({
            subject: AuditTrailSubject.STUDY_STRUCTURE_TEMPLATES,
            teamId: this.team.id,
            objectId: auditTrailParams.id,
            overwrittenObjectId: null,
            limitToOverwritten: false,
            ...this.AuditTrails.auditPagination
        }).pipe(takeUntil(this.$destroy)).subscribe(
            (audits) => {
                this.Modals.show(AuditTrailModalComponent, {
                    class: 'modal-lg',
                    initialState: {
                        data: audits,
                        item: auditTrailParams,
                        subject: AuditTrailSubject.STUDY_STRUCTURE_TEMPLATES,
                        pagination: this.AuditTrails.auditPagination,
                        onPageChange: this.AuditTrails.getAudits.bind(this.AuditTrails)
                    }
                });
            },
            (error: HttpErrorResponse) => {
                if (error.error && error.error.message) {
                    this.Notifications.error(error.error.message);
                }
                else {
                    this.Notifications.unexpectedError();
                }
            }
        );
    }
}
