import { Signature, SignatureRequest } from './signature.model';
import { User } from './user.model';

export enum LogEntryTypes {
    text = 'text',
    date = 'date',
    signature = 'signature',
    teamMember = 'team member',
    multiSelect = 'dropdown multi-select',
    singleSelect = 'dropdown single-select'
}

export const LogColumnTypes = [
    { value: LogEntryTypes.text, displayValue: 'Text' },
    { value: LogEntryTypes.teamMember, displayValue: 'Team Member' },
    { value: LogEntryTypes.date, displayValue: 'Date' },
    { value: LogEntryTypes.signature, displayValue: 'Signature' },
    { value: LogEntryTypes.multiSelect, displayValue: 'Dropdown Multi-select' },
    { value: LogEntryTypes.singleSelect, displayValue: 'Dropdown Single-select' }
] as const;

export const DoaColumnTypes = {
    TEAM_MEMBER: 'team-member',
    ROLE: 'role',
    RESPONSIBILITIES: 'responsibilities',
    START_DATE: 'start-date',
    END_DATE: 'end-date',
    STAFF_SIGNATURE: 'staff-signature',
    PI_SIGNATURE: 'pi-signature'
} as const;
export type DoaColumnTypesList = typeof DoaColumnTypes[keyof typeof DoaColumnTypes];

export interface SelectOption {
    id: string;
    name: string;
    isSelected: boolean;
    studyRoleId?: string;
}

export interface SelectOptions {
    options: SelectOption[];
    isNumberIdentifier?: boolean;
}

export interface Column {
    name: string;
    type: LogEntryTypes;
    doaType?: DoaColumnTypesList;
    value?: string | Date | Signature | SelectOptions | undefined | string[];
    selectOptions?: SelectOptions;
    pendingSignatureUserId?: string;
    signatureRequest?: SignatureRequest;
    isUpdated?: boolean;
    userInfo?: User;
}

export interface LogEntry {
    id: {
        logEntryId: string;
        version: number;
    };
    teamId: string;
    documentId: {
        documentId: string;
        version: number;
    };
    isLatestVersion: boolean;
    rowNumber: number;
    columns: Column[];
    comment: string;
    createdBy: string;
    updatedBy: string;
    updatedAt?: Date
    type?: string;
    struckThrough: boolean
    struckThroughBy?: User;
}
