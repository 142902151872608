import {
    Component, Input, OnChanges
} from '@angular/core';

import { FolderizeTreeItem } from '../../shared/folders/folders.service.types';
import { sortByLexicographically } from '../sort/sort-by-lexicographically.util';
import { sortBy } from '../sort/sort-by.util';

import template from './folder-structure-node.component.html';

@Component({
    selector: 'folder-structure-node',
    template
})
export class FolderStructureNodeComponent implements OnChanges {
    @Input() node: FolderizeTreeItem;
    @Input() teamTimezone: string;
    @Input() offsetLeft = '10px';
    @Input() isRecursive = true;

    item: FolderizeTreeItem = null;

    ngOnChanges(): void {
        if (this.node) {
            const sortedLabels = this.node.labels && this.sortLabelsByLabelNameAndValue(this.node.labels);
            const sortedErrors = this.node.errors && sortBy(this.node.errors, 'line');
            const sortedItems = this.node.items && sortByLexicographically(this.node.items, 'name');
            const { dueDate } = this.node;
            this.item = {
                ...this.node,
                labels: sortedLabels,
                errors: sortedErrors,
                items: sortedItems,
                dueDate
            };
        }
        else {
            this.item = null;
        }
    }

    private sortLabelsByLabelNameAndValue(labels: FolderizeTreeItem['labels']): FolderizeTreeItem['labels'] {
        const hash = {};
        labels.forEach((label) => {
            if (!hash[label.name]) {
                hash[label.name] = [label];
            }
            else {
                hash[label.name].push(label);
            }
        });
        const sortedLabelNames = Object.keys(hash).sort((current, next) => {
            return current.toUpperCase().localeCompare(next.toUpperCase(), 'en', { numeric: true }) || current.length - next.length;
        });
        return sortedLabelNames.reduce((acc, labelName) => {
            return [...acc, ...sortByLexicographically(hash[labelName], 'value')];
        }, []);
    }
}
