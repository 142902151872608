import { Column } from '@app/shared/models';

export enum LogEntrySigStatuses {
    pendingCurrentUser,
    pastDueCurrentUser,
    pendingAnotherUser,
    signable,
    signed
}

export type LogEntryRowColumn = Column & {
    displayValue?: string;
};

export type LogEntryRowLogEntry = Column & {
    columns: LogEntryRowColumn[];
};
