import { StateProvider } from '@uirouter/angularjs';

export const studiesRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.study-create', {
        url: '/studies/new',
        views: {
            '': 'studyView'
        }
    });

    $stateProvider.state('app.team.manage-monitor-groups', {
        url: '/monitor-groups',
        views: {
            '': 'monitorGroups'
        }
    });

    $stateProvider.state('app.team.monitor-group-form', {
        url: '/edit-monitor-groups/:monitorGroupId?isDuplicate',
        views: {
            '': 'monitorGroupForm'
        },
        params: {
            monitorGroupId: '',
            isDuplicate: 'false'
        }
    });

    $stateProvider.state('app.team.study-edit', {
        url: '/studies/:studyId/edit',
        views: {
            '': 'studyView'
        }
    });

    $stateProvider.state('app.team.study-connect', {
        url: '/studies/:studyId/connect',
        views: {
            '': 'studyView'
        },
        data: {
            tab: 'studyDetails'
        }
    });
};

studiesRoute.$inject = ['$stateProvider', '$urlServiceProvider'];
