import { Component, Inject } from '@angular/core';
import { Team, Crumb } from '@app/shared/models';
import { Observable, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { map, takeUntil } from 'rxjs/operators';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import template from './connected-teams.component.html';
import styles from './connected-teams.component.scss';
import { CurrentSessionService } from '../../../../core/current-session.service';
import { ConnectedTeamsApiService } from '../../services/connected-teams/connected-teams-api.service';
import { ConnectedTeamsStateService } from '../../services/connected-teams/connected-teams-state.service';
import {
    SortObject, Connection, CONNECTION_STATUS_TYPES, ConnectedTeamsList
} from '../../services/connected-teams.types';

@Component({
    selector: 'connected-teams',
    template,
    styles: [String(styles)]
})
export class ConnectedTeamsComponent {
    public team: Team;
    public crumbs: Crumb[];
    public isLoading$: Observable<boolean>;
    public connectedTeamsList$: Observable<ConnectedTeamsList[]>;
    public sorts$: Observable<SortObject[]>;
    public hasFilterValue: boolean;
    private destroy$ = new Subject<void>();

    constructor(
        private CurrentSession: CurrentSessionService,
        private ConnectedTeamsApi: ConnectedTeamsApiService,
        private ConnectedTeamsStateManagement: ConnectedTeamsStateService,
        private Notifications: NotificationsService,
        @Inject('Window') private window: Window
    ) {
        this.connectedTeamsList$ = this.ConnectedTeamsStateManagement.connectedTeamsList$;
        this.isLoading$ = this.ConnectedTeamsStateManagement.isLoading$;
        this.sorts$ = this.ConnectedTeamsStateManagement.sorts$;
    }

    ngOnInit(): void {
        this.team = this.CurrentSession.getCurrentTeam();
        this.crumbs = [
            {
                name: 'Document Sharing',
                stateName: 'app.team.document-sharing',
                stateParams: {
                    teamId: this.CurrentSession.getCurrentTeam().teamId
                }
            },
            {
                name: 'Connected Teams'
            }
        ];
        this.fetchPage(this.CurrentSession.getCurrentTeam().teamId);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    fetchPage(teamId: string): void {
        this.ConnectedTeamsStateManagement.setIsLoading(true);

        this.ConnectedTeamsApi.getConnectedTeams(teamId)
            .pipe(
                map((connectedTeams: Connection[]) => this.mapConnectionsToTableData(connectedTeams)),
                takeUntil(this.destroy$)
            )
            .subscribe(
                (connectedTeams: ConnectedTeamsList[]) => {
                    connectedTeams.sort((a, b) => a.teamName.localeCompare(b.teamName));
                    this.ConnectedTeamsStateManagement.setOriginalConnectedTeamsList(connectedTeams);
                    this.ConnectedTeamsStateManagement.setIsLoading(false);
                },
                (error: HttpErrorResponse) => {
                    if (error.error && error.error.message) {
                        this.Notifications.error(error.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                }
            );
    }

    contactSupport(): void {
        this.window.open('https://florencehealthcare.zendesk.com/hc/en-us/articles/13593830572819-Document-Sharing-Request-Form', '_blank');
    }

    mapConnectionsToTableData(connectedTeams: Connection[]): ConnectedTeamsList[] {
        return connectedTeams.map((connectedTeam) => {
            let teamName;
            let sharingLocation;
            let myTeamIndex;
            let connectionStatus;

            if (connectedTeam.configs[0].teamId === this.team.teamId) {
                teamName = connectedTeam.configs[1].name;
                sharingLocation = connectedTeam.configs[0].name;
                myTeamIndex = 0;
            }
            else {
                teamName = connectedTeam.configs[0].name;
                sharingLocation = connectedTeam.configs[1].name;
                myTeamIndex = 1;
            }
            if (connectedTeam.configs[0]?.receivingLocation && connectedTeam.configs[1]?.receivingLocation) {
                connectionStatus = CONNECTION_STATUS_TYPES.BOTH;
            }
            else if (connectedTeam.configs[myTeamIndex]?.receivingLocation?.binderId) {
                connectionStatus = CONNECTION_STATUS_TYPES.RECEIVING;
            }
            else if (connectedTeam.configs[myTeamIndex === 0 ? 1 : 0]?.receivingLocation?.binderId) {
                connectionStatus = CONNECTION_STATUS_TYPES.SENDING;
            }
            else {
                connectionStatus = CONNECTION_STATUS_TYPES.DEACTIVATED;
            }

            return {
                _id: connectedTeam._id,
                binderId: connectedTeam.configs[myTeamIndex]?.receivingLocation?.binderId,
                folderId: connectedTeam.configs[myTeamIndex]?.receivingLocation?.folderId,
                teamName,
                sharingLocation,
                connectionStatus
            };
        });
    }

    handleFilterValue(filterValue: string): void {
        if (this.ConnectedTeamsStateManagement.getOriginalConnectedTeamsList().length === 0) {
            return null;
        }
        this.hasFilterValue = filterValue.length > 0;
        this.ConnectedTeamsStateManagement.filterByValue(filterValue);
    }

    setNextSort(sort): void {
        this.ConnectedTeamsStateManagement.sortBy(sort);
    }
}
