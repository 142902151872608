import * as _ from 'lodash';
import {
    Transition,
    HookResult,
    RejectType,
    StateService
} from '@uirouter/angularjs';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { SessionsService } from '@app/shared/sessions/sessions.service';
import { TeamService } from '@app/shared/teams/team.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Team, User } from '@app/shared/models';
import { Auth0Service } from '@app/shared/sessions/auth0.service';
import { SystemNotificationsStoreService } from '@app/shared/system-notifications/system-notifications-store.service';
import { SessionIdleService } from '@app/shared/session-idle/session-idle-service.service';

class AppLayoutController {
    public isLoggedIn$: Observable<boolean>;
    public currentUser$: Observable<User>;
    public currentTeam$: Observable<Team>;
    public allTeams;
    private lastRedirect: number;
    // this ignore need to be here because @uirouter/angularjs/transition returns Function type
    // eslint-disable-next-line @typescript-eslint/ban-types
    private deregister: Function[] = [];
    public downtimeBannerContent$: Observable<string>;

    constructor(
        $transitions: Transition,
        private $state: StateService,
        private Sessions: SessionsService,
        private Teams: TeamService,
        private CurrentSession: CurrentSessionService,
        private SystemNotificationsStore: SystemNotificationsStoreService,
        private Auth0: Auth0Service,
        private SessionIdleService: SessionIdleService
    ) {
        this.isLoggedIn$ = Auth0.isAuthenticated$;
        this.deregister.push($transitions.onError({}, this._stateChangeError.bind(this)));
        this.SessionIdleService.init();
    }

    $onInit(): void {
        this.downtimeBannerContent$ = this.SystemNotificationsStore.getDowntimeBannerContent();
        this.currentTeam$ = this.CurrentSession.currentTeam$;
        this.currentUser$ = this.CurrentSession.currentUser$;
        this.Teams.getAll().toPromise().then((teams) => {
            this.allTeams = teams;
        });
    }

    dismissDowntimeBanner(): void {
        this.SystemNotificationsStore.dismissDowntimeBanner();
    }

    $onDestroy(): void {
        this.deregister.forEach((item) => item());
    }


    _stateChangeError(transition: Transition): HookResult {
        const ignoredErrors = [
            RejectType.ABORTED, // The transition was aborted by a hook which returned false
            RejectType.IGNORED, // The transition was ignored because it would have no effect
            RejectType.SUPERSEDED // While transition was running, a new transition started and it was superseded by it
        ];

        if (ignoredErrors.indexOf(transition.error().type) > -1) {
            return;
        }

        const fromState = transition.from();
        const fromParams = transition.params('from');
        const toState = transition.to();
        const toParams = transition.params('to');

        let targetState;
        let targetParams = {};
        let afterRedirect;
        return this.Auth0.isAuthenticated$
            .pipe(take(1))
            .toPromise()
            .then((isLoggedIn) => {
                if (isLoggedIn) {
                    if (toParams.teamId && fromState
                        && !_.isEqual({ state: toState, params: toParams }, { state: fromState, params: fromParams })) {
                        targetState = fromState;
                        targetParams = fromParams;
                    }
                    else if (toParams.teamId && toState.name !== 'app.team.binders') {
                        targetState = 'app.team.binders';
                        targetParams = { teamId: toParams.teamId };
                        afterRedirect = this.Sessions.giveNotification;
                    }
                    else {
                        targetState = 'sign-in';
                    }
                }
                else {
                    targetState = 'sign-in';
                }

                // Redirect if appropriate
                return this._redirectWithThrottling(targetState, targetParams, afterRedirect);
            });
    }

    _redirectWithThrottling(redirectState, redirectParams, afterRedirect): HookResult {
        const now = (new Date()).getTime();
        const redirectThrottleInMs = 300;
        if (!this.lastRedirect || (now - this.lastRedirect) > redirectThrottleInMs) {
            this.lastRedirect = now;
            if (afterRedirect) {
                afterRedirect();
            }
            return this.$state.target(redirectState, redirectParams);
        }
    }
}

AppLayoutController.$inject = [
    '$transitions',
    '$state',
    'SessionsService',
    'Team',
    'CurrentSession',
    'SystemNotificationsStore',
    'Auth0Service',
    'SessionIdleService'
];

export default AppLayoutController;
