import * as _ from 'lodash';
import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import template from './roles-templates-by-study-number.component.html';
import style from './roles-templates-by-study-number.component.scss';
import { RoleTemplatesResult } from '../roles-templates-preview/roles-templates-preview.types';
import { PreviewRolesTemplatesByStudyNumberParams } from './roles-templates-by-study-number.component.types';

@Component({
    selector: 'roles-templates-by-study-number',
    template,
    styles: [String(style)]
})
export class RolesTemplatesByStudyNumberComponent implements OnInit {
    @Input() loadingResults: boolean;
    @Input() results: RoleTemplatesResult;

    @Output() onPreview = new EventEmitter<PreviewRolesTemplatesByStudyNumberParams>();
    @Output() onClear = new EventEmitter<void>();

    studyNumberErrorMessage: string;
    practiveNumberErrorMessage: string;

    submittedPreivewStudyNumber: string;
    submittedPreivewPracticeNames: string;
    previewCompleted: boolean;

    result;

    auditForm: FormGroup;

    constructor(
        private fb: FormBuilder
    ) {
        this.submittedPreivewStudyNumber = '';
        this.submittedPreivewPracticeNames = '';
        this.previewCompleted = false;
    }

    ngOnInit(): void {
        this.initForm();
    }

    initForm() {
        this.auditForm = this.fb.group({
            studyNumber: ['', [Validators.required, notBlank]],
            practiceNames: ['', []]
        });
    }

    ngOnChanges(changes: ng.IOnChangesObject): void {
        if (changes.results) {
            this.result = changes.results.currentValue;
            this._setFormValidity();
        }
    }

    _setFormValidity() {
        if (!this.result || this.result.statusCode !== 400) {
            return;
        }
        const missingStudyNumbers = _.map(_.filter(this.result.data.studyNumbers, { exist: false }), 'name');
        const missingPracticeNames = _.map(_.filter(this.result.data.practiceNames, { exist: false }), 'name');

        if (missingStudyNumbers.length) {
            this.auditForm.controls.studyNumber.setErrors({ exists: false });
            this.studyNumberErrorMessage = 'Study number not found';
        }

        if (missingPracticeNames.length) {
            this.auditForm.controls.practiceNames.setErrors({ exists: false });
            this.practiveNumberErrorMessage = `Practice name(s) not found: ${missingPracticeNames.join(', ')}`;
        }
    }

    formChanged() {
        this.studyNumberErrorMessage = null;
        this.practiveNumberErrorMessage = null;
        this.auditForm.markAsPristine();
        this.auditForm.markAsUntouched();
        this.auditForm.controls.studyNumber.clearValidators();
        this.auditForm.controls.studyNumber.updateValueAndValidity();
        this.auditForm.controls.practiceNames.clearValidators();
        this.auditForm.controls.practiceNames.updateValueAndValidity();
    }

    preview() {
        const $event = this._getParams();
        this.previewCompleted = true;
        this.submittedPreivewStudyNumber = this.auditForm.controls.studyNumber.value;
        this.submittedPreivewPracticeNames = this.auditForm.controls.practiceNames.value;

        this.onPreview.emit($event);
    }

    clear() {
        this.auditForm.controls.studyNumber.setValue(null);
        this.auditForm.controls.practiceNames.setValue(null);
        this.auditForm.markAsPristine();
        this.auditForm.markAsUntouched();
        this.studyNumberErrorMessage = null;
        this.practiveNumberErrorMessage = null;
        this.submittedPreivewStudyNumber = '';
        this.submittedPreivewPracticeNames = '';

        this.onClear.emit();
    }

    previewUpToDate() {
        return this.auditForm.controls.studyNumber.value === this.submittedPreivewStudyNumber
        && this.auditForm.controls.practiceNames.value === this.submittedPreivewPracticeNames;
    }

    clearTooltip() {
        if (this.clearDisabled()) {
            return 'Nothing to clear. Form and results are empty';
        }

        return 'Clear form';
    }

    clearDisabled() {
        return !this.results && !this.auditForm.controls.studyNumber.value && !this.auditForm.controls.practiceNames.value;
    }

    previewTooltip() {
        if (!this.auditForm.controls.studyNumber.value) {
            return 'Study Number field cannot be empty';
        }
        if (this.isCreateAuditInProgress()) {
            return 'Create in progress, a new preview cannot be created. ';
        }
    }

    isCreateAuditInProgress() {
        if (!_.isEmpty(this.result)) {
            return this.result.status === 'inProgress' && this.result.action === 'create';
        }
    }

    _getParams() {
        return {
            filters: {
                studyNumbers: [this.auditForm.controls.studyNumber.value],
                practiceNames: this._extractPracticeNames(this.auditForm.controls.practiceNames.value),
                checkExistence: true
            }
        };
    }

    _extractPracticeNames(practiceNames) {
        if (!practiceNames) {
            return [];
        }

        return practiceNames
            .split('\n')
            .map((name) => name.trim())
            .filter(String);
    }
}
