import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import styles from './requestor-permission-warning.component.scss';

@Component({
    selector: 'requestor-permission-warning',
    template: `
        <div class="requestor-permission-warning">
            <span class="requestor-permission-warning__message">
                You lack Request Signature permissions for one or more of the documents you selected.
                <br>
                They will not appear in the list below.
            </span>
            <i class="fa fa-times requestor-permission-warning__close-btn"
               role="button"
               (click)="dismiss.emit()">
            </i>
        </div>
    `,
    styles: [String(styles)]
})
export class RequestorPermissionWarningComponent implements OnInit, OnDestroy {
    @Output() dismiss = new EventEmitter<void>();

    readonly warningDurationInMs = 5000;
    private readonly destroy$ = new Subject<void>();

    ngOnInit(): void {
        timer(this.warningDurationInMs)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.dismiss.emit();
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
