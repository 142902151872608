import {
    Directive, ElementRef, EventEmitter, Output
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
    selector: '[clickOutside]',
    exportAs: 'clickOutside'
})
export class ClickOutsideDirective {
 @Output() clickOutside = new EventEmitter<void>();

    clickSubscription: Subscription | null = null;

    constructor(private elementRef: ElementRef) {}

    public startListening(): void {
        if (!this.clickSubscription) {
            this.clickSubscription = fromEvent<MouseEvent>(document, 'click')
                .pipe(
                    filter((event) => {
                        const clickTarget = event.target as HTMLElement;
                        return !this.elementRef.nativeElement.contains(clickTarget);
                    })
                )
                .subscribe(() => {
                    this.clickOutside.emit();
                });
        }
    }

    public stopListening(): void {
        if (this.clickSubscription) {
            this.clickSubscription.unsubscribe();
            this.clickSubscription = null;
        }
    }

    ngOnDestroy(): void {
        this.stopListening();
    }
}
