import {
    Component,
    EventEmitter,
    Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import template from './delete-qc-review.component.html';
import styles from './delete-qc-review.component.scss';

@Component({
    selector: 'delete-qc-review',
    template,
    styles: [String(styles)]

})
export class DeleteQcReviewComponent {
    isProcessing = false;
    @Output() deleteQcReview = new EventEmitter<{ onSuccess:() => void;onError: () => void;}>();

    constructor(
        public modal: BsModalRef
    ) {}

    onDeleteQcReview(): void {
        this.isProcessing = true;
        this.deleteQcReview.emit({
            onError: () => {
                this.isProcessing = false;
                this.modal.hide();
            },
            onSuccess: () => {
                this.isProcessing = false;
                this.modal.hide();
            }
        });
    }

    cancel(): void {
        this.modal.hide();
    }
}
