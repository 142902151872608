import * as moment from 'moment-timezone';
import { PasswordOrPinSettings } from './password-or-pin-expiry.service.types';

export class PasswordOrPinExpiryService {
    private skipExpiryCheck(teamPolicy: PasswordOrPinSettings): boolean {
        return Object.prototype.hasOwnProperty.call(teamPolicy, 'isEnabled') && !teamPolicy.isEnabled;
    }

    public isExpired(teamPolicy: PasswordOrPinSettings, lastResetDate: string): boolean {
        if (this.skipExpiryCheck(teamPolicy)) {
            return false;
        }
        const passwordOrPINExpiryDate = moment(lastResetDate).add(teamPolicy.expiryDays, 'days');
        const now = moment();

        return now.isAfter(passwordOrPINExpiryDate);
    }

    public shouldShowExpiryReminderModal(
        teamPolicy: PasswordOrPinSettings,
        lastResetDate: string
    ): boolean {
        if (this.skipExpiryCheck(teamPolicy)) {
            return false;
        }
        const passwordOrPINExpiryDate = moment(lastResetDate).add(teamPolicy.expiryDays, 'days');
        const loginAlertsStartDate = passwordOrPINExpiryDate.clone().subtract(teamPolicy.loginAlertsDays, 'days');
        const now = moment();

        return now.isBetween(loginAlertsStartDate, passwordOrPINExpiryDate);
    }
}
