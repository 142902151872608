import * as _ from 'lodash';
import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
    Team
} from '@app/shared/models';
import {
    VirtualTreeFlatNode, VirtualTreeItemSelectedEvent, VirtualTreeNode, VirtualTreeSelectionMode
} from '@app/widgets/virtual-tree/virtual-tree.component.types';
import { EntityPathService } from '@app/widgets/entity/entity-path.service';
import { Subject } from 'rxjs';
import template from './select-location.component.html';
import styles from './select-location.component.scss';

@Component({
    selector: 'move',
    template,
    styles: [String(styles)]
})
export class SelectLocationComponent implements OnInit {
    @Input() teamId;
    @Input() loadRoot;
    @Input() loadItem;
    @Output() rootContainer = new EventEmitter();

    currentTeam: Team;
    selectedContainer: VirtualTreeFlatNode;
    binderName: string
    isProcessing = false;
    loadingRoot = false;
    actingType: string;
    currentPath: string[];
    rootItems: VirtualTreeNode[];
    selectionMode = VirtualTreeSelectionMode;
    rootContainerPath: string;
    $destroy = new Subject<void>();

    constructor(
        private CurrentSession: CurrentSessionService,
        private modalRef: BsModalRef,
        private EntityPath: EntityPathService
    ) { }

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.currentPath = [];
        this.loadingRoot = true;
        this.loadRoot().then((rootItems) => {
            this.rootItems = rootItems;
            this.loadingRoot = false;
        });
    }

    cancel(): void {
        this.modalRef.hide();
    }

    close(): void {
        if (!this.isProcessing) {
            this.modalRef.hide();
        }
    }

    save(): void {
        this.isProcessing = true;

        this.rootContainer.emit({
            rootContainer: this.selectedContainer
        });

    }

    isItemSelectable(): boolean {
        return true;
    }

    onItemSelected($event: VirtualTreeItemSelectedEvent<VirtualTreeFlatNode>): void {
        this.selectedContainer = $event.item;
        if (this.selectedContainer.type === 'binder') {
            this.rootContainerPath = this.selectedContainer.name;
        }
        else {
            this.EntityPath.getPathForEntity(this.selectedContainer)
                .toPromise()
                .then((data) => {
                    const binder = data[1];
                    this.rootContainerPath = `${binder.name}/${this.selectedContainer.path}`;
                });
        }
    }

    onItemUnselected(): void {
        this.selectedContainer = undefined;
    }

}
