'use strict';

exports.auditsSortableProperties = {
    TIMESTAMP: 'timestamp',
    EMAIL: 'email'
};

/**
 * @description This is a factory function that creates Joi validation schema from a given Joi instance.
 * Allows use of different Joi library versions in the project
 * @param Joi
 * @return {{next: *, sortDirNumber: *, pageSize: *, sortBy: *, sortDirString: *}}
 */
exports.createAuditsPaginationValidationWith = (Joi) => ({
    sortBy: Joi.string()
        .valid(...Object.values(exports.auditsSortableProperties))
        .default(exports.auditsSortableProperties.TIMESTAMP),
    sortDirString: Joi.string().valid('ASC', 'DESC').default('DESC'),
    sortDirNumber: Joi.number().valid(1, -1).default(-1),
    pageSize: Joi.number().default(20).min(1).max(50),
    next: Joi.string()
});
