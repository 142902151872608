import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    Input
} from '@angular/core';

import { Subject } from 'rxjs';
import { Team, Document } from '@app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { QCReviewService } from '@app/components/documents/qc-review.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';
import { calculateEntityPath, EntityPathItem } from '@app/shared/documents/calculate-entity-path.util';
import { notBlank } from '@app/core/form-validators';

import { DecoratedUser, QcReviewCreateEvent } from './document-request-qc-review.component.types';
import styles from './document-request-qc-review.component.scss';
import template from './document-request-qc-review.component.html';
@Component({
    selector: 'document-request-qc-review',
    template,
    styles: [String(styles)]
})

export class DocumentRequestQCReviewComponent implements OnInit, OnDestroy {
    @Input() entity: Document;
    @Input() docVersion: number;
    @Input() userId: string;
    @Input() currentTeam: Team;

    @Output() save = new EventEmitter<QcReviewCreateEvent>();

    isProcessing: boolean;
    private readonly destroy$ = new Subject<void>();
    loadingUsers = false;
    assingToMyself: string;
    form: FormGroup;
    path: EntityPathItem[];
    currentFilter = '';
    usersHash: { [key: string]: DecoratedUser[] } = {};
    filteredUsers: DecoratedUser[];

    constructor(
        private modal: BsModalRef,
        private QCReview: QCReviewService,
        private Notifications: NotificationsService,
        private fb: FormBuilder
    ) { }

    ngOnInit(): void {
        this.path = calculateEntityPath(this.entity, this.currentTeam);
        this.path[this.path.length - 1].name = `${this.entity.name} - Version ${this.docVersion}`;
        this.loadUsers('');
        this.initForm();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    initForm(): void {
        this.form = this.fb.group({
            reviews: new FormArray([
                this.fb.group({
                    reviewer: new FormControl('', Validators.required),
                    comment: new FormControl('', [Validators.required, notBlank])
                })
            ])
        });
    }

    get reviews(): FormArray {
        return this.form.get('reviews') as FormArray;
    }

    loadUsers(filter: string, index?: number) {
        if (filter !== '' && index < 0) {
            return;
        }
        this.loadingUsers = true;
        const params = {
            documentId: typeof this.entity.id === 'string' ? this.entity.id : this.entity.id.documentId,
            documentVersion: this.docVersion
        };
        this.QCReview.getPotentialQCReviewers(params)
            .subscribe(
                (data) => {
                    const decoratedUsers = data.map((u) => ({ ...u, displayName: u.name }));
                    const usersWithCurrentOnTop = this.setCurrentUserOnTop(decoratedUsers);
                    this.currentFilter = filter;
                    this.loadingUsers = false;
                    if (index >= 0) {
                        this.usersHash[`${index}`] = [...usersWithCurrentOnTop];
                    }
                    else if (!this.usersHash.all && filter === '') {
                        this.usersHash.all = [...usersWithCurrentOnTop];
                        this.usersHash['0'] = [...usersWithCurrentOnTop];
                        this.filteredUsers = this.usersHash['0'];
                    }
                },
                (error) => {
                    if (error.error && error.error.message) {
                        this.Notifications.error(error.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                }
            );
    }

    filterUsers(params): void {
        const {
            filter,
            index
        } = params;

        if (!filter) {
            this.usersHash[index.toString()] = this.filteredUsers;
            return;
        }
        const filterCanonical = filter.trim().toLowerCase();
        this.usersHash[index.toString()] = this.filteredUsers.filter((t) => t.name.toLowerCase().includes(filterCanonical));
    }

    addReview() {
        const reviewArr = this.form.get('reviews') as FormArray;
        this.usersHash[reviewArr.length] = [...this.usersHash.all];
        const newReview = this.fb.group({
            reviewer: new FormControl('', Validators.required),
            comment: new FormControl('', [Validators.required, notBlank])
        });

        reviewArr.push(newReview);
    }

    removeReview(index: number) {
        const reviewArr = this.form.get('reviews') as FormArray;
        reviewArr.removeAt(index);
        Object.keys(this.usersHash).forEach((key) => {
            if (key === index.toString()) {
                delete this.usersHash[key];
            }
            if (+key > index) {
                this.usersHash[`${(+key - 1)}`] = this.usersHash[key];
                delete this.usersHash[key];
            }
        });
    }

    private setCurrentUserOnTop(users: DecoratedUser[]): DecoratedUser[] {
        let usersClone = [...users];
        const currentUserIndex = usersClone.findIndex((element) => element.id === this.userId);
        if (currentUserIndex > -1) {
            const [currentUser] = usersClone.splice(currentUserIndex, 1);
            usersClone = [
                { ...currentUser, displayName: `${currentUser.fullName} (Assign to yourself)` },
                ...usersClone
            ];
        }
        return usersClone;
    }

    onSelectionCleared(index: number): void {
        const reviewArr = this.form.get('reviews') as FormArray;

        reviewArr.value[index].reviewer = '';
        this.reviews.setValue(reviewArr.value);
        this.usersHash[index] = [...this.usersHash.all];
    }

    onUserSelect(event: FilteredSelectEvent<DecoratedUser>, index: number): void {
        const reviewArr = this.form.get('reviews') as FormArray;

        const reviewer = event.added[0];
        reviewArr.value[index].reviewer = reviewer;
        this.reviews.setValue(reviewArr.value);
        this.usersHash[index.toString()] = [{ ...reviewer }];
    }

    dismissModal(): void {
        this.modal.hide();
    }

    onSubmit(): void {
        if (this.isProcessing) {
            return;
        }

        this.isProcessing = true;

        const arr = this.form.get('reviews') as FormArray;
        const qcReviewCreated = [];

        for (let i = 0; i < arr.length; i += 1) {
            const oneReview = {
                reviewerIds: [arr.value[i].reviewer.id],
                status: 'Pending review',
                message: arr.value[i].comment
            };
            qcReviewCreated.push(oneReview);
        }

        this.save.emit({
            qcReview: qcReviewCreated
        });
    }

    canSubmit(): boolean {
        return this.form.valid;
    }

}
