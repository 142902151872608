import {
    Component, EventEmitter, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import { Binder, BrowseTree } from '@app/shared/models';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { VirtualTreeFlatNode, VirtualTreeItemSelectedEvent, VirtualTreeSelectionMode } from '@app/widgets/virtual-tree/virtual-tree.component.types';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CreateShortcutsEvent } from './shortcuts-export.component.types';
import template from './shortcuts-export.html';
import styles from './shortcuts-export.scss';

@Component({
    selector: 'shortcuts-export',
    template,
    styles: [String(styles)]
})
export class ShortcutsExportComponent implements OnInit, OnDestroy {

@Input() loadRoot
@Input() loadItem;
@Input() entity: BrowseTree
@Input() documents: Document[];
@Output() save = new EventEmitter<CreateShortcutsEvent>();

  public selectionMode = VirtualTreeSelectionMode;
  public isProcessing = false;
  public loadedRoot = false;
  public loadingRoot = false;
  public selectedItems = [];
  public currentPath;
  public items: Binder[];

  constructor(
    private modalRef: BsModalRef
  ) {}

  ngOnInit(): void {
      this.currentPath = [this.entity.binderId].concat(this.entity.lineage);

      this.loadingRoot = true;
      this.loadRoot().then((items: Binder[]) => {
          this.items = sortByLexicographically(items, 'name');
          this.loadingRoot = false;
          this.loadedRoot = true;
      });
  }

  ngOnDestroy() {
      this.cancel();
  }

  public isItemSelectable() :boolean {
      return true;
  }

  public isItemDisplayable(item) {
      return item.type === 'binder' || item.type === 'folder';
  }

  public binderTreeSelectionChange($event: VirtualTreeItemSelectedEvent<VirtualTreeFlatNode>) {
      this.selectedItems = $event.selectedItems;
  }

  public submitDisabled() {
      return !(this.selectedItems.length > 0);
  }

  public cancel(): void {
      this.modalRef.hide();
  }

  public handleSubmit(): void {
      if (this.submitDisabled() || this.isProcessing) {
          return;
      }

      this.isProcessing = true;


      this.save.emit({
          data: this.selectedItems,
          onSuccess: () => this.modalRef.hide(),
          onError: () => {
              this.isProcessing = false;
          }
      });
  }
}
