import {
    Component,
    Input,
    Output,
    OnDestroy,
    EventEmitter
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SystemNotificationsStoreService } from '@app/shared/system-notifications/system-notifications-store.service';
import { SystemNotificationsService } from '@app/shared/system-notifications/system-notifications.service';
import { SystemNotification } from '@app/shared/models/system-notification.model';
import { CurrentSessionService } from '@app/core/current-session.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';

import template from './system-notification.component.html';
import styles from './system-notification.component.scss';
@Component({
    selector: 'system-notification',
    template,
    styles: [String(styles)]
})
export class SystemNotificationComponent implements OnDestroy {
    @Input() notification: SystemNotification;
    @Output() dismiss = new EventEmitter<void>();

    private destroy$ = new Subject<boolean>();

    constructor(
        private SystemNotificationsStore: SystemNotificationsStoreService,
        private SystemNotifications: SystemNotificationsService,
        private CurrentSession: CurrentSessionService
    ) { }

    readNotification(): void {
        const userId = this.CurrentSession.getCurrentUser().id;
        const notificationId = this.notification.id;
        this.SystemNotifications
            .readSystemNotification(userId, notificationId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.dismiss.emit();
            });
    }

    dismissNotification(): void {
        this.SystemNotificationsStore.dismissModalNotification(this.notification.id);
        this.dismiss.emit();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
