import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

import template from './document-sharing-pagination.component.html';
import styles from './document-sharing-pagination.component.scss';

@Component({
    selector: 'document-sharing-pagination-component',
    template,
    styles: [String(styles)]
})
export class DocumentSharingPaginationComponent {
    @Input() pageNum: number;
    @Input() isLastPage: boolean;
    @Input() isLoading: boolean;

    @Output() nextPage = new EventEmitter<void>();
    @Output() previousPage = new EventEmitter<void>();

    handlePreviousButtonClickEvent(): void {
        this.emitPreviousPage();
    }

    handleNextButtonClickEvent(): void {
        this.emitNextPage();
    }

    emitPreviousPage(): void {
        this.previousPage.emit();
    }

    emitNextPage(): void {
        this.nextPage.emit();
    }
}
