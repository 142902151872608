import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ElementRef, QueryList } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormControl,
    NgControl,
    ValidatorFn
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

export class FormUtil {
    private scrollToBottom = new Subject<QueryList<ElementRef>>();
    scrollToBottom$ = this.scrollToBottom.asObservable();

    triggerScrollToElement(elementRef: QueryList<ElementRef>): void {
        this.scrollToBottom.next(elementRef);
    }

    scrollElementIntoView$(): Observable<QueryList<ElementRef>> {
        return this.scrollToBottom$.pipe(
            delay(100),
            tap((elementRef) => {
                const element = <HTMLElement> elementRef.last.nativeElement;
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });

                const inputElement = element.querySelector('input');
                inputElement?.focus();
            })
        );
    }

    static controlHasError(
        formControl: NgControl | AbstractControl
    ): boolean {
        return formControl.invalid && (formControl.dirty || formControl.touched);
    }

    static addFormControlError(control: AbstractControl, errorName: string): void {
        control.setErrors({
            [errorName]: true,
            ...control.errors
        });
        control.markAsTouched();
    }

    static removeFormControlError(control: AbstractControl, errorName: string) {
        if (control.hasError(errorName)) {
            delete control.errors[errorName];

            if (Object.keys(control.errors).length === 0) {
                control.setErrors(null);
            }

            control.markAsTouched();
        }
    }

    static reorderFormArrayFromDragDropEvent(
        event: CdkDragDrop<string[]>,
        formArray: FormArray
    ) {
        const { previousIndex, currentIndex, container } = event;
        if (previousIndex === currentIndex) {
            return;
        }
        moveItemInArray(container.data, previousIndex, currentIndex);

        const formArrayValue = formArray.value;

        moveItemInArray(formArrayValue, previousIndex, currentIndex);
    }

    static setFormArrayToNewValue(
        formArray: FormArray,
        newValues: unknown[],
        validators?: ValidatorFn[]
    ): void {
        formArray.clear();

        newValues.forEach((newValue) => {
            const formControl = new FormControl(newValue, validators);
            formArray.push(formControl);
        });
    }

    static clearValidatorsFromFormArrayAndControls(
        formArray: FormArray
    ): void {
        formArray.clearValidators();

        formArray.controls.forEach((control) => {
            control.clearValidators();
            control.updateValueAndValidity();
        });

        formArray.updateValueAndValidity();
    }
}
