import { Inject, Injectable } from '@angular/core';
import { AppConfigService } from '../../shared/app-config/app-config.service';

@Injectable({
    providedIn: 'root'
})
export class PendoScriptInitializerService {

    constructor(@Inject('Window') private window: Window, private appConfigService: AppConfigService) { }

    private getPendoScriptContent(apiKey: string): string {
        return `(function (apiKey) {(function (p, e, n, d, o) {var v, w, x, y, z;o = p[d] = p[d] || {};o._q = o._q || [];v = ['initialize','identify','updateOptions','pageLoad','track',];for (w = 0, x = v.length; w < x; ++w)(function (m) {o[m] =o[m] ||function () {o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));};})(v[w]);y = e.createElement(n);y.async = !0;y.src ='https://cdn.pendo.io/agent/static/' +apiKey +'/pendo.js';z = e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y, z);})(window, document, 'script', 'pendo');})('${apiKey}');`;
    }

    private injectScript(scriptContent: string): void {
        const scriptElement = this.window.document.createElement('script');
        scriptElement.textContent = scriptContent;
        this.window.document.head.appendChild(scriptElement);
    }

    init(): void {
        if (this.appConfigService.config.pendoEnabled) {
            const scriptContent = this.getPendoScriptContent(this.appConfigService.config.pendoApiKey);
            this.injectScript(scriptContent);
        }
    }
}
