import { StateProvider } from '@uirouter/angularjs';

const foldersRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.folder', {
        url: '/binders/:binderId/folders/:folderId',
        views: {
            '': 'folderShow'
        },
        params: {
            folderId: ''
        }
    });
};

foldersRoute.$inject = [
    '$stateProvider'
];

export default foldersRoute;
