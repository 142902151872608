import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UIRouterModule } from '@uirouter/angular';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { WidgetsModule } from '@app/widgets/widgets.module';
import { EntityModule } from '@app/widgets/entity/entity.module';
import { MatTabsModule } from '@angular/material';

import { SharedModule } from '@app/shared/shared.module';
import { StudyFormComponent } from './components/study-form/study-form.component';
import { StudyDetailsComponent } from './components/study-details/study-details.component';
import { MonitorGroupsViewComponent } from './containers/monitor-groups/monitor-groups-view.component';
import { MonitorGroupFormComponent } from './containers/monitor-group-form/monitor-group-form.component';
import { MonitorGroupsListModalComponent } from './components/monitor-groups-list/monitor-groups-list-modal.component';
import { StudySiteTeamEditComponent } from './components/study-site-team-edit/study-site-team-edit.component';
import { CurrentSessionService } from '../../core/current-session.service';
import { TeamService } from '../../shared/teams/team.service';
import { StudyTeamImportComponent } from './components/study-team-import/study-team-import.component';
import { FormDirectivesModule } from '../../shared/forms/directives/form-directives.module';
import { StudyViewComponent } from './containers/study-view/study-view.component';
import { StudyLinkEntitiesComponent } from './components/study-link-entities/study-link-entities.component';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AccordionModule.forRoot(),
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        UIRouterModule,
        CommonModule,
        WidgetsModule,
        EntityModule,
        ScrollingModule,
        SharedModule,
        FormDirectivesModule,
        MatTabsModule
    ],
    providers: [
        CurrentSessionService,
        TeamService
    ],
    declarations: [
        StudyFormComponent,
        StudyTeamImportComponent,
        StudyDetailsComponent,
        MonitorGroupsViewComponent,
        MonitorGroupFormComponent,
        MonitorGroupsListModalComponent,
        StudySiteTeamEditComponent,
        StudyViewComponent,
        StudyLinkEntitiesComponent
    ],
    entryComponents: [
        StudyFormComponent,
        StudyDetailsComponent,
        StudyTeamImportComponent,
        MonitorGroupsViewComponent,
        MonitorGroupFormComponent,
        MonitorGroupsListModalComponent,
        StudySiteTeamEditComponent,
        StudyViewComponent,
        StudyLinkEntitiesComponent
    ],
    exports: [
    ]
})
export class StudiesModule {}
