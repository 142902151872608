export const UserConstants = {
    jobFunctions: [
        'Analyst',
        'Clinical Operations',
        'Coordinator',
        'eTMF Management',
        'GSSU Study Start-Up',
        'Investigator',
        'Management',
        'Monitor',
        'Project Management',
        'Quality Assurance',
        'Regulatory',
        'Technician',
        'Other'
    ],
    orgTypes: [
        'Academic Medical Center',
        'CRO',
        'Hospital/Health Center',
        'Independent Site',
        'Institutional Review Board (IRB)',
        'Site Network',
        'Sponsor - Pharmaceutical',
        'Sponsor - Medical Device',
        'Other'
    ],
    products: [
        {
            title: 'Florence eBinders with Florence eHub Basic',
            text: 'The clinical research industry\'s leading eISF platform with remote site access',
            selected: true
        },
        {
            title: 'Florence eHub Premium',
            text: 'Enable unlimited and bulk downloads, Global Search, Reports and Subscriptions',
            flyer: '(Coming Soon for Sponsors)',
            selected: false
        },
        {
            title: 'Florence eHub Enterprise',
            text: 'Enable enterprise-wide study access, Dashboards, Project Timelines, Site Readiness Scores, Quality Control Workflows and API Integration',
            flyer: '(Coming Soon for Sponsors)',
            selected: false
        }
    ],
    privacyPolicyLink: 'https://florencehc.com/privacy-policy/'
};

export default UserConstants;
