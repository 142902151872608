import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import { downgradeComponent } from '@angular/upgrade/static';
import { AuditTrailModalComponent } from './components/audit-trail-modal/audit-trail-modal.component';

const dependencies = [
    uiRouter
];

const auditTrailModule = angular
    .module('app.audit-trail', dependencies)
    .directive('auditTrailModal', downgradeComponent({ component: AuditTrailModalComponent }) as ng.IDirectiveFactory);

export default auditTrailModule;
