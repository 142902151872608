import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import style from './permission-tree-permission.component.scss';
import template from './permission-tree-permission.component.html';
import { PermissionItem } from './permission-tree-permission.component.types';

@Component({
    template,
    styles: [String(style)],
    selector: 'permission-tree-permission'
})

export class PermissionTreePermissionComponent implements OnInit {
    @Input() item: PermissionItem;
    @Input() canEditPermissions: boolean;
    @Input() subject: string;
    @Input() binderId: string;
    @Input() isBinder: boolean;
    @Input() isActive: boolean;
    @Input() isTeam: boolean;
    @Input() isLast: boolean;
    @Input() isFirst: boolean;
    @Input() index: number;
    @Input() itemLength: number;
    @Input() isDisabled: boolean;
    @Input() showInheritance: boolean;

    @Output() itemChanged = new EventEmitter<PermissionItem>();
    @Output() teamItemAdded = new EventEmitter<PermissionItem>();
    @Output() binderItemRemoved = new EventEmitter<PermissionItem>();
    @Output() permissionsAdded = new EventEmitter<PermissionItem>();
    @Output() permissionsRemoved = new EventEmitter<PermissionItem>();
    itemTooltipText: string;
    itemPermissions: any;

    ngOnInit() {
        this.itemTooltipText = this.canEditPermissions
            ? this.item.description
            : `This permission was ${this.item.wasAdded ? 'added to ' : 'removed from '} the ${this.subject}.`;

        if (this.item.permissions) {
            this.itemPermissions = Object.keys(this.item.permissions).map((key) => ({
                key,
                value: this.item.permissions[key]
            }));
        }

    }

    permissionsRemovedChild(item: PermissionItem) {
        this.updatePermissions(false);
        this.permissionsRemoved.emit(item);
    }

    permissionsAddedChild(item: PermissionItem) {
        this.updatePermissions(true);
        this.permissionsAdded.emit(item);
    }

    getPermissionsLength(): number {
        if (this.itemPermissions.length >= 1) {
            return this.itemPermissions.length;
        }
        return 0;
    }

    toggleExpandCollapse(item: PermissionItem) {
        if (this.isBinder) {
            if (item.expanded) {
                this.collapseAll(item);
            }
            else {
                this.expandAll(item);
            }
        }
        else {
            item.expanded = !item.expanded;
        }
    }

    updatePermissions(isAdded: boolean) {
        if (this.item.permissions && !this.item.allChildrenHidden) {
            let total = 0;
            let count = 0;
            let childrenCheckCount = 0;
            Object.keys(this.item.permissions).forEach((key) => {
                if (this.item.permissions[key].has && !this.item.permissions[key].isHidden) {
                    count += 1;
                }

                if (this.item.permissions[key].hasCheckedChildren && !this.item.permissions[key].isHidden) {
                    childrenCheckCount += 1;
                }

                if (!this.item.permissions[key].isHidden) {
                    total += 1;
                }
            });

            if (isAdded) {
                if (count === total) {
                    this.item.has = true;
                    this.addPermissionsToHiddenPrivileges(this.item);
                }
                else if ((childrenCheckCount > 0 || count > 0)) {
                    this.item.hasCheckedChildren = true;
                }
            }
            else {
                if (count !== total) {
                    this.item.has = false;
                }
                if ((childrenCheckCount === 0 && count === 0) || this.item.allChildrenHidden) {
                    this.item.hasCheckedChildren = false;
                }
                else if ((childrenCheckCount > 0 || count > 0)) {
                    this.item.hasCheckedChildren = true;
                }
            }
        }
    }

    togglePermission(): void {
        if (!this.item.has) {
            this.removePermissionsRecursively(this.item);
        }

        if (this.item.has) {
            this.addPermissionsRecursively(this.item);
        }
    }

    changedState(): void {
        this.item.has = !this.item.has;
        this.togglePermission();

        this.item.binderId = this.binderId;
        this.itemChanged.emit(this.item);
        if (this.item.has) {
            if (this.isTeam) {
                this.teamItemAdded.emit(this.item);
            }
        }
        else {
            if (!this.isTeam) {
                this.binderItemRemoved.emit(this.item);
            }
        }
    }


    expandAll(binder): void {
        binder.expanded = true;
        Object.keys(binder.permissions).forEach((key) => {
            this.expandRecursively(binder.permissions[key]);
        });
    }

    collapseAll(binder): void {
        binder.expanded = false;
        Object.keys(binder.permissions).forEach((key) => {
            this.expandRecursively(binder.permissions[key]);
        });
    }


    private removePermissionsRecursively(item: any) {
        item.has = false;
        item.hasCheckedChildren = false;
        this.permissionsRemoved.emit(item);
        if (item.permissions) {
            Object.keys(item.permissions).forEach((key) => {
                item.permissions[key].has = false;
                this.removePermissionsRecursively(item.permissions[key]);
            });
        }
        else {
            // emit up to let parent item know if it should be checked if all children are checked
            item.isTeam = this.isTeam;
        }
    }

    private addPermissionsRecursively(item: any): void {
        item.has = true;
        item.hasCheckedChildren = true;
        this.permissionsAdded.emit(item);
        if (item.permissions) {
            Object.keys(item.permissions).forEach((key) => {
                item.permissions[key].has = true;
                this.addPermissionsRecursively(item.permissions[key]);
            });
        }
        else {
            // emit up to let parent item know if it should be checked if all children are checked
            item.isTeam = this.isTeam;
        }
    }

    private addPermissionsToHiddenPrivileges(item) {
        if (item.permissions) {
            Object.keys(item.permissions).forEach((key) => {
                if (!item.permissions[key].has) {
                    item.permissions[key].has = true;
                }
                this.addPermissionsToHiddenPrivileges(item.permissions[key]);
            });
        }
    }

    private expandRecursively(item) {
        item.expanded = true;
        if (item.permissions) {
            Object.keys(item.permissions).forEach((key) => {
                this.expandRecursively(item.permissions[key]);
            });
        }
    }

    collapseRecursively(item) {
        item.expanded = false;
        if (item.permissions) {
            Object.keys(item.permissions).forEach((key) => {
                this.expandRecursively(item.permissions[key]);
            });
        }
    }

}
