'use strict';

exports.rolesFilters = {
    _ID: '_id',
    NAME: 'name'
};

exports.USER_ROLES_LOADING_LIMIT = 10;

exports.SUPPORT_ROLE_NAME = 'Florence Support';
exports.ROLE_CATEGORY_NAMES = {
    UNKNOWN: 'unknown',
    OTHER: 'other'
};
exports.BLINDED_STATUSES = {
    BLINDED: 'Blinded',
    UNBLINDED: 'Unblinded',
    NO_MARK: 'No mark'
};
