import { User } from '@app/shared/models';
import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';
import template from './monitor-review-potential-recipients.component.html';
import styles from './monitor-review-potential-recipients.component.scss';
import { DocumentMonitorReviewService } from '../../document-monitor-review.service';
import { UpdateRecipientsParams } from './monitor-review-potential-recipients.component.types';

@Component({
    selector: 'monitor-review-potential-recipients',
    template,
    styles: [String(styles)]
})

export class MonitorReviewPotentialRecipientsComponent implements OnInit, OnChanges {

    @Input() paywallId: string;
    @Input() documentId: string;
    @Input() disableToggleList: boolean;
    @Output() updateSelectedRecipientIds = new EventEmitter<UpdateRecipientsParams>();

    loadingPotentialRecipients = false;
    currentPaywallId: string;
    potentialRecipients: User[] = [];
    selectedRecipients: User[] = [];

    constructor(
        private MonitorReviews: DocumentMonitorReviewService
    ) {}

    ngOnInit(): void {
        this.loadPotentialRecipients();
    }

    ngOnChanges(): void {
        if (this.currentPaywallId !== this.paywallId) {
            this.selectedRecipients.forEach((selectedRecipient) => this.removeRecipient(selectedRecipient.id));
        }
        this.loadPotentialRecipients();
    }

    loadPotentialRecipients(filter?: string): void {
        this.loadingPotentialRecipients = true;

        const { documentId, paywallId } = this;
        this.currentPaywallId = paywallId;

        this.MonitorReviews.getPotentialRecipients(documentId, paywallId, filter)
            .subscribe(
                (data: User[]) => {
                    this.potentialRecipients = data;
                    this.loadingPotentialRecipients = false;
                }
            );
    }

    onRecipientsSelect($event: FilteredSelectEvent<User>): void {
        $event.added.forEach((item) => this.selectedRecipients.push(item));
        $event.removedIds.forEach((id) => this.removeRecipient(id));
        this.updateRecipients();
    }

    removeRecipient(id: string): void {
        this.selectedRecipients = this.selectedRecipients.filter((item) => item.id !== id);
        this.updateRecipients();
    }

    onRecipientsFilterChange(filter: string): void {
        this.loadPotentialRecipients(filter);
    }

    updateRecipients(): void {
        this.selectedRecipients.sort((a, b) => a.fullName.localeCompare(b.fullName));

        const $event = {
            selectedRecipientIds: this.selectedRecipients.map((item) => item.id)
        };
        this.updateSelectedRecipientIds.emit($event);
    }
}
