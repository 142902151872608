import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityModule } from '@app/widgets/entity/entity.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { ModalCloseButtonComponent } from './modal-close-button/modal-close-button.component';
import { ModalProgressComponent } from './modal-progress/modal-progress.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';

@NgModule({
    declarations: [
        ModalCloseButtonComponent,
        ModalProgressComponent,
        ModalHeaderComponent
    ],
    entryComponents: [
        ModalCloseButtonComponent,
        ModalProgressComponent,
        ModalHeaderComponent
    ],
    imports: [
        CommonModule,
        EntityModule,
        TooltipModule
    ],
    exports: [
        ModalCloseButtonComponent,
        ModalProgressComponent,
        ModalHeaderComponent
    ],
    providers: [
        { provide: 'Window', useValue: window }
    ]
})
export class ModalsModule { }
