import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrimOnBlurDirective } from './trim-on-blur/trim-on-blur.directive';
import { ShowIfControlInvalidDirective } from './show-if-control-invalid/show-if-control-invalid.directive';
import { ApplyErrorStylesIfControlInvalidDirective } from './apply-error-styles-if-control-invalid/apply-error-styles-if-control-invalid.directive';

@NgModule({
    declarations: [
        TrimOnBlurDirective,
        ShowIfControlInvalidDirective,
        ApplyErrorStylesIfControlInvalidDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        TrimOnBlurDirective,
        ShowIfControlInvalidDirective,
        ApplyErrorStylesIfControlInvalidDirective
    ]
})
export class FormDirectivesModule {}
