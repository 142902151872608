import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import * as _ from 'lodash';
import { CurrentSessionService } from '@app/core/current-session.service';
import {
    Binder, Role, Team, UserWithRoles
} from '@app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { DownloadPermissionsParams } from '@app/shared/permissions/permissions.service.types';
import template from './download-permission-report.component.html';

@Component({
    selector: 'download-permission-report',
    template
})
export class DownloadPermissionReportComponent implements OnInit {
    @Input() binders: Binder[] = []
    @Input() subject: UserWithRoles | Role;
    @Input() format: string;
    @Input() filterName: string;
    @Output() downloadClicked = new EventEmitter<DownloadPermissionsParams>();

    public currentTeam: Team;
    public chosenBinders: Binder[] = [];

    constructor(
        private CurrentSession: CurrentSessionService,
        private modalRef: BsModalRef
    ) {}

    ngOnInit() {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
    }

    onCancel() {
        this.modalRef.hide();
    }

    handleSubmit() {
        this.downloadClicked.emit({
            binderIds: this.chosenBinders.map((it) => it.id),
            teamId: this.currentTeam.id,
            filter: this.filterName,
            subjectId: this.subject.id,
            subjectType: this.subject.type,
            format: this.format
        });
    }

    private transferBinder(fromArr: Binder[], toArr: Binder[], binder: Binder): Binder[][] {
        const newFromArr = sortByLexicographically(fromArr.filter((item) => item.id !== binder.id), 'name');
        const newToArr = sortByLexicographically([...toArr, binder], 'name');

        return [newFromArr, newToArr];
    }

    onBinderRemove(binder: Binder) {
        [this.chosenBinders, this.binders] = this.transferBinder(this.chosenBinders, this.binders, binder);
    }

    onBinderAdd(binder: Binder) {
        [this.binders, this.chosenBinders] = this.transferBinder(this.binders, this.chosenBinders, binder);
    }
}
