
import {
    Component, Input, Output, EventEmitter, OnInit
} from '@angular/core';
import {
    AbstractControl, FormArray, FormBuilder, Validators, FormControl
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as CustomValidators from '@app/core/form-validators';

import { REGEX } from '@app/core/constants';
import { forbiddenChars } from '@app/core/constants/messages';

import { MultiChoiceData } from './multi-choice-form.component.types';
import template from './multi-choice-form.component.html';
import styles from './multi-choice-form.component.scss';

@Component({
    selector: 'multi-choice-form',
    template,
    styles: [String(styles)]
})
export class MultiChoiceFormComponent implements OnInit {
    @Input() isForColumn = false;
    @Input() data: MultiChoiceData = null;
    @Output() save = new EventEmitter<MultiChoiceData>();
    @Output() error = new EventEmitter<string>();

    isEdit = false;
    private initialData = {
        name: '',
        options: ['', '', '', '', '']
    }

    readonly errorMessages = {
        length: 'Maximum of 50 characters exceeded',
        required: 'Required field',
        pattern: `The following characters are not allowed: ${forbiddenChars}`,
        blank: 'Value cannot be blank'
    }

    readonly customDetailOptionPlaceholders = [
        'Enter value (e.g. Lot Number)',
        'Enter value (e.g. Kit Number)',
        'Enter value',
        'Enter value',
        'Enter value'
    ]

    multiChoiceForm = this.fb.group({
        nameOrDescription: ['', [
            Validators.required,
            Validators.pattern(REGEX.names),
            Validators.maxLength(50),
            CustomValidators.notBlank
        ]],
        options: this.fb.array([], [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(5)
        ])
    });

    constructor(
        private fb: FormBuilder,
        public modal: BsModalRef
    ) { }

    get nameOrDescriptionCtrl(): AbstractControl {
        return this.multiChoiceForm.controls.nameOrDescription;
    }

    get optionsCtrl(): FormArray {
        return this.multiChoiceForm.controls.options as FormArray;
    }

    ngOnInit(): void {
        this.isEdit = !!this.data;
        const nameValue = (this.data && this.data.name) || '';
        this.nameOrDescriptionCtrl.setValue(nameValue);
        this.initialData.options.forEach((option, index) => {
            let optionValidators = [
                Validators.pattern(REGEX.names),
                Validators.maxLength(50),
                CustomValidators.notBlank
            ];
            if (index < 2) {
                optionValidators = [...optionValidators, Validators.required];
            }
            const optionValue = (this.data && this.data.options[index]) || option;
            this.optionsCtrl.push(new FormControl(optionValue, optionValidators));
        });
    }

    cancel(): void {
        this.modal.hide();
    }

    onSave(): void {
        const { nameOrDescription: name, options: formOptions } = this.multiChoiceForm.value;
        const options = formOptions.filter((o) => o !== '');

        const trimmedOptions = options.map((o) => o.trim());
        const uniqueOptions = new Set(trimmedOptions);
        if (trimmedOptions.length !== uniqueOptions.size) {
            this.error.emit('Duplicate options are not allowed');
            return;
        }

        this.save.emit({ name: name.trim(), options: trimmedOptions });
        this.modal.hide();
    }
}
