import * as _ from 'lodash';
import { RawParams, StateService } from '@uirouter/core';

import { Component, OnInit } from '@angular/core';

import { CurrentSessionService } from '@app/core/current-session.service';
import {
    Announcement, Team, Crumb, RelatedObject, AnnouncementRecipient, DocumentId
} from '@app/shared/models';
import { AnnouncementsService } from '../../annoucements.service';

import template from './announcement-view.component.html';

@Component({
    template
})
export class AnnouncementViewComponent implements OnInit {
    announcement: Announcement = null;
    loadedAnnouncement = false;
    loadedAnnouncementRecipients = false;
    currentTeam: Team;
    crumbs: Crumb[];
    announcementRelatedObjects: RelatedObject[] = null;
    announcementRecipients: AnnouncementRecipient[] = null;
    relatedObjectsHeight: 180 | 65;

    constructor(
        private $state: StateService,
        private Announcements: AnnouncementsService,
        private CurrentSession: CurrentSessionService
    ) { }

    ngOnInit(): void {
        const stateParams = this.$state.params;
        this.crumbs = this.getCrumbs(stateParams);
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.Announcements.getAnnouncement(this.currentTeam.id, stateParams.announcementId)
            .subscribe({
                next: (data) => {
                    this.crumbs.push({ name: data.title });
                    this.announcement = data;
                },
                complete: () => {
                    this.loadedAnnouncement = true;
                }
            });
        this.Announcements.getAnnouncementRecipients(this.currentTeam.id, stateParams.announcementId)
            .subscribe((data) => {
                this.loadedAnnouncementRecipients = true;
                this.announcementRecipients = data;
            });
        this.Announcements.getAnnouncementRelatedObjects(this.currentTeam.id, stateParams.announcementId)
            .subscribe((relatedObjects) => {
                this.loadedAnnouncementRecipients = true;
                this.announcementRelatedObjects = this.sortRelatedObjects(relatedObjects);
                this.setRelatedItemsHeight();
            });
    }

    openObject(relatedObject: RelatedObject): void {
        let state: string;
        const params: RawParams = { teamId: relatedObject.object.teamId };

        switch (relatedObject.type) {
            case 'binder': {
                params.binderId = relatedObject.object._id;
                state = 'app.team.folder';
                break;
            }
            case 'folder': {
                params.binderId = relatedObject.object.binderId;
                params.folderId = relatedObject.object.id;
                state = 'app.team.folder';
                break;
            }
            case 'document': {
                params.documentId = (relatedObject.object.id as Exclude<DocumentId, string>).documentId
                    || relatedObject.object.id;
                params.version = (relatedObject.object.id as Exclude<DocumentId, string>).version;
                state = 'app.team.document-show';
                break;
            }
            default: {
                // noop
            }
        }

        this.$state.go(state, params);
    }

    private setRelatedItemsHeight(): void {
        this.relatedObjectsHeight = (this.announcementRelatedObjects && this.announcementRelatedObjects.length) ? 180 : 65;
    }

    private getCrumbs(params): Crumb[] {
        const crumbs = [{
            name: 'Announcements',
            stateName: 'app.team.announcements',
            stateParams: {
                teamId: params.teamId
            }
        }];
        if (params.returnTo) {
            crumbs[0].stateName = params.returnTo.stateName;
            crumbs[0].stateParams = params.returnTo.params;
        }

        return crumbs;
    }

    private sortRelatedObjects(relatedObjects: RelatedObject[]): RelatedObject[] {
        const groupedRelatedObjects: {
            brokenShortcut?: RelatedObject[];
            shortcut?: RelatedObject[];
            placeholder?: RelatedObject[];
            document?: RelatedObject[];
            binder?: RelatedObject[];
            folder?: RelatedObject[];
        } = {};

        relatedObjects.forEach((relatedObject) => {
            if (relatedObject.type === 'document') {
                if (relatedObject.object.isShortcut) {
                    if (relatedObject.object.isBrokenShortcut) {
                        groupedRelatedObjects.brokenShortcut = [...(groupedRelatedObjects.brokenShortcut || []), relatedObject];
                    }
                    else {
                        groupedRelatedObjects.shortcut = [...(groupedRelatedObjects.shortcut || []), relatedObject];
                    }
                }
                else if (relatedObject.object.subType === 'placeholder') {
                    groupedRelatedObjects.placeholder = [...(groupedRelatedObjects.placeholder || []), relatedObject];
                }
                else {
                    groupedRelatedObjects.document = [...(groupedRelatedObjects.document || []), relatedObject];
                }
            }
            if (relatedObject.type === 'binder') {
                groupedRelatedObjects.binder = [...(groupedRelatedObjects.binder || []), relatedObject];
            }
            if (relatedObject.type === 'folder') {
                groupedRelatedObjects.folder = [...(groupedRelatedObjects.folder || []), relatedObject];
            }
        });

        return _.flatMap(
            ['binder', 'folder', 'document', 'shortcut', 'brokenShortcut', 'placeholder'],
            (type) => {
                return _.sortBy(groupedRelatedObjects[type], 'object.name');
            }
        );
    }
}
