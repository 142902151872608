import { CurrentSessionService } from '@app/core/current-session.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../app-config/app-config.service';

@Injectable()
export class SigningPasscodeService {

    readonly url = {
        requestSigningPasscodeReset: `${this.AppConfig.config.authHost}/api/users/reset-signing-passcode`
    };

    constructor(
        private $http: HttpClient,
        private AppConfig: AppConfigService,
        private CurrentSession: CurrentSessionService
    ) { }

    resetSigningPasscode(): Observable<void> {
        const team = this.CurrentSession.getCurrentTeam();
        let params = {};

        if (team && team.settings.support) {
            params = { ...team.settings.support };
        }

        return this.$http.get<void>(this.url.requestSigningPasscodeReset, { params, withCredentials: true });
    }
}
