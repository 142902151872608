/**
 * Sorts array of objects lexicographically by object property name
 * Note: localeCompare returns 0 if values are numerically equal (like 01 and 1)
 *       so it does a length comparison in this case to make results consistent
 * @param array - Sort array
 * @param propName - Sort field
 * @returns Lexicographically sorted array
 */
export const sortByLexicographically = <T>(array: T[], propName: keyof T): T[] => {
    return array.sort((current, next) => {
        const value1 = current[propName];
        const value2 = next[propName];
        if (!value1) {
            return 1;
        }
        if (!value2) {
            return -1;
        }
        if (typeof value1 !== 'string' || typeof value2 !== 'string') {
            throw new Error('Unable to sort non string values.');
        }
        return value1.toUpperCase().localeCompare(value2.toUpperCase(), 'en', { numeric: true }) || value1.length - value2.length;
    });
};
