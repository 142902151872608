import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { SignatureReasonPickerComponent } from './signature-reason-picker/signature-reason-picker.component';

const dependencies = [];
const signatures = angular
    .module('app.widgets.signatures', dependencies)
    .directive('signatureReasonPicker', downgradeComponent({ component: SignatureReasonPickerComponent }) as ng.IDirectiveFactory);

export default signatures;
