import { Project } from '@app/shared/models';
import { REGEX, MESSAGES } from '@app/core/constants';
import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import * as _ from 'lodash';
import template from './edit-project-modal.component.html';
import { EditProjectEmitData, EditProjectModalModes, NewProjectData } from './edit-project-modal.component.types';

@Component({
    selector: 'edit-project-modal',
    template
})
export class EditProjectModalComponent implements OnInit {
    @Input() project: Project | undefined;
    @Input() editMode: EditProjectModalModes;

    @Output() onSave = new EventEmitter<EditProjectEmitData>();

    itemForm: FormGroup;

    isProcessing = false;
    namePattern = REGEX.names;
    nameMessage = MESSAGES.validNameMessage;

    constructor(
      private modalInstance: BsModalRef,
      private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.isProcessing = false;

        if (this.editMode === 'duplicate') {
            this.project.name += ' copy';
        }

        this.initForm();
    }

    initForm() {
        this.itemForm = this.fb.group({
            newProjectName: [
                this.project?.name ? this.project.name : '',
                [
                    Validators.required,
                    notBlank,
                    Validators.pattern(this.namePattern),
                    Validators.minLength(1),
                    Validators.maxLength(250)
                ]
            ],
            newDescription: [
                this.project?.description ? this.project.description : '',
                [
                    Validators.pattern(this.namePattern),
                    Validators.maxLength(250)
                ]
            ]
        });
    }

    handleKeypress(event: KeyboardEvent) {
        if (event.key === 'Enter' && this.itemForm.valid && !this.isProcessing) {
            this.save();
        }
    }

    cancel(): void {
        this.modalInstance.hide();
    }

    save(): void {
        this.isProcessing = true;

        let projectToEmit: Project | NewProjectData;
        if (this.editMode === 'create') {
            projectToEmit = {
                name: this.itemForm.controls.newProjectName.value.trim(),
                description: this.itemForm.controls.newDescription.value
            };
        }
        else {
            projectToEmit = _.cloneDeep(this.project);
            projectToEmit.name = this.itemForm.controls.newProjectName.value.trim();
            projectToEmit.description = this.itemForm.controls.newDescription.value;
        }

        const emitData = {
            project: projectToEmit,
            onSuccess: () => {
                this.modalInstance.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        };

        this.onSave.emit(emitData);
    }
}
