import { FormArray, FormControl, ValidationErrors } from '@angular/forms';

export const noDuplicates = function noDuplicates(controlFormArray: FormArray) {
    return (formControl: FormControl): ValidationErrors | null => {

        if (!formControl.value) {
            return null;
        }
        const existingValues = controlFormArray.controls.map((control) => control.value);

        const isDuplicate = !!existingValues.filter((existingValue) => existingValue === formControl.value).length;
        if (isDuplicate) {
            return { isDuplicate: true };
        }
        return null;
    };
};
