import { StateProvider } from '@uirouter/angularjs';

const userProfileRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.user-profile', {
        url: '/user-profile',
        views: {
            '': 'userProfile'
        }
    });
};

userProfileRoute.$inject = ['$stateProvider'];

export default userProfileRoute;
