import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hasValue'
})
export class HasValuePipe implements PipeTransform {
    transform(details: any[], filterFn: (detail: any) => boolean): any[] {
        return details.filter((detail) => filterFn(detail));
    }
}
