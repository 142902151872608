import { Component, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import {
    Subject, BehaviorSubject, of
} from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
// import * as _ from 'lodash';

import {
    Team, Crumb, ApiError
} from '@app/shared/models';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { StudyManagerService } from '../../study-manager.service';
import { StatusParams, ReturnTo, TeamConfiguration } from '../../study-manager.service.types';
import { JobStatus } from '../job-status-table/job-status-table.types';
import template from './study-manager.component.html';
import styles from './study-manager.component.scss';
@Component({
    selector: 'study-manager',
    template,
    styles: [String(styles)]
})

export class StudyManagerComponent implements OnInit, OnDestroy {
    crumbs: Crumb[] = [{ name: 'Study Manager' }];
    private jobStatusesRes = new BehaviorSubject<{ items: JobStatus[]; recordCount: number }>(undefined);
    jobStatusesRes$ = this.jobStatusesRes.asObservable();
    team: Team;
    statusParams: StatusParams;
    teamConfig: TeamConfiguration
    public returnTo: ReturnTo;
    public stateName: string;
    public createTabStateParams: StatusParams;
    public updateTabStateParams: StatusParams;
    public isLoading = true;
    loadStatusesError: boolean;
    loadStatusesErrorMessage = 'There was a problem with loading Job Statuses List. This is most likely a temporary issue. Please try again. If this continues, please contact support for assistance.';
    teamConfigLoading = true;
    errorMessage: string;
    $destroy = new Subject<void>();
    constructor(
        private featureFlags: FeatureFlagService,
        private CurrentSession: CurrentSessionService,
        private StudyManager: StudyManagerService,
        private Notifications: NotificationsService,
        private $state: StateService
    ) { }

    ngOnInit(): void {
        this.team = this.CurrentSession.getCurrentTeam();
        this.loadTeamJobStatuses();
        this.featureFlags.getFlag(FEATURE_FLAGS.STUDY_MANAGER_ENABLED, true).subscribe((data) => {
            if (!data) {
                this.$state.go('app.team.binders', { teamId: this.team.id });
            }
        });
        this.stateName = this.$state.current.name;
        this.returnTo = {
            stateName: this.stateName,
            params: { ...this.$state.params }
        };
        // Commented out because tabs were removed from page
        // this.statusParams = _.defaults(
        //     _.pick(this.returnTo.params, ['teamId', 'type']),
        //     { type: 'create' } as StatusParams
        // );
        // this.setTabsStateParams();
        this.StudyManager.getTeamConfig(this.team.id)
            .toPromise()
            .then(
                (data) => {
                    this.teamConfig = data;
                    this.teamConfigLoading = false;
                }
            );
    }

    ngOnDestroy(): void {
        this.$destroy.next();
        this.$destroy.complete();
    }

    changeTabView(type: 'create' | 'update' = 'create'): void {
        if (type === this.statusParams.type) {
            return;
        }

        this.returnTo = {
            stateName: this.$state.current.name,
            params: { ...this.$state.params }
        };
        this.statusParams = { teamId: this.statusParams.teamId, type };

    }

    // private setTabsStateParams(): void {
    //     this.createTabStateParams = { ...this.statusParams, type: 'create' };
    //     this.updateTabStateParams = { ...this.statusParams, type: 'update' };
    // }

    openCreateStudy() {
        this.$state.go('app.team.study-manager-create', { teamId: this.team.id, teamConfig: this.teamConfig });
    }

    openUpdateStudy() {
        this.$state.go('app.team.study-manager-update', { teamId: this.team.id, teamConfig: this.teamConfig });
    }

    loadTeamJobStatuses(params?): void {
        this.StudyManager.getJobStatuses(this.team.id, params)
            .pipe(
                retry(3),
                catchError((error: ApiError) => {
                    this.isLoading = false;
                    if (error.error && error.error.message) {
                        this.loadStatusesError = true;
                    }
                    else {
                        this.loadStatusesError = true;
                        this.Notifications.unexpectedError();
                    }
                    return of(null);
                })
            )
            .subscribe(
                (value) => {
                    this.jobStatusesRes.next(value);
                }
            );
    }
}
