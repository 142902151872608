
import * as _ from 'lodash';
import {
    Observable, Subject, forkJoin, of
} from 'rxjs';
import { ConfirmDestroySubmitEvent } from '@app/widgets/confirm-destroy/confirm-destroy.component.types';
import { CursorPageChangedEvent } from '@app/widgets/cursor-pagination/cursor-pagination.component.types';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { MissingPermissionSaveEvent } from '@app/widgets/missing-permissions/missing-permissions.types';
import { Role, Team, TeamPermissions } from '@app/shared/models';
import { StateService } from '@uirouter/core';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { PermissionInfoModalComponent } from '@app/components/permissions/containers/permission-info-modal/permission-info-modal.component';
import { Component, OnInit } from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import { PermissionsService } from '@app/shared/permissions/permissions.service';
import { TeamService } from '@app/shared/teams/team.service';
import { PermissionValidatorService } from '@app/shared/permissions/permission-validator.service';
import { ConfirmDestroyComponent } from '@app/widgets/confirm-destroy/confirm-destroy.component';
import { MissingPermissionsComponent } from '@app/widgets/missing-permissions/missing-permissions.component';
import { CheckIsViewBinderPermissionMissingResponse, Item } from '@app/shared/permissions/permission-validator.service.types';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { OnSaveRolePermissionForm, PermissionSet } from '../../components/role-permissions-form/role-permissions-form.component.types';
import { RolePermissionsFormComponent } from '../../components/role-permissions-form/role-permissions-form.component';
import { RolesService } from '../../roles.service';
import template from './role-permissions.component.html';
import { RolePermissionsCrumbs } from './role-permissions.component.types';

@Component({
    selector: 'role-permissions',
    template
})
export class RolePermissionsComponent implements OnInit {

    _paginationReset: Subject<void>;
    paginationReset$: Observable<void>;

    permissionSets: PermissionSet[];
    selectedPermissionSet: PermissionSet;
    loadingPermissionSets: boolean;
    readonly numberOfPermissionsToShow = 3;

    currentTeam: Team;

    roleId: string;
    role: Role;
    loadingRole: boolean;
    loadedRole: boolean;

    crumbs: RolePermissionsCrumbs[];

    hasMultiplePages: boolean;
    readonly pageSize = 20;
    next: string;

    openDropDown: number;

    constructor(
        private $state: StateService,
        private CurrentSession: CurrentSessionService,
        private Roles: RolesService,
        private Permissions: PermissionsService,
        private Teams: TeamService,
        private PermissionValidator: PermissionValidatorService,
        private Notifications: NotificationsService,
        private Modals: ModalsService
    ) {
        this.loadedRole = false;
        this.loadingRole = false;

        this._paginationReset = new Subject();

        this.paginationReset$ = this._paginationReset.asObservable();
    }

    ngOnInit(): void {
        this.roleId = this.$state.params.roleId;

        this.currentTeam = this.CurrentSession.getCurrentTeam();

        if (!this.currentTeam || !this._canManageRolePermissions(this.currentTeam.permissions)) {
            this.$state.go('app.select-team');
        }

        this.loadingRole = true;

        forkJoin({
            role: this._loadRole(),
            permissionSets: this._loadPermissions()
        }).subscribe((data: { role: Role, permissionSets: { items: PermissionSet[], next: string } }) => {
            this.role = data.role;
            this.permissionSets = data.permissionSets.items;
            this.hasMultiplePages = !!data.permissionSets.next;
            this.loadingRole = false;
            this.loadedRole = true;
            this.crumbs = this._getCrumbs();
        });
    }

    private _canManageRolePermissions(perm: TeamPermissions): boolean {
        return perm.manageTeam || perm.viewTeamUsersRolesPermissions;
    }

    toggleActions($event: PointerEvent, index: number, permissionSet: PermissionSet) {
        $event.preventDefault();
        if (this.selectedPermissionSet !== permissionSet) {
            this.select(permissionSet);
            this.openDropDown = index;
        }
        this.openDropDown = undefined;
    }


    select(permissionSet: PermissionSet) {
        if (permissionSet.selected) {
            permissionSet.selected = false;
            this.selectedPermissionSet = undefined;
        }
        else {
            this.permissionSets.forEach((set) => {
                set.selected = false;
            });

            permissionSet.selected = true;
            this.selectedPermissionSet = permissionSet;
        }
    }

    canActOnCreate() {
        return true;
    }

    canActOnSelection() {
        return !!this.selectedPermissionSet;
    }

    createPermissionSet(type: string) {
        const createPermissionSetModal = this.Modals.show(RolePermissionsFormComponent, {
            class: 'modal-lg',
            initialState: {
                permissionSetType: type,
                mode: 'create',
                roleId: this.role.id,
                selectedItems: type === 'team' ? [{ type: 'team', id: this.currentTeam.id }] : []
            }
        });

        createPermissionSetModal.content.onSave.subscribe((event: OnSaveRolePermissionForm) => {
            this._savePermissionSet(event);
        });
    }

    pageChanged(event: CursorPageChangedEvent): void {
        this.loadingPermissionSets = true;

        const next = event.cursor;

        this._loadPermissions({
            ...next && { next }
        }).subscribe((permissionSets) => {
            if (!next) {
                this.hasMultiplePages = !!permissionSets.next;
            }
            this.permissionSets = permissionSets.items;
            this.loadingPermissionSets = false;
            event.onSuccess();
        });
    }

    updatePermissionSet(permissionSet: PermissionSet) {
        const selectedItems = [
            {
                type: permissionSet.objectType.slice(0, -1),
                id: permissionSet.objectId
            }
        ];

        const updatePermissionSetModal = this.Modals.show(RolePermissionsFormComponent, {
            class: 'modal-lg',
            initialState: {
                permissionSetType: permissionSet.objectType.slice(0, -1),
                mode: 'update',
                permissionSet,
                roleId: this.role.id,
                selectedItems
            }
        });

        updatePermissionSetModal.content.onSave.subscribe((event: OnSaveRolePermissionForm) => {
            this._savePermissionSet(event);
        });
    }

    removePermissionSet(permissionSet: PermissionSet) {
        const bodyText = `This action <b class="text-uppercase">cannot</b> be undone.
            This will permanently remove permissions that role has on
            ${permissionSet.objectType.slice(0, -1)}: <b>${_.escape(permissionSet.path)}</b>`;

        const modalInstance = this.Modals.show(ConfirmDestroyComponent, {
            class: 'modal-md',
            initialState: {
                bodyText,
                confirmVerb: 'Remove',
                confirmVerbProcessing: 'Removing'
            }
        });

        modalInstance.content.dismiss.subscribe(() => {
            modalInstance.hide();
        });

        modalInstance.content.save.subscribe((event: ConfirmDestroySubmitEvent) => {
            this.Roles.removeRolePermissions(
                this.currentTeam.id,
                this.role.id,
                {
                    objectId: permissionSet.objectId,
                    objectType: [permissionSet.objectType.slice(0, -1)]
                }
            ).subscribe(
                () => {
                    this.Notifications.success('Permission deleted!');
                    this.selectedPermissionSet = undefined;
                    this._paginationReset.next();
                    event.onSuccess();
                },
                () => {
                    event.onError();
                }
            );
        });
    }

    openPermissionInfoModal(permissionSet: PermissionSet) {
        this.Permissions.getEffectivePermissions({
            subjectId: this.role.id,
            subjectType: this.role.type,
            objectId: permissionSet.objectId,
            objectType: permissionSet.objectType.slice(0, -1),
            teamId: this.currentTeam.id
        }).subscribe((effectivePermissions) => {

            const modal = this.Modals.show(PermissionInfoModalComponent, {
                class: 'modal-lg',
                initialState: {
                    effectivePermissions,
                    item: {
                        type: permissionSet.objectType.slice(0, -1),
                        name: permissionSet.path.split('/').pop(),
                        path: permissionSet.path,
                        pathCanonical: permissionSet.path,
                        teamId: this.currentTeam.id
                    },
                    subject: this.role,
                    onlyDirect: false,
                    editable: false,
                    disableEdit: true
                }
            });

            modal.content.onUpdate.subscribe(() => {
                modal.hide();
            });
        });
    }

    _getCrumbs() {
        return [
            {
                name: 'Manage Roles',
                stateName: 'app.team.manage-roles',
                stateParams: { teamId: this.currentTeam.teamId }
            },
            {
                name: this.role.name
            }
        ];
    }

    _loadRole() {
        return this.Roles.getRole(this.currentTeam.id, this.roleId).pipe(
            tap((data) => {
                return data;
            })
        );
    }

    _loadPermissions(params = {}): Observable<{ items: PermissionSet[], next: string }> {
        return this.Roles.getRolePermissions(this.currentTeam.id, this.roleId, { pageSize: this.pageSize, ...params }).pipe(
            tap((response) => {
                this.next = response.next;
                return response;
            })
        );
    }

    _savePermissionSet(savePermissionSetEvent: OnSaveRolePermissionForm) {
        const { items, itemPermissions } = savePermissionSetEvent;

        const missingPermissionsObservables = [];

        items.forEach((itemToGetMissingPermissionsFor) => {
            const observable = this.PermissionValidator
                .getMissingPermissions({
                    item: {
                        teamId: this.currentTeam.id,
                        ...itemToGetMissingPermissionsFor
                    } as unknown as Item,
                    itemPermissions
                });
            missingPermissionsObservables.push(observable);
        });

        return forkJoin(missingPermissionsObservables)
            .subscribe(
                (permissions: CheckIsViewBinderPermissionMissingResponse[]) => {

                    const localPermissions = [];
                    const externalPermissions = [];

                    permissions.forEach((permission) => {
                        permission.localPermissions.forEach((localPermissionToAdd) => {
                            const localPermissionIndex = localPermissions.findIndex((localPermission) => {
                                return localPermission.permissionName === localPermissionToAdd.permissionName
                                && localPermission.objectInfo?.objectType === localPermissionToAdd.objectInfo?.objectType
                                && localPermission.objectInfo?.objectName === localPermissionToAdd.objectInfo?.objectName;
                            });
                            if (localPermissionIndex === -1) {
                                localPermissions.push(localPermissionToAdd);
                            }
                        });

                        permission.externalPermissions.forEach((externalPermissionToAdd) => {
                            const externalPermissionIndex = externalPermissions.findIndex((externalPermission) => {
                                return externalPermission.permissionName === externalPermissionToAdd.permissionName
                                && externalPermission.objectInfo?.objectType === externalPermissionToAdd.objectInfo?.objectType
                                && externalPermission.objectInfo?.objectName === externalPermissionToAdd.objectInfo?.objectName;
                            });
                            if (externalPermissionIndex === -1) {
                                externalPermissions.push(externalPermissionToAdd);
                            }
                        });
                    });

                    if (!localPermissions.length && !externalPermissions.length) {
                        return this._savePermissionTree(savePermissionSetEvent);
                    }

                    savePermissionSetEvent.onSuccess();

                    const modalInstance = this.Modals.show(MissingPermissionsComponent, {
                        class: 'modal-md',
                        initialState: {
                            activeSubjectName: this.role.name,
                            missingPermissions: localPermissions.concat(externalPermissions)
                                .map((permission) => {
                                    return {
                                        name: permission.permissionName,
                                        objectInfo: permission.objectInfo
                                    };
                                })
                        }
                    });

                    modalInstance.content.onSave.subscribe((event: MissingPermissionSaveEvent) => {
                        event.onSave();
                        if (externalPermissions.length) {
                            return this.Permissions
                                .saveMissing(this.currentTeam.teamId, {
                                    permissions: externalPermissions.map((p) => p.saveParams)
                                }).subscribe(() => this._savePermissionTree(savePermissionSetEvent));
                        }
                        localPermissions.forEach((permission) => permission.fix());
                        return this._savePermissionTree(savePermissionSetEvent);
                    });

                    modalInstance.content.dismiss.subscribe(() => {
                        modalInstance.hide();
                    });
                }
            );
    }

    _savePermissionTree(event) {
        const { items, itemPermissions } = event;
        itemPermissions.subjectId = itemPermissions.subject.id;
        itemPermissions.subjectType = itemPermissions.subject.type;

        const shouldRemoveSrc = !items.map((i) => i.id).includes(itemPermissions.tree.id);
        const srcObjectId = itemPermissions.tree.id;


        if (items.length > 1) {
            itemPermissions.tree.ids = items.map((i) => i.id);
            delete itemPermissions.tree.id;
        }
        else {
            itemPermissions.tree.id = items[0].id;
        }

        this.selectedPermissionSet = undefined;

        this.Teams.updatePermissions(itemPermissions)
            .pipe(
                switchMap(() => {
                    event.onSuccess();
                    if (shouldRemoveSrc) {
                        return this.Roles.removeRolePermissions(
                            this.currentTeam.id,
                            this.role.id,
                            {
                                objectId: srcObjectId,
                                objectType: [itemPermissions.tree.type]
                            }
                        );
                    }
                    return of(null);
                }),
                tap(() => {
                    this._paginationReset.next();
                }),
                catchError(() => {
                    event.onError();
                    return of(null);
                })
            )
            .subscribe();
    }
}
