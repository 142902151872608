import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { StateObject, StateService } from '@uirouter/core';
import { from, Observable } from 'rxjs';
import {
    catchError, switchMap, take
} from 'rxjs/operators';
import { Auth0Service } from '../../../../shared/sessions/auth0.service';
import { ArchivesService } from '../../archives.service';
import template from './archive-download.component.html';
import styles from './archive-download.component.scss';
import logo from '../../../../../images/Florence-eBinder-logo.png';

@Component({
    selector: 'archive-download',
    template,
    styles: [String(styles)]
})
export class ArchiveDownloadComponent {
    result$: Observable<void | StateObject>;
    error = false;
    errorMessage;
    isAuthenticated = false;
    logo = logo;
    constructor(
        private Archives: ArchivesService,
        private $state: StateService,
        private Auth0: Auth0Service,
        private Notifications: NotificationsService,
        @Inject('Window') private window: Window
    ) { }

    ngOnInit() {
        this.Auth0.isAuthenticated$.pipe(
            take(1),
            switchMap((isAuthenticated) => {
                this.isAuthenticated = isAuthenticated;
                if (!isAuthenticated) {
                    return from('Session expired') as Observable<string>;
                }
                return this.downloadArchive();
            })
        ).subscribe((result) => {
            if (!this.isAuthenticated) {
                return this.$state.go('sign-in');
            }
            if (this.error) {
                this.Notifications.error(result as unknown as string);
            }
            else {
                this.window.close();
            }
        });
    }

    private downloadArchive(): Observable<void> {
        const { teamId, archiveId } = this.$state.params;
        return this.Archives.downloadArchive(teamId, archiveId)
            .pipe(
                catchError(async (error: HttpErrorResponse) => {
                    if (error.status >= 400) {
                        this.error = true;
                    }
                    return JSON.parse(await error.error.text()).message;
                })
            );
    }
}
