import {
    Component, Input, EventEmitter, Output, OnInit
} from '@angular/core';
import {
    User, SigningReasons, Document, Team
} from '@app/shared/models';
import { PasswordOrPinExpiryService } from '@app/shared/password-or-pin-expiry/password-or-pin-expiry.service';
import {
    FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { LogEntrySignature } from '../../containers/document-log-entries/document-log-entries.component.types';
import { SignLogEntriesCloseReason, SignLogEntriesSaveEvent } from './sign-log-entries.component.types';
import template from './sign-log-entries.component.html';
import styles from './sign-log-entries.component.scss';

@Component({
    selector: 'sign-log-entries',
    styles: [String(styles)],
    template
})
export class SignLogEntriesComponent implements OnInit {
    @Input() doc: Document;
    @Input() currentTeam: Team;
    @Input() currentUser: User;
    @Input() logEntrySignatures: LogEntrySignature[];
    @Input() jobTitle: string;

    @Output() save = new EventEmitter<SignLogEntriesSaveEvent>();
    @Output() dismiss = new EventEmitter<SignLogEntriesCloseReason>();

    form: FormGroup;
    isSigningPasscodeExpired = false;
    signingPassphrase = '';
    passwordOrPasscode: 'Signing passcode' | 'Password';
    hasACertifiedCopyReason = false;

    dynamicState = {
        isProcessing: false,
        submitDisabled: true,
        passwordError: {
            required: false,
            pattern: false
        }
    }

    constructor(
        private fb: FormBuilder,
        private PasswordOrPinExpiry: PasswordOrPinExpiryService
    ) {}

    ngOnInit(): void {
        this.configurePasswordOrPasscodeDetails();
        this.form = this.fb.group({
            signingPassphrase: ['', [
                Validators.required
            ]],
            jobTitle: [this.jobTitle, [Validators.required]]
        });
        this.hasACertifiedCopyReason = this.certifiedCopyReasonExists();
        this.form.controls.jobTitle.disable();
    }

    private certifiedCopyReasonExists(): boolean {
        return this.logEntrySignatures.some(({ reason }) => reason === SigningReasons.certifiedCopy);
    }

    private configurePasswordOrPasscodeDetails(): void {
        this.passwordOrPasscode = this.currentUser.isRemote ? 'Signing passcode' : 'Password';
        this.isSigningPasscodeExpired = this.currentUser.isRemote && this.PasswordOrPinExpiry.isExpired(
            this.currentTeam.settings.signingPINPolicy,
            this.currentUser.lastSigningPasscodeReset
        );
    }

    onPasswordValueChange(): void {
        const input = this.form.controls.signingPassphrase;

        this.dynamicState.submitDisabled = this.shouldDisableSubmit();
        this.dynamicState.passwordError.required = Boolean(input.dirty && input.errors && input.errors.required);
        this.dynamicState.passwordError.pattern = Boolean(input.dirty && input.errors && input.errors.pattern);
    }

    private shouldDisableSubmit(): boolean {
        return this.form.invalid
            || this.form.pristine
            || this.dynamicState.isProcessing;
    }

    saveForm(): boolean {
        if (this.dynamicState.submitDisabled) {
            return;
        }

        this.dynamicState.submitDisabled = true;
        this.dynamicState.isProcessing = true;

        this.save.emit({
            data: {
                signatures: this.logEntrySignatures.map((logEntrySignature) => ({
                    logEntryId: logEntrySignature.logEntry.id.logEntryId,
                    column: logEntrySignature.column,
                    reason: logEntrySignature.reason,
                    jobTitle: this.form.controls.jobTitle.value
                })),
                email: this.currentUser.email,
                status: 'Signed',
                teamId: this.currentTeam.id,
                ...this.currentUser.isRemote && { signingPasscode: this.form.controls.signingPassphrase.value },
                ...!this.currentUser.isRemote && { password: this.form.controls.signingPassphrase.value }
            },
            onSuccess: () => this.dismissModal('signed'),
            onError: () => {
                this.dynamicState.submitDisabled = false;
                this.dynamicState.isProcessing = false;
            }
        });
    }

    dismissModal(dismissReason?: SignLogEntriesCloseReason): void {
        this.dismiss.emit(dismissReason);
    }
}
