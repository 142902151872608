import {
    Folder,
    Document,
    SignatureRequest,
    Timeline,
    LabeledEntity,
    Task,
    Binder
} from '@app/shared/models';
import {
    ConversionState,
    DueState,
    ExpirationState,
    SignaturesState
} from '@app/shared/adapter/adapter.service.types';

export type GlobalViewItem = (Binder | Folder | Document) & {
    _id?: string;
    id?: string;
    name?: string;
    title?: string;
    version?: string;
    binderId?: string;
    expanded: boolean;
    items?: GlobalViewItem[];
    hasPendingTasks?: boolean;
    hasPendingSignatures?: boolean;
    hasTimelines?: boolean;
    state?: {
        conversion: ConversionState;
        due: DueState;
        expiration: ExpirationState;
        signatures: SignaturesState;
    };
}

export type GlobalViewItemDecorationProperty = 'pendingSignatures' | 'pendingTasks' | 'timelines' | 'directLabels';
export type GlobalViewItemDecoration = Array<SignatureRequest | Task | Timeline | LabeledEntity>;

export const globalViewItemIsBinder = (object): object is Binder => {
    return object.type === 'binder';
};

export const globalViewItemIsFolder = (object): object is Folder => {
    return object.type === 'folder';
};

export const globalViewItemIsDocument = (object): object is Document => {
    return object.type === 'document';
};
