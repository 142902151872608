import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    EventEmitter,
    SimpleChanges
} from '@angular/core';
import { Team, TeamSipIntegrationLink } from '@app/shared/models';

import template from './manage-team-sip-links.component.html';

@Component({
    selector: 'manage-team-sip-links',
    template
})
export class ManageTeamSipLinksComponent implements OnChanges, OnDestroy {
    @Input() team: Team;
    @Input() teamSipLinks: TeamSipIntegrationLink[];
    @Output() create = new EventEmitter<void>();
    @Output() delete = new EventEmitter<TeamSipIntegrationLink>();

    selectedTeamSipLinkId?: string;
    newTeamSipLinkId?: string;
    newTeamSipLinkIdTimeout;
    loading = true;

    ngOnChanges(changes: SimpleChanges): void {
        const { teamSipLinks } = changes;
        if (teamSipLinks.currentValue) {
            this.loading = false;
        }
        // this will evaulate to true only if current and previous have values
        // eslint-disable-next-line no-restricted-globals
        if (teamSipLinks.currentValue?.length > teamSipLinks.previousValue?.length) {
            this.highlightNewestElement(teamSipLinks);
        }
        this.checkSelectionExistence();
    }

    ngOnDestroy(): void {
        clearTimeout(this.newTeamSipLinkIdTimeout);
    }

    highlightNewestElement({ currentValue, previousValue }): void {
        clearTimeout(this.newTeamSipLinkIdTimeout);
        const { id: newTeamSipLinkId } = this.getNewestElementId({ currentValue, previousValue });

        this.newTeamSipLinkId = newTeamSipLinkId;
        this.newTeamSipLinkIdTimeout = newTeamSipLinkId && setTimeout(() => {
            this.newTeamSipLinkId = undefined;
        }, 5000);
    }

    getNewestElementId({ currentValue, previousValue }): TeamSipIntegrationLink {
        const idsHash = previousValue.reduce((hash, val) => {
            hash[val.id] = 1;
            return hash;
        }, {});
        return currentValue.find(({ id }) => !idsHash[id]) || {};
    }

    select(id?: string): void {
        this.selectedTeamSipLinkId = this.selectedTeamSipLinkId === id ? undefined : id;
    }

    toggleActions(id: string): void {
        this.selectedTeamSipLinkId = id;
    }

    handleCreate(): void {
        this.create.emit();
    }

    handleDelete(): void {
        this.delete.emit(this.selectedTeamSipLink);
    }

    private checkSelectionExistence(): void {
        !this.selectedTeamSipLink && this.select();
    }

    private get selectedTeamSipLink(): TeamSipIntegrationLink {
        // eslint-disable-next-line no-restricted-globals
        return this.teamSipLinks?.find(({ id }) => this.selectedTeamSipLinkId === id);
    }
}
