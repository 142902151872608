import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotificationsService } from '@app/core/notifications/notifications.service';
import { SystemNotification, SystemNotificationReading } from '@app/shared/models/system-notification.model';

@Injectable()
export class SystemNotificationsService {
    private readonly url = {
        systemNotifications: (userId: string): string => `/api/users/${userId}/system-notifications`,
        readSystemNotification: (userId: string): string => `/api/users/${userId}/read-system-notification`
    }

    constructor(private http: HttpClient, private Notifications: NotificationsService) { }

    getSystemNotifications(userId: string): Observable<SystemNotification[]> {
        return this.http.get<SystemNotification[]>(this.url.systemNotifications(userId))
            .pipe(catchError(() => {
                return of([]);
            }));
    }

    readSystemNotification(userId: string, notificationId: string): Observable<SystemNotificationReading> {
        return this.http.post<void>(this.url.readSystemNotification(userId), { userId, notificationId })
            .pipe(catchError(() => {
                this.Notifications.unexpectedError();
                return of(null);
            }));
    }
}
