/**
 * Calculates the date with days offset in the future or in the past
 * @param {Date} refDate - Reference date
 * @param {Number} daysOffset - Days offset, if negative returns the past date
 * @returns {Date}
 */
export const getDateWithDaysOffset = (refDate: Date, daysOffset: number): Date => {
    const oneDay = 24 * 60 * 60 * 1000;
    return new Date(refDate.getTime() + (daysOffset * oneDay));
};
