import { ApiError, Binder, Folder } from '@app/shared/models';
import * as moment from 'moment-timezone';
import { FoldersService } from '@app/shared/folders/folders.service';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import {
    Component, HostListener, Input, OnInit
} from '@angular/core';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { notBlank } from '@app/core/form-validators';
import { FolderizeTreeItem } from '../../shared/folders/folders.service.types';

import styles from './folderizer.component.scss';
import template from './folderizer.component.html';

@Component({
    selector: 'folderizer',
    template,
    styles: [String(styles)]
})
export class FolderizerComponent implements OnInit {
    @Input() item: Folder | Binder;
    @Input() timezone: string;

    teamTimezone: string;
    isProcessingPreview = false;
    isProcessingSave = false;
    isTutorialVisible = false;
    isPreviewed = false;
    errExists: boolean;
    folderizerForm: FormGroup;

    items: FolderizeTreeItem[] = [];
    constructor(
        private $state: StateService,
        private Folders: FoldersService,
        private Notifications: NotificationsService,
        private modalInstance: BsModalRef,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.teamTimezone = moment().tz(this.timezone).format('Z');
        this.initForm();
    }

    initForm() {
        this.folderizerForm = this.fb.group({
            template: ['', [Validators.required, notBlank]]
        });

        this.folderizerForm.controls.template.valueChanges.subscribe(() => {
            this.onTemplateChange();
        });
    }

    onTemplateChange() {
        if (this.isPreviewed) {
            this.isPreviewed = false;
        }
    }

    cancel(): void {
        this.modalInstance.hide();
    }

    preview() {
        this.isProcessingPreview = true;

        this.Folders.folderize({ item: this.item, template: this.folderizerForm.controls.template.value, previewOnly: true })
            .subscribe(
                (data) => {
                    this.items = sortByLexicographically(data.tree, 'name');
                    this.errExists = data.errExists;
                    this.isProcessingPreview = false;
                    this.isPreviewed = true;

                },
                (err) => {
                    this.items = [];
                    this.isProcessingPreview = false;
                    this._handleErrorNotification(err);
                }
            );
    }

    save() {
        this.isProcessingSave = true;

        this.Folders.folderize({ item: this.item, template: this.folderizerForm.controls.template.value, previewOnly: false })
            .subscribe(
                () => {
                    this.modalInstance.hide();
                    this.Notifications.success('Folder structure successfully imported.');
                    if (this.item.type === 'binder') {
                        this.$state.go('app.team.folder', { binderId: this.item.id });
                    }
                    else {
                        this.$state.go('app.team.folder', { folderId: this.item.id });
                    }
                },
                (err) => {
                    this.isProcessingSave = false;
                    this._handleErrorNotification(err);
                }
            );
    }

    _handleErrorNotification({ error }: ApiError): void {
        const { message } = error;
        if (message) {
            return this.Notifications.error(message);
        }

        return this.Notifications.unexpectedError();
    }

    @HostListener('keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter' && document.activeElement.id === 'toogleTutorialBtn') {
            this.isTutorialVisible = !this.isTutorialVisible;
            event.preventDefault();
        }
    }
}
