import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { Document, Team } from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Connection } from '../../services/document-sharing.types';

import template from './entity-item.component.html';
import styles from './entity-item.component.scss';


@Component({
    selector: 'entity-item',
    template,
    styles: [String(styles)]
})
export class EntityItemComponent {
  @Input() type: string;
  @Input() document?: Document;
  @Input() connection?: Connection;
  @Input() hasOneElement?: number;
  @Input() hasOneElementTooltip?: string;
  @Input() hasInfoIcon?: boolean;
  @Input() hasInfoIconTooltip?: string;
  @Input() hasRemovalIcon?: boolean;
  @Input() hasDifferentDataCenter?: boolean;
  @Input() hasDifferentDataCenterTooltip?: string;


  @Output() removeDocument = new EventEmitter<Document>();
  @Output() removeConnections = new EventEmitter<Connection>();

  public currentTeam: Team;

  constructor(private CurrentSession: CurrentSessionService) {
      this.currentTeam = this.CurrentSession.getCurrentTeam();

      this.document = null;
      this.connection = null;

      this.hasOneElement = 0;
      this.hasInfoIcon = false;
      this.hasRemovalIcon = true;

      this.hasOneElementTooltip = '';
      this.hasInfoIconTooltip = '';

      this.hasDifferentDataCenter = false;
      this.hasDifferentDataCenterTooltip = '';
  }

  onRemoveDocument(document: Document): void {
      this.removeDocument.emit(document);
  }

  onRemoveConnection(connection: Connection): void {
      this.removeConnections.emit(connection);
  }

  getConnectedTeamName(connection: Connection): string {
      return connection.configs.find((config) => config.teamId !== this.currentTeam.id)?.name;
  }
}
