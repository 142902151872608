import {
    Component, EventEmitter, Input, Output
} from '@angular/core';

import template from './annotations-clear-all-modal.component.html';

@Component({
    selector: 'annotations-clear-all-modal',
    template
})
export class AnnotationsClearAllModalComponent {
    @Input() isForm = false;
    @Output() clearAll = new EventEmitter<void>();
    @Output() dismiss = new EventEmitter<void>();

    dismissModal(): void {
        this.dismiss.emit();
    }

    onClearAll() {
        this.clearAll.emit();
    }
}
