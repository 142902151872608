import { VirtualTreeSelectionMode } from '@app/widgets/virtual-tree/virtual-tree.component.types';
import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { Binder, Folder } from '@app/shared/models';
import style from './global-view-select-root.component.scss';
import template from './global-view-select-root.component.html';
import { GlobalViewSelectRootEvent } from './global-view-select-root.types';
@Component({
    template,
    styles: [String(style)]
})

export class RootSelectComponent implements OnInit {
    @Input() loadRoot: () => Binder[];
    @Input() loadItem: () => Promise<(Binder | Document | Folder)[]>;
    @Output() onSubmit = new EventEmitter<GlobalViewSelectRootEvent>();

    public items: Binder[];
    public loadingRoot = false;
    public isProcessing = false;
    public selectionMode = VirtualTreeSelectionMode;

    constructor(public modal: BsModalRef) {}

    ngOnInit(): void {
        this.loadingRoot = true;
        this.items = sortByLexicographically(this.loadRoot(), 'name');
        this.loadingRoot = false;
    }

    cancel(): void {
        this.modal.hide();
    }

    onItemClicked = (item): void => {
        this.isProcessing = true;
        this.onSubmit.emit({
            item,
            onSuccess: () => {
                this.modal.hide();
            },
            onError: () => {
                this.isProcessing = false;
                this.modal.hide();
            }
        });
    }
}
