import {
    Input,
    Output,
    EventEmitter,
    Component
} from '@angular/core';
import style from './missing-signing-passcode.scss';
import template from './missing-signing-passcode.html';
import { MissingSigningPasscodeSubmitEvent } from './missing-signing-passcode.component.types';

@Component({
    template,
    styles: [String(style)]
})
export class MissingSigningPasscodeComponent {
    @Input() entity: unknown;
    @Output() onSubmit = new EventEmitter<MissingSigningPasscodeSubmitEvent>();
    @Output() dismiss = new EventEmitter<void>();

    isProcessing = false;

    cancel(): void {
        this.dismiss.emit();
    }

    handleSubmit(): void {
        this.isProcessing = true;

        this.onSubmit.emit({
            onSuccess: () => this.cancel(),
            onError: () => {
                this.isProcessing = false;
            }
        });
    }
}
