import 'core-js/stable';
import '!!file-loader?name=[name].[ext]!../favicon.ico'; // eslint-disable-line import/no-webpack-loader-syntax, import/no-unresolved
import '../stylesheets/styles.scss';

import 'zone.js';
import 'reflect-metadata';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UIRouter, UrlService } from '@uirouter/core';
import { AppModule } from './app.module';


platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((platformRef) => {
        const { injector } = platformRef;

        const url: UrlService = injector.get(UIRouter).urlService;

        // Fallback for old (non-existing) /manage-team-profile
        url.rules.when(
            '/app/teams/:teamId/manage-team-profile',
            '/app/teams/:teamId/manage-team-settings'
        );

    });
