import * as _ from 'lodash';
import {
    Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild
} from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

import {
    Binder, Folder, Team, Document
} from '@app/shared/models';
import { AdapterService } from '@app/shared/adapter/adapter.service';
import { PermissionReportTreeFlatNode, PermissionReportTreeNode } from './permission-report-tree.component.types';

import template from './permission-report-tree.component.html';
import styles from './permission-report-tree.component.scss';

@Component({
    selector: 'permission-report-tree',
    template,
    styles: [String(styles)]
})
export class PermissionReportTreeComponent implements OnChanges {
    @Input() team: Team;
    @Input() loadingTree: boolean;
    @Input() tree: PermissionReportTreeNode[];
    @Input() isDirectMode: boolean;
    @Input() teamHasDirectPermissions: boolean;
    @Input() filter: string;
    @Input() loadBinder: (id: string, name: string) => Promise<PermissionReportTreeNode[]>;
    @Output() onItemClicked = new EventEmitter<Team | Binder | Folder | Document>();
    @Output() onBinderExpanded = new EventEmitter();

    @ViewChild('virtualViewport', { static: true }) virtualViewport: CdkVirtualScrollViewport;

    loadingBinderId: string;
    expandedDescendantsNode: PermissionReportTreeFlatNode;

    treeControl = new FlatTreeControl<PermissionReportTreeFlatNode>(this.getNodeLevel, this.getIsNodeExpandable);
    dataSource: MatTreeFlatDataSource<PermissionReportTreeNode, PermissionReportTreeFlatNode>;
    treeFlattener = new MatTreeFlattener<PermissionReportTreeNode, PermissionReportTreeFlatNode>(
        this.nodeTransformer,
        this.getNodeLevel,
        this.getIsNodeExpandable,
        this.getNodeChildren
    );

    constructor(private Adapter: AdapterService) {
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.loadingTree) {
            // Because tree data is not emptied on time scroll to top to avoid bad UX
            this.virtualViewport.scrollToIndex(0);
        }

        if (changes.tree) {
            this.dataSource.data = _.cloneDeep(this.tree);
            delete this.expandedDescendantsNode;
        }

        if (changes.filter) {
            this.filterTreeData(this.filter);
        }
    }

    filterTreeData(filter: string): void {
        const expandedBinder = this.getExpandedBinder();

        if (!expandedBinder) {
            return;
        }

        if (filter) {
            this.dataSource.data = this.dataSource.data.filter((node) => {
                if (node.id === expandedBinder.id) {
                    node.items = node.items.filter((item) => this.filterDocumentsByName(item));
                }

                return true;
            });
        }
        else {
            this.dataSource.data = _.cloneDeep(this.tree);
        }

        const updatedExpandedBinder = this.treeControl.dataNodes.find((dataNode) => dataNode.id === expandedBinder.id);
        this.expandDescendants(updatedExpandedBinder);
    }

    teamClicked(item: Team): void {
        this.onItemClicked.emit(item);
    }

    getConversionState(item: Document): {isFailed: boolean; inProgress: boolean} {
        return this.Adapter.getConversionState(item);
    }

    nodeClicked(node: PermissionReportTreeFlatNode): void {
        const {
            level, hasChildren, expanded, items, ...sourceNode // eslint-disable-line @typescript-eslint/no-unused-vars
        } = node;
        this.onItemClicked.emit(sourceNode);
    }

    nodeHasExpandedDescendants(node: PermissionReportTreeFlatNode): boolean {
        return this.expandedDescendantsNode && this.expandedDescendantsNode.id === node.id;
    }

    async toggleDescendants(node: PermissionReportTreeFlatNode): Promise<void> {
        const updatedNode = await this.loadNode(node);

        if (this.nodeHasExpandedDescendants(updatedNode)) {
            this.collapseDescendants(updatedNode);
            return;
        }

        this.expandDescendants(updatedNode);
        this.onBinderExpanded.emit();
    }

    async toggleNode(node: PermissionReportTreeFlatNode): Promise<void> {
        const updatedNode = await this.loadNode(node);

        if (updatedNode.type === 'binder') {
            if (this.nodeHasExpandedDescendants(updatedNode)) {
                this.collapseDescendants(updatedNode);
                return;
            }

            if (!this.treeControl.isExpanded(updatedNode)) {
                this.collapseAll();
            }

            this.onBinderExpanded.emit();
        }

        this.treeControl.toggle(updatedNode);
    }

    private getExpandedBinder(): PermissionReportTreeFlatNode {
        return this.treeControl.expansionModel.selected.find((selectedNode) => selectedNode.level === 0);
    }

    private expandDescendants(node: PermissionReportTreeFlatNode): void {
        this.collapseAll();
        this.treeControl.expandDescendants(node);
        this.expandedDescendantsNode = node;
    }

    private collapseDescendants(node: PermissionReportTreeFlatNode): void {
        this.treeControl.collapseDescendants(node);
        delete this.expandedDescendantsNode;
    }

    private collapseAll(): void {
        this.treeControl.collapseAll();
        delete this.expandedDescendantsNode;
    }

    private getNodeLevel({ level }: PermissionReportTreeFlatNode): number {
        return level;
    }

    private getIsNodeExpandable({ hasChildren }: PermissionReportTreeFlatNode): boolean {
        return hasChildren;
    }

    private nodeTransformer(node: PermissionReportTreeNode, level: number): PermissionReportTreeFlatNode {
        return {
            ...node,
            level,
            hasChildren: node && node.items && node.items.length > 0
        };
    }

    private getNodeChildren({ items }: PermissionReportTreeNode): PermissionReportTreeNode[] {
        return items;
    }

    private shouldLoadBinderItems(node: PermissionReportTreeFlatNode): boolean {
        return node.type === 'binder' && node.items === undefined && !this.treeControl.isExpanded(node);
    }

    private async loadNode(node: PermissionReportTreeFlatNode): Promise<PermissionReportTreeFlatNode> {
        if (this.shouldLoadBinderItems(node)) {
            this.loadingBinderId = node.id;

            const binderSubTree = await this.loadBinder(node.id, node.name);
            this.updateTree(node.id, binderSubTree);

            delete this.loadingBinderId;

            return this.treeControl.dataNodes.find((item) => node.id === item.id);
        }

        return node;
    }

    private updateTree(binderId: string, data: PermissionReportTreeNode[]): void {
        const updatedSourceData = [...this.dataSource.data];
        updatedSourceData.find((node) => node.id === binderId).items = data;
        this.collapseAll();
        this.dataSource.data = updatedSourceData;
        this.tree = _.cloneDeep(updatedSourceData);
    }

    private filterDocumentsByName(node: PermissionReportTreeNode): boolean {
        const nameRegex = new RegExp(_.escapeRegExp(this.filter), 'gi');

        if (node.type === 'document') {
            return nameRegex.test(node.name);
        }

        node.items = node.items.filter((item) => this.filterDocumentsByName(item));
        return true;
    }
}
