import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import { Observable } from 'rxjs';
import { Report } from '@app/shared/models/report.model';
import { catchError, map, tap } from 'rxjs/operators';
import {
    CompleteReportDataParams, ReportData, SendSignatureRemindersPayload, SendSignatureRemindersResponse
} from './reports.service.types';


@Injectable()
export class ReportService {

    readonly url = {
        index: (teamId: string) => `/api/teams/${teamId}/reports`,
        get: ({ teamId, reportId }: { teamId: string, reportId: string }) => `/api/teams/${teamId}/reports/${reportId}`,
        bulkSignatureRequests: '/api/documents/signature-requests'
    };

    constructor(
        private http: HttpClient,
        private ApiErrors: ApiErrorsService,
        private Notifications: NotificationsService
    ) {}


    loadReports(teamId: string): Observable<Report[]> {
        const uri = this.url.index(teamId);
        return this.http.get<{ reports: Report[] }>(uri).pipe(
            map(({ reports }) => reports),
            catchError((error) => this.ApiErrors.handleError(error))
        );
    }

    loadReportData(params: CompleteReportDataParams): Observable<ReportData> {
        const propsForParams = ['sortBy', 'sortDir', 'pageNum', 'pageSize', 'withPermissions', 'filterBy', 'filterField'];
        const {
            teamId,
            reportId,
            binders = [],
            folders = [],
            tagIds = [],
            labelIds = [],
            studyIds = [],
            sipStatuses = [],
            qcStatuses = [],
            monitorStatuses = [],
            dateFilter,
            additionalFilters = []
        } = params;

        let query = new HttpParams();

        propsForParams.forEach((prop) => {
            if (params[prop]) {
                query = query.set(prop, params[prop].toString());
            }
        });

        if (dateFilter) {
            query = query.set('dateFilter', JSON.stringify(dateFilter));
        }

        const payload = {
            ...(binders && binders.length) && { binderIds: binders.map((i) => i.id) },
            ...(folders && folders.length) && { folderIds: folders.map((i) => i.id) },
            ...(tagIds && tagIds.length) && { tagIds },
            ...(studyIds && studyIds.length) && { studyIds },
            ...(labelIds && labelIds.length) && { labelIds },
            ...(sipStatuses && sipStatuses.length) && { sipStatuses },
            ...(qcStatuses && qcStatuses.length) && { qcStatuses },
            ...(monitorStatuses && monitorStatuses.length) && { monitorStatuses },
            ...(additionalFilters && additionalFilters.length) && { additionalFilters }
        };

        const uri = this.url.get({
            teamId,
            reportId
        });

        return this.http.post<ReportData>(uri, payload, { params: query }).pipe(
            map((data) => data),
            catchError((error) => this.ApiErrors.handleError(error))
        );
    }

    sendSignatureReminders(payload: SendSignatureRemindersPayload): Observable<SendSignatureRemindersResponse> {
        const uri = this.url.bulkSignatureRequests;
        return this.http.patch<SendSignatureRemindersResponse>(uri, payload).pipe(
            tap((data) => {
                let documentRemindersCount = 0;
                let logEntryRemindersCount = 0;
                let failedCount = 0;
                data.forEach((r) => {
                    if (r.statusCode === 200) {
                        r.payload.updatedRequests.forEach((request) => {
                            if (request.entryId) {
                                logEntryRemindersCount += 1;
                            }
                            else {
                                documentRemindersCount += 1;
                            }
                        });
                    }
                    else {
                        failedCount += 1;
                    }
                });

                if (documentRemindersCount) {
                    this.Notifications.success(`Document signatures: ${documentRemindersCount} reminded.`);
                }
                if (logEntryRemindersCount) {
                    this.Notifications.success(`Log row signatures: ${logEntryRemindersCount} reminded.`);
                }
                if (failedCount) {
                    this.Notifications.error(`Reminder requests: ${failedCount} failed`);
                }
            }),
            catchError((error) => this.ApiErrors.handleError(error))
        );
    }

}
